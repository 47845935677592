import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import find from 'lodash/find';
import includes from 'lodash/includes';
import chain from 'lodash/chain';
import startsWith from 'lodash/startsWith';

import FeedbackSummary from './feedback_summary';
import FeedbackFormTable from '../forms/FeedbackFormTable';
import {
  SURVEY_SUMMARY_QUERY_STRING,
  SURVEY_SUMMARY_TAB,
  SURVEY_RESPONSES_TAB,
} from './constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

const FeedbackSurveyManager = props => {
  const [activeTab, setActiveTab] = useState(props.activeTab);
  const [currentSelectedForm, setCurrentSelectedForm] = useState({});

  const redirectUrl = (isTabRedirection = false) => {
    let url = window.location.href;
    let a = url.split('?')[0];

    let redirectionURL = a;

    if (isTabRedirection) {
      const lastPathName = chain(a).split('/').last().value();
      const isPathNameHasTab = startsWith(lastPathName, 'tab_');

      let newPathName = lastPathName;
      if (isPathNameHasTab) {
        if (!isEqual(lastPathName, activeTab)) {
          newPathName = activeTab;
        }
        redirectionURL = `${a.replace(lastPathName, newPathName)}`;
      } else {
        redirectionURL = `${a}/${activeTab}`;
      }
    }
    return `${redirectionURL}`;
  };

  const getSelectedForm = id => {
    const { surveyForms } = props;
    return find(surveyForms, { id: parseInt(id) });
  };

  const redirectPrevForm = e => {
    e.preventDefault();

    const path = window.location.pathname;

    /** If user enters url manually and it doesn't include tab, ex: /survey/summary?survey_form_id=14
     *  we do not want to redirect url with tab name and change state for active tab.
     * If url includes tab_ redirecting back will happen accordingly.
     */

    const isPathNameHasTab = includes(path, 'tab_');
    let isTabRedirection = false;

    let newActiveTab = activeTab;
    if (isPathNameHasTab) {
      newActiveTab = !isEqual(activeTab, SURVEY_SUMMARY_TAB)
        ? SURVEY_SUMMARY_TAB
        : SURVEY_RESPONSES_TAB;
      isTabRedirection = true;
    }

    if (window.location.href.includes(`?${SURVEY_SUMMARY_QUERY_STRING}=`)) {
      const formId = window.location.search.split('=')[1];
      const selectedForm = getSelectedForm(formId);
      setCurrentSelectedForm(selectedForm);
      if (isTabRedirection) setActiveTab(newActiveTab);
      if (history.replaceState) {
        window.history.replaceState(
          '',
          '',
          `${redirectUrl(
            isTabRedirection
          )}?${SURVEY_SUMMARY_QUERY_STRING}=${formId}`
        );
      }
    } else {
      window.history.replaceState('', '', `${redirectUrl()}`);
      window.location.href = redirectUrl();
    }
    return null;
  };

  useEffect(() => {
    const { surveyForms, activeForm } = props;
    if (!isEmpty(surveyForms)) {
      let newSelectedForm = !isNull(activeForm)
        ? getSelectedForm(activeForm)
        : surveyForms[0];
      setCurrentSelectedForm(newSelectedForm);
    }
    window.addEventListener('popstate', e => redirectPrevForm(e), false);
    return () => {
      window.removeEventListener('popstate', redirectPrevForm, false);
    };
    // this useEffect will act as componentDidMount lifeCycle method
    // so adding the props on deps array is not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActiveTab = tab => {
    setActiveTab(tab);
    if (history.pushState) {
      window.history.pushState(
        '',
        '',
        `${redirectUrl(true)}${
          isEmpty(currentSelectedForm)
            ? window.location.search
            : `?${SURVEY_SUMMARY_QUERY_STRING}=${currentSelectedForm.id}`
        }`
      );
    }
  };

  const renderSurveyContent = () => {
    const {
      links: { fetchIndividualResponses },
      csrfToken,
      tenantTerms,
      surveyProjects,
      projectsEnabled,
    } = props;

    // Hotfix to ignore proptype errors.
    const defaultFeedbackFormProps = {
      userList: [],
    };

    const feedbackFormTableProps = {
      ...defaultFeedbackFormProps,
      csrfToken,
      showDeleteOption: true,
      selectedForm: currentSelectedForm,
      surveyProjects,
      projectsEnabled,
      links: {
        fetchUserResponses: fetchIndividualResponses,
        fetchFeedbackForms: '',
        getFeedbackForms: {},
      },
    };

    return activeTab === SURVEY_SUMMARY_TAB ? (
      <FeedbackSummary selectedForm={currentSelectedForm} {...props} />
    ) : (
      <FeedbackFormTable
        showImpressionsOverview
        isSurveyPage={true}
        {...feedbackFormTableProps}
        tenantTerms={tenantTerms}
      />
    );
  };

  const { surveyForms, links } = props;

  return (
    <div className="kt-content">
      <div className="p-4 mb-30">
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h3 className="">
            {`Survey ${
              !isEmpty(currentSelectedForm)
                ? `- ${currentSelectedForm['name']}`
                : ''
            }`}
          </h3>
          <div className="d-flex flex-wrap">
            <a
              className="btn btn-info text-white mr-2"
              target="_blank"
              href={`${links.exportResponses}?survey_form_id=${currentSelectedForm.id}`}
              rel="noreferrer"
            >
              Export
            </a>
            <div
              className="btn-group"
              role="group"
              style={{ backgroundColor: '#FFF' }}
            >
              <button
                type="button"
                className={`btn  ${
                  activeTab === SURVEY_SUMMARY_TAB
                    ? 'app-btn-primary'
                    : 'btn-outline-brand'
                }`}
                onClick={() => handleActiveTab(SURVEY_SUMMARY_TAB)}
              >
                Summary
              </button>
              <button
                type="button"
                className={`btn  ${
                  activeTab === SURVEY_RESPONSES_TAB
                    ? 'app-btn-primary'
                    : 'btn-outline-brand'
                }`}
                onClick={() => handleActiveTab(SURVEY_RESPONSES_TAB)}
              >
                Responses
              </button>
            </div>
          </div>
        </div>
      </div>
      {!isEmpty(surveyForms) && renderSurveyContent()}
    </div>
  );
};

FeedbackSurveyManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  projectsEnabled: PropTypes.bool.isRequired,
  links: PropTypes.shape({
    fetchSummary: PropTypes.string.isRequired,
    fetchIndividualResponses: PropTypes.string.isRequired,
    exportResponses: PropTypes.string.isRequired,
  }).isRequired,
  surveyForms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  surveyProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  tenantTerms: PropTypes.object.isRequired,
  activeForm: PropTypes.string,
};

FeedbackSurveyManager.defaultProps = {
  activeTab: SURVEY_SUMMARY_TAB,
};

export default withErrorHandler(FeedbackSurveyManager);

import React from 'react';
import fiveHunderedImg from './500.png';
import './style.scss';

const ServerError = () => {
  return (
    <div className="server-error-page">
      <img src={fiveHunderedImg} alt="500" />

      <h1 className='pb-4'>
        Oops! Don&apos;t panic!
      </h1>

      <h5 className="my-4 mx-auto custom-error-message">
        You&apos;re receiving this error because PERC-o-Manager timed out before you clicked the Mentor link. Go back to PERC-o-Manager and hit refresh on the web browser. Afterward, just click the link and you&apos;ll be teleported to Mentor with your credentials ready to sign in!
      </h5>
      <h5 className="mx-auto custom-error-message">
        If you&apos;re still having trouble with logging in, please contact the Franchise Community Line at <a href="mailto:tickets@biggby.com">tickets@biggby.com</a> or by calling <a href="tel:+1517-492-4521">517-492-4521</a>.
      </h5>
    </div>
  );
};

export default ServerError;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../common/inputs/Toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';

import Text from '../common/inputs/text';
import AssetManager from '../filestack/AssetManager';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
  renderOptions,
  addPageLeavePreventDialog,
} from '../common/utils';
import SubmitButton from '../common/presentational/submitButton';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import { sortAssetsInOrder } from '../filestack/utils/index.js';
import CancelButton from '../common/presentational/cancelButton';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import SelectInput from '../common/inputs/select';

class CourseModuleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      videos: [],
      existingVideos: props.existingVideos,
      videosOrder: [_.map(props.existingVideos, 'handle')], //it contains only handles
      attachments: [],
      existingAttachments: props.existingAttachments,
      attachmentsOrder: [_.map(props.existingAttachments, 'handle')], //it contains only handles
      [props.fieldsPrefix]: Object.assign({}, props.attributes),
    };
  }

  componentDidMount() {
    addPageLeavePreventDialog();
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  setModelAttributeState = (fieldName, value) => {
    const updatedModelAttributes = {
      ...this.state[this.props.fieldsPrefix],
      [fieldName]: value,
    };

    this.setState({
      [this.props.fieldsPrefix]: updatedModelAttributes,
    });
  };

  onVideoChange = (videos, existingVideos) => {
    let { videosOrder } = this.state;
    let newOrder = sortAssetsInOrder(videos, existingVideos, videosOrder);

    this.setState({
      videos: videos,
      existingVideos: existingVideos,
      videosOrder: newOrder,
    });
  };

  onAttachmentChange = (attachments, existingAttachments) => {
    let { attachmentsOrder } = this.state;
    let newOrder = sortAssetsInOrder(attachments, existingAttachments, attachmentsOrder);

    this.setState({
      attachments: attachments,
      existingAttachments: existingAttachments,
      attachmentsOrder: newOrder,
    });
  };

  getModelAttributeValue = attribute =>
    this.state[this.props.fieldsPrefix][attribute] || '';

  handleModelAttributeChange = (attributeName = '') => {
    return event => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, option => option.value)
          : event.value;

        this.setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        this.setModelAttributeState(targetName, targetValue);
      }
    };
  };

  onSortComplete = (assetType, assets) => {
    let handlesInOrder = _.map(assets, 'handle');

    this.setState({
      [assetType]: handlesInOrder,
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { courseId, courseModuleId, csrfToken, links } = this.props;

    const { videos, videosOrder, attachments, attachmentsOrder } = this.state;

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );

    // Remove description from submission data
    // Description will be edited and submitted from content builder in description edit view.
    delete updatedModelAttributes['description'];

    const videosAttributes = getAssetAttributes(videos);
    const attachmentsAttributes = getAssetAttributes(attachments);

    let Requester = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedModelAttributes,
          videos: videosAttributes,
          videos_order: videosOrder,
          attachments: attachmentsAttributes,
          attachments_order: attachmentsOrder,
        },
      })
      .onSuccess(response => {
        removePageLeavePreventDialog();
        window.location.href = _.get(
          response.data,
          'redirection_url',
          links.courseAdminContent
        );
      });

    if (_.isNil(courseModuleId)) {
      Requester = Requester.post(links.createModule);
    } else {
      Requester = Requester.patch(links.updateModule);
    }

    await Requester.exec();
  };

  render() {
    const {
      loading,
      videos,
      existingVideos,
      videosOrder,
      attachments,
      existingAttachments,
      attachmentsOrder,
    } = this.state;

    const { csrfToken, videoOptions, attachmentOptions, links, courseForumEnabled } = this.props;
    const { directUploadUrl } = links;

    if (loading) {
      return (
        <div className={'kt-portlet__body'}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={'kt-portlet__body'}>
        <form
          encType="multipart/form-data"
          className="kt-form kt-form--label-right kt-form--fit"
          onSubmit={this.handleSubmit}
        >
          <div className="kt-section kt-section--first">
            <FormRow label={'Name'}>
              <Text
                name={'name'}
                value={this.getModelAttributeValue('name')}
                onInputChange={this.handleModelAttributeChange('name')}
              />
            </FormRow>

            {courseForumEnabled && (
              <FormRow label={'Discussion Enabled?'}>
                <Toggle
                  name={'discussionEnabled'}
                  onClick={value => this.setModelAttributeState('discussionEnabled', value)}
                  on={<span>Yes</span>}
                  off={<span>No</span>}
                  size="sm"
                  width={100}
                  height={30}
                  recalculateOnResize
                  offstyle="default"
                  active={this.getModelAttributeValue('discussionEnabled')}
                />
              </FormRow>
            )}
          </div>

          <div className="kt-seperator kt-seperator--dashed" />
          <div className="kt-section">
            <AssetManager
              label={'Videos'}
              csrfToken={csrfToken}
              filestackOptions={videoOptions}
              existingAssets={existingVideos}
              assets={videos}
              onFileChange={this.onVideoChange}
              sortableGallery={true}
              sortOrder={videosOrder}
              onSortComplete={assets => this.onSortComplete('videosOrder', assets)}
            />
          </div>

          <div className="kt-section">
            <AssetManager
              label={'Attachments'}
              csrfToken={csrfToken}
              filestackOptions={attachmentOptions}
              existingAssets={existingAttachments}
              assets={attachments}
              onFileChange={this.onAttachmentChange}
              sortableGallery={true}
              sortOrder={attachmentsOrder}
              onSortComplete={assets => this.onSortComplete('attachmentsOrder', assets)}
            />
          </div>

          <div className="kt-seperator kt-seperator--dashed" />
          <div className="kt-section">
            <div className="col-lg-12 text-center">
              <CancelButton confirmCancel={false} />
              <SubmitButton buttonText={'Save'} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CourseModuleForm.defaultProps = {
  fieldsPrefix: 'course_module',
  attributes: {},
};

CourseModuleForm.propTypes = {
  fieldsPrefix: PropTypes.string,

  csrfToken: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  courseModuleId: PropTypes.number,

  videoOptions: PropTypes.object.isRequired,
  attachmentOptions: PropTypes.object.isRequired,

  attributes: PropTypes.object.isRequired,

  existingVideos: PropTypes.array.isRequired,
  existingAttachments: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    courseAdminContent: PropTypes.string.isRequired,
    createModule: PropTypes.string.isRequired,
    updateModule: PropTypes.string.isRequired,
    directUploadUrl: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseModuleForm);

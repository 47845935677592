import React from 'react';
import SelectInput from './select';
import { IMPRESSION_OPTIONS } from '../../forms/constants';
import { getSentimentColor } from '../utils';

const renderSentimentLabel = sentimentLabel => (
  <span className={`font-12 kt-font-${getSentimentColor(sentimentLabel)}`}>
    <i className="fa fa-circle mr-2 font-10" />
    {sentimentLabel}
  </span>
);

const SentimentSelect = props => (
  <SelectInput
    placeholder="Select an Impression"
    options={IMPRESSION_OPTIONS}
    optionIdentifier="value"
    formatOptionLabel={({ value }) => renderSentimentLabel(value)}
    {...props}
  />
);

export default SentimentSelect;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mention, MentionsInput } from 'react-mentions';
import AssetManager from '../filestack/AssetManager';
import InitialsAvatar from '../common/Avatar';
import { SMS_CHARACTER_LIMIT } from '../common/constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

class CommentForm extends Component {
  constructor(props) {
    super(props);
  }

  escFunction = event => {
    if (event.keyCode === 27 && 'function' === typeof this.props.handleCancel) {
      this.props.handleCancel();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  onCommentChange = (event, newValue, newPlainTextValue, mentions) => {
    this.props.onCommentChange(newValue, _.uniq(_.map(mentions, 'id')));
  };

  getTaggableData = () => {
    const { taggableUsers } = this.props;

    return _.map(taggableUsers, ({ id, name, avatar }) => ({
      id: id,
      display: name,
      avatar: avatar,
    }));
  };

  render() {
    const {
      renderUploader,
      csrfToken,
      onFileChange,
      assets,
      existingAssets,
      formElementId,
      getModelAttributeValue,
      showMultipleSmsAlert,
      termTicketMessages,
      loading
    } = this.props;

    const commentBody = getModelAttributeValue('body');
    return (
      <div className="kt-chat">
        <div className="kt-chat__input">
          <div className="kt-chat__editor" id={`element_${formElementId}`}>
            <MentionsInput
              name={'body'}
              className="comment-mentions"
              placeholder={`Enter ${
                !_.isUndefined(termTicketMessages)
                  ? termTicketMessages.singular
                  : 'Comment'
              } Here`}
              rows={5}
              value={commentBody}
              onChange={this.onCommentChange}
            >
              <Mention
                appendSpaceOnAdd={true}
                trigger="@"
                data={this.getTaggableData()}
                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                  <a className="kt-quick-search__item">
                    <span className="kt-quick-search__item-icon">
                      <InitialsAvatar
                        className="kt-img-rounded"
                        url={suggestion.avatar}
                        name={suggestion.display}
                        size="small"
                      />
                    </span>
                    <span className="kt-quick-search__item-text">
                      {suggestion.display}
                    </span>
                  </a>
                )}
                onAdd={this.onAdd}
              />
            </MentionsInput>
            {showMultipleSmsAlert &&
              !!commentBody &&
              commentBody.length > SMS_CHARACTER_LIMIT && (
                <p className="font-12 text-darkred">
                  *Content will be sent in Multiple (&nbsp;
                  <strong>{Math.ceil(commentBody.length / SMS_CHARACTER_LIMIT)}</strong>
                  &nbsp;) SMSes
                </p>
              )}
          </div>

          {!_.isEmpty(_.flatten([...assets, existingAssets])) && (
            <div className="my-2">
              <AssetManager
                showUploader={false}
                assets={assets}
                existingAssets={existingAssets}
                onFileChange={onFileChange}
                csrfToken={csrfToken}
                showGalleryInListView={true}
              />
            </div>
          )}

          <div className="kt-separator kt-separator--dashed kt-separator--sm" />
          <div className="kt-chat__toolbar">
            <div className="kt_chat__tools">
              <AssetManager
                filestackOptions={{
                  fromSources: ['local_file_system'],
                  maxFiles: 10,
                  rootId: 'new-drop-pane',
                }}
                csrfToken={csrfToken}
                showLabel={false}
                showGallery={false}
                renderUploader={renderUploader}
                onFileChange={onFileChange}
                assets={assets}
                existingAssets={existingAssets}
              />
            </div>
            <div className="kt_chat__actions">
              <button
                type="button"
                className="btn btn-outline-info btn-elevate btn-pill btn-md"
                onClick={this.props.handleCancel}
              >
                Cancel
              </button>
              &nbsp;
              <button
                type="button"
                className="app-btn-primary btn-md btn-elevate btn-pill kt-chat__reply"
                onClick={this.props.handleSubmit}
                disabled={loading}
              >
                {_.isNull(getModelAttributeValue('id')) ? 'Submit' : 'Update'}
              </button>
            </div>
          </div>

          <br />
        </div>
      </div>
    );
  }
}

CommentForm.propTypes = {
  handleCancel: PropTypes.func,
  getModelAttributeValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  taggableUsers: PropTypes.array.isRequired,
  formElementId: PropTypes.number,
  showMultipleSmsAlert: PropTypes.bool,
  loading: PropTypes.bool,
};

CommentForm.defaultProps = {
  formElementId: null,
  showMultipleSmsAlert: false,
  loading: false,
};

export default withErrorHandler(CommentForm);

export const USER_ROLES = ['Member', 'Reviewer', 'Admin'];

export const ALERT_CLASSES = {
  error: 'alert-danger',
  alert: 'alert-warning',
  notice: 'alert-info',
  success: 'alert-success',
};

// in ms
export const ALERT_TIMEOUTS = {
  error: 7000,
  alert: 7000,
  notice: 7000,
  success: 7000,
};

export const ALERT_DIALOG_WRAPPER_STYLE = {
  display: 'inline-block',
  margin: '10px auto',
  transition: 'all 0.5s ease-in-out',
  zIndex: '99999',
  animationIterationCount: '1',
};

export const ALERT_DIALOG_BUTTON_STYLE = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  marginRight: '0',
  zIndex: '100902',
};

export const REACT_FLASH_CONTAINER_WINDOW_REF_KEY = 'react_flash_messages';

export const PERCENTS_AS_OPTIONS = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
].map(per => ({
  label: per,
  value: per,
}));

export const SIMPLE_WYSIWYG_EDITOR_OPTIONS = [
  'inline',
  'blockType',
  'fontSize',
  'fontFamily',
  'list',
  'textAlign',
  'link',
];

export const SMS_CHARACTER_LIMIT = 160;

export const SENTIMENT_COLOR_MAP = {
  positive: 'success',
  neutral: 'warning',
  negative: 'danger',
};

export const CUSTOMER_SEARCH_ATTRIBUTE_OPTIONS = Object.freeze([
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
  { label: 'Phone', value: 'phoneNumber' },
]);

/**
 * To be used as default values only for project select dropdown in public forms with @customFilterOptions
 */
export const DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS = Object.freeze([
  'name',
  'address',
  'address_2',
  'city',
  'state',
  'zip_code',
]);

// Global date and time format to be followed in entire app
export const DATE_TIME_FORMATS = Object.freeze({
  date: 'MM/DD/YYYY',
  dateTime: 'MM/DD/YYYY hh:mm a',
  time: 'hh:mm a',
});

// DatePicker specific -Global date and time format to be followed in entire app
export const DATE_TIME_PICKER_FORMATS = Object.freeze({
  date: 'MM/dd/yyyy',
  dateTime: 'MM/dd/yyyy hh:mm aa',
  time: 'hh:mm aa',
});

export const DATE_TIME_FORMAT_BY_MONTH = Object.freeze({
  date: 'MMM Do',
  time: 'hh:mm A',
});

import React from 'react';
import PropTypes from 'prop-types';

import { NewFile, NewFolder } from '../../FolderActionComponents';
import MyFolderList from './MyFolderList';
import SharedFolderList from './SharedFolderList';
import AssetsFilterOptions from './AssetsFilterOptions';
import AppFolders from './AppFolders';

import '../../../styles/folderSidebar.scss';

const FolderSidebar = ({
  fileProps,
  folderProps,
  showAssetFilterOption,
  showNewContentOption,
}) => {
  const { handlers: fileHandlers } = fileProps;
  const {
    handlers: folderHandlers,
    data: {
      myFolders,
      sharedFolders,
      appFoldersConfig: {
        displayTerms: appFolderDisplayTerms,
        foldersDisplayConfig: appFoldersDisplayConfig,
      },
    },
    predicates: { updateEnabler: folderUpdateEnablerPredicate },
  } = folderProps;

  return (
    <div className="kt-widget24">
      <div className="ibox float-e-margins">
        <div className="ibox-content">
          <div className="file-manager">
            {showNewContentOption && (
              <div className="mt-20 mb-20 ml-15 mr-15">
                <div className="dropdown">
                  <button
                    className="app-btn-primary btn-block dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    New
                  </button>

                  <div className="dropdown-menu w-100" x-placement="bottom-start">
                    <NewFile
                      onFileUploadSuccess={fileHandlers.onFileUploadSuccess}
                      onFileUploadFailure={fileHandlers.onFileUploadFailure}
                    />
                    <NewFolder onNewFolderClick={folderHandlers.onNewFolderClick} />
                  </div>
                </div>
              </div>
            )}

            {showAssetFilterOption && (
              <div className="pl-15 mb-30 f-types">
                <AssetsFilterOptions
                  onFilterClick={assetType => {
                    fileHandlers.onAssetFilterClick({ assetType });
                  }}
                />
              </div>
            )}

            <h6 className="mb-10 pl-15">Folders</h6>
            <ul className="folder-list mb-30">
              {!_.isEmpty(myFolders) && (
                <MyFolderList
                  folders={myFolders}
                  onFolderRenameClick={folderHandlers.onRenameClick}
                  onFolderShareClick={folderHandlers.onShareClick}
                  folderUpdateEnablerPredicate={folderUpdateEnablerPredicate}
                />
              )}

              {!_.isEmpty(sharedFolders) && (
                <SharedFolderList
                  folders={sharedFolders}
                  onFolderRenameClick={folderHandlers.onRenameClick}
                  onFolderShareClick={folderHandlers.onShareClick}
                  folderUpdateEnablerPredicate={folderUpdateEnablerPredicate}
                />
              )}
            </ul>

            <AppFolders
              displayTerms={appFolderDisplayTerms}
              displayConfig={appFoldersDisplayConfig}
            />
            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

FolderSidebar.propTypes = {
  showNewContentOption: PropTypes.bool,
  showAssetFilterOption: PropTypes.bool,
  fileProps: PropTypes.shape({
    handlers: PropTypes.shape({
      onFileUploadSuccess: PropTypes.func.isRequired,
      onFileUploadFailure: PropTypes.func.isRequired,
      onAssetFilterClick: PropTypes.func.isRequired,
    }),
  }).isRequired,

  folderProps: PropTypes.shape({
    handlers: PropTypes.shape({
      onNewFolderClick: PropTypes.func.isRequired,
      onRenameClick: PropTypes.func.isRequired,
      onShareClick: PropTypes.func.isRequired,
    }),
    predicates: PropTypes.shape({
      updateEnabler: PropTypes.func.isRequired,
    }),
    data: PropTypes.shape({
      myFolders: PropTypes.array.isRequired,
      sharedFolders: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

FolderSidebar.defaultProps = {
  showNewContentOption: true,
  showAssetFilterOption: true,
  fileProps: {},
  folderProps: {
    handlers: {},
    predicates: {},
    data: {
      folders: [],
      sharedFolders: [],
    },
  },
};

export default FolderSidebar;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetManager from '../../filestack/AssetManager';
import Spinner from '../../common/presentational/spinner';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import camelCase from '../../common/dataFormatters/camelCase';

class QuizQuestionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isCollapsed: true,
    };
  }

  handleQuestionDelete = question => {
    return async event => {
      const questionId = question.id;
      const { courseQuizId, csrfToken } = this.props;

      let Requester = await new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .delete(question.links.deleteQuestion)
        .onSuccess(rs => {
          this.props.onDelete();
        })
        .exec();
    };
  };

  handleQuestionDuplicate = question => {
    return async event => {
      event.stopPropagation();
      const { csrfToken } = this.props;

      this.setState({ loading: true });

      try {
        const response = await new Http(this)
          .setToken(csrfToken)
          .post(question.links.duplicate)
          .exec();

        if (response.data) {
          const {
            id,
            title,
            position,
            answer_options,
            links,
            existingAttachments
          } = response.data;

          this.props.onDuplicate({
            id,
            title,
            position,
            links: camelCase(links),
            existingAttachments,
            answerOptions: answer_options,
          });
        }
      } catch (error) {
        console.error('Error duplicating question:', error);
      } finally {
        this.setState({ loading: false });
      }
    };
  };

  toggleCollapse = () => {
    this.setState(prevState => ({
      ...prevState,
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  render() {
    const { loading, isCollapsed } = this.state;

    if (loading) {
      return <Spinner />;
    }

    const {
      idx,
      onEdit,
      question,
      quizQuestionConfig,
      answerOptionConfig,
      csrfToken,
      tenantTerms,
      isDragging,
    } = this.props;
    const { answerOptions } = question;

    const shouldShow = !this.props.forceCollapse && !isCollapsed;

    return (
      <div
        className="card"
        style={isDragging ? { border: '3px solid #eee' } : {}}
        onClick={this.toggleCollapse}
      >
        <div className="card-header">
          <div className={`card-title ${shouldShow ? '' : 'collapsed'}`}>
            <i
              className="fas fa-grip-vertical"
              style={{ fontSize: '1rem' }}
            ></i>
            {idx + 1}. {question.title}
            <button
              onClick={this.handleQuestionDuplicate(question)}
              className="btn btn-sm btn-outline-primary ml-2"
              title="Duplicate question"
            >
              <i className="la la-copy"></i>
            </button>
          </div>
        </div>
        <div className={`collapse ${shouldShow ? 'show' : ''}`}>
          <div className="card-body">
            <div className={'text-right'}>
              <button onClick={onEdit} className="app-btn-primary btn-elevate btn-pill">
                <span>
                  <i className="la la-edit" />
                  <span>Edit</span>
                </span>
              </button>
              {'  \t'}
              <button
                onClick={this.handleQuestionDelete(question)}
                className="btn btn-darkred btn-elevate btn-pill"
              >
                <span>
                  <i className="la la-trash" />
                  <span>Delete</span>
                </span>
              </button>
            </div>
            <br />
            <AssetManager
              showGallery
              showLabel={false}
              showUploader={false}
              csrfToken={csrfToken}
              filestackOptions={quizQuestionConfig.attachmentOptions || []}
              existingAssets={question.existingAttachments || []}
              assets={question.attachments}
              onFileChange={() => {}}
            />
            {!_.isEmpty(question.existingAttachments) && (
              <div className="kt-separator kt-separator--dashed" />
            )}
            {_.map(answerOptions, (option, inx) => {
              return (
                <div
                  key={option.id}
                  className={option.correct ? 'correct_option' : 'option'}
                >
                  <h4>
                    <b>{inx + 1}.</b> {option.title}
                    {option.correct ? <i className="la la-check" /> : null}
                  </h4>
                  <AssetManager
                    showGallery
                    showLabel={false}
                    showUploader={false}
                    csrfToken={csrfToken}
                    filestackOptions={answerOptionConfig.attachmentOptions || []}
                    existingAssets={option.existingAttachments || []}
                    assets={option.attachments}
                    onFileChange={() => {}}
                  />
                  <br />
                  <br />
                </div>
              );
            })}
          </div>
        </div>
      </div >
    );
  }
}

QuizQuestionItem.propTypes = {
  isDragging: PropTypes.bool,
  forceCollapse: PropTypes.bool,
  courseQuizId: PropTypes.number.isRequired,
  idx: PropTypes.number.isRequired,
  question: PropTypes.object.isRequired,
  quizQuestionConfig: PropTypes.object.isRequired,
  answerOptionConfig: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  onDuplicate: PropTypes.func.isRequired,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(QuizQuestionItem);

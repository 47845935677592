import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import { AssetListItem } from './AssetListItem';

const AssetsAsList = props => {
  const { assets, handleAssetDelete } = props;

  return (
    <div className="img-grid">
      <div className="kt-widget4">
        {map(assets, (asset, index) => (
          <AssetListItem
            key={`asset_${asset.handle}_${index}`}
            asset={asset}
            handleAssetDelete={handleAssetDelete}
          />
        ))}
      </div>
    </div>
  );
};

AssetsAsList.defaultProps = {
  assets: [],
  existingAssets: [],
};
AssetsAsList.propTypes = {
  assets: PropTypes.array,
  existingAssets: PropTypes.array,
  handleAssetDelete: PropTypes.func,
};

export default AssetsAsList;

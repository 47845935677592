import React from 'react';
import PropTypes from 'prop-types';

import keys from 'lodash/keys';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import snakeCase from 'lodash/snakeCase';
import find from 'lodash/find';

import { sortProjects, projectSortListOptions, getFullAddress } from '../utils';
import DropdownButton from '../../common/presentational/dropDownButton';
import CategoryHeader from './CategoryHeader';
import './styles.scss';
import ProjectActionButtons from '../ProjectActionButtons';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ProjectsCategoryGroup = props => {
  const [projectFilterText, setProjectFilterText] = React.useState(null);
  const [currentSortBy, setCurrentSortBy] = React.useState(null);
  let sortedList = props.projectList;
  let categoriesList = React.useMemo(
    () => keys(props.projectList),
    [props.projectList]
  );

  const dropDownTitle = React.useMemo(
    () =>
      currentSortBy !== null
        ? find(projectSortListOptions, ({ value }) => value === currentSortBy)[
            'label'
          ]
        : 'Sort by',
    [currentSortBy]
  );

  const onInputChange = event => {
    const { target } = event;
    if (!isNil(target.name)) {
      setProjectFilterText(target.value);
    }
  };

  const handleSortBy = event => {
    if (event && event.target) {
      setCurrentSortBy(event.target.value);
    }
  };

  const sortedProjectList = React.useCallback(
    categoryName => {
      let currentCatProjectList = sortedList[categoryName];

      return sortProjects(currentSortBy, currentCatProjectList);
    },
    [currentSortBy, sortedList]
  );

  const renderProjectList = _sortedProjectList => {
    const { csrfToken } = props;
    return map(_sortedProjectList, project => {
      const { name, links, code, status } = project;

      return (
        <div
          key={project.id}
          className="kt-task_row--item project_category_row_item"
          style={{ borderBottom: '1px dashed #e1e3e5' }}
        >
          <div className="kt-task_row--name" title={name}>
            <span className="kt-widget4__text font-14 pl-15">{code}</span>
          </div>

          <div className="kt-task_row--name" title={name}>
            <span className="kt-widget4__text font-14">
              <a href={links.show} className="text-truncate kt-font-dark">
                <b>{name}</b>
              </a>
              <p>{getFullAddress(project)}</p>
            </span>
          </div>

          <div className="kt-task_row--status" title={name}>
            <span className="kt-widget4__text font-14 pl-15">
              <span className={`project-status__dot ${status}`} />
              <span className="text-capitalize">{status}</span>
            </span>
          </div>

          <div className="kt-task_row--options">
            <ProjectActionButtons links={links} csrfToken={csrfToken} />
          </div>
        </div>
      );
    });
  };

  const renderCategoryProjectsGroup = () => {
    const {
      tenantTerms: { termProject },
    } = props;

    const catHeaderList = categoriesList;
    if (!isEmpty(catHeaderList)) {
      catHeaderList.sort().reverse();
    }

    const hasOnlyNaProjects =
      isEqual(catHeaderList.length, 1) &&
      isEqual(catHeaderList[0].toLowerCase(), 'na');

    if (!isNil(catHeaderList) && !isNil(sortedList)) {
      return map(catHeaderList, catName => {
        let sortedProjectListArray = sortedProjectList(catName);

        if (!isNil(projectFilterText) && !isEmpty(projectFilterText)) {
          sortedProjectListArray = filter(sortedProjectListArray, project =>
            project.name.toLowerCase().includes(projectFilterText.toLowerCase())
          );
        }

        const showCategorySectionHeader =
          !isEmpty(sortedProjectListArray) ||
          !isEqual(catName.toLowerCase(), 'na') ||
          hasOnlyNaProjects;

        const snakeCasedCatName = snakeCase(catName);

        return (
          <div
            key={snakeCasedCatName}
            className="kt-section category_tasks_section category_projects accordion accordion-solid accordion-toggle-plus"
            style={{ minHeight: showCategorySectionHeader ? '100px' : '0' }}
            id={`${snakeCasedCatName}_tasks_section`}
          >
            <div className="card">
              {showCategorySectionHeader && (
                <CategoryHeader catName={catName} />
              )}
              <div
                id={`${snakeCasedCatName}_list`}
                className="kt-widget4 collapse show"
                aria-labelledby={`${snakeCasedCatName}Header`}
                data-parent={`#${snakeCasedCatName}_tasks_section`}
              >
                {!isEmpty(sortedProjectListArray) ? (
                  <div className="card-body">
                    {renderProjectList(sortedProjectListArray)}
                  </div>
                ) : !isEqual(catName.toLowerCase(), 'na') ||
                  hasOnlyNaProjects ? (
                  <div className="text-center p-5">
                    <h3>No {termProject.plural}</h3>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="text-center p-5">
          <h3>No {termProject.plural}</h3>
        </div>
      );
    }
  };

  const {
    tenantTerms: { termProject },
  } = props;

  return (
    <React.Fragment>
      <div className=" pt-0">
        {!isEmpty(categoriesList) && (
          <div className="m-2">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-3 col-md-4 col-sm-8 d-flex justify-content-center ml-15 mr-15 mt-10 mb-10">
                <input
                  style={{
                    height: '37px',
                  }}
                  onChange={onInputChange}
                  type="text"
                  title="Search by name"
                  className="form-control kt-input"
                  placeholder="Search by Name"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-8 ml-15 mr-15 mt-10 mb-10">
                <DropdownButton
                  // dropDown title is label of currentSortBy item from projectSortListOptions array
                  dropdownTitle={dropDownTitle}
                  onClick={handleSortBy}
                  dropdownValues={projectSortListOptions}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="kt-content plr-0 pb-0">
        {renderCategoryProjectsGroup()}
        {isEmpty(categoriesList) && (
          <div className="text-center p-5">
            <h4>No {termProject.plural}</h4>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

ProjectsCategoryGroup.propTypes = {
  csrfToken: PropTypes.string,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  projectList: PropTypes.object,
};

export default withErrorHandler(ProjectsCategoryGroup);

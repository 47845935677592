import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import QuizGallery from '../user_quiz/QuizGallery';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { getUrlQueryParamsAsObject } from '../../common/utils';
import SelectInput from '../../common/inputs/select';

class QuizResultsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quizQuestions: [],
      userAnswers: {},
      quizFinisherName: props.currentUserName,
      fetchingQuestions: false,
      loading: false,
      paginationData: {
        currentPage: 0,
        lastPage: false,
      },
      selectedAttemptId: props.allCompletedAttempts[0]?.id,
    };
    this.attemptOptions = Array.from(
      Array(props.allCompletedAttempts.length),
      (_x, index) => ({
        label: `Attempt ${props.allCompletedAttempts.length - index} - ${
          props.allCompletedAttempts[index].finalized ? 'Finalized' : 'Rejected'
        } `,
        value: props.allCompletedAttempts[index].id,
      })
    );
    this.quizResultsWrapperRef = React.createRef();
  }

  componentDidMount() {
    if (!this.quizResultsWrapperRef) {
      this.quizResultsWrapperRef = React.createRef();
    }
    this.quizResultsWrapperRef.current.addEventListener(
      'scroll',
      _.throttle(this.handleScroll, 250)
    );

    this.fetchQuizQuestions();
  }

  componentWillUnmount() {
    if (this.quizResultsWrapperRef) {
      this.quizResultsWrapperRef.current.removeEventListener(
        'scroll',
        this.handleScroll
      );
    }
  }

  handleScroll = () => {
    const { fetchingQuestions } = this.state;
    const isBottom =
      this.quizResultsWrapperRef.current.scrollTop >
      this.quizResultsWrapperRef.current.scrollHeight -
        this.quizResultsWrapperRef.current.offsetHeight -
        10;

    if (isBottom && !fetchingQuestions) {
      this.fetchQuizQuestions();
    }
  };

  handleSelectAttempt = ({ value }) => {
    this.setState({ selectedAttemptId: value });
  };

  fetchQuizQuestions = async () => {
    const {
      links: { results },
      csrfToken,
    } = this.props;

    const {
      paginationData: { currentPage, lastPage },
    } = this.state;

    if (lastPage) return; // Avoid making an API call when all the questions are fetched.

    const queryParams = getUrlQueryParamsAsObject();

    await new Http(this)
      .onBegin(() => {
        this.setState({
          fetchingQuestions: true,
          ...(_.isEqual(currentPage, 0) && { loading: true }), // Sets true and show page blocking Spinner only for first load.
        });
      })
      .setToken(csrfToken)
      .get(results)
      .setQueryParams({
        page: currentPage + 1,
        user_id: queryParams['user_id'],
      })
      .onSuccess(res => {
        const { data } = res;
        this.setState(prevState => ({
          fetchingQuestions: false,
          loading: false,
          quizQuestions: [
            ...prevState.quizQuestions,
            ...data['quiz_questions_json'],
          ],
          userAnswers: merge({}, prevState.userAnswers, data['user_answers_grouped_by_attempts']),
          quizFinisherName: data['quiz_finisher_name'],
          paginationData: data.meta.pagination,
        }));
      })
      .onError(err => {
        console.log(err);
        this.setState({
          loading: false,
          fetchingQuestions: false,
        });
      })
      .exec();
  };

  renderQuestionsWithAnswers = () => {
    const { quizQuestions, userAnswers, selectedAttemptId } = this.state;

    return _.map(quizQuestions, (question, index) => {
      // If User has an answer for given question display
      // the question title from correct version when answered
      // else display original question title
      const userAnswerForQuestion = userAnswers[selectedAttemptId][question.id];

      const correctVersionQuestionTitle = !_.isEmpty(userAnswerForQuestion)
        ? userAnswerForQuestion['versioned_question']['title']
        : question['title'];

      return (
        <React.Fragment key={question.id}>
          <div className="px-5 py-3">
            <div className="d-flex">
              <h3 className="pr-10">{`${_.padStart(index + 1, 2, 0)}.`}</h3>
              <div>
                <h3 className=" mb-20">{correctVersionQuestionTitle}</h3>
                <QuizGallery
                  hideFileInfo
                  assets={question.existingAttachments || []}
                />
                {_.map(question.answerOptions, (option, index) => {
                  const isOptionSelectedByUser =
                    !_.isEmpty(userAnswerForQuestion) &&
                    _.includes(
                      userAnswerForQuestion['selected_option_ids'],
                      option.id
                    );
                  return (
                    <div
                      className={`d-flex ${
                        option.correct
                          ? 'kt-font-success'
                          : isOptionSelectedByUser
                          ? 'text-darkred'
                          : ''
                      }`}
                      key={option.id}
                    >
                      <h4 className="pr-10">{`${String.fromCharCode(
                        index + 97
                      )}.`}</h4>
                      <div className="d-flex flex-column">
                        <h4>
                          {option.title}
                          <span className="px-2">
                            {isOptionSelectedByUser ? (
                              option.correct ? (
                                <i className="fa fa-check" />
                              ) : (
                                <i className="fa fa-times" style={{ position: 'relative', top: '2px' }} />
                              )
                            ) : null}
                          </span>
                          {isOptionSelectedByUser && !option.correct && !!option.incorrectMessage && (
                            <span className="kt-font-info" style={{ fontSize: '1.2rem', fontStyle: 'italic' }}>({option.incorrectMessage})</span>
                          )}
                        </h4>
                        <QuizGallery
                          hideFileInfo
                          assets={option.existingAttachments || []}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="kt-separator kt-separator--dashed" />
        </React.Fragment>
      );
    });
  };

  render() {
    const { courseQuizName, allCompletedAttempts } = this.props;
    const {
      quizQuestions,
      fetchingQuestions,
      loading,
      quizFinisherName,
      selectedAttemptId,
    } = this.state;

    const isCompletedAttemptsEmpty = _.isEmpty(allCompletedAttempts);

    return (
      <div className="kt-portlet">
        <div className="kt-portlet__head flex-wrap">
          <div className="kt-portlet__head-label flex-wrap">
            <h3 className="kt-portlet__head-title pr-10">{courseQuizName}</h3>
            <div className="my-2" style={{ width: 200 }}>
              <SelectInput
                placeholder={'Select Attempt'}
                name={'selectedAttemptId'}
                options={this.attemptOptions}
                value={selectedAttemptId}
                onChange={this.handleSelectAttempt}
                isClearable={false}
                optionIdentifier="value"
                isDisabled={isCompletedAttemptsEmpty}
              />
            </div>
          </div>
          <div className="kt-portlet__head-toolbar">{quizFinisherName}</div>
        </div>
        {loading && <Spinner />}
        <div
          className="kt-portlet__body"
          ref={this.quizResultsWrapperRef}
          style={{ maxHeight: '90vh', overflowY: 'auto' }}
        >
          {isCompletedAttemptsEmpty ? (
            <div className="kt-portlet__body">No Attempts Yet!</div>
          ) : (
            <div>
              {!_.isEmpty(quizQuestions) ? (
                <React.Fragment>
                  {this.renderQuestionsWithAnswers()}
                  {fetchingQuestions && (
                    <div className="text-center">
                      <span>
                        Fetching more questions...
                        <div
                          className="kt-spinner kt-spinner--brand"
                          style={{ width: 30, display: 'inline-block' }}
                        />
                      </span>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <h4>No Questions found!</h4>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

QuizResultsPage.propTypes = {
  allCompletedAttempts: PropTypes.array.isRequired,
  currentUserName: PropTypes.string.isRequired,
  courseQuizName: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    results: PropTypes.string.isRequired,
  }).isRequired,
};

export default withErrorHandler(QuizResultsPage);

import React, {
  createContext,
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Http from '../../common/Http';

import TextInput from '../../common/inputs/text';
import Spinner from '../../common/presentational/spinner';
import StatusModal from './StatusModal';
import TopicsTable from './TopicsTable';
import CategorySelect from './CustomCategorySelect';
import ForumCloningContextProvider from '../../common/ContextProvider';

import { forumCloneReducer, forumTableReducer } from './forumCloneReducer';
import { transduce } from '../../common/utils';
import { getFilterParams } from '../utils';
import '../forum.scss';

const INITIAL_FORUM_CLONING_DATA_STATE = {
  topicData: [],
  categories: [],
  categoryFilter: getFilterParams({ key: 'categoryId', parseToInt: true }),
  topicFilter: getFilterParams({ key: 'topic' }),
};

const INITIAL_TOPICS_TABLE_STATE = {
  activeRow: 0,
  selectedTopicIds: [],
};

export const ForumCloningContext = createContext();

const CloneForumView = ({
  courseName,
  csrfToken,
  links,
  tenantTerms,
  sourceCourseName,
}) => {
  const [forumCloningDataState, dispatchDataOperation] = useReducer(
    forumCloneReducer,
    INITIAL_FORUM_CLONING_DATA_STATE
  );
  const [topicsTableState, dispatchTopicsTable] = useReducer(
    forumTableReducer,
    INITIAL_TOPICS_TABLE_STATE
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isViewStatusModalOpen, setIsViewStatusModalOpen] = useState(false);

  const toggleLoader = () => setIsLoading(prevData => !prevData);

  const Requester = new Http()
    .onBegin(() => toggleLoader())
    .setToken(csrfToken)
    .useAlerts()
    .useAPIDataFormatters({
      snakifyRequestData: true,
      camelizeResponseData: true,
    })
    .onError(() => {
      toggleLoader();
    });

  const value = useMemo(
    () => ({
      forumDataState: forumCloningDataState,
      dispatchForumDataOperation: dispatchDataOperation,
      forumTopicsTableState: topicsTableState,
      dispatchTopicTableOperation: dispatchTopicsTable,
    }),
    [
      forumCloningDataState,
      dispatchDataOperation,
      topicsTableState,
      dispatchTopicsTable,
    ]
  );

  const { topicData, categoryFilter, topicFilter } = forumCloningDataState;
  const { selectedTopicIds } = topicsTableState;

  const getTopicFilteredData = topic => {
    const lowerCasedSelectedTopic = topicFilter.toLowerCase();
    const lowerCasedTopic = topic.title.toLowerCase();

    if (!lowerCasedSelectedTopic) return true;
    return lowerCasedTopic.includes(lowerCasedSelectedTopic);
  };

  const getCategoryFilteredData = topic => {
    if (!categoryFilter) return true;
    return topic.categoryId === categoryFilter;
  };

  const filtered = transduce(
    getTopicFilteredData,
    getCategoryFilteredData
  )({ payload: topicData });

  const getForumCloneData = link => {
    Requester.doesRedirect(false).onSuccess(({ data: { data } }) => {
      dispatchDataOperation({
        type: 'setForumData',
        payload: {
          topicData: data['cloneableTopics'],
          categories: data['topicsCategories'],
        },
      });
      toggleLoader();
    });

    Requester.get(link).exec();
  };

  const initiateCloning = link => {
    Requester.doesRedirect(true)
      .setPostData({
        selectedTopics: selectedTopicIds,
      })
      .onSuccess(({ data }) => {
        toggleLoader();
        setIsViewStatusModalOpen(true);
        setTimeout(() => {
          setIsViewStatusModalOpen(false);
          window.location.href = data.meta.redirectUri;
        }, 8000);
      });

    Requester.post(link).exec();
  };

  useEffect(() => {
    const { cloneableTopicsWithCategories } = links;
    getForumCloneData(cloneableTopicsWithCategories);

    // getForumCloneData handler shares the common Requester object
    // adding it in dependency array causes multiple callbacks, which breaks the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [links]);

  return (
    <ForumCloningContextProvider
      value={value}
      ComponentContext={ForumCloningContext}
    >
      <h3 className="mb-3">
        Forum Cloning from{' '}
        <strong className="app-text-primary">{sourceCourseName}</strong>{' '}
        {tenantTerms.termCourse.singular} to{' '}
        <strong className="app-text-primary">{courseName}</strong>{' '}
        {tenantTerms.termCourse.singular}
      </h3>
      <div className="forum-container text-black-50">
        <div className="d-flex align-items-start justify-content-between forum-header__tools">
          <div className="d-flex align-items-start my-2 w-100">
            <div className="col-md-6 pl-0">
              <TextInput
                placeholder="Search by topic"
                value={topicFilter}
                name="topicFilter"
                onInputChange={({ target: { value } }) => {
                  dispatchDataOperation({
                    type: 'setTopicFilter',
                    payload: { topicFilter: value },
                  });
                }}
              />
            </div>
            <div className="col-md-6 pr-0">
              <CategorySelect termCourse={tenantTerms.termCourse} />
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-end w-100">
            <div className="badge badge-primary mr-4">
              Forum Cloning is a one-time process
            </div>
          </div>
        </div>
        {isLoading && <Spinner />}
        {filtered && (
          <Fragment>
            <div className="text-black-50">
              Try <span>&uarr;</span> & <span>&darr;</span> to Switch between
              Topics and Enter(<span>&crarr;</span>) to Select Topic
            </div>
            <TopicsTable filteredData={filtered} />
          </Fragment>
        )}
        <button
          onClick={() => {
            window.location.href = links.postCloningRedirectionPath;
          }}
          className="app-btn-secondary floating-skip-button"
        >
          Skip Cloning and Proceed
        </button>
        <button
          onClick={() => initiateCloning(links.initiateCloning)}
          className="app-btn-primary floating-clone-button"
          disabled={
            !isEmpty(forumCloningDataState.topicData) &&
            isEmpty(selectedTopicIds)
          }
          title={
            isEmpty(selectedTopicIds)
              ? 'Select Topics to Initiate Clone'
              : 'Initiate Clone'
          }
        >
          Initiate Cloning
        </button>
      </div>
      {isViewStatusModalOpen && (
        <StatusModal termCourse={tenantTerms.termCourse} />
      )}
    </ForumCloningContextProvider>
  );
};

CloneForumView.propTypes = {
  courseName: PropTypes.string.isRequired,
  sourceCourseName: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    cloneableTopicsWithCategories: PropTypes.string.isRequired,
    initiateCloning: PropTypes.string.isRequired,
    postCloningRedirectionPath: PropTypes.string.isRequired,
  }),
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
};

export default CloneForumView;

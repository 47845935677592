import React from 'react';
import PropTypes from 'prop-types';

import keys from 'lodash/keys';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import MultiSelect from '../common/inputs/multiSelect';
import Text from '../common/inputs/text';
import Select from '../common/inputs/select';
import DynamicMultiSelect from '../common/inputs/dynamicMultiSelect';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import AssetManager from '../filestack/AssetManager';
import Http from '../common/Http';
// import BusinessHoursTable from './BusinessHoursTable';

import {
  constructOptionsFromRailsSelect,
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
} from '../common/utils';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import { getProjectFormInitialState } from './utils';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { projectFormReducer } from './reducers';

const ProjectForm = props => {
  const INITIAL_PROJECT_FORM_STATE = getProjectFormInitialState(props);
  const [projectFormState, dispatchFormActions] = React.useReducer(
    projectFormReducer,
    INITIAL_PROJECT_FORM_STATE
  );

  // const projectTypeOptionsHash = props.projectTypeOptions.reduce(
  //   (optionsHash, option) => {
  //     optionsHash[option.id] = option;
  //     return optionsHash;
  //   },
  //   {}
  // );

  const availableCountriesOptions = React.useMemo(
    () =>
      map(keys(props.availableCountriesStates), countryName => ({
        label: countryName,
        value: countryName,
      })),
    [props.availableCountriesStates]
  );

  const availableCountriesStatesOptions = React.useMemo(
    () =>
      reduce(
        props.availableCountriesStates,
        (options, countryStates, countryName) => {
          options[countryName] = map(countryStates, stateName => ({
            label: stateName,
            value: stateName,
          }));

          return options;
        },
        {}
      ),
    [props.availableCountriesStates]
  );

  const handleSubmit = async event => {
    event.preventDefault();

    const { projectId, links } = props;
    const { csrfToken } = projectFormState;

    let Requester = new Http()
      .onBegin(() => {
        dispatchFormActions({ type: 'initiateSubmit' });
      })
      .setToken(csrfToken);

    if (isNil(projectId)) {
      Requester = Requester.post(links.post);
    } else {
      Requester = Requester.patch(links.patch);
    }

    const {
      assets,
      assetsWithFolders,
      businessHourState,
      selectedCategoryNames,
      selectedOwners,
    } = projectFormState;

    const snakeCasedAttributes = getSnakeCaseKeyedObject(
      projectFormState[props.modelFieldsPrefix]
    );

    const updatedModelAttributes = omit(
      snakeCasedAttributes,
      'facebook_page_uid'
    );

    const assetAttributes = getAssetAttributes(assets);
    const businessHourValues = Object.values(businessHourState);

    await Requester.setPostData({
      id: projectId,
      project: {
        ...updatedModelAttributes,
        assets: assetAttributes,
        asset_folders: assetsWithFolders,
        categories: {
          names: map(selectedCategoryNames, 'value'),
        },
        // Adds business_hours to post request only when project items are disabled
        ...(!updatedModelAttributes['has_project_items'] && {
          business_hours: businessHourValues,
        }),
        owner_ids: map(selectedOwners, 'id'),
      },
    })
      .doesRedirect(true)
      .onSuccess(response => {
        removePageLeavePreventDialog();
        dispatchFormActions({ type: 'submitSucceed' });
        window.location.href = response.data.meta.redirection_url;
      })
      .onError(err => {
        dispatchFormActions({ type: 'submitFailed' });
        // eslint-disable-next-line quotes
        alertErrorNotifications(err || "Couldn't submit at this moment!");
      })
      .exec();
  };

  const handleModelAttributeChange = (attributeName = '') => {
    const setModelAttributeState = (fieldName, value) => {
      const updatedModelAttributes = {
        ...projectFormState[props.modelFieldsPrefix],
        [fieldName]: value,
        ...(fieldName === 'country' && {
          state: null,
        }),
      };

      dispatchFormActions({
        type: 'handleModelAttributeChange',
        payload: {
          [props.modelFieldsPrefix]: updatedModelAttributes,
        },
      });
    };

    return event => {
      const { target } = event;
      // Select Input Changed
      if (isNil(target)) {
        const targetValue = isArray(event)
          ? map(event, option => option.value)
          : event.value;

        setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue =
          target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(targetName, targetValue);
      }
    };
  };

  const getModelAttributeValue = attribute =>
    projectFormState[props.modelFieldsPrefix][attribute] || '';

  // const toggleFlag = fieldName => {
  //   // const { tenant_terms } = this.props;
  //   // const { term_project } = tenant_terms;

  //   let updatedModelAttributes = {
  //     ...projectFormState[props.modelFieldsPrefix],
  //     [fieldName]: !projectFormState[props.modelFieldsPrefix][fieldName],
  //   };

  //   dispatchFormActions({
  //     type: 'toggleFlag',
  //     payload: {
  //       [props.modelFieldsPrefix]: updatedModelAttributes,
  //     },
  //   });
  // };

  const onFileChange = (assets, existingAssets) => {
    dispatchFormActions({
      type: 'onFileChange',
      payload: {
        assets: assets,
        existingAssets: existingAssets,
      },
    });
  };

  const onAssetFolderChange = (handle, folderId) => {
    if (!isNull(folderId)) {
      const { assetsWithFolders } = projectFormState;

      const newFoldersOfAssets = {
        ...assetsWithFolders,
        [handle]: folderId,
      };

      dispatchFormActions({
        type: 'onAssetFolderChange',
        payload: {
          assetsWithFolders: newFoldersOfAssets,
        },
      });
    }
  };

  // const handleBusinessTableValueChange = (day, type, value) => {
  //   let newValuesByType;
  //   switch (type) {
  //     case 'open_time':
  //     case 'close_time':
  //       newValuesByType = !isNull(value) ? value.format('hh:mm a') : null;
  //       break;
  //     case 'closed':
  //       newValuesByType = value;
  //       break;
  //     default:
  //       break;
  //   }

  //   dispatchFormActions({
  //     type: 'handleBusinessTableValueChange',
  //     payload: { day: day, type: type, newValuesByType: newValuesByType },
  //   });
  // };

  const handleCustomMultiSelectChange = (values, type) => {
    dispatchFormActions({
      type: 'handleCustomMultiSelectChange',
      payload: {
        [type]: values,
      },
    });
  };

  const handleOwnersAssignment = owners =>
    dispatchFormActions({
      type: 'handleOwnersAssignment',
      payload: owners,
    });

  const customStatusOptionRenderer = option => (
    <div className="d-flex align-items-center">
      <span className={`project-status__dot ${option.value}`}></span>
      <span>{option.label}</span>
    </div>
  );

  const {
    loading,
    assets,
    existingAssets,
    assetsWithFolders,
    csrfToken,
    // businessHourState,
    selectedOwners,
  } = projectFormState;

  const {
    links,
    // projectId,
    // isSimpleProject,
    enableCodes,
    // companyOptions,
    projectFolders,
    filestackOptions,
    // allowProjectCompany,
    // modelFieldsPrefix,
    timeZoneList,
    // tenantHasHours,
    tenantHasSidebar,
    customLoginEnabled,
    // allowProjectTypes,
    // projectTypeOptions,
    assignableOwners,
    tenant_terms: tenantTerms,
    statusOptions,
  } = props;

  const { term_project: termProject } = tenantTerms;

  // const selectedTimeZone = this.getModelAttributeValue('time_zone');
  const { project_asset_folder_creation: projectAssetFoldersCreationLink } =
    links;

  const selectedCountry = getModelAttributeValue('country');
  const renderableStatesOptions =
    availableCountriesStatesOptions[selectedCountry] || [];

  return (
    <React.Fragment>
      <div
        className={`kt-portlet ${
          !tenantHasSidebar
            ? 'col-lg-8 offset-lg-2 offset-md-1 col-md-10 col-sm-12'
            : ''
        }`}
      >
        {loading && <Spinner />}

        <form
          onSubmit={handleSubmit}
          className="kt-form kt-form--label-right kt-form--fit"
        >
          <div className="kt-portlet__body">
            <FormRow label={'Name'}>
              <Text
                name={'name'}
                value={getModelAttributeValue('name')}
                onInputChange={handleModelAttributeChange()}
              />
            </FormRow>
            {customLoginEnabled && (
              <FormRow label={`${termProject.singular} Id`}>
                <Text
                  name={'custom_id'}
                  value={getModelAttributeValue('custom_id')}
                  onInputChange={handleModelAttributeChange()}
                />
              </FormRow>
            )}
            {enableCodes && (
              <FormRow label={'Code'}>
                <Text
                  name={'code'}
                  value={getModelAttributeValue('code')}
                  onInputChange={handleModelAttributeChange()}
                />
              </FormRow>
            )}
            <FormRow label={'Status'}>
              <Select
                name={'status'}
                options={constructOptionsFromRailsSelect(statusOptions)}
                value={getModelAttributeValue('status')}
                onChange={handleModelAttributeChange('status')}
                optionIdentifier="value"
                formatOptionLabel={customStatusOptionRenderer}
              />
            </FormRow>
            <FormRow label={'Categories'}>
              <DynamicMultiSelect
                name={'projectCategoryIds'}
                options={constructOptionsFromRailsSelect(
                  props.categoriesOptions
                )}
                value={projectFormState.selectedCategoryNames || []}
                onChange={values =>
                  handleCustomMultiSelectChange(values, 'selectedCategoryNames')
                }
              />
            </FormRow>
            {/* {allowProjectCompany && (
                <FormRow label={'Company'}>
                  <Select
                    name={'company_id'}
                    options={constructOptionsFromRailsSelect(companyOptions)}
                    value={this.getModelAttributeValue('company_id')}
                    onChange={this.handleModelAttributeChange('company_id')}
                  />
                </FormRow>
              )} */}
            <FormRow label={'Address'}>
              <Text
                name={'address'}
                value={getModelAttributeValue('address')}
                onInputChange={handleModelAttributeChange('address')}
              />
            </FormRow>
            <FormRow label={'Address 2'}>
              <Text
                name={'address_2'}
                value={getModelAttributeValue('address_2')}
                onInputChange={handleModelAttributeChange('address_2')}
              />
            </FormRow>
            <FormRow label={'City'}>
              <Text
                name={'city'}
                value={getModelAttributeValue('city')}
                onInputChange={handleModelAttributeChange('city')}
              />
            </FormRow>
            <FormRow label={'Country'}>
              <Select
                name={'country'}
                options={availableCountriesOptions}
                value={getModelAttributeValue('country')}
                onChange={handleModelAttributeChange('country')}
                isClearable={true}
                optionIdentifier="value"
              />
            </FormRow>
            <FormRow label={'State'}>
              <Select
                name={'state'}
                options={renderableStatesOptions}
                value={getModelAttributeValue('state')}
                onChange={handleModelAttributeChange('state')}
                isClearable={true}
                optionIdentifier="value"
              />
            </FormRow>
            <FormRow label={'Zip Code'}>
              <Text
                name={'zip_code'}
                value={getModelAttributeValue('zip_code')}
                onInputChange={handleModelAttributeChange('zip_code')}
              />
            </FormRow>

            <FormRow label={'Time Zone'}>
              <Select
                name={'time_zone'}
                options={map(timeZoneList, timeZone => ({
                  label: timeZone,
                  value: timeZone,
                }))}
                value={getModelAttributeValue('time_zone')}
                onChange={handleModelAttributeChange('time_zone')}
                optionIdentifier="value"
              />
            </FormRow>

            {/* <FormRow label={`Show ${termTicket.plural}`}>
                <Toggle
                  name={'show_tickets'}
                  onClick={() => this.toggleFlag('show_tickets')}
                  on={<span>Yes</span>}
                  off={<span>No</span>}
                  size="sm"
                  width={60}
                  height={30}
                  recalculateOnResize
                  offstyle="default"
                  active={this.getModelAttributeValue('show_tickets') || false}
                />
              </FormRow> */}
            {/* {tenantHasHours && (
                <BusinessHoursTable
                  businessHours={businessHourState}
                  handleBusinessTableValueChange={this.handleBusinessTableValueChange}
                />
              )} */}
            <React.Fragment>
              <div className="p-4">
                <h3>Assign Owners</h3>
              </div>
              <FormRow label={'Owners'}>
                <MultiSelect
                  name={'owners'}
                  options={assignableOwners}
                  value={selectedOwners}
                  getOptionLabel={({ name, email }) => name || email}
                  getOptionValue={({ id }) => id}
                  onChange={handleOwnersAssignment}
                  isClearable={true}
                  noOptionsMessage={() => 'No Owners'}
                />
              </FormRow>
            </React.Fragment>

            <AssetManager
              inputColClassName={'col-md-10'}
              label={'Attachments'}
              csrfToken={csrfToken}
              filestackOptions={filestackOptions}
              showAssetsWithFolderSelect={true}
              showGallery={false}
              assetFolderList={projectFolders}
              existingAssets={existingAssets}
              assetsWithFolders={assetsWithFolders}
              assets={assets}
              onFileChange={onFileChange}
              onAssetFolderChange={onAssetFolderChange}
              assetFoldersConfig={{
                resourceType: 'Project',
                links: {
                  assetFoldersCreation: projectAssetFoldersCreationLink,
                },
              }}
            />

            <div className="kt-portlet__foot">
              <div className="row">
                <div className="col-lg-6 offset-3 text-center">
                  <CancelButton />
                  <SubmitButton />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

ProjectForm.propTypes = {
  projectId: PropTypes.number,
  assignableOwners: PropTypes.array.isRequired,
  existingOwnerIds: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  // To group model attribute values under a key if required
  modelFieldsPrefix: PropTypes.string,
  enableCodes: PropTypes.bool,
  isSimpleProject: PropTypes.bool,
  attributes: PropTypes.object,

  existingAssets: PropTypes.array,
  existingAssetsFolders: PropTypes.array,
  projectFolders: PropTypes.array,
  categoriesOptions: PropTypes.array,
  timeZoneList: PropTypes.array,
  statusOptions: PropTypes.array,
  projectTypeOptions: PropTypes.array,
  filestackOptions: PropTypes.object,
  tenantHasSidebar: PropTypes.bool,

  companyOptions: PropTypes.array,
  allowProjectCompany: PropTypes.bool,
  customLoginEnabled: PropTypes.bool,
  availableCountriesStates: PropTypes.object.isRequired,

  tenant_terms: PropTypes.shape({
    term_project: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    term_submission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    term_user: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    project_folders_list: PropTypes.string.isRequired,
    index: PropTypes.string.isRequired,
    post: PropTypes.string.isRequired,
    project_asset_folder_creation: PropTypes.string.isRequired,
    patch: PropTypes.string.isRequired,
  }),
  businessHours: PropTypes.array,
  selectedCategoryNames: PropTypes.array.isRequired,
};

ProjectForm.defaultProps = {
  companyOptions: [],
  modelFieldsPrefix: 'attributes',
  allowProjectCompany: false,
  customLoginEnabled: false,
  isSimpleProject: false,
  businessHours: [],
};

export default withErrorHandler(ProjectForm);

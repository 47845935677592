import React from 'react';
import Toggle from '../common/inputs/Toggle';
import TextInput from '../common/inputs/text';
import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';
import Spinner from '../common/presentational/spinner';
import FormRow from '../common/presentational/formRow';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { generateRandomHashKey } from '../common/utils';

const FORM_INPUT_FIELDS = {
  projectIds: 'project_ids',
  employeeId: 'employee_id',
  firstName: 'first_name',
  lastName: 'last_name',
};

class CustomUsersOnboardingForm extends React.Component {
  constructor() {
    super();
    const initialRowHash = generateRandomHashKey();
    this.state = {
      newUserRowsCount: 1,
      customUserDetails: { [`user_${initialRowHash}`]: {} },
      loading: false,
      setNameAsProjectLabel: false,
    };
  }

  handleOnboardSubmit = async () => {
    const {
      links: { onboardCustomUsers },
      token,
      tenantTerms: { termUser },
    } = this.props;
    const { customUserDetails } = this.state;

    // Remove user data object if all rows are empty.
    let onboardUserData = customUserDetails;
    _.map(customUserDetails, (data, hash) => {
      let isRowDataEmpty = _.isEmpty(data); // Flag to verify and remove data if row is empty.

      if (!_.isEmpty(data)) {
        const totalData = _.map(_.values(data), value => !_.isEmpty(value));
        const validData = _.uniq(_.compact(totalData));
        isRowDataEmpty = _.isEmpty(validData);
      }

      if (isRowDataEmpty) {
        onboardUserData = _.omit(onboardUserData, hash);
      }
    });

    if (_.isEmpty(onboardUserData)) {
      alert(`Please add atleast one ${termUser.singular} details to onboard`);
    } else {
      await new Http()
        .setLoading()
        .setToken(token)
        .post(onboardCustomUsers)
        .setPostData({
          onboarding_data: _.values(onboardUserData),
        })
        .doesRedirect(true)
        .onSuccess(res => {
          this.setState(
            {
              loading: false,
            },
            () => {
              window.location.href = res.data.meta.redirection_url;
            }
          );
        })
        .onError(errors => {
          this.setState(
            {
              loading: false,
            },
            () => {
              alertErrorNotifications(errors || 'Cannot Onboard Users at this time!');
            }
          );
        })
        .exec();
    }
  };

  canAddNewUser = hash => {
    const { customUserDetails } = this.state;

    // First name and Last name are optional so checking only projects and employee id.
    // For allowing to add new row with fields being empty.

    return (
      !_.isEmpty(customUserDetails) &&
      !_.isEmpty(customUserDetails[hash]) &&
      !_.isEmpty(customUserDetails[hash][FORM_INPUT_FIELDS['projectIds']]) &&
      !_.isEmpty(customUserDetails[hash][FORM_INPUT_FIELDS['employeeId']])
    );
  };

  addNewUserRow = hash => {
    // Restrict adding new user row when current last row is empty
    if (!this.canAddNewUser(hash)) {
      return;
    }

    const newRowHash = generateRandomHashKey();

    this.setState(prevState => ({
      newUserRowsCount: prevState.newUserRowsCount + 1,
      customUserDetails: {
        ...prevState.customUserDetails,
        [`user_${newRowHash}`]: {},
      },
    }));
  };

  deleteUserRow = hash => {
    this.setState(prevState => ({
      newUserRowsCount: prevState.newUserRowsCount - 1,
      customUserDetails: _.omit(prevState.customUserDetails, hash),
    }));
  };

  handleProjectUserDetails = (value, hash, attribute) => {
    let fieldValue = value;
    if (
      _.includes(
        [
          FORM_INPUT_FIELDS['employeeId'],
          FORM_INPUT_FIELDS['firstName'],
          FORM_INPUT_FIELDS['lastName'],
        ],
        attribute
      )
    ) {
      fieldValue = fieldValue.target.value;
    }

    if (_.isEqual(attribute, FORM_INPUT_FIELDS['projectIds'])) {
      // Set the ids of all projects.
      fieldValue = _.map(value, val => val.id);
    }

    this.setState(prevState => ({
      customUserDetails: {
        ...prevState.customUserDetails,
        [hash]: {
          ...prevState.customUserDetails[hash],
          [attribute]: fieldValue,
        },
      },
    }));
  };

  selectProjectLabel = value => {
    this.setState({
      setNameAsProjectLabel: value,
    });
  };

  renderDynamicallyAddedRowWithHash = () => {
    const { newUserRowsCount, customUserDetails, setNameAsProjectLabel } = this.state;
    const {
      projectsList,
      tenantTerms: { termProject, termUser },
    } = this.props;

    return _.map(_.keys(customUserDetails), (hash, index) => {
      const projectsLabelKey = setNameAsProjectLabel ? 'name' : 'customId';
      return (
        <tr key={hash}>
          <td className="td-checkbox text-center">{index + 1}</td>
          <td>
            <MultiSelect
              name={'custom_id'}
              placeholder={`Select ${termProject.singular}(s)...`}
              options={projectsList}
              getOptionLabel={(option) => option[projectsLabelKey]}
              getOptionValue={(option) => option.id}
              optionIdentifier="id"
              onChange={values =>
                this.handleProjectUserDetails(
                  values,
                  hash,
                  FORM_INPUT_FIELDS['projectIds']
                )
              }
              value={
                !_.isUndefined(customUserDetails[hash])
                  ? customUserDetails[hash][FORM_INPUT_FIELDS['projectIds']] || []
                  : []
              }
            />
          </td>
          <td>
            <TextInput
              placeholder={`Enter ${termUser.singular} ID`}
              name={FORM_INPUT_FIELDS['employeeId']}
              value={
                !_.isUndefined(customUserDetails[hash])
                  ? customUserDetails[hash][FORM_INPUT_FIELDS['employeeId']]
                  : ''
              }
              onInputChange={value =>
                this.handleProjectUserDetails(
                  value,
                  hash,
                  FORM_INPUT_FIELDS['employeeId']
                )
              }
            />
          </td>
          <td>
            <TextInput
              placeholder={'Enter First Name'}
              name={FORM_INPUT_FIELDS['firstName']}
              value={
                !_.isUndefined(customUserDetails[hash])
                  ? customUserDetails[hash][FORM_INPUT_FIELDS['firstName']]
                  : ''
              }
              onInputChange={value =>
                this.handleProjectUserDetails(value, hash, FORM_INPUT_FIELDS['firstName'])
              }
            />
          </td>
          <td>
            <TextInput
              placeholder={'Enter Last Name'}
              name={FORM_INPUT_FIELDS['lastName']}
              value={
                !_.isUndefined(customUserDetails[hash])
                  ? customUserDetails[hash][FORM_INPUT_FIELDS['lastName']]
                  : ''
              }
              onInputChange={value =>
                this.handleProjectUserDetails(value, hash, FORM_INPUT_FIELDS['lastName'])
              }
            />
          </td>
          <td className="td-50 text-center">
            {_.isEqual(newUserRowsCount - 1, index) ? (
              <span
                className="kt-font-success"
                style={{
                  opacity: this.canAddNewUser(hash) ? 1 : 0.6,
                  cursor: this.canAddNewUser(hash) ? 'pointer' : 'not-allowed',
                }}
                onClick={() => this.addNewUserRow(hash)}
              >
                <i className="fa fa-user-plus font-20" />
              </span>
            ) : (
              <span
                className="text-darkred cursor-pointer"
                onClick={() => this.deleteUserRow(hash)}
              >
                <i className="fa fa-user-times font-20" />
              </span>
            )}
          </td>
        </tr>
      );
    });
  };

  renderCustomUserOnboardingTable = () => {
    const {
      tenantTerms: { termProject, termUser },
    } = this.props;
    const { customUserDetails } = this.state;

    return (
      <div className="row" style={{ overflowX: 'auto' }}>
        <div className="col-md-12">
          <table className="table table-bordered kt-table__row-equal-width mtb-0">
            <thead>
              <tr style={{ height: 42 }}>
                <th className="td-checkbox text-center">#</th>
                <th>{termProject.singular}</th>
                <th>{`${termUser.singular} Id`}</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th className="td-50">&nbsp;</th>
              </tr>
            </thead>
            <tbody>{this.renderDynamicallyAddedRowWithHash()}</tbody>
          </table>
        </div>
        <div className="col-md-12 ptb-20 text-center">
          <button
            type={'submit'}
            className="app-btn-primary mt-20"
            onClick={this.handleOnboardSubmit}
            disabled={_.isEmpty(customUserDetails)}
          >
            Onboard
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      tenantHasSidebar,
      tenantTerms: { termProject, termUser },
    } = this.props;
    const { loading, setNameAsProjectLabel } = this.state;

    return (
      <div className="kt-content">
        {loading && <Spinner />}
        <div className={'kt-portlet'}>
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">{`Onboard ${termUser.plural}`}</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            <FormRow
              label={`Choose ${termProject.singular} Label`}
              labelClassName={'col-md-3'}
              inputColClassName={'col-md-9'}
            >
              <Toggle
                name={'projectLabel'}
                on={`${termProject.singular} Name`}
                off={`${termProject.singular} Id`}
                onClick={value => this.selectProjectLabel(value)}
                width={120}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={setNameAsProjectLabel}
              />
            </FormRow>
            <div
              className="form-group kt-form__group row"
              style={{ alignItems: 'flex-end' }}
            >
              <div className="col-md-12">{this.renderCustomUserOnboardingTable()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withErrorHandler(CustomUsersOnboardingForm);

import { useEffect } from 'react';

const ENTER_KEY_CODE = 13;

const EnterKeyListener = ({ children, onPress }) => {
  useEffect(() => {
    const listener = (event) => {
      if (event.keyCode == ENTER_KEY_CODE) {
        onPress?.();
      }
    };

    document.addEventListener('keydown', listener);
    return () => document.removeEventListener('keydown', listener);
  }, [onPress]);

  return children;
}

export default EnterKeyListener;

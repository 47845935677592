import React from 'react';
import PropTypes from 'prop-types';

import '../styles/breadcrumbs.scss';

const Breadcrumbs = ({ homeUrl, folderItems, urlProvider }) => {
  return (
    <ul className="kt-subheader__breadcrumbs kt-nav">
      <li className="kt-nav__item">
        <a href={homeUrl} className="kt-nav__link p-0">
          <i className="kt-nav__link-icon la la-home" />
        </a>
      </li>

      {_.map(folderItems, (folderItem, index) => (
        <React.Fragment key={`${folderItem.name}-${index}`}>
          <li
            className="kt-nav__separator mx-2"
            style={{ width: '0.5rem', borderColor: '#6c757d' }}
          />
          <li className="kt-nav__item">
            <a href={urlProvider(folderItem.id)} className="kt-nav__link">
              <span className="kt-nav__link-text">{folderItem.name}</span>
            </a>
          </li>
        </React.Fragment>
      ))}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  homeUrl: PropTypes.string.isRequired,
  folderItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  urlProvider: PropTypes.func.isRequired,
};

Breadcrumbs.defaultProps = {
  folderItems: [],
};

export default Breadcrumbs;

import React from 'react';
import PropTypes from 'prop-types';

import reduce from 'lodash/reduce';
import map from 'lodash/map';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import FormRow from '../../common/presentational/formRow';
import Toggle from '../../common/inputs/Toggle';
import TextInput from '../../common/inputs/text';
import TextArea from '../../common/inputs/textArea';
import AssetManager from '../../filestack/AssetManager';
import SubmitButton from '../../common/presentational/submitButton';
import CancelButton from '../../common/presentational/cancelButton';
import { getOrganizationTerms, getUserRoleTerms } from '../utils';
import Http from '../../common/Http';
import {
  removePageLeavePreventDialog,
  getSnakeCaseKeyedObject,
} from '../../common/utils';
import Spinner from '../../common/presentational/spinner';
import CheckBox from '../../common/inputs/checkBox';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const GlobalSettingsForm = props => {
  const [globalSettings, setGlobalSettings] = React.useState({});
  const [logoAttributes, setLogoAttributes] = React.useState([]);
  const [existingLogo, setExistingLogo] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const { tenantTerms, tenantApps, logo } = props;
    const getTenantTerms = [
      ...getOrganizationTerms({ tenantTerms, tenantApps }),
      ...getUserRoleTerms({ tenantTerms }),
    ];
    const newTenantTerms = reduce(
      getTenantTerms,
      (acc, { key, terms }) => {
        acc[key] = terms.singular;
        return acc;
      },
      {}
    );
    const settingsFromProps = getSnakeCaseKeyedObject(props);
    const existingSettings = pick(settingsFromProps, [
      'sidebar',
      'custom_login',
      'email_from_address',
      'email_from_name',
      'subdomain',
      'ga_script',
      'allow_images',
      'allow_video',
      'allow_webcam',
      'allow_custom_email_address',
      'notification_email_address',
      'allow_custom_file_name_convention',
      'allow_daily_course_reviewer_email_notifications',
    ]);

    const logoAlreadyUploaded = !isEmpty(logo);
    let existingLogoAsset = [];
    if (logoAlreadyUploaded) {
      existingLogoAsset.push(logo);
    }

    setGlobalSettings({
      ...newTenantTerms,
      ...existingSettings,
    });
    setExistingLogo(existingLogoAsset);

    // this useEffect equivalent to componentDidMount which needs to run only on the component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = async event => {
    event.preventDefault();

    const { csrfToken, links } = props;
    const snake_cased_logo_attributes = getSnakeCaseKeyedObject(
      logoAttributes[0]
    );
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(true)
      .patch(links.settingsPath, {
        tenant: {
          ...globalSettings,
          // Adds logo_attributes to patch request only when logo asset is selected
          ...(logoAttributes[0] && {
            logo_attributes: snake_cased_logo_attributes,
          }),
        },
      })
      .onSuccess(res => {
        setLoading(false);
        removePageLeavePreventDialog();
        window.location.href = res.data.redirection_url;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handleToggleFieldValue = (field_identifier, value) => {
    let toggleFieldSettings = {};
    toggleFieldSettings[field_identifier] = value;

    setGlobalSettings(prevState => ({
      ...prevState,
      ...toggleFieldSettings,
    }));
  };

  const handleLogoUpload = (assets, existingAssets) => {
    setLogoAttributes(assets);
    setExistingLogo(existingAssets);
  };

  const handleFormAttributeChange = attributeName => {
    const setModelAttributeState = (fieldName, value) => {
      const updatedModelAttributes = {
        ...globalSettings,
        [fieldName]: value,
      };

      setGlobalSettings(updatedModelAttributes);
    };

    return event => {
      const { target } = event;

      const targetName = attributeName || target.name;
      const targetValue =
        target.type !== 'checkbox' ? target.value : target.checked;
      setModelAttributeState(targetName, targetValue);
    };
  };

  const getFormAttributeValue = attribute => {
    return globalSettings[attribute];
  };

  const renderTermsSection = (termsGroupedBy = '') => {
    // Must Specify Which Terms Section to render.
    // Though Organization terms are default, this check avoids misuse of this helper render methiod
    if (isEmpty(termsGroupedBy)) return;

    const { tenantTerms, tenantApps } = props;
    const isUserRoleTerms = isEqual(termsGroupedBy, 'userRole');

    const termsGroup = isUserRoleTerms
      ? getUserRoleTerms({ tenantTerms })
      : getOrganizationTerms({ tenantTerms, tenantApps });

    return (
      <div>
        <div className="text-center mt-20">
          <h3>{`${
            isUserRoleTerms
              ? `${tenantTerms.termUser.singular} Role`
              : 'Organization'
          } Terms`}</h3>
          <hr />
        </div>
        <div className="pb-20 pt-20">
          {map(
            termsGroup,
            ({ label, key, terms, enabled }) =>
              enabled && (
                <React.Fragment key={`${label}-${key}-${terms.singular}`}>
                  <FormRow label={label}>
                    <TextInput
                      name={key}
                      onInputChange={handleFormAttributeChange(key)}
                      value={getFormAttributeValue(key) || ''}
                    />
                  </FormRow>
                </React.Fragment>
              )
          )}
        </div>
      </div>
    );
  };

  const {
    csrfToken,
    learningEnabled,
    tenantTerms: { termCourse, termReviewer },
  } = props;

  const { allow_custom_email_address } = globalSettings;
  const existingLogoAvailable = !isEmpty(existingLogo);
  const customEmailAddressEnabled = !!allow_custom_email_address;
  const filestackOptions = {
    maxFiles: 1,
  };

  return (
    <React.Fragment>
      <form
        className="kt-form kt-form--label-right kt-form--fit"
        onSubmit={onFormSubmit}
      >
        <div className="kt-portlet__body">
          {loading && <Spinner text="Submitting" />}

          {/* <FormRow label="Sidebar">
              <Toggle
                name={'sidebar'}
                onClick={value => handleToggleFieldValue('sidebar', value)}
                on={<span>ON</span>}
                off={<span>OFF</span>}
                size="sm"
                width={60}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={getFormAttributeValue('sidebar')}
              />
            </FormRow> */}

          <FormRow label="Allow Custom Email Address">
            <Toggle
              name={'allow_custom_email_address'}
              onClick={value =>
                handleToggleFieldValue('allow_custom_email_address', value)
              }
              on={<span>ON</span>}
              off={<span>OFF</span>}
              size="sm"
              width={60}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getFormAttributeValue('allow_custom_email_address')}
            />
          </FormRow>

          {customEmailAddressEnabled && (
            <FormRow label="Notification Email Address">
              <TextInput
                name="notification_email_address"
                onInputChange={handleFormAttributeChange(
                  'notification_email_address'
                )}
                value={
                  getFormAttributeValue('notification_email_address') || ''
                }
              />
            </FormRow>
          )}

          <FormRow label="Category Custom File Convention">
            <Toggle
              name={'allow_custom_file_name_convention'}
              onClick={value =>
                handleToggleFieldValue(
                  'allow_custom_file_name_convention',
                  value
                )
              }
              on={<span>YES</span>}
              off={<span>NO</span>}
              size="sm"
              width={60}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getFormAttributeValue(
                'allow_custom_file_name_convention'
              )}
            />
          </FormRow>

          {learningEnabled && (
            <FormRow
              label={`Daily ${termCourse.singular} ${termReviewer.singular} Email Notifications`}
            >
              <Toggle
                name={'allow_daily_course_reviewer_email_notifications'}
                onClick={value =>
                  handleToggleFieldValue(
                    'allow_daily_course_reviewer_email_notifications',
                    value
                  )
                }
                on={<span>YES</span>}
                off={<span>NO</span>}
                size="sm"
                width={60}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={getFormAttributeValue(
                  'allow_daily_course_reviewer_email_notifications'
                )}
              />
            </FormRow>
          )}

          <FormRow label={`${existingLogoAvailable ? 'Logo' : ''}`}>
            <AssetManager
              wrapperClassName={''}
              label={'Logo'}
              csrfToken={csrfToken}
              filestackOptions={filestackOptions}
              showGallery={true}
              showUploader={!existingLogoAvailable}
              galleryClassName={'row offset-md-2'}
              existingAssets={existingLogo}
              assets={logoAttributes || []}
              onFileChange={handleLogoUpload}
            />
          </FormRow>

          <FormRow label="Email from address">
            <TextInput
              name="email_from_address"
              onInputChange={handleFormAttributeChange('email_from_address')}
              value={getFormAttributeValue('email_from_address')}
            />
          </FormRow>

          <FormRow label="Email from name">
            <TextInput
              name="email_from_name"
              onInputChange={handleFormAttributeChange('email_from_name')}
              value={getFormAttributeValue('email_from_name')}
            />
          </FormRow>

          <FormRow label="Subdomain">
            <TextInput
              name="subdomain"
              onInputChange={handleFormAttributeChange('subdomain')}
              value={getFormAttributeValue('subdomain')}
            />
          </FormRow>
          <div className="form-group kt-form__group row">
            <label
              htmlFor="example-text-input"
              className="col-2 col-form-label"
            >
              Media Types:
            </label>
            <div className="col-8">
              <div className="kt-checkbox-inline">
                <CheckBox
                  showLabel
                  label={'Allow Video'}
                  name={'allow_video'}
                  labelClassName="kt-checkbox--brand kt-checkbox--bold"
                  value={getFormAttributeValue('allow_video')}
                  handleOnChange={handleFormAttributeChange('allow_video')}
                />
                <CheckBox
                  showLabel
                  label={'Allow Webcam'}
                  name={'allow_webcam'}
                  labelClassName="kt-checkbox--brand kt-checkbox--bold"
                  value={getFormAttributeValue('allow_webcam')}
                  handleOnChange={handleFormAttributeChange('allow_webcam')}
                />
                <CheckBox
                  showLabel
                  label={'Allow Images'}
                  name={'allow_images'}
                  labelClassName="kt-checkbox--brand kt-checkbox--bold"
                  value={getFormAttributeValue('allow_images')}
                  handleOnChange={handleFormAttributeChange('allow_images')}
                />
              </div>
            </div>
          </div>

          <FormRow label="Google Analytics Script">
            <TextArea
              name="ga_script"
              placeholder="INPUT INTENDED FOR GOOGLE ANALYTICS SCRIPTS ONLY. DO NOT PASTE ANYOTHER SCRIPT."
              onInputChange={handleFormAttributeChange('ga_script')}
              value={getFormAttributeValue('ga_script')}
            />
          </FormRow>
        </div>

        {renderTermsSection('organization')}
        {renderTermsSection('userRole')}

        <div className="kt-portlet__foot">
          <div className="kt-form__actions text-center">
            <CancelButton />
            <SubmitButton />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

GlobalSettingsForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  allowImages: PropTypes.bool,
  allowVideo: PropTypes.bool,
  allowWebcam: PropTypes.bool,
  sidebar: PropTypes.bool,
  emailFromAddress: PropTypes.string,
  emailFromName: PropTypes.string,
  allowCustomFileNameConvention: PropTypes.bool,
  subdomain: PropTypes.string,
  links: PropTypes.shape({
    settingsPath: PropTypes.string.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.oneOfType([PropTypes.object]).isRequired,
  tenantApps: PropTypes.object,
  logo: PropTypes.object,
  learningEnabled: PropTypes.bool,
};

export default withErrorHandler(GlobalSettingsForm);

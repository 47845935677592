import React from "react";
import PropTypes from "prop-types";

const SubmitButton = ({ buttonText, className, ...props }) => (
  <input
    className={`app-btn-primary ${className}`}
    type="submit"
    name="Submit"
    value={buttonText}
    {...props}
  />
);

SubmitButton.propTypes = {
  buttonText: PropTypes.string,
  className: PropTypes.string
};

SubmitButton.defaultProps = {
  buttonText: "Submit",
  className: ''
};

export default SubmitButton;

import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import SelectInput from '../../../common/inputs/select';

const groupStyles = {
  color: 'white',
  padding: '5px 0px',
  display: 'flex',
};

const CurriculumSelect = ({ options, value, onChange, placeholder, name }) => {
  const GroupHeading = data => {
    const isPublished = data.children === 'Published';
    return (
      <div
        style={groupStyles}
        className={isPublished ? 'bg-success' : 'bg-warning'}
      >
        <components.GroupHeading {...data} />
      </div>
    );
  };
  return (
    <SelectInput
      placeholder={placeholder}
      name={name}
      options={options}
      value={value || ''}
      onChange={value => onChange(value)}
      isClearable={false}
      isGrouped={true}
      getOptionLabel={option => option.name}
      getOptionValue={option => option.id}
      optionIdentifier="id"
      components={{ GroupHeading }}
      styles={{
        groupHeading: base => ({
          ...base,
          flex: '1 1',
          color: 'white',
          margin: 0,
        }),
      }}
    />
  );
};

CurriculumSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
};

export default CurriculumSelect;

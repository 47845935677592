import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import map from 'lodash/map';

import Spinner from '../common/presentational/spinner';
import './course_report.scss';
import Http from '../common/Http';
import Modal from '../filestack/modal';
import ReactFlowPlayerWrapper from '../filestack/ReactFlowPlayerWrapper';
import { formatDateTime, getAssetTypeFromMIME } from '../common/utils';
import Asset from '../filestack/Asset';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { alertErrorNotifications } from '../folders/utils';
import TextInput from '../common/inputs/text';

const CourseReport = ({ csrfToken, courseContentItems, links, filters = {}, meta, ...props }) => {
  const [keyword, setKeyword] = useState("");
  const [totalPages, setTotalPages] = useState(meta?.totalPages || 0);
  const [currentPage, setCurrentPage] = useState(meta?.currentPage || 0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(meta?.totalRecordsCount || 0);
  const [allCourseUsers, setAllCourseUsers] = useState(props.allCourseUsers);
  const [completedItems, setCompletedItems] = useState(props.completedItems);
  const [completedQuizzesSummary, setCompletedQuizzesSummary] = useState(props.completedQuizzesSummary);
  const [learnersRecentActivityTsMap, setLearnersRecentActivityTsMap] = useState(props.learnersRecentActivityTsMap);
  const [loading, setLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [modalContentLoading, setModalContentLoading] = React.useState(false);
  const [fileState, setFileState] = React.useState({
    fileSrc: '',
    fileType: '',
    filePoster: '',
  });
  const [courseAssessmentData, setCourseAssessmentData] = React.useState({
    submissions: [],
    evaluations: [],
  });

  const onKeywordChange = useCallback((e) => setKeyword(e.target.value.trim()), []);
  const onClearFilter = useCallback(() => {
    setKeyword('');
    handleFilter('');
  }, []);

  const handleFilter = (keywordToSearch) => {
    const storeFilter = new URLSearchParams(window.location.search).get('apply_store_filter');

    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(links.courseReportShow, {
        page: 0,
        per_page: 20,
        ...(storeFilter ? { apply_store_filter: storeFilter } : {}),
        ...(keywordToSearch ? { name: keywordToSearch } : {}),
        ...filters,
      })
      .onSuccess(response => {
        const { data } = response;
        setAllCourseUsers(data.allCourseUsers);
        setCompletedItems(data.completedItems);
        setCompletedQuizzesSummary(data.completedQuizzesSummary);
        setLearnersRecentActivityTsMap(data.learnersRecentActivityTsMap);
        setTotalPages(data.meta.totalPages);
        setCurrentPage(data.meta.currentPage);
        setTotalRecordsCount(data.meta.totalRecordsCount);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting reports!');
      })
      .exec();
  }

  const handleLoadMore = () => {
    const storeFilter = new URLSearchParams(window.location.search).get('apply_store_filter');

    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(links.courseReportShow, {
        page: currentPage + 1,
        per_page: 20,
        ...(keyword ? { name: keyword } : {}),
        ...(storeFilter ? { apply_store_filter: storeFilter } : {}),
        ...filters,
      })
      .onSuccess(response => {
        const { data } = response;
        setAllCourseUsers((prevAllCourseUsers) => ([
          ...prevAllCourseUsers,
          ...data.allCourseUsers,
        ]));
        setCompletedItems((prevCompletedItems) => ({
          ...prevCompletedItems,
          ...data.completedItems,
        }));
        setCompletedQuizzesSummary((prevCompletedQuizzesSummary) => ({
          ...prevCompletedQuizzesSummary,
          ...data.completedQuizzesSummary,
        }));
        setLearnersRecentActivityTsMap((prevLearnersRecentActivityTsMap) => ({
          ...prevLearnersRecentActivityTsMap,
          ...data.learnersRecentActivityTsMap,
        }));
        setTotalPages(data.meta?.totalPages || 0);
        setCurrentPage(data.meta?.currentPage || 0);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting reports!');
      })
      .exec();
  }

  const closeModal = () => {
    setIsModalOpen(false);
    setFileState({
      fileSrc: '',
      fileType: '',
      filePoster: '',
    });
  };

  // id parameter has been used globally and not on this particular handler
  // we can't remove the id from the function parameter.
  // eslint-disable-next-line no-unused-vars
  const setModalAssetData = (src, type, id = null, poster = '') => {
    setFileState({
      fileSrc: src,
      fileType: type,
      filePoster: poster,
    });
  };

  const fetchAssesmentSubmissionsData = async (item, userId) => {
    const {
      links: { viewSubmissionSummary },
    } = item;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .get(viewSubmissionSummary)
      .setQueryParams({ user_id: userId })
      .onSuccess(res => {
        const { data } = res;
        setModalContentLoading(false);
        setCourseAssessmentData({
          submissions: data['submissions'],
          evaluations: data['evaluations'],
        });
        const isEvaluationsEmpty = isEmpty(data['evaluations']);
        const isSubmissionsEmpty = isEmpty(data['submissions']);

        let fileSource = '';
        let fileType = '';
        let filePoster = '';
        // Set First available submission/evaluation asset by default
        if (!isEvaluationsEmpty) {
          fileSource = data['evaluations'][0].url;
          fileType = getAssetTypeFromMIME(data['evaluations'][0].mimetype);
          filePoster = data['evaluations'][0].poster;
        } else if (!isSubmissionsEmpty) {
          fileSource = data['submissions'][0].url;
          fileType = getAssetTypeFromMIME(data['submissions'][0].mimetype);
          filePoster = data['submissions'][0].poster;
        }
        setModalAssetData(fileSource, fileType, null, filePoster);
        setLoading(false);
      })
      .onError(() => {
        setIsModalOpen(false);
        setModalContentLoading(false);
        setCourseAssessmentData({
          submissions: [],
          evaluations: [],
        });
        setLoading(false);
      })
      .exec();
  };

  const openAssesmentSubmissionsModal = (
    item,
    userId,
    courseItemCompleted = false
  ) => {
    if (!courseItemCompleted) return;
    setIsModalOpen(true);
    setModalContentLoading(true);
    setFileState({
      fileSrc: '',
      fileType: '',
      filePoster: '',
    });
    fetchAssesmentSubmissionsData(item, userId);
  };

  const handleMarkAsComplete = (item, userId) => async () => {
    const { links: courseLink } = item;

    const proceedWithAction = confirm(
      'Are you sure you want to mark it as complete'
    );

    if (proceedWithAction) {
      new Http()
        .setToken(csrfToken)
        .onBegin(() => setLoading(true))
        .useAlerts()
        .doesRedirect(true)
        .patch(courseLink.markAsComplete, {
          user_id: userId,
        })
        .onSuccess(() => {
          setLoading(false);
          window.location.href = links.courseReportShow;
        })
        .onError(() => setLoading(false))
        .exec();
    }
  };

  const renderModalContent = () => {
    const { tenantTerms: { termSubmission } } = props;
    const { fileType, fileSrc, filePoster } = fileState;
    const { submissions, evaluations } = courseAssessmentData;

    if (modalContentLoading) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 200, background: 'rgba(0,0,0,0.05)' }}
        >
          <div className="kt-spinner kt-spinner--brand" />
        </div>
      );
    }

    if (isEmpty(evaluations) && isEmpty(submissions)) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 200 }}
        >
          <h3>{`No ${termSubmission.plural}`}</h3>
        </div>
      );
    }

    switch (fileType) {
      case 'image': {
        return (
          <img
            src={fileSrc}
            style={{
              width: '100%',
              maxHeight: '400px',
            }}
          />
        );
      }
      case 'video': {
        return (
          <ReactFlowPlayerWrapper
            poster={filePoster}
            playerId="reactFlowPlayer"
            sources={[
              {
                type: 'video/mp4',
                src: fileSrc,
              },
            ]}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderAssessmentPreviewAsset = asset => {
    const isAssetFileType = getAssetTypeFromMIME(asset.mimetype) === 'file';
    let AssetParentElement = 'span';
    let assetParentProps = {};
    if (isAssetFileType) {
      AssetParentElement = 'a';
      assetParentProps = {
        href: `${asset.url}`,
        target: '_blank',
      };
    }

    return (
      <div
        style={{
          borderRadius: '4px',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        <AssetParentElement {...assetParentProps}>
          <Asset
            className={'asset-preview'}
            mimetype={asset.mimetype}
            url={asset.url}
            openModal={setModalAssetData}
          />
        </AssetParentElement>
      </div>
    );
  };

  const renderSubmissionList = () => {
    const { fileSrc } = fileState;

    const { submissions, evaluations } = courseAssessmentData;

    let assetList = [];

    const isEvaluationsEmpty = isEmpty(evaluations);
    const isSubmissionsEmpty = isEmpty(submissions);

    if (!isEvaluationsEmpty) {
      assetList = evaluations;
    } else if (!isSubmissionsEmpty) {
      assetList = submissions;
    }

    return (
      <div className="d-flex align-items-center flex-wrap">
        {map(assetList, asset => (
          <span
            key={asset.handle}
            className="m-3"
            style={{
              border: `2px solid ${
                isEqual(asset.url, fileSrc) ? '#6f42c1' : 'transparent'
              }`,
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            {renderAssessmentPreviewAsset(asset)}
          </span>
        ))}
      </div>
    );
  };

  const renderEmptyView = () => {
    const {
      tenantTerms: { termCourse },
    } = props;
    return (
      <div style={{ margin: '80px 0' }}>
        <h5 className="text-center no_submissions">
          There are no members matching the filter.
        </h5>
      </div>
    );
  };

  const courseBadgeElement = courseItem => {
    let className =
      'kt-badge kt-badge--inline kt-badge--pill overflow-hidden rotated-name';
    switch (courseItem.type) {
      case 'CourseModule':
        className += ' kt-badge--info';
        break;
      case 'CourseAssessment':
        className += ' kt-badge--primary';
        break;
      case 'CourseQuiz':
        className += ' badge-darkred';
        break;
      case 'CourseFeedbackForm':
        className += ' kt-badge--warning';
        break;
      case 'CourseScorm':
          className += ' badge-darkgreen';
          break;
      default:
        break;
    }

    return (
      <span className={className} title={courseItem.name || '-'}>
        <span className="text-truncate">{courseItem.name || '-'}</span>
      </span>
    );
  };

  const renderReportContent = () => {
    const { canMarkComplete: isSuperAdmin } = props;
    return map(allCourseUsers, user => {
      const learnerLastActiveTimestamp = learnersRecentActivityTsMap[user.id];

      const courseContentItemsStatus = completedItems[user.id] || {};
      const courseStatusCells = map(courseContentItems, courseContentItem => {
        const contentItemMeta = courseContentItemsStatus[courseContentItem.id];
        const contentItemCompleted =
          !isEmpty(contentItemMeta) && contentItemMeta.completed;
        const statusIconClass = contentItemCompleted ? 'la-check' : 'la-close';
        const statusTextClass = contentItemCompleted
          ? 'text-success'
          : 'text-danger';

        switch (courseContentItem.type) {
          case 'CourseQuiz': {
            const completedQuizData =
              completedQuizzesSummary[courseContentItem.id];
            const textClass = completedQuizData?.['correctAnswersByUser']?.[user.id]?.finalized
              ? 'text-success'
              : 'text-danger';


            return (
              <td className="text-center" key={courseContentItem.id + user.id}>
                <span className={textClass}>
                  {
                    completedQuizData &&
                    contentItemCompleted &&
                    !isEmpty(completedQuizData['correctAnswersByUser']) &&
                    completedQuizData['correctAnswersByUser'][user.id] &&
                    (completedQuizData['correctAnswersByUser'][user.id].correctAnswersCount > 0) ? (
                    <span>
                      {completedQuizData['correctAnswersByUser'][user.id].correctAnswersCount}/
                      {completedQuizData['totalQuestions']}
                      <a
                        target="_blank"
                        href={`${completedQuizData.links.result}?user_id=${user.id}`}
                      >
                        <span className="rounded p-2 ml-1">
                          <i className="far fa-eye"></i>
                        </span>
                      </a>
                    </span>
                  ) : (
                    <i className="la la-bold la-close" />
                  )}
                </span>
              </td>
            );
          }
          case 'CourseFeedbackForm': {
            const formResponseLink =
              !isEmpty(contentItemMeta) && contentItemMeta.links.viewResponse;
            return (
              <td className="text-center" key={courseContentItem.id + user.id}>
                <span className={statusTextClass}>
                  {contentItemCompleted && !isNil(formResponseLink) ? (
                    <span>
                      <i className="la la-bold la-check" />
                      <a target="_blank" href={formResponseLink}>
                        <span className="rounded p-2 ml-1">
                          <i className="far fa-eye"></i>
                        </span>
                      </a>
                    </span>
                  ) : (
                    <i className="la la-bold la-close" />
                  )}
                </span>
              </td>
            );
          }
          case 'CourseAssessment': {
            return (
              <td className="text-center" key={courseContentItem.id + user.id}>
                <span
                  onClick={() =>
                    openAssesmentSubmissionsModal(
                      courseContentItem,
                      user.id,
                      contentItemCompleted
                    )
                  }
                  className={contentItemCompleted ? 'cursor-pointer' : ''}
                >
                  <span className={statusTextClass}>
                    <i className={`la la-bold ${statusIconClass}`} />
                  </span>
                </span>
              </td>
            );
          }
          default: {
            return isSuperAdmin && !contentItemCompleted ? (
              <td className="text-center" key={courseContentItem.id + user.id}>
                <span
                  onClick={handleMarkAsComplete(courseContentItem, user.id)}
                  type="button"
                  className="cursor-pointer"
                  title="Mark as Complete"
                >
                  <span className={statusTextClass}>
                    <i className={`la la-bold ${statusIconClass}`} />
                  </span>
                </span>
              </td>
            ) : (
              <td className="text-center" key={courseContentItem.id + user.id}>
                <span className={statusTextClass}>
                  <i className={`la la-bold ${statusIconClass}`} />
                </span>
              </td>
            );
          }
        }
      });

      return (
        <tr style={{ height: 53 }} key={user.id}>
          <td className="column__user-name" width="200px">
            <span>
              {user.name} {`(${user.signInCount})`}
            </span>
            {learnerLastActiveTimestamp && (
              <p>{formatDateTime({ date: learnerLastActiveTimestamp })}</p>
            )}
          </td>
          {courseStatusCells}
        </tr>
      );
    });
  };

  const {
    courseName,
    renderWithTitle,
    tenantTerms: {
      termCourse,
      termAssignment,
      termUser,
      termModule,
      termCourseQuiz,
      termSubmission,
      termCourseFeedbackForm,
      termScorm,
    },
  } = props;

  const renderBody = () => (
    <>
      {loading && <Spinner />}

      <div className="course_assessment_submissions_modal">
        {isModalOpen && (
          <Modal
            title={`${termAssignment.singular} ${termSubmission.plural}`}
            onClose={closeModal}
            renderContent={renderModalContent}
            renderFooter={renderSubmissionList}
          />
        )}
      </div>

      {
        !isEmpty(allCourseUsers) || !keyword
          ?
            (
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-lg-3 pt-10 pb-10">
                  <TextInput
                    placeholder={'Filter Report by User Name'}
                    name={'nameSearch'}
                    value={keyword}
                    onInputChange={onKeywordChange}
                  />
                </div>

                <div className="col-lg-2 pt-10 pb-10">
                  <button
                    className="app-btn-primary m-btn--wide"
                    onClick={() => handleFilter(keyword)}
                    style={{ width: '100%' }}
                  >
                    Filter By User
                  </button>
                </div>

                <div className="col-lg-2 pt-10 pb-10">
                  <button
                    className="app-btn-outline-primary m-btn--wide"
                    onClick={onClearFilter}
                    style={{ width: '100%' }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )
          : null
      }


      {
        !isEmpty(allCourseUsers)
          ?
            (
              <React.Fragment>
                <p className="text-right">
                  {termCourse.singular} {termModule.singular}{' '}
                  <span className="kt-badge kt-badge--info" />
                  <br />
                  {termCourse.singular} {termAssignment.singular}{' '}
                  <span className="kt-badge kt-badge--primary" />
                  <br />
                  {termCourseQuiz.singular}{' '}
                  <span className="kt-badge badge-darkred" />
                  <br />
                  {termCourseFeedbackForm.singular}{' '}
                  <span className="kt-badge kt-badge--warning" />
                  <br />
                  {termCourse.singular}{' '}{termScorm.singular}{' '}
                  <span className="kt-badge badge-darkgreen" />
                </p>

                <div className="table-responsive">
                  <table
                    className="table table-hover table-checkable table-striped dataTable no-footer dtr-inline table__fixed_cell_width"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th colSpan="1" rowSpan="2" style={{ width: 65 }}>
                          <span>{termUser.plural}</span>
                          <p>(With Last Active TimeStamp)</p>
                        </th>
                      </tr>

                      <tr style={{ height: 220, whiteSpace: 'nowrap' }}>
                        {map(courseContentItems, course => (
                          <th className="text-center" key={course.id}>
                            {courseBadgeElement(course)}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>{renderReportContent()}</tbody>
                  </table>
                  <div className="kt-datatable__pager kt-datatable--paging-loaded clearfix">
                    <div className="kt-datatable__pager-info" />
                  </div>
                </div>
              </React.Fragment>
            )
          : renderEmptyView()
      }

      {
        currentPage + 1 < totalPages
          ?
            (
              <center>
                <button className="btn btn-primary" onClick={handleLoadMore}>
                  Load More
                </button>
              </center>
            )
          : null
      }

      {
        totalPages > 1
          ?
            (
              <div>
                Showing {allCourseUsers.length} of {totalRecordsCount} records
              </div>
            )
          : null
      }
    </>
  );

  if (!renderWithTitle) {
    return renderBody();
  }

  return (
    <div className="kt-portlet kt-portlet--tabs">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <span className="kt-font-info">{courseName}</span> - Reports
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <span className="pull-right" />
        </div>
      </div>
      <div className="kt-portlet__body">
        {renderBody()}
      </div>
    </div>
  );
};

CourseReport.defaultProps = {
  completedItems: {},
  canMarkComplete: false,
  renderWithTitle: true,
};

CourseReport.propTypes = {
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCourseFeedbackForm: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termModule: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCourseQuiz: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termScorm: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    courseReportShow: PropTypes.string.isRequired,
  }),

  canMarkComplete: PropTypes.bool,
  completedItems: PropTypes.object,
  courseId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  courseContentItems: PropTypes.array.isRequired,
  allCourseUsers: PropTypes.array.isRequired,
  completedQuizzesSummary: PropTypes.object,
  learnersRecentActivityTsMap: PropTypes.object,
  courseName: PropTypes.string,
  renderWithTitle: PropTypes.bool,
};

export default withErrorHandler(CourseReport);

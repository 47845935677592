import React from 'react';
import PropTypes from 'prop-types';
import { withErrorHandler } from '../../../hoc/withErrorHandler';

const NoTopic = ({ message }) => (
  <div className="d-flex justify-content-center align-items-center flex-column mt-5">
    <p>Sorry! {message}</p>
  </div>
);

NoTopic.propTypes = {
  message: PropTypes.string,
};

NoTopic.defaultProps = {
  message: 'No Topics Yet.',
};

export default withErrorHandler(NoTopic);

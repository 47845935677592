import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import SmoothImage from '../common/presentational/SmoothImage';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import './badge.scss';
import Spinner from '../common/presentational/spinner';
import { Transition, TransitionContainer } from '../common/animations';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';

const BadgesIndex = ({
  csrfToken,
  badges: propsBadges,
  links: { newBadge },
  isTenantAdmin,
}) => {
  const [loading, setLoading] = useState(false);
  const [badges, setBadges] = useState(propsBadges);
  const [badgeFilterText, setBadgeFilterText] = useState(null);

  const onInputChange = event => {
    const { target } = event;
    if (!isNil(target.name)) {
      setBadgeFilterText(target.value);
    }
  };

  const handleDeleteRequest = (url, id) => {
    const proceed = confirm('Are you sure, you want to delete?');
    if (proceed) {
      new Http()
        .setToken(csrfToken)
        .onBegin(() => setLoading(true))
        .doesRedirect(false)
        .delete(url)
        .useAlerts()
        .useAPIDataFormatters({
          snakifyRequestData: true,
          camelizeResponseData: true,
        })
        .onSuccess(() => {
          const newBadges = filter(badges, badge => badge.id !== id);
          setBadges(newBadges);
          setLoading(false);
        })
        .onError(() => {
          setLoading(false);
        })
        .exec();
    }
  };

  const renderNoBadgesView = () => (
    <Transition classNames="slide-up">
      <div className="kt-portlet text-center p-5 w-100">
        <h2>No Badges found</h2>
      </div>
    </Transition>
  );

  const renderBadges = badges => {
    if (isEmpty(badges)) {
      return renderNoBadgesView();
    }

    return map(
      badges,
      ({
        id,
        name,
        links: { edit, delete: deleteLink },
        attachment: { url },
      }) => (
        <Transition key={`${badgeFilterText}-${id}`}>
          <div className="card badge-card" key={id}>
            <SmoothImage src={url} className="card-img-top" alt="badge" />
            <div className="card-body d-flex justify-content-between align-items-center">
              <h5 className="card-title" style={{ flex: 1 }}>
                {name}
              </h5>
              {isTenantAdmin && (
                <div>
                  <a
                    href={edit}
                    className="app-btn-outline-secondary text-center mr-2"
                  >
                    <i className="far fa-edit pr-0"></i>
                  </a>
                  <span
                    onClick={() => handleDeleteRequest(deleteLink, id)}
                    className="app-btn-outline-secondary text-center cursor-pointer"
                  >
                    <i className="fas fa-trash-alt pr-0"></i>
                  </span>
                </div>
              )}
            </div>
          </div>
        </Transition>
      )
    );
  };

  let allBadges = badges;
  if (!isNil(badgeFilterText) && !isEmpty(badgeFilterText)) {
    allBadges = filter(allBadges, badge =>
      badge.name.toLowerCase().includes(badgeFilterText.toLowerCase())
    );
  }

  return (
    <Fragment>
      {loading && <Spinner />}
      <div className="kt-portlet">
        <div className="kt-portlet__head align-items-center">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Badges</h3>
            <input
              name="badgeFilterText"
              onChange={onInputChange}
              type="text"
              title="Filter by Badge name"
              className="form-control kt-input ml-3"
              placeholder="Filter by Badge name"
            />
          </div>
          {isTenantAdmin && (
            <a href={newBadge} className="app-btn-primary ml-2">
              New Badge
            </a>
          )}
        </div>
        <TransitionContainer className="kt-portlet__body flex-row flex-wrap">
          {renderBadges(allBadges)}
        </TransitionContainer>
      </div>
    </Fragment>
  );
};

BadgesIndex.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  badges: PropTypes.array.isRequired,
  isTenantAdmin: PropTypes.bool,
  links: PropTypes.shape({
    newBadge: PropTypes.string,
  }).isRequired,
};

BadgesIndex.defaultProps = {
  isTenantAdmin: false,
};

export default withErrorHandler(BadgesIndex);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

const styles = {
  // backgroundColor: '#f2f3f8',
  color: '#3f4047',
};

class Modal extends Component {
  constructor(props) {
    super(props);
    this.modalRef = null;

    this.setModalRef = (element) => {
      this.modalRef = element;
    };
  }

  componentDidMount() {
    $(this.modalRef).modal('show');
    $(this.modalRef).on('hidden.bs.modal', this.props.onClose);
  }

  componentWillUnmount() {
    $(this.modalRef).modal('hide');
    $('.modal-backdrop').remove();
  }

  render() {
    const {
      renderContent,
      title,
      renderFooter,
      large,
      hideClose,
      closeOnBackdropClick,
      closeOnEscKey,
      ...props
    } = this.props;

    return (
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        ref={this.setModalRef}
        data-backdrop={closeOnBackdropClick || 'static'}
        data-keyboard={closeOnEscKey}
        {...props}
      >
        <div className={`modal-dialog ${large && 'modal-lg'}`} style={styles}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="myModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ visibility: hideClose ? 'hidden' : 'visible' }}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{renderContent()}</div>
            {!_.isNull(renderFooter) && (
              <div className="modal-footer">{renderFooter()}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Modal.defaultProps = {
  renderFooter: null,
  large: false,
  hideClose: false,
  closeOnBackdropClick: true,
  closeOnEscKey: true,
};

Modal.propTypes = {
  large: PropTypes.bool,
  hideClose: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  renderContent: PropTypes.func.isRequired,
  renderFooter: PropTypes.func,
  closeOnEscKey: PropTypes.bool,
  closeOnBackdropClick: PropTypes.bool,
};

export default Modal;

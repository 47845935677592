import React from 'react';
import PropTypes from 'prop-types';

const FormRow = ({
  label,
  children,
  inlineInput,
  className,
  inputColClassName,
  labelClassName,
  stackedLabel,
  row,
}) => (
  <div
    className={
      inlineInput
        ? `${className}`
        : `form-group kt-form__group ${row ? 'row' : ''} ${className}`
    }
  >
    {!_.isEmpty(label) && (
      <label
        className={(stackedLabel ? 'col-md-12' : labelClassName) + ' col-form-label'}
      >
        {label}
      </label>
    )}
    <div className={stackedLabel ? 'col-md-12' : inputColClassName}>{children}</div>
  </div>
);

FormRow.propTypes = {
  label: PropTypes.string,
  inlineInput: PropTypes.bool,
  className: PropTypes.string,
  inputColClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  stackedLabel: PropTypes.bool,
  row: PropTypes.bool,
};

FormRow.defaultProps = {
  label: 'FormRow',
  row: true,
  stackedLabel: false,
  inlineInput: false,
  className: '',
  labelClassName: 'col-md-2',
  inputColClassName: 'col-md-10',
};

export default FormRow;

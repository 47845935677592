import { useContext } from 'react';
import { AdminTabContext } from '../AdminTab';

const useAdminTabContext = () => {
  const adminTabContext = useContext(AdminTabContext);
  if (adminTabContext === undefined) {
    throw new Error(
      'useAdminTabContext must be used within a AdminTabProvider'
    );
  }
  return adminTabContext;
};

export default useAdminTabContext;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import CommentForm from './commentForm';
import Comment from './comment';

import {
  getCamelCaseKeyedObject,
  getSnakeCaseKeyedObject,
  openCallInterfaceWindow,
} from '../common/utils';

import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';
import WysiwygCommentForm from './WysiwygCommentForm';
import Select from '../common/inputs/select';
import FormRow from '../common/presentational/formRow';
import { withErrorHandler } from '../hoc/withErrorHandler';

const RESETTED_COMMENT_DATA = Object.freeze({
  id: null,
  body: '',
  wysiwygBody: EditorState.createEmpty(), // Make it empty and later pass in the data
});

const RESET_RESPONSE_TEMPLATE = Object.freeze({
  id: null,
  body: '',
  title: '',
  attachments: [],
});

const RESET_COMMENT_FORM_STATE = Object.freeze({
  commentId: null,
  isEditing: false,
  currentComment: {
    ...RESETTED_COMMENT_DATA,
  },
  mentionUserIds: [],
  assets: [],
  existingAssets: [],
});

const renderUploader = ({ onPick }) => (
  <button
    title="Attachments"
    className="custom-secondary-btn"
    onClick={onPick}
  >
    <i className="flaticon2-photograph" />
  </button>
);

class CommentBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commentId: null,
      currentComment: {
        ...RESETTED_COMMENT_DATA,
      },
      mentionUserIds: [],
      assets: [],
      existingAssets: [],
      isEditing: false,
      commentList: this.props.commentList,
      ...(props.enableInternalComments && {
        isInternalComment: false,
      }),
      ...(!_.isUndefined(props.editorModeConfig) && {
        notificationType: !_.isEmpty(props.editorModeConfig.allowed)
          ? props.editorModeConfig.allowed[0]
          : '',
      }),
      ...(!_.isUndefined(props.responseTemplates) && {
        selectedResponseTemplate: {
          ...RESET_RESPONSE_TEMPLATE,
        },
      }),
      commentsOpenedTimeLog: null,
      smsDeliveredTimeLog: null,
      commentsSentiments: {},
    };
  }

  componentDidMount() {
    const { links } = this.props;
    // `links.commentsOpened` is only received for tickets
    if (!_.isUndefined(links['commentsOpened'])) {
      this.fetchCommentsOpened();
    }

    // Note: Firing multiple requests simultaneously will crash dev server
    // Hence adding intentional delay
    if (!_.isUndefined(links['commentsSentiments'])) {
      setTimeout(this.fetchCommentsSentiments, 100);
    }
  }

  onEditorStateChange = editorState => {
    this.setState(prevState => ({
      currentComment: {
        ...prevState.currentComment,
        wysiwygBody: editorState,
      },
    }));
  };

  fetchCommentsOpened = async () => {
    const {
      links: { commentsOpened },
    } = this.props;
    await new Http(this)
      .setToken(this.props.csrfToken)
      .get(commentsOpened)
      .onSuccess(({ data }) => {
        this.setState({
          commentsOpenedTimeLog: data['comments_opened_time_log'],
          smsDeliveredTimeLog: data['sms_comments_delivered_time_logs'],
        });
      })
      .onError(err => alertErrorNotifications(err))
      .exec();
  };

  fetchCommentsSentiments = async () => {
    const {
      links: { commentsSentiments },
    } = this.props;

    const objectifiedSentimentsData = commentsSentiments =>
      _.reduce(
        commentsSentiments,
        (processedSentimentsData, sentimentedGroupedComments, sentimentLabel) => {
          _.forEach(sentimentedGroupedComments, commentSentiment => {
            processedSentimentsData[commentSentiment['comment_id']] = {
              score: commentSentiment.score,
              label: sentimentLabel,
              emotions: commentSentiment.emotions,
            };
          });

          return processedSentimentsData;
        },
        {}
      );

    await new Http(this)
      .setToken(this.props.csrfToken)
      .get(commentsSentiments)
      .onSuccess(({ data }) => {
        this.setState({
          commentsSentiments: objectifiedSentimentsData(data['comments_sentiments']),
        });
      })
      .onError(err => alertErrorNotifications(err))
      .exec();
  };

  getModelAttributeValue = attribute =>
    !_.isUndefined(this.state.currentComment[attribute])
      ? this.state.currentComment[attribute]
      : '';

  toggleEditBox = comment => {
    const { isEditing } = this.state;

    const newEditState = isEditing
      ? { ...RESET_COMMENT_FORM_STATE }
      : {
          isEditing: true,
          commentId: comment.id,
          currentComment: {
            id: comment.id,
            body: comment.body,
          },
          mentionUserIds: comment['mentionUserIds'],
          assets: [],
          existingAssets: comment['attachments'],
        };

    this.setState(newEditState);
  };

  onFileChange = (assets, existingAssets) => {
    this.setState({
      assets: assets,
      existingAssets: existingAssets,
    });
  };

  getTemplateAttachments = template => {
    if (_.isNil(template.attachments)) return [];

    return template.attachments;
  };

  onCommentAssetsChange = (commentId, assets, existingAssets) => {
    const { commentList } = this.state;
    const newCommentList = _.map(commentList, comment => {
      if (comment.id === commentId) {
        return {
          ...comment,
          ...{
            attachments: existingAssets,
          },
        };
      } else {
        return comment;
      }
    });

    this.setState({
      commentList: newCommentList,
    });
  };

  handleNewCommentCancel = () => {
    const { isTicketComments } = this.props;
    this.setState(prevState => ({
      ...prevState,
      ...RESET_COMMENT_FORM_STATE,
      ...(isTicketComments && {
        selectedResponseTemplate: { ...RESET_RESPONSE_TEMPLATE },
      }),
    }));
  };

  handleSubmit = async event => {
    event.preventDefault();

    const {
      commentId,
      commentList,
      currentComment,
      assets,
      mentionUserIds,
      isInternalComment,
      notificationType,
      selectedResponseTemplate,
    } = this.state;

    const {
      commentableId,
      commentableType,
      enableInternalComments,
      csrfToken,
      isTicketComments,
      links,
    } = this.props;
    const { createComment } = links;

    let attributesWithSnakeKeys = getSnakeCaseKeyedObject(currentComment);

    if (_.isEqual(notificationType, 'email')) {
      // body is not in html format, convert it
      const htmlConvertedBody = draftToHtml(
        convertToRaw(currentComment['wysiwygBody'].getCurrentContent())
      );
      attributesWithSnakeKeys.body = htmlConvertedBody.replace('<p></p>\n', '');
      attributesWithSnakeKeys = _.omit(attributesWithSnakeKeys, 'wysiwyg_body');
    }
    const templateAssets = isTicketComments
      ? this.getTemplateAttachments(selectedResponseTemplate)
      : [];
    const submittableAssets = [...assets, ...templateAssets];
    const assetAttributes = getAssetAttributes(submittableAssets);

    let Request = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .setPostData({
        comment: {
          ...attributesWithSnakeKeys,
          commentable_type: commentableType,
          commentable_id: commentableId,
          attachments: assetAttributes,
          mention_user_ids: mentionUserIds,
          ...(enableInternalComments && {
            internal: isInternalComment,
          }),
          ...(!_.isUndefined(this.props.editorModeConfig) && {
            notification_type: notificationType,
          }),
        },
      })
      .onSuccess(response => {
        const commentFromResponse = getCamelCaseKeyedObject(response.data['comment']);

        /* caluculate new comment to set in state */
        const newCommentList = _.isNil(commentId)
          ? /* if new comment added, keep it in existing state */
            _.flatten([...commentList, commentFromResponse])
          : /* if existing comment updated - update specific comment in the state */
            _.map(commentList, comment =>
              comment.id === commentId ? commentFromResponse : comment
            );

        this.setState(
          {
            ...RESET_COMMENT_FORM_STATE,
            commentList: newCommentList,
            selectedResponseTemplate: { ...RESET_RESPONSE_TEMPLATE },
          }
        );
      })
      .onError(err => alertErrorNotifications(err));

    if (!_.isNil(commentId)) {
      let currentComment = _.find(commentList, { id: commentId });
      Request = Request.patch(currentComment.links.update);
    } else {
      Request = Request.post(createComment);
    }

    await Request.exec();
  };

  handleDelete = async commentId => {
    const { commentList } = this.state;
    const { csrfToken } = this.props;
    let currentComment = _.find(commentList, { id: commentId });

    const Request = await new Http(this)
      .setToken(csrfToken)
      .delete(currentComment.links.delete)
      .onSuccess(response => {
        if (response.status === 200) {
          const newCommentList = _.filter(commentList, ({ id }) => {
            return !(id === commentId);
          });
          this.setState(
            {
              commentId: null,
              isEditing: false,
              commentList: newCommentList,
              currentComment: {
                ...RESETTED_COMMENT_DATA,
              },
              mentionUserIds: [],
              assets: [],
              existingAssets: [],
            }
          );
        }
      })
      .onError(err => alertErrorNotifications(err))
      .exec();
  };

  onCommentChange = (body, mentionUserIds = []) => {
    const { currentComment } = this.state;
    const updatedComment = { ...currentComment, body: body };

    this.setState({
      currentComment: updatedComment,
      mentionUserIds: mentionUserIds,
    });
  };

  handleNotificationTypeChange = (type, isDisabled) => {
    // Return if disabled
    if (isDisabled) return;

    const { notificationType, isInternalComment } = this.state;
    const isInternalNoteTab = _.isEqual(type, 'internal');
    const isCurrentActiveTab = _.isEqual(notificationType, type);

    // Return If Clicked on currently active tab again
    if (isCurrentActiveTab || (isInternalComment && isInternalNoteTab)) {
      return;
    }

    let updatedState = {};
    if (isInternalNoteTab) {
      updatedState = {
        notificationType: null,
        isInternalComment: true,
      };
    } else {
      updatedState = {
        notificationType: type,
        isInternalComment: false,
      };
    }

    this.setState({
      ...updatedState,
      currentComment: { ...RESETTED_COMMENT_DATA },
      selectedResponseTemplate: { ...RESET_RESPONSE_TEMPLATE },
    });
  };

  openCallInterface = () => {
    const { links } = this.props;

    return openCallInterfaceWindow(links.voiceCallInterface);
  };

  getWysiwygEditorState = (wysiwygBody = '') => {
    const { editorModeConfig } = this.props;

    if (!_.isUndefined(editorModeConfig)) {
      try {
        const contentBlock = htmlToDraft(wysiwygBody);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          return EditorState.createWithContent(contentState);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return EditorState.createEmpty();
  };

  handleResponseTemplateChange = template => {
    const { currentComment, assets } = this.state;

    let updatedComment = {
      ...currentComment,
      body: template.body,
      wysiwygBody: this.getWysiwygEditorState(template.body),
    };

    this.setState({
      selectedResponseTemplate: template,
      currentComment: {
        ...updatedComment,
      },
    });
  };

  renderCommentsList = () => {
    const {
      csrfToken,
      currentUserId,
      taggableUsers,
      callLoggedCommentIds,
    } = this.props;

    const {
      commentList,
      commentId,
      isEditing,
      assets,
      existingAssets,
      commentsOpenedTimeLog,
      smsDeliveredTimeLog,
      commentsSentiments,
      loading
    } = this.state;

    const commentProps = {
      csrfToken: csrfToken,
      currentUserId: currentUserId,
      isEditing: isEditing,
      toggleEditBox: this.toggleEditBox,
      handleDelete: this.handleDelete,
      onCommentAssetsChange: this.onCommentAssetsChange,
    };

    return (
      <div className="kt-widget3">
        {_.map(commentList, comment => {
          // show inline comment edit form
          if (isEditing && commentId === comment.id) {
            return (
              <div
                key={`edit_${comment.id}`}
                className="p-3"
                style={{
                  backgroundColor: `${comment.internal ? '#f0f2fc' : '#fff'}`,
                }}
              >
                <CommentForm
                  csrfToken={csrfToken}
                  assets={assets}
                  existingAssets={existingAssets}
                  renderUploader={renderUploader}
                  onFileChange={this.onFileChange}
                  getModelAttributeValue={this.getModelAttributeValue}
                  handleSubmit={this.handleSubmit}
                  handleCancel={() => this.toggleEditBox(comment)}
                  taggableUsers={taggableUsers}
                  onCommentChange={this.onCommentChange}
                  loading={loading}
                />
              </div>
            );
          } else {
            const camelizedComment = getCamelCaseKeyedObject(comment);
            return (
              <Comment
                key={`comment_${comment.id}`}
                comment={camelizedComment}
                openedTimeLog={
                  !_.isNull(commentsOpenedTimeLog)
                    ? commentsOpenedTimeLog[comment.id]
                    : null
                }
                smsDeliveredTime={
                  !_.isNull(smsDeliveredTimeLog) ? smsDeliveredTimeLog[comment.id] : null
                }
                sentiment={_.get(commentsSentiments, comment.id, null)}
                editable={_.isUndefined(this.props.editorModeConfig)}
                callLoggedCommentIds={callLoggedCommentIds}
                {...commentProps}
              />
            )
          }
        })}
      </div>
    );
  };

  renderNotificationTypeTabs = () => {
    const { editorModeConfig, enableInternalComments, enableCalling } = this.props;

    const editorModeConfigExists = !_.isUndefined(editorModeConfig);

    const { notificationType, isInternalComment } = this.state;

    const getClassNames = (type='') => {
      if(_.isEqual('sms', type)) return 'custom-btn-blue'
      else if(_.isEqual('email', type)) return 'custom-btn-red'
      else return 'custom-btn-green'
    }

    const getActiveClass = type =>
      _.isEqual(notificationType, type) ||
      (_.isEqual('internal', type) && isInternalComment)
        ? 'active'
        : '';

    return (
      <div className='d-md-flex align-items-center'>
        <strong className="text-muted">Reply by:</strong>
        <ul className="nav mb-0 ml-0 ml-md-3" role="tablist">
          {editorModeConfigExists &&
            _.map(editorModeConfig.available, type => {
              const isDisabled = !_.includes(editorModeConfig.allowed, type);

              return (
                <button
                  className={`${getClassNames(type)} mr-3 ${getActiveClass(type)}`}
                  key={type}
                  onClick={() => this.handleNotificationTypeChange(type, isDisabled)}
                  disabled={isDisabled}
                >
                  <strong>
                    {_.isEqual('sms', type) ? _.upperCase(type) : _.capitalize(type)}
                  </strong>
                </button>
              );
            })}
          {enableInternalComments && (
            <button
              className={`${getClassNames()} mr-1 ${getActiveClass('internal')}`}
              key={'internal_note'}
              onClick={() => this.handleNotificationTypeChange('internal', false)}
            >
              <strong>
                Internal Note
              </strong>
            </button>
          )}
        </ul>
      </div>
    );
  };

  renderNewTicketCommentForm = () => {
    const {
      csrfToken,
      taggableUsers,
      formElementRef,
      formElementId,
      tenantTerms,
    } = this.props;

    const {
      assets,
      isInternalComment,
      notificationType,
      currentComment: { wysiwygBody },
      selectedResponseTemplate,
      loading
    } = this.state;
    const templateAssets = this.getTemplateAttachments(selectedResponseTemplate);
    return (
      <div className="tab-content">
        <div
          className={`tab-pane ${_.isEqual(notificationType, 'email') ? 'active' : ''}`}
          role="tabpanel"
        >
          <WysiwygCommentForm
            csrfToken={csrfToken}
            assets={assets}
            existingAssets={templateAssets}
            renderUploader={renderUploader}
            onFileChange={this.onFileChange}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleNewCommentCancel}
            formElementId={formElementId}
            wysiwygBody={wysiwygBody}
            onEditorStateChange={this.onEditorStateChange}
            loading={loading}
          />
        </div>

        <div
          className={`tab-pane ${
            _.isEqual(notificationType, 'sms') || isInternalComment ? 'active' : ''
          }`}
          role="tabpanel"
        >
          <CommentForm
            csrfToken={csrfToken}
            assets={assets}
            existingAssets={[]}
            renderUploader={renderUploader}
            onFileChange={this.onFileChange}
            getModelAttributeValue={this.getModelAttributeValue}
            handleSubmit={this.handleSubmit}
            handleCancel={this.handleNewCommentCancel}
            taggableUsers={taggableUsers}
            onCommentChange={this.onCommentChange}
            formElementRef={formElementRef}
            formElementId={formElementId}
            showMultipleSmsAlert={true}
            termTicketMessages={tenantTerms.termTicketMessages}
            loading={loading}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      noHover,
      isTicketComments,
      csrfToken,
      taggableUsers,
      formElementRef,
      formElementId,
      responseTemplates,
      enableCalling,
      tenantTerms,
      showHeader
    } = this.props;
    const {
      commentList,
      isEditing,
      isInternalComment,
      assets,
      selectedResponseTemplate,
      notificationType,
      loading,
    } = this.state;

    const commentsTerm = isTicketComments
      ? tenantTerms.termTicketMessages.plural
      : 'Comments';

    const templateOptions = isTicketComments
      ? isInternalComment
        ? responseTemplates['sms']
        : responseTemplates[notificationType]
      : [];

    return (
      <div
        className={`kt-portlet kt-portlet--mobile ${
          noHover ? ' kt-portlet--unelevate' : ''
        }`}
      >
        {showHeader && (<div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{commentsTerm}</h3>
          </div>
        </div>)}
        <div className="kt-portlet__body p-0">
          <div className='mt-0'>
            {_.isEmpty(commentList) ? (
              <h4 className="text-center mt-20 weight-600">{`No ${commentsTerm}`}</h4>
            ) : (
              this.renderCommentsList()
            )}
          </div>
        </div>
        <div className="kt-portlet__foot p-0">
          {!isEditing &&
            (isTicketComments ? (
              <div
                className="p-4"
                style={{
                  backgroundColor: `${isInternalComment ? '#f0f2fc' : '#fff'}`,
                }}
              >
                <div className="kt-content my-4">
                  <div className="d-flex justify-content-between align-items-center flex-wrap">
                    {this.renderNotificationTypeTabs()}
                    <div className="align-self-end">
                      <button
                        className="btn btn-primary kt-font-bold mt-2 mt-md-0"
                        onClick={this.openCallInterface}
                        disabled={!enableCalling}
                      >
                        <i className="fa fa-phone mr-1 font-12 rotate-call-icon" />
                        Call
                      </button>
                    </div>
                  </div>
                  <div className="">
                    {!_.isUndefined(responseTemplates) &&
                      !_.isUndefined(selectedResponseTemplate) && (
                        <FormRow
                          label="Choose From Template"
                          className="response-template my-3 no-gutters"
                          labelClassName="col-md-3"
                          inputColClassName="col-md-12"
                        >
                          <Select
                            name={'responseTemplate'}
                            placeholder="Select a Response Template..."
                            options={templateOptions}
                            getOptionLabel={(option) => option.title}
                            getOptionValue={(option) => option.id}
                            value={selectedResponseTemplate.id || ''}
                            optionIdentifier="id"
                            onChange={this.handleResponseTemplateChange}
                            noOptionsMessage={() => "No Templates Found"}
                          />
                        </FormRow>
                      )}
                    {this.renderNewTicketCommentForm()}
                  </div>
                </div>
              </div>
            ) : (
              <CommentForm
                csrfToken={csrfToken}
                assets={assets}
                existingAssets={[]}
                renderUploader={renderUploader}
                onFileChange={this.onFileChange}
                getModelAttributeValue={this.getModelAttributeValue}
                handleSubmit={this.handleSubmit}
                handleCancel={this.handleNewCommentCancel}
                taggableUsers={taggableUsers}
                onCommentChange={this.onCommentChange}
                formElementRef={formElementRef}
                formElementId={formElementId}
                loading={loading}
              />
            ))}
        </div>
      </div>
    );
  }
}

CommentBox.propTypes = {
  noHover: PropTypes.bool,
  showHeader: PropTypes.bool,
  commentableType: PropTypes.string.isRequired,
  commentableId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  commentList: PropTypes.array,
  taggableUsers: PropTypes.array,
  updateCommentList: PropTypes.func,
  enableInternalComments: PropTypes.bool,
  enableCalling: PropTypes.bool,
  isTicketComments: PropTypes.bool,
  callLoggedCommentIds: PropTypes.array,
  tenantTerms: PropTypes.shape({
    termTicketMessages: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
};

CommentBox.defaultProps = {
  commentList: [],
  taggableUsers: [],
  noHover: false,
  updateCommentList: () => {},
  enableInternalComments: false,
  enableCalling: false,
  isTicketComments: false,
  callLoggedCommentIds: [],
  showHeader: true,
};

export default withErrorHandler(CommentBox);

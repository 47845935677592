import React from 'react';
import PropTypes from 'prop-types';
import {
  CSSTransition,
  TransitionGroup as TransitionContainer,
} from 'react-transition-group';
import './styles.scss';

const animationStyles = {
  'zoom-in': {
    classNames: 'zoom-in',
    timeout: 400,
  },
  'slide-up': {
    classNames: 'slide-up',
    timeout: 500,
  },
};

const Transition = ({
  children,
  classNames,
  timeout,
  ...otherAnimationProps
}) => (
  <CSSTransition
    in={true}
    appear={true}
    timeout={timeout || animationStyles[classNames].timeout}
    classNames={classNames}
    mountOnEnter
    unmountOnExit
    {...otherAnimationProps}
  >
    {children}
  </CSSTransition>
);

Transition.propTypes = {
  classNames: PropTypes.string,
  timeout: PropTypes.number,
  children: PropTypes.any,
};

Transition.defaultProps = {
  classNames: 'zoom-in',
  timeout: null,
};

export { Transition, TransitionContainer };

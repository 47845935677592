export const GRADIENT_COLORS = [
  'linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)',
  'linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%)',
  'linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%)',
  'linear-gradient(to right, #ffecd2 0%, #fcb69f 100%)',
  'linear-gradient(to right, #ff8177 0%, #ff867a 0%, #ff8c7f 21%, #f99185 52%, #cf556c 78%, #b12a5b 100%)',
  'linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%)',
  'linear-gradient(120deg, #f6d365 0%, #fda085 100%)',
  'linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%)',
  'linear-gradient(to top, #fdcbf1 0%, #fdcbf1 1%, #e6dee9 100%)',
  'linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)',
  'linear-gradient(120deg, #a6c0fe 0%, #f68084 100%)',
  'linear-gradient(120deg, #fccb90 0%, #d57eeb 100%)',
  'linear-gradient(to right, #43e97b 0%, #38f9d7 100%)',
  'linear-gradient(to top, #a8edea 0%, #fed6e3 100%)',
  'linear-gradient(to top, #5ee7df 0%, #b490ca 100%)',

  'linear-gradient( 109.6deg,  rgba(5,84,94,1) 16%, #bbb 91.1% )',
  'linear-gradient( 109.6deg,  rgba(5,84,94,1) 16%, #bbb 91.1% )',
  'radial-gradient( circle farthest-corner at 10% 20%,  rgba(206,124,124,1) 0%, rgba(237,133,69,1) 90% )',
  'linear-gradient( 135deg, #F5CBFF 10%, #C346C2 100%)',
  'linear-gradient( 109.6deg,  rgba(121,203,202,1) 11.2%, rgba(119,161,211,1) 91.1% )',
  'linear-gradient( 109.6deg,  rgba(48,207,208,1) 11.2%, rgba(51,8,103,1) 92.5% )',
  'linear-gradient( 109.5deg,  rgba(76,221,242,1) 11.2%, rgba(92,121,255,1) 91.1% )',

  'linear-gradient(to left, #bdc3c7, #2c3e50)',
  'linear-gradient(to left, #ee9ca7, #ffdde1)',
  'linear-gradient(to left, #2193b0, #6dd5ed)',
  'linear-gradient(to left, #0f2027, #203a43, #2c5364)',
  'linear-gradient(to left, #373b44, #4286f4)',
  'linear-gradient(to left, #2980b9, #6dd5fa, #957dff)',
  'linear-gradient(to left, #1f4037, #99f2c8)',
  'linear-gradient(to left, #7f7fd5, #86a8e7, #91eae4)',
  'linear-gradient(to left, #8360c3, #2ebf91)',
];

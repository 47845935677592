import React from 'react';
import PropTypes from 'prop-types';
import Toggle from '../common/inputs/Toggle';
import Select from '../common/inputs/select';
import MultiSelect from '../common/inputs/multiSelect';
import Modal from '../filestack/modal';
import FormRow from '../common/presentational/formRow';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

const BULK_EDIT_MODE = 'bulkEditMode';
const SINGLE_EDIT_MODE = 'singleEditMode';

class UserTeamAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBulkEditMode: false,
      isModalOpen: false,
      loading: false,
      redirectLoading: false,
      bulkUserAssigmentData: {
        users: [],
        teams: [],
      },
      currentUserData: {
        user: '',
        teams: [],
      },
      userAssignmentData: this.setInitialUserAssignmentData(), // Add the user Team Assignment data on success callback, Basically this obj stores all the populated data.
    };
  }

  setInitialUserAssignmentData = () => {
    const { userList } = this.props;
    let mappedUserAssignmentData = {};
    userList.map(user => {
      mappedUserAssignmentData[user.id] = {
        teams: user.teamIds,
      };
    });
    return mappedUserAssignmentData;
  };

  handleBulkAssignToggle = value => {
    this.setState({
      isBulkEditMode: value,
      ...(!value && {
        bulkUserAssigmentData: {
          users: [],
          teams: [],
        },
      }),
    });
  };

  handleBulkAssignModal = value => {
    this.setState({
      isModalOpen: value,
    });
  };

  mapBulkUserAssignmentData = bulkUserAssigmentData => {
    const { users, teams } = bulkUserAssigmentData;
    let mappedUserData = {};
    users.map(user => {
      mappedUserData[user] = {
        teams,
      };
    });
    return mappedUserData;
  };

  handleBulkUserAssignment = (value, attribute = '') => {
    if (_.isEmpty(attribute)) return;
    this.setState(prevState => ({
      bulkUserAssigmentData: {
        ...prevState.bulkUserAssigmentData,
        [attribute]: value,
      },
    }));
  };

  handleAssignmentDone = () => {
    const {
      links: { redirectToMembersLink },
    } = this.props;

    const proceed = window.confirm(
      'This will submit changes and redirects to Teams section!\nPlease confirm!'
    );
    // Wait time for API request to complete, before redirecting.
    // Since there is a delay in redirection,
    // Loader will restrict the user from clicking multiple times.
    if (proceed) {
      this.setState({
        redirectLoading: true,
      });
      setTimeout(() => {
        window.location.href = redirectToMembersLink;
      }, 2500);
    }
  };

  mapTeamIds = teams => _.map(teams, 'id');

  handleUserTeamAssignSubmit = async (mode, userId = '') => {
    const {
      bulkUserAssigmentData: { users, teams },
      bulkUserAssigmentData,
      currentUserData: { user: currentUser, teams: currentUserTeams },
      userAssignmentData,
    } = this.state;
    const {
      csrfToken,
      links: { userTeamAssignmentLink },
    } = this.props;

    let postData = {};

    const isBulkEditMode = _.isEqual(mode, BULK_EDIT_MODE);
    const isSingleEditMode = _.isEqual(mode, SINGLE_EDIT_MODE);
    const isCurrentUser = _.isEqual(userId, currentUser);
    let teamIds = [];
    if (isBulkEditMode) {
      teamIds = this.mapTeamIds(teams);
      postData = {
        user_ids: users,
        team_ids: teamIds,
      };
    } else if (isSingleEditMode && isCurrentUser) {
      teamIds = this.mapTeamIds(currentUserTeams);
      postData = {
        user_ids: [userId],
        team_ids: teamIds,
      };
    }

    const Requester = await new Http(this).setToken(csrfToken);
    // Make POST request only when there is postData, i.e, changed something
    if (!_.isEmpty(postData)) {
      Requester.setLoading()
        .post(userTeamAssignmentLink)
        .setPostData({
          bulk_user_csv_upload: { ...postData },
        })
        .onSuccess(() => {
          let mappedUserData = {};
          if (isBulkEditMode) {
            mappedUserData = this.mapBulkUserAssignmentData(bulkUserAssigmentData);
          } else if (isSingleEditMode && isCurrentUser) {
            mappedUserData[userId] = {
              ...userAssignmentData[userId],
              teams: teamIds,
            };
          }
          this.setState(prevState => ({
            isModalOpen: false,
            isBulkEditMode: false,
            loading: false,
            bulkUserAssigmentData: {
              users: [],
              teams: [],
            },
            userAssignmentData: { ...prevState.userAssignmentData, ...mappedUserData },
            currentUserData: {
              user: '',
              teams: [],
            },
          }));
        })
        .onError(err => {
          this.setState(
            {
              loading: false,
              isModalOpen: false,
            },
            () =>
              alertErrorNotifications(
                err || 'Could not assign teams/ role at this moment!'
              )
          );
        })
        .exec();
    }
  };

  handleTeamAndRoleSelection = (userId, value, attribute = '') => {
    if (_.isEmpty(attribute)) return;

    this.setState(prevState => {
      if (prevState.currentUserData.user === userId) {
        return {
          currentUserData: {
            ...prevState.currentUserData,
            [attribute]: value,
          },
        };
      } else {
        return {
          currentUserData: {
            user: userId,
            [attribute]: value,
          },
        };
      }
    });
  };

  getUserAssignmentData = (userId, attribute) => {
    const { userAssignmentData, currentUserData } = this.state;
    if (_.isEqual(currentUserData.user, userId)) {
      return currentUserData[attribute];
    }
    if (!_.isEmpty(userAssignmentData[userId])) {
      return userAssignmentData[userId][attribute];
    }
  };

  handleUserSelect = userId => {
    const {
      bulkUserAssigmentData: { users: selectedUsers },
    } = this.state;
    let newSelectedUsers = [];

    if (_.includes(selectedUsers, userId)) {
      newSelectedUsers = _.filter(selectedUsers, id => {
        return !(userId === id);
      });
    } else {
      newSelectedUsers = [...selectedUsers, userId];
    }

    this.setState(prevState => ({
      bulkUserAssigmentData: {
        ...prevState.bulkUserAssigmentData,
        users: newSelectedUsers,
      },
    }));
  };

  selectAllUsers = () => {
    const { userList } = this.props;
    let newSelectedUsers = [];

    if (!this.isSelectedAll()) {
      newSelectedUsers = _.map(userList, 'id');
    }

    this.setState(prevState => ({
      bulkUserAssigmentData: {
        ...prevState.bulkUserAssigmentData,
        users: newSelectedUsers,
      },
    }));
  };

  isSelected = userId => _.includes(this.state.bulkUserAssigmentData.users, userId);

  isSelectedAll = () => {
    const {
      bulkUserCsvUpload: { importedUserCount },
    } = this.props;
    const {
      bulkUserAssigmentData: { users: selectedUsers },
    } = this.state;
    return selectedUsers.length === importedUserCount;
  };

  renderModalContent = () => {
    const {
      bulkUserAssigmentData: { users, teams, role },
    } = this.state;
    const {
      teamList,
      roleList,
      tenantTerms: { termUser, termMember },
    } = this.props;
    return (
      <div className="px-2">
        <p className="kt-font-info">
          Selected Teams will be assigned to&nbsp;&nbsp;&nbsp;
          <strong>{users.length}</strong>
          &nbsp; {termUser.singular}(s).
        </p>
        <br />
        <FormRow label={'Teams'}>
          <MultiSelect
            name="teams"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            optionIdentifier="id"
            placeholder={`Select Teams`}
            closeMenuOnSelect={false}
            options={teamList}
            value={teams || []}
            onChange={values => this.handleBulkUserAssignment(values, 'teams')}
          />
        </FormRow>
        {/* Due To several Edge Cases, Roles Assignment is not allowed for now. 
        <FormRow label={'Role'}>
          <Select
            name="roles"
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            placeholder={`Select Role`}
            options={roleList}
            value={role || ''}
            onChange={value => this.handleBulkUserAssignment(value.id, 'role')}
            optionIdentifier="id"
          />
        </FormRow> */}
        <br />
        <div>
          <h6>Note:</h6>
          <p>
            1. This will <strong className="text-darkred">OVERRIDE</strong> the
            previously assigned teams.
          </p>
          <p>
            2. <strong>{termMember.singular}</strong> is assigned as Default Role. Please
            update roles in Teams section.
          </p>
        </div>
      </div>
    );
  };

  getCsvUploadStatusColor = status => {
    switch (status) {
      case 'Completed':
        return 'success';
      case 'Completed With Errors':
      case 'Inprogress':
        return 'warning';
      case 'pending':
        return 'danger';
    }
  };

  renderUserCsvUploadHeader = () => {
    const {
      tenantTerms: { termUser },
      userList,
      bulkUserCsvUpload: { filename, importedUserCount, status, uploadedAt },
    } = this.props;
    return (
      <div className="user-import-header">
        <div className="row">
          <div className="col-md-6">
            <div className="py-2">
              <strong>File Name:</strong>
              <span className="pl-15">{filename}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="py-2">
              <strong>Uploaded At:</strong>
              <span className="pl-15">{formatDateTime({ date: uploadedAt })}</span>
            </div>
          </div>
        </div>
        <div className="row py-2">
          <div className="col-md-6">
            <div className="py-2">
              <strong>Status:</strong>
              <span
                className={`pl-15 kt-font-${this.getCsvUploadStatusColor(
                  status
                )} kt-font-bold`}
              >
                {status}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="py-2">
              <strong>{`${termUser.singular} Count:`}</strong>
              <span className="pl-15">{importedUserCount}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderUserCsvTeamAssignmentData = () => {
    const {
      userList,
      teamList,
      roleList,
      tenantTerms: { termUser, termMember },
    } = this.props;
    const {
      bulkUserAssigmentData: { users: selectedUsers },
      isBulkEditMode,
    } = this.state;

    return (
      <div className="table-responsive" style={{ maxHeight: '60vh' }}>
        <table
          className="table table-bordered kt-table__row-equal-width"
          style={{ marginBottom: '150px' }}
        >
          <thead>
            <tr>
              <th className="td-50 text-center">
                {isBulkEditMode ? (
                  <label className="kt-checkbox kt-checkbox--brand kt-checkbox--bold">
                    <input
                      type="checkbox"
                      checked={this.isSelectedAll()}
                      onChange={this.selectAllUsers}
                    />
                    <span
                      className={
                        !this.isSelectedAll() && selectedUsers.length >= 1
                          ? 'checkbox-semi'
                          : ''
                      }
                    />
                  </label>
                ) : (
                  <span>#</span>
                )}
              </th>
              <th>Name</th>
              <th className="td-250">Email</th>
              <th className="td-250">Teams</th>
              {/* <th>Role</th> */}
            </tr>
          </thead>
          <tbody>
            {_.map(userList, (user, index) => {
              {
                /* const userRole = () =>
                _.find(roleList, {
                  id: this.getUserAssignmentData(user.id, 'role'),
                }); */
              }
              return (
                <tr key={user.id}>
                  <td className="td-50 text-center">
                    {isBulkEditMode ? (
                      <label className="kt-checkbox kt-checkbox--brand kt-checkbox--bold">
                        <input
                          type="checkbox"
                          checked={this.isSelected(user.id)}
                          onChange={() => this.handleUserSelect(user.id)}
                        />
                        <span />
                      </label>
                    ) : (
                      <span>{`${index + 1}`}</span>
                    )}
                  </td>
                  <td>{user.name || '-'}</td>
                  <td className="td-250">{user.email || '-'}</td>
                  <td className="td-250">
                    <MultiSelect
                      disabled={isBulkEditMode}
                      name="teams"
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      optionIdentifier="id"
                      placeholder={`Select Teams`}
                      options={teamList}
                      value={this.getUserAssignmentData(user.id, 'teams') || []}
                      onChange={values =>
                        this.handleTeamAndRoleSelection(user.id, values, 'teams')
                      }
                      onBlur={() =>
                        this.handleUserTeamAssignSubmit(SINGLE_EDIT_MODE, user.id)
                      }
                    />
                  </td>
                  {/* Due To several Edge Cases, Roles Assignment is not allowed for now.
                    <td>{!_.isEmpty(userRole()) ? userRole().name : termMember.singular}</td>
                  */}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-center">
          <button
            type="button"
            className="btn btn-lg btn-success"
            onMouseDown={this.handleAssignmentDone}
          >
            Done
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      isBulkEditMode,
      bulkUserAssigmentData: { users: selectedUsers, teams: bulkSelectedTeams },
      isModalOpen,
      loading,
      redirectLoading,
    } = this.state;
    const {
      tenantTerms: { termUser },
    } = this.props;
    return (
      <React.Fragment>
        <div className="kt-portlet">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">{`Bulk ${termUser.singular} CSV Import`}</h3>
            </div>
          </div>
          <div className="kt-portlet__body">
            {(loading || redirectLoading) && <Spinner />}
            {this.renderUserCsvUploadHeader()}
            <div className="kt-separator kt-separator--sm" />
            <div className="d-flex align-items-center justify-content-end my-4">
              {isBulkEditMode && (
                <button
                  className="btn btn-sm app-btn-primary mr-15"
                  disabled={_.isEmpty(selectedUsers)}
                  onClick={() => this.handleBulkAssignModal(true)}
                >
                  Assign Teams
                </button>
              )}
              <span className="ml-15">
                Bulk Assign Mode&nbsp;&nbsp;
                <Toggle
                  name={'bulkTeamAssignment'}
                  onClick={value => this.handleBulkAssignToggle(value)}
                  on={'ON'}
                  off={'OFF'}
                  width={80}
                  recalculateOnResize
                  offstyle="default"
                  active={isBulkEditMode}
                />
              </span>
            </div>
            {this.renderUserCsvTeamAssignmentData()}
          </div>
        </div>
        {isModalOpen && (
          <Modal
            title={`Bulk ${termUser.singular} Assignment`}
            onClose={() => this.handleBulkAssignModal(false)}
            renderContent={this.renderModalContent}
            renderFooter={() => (
              <button
                type="button"
                className="btn btn-lg btn-success"
                onClick={() => this.handleUserTeamAssignSubmit(BULK_EDIT_MODE)}
                disabled={_.isEmpty(bulkSelectedTeams)}
              >
                Done
              </button>
            )}
          />
        )}
      </React.Fragment>
    );
  }
}

UserTeamAssignment.propTypes = {
  teamList: PropTypes.array.isRequired,
  roleList: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  bulkUserCsvUpload: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    importedUserCount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    uploadedAt: PropTypes.string.isRequired,
  }).isRequired,
  links: PropTypes.shape({
    userTeamAssignmentLink: PropTypes.string.isRequired,
    redirectToMembersLink: PropTypes.string.isRequired,
  }).isRequired,
};

export default withErrorHandler(UserTeamAssignment);

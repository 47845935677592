import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Asset from './Asset';
import DynamicSelect from '../common/inputs/dynamicSelect';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import Http from '../common/Http';

import './AssetListWithFolderSelect.scss';

class AssetListWithFolderSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      assetFolderList: props.assetFolderList,
    };
  }

  handleNewFolderOption = handle => async optionLabel => {
    const { assetFolderList } = this.state;

    const {
      assetFoldersConfig: {
        resourceType,
        links: { assetFoldersCreation: assetFoldersCreationLink },
      },
      csrfToken,
    } = this.props;

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .post(assetFoldersCreationLink, {
        resource_type: resourceType,
        name: optionLabel,
      })
      .onSuccess(res => {
        const newFolder = res.data['asset_folder'];

        const newAssetFolderList = [
          ...assetFolderList,
          {
            id: newFolder.id,
            name: newFolder.name,
            enabled: newFolder.enabled,
          },
        ];

        this.setState({
          assetFolderList: newAssetFolderList,
        }, () => this.props.onAssetFolderChange(handle, newFolder.id));
      })
      .onError(error => {
        const errorMessage = _.get(
          error,
          'response.data.errors',
          'Error while creating folder'
        );

        this.setState(
          {
            loading: false,
          },
          () => alertErrorNotifications(errorMessage)
        );
      })
      .exec();
  };

  render() {
    const { assets, assetsWithFolders, onAssetDelete, onAssetFolderChange } = this.props;

    const { assetFolderList, loading } = this.state;

    if (_.isEmpty(assets)) return null;

    return (
      <div
        className="table-responsive asset-with-folder-select-container"
        style={{
          marginBottom: '60px',
          minHeight: '300px',
        }}
      >
        <table className="table table-striped asset-folder__table">
          <thead>
            <tr>
              <th className="preview-icon">
                <span>Preview</span>
              </th>
              <th className="file-name">
                <span>File Name</span>
              </th>
              <th className="folder-name">
                <span>Select Folder</span>
              </th>
              <th className="actions">
                <span />
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(
              assets,
              ({ id, filename, mimetype, handle, url, deletable }, index) => (
                <tr key={index}>
                  <td className="preview-icon">
                    <Asset
                      className={'asset-inside-row'}
                      id={id}
                      mimetype={mimetype}
                      url={url}
                    />
                  </td>
                  <td className="file-name">
                    <span>{filename}</span>
                  </td>
                  <td className="folder-name">
                    <span>
                      <DynamicSelect
                        name={'assetFolder'}
                        isLoading={loading}
                        isDisabled={loading}
                        options={assetFolderList}
                        getOptionLabel={option => option.name}
                        getOptionValue={option => option.id}
                        getNewOptionData={(inputValue, optionLabel) => ({
                          __isNew__: true,
                          id: inputValue,
                          name: optionLabel,
                        })}
                        value={_.find(assetFolderList, { id: assetsWithFolders[handle] }) || ''}
                        showNewOptionAtTop
                        onChange={option => {
                          onAssetFolderChange(handle, option.id);
                        }}
                        onCreateOption={this.handleNewFolderOption(handle)}
                      />
                    </span>
                  </td>
                  {(!id || (id && deletable)) && (
                    <td className="actions">
                      <span>
                        <div
                          className="btn-sm btn btn-darkred btn-pill"
                          onClick={() => onAssetDelete(id, handle)}
                        >
                          <span>
                            <i className="la la-trash-o" />
                            <span>Delete</span>
                          </span>
                        </div>
                      </span>
                    </td>
                  )}
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

AssetListWithFolderSelect.propTypes = {
  assets: PropTypes.array.isRequired,
  assetsWithFolders: PropTypes.object.isRequired,
  assetFolders: PropTypes.array.isRequired,
  onAssetDelete: PropTypes.func.isRequired,
  onAssetFolderChange: PropTypes.func.isRequired,
  assetFolderList: PropTypes.array.isRequired,
};

AssetListWithFolderSelect.defaultProps = {
  assets: [],
  assetsWithFolders: {},
  assetFolders: [],
};

export default AssetListWithFolderSelect;

import React, { Fragment, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Http from '../../common/Http';
import { convertArrayOfObjectsToHash } from '../../common/utils';
import Reply from './Reply';
import ReplyForm from './ReplyForm';
import RepliesContextProvider from '../../common/ContextProvider';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { alertErrorNotifications, alertSuccessNotifications } from '../../folders/utils';

// const NEW_REPLY = {
//   body: '',
//   attachments: [],
// };

export const RepliesContext = React.createContext();

const Replies = ({
  links: { viewReplies, postReply },
  csrfToken,
  // topicInfo: { body, recentActivityAt, postedAt },
  currentUserId,
  replyAttachmentsConfig,
  isCourseAdmin,
}) => {
  const [replies, setReplies] = useState([]);
  const [respondents, setRespondents] = useState({});
  const [loading, setLoading] = useState(true);
  const [clearFormValues, setClearFormValues] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const fetchReplies = React.useCallback(() => {
    new Http()
      .setToken(csrfToken)
      .get(viewReplies)
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { replies, respondents } }) => {
        setReplies(replies);
        setRespondents(prevRespondents => ({
          ...prevRespondents,
          ...convertArrayOfObjectsToHash(respondents, 'id'),
        }));
        setLoading(false);
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  }, [csrfToken, viewReplies]);

  const handleTogglePin = (reply) => {
    if (loading) return;

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .post(reply.links.togglePin)
      .useAPIDataFormatters({
        camelizeResponseData: true,
      })
      .onSuccess(() => {
        alertSuccessNotifications(`${reply.pinnedAt ? 'Unpinned' : 'Pinned'} Reply Successfully`);
        setReplies([]);
        setRespondents({});
        fetchReplies();
      })
      .onError(() => {
        alertErrorNotifications(`Unable to ${reply.pinnedAt ? 'unpin' : 'pin'} the reply`);
        setLoading(false);
      })
      .exec();
  };

  useEffect(() => {
    fetchReplies();

    // only when the component is mount and not on every state update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const newReplySubmissionHandle = React.useCallback(
    formData => {
      new Http()
        .useAlerts()
        .onBegin(() => {
          setIsFormLoading(true);
          setClearFormValues(false);
        })
        .setToken(csrfToken)
        .post(postReply)
        .setPostData({
          body: formData.body,
          attachments: formData.attachments,
        })
        .useAPIDataFormatters({
          snakifyRequestData: true,
          camelizeResponseData: true,
        })
        .onSuccess(({ data: { reply } }) => {
          setReplies(prevReplies => [...prevReplies, reply]);
          setRespondents(prevRespondents => {
            return {
              ...prevRespondents,
              [reply.respondent.id]: reply.respondent,
            };
          });
          setIsFormLoading(false);
          setClearFormValues(true);
          return true;
        })
        .onError(() => {
          setIsFormLoading(false);
          return false;
        })
        .exec();
    },
    [csrfToken, postReply]
  );

  const repliesContextValues = React.useMemo(
    () => ({
      reloadReplies: fetchReplies,
    }),
    [fetchReplies]
  );

  return (
    <RepliesContextProvider
      ComponentContext={RepliesContext}
      value={repliesContextValues}
    >
      <Fragment>
        {loading ? (
          <div className="text-center my-5">
            <div className="spinner-border text-secondary mb-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <h3 className="text-black-50">Loading Replies...</h3>
          </div>
        ) : isEmpty(replies) ? (
          <h3 className="text-center my-5">
            <i className="far fa-comments" style={{ fontSize: '60px' }}></i>
            <br />
            No Replies Yet
          </h3>
        ) : (
          <div className="replies-container topic no-hover-effect">
            <Reply
              replies={replies}
              respondents={respondents}
              level={0}
              csrfToken={csrfToken}
              currentUserId={currentUserId}
              replyAttachmentsConfig={replyAttachmentsConfig}
              handleTogglePin={handleTogglePin}
              isCourseAdmin={isCourseAdmin}
            />
          </div>
        )}
        <div className="replies-container">
          <div className="reply no-border mt-2">
            <ReplyForm
              onSubmit={newReplySubmissionHandle}
              clearFormValues={clearFormValues}
              loading={isFormLoading}
              replyAttachmentsConfig={replyAttachmentsConfig}
              isFocused={false}
              csrfToken={csrfToken}
            />
          </div>
        </div>
      </Fragment>
    </RepliesContextProvider>
  );
};

Replies.propTypes = {
  links: PropTypes.shape({
    viewReplies: PropTypes.string,
    postReply: PropTypes.string,
  }),
  csrfToken: PropTypes.string.isRequired,
  topicInfo: PropTypes.shape({
    body: PropTypes.string,
    recentActivityAt: PropTypes.string,
    postedAt: PropTypes.string,
  }),
  currentUserId: PropTypes.number,
  replyAttachmentsConfig: PropTypes.shape({}),
  isCourseAdmin: PropTypes.bool.isRequired,
};

export default withErrorHandler(memo(Replies));

import React from 'react';
import PropTypes from 'prop-types';

import Toggle from 'react-bootstrap-toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import './styles.scss';

const CustomToggle = props => {
  const isDisabled = props.disabled;
  return (
    <div className="custom-toggle d-inline-block">
      <Toggle
        {...props}
        offstyle=" "
        onClassName="app-btn-primary"
        offClassName={`app-btn-outline-primary ${
          isDisabled ? 'no-hover-effect' : ''
        }`}
        onstyle=" "
        handleClassName="custom-toggle-handle"
      />
    </div>
  );
};

CustomToggle.propTypes = {
  disabled: PropTypes.bool,
};

export default CustomToggle;

import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import RadioButtonGroup from '../../common/inputs/RadioButtonGroup';
import CourseTable from './CourseTable';

const COURSE_STATUS = Object.freeze({
  published: 'published',
  unpublished: 'unpublished',
  archived: 'archived',
});

const PUBLISHING_FILTER_BUTTON = [
  { id: COURSE_STATUS.published, label: 'Published', color: 'success' },
  { id: COURSE_STATUS.unpublished, label: 'Unpublished', color: 'warning' },
  { id: COURSE_STATUS.archived, label: 'Archived', color: 'warning' },
];

const ManageCourses = ({
  courses,
  tenantTerms,
  links,
  csrfToken,
  curriculumCloneConfig,
}) => {
  const { termCourse } = tenantTerms;

  const initialCourses = useMemo(() => courses, [courses]);

  const [publishedCourseList, setPublishedCourseList] = useState(
    initialCourses.published
  );
  const [unpublishedCourseList, setUnpublishedCourseList] = useState(
    initialCourses.unpublished
  );
  const [archivedCourseList, setArchivedCourseList] = useState(
    initialCourses.archived
  );
  const [courseFilterText, setCourseFilterText] = useState('');
  const [courseStatus, setCourseStatus] = useState(COURSE_STATUS.published);

  useEffect(() => {
    if (!courseFilterText) {
      setPublishedCourseList(initialCourses.published);
      setUnpublishedCourseList(initialCourses.unpublished);
      setArchivedCourseList(initialCourses.archived);
      return;
    }
    const newCourseList = filter(initialCourses[courseStatus], course =>
      course.name.toLowerCase().includes(courseFilterText.toLowerCase())
    );

    if (courseStatus === COURSE_STATUS.published) {
      setPublishedCourseList(newCourseList);
    }

    if (courseStatus === COURSE_STATUS.unpublished) {
      setUnpublishedCourseList(newCourseList);
    }

    if (courseStatus === COURSE_STATUS.archived) {
      setArchivedCourseList(newCourseList);
    }

    // This useEffect is depended on only courseFilterText, having separate useEffect for the courseStatus
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseFilterText]);

  useEffect(() => {
    setCourseFilterText('');
  }, [courseStatus]);

  const commonCourseTableProps = {
    tenantTerms,
    csrfToken,
    links,
    curriculumCloneConfig,
  };
  return (
    <Fragment>
      <div className="kt-subheader px-0 course-dashboard ">
        <div className="course-header--responsive align-items-baseline">
          <div className="m-2 d-flex align-items-center text-truncate">
            <h4 className="kt-subheader__title mb-0 text-truncate">
              {termCourse.plural}
            </h4>
            {links?.newCourse && (
              <a
                href={links.newCourse}
                className="app-btn-primary m-0 ml-2 rounded-button-link"
                title={`Add New ${termCourse.singular}`}
              >
                <span className="fas fa-plus"></span>
              </a>
            )}
          </div>
          <div
            style={{ minWidth: 300 }}
            className="m-2 d-flex justify-content-center align-items-center flex-md-row flex-column"
          >
            <div className="form-group kt-form__group d-flex align-items-center mb-2">
              <span className="font-weight-bold pr-1">Filter: </span>

              <input
                style={{
                  height: 40,
                }}
                name="courseFilterText"
                onChange={({ target }) => setCourseFilterText(target.value)}
                type="text"
                title="Filter by Name"
                className="form-control kt-input"
                placeholder="Filter by Name"
                value={courseFilterText}
              />
            </div>
            <RadioButtonGroup
              className="ml-2 mb-2"
              checkedId={courseStatus}
              onChange={({ target }) => setCourseStatus(target.value)}
              buttonList={PUBLISHING_FILTER_BUTTON}
            />
          </div>
        </div>
      </div>
      <div className="pb-5 pt-0">
        <div className="kt-content">
          {courseStatus === COURSE_STATUS.published && (
            <Fragment>
              {!!courseFilterText && (
                <p className="kt-font-info">* Clear filter to reorder</p>
              )}
              <CourseTable
                courses={publishedCourseList}
                sortable={!courseFilterText} // Do not allow sorting when filtered, order gets messed up as it is index based
                afterSortSuccess={setPublishedCourseList}
                {...commonCourseTableProps}
              />
            </Fragment>
          )}
          {courseStatus === COURSE_STATUS.unpublished && (
            <CourseTable
              courses={unpublishedCourseList}
              {...commonCourseTableProps}
            />
          )}
          {courseStatus === COURSE_STATUS.archived && (
            <CourseTable
              courses={archivedCourseList}
              {...commonCourseTableProps}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

ManageCourses.propTypes = {
  courses: PropTypes.shape({
    published: PropTypes.array.isRequired,
    unpublished: PropTypes.array.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    newCourse: PropTypes.string,
    changeOrder: PropTypes.string,
  }),
  csrfToken: PropTypes.string,
  curriculumCloneConfig: PropTypes.shape({
    currentCurriculum: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    remainingCloneTargetCurriculums: PropTypes.array,
  }),
};

export default withErrorHandler(ManageCourses);

import React, { useState } from 'react';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { withErrorHandler } from '../hoc/withErrorHandler';

const ProjectActionButtons = ({ links, csrfToken }) => {
  const [loading, setLoading] = useState(false)
  const deleteProject = () => {
    new Http()
    .setToken(csrfToken)
    .onBegin(() => setLoading(true))
    .delete(links.delete)
    .useAPIDataFormatters({
      camelizeResponseData: true
    })
    .useAlerts()
    .doesRedirect(true)
    .onSuccess(({ data }) => {
      window.location.href = data.redirectionUrl;
    })
    .exec();
  }
  return loading ? <Spinner /> : (
    <div className="btn-group" role="group">
      <a
        href={links.show}
        type="button"
        className="app-btn-outline-secondary btn-icon btn-sm"
      >
        <i className="la la-eye" />
      </a>
      {
        !_.isNil(links.edit) && (
          <a
            href={links.edit}
            type="button"
            className="app-btn-outline-secondary btn-icon btn-sm"
          >
            <i className="la la-edit" />
          </a>
        )
      }
      {
        !_.isNil(links.delete) && (
          <button
            onClick={deleteProject}
            className="app-btn-outline-secondary btn-icon text-darkred btn-sm"
          >
            <i className="la la-trash" />
          </button>
        )
      }
    </div>
  );
};

export default withErrorHandler(ProjectActionButtons);
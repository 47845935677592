import React from 'react';
import PropTypes from 'prop-types';

const FolderContainer = ({ folderSidebar, assetsHolder }) => {
  return (
    <div className="kt-content">
      <div className="kt-portlet ">
        <div className="kt-portlet__body  p-0">
          <div className="row p-0">
            <div className="col-md-12 col-lg-4">{folderSidebar}</div>
            <div className="col-md-12 col-lg-8">{assetsHolder}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

FolderContainer.propTypes = {
  folderSidebar: PropTypes.node.isRequired,
  assetsHolder: PropTypes.node.isRequired,
};

FolderContainer.defaultProps = {
  folderSidebar: null,
  assetsHolder: null,
};

export default FolderContainer;

import React from 'react';
import PropTypes from 'prop-types';

import FolderListRow from './FolderListRow';

const FolderList = ({
  folders,
  folderUrlProvider,
}) => (
  _.map(folders, (folder, index) => (
    <FolderListRow
      key={`folderListRow-${index}`}
      folderName={folder.name}
      folderUrl={folderUrlProvider(folder.id)}
    />
  ))
);

FolderList.propTypes = {
  folders: PropTypes.array,
  folderUrlProvider: PropTypes.func,
};

FolderList.defaultProps = {
  folders: [],
  folderUrlProvider: () => { console.log('Default Props Folder url Provider') },
};

export default FolderList;

import React from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import map from 'lodash/map';
import uniqWith from 'lodash/uniqWith';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';

import moment from 'moment';

import Http from '../common/Http';
import MultiSelect from '../common/inputs/multiSelect';
import Select from '../common/inputs/select';
import DatePicker from 'react-datepicker';
import Spinner from '../common/presentational/spinner';
import { SkeletonRow, SkeletonWrap } from '../SkeletonLoading/index';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime, getDateInstance } from '../common/utils';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';

const MAIL_DELIVERY_TYPE_OPTIONS = [
  { label: 'Inbound', value: 'inbound' },
  { label: 'Outbound', value: 'outbound' },
];

const EmailNotificationLogs = props => {
  const {
    links: { fetchLogs: fetchLogsLink },
    csrfToken,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(moment().local());
  const [emailLogs, setEmailLogs] = React.useState([]);
  const [selectedValues, setSelectedValues] = React.useState({
    resources: '',
    recipients: [],
  });
  const [resourceNameOptions, setResourceNameOptions] = React.useState([]);
  const [recipientOptions, setRecipientOptions] = React.useState([]);

  const fetchLogs = React.useCallback(async () => {
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(fetchLogsLink)
      .setQueryParams({
        sent_at: selectedDate.format(),
      })
      .onSuccess(res => {
        const { data } = res;
        const nameOptions = uniq(
          compact(map(data['email_notification_logs'], 'resourceName'))
        );
        const recipients = map(
          data['email_notification_logs'],
          ({ recipient }) => {
            return { label: recipient['name'], value: recipient['id'] };
          }
        );
        const recipientOptionsArray = uniqWith(recipients, isEqual);
        setLoading(false);
        setEmailLogs(data['email_notification_logs']);
        setResourceNameOptions(nameOptions);
        setRecipientOptions(recipientOptionsArray);
      })
      .onError(err => {
        setLoading(false);
        alertErrorNotifications(err || 'Error fetching Email Logs!');
      })
      .exec();
  }, [csrfToken, fetchLogsLink, selectedDate]);

  React.useEffect(() => {
    fetchLogs();

    // only run on the initial mount of the component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    fetchLogs();
    setSelectedValues({
      resources: '',
      recipients: [],
    });
  }, [fetchLogs, selectedDate]);

  const handleFilterAttributeChange = (value, attribute) => {
    let newSelectedValues;
    let newEmailLogs = emailLogs;
    newSelectedValues = {
      ...selectedValues,
      [attribute]: value,
    };
    setSelectedValues(newSelectedValues);
    setEmailLogs(newEmailLogs);
  };

  const getFilterAttributeValue = attribute => selectedValues[attribute];

  const handleDateChange = date => {
    const localDate = moment(date).local();
    setSelectedDate(localDate);
  };

  const handleDateNavigation = type => {
    let newSelectedDate = selectedDate;
    if (type === 'prev') {
      newSelectedDate = moment(selectedDate).subtract(1, 'd');
    } else if (type === 'next') {
      newSelectedDate = moment(selectedDate).add(1, 'd');
    }

    setSelectedDate(newSelectedDate);
  };

  const filterLogs = React.useCallback(() => {
    const { resources, recipients, deliveryType } = selectedValues;

    let filteredLogs;
    if (!isEmpty(resources)) {
      filteredLogs = filter(emailLogs, { resourceName: resources });
    }

    if (!isEmpty(recipients)) {
      const recipientsIds = map(recipients, 'value');
      let filterLogsFrom = emailLogs;
      if (!isEmpty(filteredLogs)) {
        filterLogsFrom = filteredLogs;
      }
      filteredLogs = filter(filterLogsFrom, ({ recipient }) =>
        includes(recipientsIds, recipient['id'])
      );
    }

    if (!isEmpty(deliveryType)) {
      filteredLogs = filter(emailLogs, { deliveryType });
    }

    return filteredLogs;
  }, [emailLogs, selectedValues]);

  const renderFilters = () => {
    return (
      <div className="mt-20 mb-30">
        <div className="form-group kt-form__group row d-flex justify-content-center ml-15 mr-15">
          <div className="d-flex justify-content-center flex-column flex-sm-row">
            <div className="text-center m-1">
              <button
                className="app-btn-primary btn-wide"
                onClick={() => handleDateNavigation('prev')}
              >
                Prev
              </button>
            </div>
            <div className="m-1">
              <DatePicker
                dropdownMode={'scroll'}
                selected={getDateInstance(selectedDate)}
                showTimeSelect={false}
                onChange={date => handleDateChange(date)}
                className={'form-control form-filter '}
                placeholderText="Select a Date"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
                showYearDropdown
                showMonthDropdown
              />
            </div>
            <div className="text-center m-1">
              <button
                className="app-btn-primary btn-wide"
                onClick={() => handleDateNavigation('next')}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div className="row ml-15 mr-15 mt-20 mb-20 justify-content-center">
          <div className="col-md-4 my-1">
            <Select
              name="resources"
              options={
                map(resourceNameOptions, resource => ({
                  label: startCase(resource),
                  value: resource,
                })) || []
              }
              value={getFilterAttributeValue('resources') || ''}
              optionIdentifier="value"
              onChange={({ value }) =>
                handleFilterAttributeChange(value, 'resources')
              }
              placeholder="Select Resources"
              noOptionsMessage={() => 'No Resources found'}
            />
          </div>
          <div className="col-md-4 my-1">
            <MultiSelect
              placeholder={'Select Recipients'}
              options={recipientOptions || []}
              value={getFilterAttributeValue('recipients')}
              onChange={values =>
                handleFilterAttributeChange(values, 'recipients')
              }
              closeMenuOnSelect={false}
              noOptionsMessage={() => 'No Recipients found'}
            />
          </div>
          <div className="col-md-4 my-1">
            <Select
              name="deliveryType"
              options={MAIL_DELIVERY_TYPE_OPTIONS}
              value={getFilterAttributeValue('deliveryType') || ''}
              onChange={({ value }) =>
                handleFilterAttributeChange(value, 'deliveryType')
              }
              optionIdentifier="value"
              placeholder="Select Mail Type"
              noOptionsMessage={() => 'No mail type Found'}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLogItem = (
    {
      id,
      resourceName,
      deliveryType,
      jobName,
      recipient: { name },
      sentAt,
      to,
    },
    index
  ) => (
    <tr key={id} style={{ height: 50 }}>
      <td>{index + 1}</td>
      <td title={resourceName}>
        {resourceName || '--'}
        <span
          className={`btn btn-bold btn-sm btn-font-sm  btn-label-${
            isEqual(deliveryType, 'inbound') ? 'success' : 'danger'
          } ml-2`}
        >
          {capitalize(deliveryType)}
        </span>
      </td>
      <td title={jobName}>{jobName || '--'}</td>
      <td>
        {name || ''}
        &nbsp;&lt;&nbsp;{to}&nbsp;&gt;
      </td>
      <td>{moment(sentAt).format('HH:mm:ss')}</td>
    </tr>
  );

  const filteredLogs = React.useMemo(() => {
    const { resources, recipients, deliveryType } = selectedValues;
    if (!isEmpty(resources) || !isEmpty(recipients) || !isEmpty(deliveryType)) {
      return filterLogs();
    }
    return emailLogs;
  }, [emailLogs, filterLogs, selectedValues]);

  return (
    <React.Fragment>
      <div className="kt-content">
        <div className="kt-portlet kt-portlet--tabs">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title text-center font-20">
                Email Logs
                <small className="kt-font-info">
                  {formatDateTime({ date: selectedDate, formatTime: false })}
                </small>
              </h3>
            </div>
          </div>
          <div className="kt-portlet__body plr-0">
            <div className="col-md-12 plr-0">
              {renderFilters()}
              {loading ? (
                <React.Fragment>
                  <SkeletonWrap>
                    <SkeletonRow header />
                    <br />
                    <SkeletonRow rows={8} />
                  </SkeletonWrap>
                  <Spinner />
                </React.Fragment>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Resource</th>
                        <th>Summary</th>
                        <th>Recipient</th>
                        <th>
                          Time<small> (Local)</small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(filteredLogs, (log, index) =>
                        renderLogItem(log, index)
                      )}
                      {isEmpty(filteredLogs) && (
                        <tr>
                          <td colSpan={5} className="text-center">
                            <h4>No Logs for the day.</h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

EmailNotificationLogs.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    fetchLogs: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(EmailNotificationLogs);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Asset from '../Asset';
import { humanFileSize } from '../../common/utils';

import './GallerListView.scss';

const DownLoadableAssetItem = ({ downloadLink, children }) =>
  !_.isEmpty(downloadLink) ? (
    <a href={downloadLink} className="list-file-download">
      {children}
    </a>
  ) : (
    children
  );

const GalleryListView = ({
  assets,
  onAssetDelete,
  galleryClassName,
  showAssetDeleteOption,
}) => (
  <div className={galleryClassName ? galleryClassName : 'row'}>
    {_.map(
      assets,
      ({ id, filename, mimetype, handle, size, url, deletable, links }, index) => {
        const isDownloadable = !_.isEmpty(links) && !_.isNil(links.download);
        return (
          <div
            className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12"
            key={index}
            style={{ margin: '10px 0', minWidth: 120 }}
          >
            <div className="list-view-file">
              <div className="list-view-asset">
                <DownLoadableAssetItem
                  downloadLink={isDownloadable ? links.download : ''}
                >
                  <Asset
                    id={id}
                    mimetype={mimetype}
                    url={url}
                    className="list-view-image"
                  />
                  {isDownloadable && (
                    <span className="list-file-download-hover d-flex justify-content-center align-items-center">
                      <i className="la la-download font-20" />
                    </span>
                  )}
                </DownLoadableAssetItem>
              </div>
              <div className="list-file-name text-truncate">
                {(_.isInteger(parseInt(id)) || filename) && (
                  <DownLoadableAssetItem
                    downloadLink={isDownloadable ? links.download : ''}
                  >
                    <h6
                      className="break-word filename-truncate"
                      title={filename}
                      style={{ color: '#575962', maxWidth: '11em' }}
                    >
                      {filename}
                    </h6>
                  </DownLoadableAssetItem>
                )}
                <div className="list-file-details">
                  <span className="kt-date text-muted" style={{ marginRight: '1.2em' }}>
                    {humanFileSize(size)}
                  </span>
                  {showAssetDeleteOption && (!id || (id && deletable)) && (
                    <div>
                      <span
                        type="button"
                        className="text-darkred"
                        style={{ cursor: 'pointer', fontWeight: 500 }}
                        onClick={() => onAssetDelete(id, handle)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }
    )}
  </div>
);

GalleryListView.propTypes = {
  galleryClassName: PropTypes.string,
  assets: PropTypes.array.isRequired,
  onAssetDelete: PropTypes.func.isRequired,
  showAssetDeleteOption: PropTypes.bool,
};

GalleryListView.defaultProps = {
  showAssetDeleteOption: true,
};

export default GalleryListView;

import React from 'react';
import PropTypes from 'prop-types';
import { getAssetTypeFromMIME, renderAttachmentThumbnail } from '../utils';
import SmoothImage from '../presentational/SmoothImage';

const AssetPreview = ({ mimetype, url, poster, className }) => {
  const renderMemeType = () => {
    switch (getAssetTypeFromMIME(mimetype)) {
      case 'image':
        return (
          <div className={`image ${className}`}>
            <SmoothImage src={url} />
          </div>
        );

      case 'video':
        return (
          <div className={`image ${className}`}>
            {poster && <div className="video-overlay"></div>}
            <SmoothImage src={renderAttachmentThumbnail(mimetype, poster)} />
          </div>
        );

      case 'file':
        return (
          <div
            className={`icon ${className}`}
            style={{
              backgroundImage: `url(${renderAttachmentThumbnail(mimetype)})`,
            }}
          />
        );

      default:
        break;
    }
  };

  return renderMemeType();
};

AssetPreview.propTypes = {
  mimetype: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AssetPreview.defaultProps = {
  className: '',
};

export default AssetPreview;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import Toggle from '../../common/inputs/Toggle';
import ReactDraftWysiwygEditor from '../../wysiwyg_editor/index';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';

import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Text from '../../common/inputs/text';
import CheckBox from '../../common/inputs/checkBox';
import AssetManager from '../../filestack/AssetManager';
import FormRow from '../../common/presentational/formRow';
import Spinner from '../../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
  renderOptions,
  addPageLeavePreventDialog,
} from '../../common/utils';
import SubmitButton from '../../common/presentational/submitButton';
import { getAssetAttributes } from '../../filestack/utils/getAssetAttributes';
import CancelButton from '../../common/presentational/cancelButton';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import SelectInput from '../../common/inputs/select';
import MultiSelect from '../../common/inputs/multiSelect';

class CourseAssessmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      attachments: [],
      existingAttachments: props.existingAttachments,
      attachmentsOrder: [_.map(props.existingAttachments, 'handle')], //it contains only handles
      [props.fieldsPrefix]: Object.assign({}, props.attributes, {
        task: EditorState.createEmpty(), // Make it empty and later pass in the data
      }),
      evaluators: props.existingEvaluatorIds,
    };
  }

  componentDidMount() {
    const {
      attributes: { task },
    } = this.props;
    // if task is present do conversion and save and display in editor
    try {
      if (task) {
        const contentBlock = htmlToDraft(task);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setModelAttributeState('task', editorState);
        }
      }
    } catch (e) {
      //
    }

    addPageLeavePreventDialog();
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  setModelAttributeState = (fieldName, value) => {
    const updatedModelAttributes = {
      ...this.state[this.props.fieldsPrefix],
      [fieldName]: value,
    };

    this.setState({
      [this.props.fieldsPrefix]: updatedModelAttributes,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setModelAttributeState('task', editorState);
  };

  onAttachmentChange = (attachments, existingAttachments) => {
    let attachmentsOrder = _.map(attachments, 'handle');

    this.setState({
      attachments: attachments,
      existingAttachments: existingAttachments,
      attachmentsOrder: attachmentsOrder,
    });
  };

  getModelAttributeValue = (attribute) =>
    this.state[this.props.fieldsPrefix][attribute] || '';

  handleModelAttributeChange = (attributeName = '') => {
    return (event) => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, (option) => option.value)
          : event.value;

        this.setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        this.setModelAttributeState(targetName, targetValue);
      }
    };
  };

  onSortComplete = (assetType, assets) => {
    let handlesInOrder = _.map(assets, 'handle');

    this.setState({
      [assetType]: handlesInOrder,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { courseId, courseAssessmentId, csrfToken, links } = this.props;

    const { attachments, attachmentsOrder, evaluators } = this.state;

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );
    const evaluatorIds = evaluators.map((e) => e.id);

    // task is not in html format, convert it
    updatedModelAttributes.task = draftToHtml(
      convertToRaw(updatedModelAttributes.task.getCurrentContent())
    );
    updatedModelAttributes.task = updatedModelAttributes.task.replace('<p></p>\n', '');

    const attachmentsAttributes = getAssetAttributes(attachments);

    let Requester = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedModelAttributes,
          attachments: attachmentsAttributes,
          attachments_order: attachmentsOrder,
          evaluator_ids: evaluatorIds,
        },
      })
      .onSuccess((_response) => {
        removePageLeavePreventDialog();
        window.location.href = links.courseAdminContent;
      });

    if (_.isNil(courseAssessmentId)) {
      Requester = Requester.post(links.createAssessment);
    } else {
      Requester = Requester.patch(links.updateAssessment);
    }

    await Requester.exec();
  };

  getCheckboxValue = (key) => {
    return this.getModelAttributeValue(key) === ''
      ? false
      : this.getModelAttributeValue(key);
  };

  render() {
    const {
      loading,
      attachments,
      existingAttachments,
      attachmentsOrder,
      evaluators,
    } = this.state;

    const {
      csrfToken,
      attachmentOptions,
      enableAssetLinking,
      links,
      courseForumEnabled,
      tenantTerms: { termReviewer },
      assignableEvaluators,
    } = this.props;
    const { directUploadUrl } = links;

    const allowImages = this.getCheckboxValue('allowImages');
    const allowVideo = this.getCheckboxValue('allowVideo');
    const allowWebcam = this.getCheckboxValue('allowWebcam');
    const allowDocuments = this.getCheckboxValue('allowDocuments');
    const allowMultiple = this.getCheckboxValue('allowMultiple');
    const selfEvaluations = this.getCheckboxValue('selfEvaluations');
    const allowAssetLinking = this.getCheckboxValue('allowAssetLinking');

    return (
      <form
        encType="multipart/form-data"
        className="kt-form kt-form--label-right"
        onSubmit={this.handleSubmit}
      >
        {loading && <Spinner />}
        <div className="kt-section kt-section--first">
          <FormRow label={'Name'}>
            <Text
              numberAttributes={{
                required: true,
              }}
              name={'name'}
              value={this.getModelAttributeValue('name')}
              onInputChange={this.handleModelAttributeChange('name')}
            />
          </FormRow>

          <FormRow label={'Description'}>
            <ReactDraftWysiwygEditor
              editorState={this.getModelAttributeValue('task')}
              onEditorStateChange={this.onEditorStateChange}
              token={csrfToken}
              directUploadUrl={directUploadUrl}
            />
          </FormRow>

          <FormRow label={termReviewer.plural}>
            <MultiSelect
              placeholder={`Select ${termReviewer.plural}`}
              name="reviewers"
              options={assignableEvaluators}
              onChange={(values) => this.setState({ evaluators: values })}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              value={evaluators}
              optionIdentifier="id"
            />
          </FormRow>
        </div>
        <div className="kt-separator kt-separator--dashed" />
        <div className="kt-section">
          <FormRow label={'Media Types'}>
            <CheckBox
              showLabel
              label={'Allow Images'}
              name={'allowImages'}
              value={allowImages}
              handleOnChange={this.handleModelAttributeChange('allowImages')}
            />
            <CheckBox
              showLabel
              label={'Allow Video'}
              name={'allowVideo'}
              value={allowVideo}
              handleOnChange={this.handleModelAttributeChange('allowVideo')}
            />
            <CheckBox
              showLabel
              label={'Allow Webcam'}
              name={'allowWebcam'}
              value={allowWebcam}
              handleOnChange={this.handleModelAttributeChange('allowWebcam')}
            />
            <CheckBox
              showLabel
              label={'Allow Documents'}
              name={'allowDocuments'}
              value={allowDocuments}
              handleOnChange={this.handleModelAttributeChange('allowDocuments')}
            />
          </FormRow>

          <FormRow label={'Settings'}>
            <CheckBox
              showLabel
              label={'Allow Self Evaluations'}
              name={'selfEvaluations'}
              value={selfEvaluations}
              handleOnChange={this.handleModelAttributeChange('selfEvaluations')}
            />
            <CheckBox
              showLabel
              label={'Allow Multiple Uploads'}
              name={'allowMultiple'}
              value={allowMultiple}
              handleOnChange={this.handleModelAttributeChange('allowMultiple')}
            />
            {enableAssetLinking && (
              <CheckBox
                showLabel
                label={'Allow Asset Linking'}
                name={'allowAssetLinking'}
                value={allowAssetLinking}
                handleOnChange={this.handleModelAttributeChange('allowAssetLinking')}
              />
            )}
          </FormRow>

          <FormRow label={'Asset Limit'}>
            <Text
              name={'assetLimit'}
              value={this.getModelAttributeValue('assetLimit') + ''}
              onInputChange={this.handleModelAttributeChange('assetLimit')}
            />
          </FormRow>
          {courseForumEnabled && (
            <FormRow label={'Discussion Enabled?'}>
              <Toggle
                name={'discussionEnabled'}
                onClick={(value) =>
                  this.setModelAttributeState('discussionEnabled', value)
                }
                on={<span>Yes</span>}
                off={<span>No</span>}
                size="sm"
                width={100}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={this.getModelAttributeValue('discussionEnabled')}
              />
            </FormRow>
          )}
          <FormRow label={'Generate Report'}>
            <Toggle
              name={'allowVideoReport'}
              onClick={(value) => this.setModelAttributeState('allowVideoReport', value)}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={this.getModelAttributeValue('allowVideoReport')}
            />
          </FormRow>
        </div>

        <div className="kt-separator kt-separator--dashed" />

        <div className="kt-section">
          <AssetManager
            label={'Attachments'}
            csrfToken={csrfToken}
            filestackOptions={attachmentOptions}
            existingAssets={existingAttachments}
            assets={attachments}
            onFileChange={this.onAttachmentChange}
            sortableGallery={true}
            sortOrder={attachmentsOrder}
            onSortComplete={(assets) => this.onSortComplete('attachmentsOrder', assets)}
          />
        </div>

        <div className="kt-separator kt-separator--dashed" />
        <div className="kt-section">
          <div className="col-lg-6 text-center">
            <CancelButton confirmCancel={false} />
            <SubmitButton buttonText={'Save'} />
          </div>
        </div>
      </form>
    );
  }
}

CourseAssessmentForm.defaultProps = {
  fieldsPrefix: 'course_assessment',
  attributes: {},
};

CourseAssessmentForm.propTypes = {
  fieldsPrefix: PropTypes.string,
  assignableEvaluators: PropTypes.array.isRequired,
  existingEvaluatorIds: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  courseAssessmentId: PropTypes.number,

  attachmentOptions: PropTypes.object.isRequired,

  attributes: PropTypes.object.isRequired,

  existingAttachments: PropTypes.array.isRequired,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    courseAdminContent: PropTypes.string.isRequired,
    createAssessment: PropTypes.string.isRequired,
    updateAssessment: PropTypes.string.isRequired,
    directUploadUrl: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseAssessmentForm);

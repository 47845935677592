import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from './utils';

// Used for rendering formated date time display
// When this component is created its used only in Rails views, but there shouldn't be a problem using n react views too.
// Props are same a params to `formatDateTime` method, any additional props is simply ignored for now.
function DateTime({ date, formatTime, timeOnly }) {
  return <span>{formatDateTime({ date, formatTime, timeOnly })}</span>;
}

DateTime.propTypes = {
  date: PropTypes.string.isRequired,
  formatTime: PropTypes.bool,
  timeOnly: PropTypes.bool,
};

DateTime.defaultProps = {
  formatTime: true,
  timeOnly: false,
};

export default DateTime;

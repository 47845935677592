// referred on the stackoverflow comment https://stackoverflow.com/a/3552493
const joinDateAttributes = (t, a, s) => {
  const format = m => {
    let date = new Intl.DateTimeFormat('en', m);
    return date.format(t);
  };
  return a.map(format).join(s);
};

export const formattedDateString = dateStr => {
  const newDate = new Date(dateStr);

  let format = [{ month: '2-digit' }, { day: '2-digit' }, { year: 'numeric' }];
  let formattedDate = joinDateAttributes(newDate, format, '/');
  return formattedDate;
};

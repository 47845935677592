import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import DatePicker from 'react-datepicker';

import map from 'lodash/map';
import compact from 'lodash/compact';
import castArray from 'lodash/castArray';
import isNil from 'lodash/isNil';
import isArray from 'lodash/isArray';

import ReactDraftWysiwygEditor from '../../wysiwyg_editor/index';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';

import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Toggle from '../../common/inputs/Toggle';
import Text from '../../common/inputs/text';
import AssetManager from '../../filestack/AssetManager';
import FormRow from '../../common/presentational/formRow';
import Spinner from '../../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
  renderOptions,
} from '../../common/utils';
import SubmitButton from '../../common/presentational/submitButton';
import { getAssetAttributes } from '../../filestack/utils/getAssetAttributes';
import { sortAssetsInOrder } from '../../filestack/utils/index.js';
import Http from '../../common/Http';
import TextArea from '../../common/inputs/textArea';
import SelectInput from '../../common/inputs/select';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import FormElementHelperText from '../../common/presentational/FormElementHelperText';
import ParticipantSelectWithList from './../ParticipantSelectWithList';
import { alertErrorNotifications } from '../../folders/utils';
import { DATE_TIME_PICKER_FORMATS } from '../../common/constants.js';

const getSettingsFormState = props => {
  const getHoursAndMinutesFromSeconds = seconds => {
    return {
      hours: Math.floor(seconds / 3600) || 0,
      minutes: Math.floor((seconds % 3600) / 60) || 0,
    };
  };

  return {
    videos: [],
    existingVideos: props.existingVideos,
    videosOrder: [map(props.existingVideos, 'handle')], //it contains only handles
    attachments: [],
    existingAttachments: props.existingAttachments,
    attachmentsOrder: [map(props.existingAttachments, 'handle')], //it contains only handles
    headerImages: [],
    thumbnailImages: [],
    selectedOwners: (props.owners || []),
    courseDuration: getHoursAndMinutesFromSeconds(props.attributes.duration),
    existingHeaderImages: compact(castArray(props.existingHeaderImage)),
    existingThumbnailImages: compact(castArray(props.existingThumbnailImage)),
    [props.fieldsPrefix]: Object.assign({}, props.attributes, {
      description: EditorState.createEmpty(), // Make it empty and later pass in the data
      expiryDate: props.attributes.expiryDate
        ? new Date(props.attributes.expiryDate)
        : null,
    }),
  };
};

const settingsFormStateReducer = (state, action) => {
  switch (action.type) {
    case 'handleModelAttributes': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'handleCourseDuration': {
      return {
        ...state,
        courseDuration: {
          ...state.courseDuration,
          ...action.payload,
        },
      };
    }
    case 'handleVideosChange': {
      const { videos, existingVideos, newOrder } = action.payload;
      return {
        ...state,
        videos,
        existingVideos,
        videosOrder: newOrder,
      };
    }
    case 'handleAttachmentChange': {
      const { attachments, existingAttachments, newOrder } = action.payload;
      return {
        ...state,
        attachments,
        existingAttachments,
        attachmentsOrder: newOrder,
      };
    }
    case 'handleHeaderImageChange': {
      const { headerImages, existingHeaderImages } = action.payload;
      return {
        ...state,
        headerImages,
        existingHeaderImages,
      };
    }
    case 'handleThumbnailImageChange': {
      const { thumbnailImages, existingThumbnailImages } = action.payload;
      return {
        ...state,
        thumbnailImages,
        existingThumbnailImages,
      };
    }
    case 'handleSortComplete': {
      return { ...state, ...action.payload };
    }
    case 'handleSetSelectedOwners': {
      return {
        ...state,
        selectedOwners: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

const SettingsForm = props => {
  const initialSettingsState = getSettingsFormState(props);
  const [settingsFormState, dispatchFormActions] = useReducer(
    settingsFormStateReducer,
    initialSettingsState
  );
  const [loading, setLoading] = useState(false);

  const setModelAttributeState = useCallback(
    (fieldName, value) => {
      const updatedModelAttributes = {
        [props.fieldsPrefix]: {
          ...settingsFormState[props.fieldsPrefix],
          [fieldName]: value,
        },
      };

      dispatchFormActions({
        type: 'handleModelAttributes',
        payload: updatedModelAttributes,
      });
    },

    [props.fieldsPrefix, settingsFormState]
  );

  const handleSetSelectedOwners = useCallback((ownerIds) => {
    dispatchFormActions({
      type: 'handleSetSelectedOwners',
      payload: ownerIds,
    });
  }, []);

  const handleCourseDuration = fieldName => event => {
    const { target } = event;
    let timeValue = target ? target.value : 0;
    const value = parseInt(timeValue) || 0;

    dispatchFormActions({
      type: 'handleCourseDuration',
      payload: {
        [fieldName]: value,
      },
    });
  };

  useEffect(() => {
    const { description } = props.attributes;
    // if description is present do conversion and save and display in editor
    try {
      if (description) {
        const contentBlock = htmlToDraft(description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          setModelAttributeState('description', editorState);
        }
      }
    } catch (e) {
      //
    }

    // the effect needs to updated when the props.attributes is changed not on every state update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.attributes]);

  const onEditorStateChange = editorState => {
    setModelAttributeState('description', editorState);
  };

  const onVideoChange = (videos, existingVideos) => {
    let { videosOrder } = settingsFormState;
    let newOrder = sortAssetsInOrder(videos, existingVideos, videosOrder);

    dispatchFormActions({
      type: 'handleVideosChange',
      payload: {
        videos,
        existingVideos,
        newOrder,
      },
    });
  };

  const onAttachmentChange = (attachments, existingAttachments) => {
    let { attachmentsOrder } = settingsFormState;
    let newOrder = sortAssetsInOrder(
      attachments,
      existingAttachments,
      attachmentsOrder
    );

    dispatchFormActions({
      type: 'handleAttachmentChange',
      payload: {
        attachments,
        existingAttachments,
        newOrder,
      },
    });
  };

  const onHeaderImageChange = (headerImages, existingHeaderImages) => {
    dispatchFormActions({
      type: 'handleHeaderImageChange',
      payload: {
        headerImages: headerImages || [],
        existingHeaderImages: existingHeaderImages || [],
      },
    });
  };

  const onThumbnailImageChange = (thumbnailImages, existingThumbnailImages) => {
    dispatchFormActions({
      type: 'handleThumbnailImageChange',
      payload: {
        thumbnailImages: thumbnailImages || [],
        existingThumbnailImages: existingThumbnailImages || [],
      },
    });
  };

  const getModelAttributeValue = attribute =>
    settingsFormState[props.fieldsPrefix][attribute] || '';

  const handleModelAttributeChange = (attributeName = '') => {
    return event => {
      const { target } = event;

      // Select Input Changed
      if (isNil(target)) {
        const targetValue = isArray(event)
          ? map(event, option => option.value)
          : event.value;

        setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue =
          target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(targetName, targetValue);
      }
    };
  };

  const handleToggle = attribute => value => {
    setModelAttributeState(attribute, value);
  };

  const onSortComplete = (assetType, assets) => {
    let handlesInOrder = map(assets, 'handle');

    dispatchFormActions({
      type: 'handleSortComplete',
      payload: {
        [assetType]: handlesInOrder,
      },
    });
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { csrfToken, courseId, links } = props;
    const {
      videos,
      videosOrder,
      attachments,
      attachmentsOrder,
      headerImages,
      thumbnailImages,
      courseDuration: { hours, minutes },
      selectedOwners = [],
    } = settingsFormState;

    if (!selectedCurriculumId && (!selectedOwners || selectedOwners.length < 1)) {
      return alertErrorNotifications("Atleast one course owner must be selected");
    }

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      settingsFormState[props.fieldsPrefix]
    );
    const durationSeconds = parseInt(hours * 3600) + parseInt(minutes * 60);

    // description is not in html format, convert it
    updatedModelAttributes.description = draftToHtml(
      convertToRaw(updatedModelAttributes.description.getCurrentContent())
    );
    updatedModelAttributes.description =
      updatedModelAttributes.description.replace('<p></p>\n', '');

    // Add expiryDate to attributes
    updatedModelAttributes.expiry_date = settingsFormState[props.fieldsPrefix].expiryDate;

    const videosAttributes = getAssetAttributes(videos);
    const attachmentsAttributes = getAssetAttributes(attachments);
    const headerImagesAttributes = getAssetAttributes(headerImages);
    const thumbnailImagesAttributes = getAssetAttributes(thumbnailImages);

    let Requester = new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        course: {
          ...updatedModelAttributes,
          duration: durationSeconds,
          videos: videosAttributes,
          videos_order: videosOrder,
          attachments: attachmentsAttributes,
          attachments_order: attachmentsOrder,
          header_image: headerImagesAttributes,
          thumbnail_image: thumbnailImagesAttributes,
          owner_ids: selectedOwners,
        },
      })
      .onSuccess(response => {
        setLoading(false);
        removePageLeavePreventDialog();
        window.location.href =
          links.redirectionUrl || response.data.meta.redirection_url || '/';
      })
      .onError(() => setLoading(false));

    if (isNil(courseId)) {
      Requester = Requester.post(links.createCourse);
    } else {
      Requester = Requester.patch(links.updateCourse);
    }

    await Requester.exec();
  };

  const {
    videos,
    existingVideos,
    videosOrder,
    attachments,
    existingAttachments,
    attachmentsOrder,
    headerImages,
    thumbnailImages,
    existingHeaderImages,
    existingThumbnailImages,
    courseDuration,
    selectedOwners,
  } = settingsFormState;

  const {
    csrfToken,
    videoOptions,
    attachmentOptions,
    headerImageOptions,
    thumbnailImageOptions,
    links,
    curriculumOptions,
    userList,
    tenantTerms: { termCurriculum },
    meta: {
      overviewMaxLength,
      learningForumEnabledForOrganization,
      curriculumFlowEnabled,
      learningProgressEnabledForManagers,
    },
  } = props;
  const showHeaderImageUploader = useMemo(() => !(
    existingHeaderImages.length || headerImages.length
  ), [existingHeaderImages.length, headerImages.length]);
  const showThumbnailImageUploader = useMemo(() => !(
    existingThumbnailImages.length || thumbnailImages.length
  ), [existingThumbnailImages.length, thumbnailImages.length]);

  if (loading) {
    return <Spinner />;
  }

  const publishedValue =
    getModelAttributeValue('published') === ''
      ? false
      : getModelAttributeValue('published');
  const sequentialValue =
    getModelAttributeValue('sequential') === ''
      ? false
      : getModelAttributeValue('sequential');

  const selectedCurriculumId = getModelAttributeValue('curriculumId');
  const isManagerExclusive = !!getModelAttributeValue('managerExclusive');

  return (
    <form
      encType="multipart/form-data"
      className="kt-form kt-form--label-right kt-form--fit pt-20"
      onSubmit={handleSubmit}
    >
      <div className="kt-section kt-section--first">
        <FormRow label={'Status'}>
          <Toggle
            name={'published'}
            onClick={handleToggle('published')}
            on={<span>Published</span>}
            off={<span>Unpublished</span>}
            size="sm"
            width={100}
            height={30}
            recalculateOnResize
            offstyle="default"
            active={publishedValue}
          />
        </FormRow>

        <FormRow label={'Name'} className="required-setting">
          <Text
            numberAttributes={{
              required: true,
            }}
            name={'name'}
            value={getModelAttributeValue('name')}
            onInputChange={handleModelAttributeChange('name')}
          />
        </FormRow>

        <FormRow label={'Objective'} className="required-setting">
          <TextArea
            name={'objective'}
            value={getModelAttributeValue('objective')}
            maxLength={255}
            rows={8}
            onInputChange={handleModelAttributeChange('objective')}
          />
          <small className="text-darkred">* Max. 255 characters.</small>
        </FormRow>

        <ParticipantSelectWithList
          onChange={handleSetSelectedOwners}
          participantList={userList}
          selectedUsers={selectedOwners}
          participantListLabel={'Users'}
          participantLabel={'Course Owners'}
          showList={false}
          showHeaderText={false}
          helperText={'These users are able to edit the course.'}
          requiredField={true}
          mapUserIdWithList
        />


        <FormRow label={termCurriculum.singular}>
          <SelectInput
            placeholder={`Select ${termCurriculum.singular}`}
            name="curriculumId"
            options={renderOptions(curriculumOptions)}
            onChange={handleModelAttributeChange('curriculumId')}
            value={selectedCurriculumId || ''}
            optionIdentifier="value"
            isDisabled={curriculumFlowEnabled}
          />
          <FormElementHelperText
            text={`Learners can be enrolled only via ${termCurriculum.singular} when assigned`}
          />
        </FormRow>

        {learningForumEnabledForOrganization && (
          <FormRow label={'Discussion Forum'}>
            <Toggle
              name={'forumEnabled'}
              onClick={handleToggle('forumEnabled')}
              on={<span>Enabled</span>}
              off={<span>Disabled</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getModelAttributeValue('forumEnabled')}
            />
          </FormRow>
        )}

        {learningProgressEnabledForManagers && (
          <FormRow label={'Enable Learning Progress for Managers'}>
            <Toggle
              name={'enableLearningProgressForManagers'}
              onClick={handleToggle('enableLearningProgressForManagers')}
              on={<span>Enabled</span>}
              off={<span>Disabled</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getModelAttributeValue(
                'enableLearningProgressForManagers'
              )}
            />
            <FormElementHelperText text="Learner progress is visible to store managers when this is enabled." />
          </FormRow>
        )}

        {!selectedCurriculumId && (
          <FormRow label={'Manager Exclusive'}>
            <Toggle
              name={'managerExclusive'}
              onClick={handleToggle('managerExclusive')}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={isManagerExclusive}
            />
            <FormElementHelperText text="Only users who are a Manager of above can view and enroll in this course." />
          </FormRow>
        )}

          <FormRow label={'Public'}>
            <Toggle
              name={'public'}
              onClick={handleToggle('public')}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={!!getModelAttributeValue('public')}
            />
            <FormElementHelperText
              text={
                isManagerExclusive
                  ? "A Public course can be seen and enrolled by any manager."
                  : "A Public course can be seen and enrolled by any user."
              }
            />
          </FormRow>

          <FormRow label={'Brief Description'}>
            <TextArea
              name={'overview'}
              value={getModelAttributeValue('overview')}
              maxLength={overviewMaxLength}
              rows={8}
              onInputChange={handleModelAttributeChange('overview')}
            />
            <small className="text-darkred">{`* Max. ${overviewMaxLength} characters`}</small>
          </FormRow>

        <FormRow label={'Duration'} inputColClassName="d-flex">
          <div className="col-md-5">
            <Text
              numberAttributes={{
                step: 1,
                min: 0,
              }}
              inputType={'number'}
              name={'hours'}
              value={courseDuration.hours}
              onInputChange={handleCourseDuration('hours')}
            />
            <small>hours</small>
          </div>
          <div className="col-md-5">
            <Text
              numberAttributes={{
                step: 1,
                min: 0,
                max: 59,
              }}
              inputType={'number'}
              name={'minutes'}
              value={courseDuration.minutes}
              onInputChange={handleCourseDuration('minutes')}
            />
            <small>minutes</small>
          </div>
        </FormRow>

        <FormRow label={'Expiry Date'}>
          <DatePicker
            dropdownMode={'select'}
            minDate={new Date()}
            selected={getModelAttributeValue('expiryDate')}
            onChange={(v) => setModelAttributeState('expiryDate', v)}
            className={'form-control input-sm'}
            placeholderText="Expiry Date"
            dateFormat={DATE_TIME_PICKER_FORMATS.date}
          />
        </FormRow>

        <FormRow
          label="Cover Page"
        >
          <Toggle
            name={'isWysiwygEditableDescription'}
            onClick={handleToggle('isWysiwygEditableDescription')}
            on={<span>Enabled</span>}
            off={<span>Disabled</span>}
            size="sm"
            width={100}
            height={30}
            recalculateOnResize
            offstyle="default"
            active={!!getModelAttributeValue('isWysiwygEditableDescription')}
          />
          <FormElementHelperText text="Create a custom designed page for the course." />
        </FormRow>

        <FormRow label={'Description'}>
          <ReactDraftWysiwygEditor
            editorState={getModelAttributeValue('description')}
            onEditorStateChange={onEditorStateChange}
            token={csrfToken}
            directUploadUrl={links.directUploadUrl}
          />
        </FormRow>
        <FormRow
          label={'Pin this course'}
        >
          <Toggle
            name={'pinned'}
            onClick={handleToggle('pinned')}
            on={<span>Pinned</span>}
            off={<span>Unpinned</span>}
            size="sm"
            width={100}
            height={30}
            recalculateOnResize
            offstyle="default"
            active={!!getModelAttributeValue('pinned')}
          />
          <FormElementHelperText text="Pinned courses will appear at the top of the course list." />
        </FormRow>
      </div>
      <div className="kt-separator kt-separator--dashed" />
      <div className="kt-section">
        <FormRow
          label={'Modules should be completed in order'}
          inputColClassName="d-flex align-items-center pl-10"
        >
          <Toggle
            name={'sequential'}
            onClick={handleToggle('sequential')}
            on={<span>Enabled</span>}
            off={<span>Disabled</span>}
            size="sm"
            width={100}
            height={30}
            recalculateOnResize
            offstyle="default"
            active={sequentialValue}
          />
        </FormRow>
      </div>

      <div className="kt-separator kt-separator--dashed" />
      <div className="kt-section">
        <FormRow label="Banner Image">
          <AssetManager
            label={'Banner Image'}
            labelClassName="hidden"
            rowClassName='mb-1'
            csrfToken={csrfToken}
            filestackOptions={headerImageOptions}
            existingAssets={existingHeaderImages || []}
            assets={headerImages || []}
            onFileChange={onHeaderImageChange}
            showUploader={showHeaderImageUploader}
          />
          <FormElementHelperText text="This is the top banner image on the course." />
        </FormRow>
      </div>

      <div className="kt-section">
        <FormRow label="Thumbnail Image">
          <AssetManager
            label={'Thumbnail Image'}
            labelClassName="hidden"
            rowClassName='mb-1'
            csrfToken={csrfToken}
            filestackOptions={thumbnailImageOptions}
            existingAssets={existingThumbnailImages || []}
            assets={thumbnailImages || []}
            onFileChange={onThumbnailImageChange}
            showUploader={showThumbnailImageUploader}
          />
          <FormElementHelperText text="This is the small thumbnail image on the course and curriculum listings page." />
        </FormRow>
      </div>

      <div className="kt-section">
        <AssetManager
          label={'Videos'}
          csrfToken={csrfToken}
          filestackOptions={videoOptions}
          existingAssets={existingVideos}
          assets={videos}
          onFileChange={onVideoChange}
          sortableGallery={true}
          sortOrder={videosOrder}
          onSortComplete={assets => onSortComplete('videosOrder', assets)}
        />
      </div>

      <div className="kt-section">
        <AssetManager
          label={'Attachments'}
          csrfToken={csrfToken}
          filestackOptions={attachmentOptions}
          existingAssets={existingAttachments}
          assets={attachments}
          onFileChange={onAttachmentChange}
          sortableGallery={true}
          sortOrder={attachmentsOrder}
          onSortComplete={assets => onSortComplete('attachmentsOrder', assets)}
        />
      </div>

      <div className="kt-separator kt-separator--dashed" />
      <div className="kt-section">
        <div className="col-lg-6 text-center">
          <SubmitButton buttonText={'Save'} />
        </div>
      </div>
    </form>
  );
};

SettingsForm.defaultProps = {
  fieldsPrefix: 'course',
  attributes: {},
};

SettingsForm.propTypes = {
  fieldsPrefix: PropTypes.string,

  csrfToken: PropTypes.string.isRequired,
  courseId: PropTypes.number,

  videoOptions: PropTypes.object.isRequired,
  attachmentOptions: PropTypes.object.isRequired,

  attributes: PropTypes.object.isRequired,
  existingHeaderImage: PropTypes.object,
  headerImageOptions: PropTypes.object,

  existingThumbnailImage: PropTypes.object,
  thumbnailImageOptions: PropTypes.object,

  curriculumOptions: PropTypes.array,

  existingVideos: PropTypes.array.isRequired,
  existingAttachments: PropTypes.array.isRequired,
  userList: PropTypes.array.isRequired,
  links: PropTypes.shape({
    updateCourse: PropTypes.string,
    createCourse: PropTypes.string,
    redirectionUrl: PropTypes.string,
    directUploadUrl: PropTypes.string.isRequired,
  }),
  meta: PropTypes.shape({
    overviewMaxLength: PropTypes.number.isRequired,
    learningForumEnabledForOrganization: PropTypes.bool.isRequired,
    curriculumFlowEnabled: PropTypes.bool,
    learningProgressEnabledForManagers: PropTypes.bool,
  }),

  tenantTerms: PropTypes.shape({
    termCurriculum: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
};

export default withErrorHandler(SettingsForm);

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isArray from 'lodash/isArray';

import MembersForm from '../common/forms/members_form';
import TextArea from '../common/inputs/textArea';
import Text from '../common/inputs/text';
import Select from '../common/inputs/select';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import 'react-datepicker/dist/react-datepicker.css';

import { getSnakeCaseKeyedObject } from '../common/utils';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';

const SimpleTaskForm = props => {
  const getTaskAttributesInitialState = taskAttributes => {
    const { isTicketTask } = props;
    if (isNil(taskAttributes)) return {};

    return isTicketTask
      ? omit(taskAttributes, ['description', 'teamIds'])
      : taskAttributes;
  };

  const [selectedIds, setSelectedIds] = React.useState({
    selectedUsersIds: props.taskAttributes.userIds,
    selectedTeamsIds: !props.isTicketTask ? props.taskAttributes.teamIds : [],
  });
  const [loading, setLoading] = React.useState(false);
  const [taskFormState, setTaskFormState] = React.useState({
    [props.modelFieldsPrefix]: getTaskAttributesInitialState(
      props.taskAttributes
    ),
  });

  React.useEffect(() => {
    // Assign current user By default for new ticket tasks
    if (
      props.isTicketTask &&
      isNull(taskFormState[props.modelFieldsPrefix]['id'])
    ) {
      setSelectedIds(prevState => ({
        ...prevState,
        selectedUsersIds: [props.currentUser.id],
      }));
    }
  }, [
    props.currentUser.id,
    props.isTicketTask,
    props.modelFieldsPrefix,
    taskFormState,
  ]);

  const getUpdatedMembersList = () =>
    filter(props.assignableUsersCollection, user =>
      includes(selectedIds.selectedUsersIds, user.id)
    );

  const handleSubmit = async event => {
    event.preventDefault();

    const {
      taskParentId,
      taskId,
      links,
      isTicketTask,
      token: csrfToken,
    } = props;

    const { selectedUsersIds, selectedTeamsIds } = selectedIds;

    const attributesWithSnakeKeys = getSnakeCaseKeyedObject(
      taskFormState[props.modelFieldsPrefix]
    );

    const updatedModelAttributes = {
      ...attributesWithSnakeKeys,
      ...(!isEmpty(attributesWithSnakeKeys['due_date']) && {
        due_date: new Date(attributesWithSnakeKeys['due_date']).toISOString(),
      }),
    };

    let Request = new Http(this)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .setToken(csrfToken)
      .setPostData({
        id: taskId,
        simple_task: {
          ...updatedModelAttributes,
          ...(!isTicketTask && { team_ids: selectedTeamsIds }),
          user_ids: selectedUsersIds,
          task_parent_id: taskParentId,
        },
      })
      .onSuccess(response => {
        const { data } = response;
        setLoading(false);
        window.location.href = data.redirection_url || '/';
      })
      .onError(() => setLoading(false));

    if (!isNil(taskId)) {
      Request = Request.patch(links.update);
    } else {
      Request = Request.post(links.create);
    }

    await Request.exec();
  };

  const handleUserSelect = users => {
    const newSelectedUsersIds = map(users, 'value');
    setSelectedIds(prevState => ({
      ...prevState,
      selectedUsersIds: newSelectedUsersIds,
    }));
  };

  const handleUserDelete = userId => {
    const { selectedUsersIds } = selectedIds;
    const newSelectedUsersIds = filter(
      selectedUsersIds,
      user_id => user_id !== userId
    );
    setSelectedIds(prevState => ({
      ...prevState,
      selectedUsersIds: newSelectedUsersIds,
    }));
  };

  const handleMultiSelectChange = (values, type) => {
    const selectedValues = map(values, 'value');

    setSelectedIds(prevState => ({
      ...prevState,
      [type]: selectedValues,
    }));
  };

  const handleModelAttributeChange = (attributeName = '') => {
    const setModelAttributeState = (fieldName, value) => {
      const updatedModelAttributes = {
        ...taskFormState[props.modelFieldsPrefix],
        [fieldName]: value,
      };

      setTaskFormState(prevState => ({
        ...prevState,
        [props.modelFieldsPrefix]: updatedModelAttributes,
      }));
    };

    return event => {
      const { target } = event;

      // Select Input Changed
      if (isNil(target)) {
        const targetValue = isArray(event)
          ? map(event, option => option.value)
          : event.value;

        setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue =
          target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(targetName, targetValue);
      }
    };
  };

  const handleDateAndColorChange = (attributeName, value) => {
    const updatedModelAttributes = {
      ...taskFormState[props.modelFieldsPrefix],
      [attributeName]: value,
    };

    setTaskFormState(prevState => ({
      ...prevState,
      [props.modelFieldsPrefix]: updatedModelAttributes,
    }));
  };

  const getModelAttributeValue = attribute =>
    taskFormState[props.modelFieldsPrefix][attribute] || '';

  const getSelectedDate = attribute => {
    const date = getModelAttributeValue(attribute) || null;
    if (isNil(date)) {
      return null;
    } else {
      return new Date(date);
    }
  };

  const renderPrioritySelectValue = option => (
    <span
      className={`kt-badge kt-badge--${option.color} kt-badge--inline kt-font-bold`}
    >
      {option.name}
    </span>
  );

  const { tasksPriorities, isTicketTask } = props;
  const { selectedTeamsIds, selectedUsersIds } = selectedIds;

  const taskPriorities = tasksPriorities.map(pr => ({
    label: pr.name,
    value: pr.id,
    ...pr,
  }));

  return (
    <div>
      {loading && <Spinner />}

      <form onSubmit={handleSubmit} className="kt-form kt-form--label-right">
        <div className="kt-portlet__body">
          <FormRow label={'Name *'}>
            <Text
              name={'name'}
              value={getModelAttributeValue('name')}
              onInputChange={handleModelAttributeChange()}
            />
          </FormRow>
          {!isTicketTask && (
            <FormRow label={'Description'}>
              <TextArea
                name={'description'}
                value={getModelAttributeValue('description')}
                onInputChange={handleModelAttributeChange()}
              />
            </FormRow>
          )}

          <FormRow label={'Priority *'}>
            <Select
              options={taskPriorities}
              value={getModelAttributeValue('priorityId')}
              formatOptionLabel={renderPrioritySelectValue}
              onChange={handleModelAttributeChange('priorityId')}
              optionIdentifier="value"
            />
          </FormRow>

          <FormRow label={'End Date'}>
            <DatePicker
              dropdownMode={'scroll'}
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
              selected={getSelectedDate('dueDate')}
              onChange={date => handleDateAndColorChange('dueDate', date)}
              className={'form-control m-input'}
              showYearDropdown
              showMonthDropdown
            />
          </FormRow>

          <FormRow
            label={'Completion Percentage'}
            inputColClassName={'col-md-3'}
          >
            <Text
              name={'completionPercentage'}
              inputType={'number'}
              numberAttributes={{
                min: 0,
                max: 100,
                step: 1,
              }}
              value={getModelAttributeValue('completionPercentage')}
              onInputChange={handleModelAttributeChange()}
            />
          </FormRow>

          <MembersForm
            onUserSelect={handleUserSelect}
            onUserDelete={handleUserDelete}
            onMultiSelectChange={handleMultiSelectChange}
            userList={props.assignableUsersCollection}
            memberList={getUpdatedMembersList()}
            selectedUsersRoles={{}}
            selectedUsersIds={selectedUsersIds}
            selectedTeamsIds={selectedTeamsIds}
            entireTeams={props.assignableTeamsCollection}
            allRoles={[]}
            onUserRoleChange={() => {}}
            hideTeamSection={isTicketTask}
          />
        </div>
        <div className="kt-portlet__foot">
          <div className="row">
            <div className="col-lg-6 offset-3 text-center">
              <CancelButton />
              <SubmitButton />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

SimpleTaskForm.propTypes = {
  taskParentId: PropTypes.number,

  taskId: PropTypes.number,
  assignableUsersCollection: PropTypes.array.isRequired,
  selectedUsersIds: PropTypes.array,

  selectedTeamIds: PropTypes.array,
  assignableTeamsCollection: PropTypes.array.isRequired,
  isTicketTask: PropTypes.bool,
  token: PropTypes.string.isRequired,
  // To group model attribute values under a key if required
  modelFieldsPrefix: PropTypes.string,

  taskAttributes: PropTypes.object,
  tenantTerms: PropTypes.shape({
    termSimpleTask: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),

  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }),
  links: PropTypes.shape({
    create: PropTypes.string,
    update: PropTypes.string,
  }),
  tasksPriorities: PropTypes.array,
};

SimpleTaskForm.defaultProps = {
  taskId: null,
  taskAttributes: {},
  modelFieldsPrefix: 'attributes',
  isTicketTask: false,
};

export default withErrorHandler(SimpleTaskForm);

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { convertArrayOfObjectsToHash } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import Http from '../common/Http';
import CheckBox from '../common/inputs/checkBox';
import Text from '../common/inputs/text';
import SubmitButton from '../common/presentational/submitButton';

import './rbac.scss';

// when view permission is revoked, revoke all other action permissions too
const ALL_PERMISSIONS_REVOKABLE_ACTION = 'View';
// when delete permission is assigned, assign all other action permissions too
const ALL_PERMISSIONS_ASSIGNABLE_ACTION = 'Delete';

class RolesPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: this.formatRoles(props.roles),
      currentEditingRoleId: null,
      loading: false,
    };
    this.resourceGroupedPermissions = _.groupBy(props.permissions, 'resource');
    this.roleNameInputRef = React.createRef();
  }

  formatRoles = roles => {
    const roleHash = convertArrayOfObjectsToHash(roles, 'id');

    return Object.keys(roleHash).reduce((acc, key) => {
      acc[key] = _.omit(roleHash[key], ['id', 'locationSpecific']);
      return acc;
    }, {});
  };

  unSetEditingState = () =>
    this.setState({
      currentEditingRoleId: null,
    });

  setEditingState = id => {
    this.setState({ currentEditingRoleId: id }, () =>
      this.roleNameInputRef.current.focus()
    );
  };

  handleOnSave = () => {
    const {
      csrfToken,
      links: { assignPermissions },
    } = this.props;
    const { roles } = this.state;

    // remove non editable roles from request payload
    const updatableRoles = Object.entries(roles).filter(
      ([_roleId, role]) => role.editable
    );

    new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .doesRedirect(false)
      .setPostData({ roles: Object.fromEntries(updatableRoles) })
      .useAlerts()
      .setSuccessMessage('Permissions saved successfully')
      .post(assignPermissions)
      .exec();
  };

  onChangePermission = (roleId, permission) => {
    this.setState(prevState => {
      let permissionIds = prevState.roles[roleId].permissionIds;
      let updateablePermissionIds = [permission.id];

      if (permissionIds.includes(permission.id)) {
        // unCheck
        if (_.isEqual(ALL_PERMISSIONS_REVOKABLE_ACTION, permission.action)) {
          const allActionIds = _.map(
            this.resourceGroupedPermissions[permission.resource],
            'id'
          );
          updateablePermissionIds = [
            ...updateablePermissionIds,
            ...allActionIds,
          ];
        }
        permissionIds = _.difference(permissionIds, updateablePermissionIds);
      } else {
        //check
        if (_.isEqual(ALL_PERMISSIONS_ASSIGNABLE_ACTION, permission.action)) {
          const allActionIds = _.map(
            this.resourceGroupedPermissions[permission.resource],
            'id'
          );
          updateablePermissionIds = [
            ...updateablePermissionIds,
            ...allActionIds,
          ];
        }
        permissionIds = _.uniq(
          _.concat(permissionIds, updateablePermissionIds)
        );
      }

      return {
        roles: {
          ...prevState.roles,
          [roleId]: {
            ...prevState.roles[roleId],
            permissionIds,
          },
        },
      };
    });
  };

  handleInputSubmit = (roleId, roleName) => {
    this.setState(prevState => ({
      roles: {
        ...prevState.roles,
        [roleId]: {
          ...prevState.roles[roleId],
          name: roleName,
        },
      },
    }));
  };

  renderHeader = () => {
    const { permissions } = this.props;

    return (
      <thead>
        <tr>
          <th
            scope="col"
            rowSpan="3"
            style={{ borderBottom: '1px solid #ebedf2' }}
          >
            Roles
          </th>
          <th scope="col" colSpan={permissions.length} className="text-center">
            Permissions
          </th>
        </tr>
        <tr>
          {_.map(Object.keys(this.resourceGroupedPermissions), resource => (
            <th
              scope="col"
              colSpan="2"
              key={resource}
              className="text-center"
              style={{
                borderBottom: '1px solid #ebedf2',
                borderTop: '1px solid #ebedf2',
              }}
            >
              {resource}
            </th>
          ))}
        </tr>
        <tr>
          {_.map(permissions, ({ action }, idx) => (
            <th
              scope="col"
              key={idx}
              style={{
                borderBottom: '1px solid #ebedf2',
                borderTop: '1px solid #ebedf2',
              }}
              className="text-center"
            >
              {action}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  renderbody = () => {
    const { permissions, roles: allRoles } = this.props;
    const { roles, currentEditingRoleId } = this.state;

    return (
      <tbody>
        {_.map(allRoles, ({ id, locationSpecific }) => (
          <tr key={id}>
            <td style={{ width: '300px' }}>
              {currentEditingRoleId === id ? (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    this.unSetEditingState();
                  }}
                >
                  <span className="d-flex">
                    <Text
                      name="roleName"
                      value={roles[id].name}
                      onInputChange={({ target: { value } }) =>
                        this.handleInputSubmit(id, value)
                      }
                      numberAttributes={{
                        ref: this.roleNameInputRef,
                      }}
                    />
                    <button
                      onClick={this.unSetEditingState}
                      className="btn btn-sm btn-icon-only btn-outline-success"
                    >
                      <i className="fa fa-check"></i>
                    </button>
                  </span>
                </form>
              ) : (
                <span
                  className={`d-flex align-items-center ${
                    roles[id].editable ? 'editable' : 'non-editable'
                  }`}
                  onClick={() =>
                    roles[id].editable ? this.setEditingState(id) : {}
                  }
                >
                  <div>
                    <div>{roles[id].name}</div>
                    {locationSpecific && (
                      <div className="badge badge-pill badge-primary">
                        Location Specific Role
                      </div>
                    )}
                  </div>
                  {roles[id].editable && (
                    <i className="far fa-edit pl-3 app-text-primary"></i>
                  )}
                </span>
              )}
            </td>
            {_.map(permissions, permission => (
              <td key={permission.id} className="text-center">
                <CheckBox
                  handleOnChange={() => this.onChangePermission(id, permission)}
                  value={roles[id]['permissionIds'].includes(permission.id)}
                  name="permission"
                  disabled={!roles[id].editable}
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );
  };

  render() {
    const { loading } = this.state;
    const {
      tenantTerms: { termProject },
    } = this.props;

    return (
      <div className="kt-portlet p-3 location-rbac-container">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Roles - Permissions</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="table-responsive">
            <table className="table table-borderless">
              {this.renderHeader()}
              {this.renderbody()}
            </table>
          </div>
          <div className="d-flex p-3 justify-content-end">
            <SubmitButton
              value={loading ? 'Saving...' : 'Save'}
              onClick={this.handleOnSave}
              disabled={loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

RolesPermissions.propTypes = {
  links: PropTypes.shape({
    assignPermissions: PropTypes.string.isRequired,
  }),
  roles: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
};

export default withErrorHandler(RolesPermissions);

import React from 'react';
import PropTypes from 'prop-types';
import Asset from './Asset';
import { humanFileSize } from '../common/utils';

const GalleryCard = ({
  asset,
  noAssetOptions,
  onAssetDelete,
  showAssetShareOption,
  showAssetDeleteOption,
  showAssetDownloadOption,
  assetShareEnablerPredicate,
  onAssetShareClick,
  hideFileInfo,
  handleAddToLibrary,
  openModal,
  libraryViewConfig,
}) => {
  // Dirty Fix -
  // We are recieving created at with two different keys in different places.
  // So, destructuring with both keys and using it as a temporary fix.
  const {
    id,
    filename,
    mimetype,
    handle,
    size,
    url,
    poster,
    deletable,
    created_at,
    createdAt,
    links,
    libraryIncludable,
    library_includable,
  } = asset;

  const showAssetOptions =
    !noAssetOptions &&
    ((showAssetShareOption && !!assetShareEnablerPredicate(id)) ||
      libraryIncludable ||
      library_includable ||
      (showAssetDeleteOption && (!id || (id && deletable))));

  return (
    <div className="file-card mb-0">
      <div className="kt-file">
        <div className="file-options-right">
          {showAssetOptions && (
            <div className="btn-group mr-2" role="group" style={{ zIndex: 1 }}>
              <div className="dropdown">
                <a
                  className="app-btn-secondary btn-icon btn-pill"
                  data-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    background: '#ffffff',
                  }}
                >
                  <i className="la la-ellipsis-v" />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  x-placement="bottom-end"
                >
                  {showAssetShareOption && !!assetShareEnablerPredicate(id) && (
                    <a
                      type="button"
                      className="dropdown-item"
                      download=""
                      target="blank"
                      onClick={() =>
                        onAssetShareClick({
                          fileId: id,
                          fileType: 'Asset',
                        })
                      }
                    >
                      <i className="la la-share-alt" /> Share
                    </a>
                  )}
                  {(libraryIncludable || library_includable) && (
                    <a
                      type="button"
                      className="dropdown-item"
                      onClick={() => handleAddToLibrary(filename, links, id)}
                    >
                      <i className="la la-folder-open" /> Library
                    </a>
                  )}
                  {showAssetDeleteOption && (!id || (id && deletable)) && (
                    <a
                      type="button"
                      className="dropdown-item text-darkred"
                      onClick={() => onAssetDelete(id, handle)}
                    >
                      <i className="la la-trash text-darkred" /> Delete
                    </a>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        <Asset
          id={id}
          mimetype={mimetype}
          url={url}
          poster={poster}
          openModal={openModal}
        />

        {!hideFileInfo && (
          <div className="file-name d-flex justify-content-between align-items-center">
            <div style={{ flex: 1, width: 0 }}>
              <p className="m-0 font-13 text-truncate text-dark">
                <strong>{filename}</strong>
              </p>
              <small className="font-10 text-muted">Size: {humanFileSize(size)}</small>
            </div>
            {_.isInteger(parseInt(id)) && showAssetDownloadOption && (
              <div>
                <a href={links.download} type="button" className="custom-secondary-btn">
                  <i className="fas fa-download"></i>
                </a>
              </div>
            )}
          </div>
        )}

        {!_.isNull(libraryViewConfig) && libraryViewConfig.assetFooterRenderer(id)}
      </div>
    </div>
  );
};

GalleryCard.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.object]).isRequired,
  noAssetOptions: PropTypes.bool,
  onAssetDelete: PropTypes.func.isRequired,
  showAssetShareOption: PropTypes.bool,
  hideFileInfo: PropTypes.bool,
  assetShareEnablerPredicate: PropTypes.func,
  onAssetShareClick: (props, propName, componentName) => {
    const dependentPropKey = 'showAssetShareOption';

    if (props[dependentPropKey]) {
      if (!_.isFunction(props[propName])) {
        return new Error(
          `
            Invalid prop ${propName} supplied to ${componentName}\n
            If ${dependentPropKey} is true, function is required
          `
        );
      }
    }
  },
  libraryViewConfig: PropTypes.object,
  showAssetDeleteOption: PropTypes.bool,
  showAssetDownloadOption: PropTypes.bool,
  handleAddToLibrary: PropTypes.func,
  openModal: PropTypes.func,
};

GalleryCard.defaultProps = {
  hideFileInfo: false,
  noAssetOptions: false,
  showAssetShareOption: false,
  showAssetDownloadOption: true,
  onAssetDelete: () => {},
  onAssetShareClick: () => {},
  assetShareEnablerPredicate: () => true,
  showAssetDeleteOption: true,
  libraryViewConfig: null,
  handleAddToLibrary: () => {},
  openModal: () => {},
};

export default GalleryCard;

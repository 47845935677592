import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../common/Modal/Modal';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const StatusModal = ({ termCourse }) => {
  const renderSuccessMessage = () => {
    return (
      <div>
        <div className="text-success">
          <i className="far fa-check-circle" style={{ fontSize: '60px' }}></i>
          <h4>Success!</h4>
        </div>
        <div className="border rounded app-text-primary p-2">
          <h5>Forum Cloning is Completed.</h5>
          <br />
          <strong className="d-flex align-items-center justify-content-center text-black-50">
            You will be redirected to {termCourse.singular} Settings View
            shortly
          </strong>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        modalState={true}
        header={'Forum Cloning Status'}
        content={<div className="text-center">{renderSuccessMessage()}</div>}
        onClose={() => {}}
        disableBackdropClick
        hideClose={true}
      />
    </div>
  );
};

StatusModal.propTypes = {
  termCourse: PropTypes.shape({
    singular: PropTypes.string,
    plural: PropTypes.string,
  }),
};

export default withErrorHandler(StatusModal);

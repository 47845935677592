import React from 'react';
import PropTypes from 'prop-types';

const ContextProvider = ({ children, ComponentContext, value }) => {
  return (
    <ComponentContext.Provider value={value}>
      {children}
    </ComponentContext.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  value: PropTypes.object.isRequired,
  ComponentContext: PropTypes.object.isRequired,
};

export default ContextProvider;

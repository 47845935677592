import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import Http from '../../common/Http';
import Spinner from "../../common/presentational/spinner";
import { alertErrorNotifications } from "../../folders/utils";
import PaginationWrapper from "../../hoc/PaginationWrapper";
import Filters from "./Filters";
import Results from "./Results";

const Body = ({ csrfToken, links }) => {
  const [user, setUser] = useState();
  const [certification, setCertification] = useState();
  const [status, setStatus] = useState('');
  const [pagination, setPagination] = useState({ currentPage: 1, totalPages: 0 });
  const [isLoading, setLoading] = useState(true);
  const [certifications, setCertifications] = useState([]);

  const fetchCertifications = async (pageNumber = 1) => {
    const queryParams = `?page=${pageNumber}&`
      + `q[member_id_eq]=${user?.id || ''}&`
      + `q[certification_id_eq]=${certification?.id || ''}`
      + `&status=${status || ''}`;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${links.listCertificationStatus}${queryParams}`)
      .onSuccess(response => {
        const { data: { memberCertifications, meta } } = response;

        setCertifications(memberCertifications);
        setPagination(meta.pagination);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting certifications!');
      })
      .exec();
  }

  useEffect(() => {
    fetchCertifications();
  }, [user, certification, status]);

  const renderPagination = () => {
    if (!isEmpty(pagination) && pagination.totalPages > 1) {
      return (
        <PaginationWrapper
          paginationObject={pagination}
          fetchData={fetchCertifications}
        />
      );
    }

    return <br />;
  };

  return (
    <>
      <Filters
        csrfToken={csrfToken}
        setSelectedUser={setUser}
        setSelectedCertification={setCertification}
        selectedStatus={status}
        setSelectedStatus={setStatus}
        usersPath={links.usersList}
        certificationsPath={links.certificationsList}
      />
      <Results certifications={certifications} />
      {renderPagination()}
      {
        isLoading
          ? <Spinner />
          : null
      }
    </>
  )
}

export default Body;
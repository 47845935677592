import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AttachmentSlider from '../AttachmentPreviewSlider/PreviewSlider';
import AttachmentCard from './AttachmentCard';
import { calculateFileSize } from './utils';

const PreviewSlider = ({ attachments }) => {
  const [activePreviewState, setActivePreviewState] = useState(null);

  const handleActiveAttachment = (id) => {
    setActivePreviewState(id);
  };

  return (
    <div>
      <div className="row">
        {attachments.map(({ filename, id, links, url, size, mimetype, poster }, idx) => (
          <div
            className="col-md-4"
            key={url}
            onClick={() => handleActiveAttachment(idx + 1)}
          >
            <AttachmentCard
              url={url}
              filename={filename}
              links={links}
              size={calculateFileSize(size)}
              mimetype={mimetype}
              poster={poster}
            />
          </div>
        ))}
      </div>
      <AttachmentSlider
        attachments={attachments}
        activeState={activePreviewState}
        onClose={() => setActivePreviewState(null)}
      />
    </div>
  );
};

PreviewSlider.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      links: PropTypes.shape({
        download: PropTypes.string,
      }),
      mimetype: PropTypes.string.isRequired,
      poster: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PreviewSlider;

import React from 'react';
import errorFallbackImg from '../../../../assets/images/error-fallback.png';

export default function ErrorFallback() {
  return (
    <div className="kt-portlet">
      <div className="my-5 text-center">
        <div className="text-center">
          <img src={errorFallbackImg} alt="" width="100%" style={{ maxWidth: '400px' }} />
          <h3 className="mb-4">
            <i className="fa fa-exclamation-triangle text-darkred mr-2" />
            Uh-oh! Something went wrong!
          </h3>
        </div>

        <div>
          <p className="font-18 font-weight-bold">
            We have been notified.
            <br /> Please try reloading the page.
          </p>
          <button
            className="btn-outline-darkred mb-3"
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
          <h3
            className="font-18 m-auto w-75"
            style={{
              padding: '15px 10px',
              border: '1px solid Red',
              borderRadius: '4px',
              color: 'rgb(138, 0, 0)',
              background: 'rgba(239, 20, 20, 0.4)',
            }}
          >
            If you are unable to use the app further please contact admin.
          </h3>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import PaginationWrapper from '../hoc/PaginationWrapper';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import TextInput from '../common/inputs/text';
import EnterKeyListener from '../common/EnterKeyListener';
import TransitionModal from '../common/Modal/Modal';

const Learners = ({ csrfToken, links }) => {
  const [isLoading, setLoading] = useState(false);
  const [learners, setLearners] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [courses, setCourses] = useState([]);
  const [curriculums, setCurriculums] = useState([]);
  const [selectedLearnerMember, setSelectedLearnerMember] = useState(null);
  const [activeTab, setActiveTab] = useState('courses');

  const fetchLearners = async (pageNumber = 1) => {
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${links.learnersFetcher}?page=${pageNumber}&name=${nameSearch}&email=${emailSearch}`)
      .onSuccess(response => {
        const { learners, meta } = response.data;
        setLearners(learners);
        setPagination(meta.pagination);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting learners!');
      })
      .exec();
  };

  const fetchCourses = async (memberId) => {
    await new Http()
      .setToken(csrfToken)
      .get(`${links.privateCoursesIndex}?member_id=${memberId}`)
      .onSuccess(response => {
        setCourses(response.data);
      })
      .onError(err => {
        console.log(err);
        alertErrorNotifications(err || 'Error getting courses!');
      })
      .exec();
  };

  const fetchCurriculums = async (memberId) => {
    await new Http()
      .setToken(csrfToken)
      .get(`${links.privateCurriculumsIndex}?member_id=${memberId}`)
      .onSuccess(response => {
        setCurriculums(response.data);
      })
      .onError(err => {
        console.log(err);
        alertErrorNotifications(err || 'Error getting curriculums!');
      })
      .exec();
  };

  const enrollToCourse = async (courseId) => {
    await new Http()
      .setToken(csrfToken)
      .post(links.enrollToCourse, { course_id: courseId, member_id: selectedLearnerMember?.id })
      .onSuccess(response => {
        setCourses(prevCourses =>
          prevCourses.map(course =>
            course.id === courseId ? { ...course, enrolled: true } : course
          )
        );
      })
      .onError(err => {
        console.log(err);
        alertErrorNotifications(err || 'Error enrolling to course!');
      })
      .exec();
  };

  const enrollToCurriculum = async (curriculumId) => {
    await new Http()
      .setToken(csrfToken)
      .post(links.enrollToCurriculum, { curriculum_id: curriculumId, member_id: selectedLearnerMember?.id })
      .onSuccess(response => {
        setCurriculums(prevCurriculums =>
          prevCurriculums.map(curriculum =>
            curriculum.id === curriculumId ? { ...curriculum, enrolled: true } : curriculum
          )
        );
      })
      .onError(err => {
        console.log(err);
        alertErrorNotifications(err || 'Error enrolling to curriculum!');
      })
      .exec();
  };

  useEffect(() => {
    fetchLearners();
  }, []);

  const renderTableHeader = useMemo(
    () => (
      <tr>
        <th className="td-130">
          <span className="pl-15">
            <strong>
              First Name
            </strong>
          </span>
        </th>
        <th className="td-130">
          <strong>
            Last Name
          </strong>
        </th>
        <th>
          <strong>
            Email
          </strong>
        </th>
        <th className="td-110"></th>
      </tr>
    ),
    []
  );

  const renderPagination = () => {
    if (
      !isEmpty(learners) &&
      !isEmpty(pagination) &&
      pagination.totalPages > 1
    ) {
      return (
        <PaginationWrapper
          paginationObject={pagination}
          fetchData={fetchLearners}
        />
      );
    }
    return <br />;
  };

  const renderEmptyMessage = useCallback(() => {
    if (isEmpty(learners)) {
      return (
        <tr>
          <td colSpan="4" className="text-center p-4">
            <h4>No Learners present.</h4>
          </td>
        </tr>
      );
    }
    return null;
  }, [learners]);

  const handleNameChange = event => {
    const { target } = event;
    setNameSearch(target.value);
  };

  const handleEmailChange = event => {
    const { target } = event;
    setEmailSearch(target.value);
  };

  const handleFilterClick = () => {
    fetchLearners();
  };

  const toggleCoursesModal = useCallback((member) => {
    if (!selectedLearnerMember) {
      fetchCourses(member?.id);
      fetchCurriculums(member?.id);
    }
    setSelectedLearnerMember(member);
  }, [selectedLearnerMember]);

  const renderCoursesModalContent = () => (
    <div className="m-4">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'courses' ? 'active' : ''}`}
            onClick={() => setActiveTab('courses')}
          >
            Courses
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link ${activeTab === 'curriculums' ? 'active' : ''}`}
            onClick={() => setActiveTab('curriculums')}
          >
            Curriculums
          </button>
        </li>
      </ul>

      <div className="tab-content">
        <table className="table table-striped">
          <thead>
            <tr>
              <th><strong>{activeTab === 'courses' ? 'Course Name' : 'Curriculum Name'}</strong></th>
              <th><strong>Enroll Status</strong></th>
            </tr>
          </thead>
          <tbody>
            {activeTab === 'courses' ? (
              courses.map(course => (
                <tr key={course.id}>
                  <td>{course.name}</td>
                  <td>
                    {course.enrolled ? 'Enrolled' : (
                      <button
                        className="btn app-btn-primary"
                        onClick={() => enrollToCourse(course.id)}
                      >
                        Enroll
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              curriculums.map(curriculum => (
                <tr key={curriculum.id}>
                  <td>{curriculum.name}</td>
                  <td>
                    {curriculum.enrolled ? 'Enrolled' : (
                      <button
                        className="btn app-btn-primary"
                        onClick={() => enrollToCurriculum(curriculum.id)}
                      >
                        Enroll
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">All Learners</h3>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <EnterKeyListener onPress={handleFilterClick}>
            <div className="form-group row ml-15 mr-15">
              <div className="col-lg-4 m-auto pt-10 pb-10">
                <TextInput
                  placeholder={'Search learners by name'}
                  name={'nameSearch'}
                  value={nameSearch}
                  onInputChange={handleNameChange}
                />
              </div>
              <div className="col-lg-4 m-auto pt-10 pb-10">
                <TextInput
                  placeholder={'Search learners by email'}
                  name={'emailSearch'}
                  value={emailSearch}
                  onInputChange={handleEmailChange}
                />
              </div>
              <div className="col-lg-4 m-auto pt-10 pb-10">
                <button
                  className="app-btn-primary m-btn--wide"
                  onClick={handleFilterClick}
                  style={{ width: '100%' }}
                >
                  Filter
                </button>
              </div>
            </div>
          </EnterKeyListener>

          <div className="kt-section">
            <div className="kt-section__content">
              <div className="table-responsive">
                <table className="table table-striped kt-table__row-equal-width">
                  <thead>{renderTableHeader}</thead>
                  <tbody>
                    {map(learners, learner => (
                      <tr key={learner.id}>
                        <td>
                          <span className="pl-15">
                            {learner.firstName}
                          </span>
                        </td>
                        <td>{learner.lastName}</td>
                        <td>{learner.email}</td>
                        <td>
                          <button
                            className="btn app-btn-primary"
                            onClick={() => toggleCoursesModal(learner)}
                          >
                              Add to Course
                          </button>
                        </td>
                      </tr>
                    ))}
                    {renderEmptyMessage()}
                  </tbody>
                </table>
                <TransitionModal
                  header={`Available Courses for ${selectedLearnerMember?.firstName} ${selectedLearnerMember?.lastName}`}
                  content={selectedLearnerMember ? renderCoursesModalContent() : ''}
                  modalState={!!selectedLearnerMember}
                  onClose={toggleCoursesModal}
                  size="lg"
                  fullHeight
                />
              </div>
            </div>
          </div>
          {renderPagination()}
        </>
      )}
    </div>
  );
};

export default Learners;

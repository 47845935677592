import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../common/inputs/Toggle';
import DatePicker from 'react-datepicker';
import FormRow from '../common/presentational/formRow';

const weekDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
const BusinessHoursTable = ({ businessHours, handleBusinessTableValueChange }) => (
  <FormRow label="Business Hours">
    <div style={{ overflowX: 'auto', maxWidth: '100%' }}>
      <table className="table table-bordered kt-table__row-equal-width mtb-0 border border-secondary">
        <thead>
          <tr style={{ height: 42 }}>
            <th className="td-100">Day</th>
            <th className="td-150 text-center">Opening time</th>
            <th className="td-150 text-center">Closing time</th>
            <th className="td-100 text-center">Closed</th>
          </tr>
        </thead>
        <tbody>
          {_.map(weekDays, (day, index) => (
            <tr style={{ height: 42 }} key={`${day}-${index}`}>
              <td className="td-100">{_.capitalize(day)}</td>
              <td className="td-150 text-center">
                <DatePicker
                  selected={
                    !_.isEmpty(businessHours[day]['open_time'])
                      ? moment(businessHours[day]['open_time'], 'hh:mm a')
                      : null
                  }
                  onChange={time =>
                    handleBusinessTableValueChange(day, 'open_time', time)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  shouldCloseOnSelect
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  timeCaption="Time"
                  placeholderText="Select"
                />
              </td>
              <td className="td-150 text-center">
                <DatePicker
                  selected={
                    !_.isEmpty(businessHours[day]['close_time'])
                      ? moment(businessHours[day]['close_time'], 'hh:mm a')
                      : null
                  }
                  onChange={time =>
                    handleBusinessTableValueChange(day, 'close_time', time)
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  shouldCloseOnSelect
                  timeIntervals={15}
                  dateFormat="h:mm a"
                  timeCaption="Time"
                  placeholderText="Select"
                />
              </td>
              <td className="td-100 text-center">
                <Toggle
                  name={'has_project_items'}
                  onClick={value => handleBusinessTableValueChange(day, 'closed', value)}
                  on={<span>Yes</span>}
                  off={<span>No</span>}
                  size="sm"
                  width={60}
                  height={30}
                  recalculateOnResize
                  offstyle="default"
                  active={businessHours[day]['closed']}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </FormRow>
);

export default BusinessHoursTable;

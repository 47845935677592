import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import size from 'lodash/size';

import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import UserResponsesIndexTable from './UserResponsesIndexTable';
import ImpressionHeatMap from './ImpressionHeatMap';
import { getFilterOptionsQueryParams, getTabs } from './utils';
import {
  metaDataStateReducer,
  userResponseStateReducer,
} from './formsReducers';

import useFetchQueryParams from '../common/hooks/useFetchParams';
import {
  HEAT_MAP_KEY,
  RESPONSE_KEY,
} from './constants';

const getMetaDataInitialState = props => {
  return {
    metaDataLoading: false,
    forms: [],
    ...(props.featureFlags.projectsEnabled && {
      projects: [],
    }),
    ...(props.featureFlags.projectCategoriesEnabled && {
      projectCategories: [],
    }),
  };
};

const INITIAL_USER_RESPONSE_STATE = {
  responsesLoading: false,
  userResponses: [],
  pagingMeta: {
    rowsLoading: false,
    hasNextPage: true,
    startIndex: 0,
  },
};

const UserResponsesIndex = props => {
  const INITIAL_META_DATA_STATE = getMetaDataInitialState(props);
  const TABS = getTabs(props);

  const [activeTab, setActiveTab, getQueryParamStatus] =
    useFetchQueryParams(TABS);

  const redirectPrev = e => {
    e.preventDefault();

    getQueryParamStatus();
  };

  const [userResponseDataState, dispatchUserResponseDataActions] = useReducer(
    userResponseStateReducer,
    INITIAL_USER_RESPONSE_STATE
  );
  const [metaDataState, dispatchMetaDataActions] = useReducer(
    metaDataStateReducer,
    INITIAL_META_DATA_STATE
  );

  const fetchMetaData = async () => {
    const {
      links: { metadata },
      csrfToken,
    } = props;

    await new Http()
      .setToken(csrfToken)
      .onBegin(() => {
        dispatchMetaDataActions({ type: 'initiateMetaDataFetch' });
      })
      .get(metadata)
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        dispatchMetaDataActions({
          type: 'resolvedMetaDataFetch',
          payload: data,
        });
      })
      .onError(() => {
        dispatchMetaDataActions({ type: 'rejectedMetaDataFetch' });
      })
      .exec();
  };

  const fetchUserResponses = async (options = null) => {
    const {
      links: { userResponses: userResponsesLink },
    } = props;
    const { startIndex } = userResponseDataState.pagingMeta;

    let queryParams = {};

    if (!isNil(options)) {
      if (!!options.isFilterSubmit && !isEmpty(options.filterOptions)) {
        queryParams = {
          ...getFilterOptionsQueryParams(options.filterOptions),
        };
        dispatchUserResponseDataActions({ type: 'initiateUserResponseFetch' });
      }

      if (options.isInfiniteLoader && startIndex > 0) {
        queryParams = {
          paging: { startIndex },
          ...getFilterOptionsQueryParams(options.filterOptions),
        };
        dispatchUserResponseDataActions({ type: 'initiateRowsLoading' });
      }
    } else {
      dispatchUserResponseDataActions({ type: 'initiateResponseLoading' });
    }

    await new Http()
      .setToken(props.csrfToken)
      .get(userResponsesLink)
      .setQueryParams({
        ...queryParams,
      })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .useAlerts()
      .onSuccess(({ data }) => {
        dispatchUserResponseDataActions({
          type: 'resolvedUserResponseFetch',
          payload: {
            userResponses:
              !isNull(options) && options.isFilterSubmit
                ? data.userResponses
                : [
                    ...userResponseDataState.userResponses,
                    ...data.userResponses,
                  ],
            pagingMeta: {
              rowsLoading: false,
              ...(!isNil(data.paging) && {
                hasNextPage: data.paging.hasNextPage,
                startIndex: data.paging.startIndex,
              }),
            },
          },
        });
      })
      .onError(() => {
        dispatchUserResponseDataActions({ type: 'rejectedUserResponseFetch' });
      })
      .exec();
  };

  const fetchResponsesTabResources = async () => {
    await fetchUserResponses();
    await fetchMetaData();
  };

  useEffect(() => {
    if (isEqual(activeTab?.key, RESPONSE_KEY)) {
      fetchResponsesTabResources();
    }

    // adding the fetch function in the dependency array will trigger infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener('popstate', redirectPrev, false);
    return () => {
      window.removeEventListener('popstate', redirectPrev, false);
    };

    // adding the redirect prev function will trigger in a infinite loop and affects the performance.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = tab => {
    // do nothing when clicked in same tab
    if (isEqual(activeTab?.key, tab.key)) return;

    setActiveTab(tab);
  };

  const renderTabs = () => (
    <div className="py-2">
      {size(TABS) > 1 && (
        <ul className="nav text-black-50" role="tablist">
          {map(TABS, tab => (
            <li
              className={`p-4 nav-link cursor-pointer${
                activeTab?.key === tab.key ? ' app-text-primary' : ''
              }`}
              key={tab.key}
              onClick={() => handleTabChange(tab)}
              style={{ fontWeight: 500 }}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const renderUserResponsesTable = () => {
    const { csrfToken, showImpressionsOverview, tenantTerms, featureFlags } =
      props;
    const { forms, projects, projectCategories, metaDataLoading } =
      metaDataState;
    const {
      userResponses,
      responsesLoading,
      pagingMeta: { rowsLoading, hasNextPage, startIndex },
    } = userResponseDataState;

    return (
      <UserResponsesIndexTable
        userResponses={userResponses}
        csrfToken={csrfToken}
        forms={forms}
        showImpressionsOverview={showImpressionsOverview}
        tenantTerms={tenantTerms}
        getUserResponses={fetchUserResponses}
        projects={projects}
        projectCategories={projectCategories}
        metaDataLoading={metaDataLoading}
        responsesLoading={responsesLoading}
        featureFlags={featureFlags}
        pagingMeta={{
          rowsLoading,
          hasNextPage,
          startIndex,
        }}
      />
    );
  };

  const renderResponsesTab = () => {
    const { links } = props;
    return (
      <div className="kt-portlet mb-0">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Responses</h3>
          </div>
          {!isEmpty(links.archivedUserResponses) && (
            <div className="kt-portlet__head-toolbar">
              <div className="kt-portlet__head-group">
                <a
                  href={links.archivedUserResponses}
                  className="btn-outline-darkred mr-15"
                >
                  Deleted Responses
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="kt-portlet__body plr-0">
          {renderUserResponsesTable()}
        </div>
      </div>
    );
  };

  const renderHeatMap = () => {
    const { tenantTerms, featureFlags, csrfToken, impressionsHeatmap } = props;

    return (
      <ImpressionHeatMap
        tenantTerms={tenantTerms}
        featureFlags={featureFlags}
        csrfToken={csrfToken}
        links={impressionsHeatmap.links}
        responseChannels={impressionsHeatmap.responseChannels}
        mapKey={impressionsHeatmap.mapKey}
      />
    );
  };

  const { featureFlags: { isSingleProjectTenant } } = props;

  return (
    <div className="new-theme-tabs-container">
      {renderTabs()}
      <div className="kt-portlet mb-0">
        <div className="kt-portlet__body p-0">
          <div className="row">
            <div className="col-md-12">
              {isEqual(activeTab?.key, RESPONSE_KEY) && renderResponsesTab()}
              {!isSingleProjectTenant &&
                isEqual(activeTab?.key, HEAT_MAP_KEY) &&
                renderHeatMap()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

UserResponsesIndex.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  featureFlags: PropTypes.shape({
    isFormsEnabled: PropTypes.bool.isRequired,
    projectsEnabled: PropTypes.bool.isRequired,
    projectCategoriesEnabled: PropTypes.bool.isRequired,
    showDeleteOption: PropTypes.bool.isRequired,
    isSingleProjectTenant: PropTypes.bool.isRequired,
  }).isRequired,
  links: PropTypes.shape({
    userResponses: PropTypes.string.isRequired,
    metadata: PropTypes.string.isRequired,
    archivedUserResponses: PropTypes.string.isRequired,
  }).isRequired,
  showImpressionsOverview: PropTypes.bool,
  impressionsHeatmap: PropTypes.shape({
    mapKey: PropTypes.string,
    links: PropTypes.shape({
      metadata: PropTypes.string.isRequired,
      impressions: PropTypes.string.isRequired,
    }).isRequired,
    responseChannels: PropTypes.array.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termTicket: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

UserResponsesIndex.defaultProps = {
  showImpressionsOverview: false,
};

export default withErrorHandler(UserResponsesIndex);

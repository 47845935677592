import React from 'react';
import PropTypes from 'prop-types';
import Http from './Http';
import CallTimer from './CallTimer';
import { withErrorHandler } from '../hoc/withErrorHandler';

const TwilioClient = window.Twilio;

const TWILIO_STATUS = Object.freeze({
  initializing: 'Initializing...',
  ready: 'Ready',
  error: 'Something Went Wrong, Please Retry!',
  retrying: 'Reconnecting...',
  connected: 'In Call',
  calling: 'Calling...',
  hangingUp: 'Hanging Up...',
  offline:
    'Something Went Wrong, Please Check Network / Reload the Page. Contact Admin If problem persists',
});

class CallInterface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: TWILIO_STATUS['initializing'],
      isMuted: false,
      callEstablished: false,
      hasErrorSettingUp: false,
      showCallQualityWarning: false,
    };
    this.twilioClientDevice = null;
  }

  componentDidMount() {
    this.twilioClientDevice = new TwilioClient.Device();
    this.fetchTokenAndInitializeTwilio();
  }

  componentWillUnmount() {
    if (this.isDevicePresent()) {
      this.twilioClientDevice.destroy();
    }
  }

  fetchTokenAndInitializeTwilio = async () => {
    const {
      csrfToken,
      links: { voiceCallToken },
    } = this.props;

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .get(voiceCallToken)
      .onSuccess(({ data }) => {
        this.initializeTwilio(data.token);
      })
      .onError(_error => this.toggleErrorState(true))
      .exec();
  };

  abortActiveCall = async () => {
    const {
      csrfToken,
      links: { abortCall: abortCallLink },
    } = this.props;

    const activeCallSid = this.twilioClientDevice.activeConnection().parameters[
      'CallSid'
    ];

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .post(abortCallLink)
      .setPostData({
        CallSid: activeCallSid,
      })
      .onSuccess(this.closeWindow)
      .onError(_error => this.toggleErrorState(true))
      .exec();
  };

  isDevicePresent = () => !_.isNil(this.twilioClientDevice);

  initializeTwilio = token => {
    if (!this.isDevicePresent()) {
      this.twilioClientDevice = new TwilioClient.Device();
    }

    // Setup Device with options
    this.twilioClientDevice.setup(token, {
      closeProtection: true,
      enableRingingState: true,
      enableIceRestart: true,
    });

    // Ready Status Listener
    this.twilioClientDevice.on('ready', _device => {
      this.updateStatus(TWILIO_STATUS['ready']);
      this.toggleErrorState(false);
    });

    // Error Status Listener
    this.twilioClientDevice.on('error', _error => this.toggleErrorState(true));

    // Offline Status Listener
    this.twilioClientDevice.on('offline', () => {
      this.updateStatus(TWILIO_STATUS['offline']);
      this.setState({ hasErrorSettingUp: true });
    });

    // Connect Status Listener
    this.twilioClientDevice.on('connect', connection => {
      this.updateStatus(TWILIO_STATUS['connected']);
      this.setCallEstablishment(true);

      connection.on('warning', _warning =>
        this.setState({ showCallQualityWarning: true })
      );
      connection.on('warning-cleared', _warning =>
        this.setState({ showCallQualityWarning: false })
      );
    });

    // Disconnect Status Listener
    this.twilioClientDevice.on('disconnect', _connection => {
      this.setCallEstablishment(false);
      if (!this.state.hasErrorSettingUp) {
        this.toggleErrorState(false);
        this.updateStatus(TWILIO_STATUS[this.twilioClientDevice.status()]);
      } else {
        this.updateStatus(TWILIO_STATUS['error']);
      }
    });
  };

  retryInitialization = () => {
    this.updateStatus(TWILIO_STATUS['retrying']);
    window.location.reload();
  };

  updateStatus = status => this.setState({ status });

  toggleErrorState = (hasError = false) => {
    this.setState({ hasErrorSettingUp: hasError });
    if (hasError) {
      this.updateStatus(TWILIO_STATUS['error']);
    }
  };

  setCallEstablishment = callEstablished => this.setState({ callEstablished });

  establishCall = () => {
    const {
      receiver: { mobileNumber },
      connectPassbackParams,
    } = this.props;
    this.updateStatus(TWILIO_STATUS['calling']);
    this.twilioClientDevice.connect({
      phoneNumber: mobileNumber,
      customParams: JSON.stringify(connectPassbackParams),
    });
  };

  closeWindow = () => window.close();

  disconnectCall = async () => {
    this.updateStatus(TWILIO_STATUS['hangingUp']);
    await this.abortActiveCall();
  };

  toggleMute = () => {
    const muted = this.twilioClientDevice.activeConnection().isMuted();

    this.setState({ isMuted: !muted });
    this.twilioClientDevice.activeConnection().mute(!muted);
  };

  // Helper method to disable button after action triggered once
  disableAction = action => _.isEqual(TWILIO_STATUS[action], this.state.status);

  render() {
    const {
      receiver: { name: receiverName, mobileNumber: receiverMobNumber },
    } = this.props;
    const {
      status,
      isMuted,
      callEstablished,
      hasErrorSettingUp,
      showCallQualityWarning,
    } = this.state;

    const callActionIconCommonClass = 'btn btn-lg btn-icon btn-circle m-2';

    return (
      <div className="kt-portlet kt-portlet--unelevate bg-transparent">
        <div className="kt-portlet__body">
          <div className="d-flex flex-column align-items-center justify-content-center my-2">
            <div className="text-center">
              <h2 className="my-2">{receiverName}</h2>
              <h4 className="my-2">{receiverMobNumber}</h4>
              <p className="my-2 kt-font-info font-18 font-weight-bold">{status}</p>
              {callEstablished && <CallTimer />}
            </div>

            <div className="m-4 text-center">
              {!callEstablished ? (
                <React.Fragment>
                  <button
                    className={`${callActionIconCommonClass} btn-success my-1`}
                    onClick={this.establishCall}
                    disabled={!this.disableAction('ready') || hasErrorSettingUp}
                  >
                    <i className="fa fa-phone mx-1"></i>
                  </button>
                  <br />
                  {hasErrorSettingUp && (
                    <button
                      className={`btn btn-outline-warning my-1`}
                      onClick={this.retryInitialization}
                      disabled={this.disableAction('retrying')}
                    >
                      <i className="fa fa-broadcast-tower mx-1"></i>
                      Retry
                    </button>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button
                    className={`${callActionIconCommonClass} btn-outline-darkred`}
                    onClick={this.toggleMute}
                    disabled={this.disableAction('hangingUp')}
                  >
                    <i className={`fa fa-microphone${isMuted ? '-slash' : ' '}`}></i>
                  </button>
                  <button
                    className={`${callActionIconCommonClass} btn-darkred`}
                    onClick={this.disconnectCall}
                    disabled={this.disableAction('hangingUp')}
                  >
                    <i className="fa fa-phone-slash mx-1"></i>
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        {showCallQualityWarning && (
          <div className="kt-portlet__foot kt-font-warning text-center">
            We have detected poor call quality conditions. You may experience degraded
            call quality.
          </div>
        )}
      </div>
    );
  }
}

CallInterface.propTypes = {
  header: PropTypes.string.isRequired,
  receiver: PropTypes.shape({
    name: PropTypes.string.isRequired,
    mobileNumber: PropTypes.string.isRequired,
  }).isRequired,
  connectPassbackParams: PropTypes.object.isRequired,
  links: PropTypes.shape({
    voiceCallToken: PropTypes.string.isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default withErrorHandler(CallInterface);

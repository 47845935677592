import { useEffect } from 'react';

const useEscapeKey = callBack => {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Escape') callBack();
    }
    document.addEventListener('keyup', onKeyup, false);
    return () => document.removeEventListener('keyup', onKeyup, false);
  }, []);
};

export default useEscapeKey;

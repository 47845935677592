export const feedbackStateReducer = (state, action) => {
  switch (action.type) {
    case 'handleSummaryFilters': {
      return {
        ...state,
        summaryFilters: { ...state.summaryFilters, ...action.payload },
      };
    }
    case 'onFetchResponseSuccess': {
      return { ...state, loading: false, ...action.payload };
    }
    case 'onFetchBegin': {
      return { ...state, loading: true };
    }
    case 'onFetchError': {
      return { ...state, loading: false };
    }
    default: {
      return state;
    }
  }
};

export const summaryItemReducer = (state, action) => {
  switch (action.type) {
    case 'handleOpenModal': {
      return { ...state, isModalOpen: true, ...action.payload };
    }
    case 'handleChartType': {
      return { ...state, chartType: action.payload };
    }
    case 'handleCurrentAssetResponseId': {
      return { ...state, currentAssetResponseId: action.payload };
    }
    case 'handleCloseModal': {
      return {
        ...state,
        currentAssetResponseId: [],
        isModalOpen: false,
        fileSrc: '',
        fileType: '',
        filePoster: '',
      };
    }
    default: {
      return state;
    }
  }
};

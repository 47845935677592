import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import Http from '../common/Http';
import SubmitButton from '../common/presentational/submitButton';
import FormRow from '../common/presentational/formRow';
import TextInput from '../common/inputs/text';
import { withErrorHandler } from '../hoc/withErrorHandler';
import CancelButton from '../common/presentational/cancelButton';
import AssetManager from '../filestack/AssetManager';
import { removePageLeavePreventDialog } from '../common/utils';
import FormElementHelperText from '../common/presentational/FormElementHelperText';

const BadgeForm = props => {
  const [loading, setLoading] = React.useState(false);
  const [attachments, setAttachments] = React.useState([]);
  const [attributes, setAttributes] = React.useState({ ...props.attributes });
  const [existingAttachments, setExistingAttachments] = React.useState(
    !isEmpty(props.attachment) ? [props.attachment] : []
  );
  const [attachmentUpdated, setAttachmentUpdated] = React.useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const { name } = attributes;
    const { links, badgeId, csrfToken, entityId, entityType } = props;

    const Requestor = new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      });

    if (isNull(badgeId)) {
      Requestor.post(links.badge);
    } else {
      Requestor.patch(links.badge);
    }

    Requestor.setPostData({
      badge: {
        name,
        attachment: attachments[0] || {},
        attachmentUpdated,
        entity: {
          id: entityId,
          type: entityType,
        },
      },
    })
      .onSuccess(({ data }) => {
        setLoading(false);
        removePageLeavePreventDialog();
        window.location.href = links.badge;
      })
      .onError(() => setLoading(false));

    await Requestor.exec();
  };

  const handleDelete = async () => {
    const { csrfToken, links } = props;

    if (window.confirm('Are you sure you want to delete this badge?')) {
      let Request = new Http()
        .setToken(csrfToken)
        .setLoading(() => setLoading(true))
        .useAlerts()
        .doesRedirect(true)
        .onSuccess((_response) => {
          window.location.href = links.badge;
        });

      Request = Request.delete(links.badge);

      await Request.exec();
    }
  };

  const setAttributeValue = (attributeName, attributeValue) => {
    setAttributes(prevState => ({
      ...prevState,
      [attributeName]: attributeValue,
    }));
  };

  const getAttributeValue = attribute => attributes[attribute] || '';

  const handleOnChange = ({ target: { name, value } }) =>
    setAttributeValue(name, value);

  const onAttachmentsChange = attachments => {
    // Note: newly added attachment is always added last - Ref: AssetManager.jsx - onFileUploadSuccess - newAssets
    setAttachments(attachments.slice(-1) || []);
    setExistingAttachments([]);
    setAttachmentUpdated(true);
  };

  const { csrfToken, attachmentOptions } = props;

  const isAttachmentsPresent = React.useMemo(
    () => !isEmpty(existingAttachments) || !isEmpty(attachments),
    [attachments, existingAttachments]
  );

  return (
      <div className="kt-portlet__body">
        {props.badgeId && (
          <div className="text-right mb-3" style={{ marginTop: "-35px" }}>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDelete}
            >
              <i className="kt-nav__link-icon flaticon-delete"></i>
              Delete Badge
            </button>
          </div>
        )}
        <form
          encType="multipart/form-data"
          className="kt-form kt-form--label-right kt-form--fit pt-20"
          onSubmit={handleSubmit}
        >
          <div className="kt-section">
            <FormRow label="Name">
              <TextInput
                numberAttributes={{
                  required: true,
                }}
                name="name"
                value={getAttributeValue('name')}
                onInputChange={handleOnChange}
              />
            </FormRow>
            <FormRow label="Attachment">
              <AssetManager
                showLabel={false}
                showUploader={true}
                showFileDeleteOption={false}
                noAssetOptions={true}
                label={''}
                csrfToken={csrfToken}
                filestackOptions={attachmentOptions}
                existingAssets={existingAttachments || []}
                assets={attachments || []}
                onFileChange={onAttachmentsChange}
              />
              <FormElementHelperText text="Best results with 300x300 (square) dimensions" />
            </FormRow>
            <div className="kt-section row">
              <div className="col-md-2" />
              <div className="col-md-10">
                <CancelButton />
                <SubmitButton
                  buttonText={loading ? 'Saving...' : 'Save'}
                  disabled={loading || !isAttachmentsPresent}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
  );
};

BadgeForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  attachment: PropTypes.object,
  links: PropTypes.shape({
    badge: PropTypes.string,
  }).isRequired,
  badgeId: PropTypes.number,
  attachmentOptions: PropTypes.object,
};

BadgeForm.defaultProps = {
  attachment: {},
};

export default withErrorHandler(BadgeForm);

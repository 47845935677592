import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import AssetPreview from './AssetPreview';
import { humanFileSize } from '../utils';
import AttachmentSlider from './PreviewSlider';

const AttachmentPreviewSlider = ({ attachments }) => {
  const [activePreviewState, setActivePreviewState] = useState(null);

  const handleClick = (id) => {
    setActivePreviewState(id);
  };

  if (_.isEmpty(attachments)) {
    return <Fragment />;
  }

  return (
    <div className="attachment-preview-slider">
      <div className="row">
        {attachments.map(({ mimetype, url, filename, size, links, poster }, index) => (
          <div
            className="col-xl-3 col-lg-4 col-md-4 col-sm-12"
            key={index}
            style={{
              margin: '10px 0',
              minWidth: '230px',
            }}
            onClick={() => handleClick(index + 1)}
          >
            <div className="kt-file">
              <AssetPreview mimetype={mimetype} url={url} poster={poster} />
              <div className="file-name d-flex justify-content-between align-items-center">
                <div style={{ flex: 1, width: 0 }}>
                  <p className="m-0 font-13 text-truncate text-dark">
                    <strong>{filename}</strong>
                  </p>
                  <small className="font-10 text-muted">
                    Size: {humanFileSize(size)}
                  </small>
                </div>
                {links?.download && (
                  <div>
                    <a
                      href={links.download}
                      type="button"
                      className="custom-secondary-btn"
                    >
                      <i className="fas fa-download"></i>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <AttachmentSlider
        attachments={attachments}
        activeState={activePreviewState}
        onClose={() => setActivePreviewState(null)}
      />
    </div>
  );
};

AttachmentPreviewSlider.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string.isRequired,
      links: PropTypes.shape({
        download: PropTypes.string,
      }),
      mimetype: PropTypes.string.isRequired,
      poster: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AttachmentPreviewSlider;

import { constructUserResponses } from './utils';

export const metaDataStateReducer = (state, action) => {
  switch (action.type) {
    case 'initiateMetaDataFetch': {
      return {
        ...state,
        metaDataLoading: true,
      };
    }
    case 'resolvedMetaDataFetch': {
      const { forms, projects, projectCategories } = action.payload;
      return {
        ...state,
        metaDataLoading: false,
        forms: forms,
        projects: projects,
        projectCategories: projectCategories,
      };
    }
    case 'rejectedMetaDataFetch': {
      return {
        ...state,
        metaDataLoading: false,
        forms: [],
        projects: [],
        projectCategories: [],
      };
    }
    default:
      throw new Error('Unhandled Meta Data Action Type.');
  }
};

export const userResponseStateReducer = (state, action) => {
  switch (action.type) {
    case 'initiateResponseLoading': {
      return {
        ...state,
        responsesLoading: true,
      };
    }
    case 'initiateUserResponseFetch': {
      return {
        ...state,
        responsesLoading: true,
        pagingMeta: { ...state.pagingMeta, startIndex: 0 },
      };
    }
    case 'initiateRowsLoading': {
      return {
        ...state,
        pagingMeta: { ...state.pagingMeta, rowsLoading: true },
      };
    }
    case 'resolvedUserResponseFetch': {
      return {
        ...state,
        responsesLoading: false,
        ...action.payload,
      };
    }
    case 'rejectedUserResponseFetch': {
      return {
        ...state,
        userResponses: [],
        responsesLoading: false,
        pagingMeta: {
          ...state.pagingMeta,
          rowsLoading: false,
        },
      };
    }
    default:
      throw new Error('Unhandled User Response Data Action Type.');
  }
};

export const userResponsesIndexTableReducer = (state, action) => {
  switch (action.type) {
    case 'updateUserResponse': {
      return {
        ...state,
        userResponses: constructUserResponses(action.payload),
      };
    }
    case 'updateProjectsHash': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'handleFilterOptions': {
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
      };
    }
    default:
      throw new Error('Unhandled User Response Index Table Data Action Type.');
  }
};

export const userResponsesTableReducer = (state, action) => {
  switch (action.type) {
    case 'updateUserResponse': {
      return {
        ...state,
        userResponses: constructUserResponses(action.payload),
      };
    }
    case 'handleFilterOptions': {
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
      };
    }
    case 'initiateDelete':
    case 'initiateRestore': {
      return { ...state, loading: true };
    }
    case 'resolved':
    case 'rejected': {
      return { ...state, loading: false };
    }
    default:
      throw new Error('Unhandled User Response Table Data Action Type.');
  }
};

export const feedbackFormTableReducer = (state, action) => {
  switch (action.type) {
    case 'initiateFeedbackFormsFetch':
    case 'initiateFormsDataFetch': {
      return { ...state, formsLoading: true };
    }
    case 'initiateUserResponseFetch': {
      return { ...state, responsesLoading: true };
    }
    case 'resolvedFeedbackFormsFetch': {
      return { ...state, formsLoading: false, feedbackForms: action.payload };
    }
    case 'resolvedUserResponseFetch': {
      return {
        ...state,
        responsesLoading: false,
        userResponses: action.payload,
      };
    }
    case 'resolvedFormsDataFetch': {
      return {
        ...state,
        formsLoading: false,
        formDataHash: { ...state.formDataHash, ...action.payload },
      };
    }
    case 'rejectedFeedbackFormsFetch': {
      return { ...state, formsLoading: false, feedbackForms: [] };
    }
    case 'rejectedUserResponseFetch': {
      return { ...state, responsesLoading: false, userResponses: [] };
    }
    case 'rejectedFormsDataFetch': {
      return { ...state, formsLoading: false };
    }
    case 'selectFormId': {
      return {
        ...state,
        selectedFormId: action.payload,
      };
    }
    case 'cancelNewForm': {
      return {
        ...state,
        newFormMode: false,
        selectedFormId: null,
      };
    }
    case 'handleNewForm': {
      return {
        ...state,
        newFormMode: true,
        selectedFormId: null,
      };
    }
    default:
      throw new Error('Unhandled Feedback Form Table Data Action Type.');
  }
};

export const impressionHeatMapReducer = (state, action) => {
  switch (action.type) {
    case 'handleFilterOptions': {
      return {
        ...state,
        filterOptions: { ...state.filterOptions, ...action.payload },
      };
    }
    case 'updateHeatMapData': {
      return { ...state, impressionMapData: action.payload };
    }
    case 'initiateMetaDataFetch': {
      return { ...state, metaDataLoading: true };
    }
    case 'resolvedMetaDataFetch': {
      return { ...state, metaDataLoading: false, ...action.payload };
    }
    case 'rejectedMetaDataFetch': {
      return {
        ...state,
        metaDataLoading: false,
        projects: {},
        projectCategories: [],
      };
    }
    case 'initiateHeatMapDataFetch': {
      return { ...state, loading: true };
    }
    case 'resolvedHeatMapDataFetch': {
      return { ...state, loading: false, ...action.payload };
    }
    case 'rejectedHeatMapDataFetch': {
      return { ...state, loading: false, projectImpressions: {} };
    }
    default:
      throw new Error('Unhandled Impression Heat Map Data Action Type.');
  }
};

// Removed the usage of the function, since the FormShowPage Component state is simple.
// keeping this for the future reference.
// export const formShowPageReducer = (state, action) => {
//   switch (action.type) {
//     case 'initiateClosestProjectFetch':
//     case 'initiateFormResponseSubmit': {
//       return { ...state, loading: true };
//     }
//     case 'formResponseResolved':
//     case 'formResponseRejected': {
//       return { ...state, loading: false };
//     }
//     case 'closestProjectFetchResolved': {
//       return { ...state, loading: false, closestProject: action.payload };
//     }
//     case 'closestProjectFetchRejected': {
//       return { ...state, loading: false, closestProject: {} };
//     }
//     default:
//       throw new Error('Unhandled Action Type.');
//   }
// };

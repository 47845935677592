import React from 'react';
import PropTypes from 'prop-types';

import CloneLink from './cloning/CloneLink';
import { checkIsForumClonable, getForumCloningTitle } from './cloning/utils';
import { formatDateTime } from '../../common/utils';
import InitialsAvatar from '../../common/Avatar';

export const NormalCourseTableBody = ({
  courses,
  handleArchiveRequest,
  handleUnArchiveRequest,
  handlePublishedStatus,
  toggleLoader,
  csrfToken,
}) => (
  <tbody>
    {courses.map(
      (
        {
          id,
          name,
          memberUsersCount,
          contentItemsCount,
          archived,
          published,
          links,
          cloneConfig,
          lastModifiedAt,
          lastModifiedBy,
        },
        index
      ) => {
        const isForumClonable = checkIsForumClonable(
          cloneConfig.forumCloningCompleted
        );
        const forumCloningTitle = getForumCloningTitle(
          cloneConfig.forumCloningCompleted
        );
        return (
          <tr key={id}>
            <td className="text-center td-50">{index + 1}</td>
            <td>{name}</td>
            <td>{memberUsersCount}</td>
            <td>{contentItemsCount}</td>
            <td>
              {lastModifiedAt
                ? formatDateTime({
                    date: lastModifiedAt,
                    formatTime: true,
                  })
                : 'N/A'}
            </td>
            <td>
              {lastModifiedBy ? (
                <InitialsAvatar
                  url={lastModifiedBy.avatar}
                  name={lastModifiedBy.name}
                />
              ) : (
                'N/A'
              )}
            </td>
            <td className="td-110 text-center">
              <div className="btn-group dropleft">
                <button
                  type="button"
                  className="btn-sm app-btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Options
                </button>
                <div className="dropdown-menu">
                  <a href={links.show} className="dropdown-item">
                    View
                  </a>
                  <a href={links.report} className="dropdown-item">
                    Report
                  </a>
                  <a href={links.edit} className="dropdown-item">
                    Edit
                  </a>
                  <a href={links.manageParticipants} className="dropdown-item">
                    Manage Participants
                  </a>
                  <a href={links.retake} className="dropdown-item">
                    Retake
                  </a>
                  <CloneLink
                    link={links.clone}
                    subjectName={name}
                    csrfToken={csrfToken}
                    toggleLoader={toggleLoader}
                  />
                  <a
                    href={
                      isForumClonable ? links.initiateForumPostsCloning : null
                    }
                    className={`dropdown-item ${
                      !isForumClonable && 'cursor-disabled'
                    }`}
                    title={forumCloningTitle}
                  >
                    Initiate Forum Cloning
                  </a>
                  {!archived &&
                    (published ? (
                      <button
                        className="dropdown-item"
                        onClick={handlePublishedStatus(links.unpublish)}
                      >
                        Mark as Unpublished
                      </button>
                    ) : (
                      <button
                        className="dropdown-item"
                        onClick={handlePublishedStatus(links.publish)}
                      >
                        Mark as Published
                      </button>
                    ))}
                  {!published && !archived && (
                    <button
                      className="dropdown-item text-darkred"
                      onClick={handleArchiveRequest(links.archive)}
                    >
                      Archive
                    </button>
                  )}
                  {archived && (
                    <button
                      className="dropdown-item text-danger"
                      onClick={handleUnArchiveRequest(links.unarchive)}
                    >
                      UnArchive
                    </button>
                  )}
                </div>
              </div>
            </td>
          </tr>
        );
      }
    )}
  </tbody>
);
NormalCourseTableBody.propTypes = {
  courses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      archived: PropTypes.bool,
      cloneConfig: PropTypes.object,
      contentItemsCount: PropTypes.number,
      description: PropTypes.string,
      links: PropTypes.object,
      memberUsersCount: PropTypes.number,
      objective: PropTypes.string,
      overview: PropTypes.string,
      published: PropTypes.bool,
      sequential: PropTypes.bool,
      tenantId: PropTypes.number,
    })
  ),
  handleArchiveRequest: PropTypes.func,
  handleUnArchiveRequest: PropTypes.func,
  handlePublishedStatus: PropTypes.func,
  toggleLoader: PropTypes.func,
  csrfToken: PropTypes.string,
};

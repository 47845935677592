import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { withErrorHandler } from '../hoc/withErrorHandler';

import './showpage.scss';

const ShowPageSideBar = ({
  csrfToken,
  links,
  allowForum,
  isUserEnrolled,
  showEnrollmentSection,
  courseDuration,
  totalCourseModules,
  isUserEnrollableCourse,
  isCourseManager,
}) => {
  const [pageLoader, setPageLoader] = useState(false);

  const enrollUser = () => {
    new Http()
      .onBegin(() => setPageLoader(true))
      .useAlerts()
      .doesRedirect(false)
      .setToken(csrfToken)
      .post(links.enrollCourse)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        window.location.href = data.redirectionUrl;
      })
      .onError(err => {
        console.log(err);
        setPageLoader(false);
      })
      .exec();
  };

  if (showEnrollmentSection && isUserEnrollableCourse && !isUserEnrolled) {
    return (
      <div className="course_sidebar">
        {pageLoader && <Spinner text="Connecting..." />}
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <button className="btn btn-darkred w-100 mb-4" onClick={enrollUser}>
              <strong className="text-uppercase">ENROLL</strong>
            </button>
            <div className="text-black-50 weight-500">
              <p>
                <i className="fas fa-book mr-3"></i>
                {totalCourseModules} Modules
              </p>
              {courseDuration && (
                <p>
                  <i className="far fa-clock mr-3"></i>
                  {courseDuration} total time
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return isUserEnrolled || isCourseManager ? (
    <div className="course_sidebar">
      {pageLoader && <Spinner text="Connecting..." />}
      {allowForum && (
        <a href={links.forum}>
          <div
            className="course_sidebar-forum mt-3 p-3 mb-3 d-flex justify-content-center align-items-center rounded text-white"
            style={{ backgroundImage: `url(${links?.assets?.forumBg})` }}
          >
            <h2>Discussion Forum</h2>
          </div>
        </a>
      )}
    </div>
  ) : null;
};

ShowPageSideBar.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  allowForum: PropTypes.bool.isRequired,
  isUserEnrolled: PropTypes.bool,
  isUserEnrollableCourse: PropTypes.bool.isRequired,
  showEnrollmentSection: PropTypes.bool,
  totalCourseModules: PropTypes.number,
  courseDuration: PropTypes.string,
  isCourseManager: PropTypes.bool.isRequired,
  links: PropTypes.shape({
    enrollCourse: PropTypes.string,
    forum: PropTypes.string,
    assets: PropTypes.shape({
      forumBg: PropTypes.string,
    }),
  }),
};

ShowPageSideBar.defaultProps = {
  isUserEnrolled: false,
  showEnrollmentSection: false,
  totalCourseModules: 0,
};

export default withErrorHandler(ShowPageSideBar);

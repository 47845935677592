import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { SortableCourseTableBody } from './SortableCourseTableBody';
import { NormalCourseTableBody } from './NormalCourseTableBody';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import { reorder } from '../../common/utils';
import {
  alertSuccessNotifications,
  alertErrorNotifications,
  clearAlertNotifications,
} from '../../folders/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import TextInput from '../../common/inputs/text';

const CourseTable = ({
  courses,
  sortable,
  links,
  tenantTerms,
  csrfToken,
  keyword,
  onKeywordChange,
}) => {
  const [coursesData, setCoursesData] = useState(courses);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCoursesData(courses);
  }, [courses]);

  const handlePatchRequest = url => {
    new Http()
      .onBegin(() => setIsLoading(true))
      .setToken(csrfToken)
      .doesRedirect(true)
      .patch(url)
      .useAlerts()
      .onSuccess(response => {
        setIsLoading(false);
        window.location = response.data.redirection_url || window.location.href;
      })
      .onError(() => setIsLoading(false))
      .exec();
  };

  const handleArchiveRequest = url => () => {
    const proceed = confirm('Are you sure, you want to archive this?');
    if (proceed) {
      handlePatchRequest(url);
    }
  };

  const handleUnArchiveRequest = url => () => {
    const proceed = confirm('Are you sure, you want to Unarchive this?');
    if (proceed) {
      handlePatchRequest(url);
    }
  };

  const handlePublishedStatus = url => async event => {
    event.preventDefault();
    handlePatchRequest(url);
  };

  const onDragEnd = async result => {
    const { changeOrder } = links;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedCourses = reorder(
      [...courses],
      result.source.index,
      result.destination.index
    );

    clearAlertNotifications();

    const courseIds = map(sortedCourses, 'id');

    await new Http()
      .onBegin(() => setIsLoading(true))
      .setToken(csrfToken)
      .post(changeOrder) //Change this
      .setPostData({
        course_ids: courseIds,
      })
      .onSuccess(() => {
        setIsLoading(false);
        setCoursesData(sortedCourses);
        alertSuccessNotifications('Order Updated Successfully!');
      })
      .onError(() => {
        setIsLoading(false);
        alertErrorNotifications('Unable to reorder at this moment!');
      })
      .exec();
  };

  const toggleLoader = () => {
    setIsLoading(prevState => !prevState);
  };

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {tenantTerms.termCourse.plural}
            {!isEmpty(coursesData) && (
              <span className="text-success"> ({coursesData.length})</span>
            )}
          </h3>
        </div>

        <div className="kt-portlet__head-toolbar" style={{ minWidth: '40%' }}>
          {onKeywordChange && (
            <input
              name="filterText"
              onChange={({ target: { value } }) => onKeywordChange(value)}
              type="text"
              placeholder="Filter by name"
              className="form-control mx-2"
              style={{ width: '100%' }}
              value={keyword}
              autoFocus
            />
          )}

          {links?.new && (
            <a
              className="app-btn-primary"
              href={links.new}
              style={{ width: '80%' }}
            >
              New {tenantTerms.termCourse.singular}
            </a>
          )}
        </div>
      </div>
      <div className="kt-portlet__body px-0">
        <div className="table-responsive">
          {isLoading && <Spinner />}
          <table className="table kt-table__row-equal-width">
            <thead>
              <tr>
                <th className="text-center td-50"></th>
                <th>Name</th>
                <th>Enrolled</th>
                <th>Content Items</th>
                <th>Last Modified At</th>
                <th>Last Modified By</th>
                <th className="td-110 text-center">Actions</th>
              </tr>
            </thead>

            {!isEmpty(coursesData) &&
              (sortable ? (
                <SortableCourseTableBody
                  courses={coursesData}
                  handleUnArchiveRequest={handleUnArchiveRequest}
                  handleArchiveRequest={handleArchiveRequest}
                  handlePublishedStatus={handlePublishedStatus}
                  onDragEnd={onDragEnd}
                  csrfToken={csrfToken}
                  toggleLoader={toggleLoader}
                />
              ) : (
                <NormalCourseTableBody
                  courses={coursesData}
                  handleUnArchiveRequest={handleUnArchiveRequest}
                  handleArchiveRequest={handleArchiveRequest}
                  handlePublishedStatus={handlePublishedStatus}
                  csrfToken={csrfToken}
                  toggleLoader={toggleLoader}
                />
              ))}

            {isEmpty(coursesData) && (
              <tbody>
                <tr>
                  <td colSpan="6" className="p-4 text-center">
                    {`There are no ${tenantTerms.termCourse.plural} at this time.`}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

CourseTable.defaultProps = {
  courses: [],
  sortable: false,
  links: {},
};

CourseTable.propTypes = {
  courses: PropTypes.array.isRequired,
  sortable: PropTypes.bool,
  links: PropTypes.shape({
    new: PropTypes.string,
    changeOrder: PropTypes.string,
  }),
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  csrfToken: PropTypes.string.isRequired,
};

export default withErrorHandler(CourseTable);

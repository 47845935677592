import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FeedbackSummary from './feedback_summary';
import { SURVEY_SUMMARY_TAB, SURVEY_RESPONSES_TAB } from './constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

import CourseSurveyListing from './feedback_listing';

class CourseFormSurveysManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', (e) => this.redirectPrevTab(e), false);
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.redirectPrevTab, false);
  }

  redirectPrevTab = (e) => {
    e.preventDefault();

    window.history.replaceState('', '', `${this.redirectUrl()}`);
    window.location.href = this.redirectUrl();

    return null;
  };

  redirectUrl = (isTabRedirection = false) => {
    const { activeTab } = this.state;

    let url = window.location.href;
    let redirectionURL = url;

    if (isTabRedirection) {
      const lastPathName = _.chain(url).split('/').last().value();
      let newPathName = lastPathName;

      if (!_.isEqual(lastPathName, activeTab)) {
        newPathName = activeTab;
      }
      redirectionURL = `${url.replace(lastPathName, newPathName)}`;
    }

    return `${redirectionURL}`;
  };

  handleActiveTab = (tab) => {
    this.setState(
      {
        activeTab: tab,
      },
      () => {
        if (history.pushState) {
          window.history.pushState('', '', `${this.redirectUrl(true)}`);
        }
      }
    );
  };

  renderSurveyContent = () => {
    const { activeTab, currentSelectedForm } = this.state;

    const {
      links: { fetchResponsesListing },
      csrfToken,
      tenantTerms,
    } = this.props;

    return activeTab === SURVEY_SUMMARY_TAB ? (
      <FeedbackSummary selectedForm={currentSelectedForm} {...this.props} />
    ) : (
      <CourseSurveyListing
        fetchResponsesListing={fetchResponsesListing}
        csrfToken={csrfToken}
        tenantTerms={tenantTerms}
      />
    );
  };

  render() {
    const { courseFeedbackFormName } = this.props;
    const { activeTab } = this.state;

    return (
      <div className="kt-content">
        <div className="p-4 mb-30">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h3 className="">{`${courseFeedbackFormName} - Responses Overview`}</h3>
            <div className="btn-group" role="group" style={{ backgroundColor: '#FFF' }}>
              <button
                type="button"
                className={`btn  ${
                  activeTab === SURVEY_SUMMARY_TAB ? 'app-btn-primary' : 'btn-outline-brand'
                }`}
                onClick={() => this.handleActiveTab(SURVEY_SUMMARY_TAB)}
              >
                Summary
              </button>
              <button
                type="button"
                className={`btn  ${
                  activeTab === SURVEY_RESPONSES_TAB ? 'app-btn-primary' : 'btn-outline-brand'
                }`}
                onClick={() => this.handleActiveTab(SURVEY_RESPONSES_TAB)}
              >
                Responses
              </button>
            </div>
          </div>
        </div>
        {this.renderSurveyContent()}
      </div>
    );
  }
}

CourseFormSurveysManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  courseFeedbackFormName: PropTypes.string.isRequired,
  links: PropTypes.shape({
    fetchSummary: PropTypes.string.isRequired,
    fetchResponsesListing: PropTypes.string.isRequired,
  }).isRequired,
};

CourseFormSurveysManager.defaultProps = {
  activeTab: SURVEY_SUMMARY_TAB,
};

export default withErrorHandler(CourseFormSurveysManager);

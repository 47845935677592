import isPlainObject from 'lodash/isPlainObject';
import camelCaseKeys from '../../dataFormatters/camelCase';

const DEFAULT_CAMELIZER_OPTIONS = Object.freeze({
  exclude: [],
  stopPaths: [],
  deep: true,
  pascalCase: false,
});

export const responseDataCamelizer =
  (formattingOptions = {}) =>
  response => ({
    ...response,
    ...(isPlainObject(response.data) && {
      data: camelCaseKeys(response.data, {
        ...DEFAULT_CAMELIZER_OPTIONS,
        ...formattingOptions,
      }),
    }),
  });

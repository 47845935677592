import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetUploader from '../filestack/AssetUploader';
import Gallery from '../filestack/gallery';
import GalleryListView from './GalleryListView/index';
import AssetsAsList from './AssetsAsListView/index';
import AssetListWithFolderSelect from '../filestack/AssetListWithFolderSelect';
import { deleteAssetFromFS } from '../filestack/utils/deleteAsset';
import { addPageLeavePreventDialog, removePageLeavePreventDialog } from '../common/utils';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

class AssetManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  listenerAdded = false;

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  onFileUploadError = error => {
    console.log(error);
  };

  setLoadingFalse = () => {
    this.setState({
      loading: false,
    });
  };

  filterAssets = (assets, assetHandle) =>
    _.filter(assets, ({ handle }) => !(handle == assetHandle));

  removeAsset = async assetHandle => {
    const { assets, existingAssets } = this.props;

    // Delete from filestack
    const res = await deleteAssetFromFS(assetHandle);

    if (res.status === 200) {
      const newAssets = this.filterAssets(assets, assetHandle);
      this.setLoadingFalse();
      this.props.onFileChange(newAssets, existingAssets);
    } else {
      // Handle errors in a better way
      this.setLoadingFalse();
      alertErrorNotifications('Asset deletion failed, please try again later!');
    }
  };

  deleteAssetFromDb = async (assetId, handle) => {
    const { csrfToken, assets, existingAssets } = this.props;

    const Requester = await new Http(this)
      .setToken(csrfToken)
      .delete(`/delete_asset/${assetId}`)
      .onSuccess(() => {
        const filteredExistingAssets = this.filterAssets(existingAssets, handle);
        this.setLoadingFalse();
        this.props.onFileChange(assets, filteredExistingAssets);
      })
      .onError(error => {
        console.log(error);
        alertErrorNotifications(error);
        this.setLoadingFalse();
      })
      .exec();
  };

  handleAssetDelete = (assetId, handle) => {
    const { handleGalleryItemDelete, showGallery } = this.props;

    /**
     * Use a custom File delete method for gallery
     * example., when we want to delete file from showing in gallery
     * not from DB and Filestack
     */
    if (showGallery && !_.isNil(handleGalleryItemDelete)) {
      this.props.handleGalleryItemDelete(assetId, handle);
    } else {
      if (window.confirm('Are you sure you want to delete this asset?')) {
        this.setState({
          loading: true,
        });

        if (!!assetId) {
          // Remove asset from database
          this.deleteAssetFromDb(assetId, handle);
        } else {
          //Delete asset from filestack and aws
          this.removeAsset(handle);
        }
      }
    }
  };

  onFileUploadSuccess = async ({ filesUploaded }) => {
    const { assets, existingAssets } = this.props;
    const newAssets = _.flatten([...assets, filesUploaded]);

    if (!this.listenerAdded) {
      this.listenerAdded = true;
      addPageLeavePreventDialog();
    }

    this.setLoadingFalse();
    this.props.onFileChange(newAssets, existingAssets);
  };

  getAssetsFromProps = () => {
    let { sortableGallery, sortOrder, assets, existingAssets } = this.props;
    let allAssets = _.flatten([...assets, ...existingAssets]);

    if (sortableGallery && !_.isEmpty(sortOrder)) {
      let sortedAssets = allAssets.sort(
        (firstElement, secondElement) =>
          sortOrder.indexOf(firstElement['handle']) -
          sortOrder.indexOf(secondElement['handle'])
      );

      return sortedAssets;
    } else {
      return allAssets;
    }
  };

  render() {
    const {
      filestackOptions,
      label,
      assets,
      existingAssets,
      noAssetOptions,
      assetsWithFolders,
      showUploader,
      renderUploader,
      showAssetsWithFolderSelect,
      onAssetFolderChange,
      assetFolderList,
      showLabel,
      showGallery,
      showGalleryInListView,
      labelClassName,
      galleryClassName,
      wrapperClassName,
      inputColClassName,
      rowClassName,
      selectableMode,
      selectedAssets,
      onAssetSelect,
      hideFileInfo,
      showFileDeleteOption,
      folderSelectClassName,
      readOnly,
      csrfToken,
      libraryViewConfig,
      selectableModeToggleText,
      assetFoldersConfig,
      showMoreAssetsCount,
      sortableGallery,
      showAssetsAsListItems, // This will be in effect only for Gallery and when AssetManager is used directly without any wrapper.
      onSortComplete,
      showFileShareOption,
      showAssetDownloadOption = true,
      shareEnablerPredicate,
      onFileShareClick,
    } = this.props;

    const { loading } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <div className={`w-100 ${wrapperClassName}`}>
        {showUploader && (
          <AssetUploader
            readOnly={readOnly}
            rowClassName={rowClassName}
            inputColClassName={inputColClassName}
            labelClassName={labelClassName}
            showLabel={showLabel}
            label={label}
            options={filestackOptions}
            onSuccess={this.onFileUploadSuccess}
            onError={this.onFileUploadError}
            renderUploader={_.isNil(renderUploader) ? undefined : renderUploader}
          />
        )}
        {showGallery && !showGalleryInListView && !showAssetsAsListItems && (
          <Gallery
            sortableGallery={sortableGallery}
            onSortComplete={onSortComplete}
            galleryClassName={galleryClassName}
            showAssetDeleteOption={showFileDeleteOption}
            hideFileInfo={hideFileInfo}
            assets={this.getAssetsFromProps()}
            noAssetOptions={noAssetOptions}
            showAssetShareOption={showFileShareOption}
            showAssetDownloadOption={showAssetDownloadOption}
            onAssetShareClick={onFileShareClick}
            onAssetDelete={this.handleAssetDelete}
            assetShareEnablerPredicate={shareEnablerPredicate}
            selectableMode={selectableMode}
            selectedAssets={selectedAssets}
            onAssetSelect={onAssetSelect}
            csrfToken={csrfToken}
            libraryViewConfig={libraryViewConfig}
            showMoreAssetsCount={showMoreAssetsCount}
          />
        )}
        {showGalleryInListView && (
          <GalleryListView
            galleryClassName={galleryClassName}
            showAssetDeleteOption={showFileDeleteOption}
            hideFileInfo={hideFileInfo}
            assets={_.flatten([...assets, existingAssets])}
            onAssetDelete={this.handleAssetDelete}
          />
        )}

        {showAssetsAsListItems && (
          <AssetsAsList
            assets={_.flatten([...assets, existingAssets])}
            handleAssetDelete={this.handleAssetDelete}
          />
        )}

        {showAssetsWithFolderSelect && (
          <div className="form-group kt-form__group row">
            <div className={folderSelectClassName}>
              <AssetListWithFolderSelect
                assets={_.flatten([...assets, existingAssets])}
                assetsWithFolders={assetsWithFolders}
                onAssetFolderChange={onAssetFolderChange}
                assetFolderList={assetFolderList}
                onAssetDelete={this.handleAssetDelete}
                assetFoldersConfig={assetFoldersConfig}
                csrfToken={csrfToken}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

AssetManager.propTypes = {
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputColClassName: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  showUploader: PropTypes.bool,
  showLabel: PropTypes.bool,
  filestackOptions: (props, propName, componentName) => {
    const showUploader = props['showUploader'];
    if (showUploader) {
      if (_.isNil(props[propName])) {
        return new Error(`
          ${componentName} requires the prop "${propName}", if "showUploader" is true.
        `);
      }
    }
  },
  renderUploader: PropTypes.func,

  assets: PropTypes.array.isRequired,
  existingAssets: PropTypes.array,
  noAssetOptions: PropTypes.bool,
  onFileChange: PropTypes.func.isRequired,
  showGallery: PropTypes.bool,
  showGalleryInListView: PropTypes.bool,
  handleGalleryItemDelete: PropTypes.func,
  showFileShareOption: PropTypes.bool,
  selectableMode: PropTypes.bool,
  onAssetSelect: PropTypes.func,
  selectedAssets: PropTypes.array,
  shareEnablerPredicate: PropTypes.func,
  onFileShareClick: (props, propName, componentName) => {
    const dependentPropKey = 'showFileShareOption';

    if (props[dependentPropKey]) {
      if (!_.isFunction(props[propName])) {
        return new Error(
          `
            Invalid prop ${propName} supplied to ${componentName}\n
            If ${dependentPropKey} is true, function is required
          `
        );
      }
    }
  },
  showAssetsWithFolderSelect: (props, propName, componentName) => {
    const showAssetsWithFolderSelect = props[propName];
    if (showAssetsWithFolderSelect) {
      const assetsWithFolders = props['assetsWithFolders'];

      if (_.isNil(assetsWithFolders)) {
        return new Error(`
          ${componentName} requires the prop "assetsWithFolders", if "showAssetsWithFolderSelect" is true.
        `);
      }

      const onAssetFolderChange = props['onAssetFolderChange'];

      if (!_.isFunction(onAssetFolderChange)) {
        return new Error(`
          ${componentName} requires the prop "onAssetFolderChange", if "showAssetsWithFolderSelect" is true.
        `);
      }

      const assetFolderList = props['assetFolderList'];

      if (!_.isArray(assetFolderList)) {
        return new Error(`
          ${componentName} requires the prop "assetFolderList", if "showAssetsWithFolderSelect" is true.
        `);
      }
    }
  },
  assetFoldersConfig: (props, propName, componentName) => {
    const assetFoldersConfig = props[propName];
    if (assetFoldersConfig) {
      const assetsWithFolders = props['assetsWithFolders'];

      if (_.isNil(assetsWithFolders)) {
        return new Error(`
          ${componentName} requires the prop "assetsWithFolders", if "assetFoldersConfig" is true.
        `);
      }

      const onAssetFolderChange = props['onAssetFolderChange'];

      if (!_.isFunction(onAssetFolderChange)) {
        return new Error(`
          ${componentName} requires the prop "onAssetFolderChange", if "assetFoldersConfig" is true.
        `);
      }

      const assetFolderList = props['assetFolderList'];

      if (!_.isArray(assetFolderList)) {
        return new Error(`
          ${componentName} requires the prop "assetFolderList", if "assetFoldersConfig" is true.
        `);
      }
    }
  },
  hideFileInfo: PropTypes.bool,
  showFileDeleteOption: PropTypes.bool,
  assetsWithFolders: PropTypes.object,
  onAssetFolderChange: PropTypes.func,
  assetFolderList: PropTypes.array,
  galleryClassName: PropTypes.string,
  libraryViewConfig: PropTypes.object,
  showMoreAssetsCount: PropTypes.bool,
};

AssetManager.defaultProps = {
  showUploader: true,
  hideFileInfo: false,
  showLabel: true,
  readOnly: false,
  existingAssets: [],
  assets: [],
  noAssetOptions: false,
  filestackOptions: {},
  showGallery: true,
  showGalleryInListView: false,
  showFileShareOption: false,
  wrapperClassName: '',
  labelClassName: 'col-md-2',
  inputColClassName: 'col-md-10',
  folderSelectClassName: 'offset-md-2 col-md-10',
  handleGalleryItemDelete: null,
  showFileDeleteOption: true,
  shareEnablerPredicate: () => false,
  onFileShareClick: () => {},
  showAssetsWithFolderSelect: false,
  selectableMode: false,
  selectedAssets: [],
  onAssetSelect: () => {},
  onFileChange: () => {},
  onSortComplete: () => {},
  libraryViewConfig: null,
  showMoreAssetsCount: false,
  sortableGallery: false,
  showAssetsAsListItems: false,
  sortOrder: [],
};

export default withErrorHandler(AssetManager);

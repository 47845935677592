import React from 'react';
import PropTypes from 'prop-types';

import {
  ALERT_CLASSES,
  ALERT_DIALOG_WRAPPER_STYLE,
  ALERT_DIALOG_BUTTON_STYLE,
} from '../constants';

class AlertDialog extends React.Component {
  componentDidMount() {
    this.timer = setTimeout(this.props.onClose, this.props.timeout);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  getAlertClassByType = alertType =>
    ALERT_CLASSES[alertType] || ALERT_CLASSES['notice'];

  renderAlertMessage = messagePayload => {
    if (_.isString(messagePayload)) {
      return messagePayload.replace(/\^/g, '').replace(/<[^>]+>/g, '');
    } else if (_.isObject(messagePayload)) {
      return (
        <React.Fragment>
          {_.map(messagePayload, (message, messageHeader) => (
            <React.Fragment key={messageHeader}>
              <h6>{_.capitalize(messageHeader.replace(/<[^>]+>/g, ''))}</h6>
              <ul>
                {_.map(_.castArray(message), messageObject => (
                  <li key={Math.random().toString().substring(8)}>
                    {this.renderAlertMessage(messageObject)}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    } else {
      return _.toString(_.capitalize(messagePayload.replace(/<[^>]+>/g, '')));
    }
  };

  render() {
    const { message } = this.props;
    const alertClassAsType = this.getAlertClassByType(message.type);

    return (
      <div
        data-notify="container"
        className={`alert ${alertClassAsType} animated bounce`}
        role="alert"
        data-notify-position="bottom-center"
        style={{
          ...ALERT_DIALOG_WRAPPER_STYLE,
        }}
      >
        <button
          onClick={this.props.onClose}
          type="button"
          aria-hidden="true"
          className="close"
          data-notify="dismiss"
          style={ALERT_DIALOG_BUTTON_STYLE}
        />

        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {this.renderAlertMessage(message.payload)}
        </span>
      </div>
    );
  }
}

AlertDialog.propTypes = {
  onClose: PropTypes.func,
  timeout: PropTypes.number,
  dialogPosition: PropTypes.number.isRequired,
  message: PropTypes.object.isRequired,
};

AlertDialog.defaultProps = {
  timeout: 5000,
};

export default AlertDialog;

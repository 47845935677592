import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import includes from 'lodash/includes';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import FormBuilder from 'cb-react-form-builder';
import './form_styles.scss';
import CustomAttachmentSlider from './custom/CustomAttachmentSlider';
import CustomTextInput from './custom/CustomTextInput';
import FormToggleBox from './custom/FormToggleBox';
import CustomDatePicker from './custom/CustomDatePicker';
import CustomSelectDropDown from './custom/CustomSelectDropDown';
import SubmissionsEvaluator from '../common/SubmissionsEvaluator';
import CustomPhoneInput from './custom/CustomPhoneInput';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS } from '../common/constants';
import { constructUserResponseSentimentsHash } from './utils';

const CTextInputRenderer = ({ name, answerData }) => {
  const response = (answerData || {})[name] || '-';
  return response;
};

const UserResponseShowPage = props => {
  const [showEvaluator, setShowEvaluator] = useState(false);
  const userResponseSentiments = constructUserResponseSentimentsHash(props);

  const showEvaluatorMode = e => {
    e.preventDefault();
    setShowEvaluator(true);
  };

  const hideEvaluatorMode = e => {
    e.preventDefault();
    setShowEvaluator(false);
  };

  const createAssetsArrayFromResponse = responseObject => {
    const { data } = responseObject;
    const allFields = Object.keys(data);
    let assetsArray = [];
    allFields.forEach(keyName => {
      if (keyName.startsWith('attachments_')) {
        assetsArray.push(...data[keyName]);
      }
    });
    return assetsArray.map(arr => ({
      id: arr.id || Math.ceil(Math.random() * 100000),
      ...arr,
    }));
  };

  const {
    formId,
    feedbackForm,
    readOnly,
    userResponse,
    attachableName,
    csrfToken,
    canEvaluate,
    evaluationConfig,
    tenantTerms,
    userList,
    projectList,
    sentimentalFields,
    ticketsEnabled,
    isTicketsShowPage,
    isSimpleProject,
    respondedQuestionData,
  } = props;

  const assets = createAssetsArrayFromResponse(userResponse);
  const assetsAvailable = assets.length > 0;
  if (showEvaluator) {
    return (
      <React.Fragment>
        <div className="mb-4 text-right">
          <button
            type="button"
            className="btn btn-darkred"
            onClick={hideEvaluatorMode}
          >
            Go Back
          </button>
        </div>
        <SubmissionsEvaluator
          canEvaluate={canEvaluate}
          tenantTerms={tenantTerms}
          evaluationConfig={evaluationConfig}
          csrfToken={csrfToken}
          assets={assets}
          noAssetOptions={true}
          // Prop to provide gallery mode toggle button inner text
          // for ON and OFF states
          selectableGalleryConfig={{
            onLabel: 'Evaluate',
            offLabel: 'Preview',
          }}
        />
      </React.Fragment>
    );
  }

  const convertedKeys = map(respondedQuestionData, item => {
    let response = {
      ...item,
    };

    if (includes(sentimentalFields, item.element)) {
      if (!isEmpty(userResponseSentiments)) {
        response['sentiment'] = get(
          userResponseSentiments,
          `${item.field_name}[0]`,
          {}
        );
      }
    }

    // To render text without text input
    if (item.element === 'TextInput') {
      response['custom'] = true;
      response['element'] = 'CTextInput';
      response['component'] = CTextInputRenderer;
    }

    if (item.custom) {
      switch (item.element) {
        case 'CustomAssetManager':
          response['component'] = CustomAttachmentSlider;
          break;
        case 'NameTextInput':
        case 'EmailTextInput':
          response['component'] = CustomTextInput;
          break;
        case 'CustomPhoneInput':
          response['component'] = CustomPhoneInput;
          break;
        case 'FormToggleBox':
          response['component'] = FormToggleBox;
          break;
        case 'DateTime':
        case 'Time':
          response['component'] = CustomDatePicker;
          break;
        case 'CustomMemberDropdown':
          response['component'] = CustomSelectDropDown;
          response['props']['optionsList'] = userList;
          break;
        case 'CustomProjectDropdown':
          response['component'] = CustomSelectDropDown;
          response['props']['optionsList'] = projectList;
          response['props']['customFilterOptionKeys'] =
            DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS;
          break;
      }

      response['read_only'] = readOnly;
      response['props'] = response['props'] || {};
      response['props']['csrfToken'] = csrfToken;
    }
    return response;
  });

  const convertedResponse = reduce(
    Object.entries(userResponse.data),
    (result, [key, values]) => {
      if (key.startsWith('tags')) {
        result[key] = Array.isArray(values)
          ? values.map(v => v.value || v.label).join(',')
          : values;
      } else {
        result[key] = values;
      }
      return result;
    },
    {}
  );

  return (
    <div
      className={`kt-portlet kt-portlet--full-height kt-portlet--tabs ${
        isTicketsShowPage ? 'kt-portlet--unelevate' : ''
      }`}
    >
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {feedbackForm.name}
            {attachableName}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar align-items-center">
          <h5>{userResponse.user && userResponse.user.name}</h5>
        </div>
      </div>
      <div className="kt-portlet__body">
        <FormBuilder.ReactFormGenerator
          task_id={formId}
          answer_data={convertedResponse}
          data={convertedKeys}
          hide_actions={readOnly}
          read_only={readOnly}
          onSubmit={() => {}}
        />
      </div>
      <div className="kt-portlet__foot d-flex align-items-center justify-content-between">
        {assetsAvailable && canEvaluate && !isSimpleProject && (
          <button
            type="button"
            className="app-btn-primary"
            onClick={showEvaluatorMode}
          >
            Evaluate Now
          </button>
        )}
        {ticketsEnabled && !isTicketsShowPage && (
          <React.Fragment>
            {!isEmpty(userResponse['ticket']) ? (
              <a
                href={userResponse.ticket.links['show']}
                className="app-btn-primary"
              >
                <span>{`View ${tenantTerms.term_ticket.singular} Progress`}</span>
              </a>
            ) : (
              <a
                href={userResponse.links['new_user_response_ticket']}
                className="ptb-5 text-darkred"
              >
                Create <b>{tenantTerms.term_ticket.singular}</b> from this
                response
              </a>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

UserResponseShowPage.defaultProps = {
  readOnly: true,
  userList: [],
  ticketsEnabled: false,
  isTicketsShowPage: false,
  isSimpleProject: false,
};

UserResponseShowPage.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  feedbackForm: PropTypes.object.isRequired,
  userResponse: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  ticketsEnabled: PropTypes.bool,
  isTicketsShowPage: PropTypes.bool,
  isSimpleProject: PropTypes.bool,
  respondedQuestionData: PropTypes.array.isRequired,
  attachableName: PropTypes.string,
  csrfToken: PropTypes.string,
  canEvaluate: PropTypes.bool,
  evaluationConfig: PropTypes.object,
  tenantTerms: PropTypes.shape({
    term_ticket: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  userList: PropTypes.array,
  projectList: PropTypes.array,
  sentimentalFields: PropTypes.array,
};

export default withErrorHandler(UserResponseShowPage);

import React from 'react';
import PropTypes from 'prop-types';

const FolderHeader = ({ title }) => (
  <div className="kt-subheader ">
    <div className="kt-subheader__main">
      <h3 className="kt-subheader__title">{title}</h3>
    </div>
  </div>
);

FolderHeader.propTypes = {
  title: PropTypes.string,
};

FolderHeader.defaultProps = {
  title: 'Folder',
};

export default FolderHeader;

import React from 'react';
import PropTypes from 'prop-types';

import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import get from 'lodash/get';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import { SENTIMENT_COLOR_MAP } from '../common/constants';
import SimpleUserResponsesTable from '../forms/SimpleUserResponsesTable';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

const CustomerShowPage = props => {
  const [loading, setLoading] = React.useState(false);
  const {
    formResponseCustomer: {
      responsesCount,
      responses,
      links: { delete: deleteCustomerLink },
    },
  } = props;

  const customerAttributes = React.useMemo(
    () =>
      pick(props.formResponseCustomer, [
        'name',
        'email',
        'phoneNumber',
        'latestImpression',
        'latestResponseDate',
      ]),
    [props.formResponseCustomer]
  );

  const renderCustomerAttribute = (attributeValue, attributeName) => {
    const isLatestImpression = isEqual('latestImpression', attributeName);
    const isLatestResponseDate = isEqual('latestResponseDate', attributeName);

    return (
      <div key={`customer_${attributeName}`} className="row font-16 my-2">
        <span className="col-md-2 col-sm-12">
          <strong>{startCase(attributeName)}</strong>
        </span>
        {!isNull(attributeValue) ? (
          isLatestImpression ? (
            <span
              className={`col-md-10 col-sm-12 font-weight-bolder kt-font-${
                SENTIMENT_COLOR_MAP[attributeValue.toLowerCase()]
              }`}
            >
              {attributeValue}
            </span>
          ) : (
            <span className="col-md-10 col-sm-12">
              {isLatestResponseDate
                ? formatDateTime({ date: attributeValue })
                : attributeValue}
            </span>
          )
        ) : (
          <span className="col-md-10 col-sm-12">-</span>
        )}
      </div>
    );
  };

  const handleDeleteCustomerRequest = url => () => {
    const proceedDialogConfirmed = confirm(
      'Deleting the customer will also remove their responses and corresponding tickets too. Are you sure you want to proceed?'
    );

    const { csrfToken } = props;

    if (proceedDialogConfirmed) {
      new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .setSuccessMessage('Customer Deleted Successfully')
        .doesRedirect(true)
        .delete(url)
        .useAlerts()
        .onSuccess(response => {
          setLoading(false);
          window.location = get(
            response,
            'data.meta.redirection_url',
            window.location.href
          );
        })
        .onError(error => {
          setLoading(false);
          console.log(error);
        })
        .exec();
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}

      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Form Response Customer</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button
              className="btn-outline-darkred btn-sm"
              onClick={handleDeleteCustomerRequest(deleteCustomerLink)}
            >
              Delete Customer
            </button>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-section">
            {map(customerAttributes, renderCustomerAttribute)}
          </div>
        </div>
      </div>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Responses ({responsesCount})
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body p-0">
          <SimpleUserResponsesTable userResponses={responses} />
        </div>
      </div>
    </React.Fragment>
  );
};

CustomerShowPage.propTypes = {
  formResponseCustomer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    responsesCount: PropTypes.number,
    latestResponseDate: PropTypes.string,
    latestImpression: PropTypes.string,
    links: PropTypes.shape({
      delete: PropTypes.string,
    }).isRequired,
    responses: PropTypes.array.isRequired,
  }),
  csrfToken: PropTypes.string,
};

export default withErrorHandler(CustomerShowPage);

import React from 'react';
import PropTypes from 'prop-types';
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';

export const AssetListItem = props => {
  let { asset, handleAssetDelete } = props;
  let { id, handle, filename, size, deletable, url, mimetype } = asset;

  return (
    <div className="kt-widget4__item" key={handle}>
      <div className="pr-2" style={{ maxWidth: '30px' }}>
        {mimetype.includes('image') ? (
          <RenderSmoothImage src={url} alt={filename} />
        ) : (
          <video src={url} className="w-100" />
        )}
      </div>
      <span className="kt-widget4__title text--truncate">{filename}</span>
      <div className="kt-widget4__info">
        <span className="kt-widget4__text">
          {parseFloat(size / (1024 * 1024)).toFixed(3)} MB
        </span>
      </div>
      <div className="kt-widget4__tools">
        {(!id || (id && deletable)) && (
          <a
            className="app-btn-secondary btn-icon"
            onClick={() => handleAssetDelete(id, handle)}
          >
            <i className="la la-trash" />
          </a>
        )}
      </div>
    </div>
  );
};

AssetListItem.propTypes = {
  asset: PropTypes.object,
  handleAssetDelete: PropTypes.func,
};

import React from 'react';
import PropTypes from 'prop-types';

const AssetsContainer = ({ children }) => (
  <div className="kt-section">
    <h3 className="kt-section__heading" />
    <div className="kt-section__content">
      <div className="row mt-30 mb-30 ml-15 mr-15" id="asset-list">
        {children}
      </div>
    </div>
  </div>
);

AssetsContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

AssetsContainer.defaultProps = {
  children: null,
};

export default AssetsContainer;

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import ImpressionEmoji from '../common/ImpressionEmoji';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

const SimpleUserResponsesTable = ({ userResponses }) => (
  <div className="table-responsive">
    <table className="table table-striped kt-table__row-equal-width">
      <thead>
        <tr style={{ height: '55px' }}>
          <th className="td-30">&nbsp;</th>
          <th>Form Name</th>
          <th className="td-110 text-center">Submitted At</th>
          <th className="td-30">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {map(userResponses, response => (
          <tr key={`response_${response.id}`}>
            <td className="td-30 text-center">
              {!isEmpty(response.impression) ? (
                <ImpressionEmoji impression={response.impression} />
              ) : null}
            </td>
            <td>
              <a href={response.links.show}>{response.formName}</a>
            </td>
            <td className="td-110 text-center">
              {formatDateTime({
                date: response.submittedAt,
                formatTime: false,
              })}
            </td>
            <td className="td-30">
              <a
                className="btn btn-sm btn-label-info btn-icon"
                href={response.links.show}
              >
                <i className="fa fa-eye" />
              </a>
            </td>
          </tr>
        ))}
        {isEmpty(userResponses) && (
          <tr>
            <td colSpan={4} className="text-center">
              <h3>No Responses</h3>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

SimpleUserResponsesTable.propTypes = {
  userResponses: PropTypes.array.isRequired,
};

export default withErrorHandler(SimpleUserResponsesTable);

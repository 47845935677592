import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactFlowPlayerWrapper from '../../filestack/ReactFlowPlayerWrapper';
import Asset from '../../filestack/Asset';
import Modal from '../../filestack/modal';
import { formatDateTime, humanFileSize } from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class QuizGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      fileSrc: '',
      fileType: '',
      filePoster: '',
    };
  }

  openModal = (src, type, id = null, poster = '') => {
    this.setState({
      isModelOpen: true,
      fileSrc: src,
      fileType: type,
      filePoster: poster,
    });
  };

  closeModal = () => {
    this.setState({
      isModelOpen: false,
      fileSrc: '',
      fileType: '',
      filePoster: '',
    });
  };

  renderModalContent = () => {
    const { fileType, fileSrc, filePoster } = this.state;

    switch (fileType) {
      case 'image':
        return (
          <img
            src={fileSrc}
            style={{
              width: 'auto',
              maxHeight: '400px',
              maxWidth: '100%',
            }}
          />
        );
      case 'video':
        return (
          <ReactFlowPlayerWrapper
            poster={filePoster}
            playerId="reactFlowPlayer"
            sources={[
              {
                type: 'video/mp4',
                src: fileSrc,
              },
            ]}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const { assets, hideFileInfo } = this.props;

    const { isModelOpen } = this.state;

    return (
      <div className="row">
        {_.map(
          assets,
          (
            { id, filename, mimetype, handle, size, url, poster, createdAt, deletable },
            index
          ) => (
            <div className="col-lg-4 col-md-4 col-sm-12 mt-10 mb-10 mlr-0" key={index}>
              <div className="file-card">
                <div className="kt-file">
                  <a>
                    <Asset
                      id={id}
                      mimetype={mimetype}
                      url={url}
                      poster={poster}
                      openModal={this.openModal}
                    />

                    {!hideFileInfo && (
                      <div className="file-name">
                        <p className="m-b-5 text-muted f-10 break-word filename-truncate">
                          {filename}
                        </p>
                        <small>
                          Size: {humanFileSize(size)}
                          <span className="kt-date text-muted">
                            {formatDateTime({ date: createdAt, formatTime: false })}
                          </span>
                        </small>
                      </div>
                    )}
                  </a>
                </div>
              </div>
            </div>
          )
        )}
        {isModelOpen ? (
          <Modal
            large
            title={'Asset Preview'}
            onClose={this.closeModal}
            renderContent={this.renderModalContent}
          />
        ) : null}
      </div>
    );
  }
}

QuizGallery.propTypes = {
  assets: PropTypes.array.isRequired,
  hideFileInfo: PropTypes.bool,
};

QuizGallery.defaultProps = {
  hideFileInfo: false,
  showAssetShareOption: false,
};

export default withErrorHandler(QuizGallery);

import React from 'react';
import PropTypes from 'prop-types';

const FormElementHelperText = ({ text, type }) => (
  <p className={`form-text text-muted kt-font-${type}`}>{text}</p>
);

FormElementHelperText.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'danger', 'success', 'warning']),
};

FormElementHelperText.defaultProps = {
  type: 'info',
};

export default FormElementHelperText;

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import GridItem from './GridItem';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { Transition, TransitionContainer } from '../../common/animations';
import RadioButtonGroup from '../../common/inputs/RadioButtonGroup';
import './styles.scss';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

const PUBLISHING_FILTER_BUTTON = [
  { id: 'all', label: 'All', color: 'primary' },
  { id: 'published', label: 'Published', color: 'success' },
  { id: 'unPublished', label: 'Unpublished', color: 'warning' },
];

const CourseView = ({
  courses: allCourseList,
  tenantTerms: { termCourse },
  showHeader,
  manageMode,
  links,
}) => {
  const [courseFilterText, setCourseFilterText] = useState(null);
  const [coursePublishingStatus, setCoursePublishingStatus] = useState('all');

  const onInputChange = event => {
    const { target } = event;
    if (!isNil(target.name)) {
      setCourseFilterText(target.value);
    }
  };

  const setPublishingFilter = ({ target: { value } }) =>
    setCoursePublishingStatus(value);

  const renderEmptyView = term => (
    <Transition classNames="slide-up">
      <div className="col-md-12">
        <div className="kt-portlet kt-portlet--fit">
          <div className="kt-portlet__body" style={{ padding: '15px' }}>
            <div className="text-center">
              <i
                className="flaticon-presentation mr-2"
                style={{ fontSize: '50px' }}
              />
              <h4>No {term.plural} Available</h4>
              {manageMode && (
                <h6>
                  Assign <a href={links?.newCourse}>New {term.plural}</a>
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );

  let courseList = allCourseList;
  if (!isNil(courseFilterText) && !isEmpty(courseFilterText)) {
    courseList = filter(allCourseList, course =>
      course.name.toLowerCase().includes(courseFilterText.toLowerCase())
    );
  }
  if (coursePublishingStatus === 'all') {
    courseList = [...courseList];
  } else if (coursePublishingStatus === 'published') {
    courseList = filter(courseList, course => course.published);
  } else if (coursePublishingStatus === 'unPublished') {
    courseList = filter(courseList, course => !course.published);
  }

  return (
    <Fragment>
      <div className="kt-subheader px-0 course-dashboard">
        <div className="course-header--responsive align-items-baseline">
          <div className="m-2 d-flex align-items-center text-truncate">
            {showHeader && (
              <h4 className="kt-subheader__title mb-0 text-truncate">
                {termCourse.plural}
              </h4>
            )}
            {manageMode && links?.newCourse && (
              <a
                href={links.newCourse}
                className="app-btn-primary m-0 ml-2 rounded-button-link"
                title={`Add New ${termCourse.singular}`}
              >
                <span className="fas fa-plus"></span>
              </a>
            )}
          </div>
          <div
            style={{ minWidth: 300 }}
            className="m-2 d-flex justify-content-center align-items-center flex-md-row flex-column"
          >
            <div className="form-group kt-form__group d-flex align-items-center mb-2">
              <span className="font-weight-bold pr-1">Filter: </span>

              <input
                style={{
                  height: 40,
                }}
                name="courseFilterText"
                onChange={onInputChange}
                type="text"
                title="Filter by Name"
                className="form-control kt-input"
                placeholder="Filter by Name"
              />
            </div>
            {/* Show Published/UnPublished filter only for Admin */}
            {manageMode && (
              <RadioButtonGroup
                className="ml-2 mb-2"
                checkedId={coursePublishingStatus}
                onChange={setPublishingFilter}
                buttonList={PUBLISHING_FILTER_BUTTON}
              />
            )}
          </div>
        </div>
      </div>
      <div className={`pb-5 pt-${showHeader && !manageMode ? '5' : '0'}`}>
        <div className="kt-content">
          <TransitionContainer className="d-flex justify-content-center justify-content-md-start flex-wrap">
            {courseList.map(course => (
              <Transition
                key={`${courseFilterText}-${coursePublishingStatus}-${course.id}`}
              >
                <GridItem
                  item={course}
                  descriptionKey="objective"
                  showPublishStatus={manageMode}
                />
              </Transition>
            ))}

            {isEmpty(courseList) && renderEmptyView(termCourse)}
          </TransitionContainer>
        </div>
      </div>
    </Fragment>
  );
};

CourseView.propTypes = {
  showHeader: PropTypes.bool,
  courses: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  manageMode: PropTypes.bool,
  links: PropTypes.shape({
    newCourse: PropTypes.string,
  }),
};

CourseView.defaultProps = {
  showHeader: true,
  manageMode: false,
};

export default withErrorHandler(CourseView);

import React, { useCallback, useEffect, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';

import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import SelectInput from "../../common/inputs/select";
import { RECORD_GROUPABLE_ELEMENTS } from '../../surveys/constants';
import FeedbackSummaryItem from '../../surveys/feedback_summary/FeedbackSummaryItem';
import StoreFilter from './storeFilter';
import DateRangeFilter from './dateRangeFilter';

const FeedbackFormProgress = ({ course, csrfToken }) => {
  const [feedbackForms, setFeedbackForms] = useState([]);
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState(null);
  const [feedbackFormSummary, setFeedbackFormSummary] = useState({});
  const [selectedStores, setSelectedStores] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isResultsLoading, setResultsLoading] = useState(false);

  const fetchFeedbackFormProgress = useCallback((feedbackForm, filters) => {
    if (feedbackForm?.id) {
      const queryString = new URLSearchParams(filters).toString();

      new Http()
        .onBegin(() => setResultsLoading(true))
        .setToken(csrfToken)
        .get(`/c/${course.slug}/feedback_forms/${feedbackForm.id}/summary?${queryString}`)
        .onSuccess(response => {
          const { data } = response;
          let groupedResponseElements = [];
          let uniqueResponseElements = [];

          map(data, item => {
            if (includes(RECORD_GROUPABLE_ELEMENTS, item.element)) {
              groupedResponseElements = [...groupedResponseElements, item];
            } else {
              uniqueResponseElements = [...uniqueResponseElements, item];
            }
          });

          setFeedbackFormSummary({
            groupedResponseElements: uniqBy(groupedResponseElements, 'element'),
            uniqueResponseElements,
          });
          setResultsLoading(false);
        })
        .onError(err => {
          console.log(err);
          setResultsLoading(false);
        })
        .exec();
    }
  }, [course]);

  const applyFilters = useCallback(() => {
    fetchFeedbackFormProgress(selectedFeedbackForm, omitBy({
      store_ids: map(selectedStores, 'id'),
      submission_start_date: document.querySelector("[name=start]").value,
      submission_end_date: document.querySelector("[name=end]").value,
    }, isEmpty));
  }, [selectedFeedbackForm, selectedStores]);

  useEffect(() => {
    if (course) {
      new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .get(`/c/${course.slug}/course_feedback_forms`)
        .onSuccess(response => {
          const { data } = response;
          setFeedbackForms(data);
          setSelectedFeedbackForm(null);
          setLoading(false);
        })
        .onError(err => {
          console.log(err);
          setLoading(false);
        })
        .exec();
    }
  }, [course]);

  useEffect(() => {
    fetchFeedbackFormProgress(selectedFeedbackForm, {});
  }, [selectedFeedbackForm]);

  if (isLoading) {
    return <Spinner />;
  }

  if (feedbackForms.length < 1) {
    return (
      <div className='p-5' style={{ margin: '80px 0' }}>
        <h5 className='text-center'>
          No feedback forms available
        </h5>
      </div>
    )
  }

  return (
    <>
      <div style={{ width: '50%', margin: '20px auto' }}>
        <SelectInput
          isMulti={false}
          name="feedback-form-selector"
          getOptionLabel={option => option['name']}
          getOptionValue={option => option['id']}
          options={feedbackForms}
          value={selectedFeedbackForm?.id}
          onChange={setSelectedFeedbackForm}
          optionIdentifier='id'
          placeholder="Select a Form Response"
        />
      </div>

      {
        isEmpty(feedbackFormSummary.uniqueResponseElements)
          ? null
          :
            (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '15px 0' }}>
                <div style={{ width: '45%', paddingRight: '2%' }}>
                  <StoreFilter onChange={setSelectedStores} />
                </div>
                <div style={{ width: '45%', paddingRight: '2%' }}>
                  <DateRangeFilter />
                </div>
                <div style={{ width: '6%' }}>
                  <button className='app-btn-primary w-full' onClick={applyFilters}>
                    Filter
                  </button>
                </div>
              </div>
            )
      }

      {
        isResultsLoading
          ? <Spinner />
          :
            (
              <div className='mt-4'>
                {map(Object.values(feedbackFormSummary.uniqueResponseElements || []), (item, index) => (
                  <div key={`${item.element}-${index}`} className="kt-portlet">
                    <FeedbackSummaryItem
                      summaryItem={item}
                      summaryMetaData={{ sentimental_fields: [] }}
                      index={index}
                    />
                  </div>
                ))}
              </div>
            )
      }
    </>
  );
};

export default FeedbackFormProgress;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../common/presentational/spinner';
import QuizQuestionForm from './QuizQuestionForm';
import './QuizPage.scss';
import QuizQuestionItem from './QuizQuestionItem';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import {
  alertErrorNotifications,
  alertSuccessNotifications,
  clearAlertNotifications,
} from '../../folders/utils';
import { reorder } from '../../common/utils';
import map from 'lodash/map';
import Http from '../../common/Http';

class QuizShowPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showQuizQuestionForm: false,
      selectedQuestionToEdit: null,
      quizQuestions: props.courseQuiz.quizQuestions || [],
      forceCollapse: false,
    };
  }

  handleQuestionDelete = questionId => {
    return ev => {
      this.setState(prevState => ({
        quizQuestions: _.filter(
          prevState.quizQuestions,
          q => q.id !== questionId
        ),
      }));
    };
  };

  resetQuizForm = () => {
    this.setState({
      showQuizQuestionForm: false,
      selectedQuestionToEdit: null,
    });
  };

  selectQuestionToEdit = question => {
    return ev =>
      this.setState({
        selectedQuestionToEdit: question,
        showQuizQuestionForm: true,
      });
  };

  setIsLoading = isLoading => {
    this.setState({ isLoading });
  };

  onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { updateQuestionPositions } = this.props.links;

    const sortedQuizQuestions = reorder(
      [...this.state.quizQuestions],
      result.source.index,
      result.destination.index
    );

    clearAlertNotifications();

    const order = map(sortedQuizQuestions, ({ id }, index) => ({
      id,
      position: index + 1,
    }));

    await new Http()
      .onBegin(() => this.setIsLoading(true))
      .setToken(this.props.csrfToken)
      .post(updateQuestionPositions)
      .setPostData({ order })
      .onSuccess(() => {
        this.setIsLoading(false);
        this.setState({
          quizQuestions: sortedQuizQuestions,
        });
        alertSuccessNotifications('Order Updated Successfully!');
      })
      .onError(() => {
        this.setIsLoading(false);
        alertErrorNotifications('Unable to reorder at this moment!');
      })
      .exec();

    this.setState(state => ({
      ...state,
      forceCollapse: false,
    }));
  };

  handleOnDuplicate = (duplicatedQuizQuestion) => {
    console.log(duplicatedQuizQuestion);
    this.setState((prevState) => ({
      ...prevState,
      quizQuestions: [...prevState.quizQuestions, duplicatedQuizQuestion]
    }));
  }

  renderQuestionsTable = () => {
    const {
      courseQuiz,
      quizQuestionConfig,
      answerOptionConfig,
      csrfToken,
      tenantTerms,
    } = this.props;

    const { quizQuestions } = this.state;

    const getItemStyle = (_, draggableStyle) => ({
      marginBottom: '1rem',
      // styles we need to apply on draggables
      ...draggableStyle,
    });

    const getListStyle = () => ({});

    const onBeforeCapture = () => {
      this.setState(prevState => ({
        ...prevState,
        forceCollapse: true,
      }));
    };

    return (
      <DragDropContext
        onBeforeCapture={onBeforeCapture}
        onDragEnd={this.onDragEnd}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <div
                className="accordion accordion-solid accordion-toggle-arrow"
                id="accordion_quiz"
              >
                {map(quizQuestions, (question, idx) => {
                  return (
                    <Draggable
                      key={question.id}
                      draggableId={question.id.toString()}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <QuizQuestionItem
                            isDragging={snapshot.isDragging}
                            forceCollapse={this.state.forceCollapse}
                            tenantTerms={tenantTerms}
                            onEdit={this.selectQuestionToEdit(question)}
                            onDelete={this.handleQuestionDelete(question.id)}
                            courseQuizId={courseQuiz.id}
                            idx={idx}
                            answerOptionConfig={answerOptionConfig}
                            csrfToken={csrfToken}
                            question={question}
                            quizQuestionConfig={quizQuestionConfig}
                            onDuplicate={this.handleOnDuplicate}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  render() {
    const {
      isLoading,
      showQuizQuestionForm,
      selectedQuestionToEdit,
      quizQuestions,
    } = this.state;

    const {
      courseId,
      csrfToken,
      courseQuiz,
      answerOptionConfig,
      quizQuestionConfig,
      tenantTerms,
      links,
    } = this.props;

    const { termCourse } = tenantTerms;

    const courseQuizId = courseQuiz.id;

    return (
      <>
        {isLoading && <Spinner />}
        <div className="kt-portlet kt-portlet--mobile">
          <div className="kt-portlet__head">
            <div className="w-100 d-flex flex-column flex-sm-row align-items-sm-center justify-content-center justify-content-sm-between flex-wrap">
              <div className="kt-portlet__head-label my-3 my-sm-0">
                <h3 className="kt-portlet__head-title">{courseQuiz.name}</h3>
              </div>
              {!showQuizQuestionForm && (
                <div className="kt-portlet__head-toolbar mb-3 mb-sm-0">
                  <a className="app-btn-outline-secondary d-flex mr-3" href={links.courseSettingsPath}>
                    <span>
                      <i className="la la-arrow-left"></i>
                    </span>
                    Back to {termCourse.singular}
                  </a>
                  <button
                    type="button"
                    className="app-btn-primary d-flex"
                    onClick={() => {
                      this.setState({
                        showQuizQuestionForm: true,
                        selectedQuestionToEdit: null,
                      });
                    }}
                  >
                    <span>
                      <i className="flaticon2-plus"></i>
                    </span>
                    Add New Question
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="kt-section">
              <div className="kt-section__content">
                {showQuizQuestionForm && (
                  <QuizQuestionForm
                    links={links}
                    tenantTerms={tenantTerms}
                    courseId={courseId}
                    attributes={selectedQuestionToEdit || {}}
                    quizQuestionConfig={quizQuestionConfig}
                    answerOptionConfig={answerOptionConfig}
                    csrfToken={csrfToken}
                    courseQuizId={courseQuizId}
                    onCancel={this.resetQuizForm}
                  />
                )}
                {!showQuizQuestionForm &&
                  (_.isEmpty(quizQuestions) ? (
                    <h1 className="text-center">No question in quiz.</h1>
                  ) : (
                    <div>{this.renderQuestionsTable()}</div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

QuizShowPage.defaultProps = {};

QuizShowPage.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  courseSettingsPath: PropTypes.string.isRequired,

  courseId: PropTypes.number.isRequired,

  courseQuiz: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    quizQuestions: PropTypes.array,
  }).isRequired,

  quizQuestionConfig: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
    attachmentOptions: PropTypes.object.isRequired,
  }).isRequired,

  answerOptionConfig: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
    attachmentOptions: PropTypes.object.isRequired,
    answerOptionsLength: PropTypes.shape({
      minimum: PropTypes.number.isRequired,
      maximum: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    createQuiz: PropTypes.string.isRequired,
    listQuizzes: PropTypes.string.isRequired,
    updateQuiz: PropTypes.string.isRequired,
    showQuiz: PropTypes.string.isRequired,
    courseSettingsPath: PropTypes.string.isRequired,
    createQuizQuestion: PropTypes.string.isRequired,
    updateQuestionPositions: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(QuizShowPage);

import React from 'react';
import PropTypes from 'prop-types';

const RadioInput = ({
  className,
  name,
  id,
  value,
  checked,
  onChange,
  isDisabled,
}) => {
  const customClassName = ['mx-2', className].join(' ');
  return (
    <input
      className={customClassName}
      name={name}
      id={id}
      type="radio"
      value={value}
      checked={checked}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
};

RadioInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default RadioInput;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactChartkick, {
  BarChart,
  PieChart,
  ColumnChart,
} from 'react-chartkick';
import Chart from 'chart.js';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';

import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import SelectInput from "../../common/inputs/select";
import StoreFilter from './storeFilter';
import DateRangeFilter from './dateRangeFilter';

ReactChartkick.addAdapter(Chart);

const QuizSummaryCard = ({ question, index }) => {
  const { answers_count, correct_answers_count, incorrect_answers_count } = question;
  const [chartType, setChartType] = useState('pie');
  const hasAtleastOneAnswer = useMemo(() => answers_count > 0, [answers_count]);
  const chartData = useMemo(() => (
    [['Correct', correct_answers_count], ['Incorrect', incorrect_answers_count]]
  ), [correct_answers_count, incorrect_answers_count]);

  const getLibraryOptions = type => {
    const bar = type === 'bar' ? 'xAxes' : 'yAxes';
    const column = type === 'column' ? 'xAxes' : 'yAxes';
    return {
      scales: {
        [bar]: [
          {
            ticks: {
              stepSize: answers_count > 10 ? 1 : null,
			  beginAtZero: true,
            },
          },
        ],
        [column]: [
          {
            ticks: {
			  beginAtZero: true,
            },
          },
        ],
      },
    };
  };


  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart
            data={chartData}
            library={getLibraryOptions('bar')}
          />
        );
      case 'column':
        return (
          <ColumnChart
            data={chartData}
            library={getLibraryOptions('column')}
          />
        );
      case 'pie':
      default:
        return (
          <PieChart
            data={chartData}
            donut
          />
        );
    }
  };

  return (
    <div className="kt-portlet__body feedback-survey-summary">
      <div className="kt-section mb-20">
        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <div style={{ flex: 3 }}>
            <h3 className="mb-20">
              {`Q${index + 1}`}&#58;&nbsp;&nbsp;
              <span
                className="d-inline-block"
                dangerouslySetInnerHTML={{ __html: `${question.title}` }}
              />
            </h3>
            <div className="mb-20">
              <h5>{question.type}</h5>
              <span>
                Answered: <strong>{`${question.answers_count}`}</strong>
              </span>
            </div>
          </div>
          <div
            className="btn-group ml-15 plr-10 mt-10 mb-20"
            role="group"
            style={{ flex: 1 }}
          >
            <button
              type="button"
              title="Pie"
              className={`btn btn-sm ${
                chartType === 'pie'
                  ? 'app-btn-primary'
                  : 'btn-outline-brand'
              }`}
              onClick={() => setChartType('pie')}
              disabled={!hasAtleastOneAnswer}
            >
              <i className="la la-pie-chart" />
            </button>
            <button
              type="button"
              title="Bar"
              className={`btn btn-sm ${
                chartType === 'bar'
                  ? 'app-btn-primary'
                  : 'btn-outline-brand'
              }`}
              onClick={() => setChartType('bar')}
              disabled={!hasAtleastOneAnswer}
            >
              <i className="fa fa-align-left" />
            </button>
            <button
              type="button"
              title="Column"
              className={`btn btn-sm ${
                chartType === 'column'
                  ? 'app-btn-primary'
                  : 'btn-outline-brand'
              }`}
              onClick={() => setChartType('column')}
              disabled={!hasAtleastOneAnswer}
            >
              <i className="la la-bar-chart" />
            </button>
          </div>
        </div>

        {
          hasAtleastOneAnswer
            ? renderChart()
            : <h4>No Answers yet.</h4>
        }
      </div>
    </div>
  );
}

const QuizProgress = ({ course, csrfToken }) => {
  const [quizs, setQuizs] = useState([]);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [quizSummary, setQuizSummary] = useState([]);
  const [selectedStores, setSelectedStores] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [isResultsLoading, setResultsLoading] = useState(false);

  const fetchQuizProgress = useCallback((quiz, filters) => {
    if (quiz?.id) {
      const queryString = new URLSearchParams(filters).toString();

      new Http()
        .onBegin(() => setResultsLoading(true))
        .setToken(csrfToken)
        .get(`/c/${course.slug}/quizzes/${quiz.id}/summary?${queryString}`)
        .onSuccess(response => {
          const { data } = response;
          setQuizSummary(data);
          setResultsLoading(false);
        })
        .onError(err => {
          console.log(err);
          setResultsLoading(false);
        })
        .exec();
    }
  }, [course]);

  const applyFilters = useCallback(() => {
    fetchQuizProgress(selectedQuiz, omitBy({
      store_ids: map(selectedStores, 'id'),
      submission_start_date: document.querySelector("[name=start]").value,
      submission_end_date: document.querySelector("[name=end]").value,
    }, isEmpty));
  }, [selectedQuiz, selectedStores]);

  useEffect(() => {
    if (course) {
      new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .get(`/c/${course.slug}/course_quizzes`)
        .onSuccess(response => {
          const { data } = response;
          setQuizs(data);
          setSelectedQuiz(null);
          setLoading(false);
        })
        .onError(err => {
          console.log(err);
          setLoading(false);
        })
        .exec();
    }
  }, [course]);

  useEffect(() => {
    fetchQuizProgress(selectedQuiz, {});
  }, [selectedQuiz]);

  if (isLoading) {
    return <Spinner />;
  }

  if (quizs.length < 1) {
    return (
      <div className='p-5' style={{ margin: '80px 0' }}>
        <h5 className='text-center'>
          No Quizzes available
        </h5>
      </div>
    )
  }

  return (
    <>
      <div style={{ width: '50%', margin: '20px auto' }}>
        <SelectInput
          isMulti={false}
          name="quizzes-selector"
          getOptionLabel={option => option['name']}
          getOptionValue={option => option['id']}
          options={quizs}
          value={selectedQuiz?.id}
          onChange={setSelectedQuiz}
          optionIdentifier='id'
          placeholder="Select a Quiz"
        />
      </div>

      {
        isEmpty(quizSummary)
          ? null
          :
            (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '15px 0' }}>
                <div style={{ width: '45%', paddingRight: '2%' }}>
                  <StoreFilter onChange={setSelectedStores} />
                </div>
                <div style={{ width: '45%', paddingRight: '2%' }}>
                  <DateRangeFilter />
                </div>
                <div style={{ width: '6%' }}>
                  <button className='app-btn-primary w-full' onClick={applyFilters}>
                    Filter
                  </button>
                </div>
              </div>
            )
      }

      {
        isResultsLoading
          ? <Spinner />
          :
            (
              <div className='mt-4'>
                {
                  quizSummary.map((summary, index) => (
                    <QuizSummaryCard
                      index={index}
                      question={summary}
                      key={summary.id}
                    />
                  ))
                }
              </div>
            )
      }
    </>
  );
};

export default QuizProgress;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Http from '../../common/Http';
import TextInput from '../../common/inputs/text';
import FormRow from '../../common/presentational/formRow';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ReactDraftWysiwygEditor from '../../wysiwyg_editor/index';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { alertSuccessNotifications } from '../../folders/utils';
import AssetManager from '../../filestack/AssetManager';
import { SIMPLE_WYSIWYG_EDITOR_OPTIONS } from '../../common/constants';

const INITIAL_DATA = {
  question: '',
  answer: EditorState.createEmpty(),
  attachments: [],
  existingAttachments: [],
};

const TopicForm = ({
  onSuccess,
  data,
  link,
  closeModal,
  csrfToken,
  topicAttachmentsConfig,
}) => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      let wysiwygAnswer = data.answer;

      try {
        if (wysiwygAnswer) {
          const contentBlock = htmlToDraft(wysiwygAnswer);
          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            wysiwygAnswer = EditorState.createWithContent(contentState);
          }
        }
      } catch (e) {
        // Do Nothing
      }

      setFormData({
        question: data.question,
        answer: wysiwygAnswer,
        attachments: [],
        existingAttachments: data.attachments,
      });
    }
  }, [data]);

  const onSubmitHandle = e => {
    e.preventDefault();

    const draftToHtmlAnswer = draftToHtml(
      convertToRaw(formData.answer.getCurrentContent())
    );
    const updatedAnswer = draftToHtmlAnswer.replace('<p></p>\n', '');

    const Requestor = new Http()
      .setToken(csrfToken)
      .useAlerts()
      .onBegin(() => setLoading(true))
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData({
        topic: {
          question: formData.question,
          answer: updatedAnswer,
          attachments: formData.attachments,
        },
      })
      .onSuccess(response => {
        onSuccess(response);
        setFormData(INITIAL_DATA);
        setLoading(false);
        alertSuccessNotifications('Topic Successfully Saved');
        closeModal();
      })
      .onError(() => {
        setLoading(false);
      });

    if (data !== null) {
      Requestor.put(link);
    } else {
      Requestor.post(link);
    }

    Requestor.exec();
  };

  const inputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onAttachmentChange = (attachments, existingAttachments) => {
    setFormData(prev => ({
      ...prev,
      attachments,
      existingAttachments,
    }));
  };

  return (
    <form onSubmit={onSubmitHandle} className="d-flex flex-column">
      <FormRow label="Question">
        <TextInput
          name="question"
          placeholder="ex: How to ....?"
          value={formData.question}
          onInputChange={({ target: { value, name } }) =>
            inputChange(name, value)
          }
          numberAttributes={{
            required: true,
          }}
        />
      </FormRow>
      <FormRow label="Answer">
        <ReactDraftWysiwygEditor
          editorState={formData.answer}
          onEditorStateChange={editorState =>
            inputChange('answer', editorState)
          }
          toolbarConfig={{
            options: SIMPLE_WYSIWYG_EDITOR_OPTIONS,
          }}
        />
      </FormRow>
      <FormRow label="Attachments">
        <AssetManager
          showLabel={false}
          showUploader={true}
          csrfToken={csrfToken}
          filestackOptions={topicAttachmentsConfig}
          existingAssets={formData.existingAttachments}
          assets={formData.attachments}
          showAssetsAsListItems
          onFileChange={onAttachmentChange}
          renderUploader={({ onPick }) => (
            <button
              className="btn-sm app-btn-primary mb-3"
              onClick={!loading ? onPick : null}
              disabled={loading}
            >
              <i className="la la-plus"></i> Select Files
            </button>
          )}
        />
      </FormRow>

      <div className="text-right">
        <button
          className="btn-outline-darkred mr-2"
          onClick={e => {
            e.preventDefault();
            closeModal();
          }}
        >
          Cancel
        </button>
        <button className="btn app-btn-primary" disabled={loading}>
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>
    </form>
  );
};

TopicForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    attachments: PropTypes.array,
  }),
  link: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  topicAttachmentsConfig: PropTypes.object,
};

TopicForm.defaultProps = {
  data: null,
};

export default TopicForm;

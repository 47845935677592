import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../common/inputs/multiSelect';
import { withErrorHandler } from '../hoc/withErrorHandler';
import FormRow from '../common/presentational/formRow';

const CourseSelect = ({
  onChange,
  courseList,
  selectedCourses: coursesSelected,
  courseLabel,
  name,
  optionLabel,
  optionValue,
  showHeaderText,
  headerText,
  optionIdentifier,
}) => {
  const [selectedCourses, setSelectedCourses] = useState(coursesSelected);

  useEffect(() => {
    setSelectedCourses(coursesSelected);
  }, [coursesSelected]);

  const onSelectChange = participants => {
    const participantIds = participants.map(({ id }) => id);
    setSelectedCourses(participantIds);
    onChange(participantIds);
  };

  return (
    <div>
      {showHeaderText && <h2 className="mb-3">{headerText}</h2>}
      <FormRow label={courseLabel}>
        <MultiSelect
          name={name}
          getOptionLabel={option => option[optionLabel]}
          getOptionValue={option => option[optionValue]}
          options={courseList}
          value={selectedCourses}
          onChange={onSelectChange}
          optionIdentifier={optionIdentifier}
        />
      </FormRow>
    </div>
  );
};

CourseSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  courseList: PropTypes.array.isRequired,
  selectedCourses: PropTypes.array,
  courseLabel: PropTypes.string,
  courseListLabel: PropTypes.string,
  name: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  optionIdentifier: PropTypes.string,
  showHeaderText: PropTypes.bool,
  headerText: PropTypes.string,
};

CourseSelect.defaultProps = {
  name: 'Participants',
  selectedCourses: [],
  optionLabel: 'name',
  optionValue: 'id',
  optionIdentifier: 'id',
  courseLabel: 'Participants',
  headerText: 'Participants',
  courseListLabel: 'Enrolled Participants',
  showHeaderText: true,
};

export default withErrorHandler(memo(CourseSelect));

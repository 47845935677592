import React from 'react';
import PropTypes from 'prop-types';
import { getRandomColor } from './utils';
import './avatarStyles.scss';

const InitialsAvatar = ({
  name,
  initials,
  url,
  className,
  size,
  isHeaderProfile,
  ...restProps
}) => {
  let avatarSizeClass;
  const getAvatarSizeClass = () => {
    switch (size) {
      case 'small':
        avatarSizeClass = 'small_avatar';
        break;
      case 'medium':
        avatarSizeClass = 'medium_avatar';
        break;
      case 'large':
        avatarSizeClass = 'large_avatar';
        break;
      default:
        avatarSizeClass = 'medium_avatar';
        break;
    }
    return avatarSizeClass;
  };
  if (!!url) {
    return (
      <img
        src={url}
        alt={name}
        className={`${className} avatar ${getAvatarSizeClass()} ${isHeaderProfile &&
          'header-profile-avatar-image'}`}
        title={name}
        {...restProps}
      />
    );
  }

  let computedInitials = '';

  if (name) {
    const words = name.split(' ');
    computedInitials = words.reduce((acc, ix) => {
      acc += ix.charAt(0) || '';
      return acc;
    }, '');
    if (computedInitials.length > 2) {
      computedInitials = computedInitials.slice(0, 2);
    }
  }

  const firstChar = (initials || computedInitials).charAt(0) || 'A';
  const stringToGetRandomColor = name || computedInitials || firstChar;

  return (
    <div
      className={`kt-header__topbar-icon kt-header__topbar-icon--brand ${className} avatar ${getAvatarSizeClass()}`}
      style={{
        backgroundColor: getRandomColor(stringToGetRandomColor),
      }}
      title={name}
      {...restProps}
    >
      <span className={`initials ${isHeaderProfile && 'header-profile-avatar-initial'}`}>
        {_.toUpper(initials || computedInitials)}
      </span>
    </div>
  );
};

InitialsAvatar.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  initials: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  isHeaderProfile: PropTypes.bool,
};

InitialsAvatar.defaultProps = {
  isHeaderProfile: false,
};

export default InitialsAvatar;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FeedbackSummaryItem from '../../surveys/feedback_summary/FeedbackSummaryItem';
import Http from '../../common/Http';
import { alertErrorNotifications } from '../../folders/utils';
import Spinner from '../../common/presentational/spinner';
import Select from '../../common/inputs/select';
import {
  getStartAndEndDatesForAGivenPeriod,
  PERIOD_FILTER_OPTIONS,
} from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

import map from 'lodash/map';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

const FeedbackSummary = ({
  links: { fetchSummary: fetchSummaryLink },
  csrfToken,
}) => {
  const [loading, setLoading] = useState(false);
  const [summaryResponseElements, setSummaryResponseElements] = useState({});
  const [summaryMetaData, setSummaryMetaData] = useState({});
  const [dateSubmitted, setDateSubmitted] = useState(null);

  const fetchFeedbackSummary = useCallback(async () => {
    let { startDate, endDate } =
      getStartAndEndDatesForAGivenPeriod(dateSubmitted);

    const submission_start_date = !isNull(startDate)
      ? startDate.format()
      : startDate;
    const submission_end_date = !isNull(endDate) ? endDate.format() : endDate;

    const filterQueries = {
      submission_start_date,
      submission_end_date,
    };

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(fetchSummaryLink)
      .setQueryParams({
        ...filterQueries,
      })
      .onSuccess(res => {
        const { data } = res;
        const summaryData = data['survey_summary'] || {};
        let responseElements = [];
        map(summaryData, item => {
          responseElements = [...responseElements, item];
        });
        setSummaryMetaData(data['meta']);
        setSummaryResponseElements(responseElements);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error fetching Summary!');
      })
      .exec();
  }, [dateSubmitted, csrfToken, fetchSummaryLink]);

  useEffect(() => {
    fetchFeedbackSummary();
  }, [fetchFeedbackSummary]);

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <div className="row mb-30">
        <div className="col-md-4 pb-1">
          <Select
            placeholder={'Date Submitted'}
            name={'dateSubmitted'}
            isSearchable={false}
            getOptionLabel={option => option.label}
            getOptionValue={option => option.key}
            optionIdentifier="key"
            options={PERIOD_FILTER_OPTIONS}
            value={dateSubmitted || ''}
            onChange={value => setDateSubmitted(value['key'])}
          />
        </div>
      </div>
      {!isEmpty(summaryResponseElements) ? (
        <React.Fragment>
          {map(Object.values(summaryResponseElements), (item, index) => (
            <div key={`${item.element}-${index}`} className="kt-portlet">
              <FeedbackSummaryItem
                summaryItem={item}
                summaryMetaData={summaryMetaData}
                index={index}
              />
            </div>
          ))}
        </React.Fragment>
      ) : (
        <div className="kt-portlet p-4">No Summary</div>
      )}
    </React.Fragment>
  );
};

FeedbackSummary.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    fetchSummary: PropTypes.string.isRequired,
    fetchIndividualResponses: PropTypes.string,
  }).isRequired,
};

export default withErrorHandler(FeedbackSummary);

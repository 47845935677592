import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const Breadcrumbs = ({ data }) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb bg-light">
      {data.map(({ label, link }) => (
        <li
          className={`breadcrumb-item font-weight-normal ${!link ? 'active' : ''}`}
          aria-current="page"
          key={label}
        >
          {link ? (
            <a className="app-text-primary text-capitalize" href={link}>
              {label}
            </a>
          ) : (
            <span className="text-capitalize">{label}</span>
          )}
        </li>
      ))}
    </ol>
  </nav>
);

Breadcrumbs.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ).isRequired,
};

export default withErrorHandler(memo(Breadcrumbs));


export const getAssetAttributes = (assets) => (
 _.map(assets, (asset) => ({
		filename: asset['filename'],
		handle: asset['handle'],
		mimetype: asset['mimetype'],
		original_path: asset['originalPath'],
		size: asset['size'],
		source: asset['source'],
		status: asset['status'],
		upload_id: asset['uploadId'],
		url: asset['url'],
		latitude: asset['latitude'],
		longitude: asset['longitude'] 
	}))
)

import React, { useMemo, useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import TransitionModal from '../../common/Modal/Modal';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import {
  alertErrorNotifications,
  alertSuccessNotifications,
} from '../../folders/utils';
import PaginationWrapper from '../../hoc/PaginationWrapper';
import Results from './Results';
import Filters from './Filters';

const Participants = ({ csrfToken, links }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);

  const [participants, setParticipants] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [nameSearch, setNameSearch] = useState('');

  const toggleVisibility = useCallback(() => {
    setIsExpanded(v => !v);
  }, []);

  const loadAssignableUsers = useCallback(
    (inputValue, callback) => {
      fetch(`${links.assignableUsers}?name=${inputValue}`)
        .then(response => response.json())
        .then(data => {
          callback(data);
        })
        .catch(error => {
          console.error(error);
          callback([]);
        });
    },
    [links.assignableUsers]
  );

  const debouncedLoadAssignableUsers = useMemo(
    () => debounce(loadAssignableUsers, 500),
    [loadAssignableUsers]
  );

  const handleAddParticipants = async () => {
    console.log(selectedUser);
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .post(links.addParticipants, {
        user_ids: selectedUser.map(u => u.id),
      })
      .onSuccess(response => {
        if (response.data.success) {
          setIsExpanded(false);
          setLoading(false);
          setSelectedUser([]);
          alertSuccessNotifications('Participants added successfully');
        } else {
          alertErrorNotifications(
            response.data.error || 'Error occurred. Could not add participants!'
          );
        }
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(
          err || 'Error occurred. Could not add participants!'
        );
      })
      .exec();
    fetchParticipants();
  };

  const renderParticipantAdder = () => (
    <div className="m-4">
      <AsyncSelect
        name="user-selector"
        placeholder="Search Participant"
        noOptionsMessage={({ inputValue }) =>
          inputValue ? 'No users found' : 'Type something'
        }
        loadOptions={debouncedLoadAssignableUsers}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        onChange={setSelectedUser}
        value={selectedUser}
        cacheOptions
        isClearable
        isMulti
      />
      <div className="d-flex mt-4 justify-content-center">
        <button onClick={handleAddParticipants} className="btn app-btn-primary">
          Add Participants
        </button>
      </div>
      {isLoading && <Spinner />}
    </div>
  );

  const fetchParticipants = async (keyword = '', pageNumber = 1) => {
    const queryParams = `?page=${pageNumber}&name=${keyword.trim()}`;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${links.participantList}${queryParams}`)
      .onSuccess(response => {
        const {
          data: { participants, meta },
        } = response;

        setParticipants(participants);
        setPagination(meta.pagination);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting participants!');
      })
      .exec();
  };

  const handleDeleteParticipant = async participant => {
    const queryString = `?user_ids[]=${participant.id}`;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .delete(`${links.removeParticipants}${queryString}`)
      .onSuccess(() => {
        alertSuccessNotifications(
          `${participant.name} had been removed from the Curriculum successfully!`
        );
        fetchParticipants(nameSearch, pagination.currentPage);
      })
      .onError(err => {
        console.log(err);
        alertErrorNotifications(err || 'Error removing participant!');
      })
      .exec();
  };

  useEffect(() => {
    fetchParticipants();
  }, []);

  const handleNameFilterChange = event => {
    setNameSearch(event.target.value);
  };

  const handleFilterAction = () => {
    fetchParticipants(nameSearch, 1);
  };

  const clearFilter = () => {
    setNameSearch('');
    fetchParticipants();
  };

  const renderPagination = () => {
    if (!isEmpty(pagination) && pagination.totalPages > 1) {
      return (
        <PaginationWrapper
          paginationObject={pagination}
          fetchData={pageNumber => fetchParticipants(nameSearch, pageNumber)}
        />
      );
    }

    return <br />;
  };

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet kt-portlet--mobile">
        {/* Render Header */}
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Curriculum Participants</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <button
              className="pull-right btn app-btn-primary"
              onClick={toggleVisibility}
            >
              Add Participants
            </button>
          </div>
        </div>
        <TransitionModal
          header={'Add Participants'}
          content={isExpanded ? renderParticipantAdder() : null}
          modalState={isExpanded}
          onClose={toggleVisibility}
        />

        <>
          <Filters
            nameFilter={nameSearch}
            onNameFilterChange={handleNameFilterChange}
            onFilter={handleFilterAction}
            onClear={clearFilter}
          />
          <Results
            participants={participants}
            onDeleteParticipant={handleDeleteParticipant}
          />
          {renderPagination()}
          {isLoading ? <Spinner /> : null}
        </>
      </div>
    </div>
  );
};

export default Participants;

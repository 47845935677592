import React from 'react';
import PropTypes from 'prop-types';

import useLearnerTabContext from '../hooks/useLearnerTabContext';
import { isValidChildren } from '../../../common/utils';

const TabContent = ({ children }) => {
  const { activeTab } = useLearnerTabContext();

  const renderChildren = activeTab
    ? children.filter(tab => {
        if (isValidChildren(tab)) {
          return tab.props.id === activeTab.key;
        }
      })
    : null;
  return <div className="tab-content">{renderChildren}</div>;
};

TabContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TabContent;

import React from "react";
import PropTypes from 'prop-types';

const Header = ({ links }) => (
  <div className="kt-portlet__head">
    <div className="kt-portlet__head-label">
      <h3 className="kt-portlet__head-title">
        Certification Status
      </h3>
    </div>
    <div className="kt-portlet__head-toolbar">
      <a
        className="pull-right btn app-btn-primary"
        href={links.reportCertification}
      >
        Report
      </a>
    </div>
  </div>
);

Header.propTypes = {
  links: PropTypes.shape({
    reportCertification: PropTypes.string.isRequired
  }).isRequired
};

export default Header;
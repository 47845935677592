import React, { Fragment, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import Modal from '../filestack/modal';
import SelectInput from '../common/inputs/select';
import Text from '../common/inputs/text';
import FormRow from '../common/presentational/formRow';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { alertSuccessNotifications } from '../folders/utils';
import useAttributesMutationObserver from '../common/hooks/useAttributesMutationObserver';

const DropdownBtn = () => {
  const dropdownRef = useRef(null);
  const onMutate = useCallback(mutation => {
    if (mutation.attributeName === 'aria-expanded') {
      mutation.target.getAttribute('aria-expanded') === 'true'
        ? window.disableSortable?.()
        : window.enableSortable?.();
    }
  }, []);

  useAttributesMutationObserver(dropdownRef, onMutate);

  return (
    <button
      type="button"
      className="btn mt0 mt-0 btn-empty dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      ref={dropdownRef}
    >
      <i className="fa fa-cog" />
      <span className="caret"></span>
    </button>
  );
};

const AdminContent = ({
  contentItems,
  assignableCourses,
  defaultTargetCourseId,
  defaultTargetCourseSlug,
  csrfToken,
  tenantTerms: { termCourse, termModule, termAssignment },
}) => {
  const INITIAL_TARGET_DATA = Object.freeze({
    name: '',
    cloneToCourseId: defaultTargetCourseId,
  });

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cloneableContent, setCloneableContent] = useState(null);
  const [targetData, setTargetData] = useState(INITIAL_TARGET_DATA);

  const getContentBadgeMeta = type => {
    let label;
    let badgeClass;

    switch (type) {
      case 'CourseModule':
        label = termModule.singular;
        badgeClass = 'kt-badge--info';
        break;
      case 'CourseAssessment':
        label = termAssignment.singular;
        badgeClass = 'kt-badge--primary';
        break;
      case 'CourseQuiz':
        label = 'Quiz';
        badgeClass = 'badge-darkred';
        break;
      case 'CourseFeedbackForm':
        label = 'Form';
        badgeClass = 'kt-badge--warning';
        break;
      case 'CourseScorm':
        label = 'SCORM';
        badgeClass = 'kt-badge--info';
        break;
    }

    return { label, badgeClass };
  };

  const handleContentDelete = async deleteLink => {
    const confirmDelete = confirm('Are you sure you want to delete?');

    if (!confirmDelete) return;

    await new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .delete(deleteLink)
      .onSuccess(_res => {
        window.location.reload();
      })
      .exec();
  };

  const canClone = () => !!targetData.name && !!targetData.cloneToCourseId;

  const submitCloneReuest = async cloneLink => {
    if (!canClone()) return;

    await new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData({ ...targetData })
      .post(cloneLink)
      .onSuccess(({ data }) => {
        setLoading(false);
        handleModalClose();
        alertSuccessNotifications(
          `Module Duplicated Successfully! Redirecting to Target ${termCourse.singular}`
        );
        setTimeout(() => {
          window.location.href = data.meta.redirectionUrl;
        }, 1000);
      })
      .onError(_err => setLoading(false))
      .exec();
  };

  const handleTargetDataChange = (attributeValue, attributeName) => {
    setTargetData(prevData => ({
      ...prevData,
      [attributeName]: attributeValue,
    }));
  };

  const handleModalClose = () => {
    setTargetData(INITIAL_TARGET_DATA);
    setCloneableContent(null);
    setIsModalOpen(false);
  };

  const renderModalBody = () => (
    <div>
      <FormRow label={'Name *'}>
        <Text
          placeholder={'Enter New Module Name...'}
          className={'form-control'}
          name={'name'}
          value={targetData.name || ''}
          onInputChange={({ target: { value } }) =>
            handleTargetDataChange(value, 'name')
          }
          numberAttributes={{
            required: true,
          }}
        />
      </FormRow>
      <FormRow label={`Target ${termCourse.singular}*`}>
        <SelectInput
          placeholder={`Select Target ${termCourse.singular}...`}
          options={assignableCourses || []}
          name="cloneToCourseId"
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          onChange={({ id }) => handleTargetDataChange(id, 'cloneToCourseId')}
          value={targetData.cloneToCourseId || ''}
          optionIdentifier="id"
          isClearable={false}
        />
      </FormRow>
    </div>
  );

  const renderModal = () => {
    const restrictSubmit = loading || !canClone();
    return (
      <Modal
        title={`Duplicate ${termCourse.singular} ${termModule.singular} - ${cloneableContent.name}`}
        onClose={handleModalClose}
        renderContent={renderModalBody}
        renderFooter={() => (
          <div className="w-100 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-secondary m-2"
              onClick={handleModalClose}
              disabled={loading}
            >
              Close
            </button>
            <button
              type="button"
              className={`btn m-2 btn-success ${
                restrictSubmit ? 'cursor-not-allowed' : ''
              }`}
              onClick={() =>
                submitCloneReuest(cloneableContent.links.cloneContent)
              }
              disabled={restrictSubmit}
            >
              {loading ? 'Duplicating...' : 'Duplicate'}
            </button>
          </div>
        )}
        closeOnBackdropClick={!loading}
        closeOnEscKey={!loading}
        hideClose={loading}
        large
      />
    );
  };

  return (
    <div className="kt-section mt-5">
      {loading && <Spinner />}
      <div className="card mb-3">
        <div className="card-header">{`${termCourse.singular} - Content Items`}</div>
        <div className="card-body flex-column">
          <p className="text-success text-right m-0">
            * Drag the items to re-arrange
          </p>
          <div id="sortable_content" data-course-slug={defaultTargetCourseSlug} className="ui-sortable">
            {_.isEmpty(contentItems) ? (
              <div>No Items Found</div>
            ) : (
              contentItems.map(({ contentType, contentId, content, order }) => {
                const badgeMeta = getContentBadgeMeta(contentType);
                const snakeCasedContentType = _.snakeCase(contentType);
                const isFeedbackFormContent =
                  contentType === 'CourseFeedbackForm';
                const isDuplicatable = isFeedbackFormContent
                  ? content.published
                  : true;
                return (
                  <div
                    className="sort-strip-container row ui-sortable-handle"
                    content-id={`${snakeCasedContentType}_${contentId}`}
                    content-name={snakeCasedContentType}
                    key={`${order}_${snakeCasedContentType}_${contentId}`}
                  >
                    <i className="fas fa-grip-vertical col-1 d-flex align-items-center text-muted" />
                    <div className="sortable-row sort-strip col-11 col-md-7">
                      <h4>
                        {content.name}
                        <span className="ml-2"></span>
                        <span
                          className={`kt-badge ${badgeMeta?.badgeClass} kt-badge--inline kt-badge--rounded `}
                        >
                          {badgeMeta?.label}
                        </span>
                        {content.badgePresent && (
                          <span className="ml-2 kt-badge kt-badge--inline kt-badge--rounded kt-badge--warning flaticon-medal">
                            Earns Badge
                          </span>
                        )}
                      </h4>
                    </div>
                    <div className="col-12 col-md-4 sort-stip-buttons d-flex justify-content-end align-items-center">
                      <div className="d-flex flex-md-column">
                        {isFeedbackFormContent &&
                          content.published &&
                          content.drafted && (
                            <span className="kt-badge kt-badge--inline kt-badge--rounded app-badge-primary kt-font-bold">
                              Published
                            </span>
                          )}
                        {isFeedbackFormContent && content.drafted && (
                          <span className="mt-md-1 ml-1 ml-md-0 kt-badge kt-badge--inline kt-badge--rounded badge-secondary kt-font-bold">
                            Draft
                          </span>
                        )}
                      </div>
                      <div className="btn-group pull-right p-2">
                        <DropdownBtn />
                        <div className="dropdown-menu dropdown-menu-right">
                          {isDuplicatable && (
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                setCloneableContent(content);
                                setIsModalOpen(true);
                              }}
                            >{`Duplicate ${termModule.singular}`}</button>
                          )}
                          {content.links.badge&& (
                            <a
                              className="dropdown-item"
                              href={content.links.badge}
                            >
                              {content.badgePresent ? 'Edit Badge' : 'Create Badge'}
                            </a>
                          )}
                          {isFeedbackFormContent && content.published && (
                            <Fragment>
                              <a
                                className="dropdown-item"
                                href={content.links.responsesOverviewSummary}
                              >
                                Responses - Summary
                              </a>
                              <a
                                className="dropdown-item"
                                href={content.links.responsesOverviewListing}
                              >
                                Responses - Listing
                              </a>
                            </Fragment>
                          )}
                          {!_.isNil(content.links.viewVersions) && (
                            <a
                              className="dropdown-item"
                              href={content.links.viewVersions}
                            >
                              View Versions
                            </a>
                          )}
                          <a
                            className="dropdown-item"
                            href={content.links.edit}
                          >
                            Edit
                          </a>
                          <button
                            className="dropdown-item text-darkred"
                            onClick={() =>
                              handleContentDelete(content.links.delete)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      {isModalOpen && cloneableContent !== null && renderModal()}
    </div>
  );
};

AdminContent.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  contentItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      contentType: PropTypes.string.isRequired,
      contentId: PropTypes.number.isRequired,
      order: PropTypes.number.isRequired,
      content: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        badgePresent: PropTypes.bool,
        links: PropTypes.shape({
          edit: PropTypes.string.isRequired,
          delete: PropTypes.string.isRequired,
          cloneContent: PropTypes.string.isRequired,
          badge: PropTypes.string,
        }).isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  assignableCourses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  defaultTargetCourseId: PropTypes.number.isRequired,
  defaultTargetCourseSlug: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(AdminContent);

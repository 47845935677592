import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../common/inputs/select';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

class ReportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCert: '',
      userList: [],
      certStatusList: {},
      loading: false,
      mappedCertifications: props.certifications.map(ce => ({
        requireExpireDate: ce.requireExpireDate,
        requireIssueDate: ce.requireIssueDate,
        label: ce.name,
        value: ce.id,
        links: ce.links,
      })),
    };
  }

  fetchCertUsers = certificate => {
    if (!_.isNil(certificate) && !_.isEmpty(certificate.links)) {
      const { csrfToken } = this.props;

      const url = `${certificate.links.detailedReport}.json`;

      const Requester = new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .useAlerts()
        .get(url)
        .onSuccess(res => {
          this.setState({
            userList: res.data.certification_members || [],
            certStatusList: res.data.certification_status || {},
          });
        })
        .exec();
    }
  };

  handleCertificateSelect = value => {
    if (!_.isEmpty(value) && value.value) {
      this.setState(
        {
          selectedCert: value,
          userList: [],
          certStatusList: [],
        },
        () => this.fetchCertUsers(value)
      );
    } else {
      this.setState({
        selectedCert: {},
        userList: [],
        certStatusList: [],
      });
    }
  };

  renderCertStatus = status => {
    if (_.isNil(status)) {
      return (
        <span className={'kt-badge kt-badge--warning kt-badge--inline'}>
          Not Certified
        </span>
      );
    }
    if (_.isEqual(status.toLowerCase(), 'expired')) {
      return (
        <span className={'kt-badge badge-darkred kt-badge--inline'}>
          Expired
        </span>
      );
    }
    if (_.isEqual(status.toLowerCase(), 'active')) {
      return (
        <span className={'kt-badge kt-badge--success kt-badge--inline'}>
          Active
        </span>
      );
    }
    return (
      <span
        className={
          'kt-badge kt-label-bg-color-3 kt-font-light kt-badge--inline'
        }
      >
        {status}
      </span>
    );
  };

  renderCertificateUserTable = () => {
    const { selectedCert, userList, certStatusList } = this.state;
    const {
      tenantTerms: { termUser },
    } = this.props;

    if (
      !_.isEmpty(selectedCert) &&
      !_.isNil(userList) &&
      !_.isEmpty(userList)
    ) {
      const showExpiry = selectedCert.requireExpireDate;
      const showIssue = selectedCert.requireIssueDate;
      return (
        <table className="table table-bordered table-hover dataTable">
          <thead>
            <tr>
              <th colSpan={5}>
                <h2>{selectedCert.label}</h2>
              </th>
            </tr>
            <tr>
              <td>&nbsp;</td>
              {showIssue && <td>Issue Date</td>}
              {showExpiry && <td>Expiry Date</td>}
              <td>Status</td>
              <td>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {_.map(userList, (user, _index) => {
              const certData = certStatusList[user.id] || {};

              if (_.isEmpty(certData)) {
                return null;
              }

              const issueDate = !_.isNil(certData.issued_date)
                ? formatDateTime({
                    date: certData.issued_date,
                    formatTime: false,
                  })
                : '';
              const expiryDate = !_.isNil(certData.expiration_date)
                ? formatDateTime({
                    date: certData.expiration_date,
                    formatTime: false,
                  })
                : '';

              const url = certData.links.show;
              const editUrl = certData.links.edit;

              return (
                <tr key={user.id}>
                  <td>{user.full_name}</td>
                  {showIssue && <td>{issueDate || '-'}</td>}
                  {showExpiry && <td>{expiryDate || '-'}</td>}
                  <td>{this.renderCertStatus(certData.status)}</td>
                  <td>
                    {!_.isNil(certData.status) && [
                      <a href={url} key="view">
                        View
                      </a>,
                      ' | ',
                      <a href={editUrl} key="edit">
                        Edit
                      </a>,
                    ]}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    } else {
      if (!_.isEmpty(selectedCert)) {
        return (
          <div className={'col-md-12 row text-center'}>
            <p
              style={{
                fontSize: 22,
                margin: '0 auto',
                padding: 10,
              }}
            >
              None of the {termUser.plural.toLowerCase()} certified with this at
              the moment.
            </p>
          </div>
        );
      } else {
        return null;
      }
    }
  };

  render() {
    const {
      tenantTerms: { termCertification },
    } = this.props;
    const { selectedCert, mappedCertifications, loading } = this.state;

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {termCertification.singular} Report
            </h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <span className="pull-right">
              <a href="javascript:history.back()">Back</a>
            </span>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-section">
            <div className="kt-section__content">
              <div className={'col-md-12 row'}>
                <div className={'col-md-6'} style={{ margin: '10px auto' }}>
                  <Select
                    name={'certificate'}
                    options={mappedCertifications}
                    value={selectedCert.value || ''}
                    optionIdentifier="value"
                    onChange={this.handleCertificateSelect}
                    placeholder={`Select a ${termCertification.singular}`}
                  />
                </div>
                <br />
                <div className="col-md-12 row table-responsive">
                  {loading && <Spinner />}
                  {!loading && this.renderCertificateUserTable()}
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ReportView.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  certifications: PropTypes.array.isRequired,

  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCertification: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(ReportView);

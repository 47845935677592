import React, { useEffect, useMemo } from 'react';

const useAttributesMutationObserver = (element, callback) => {
  useEffect(() => {
    if (element) {
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.type === 'attributes') {
            callback(mutation);
          }
        });
      });

      observer.observe(element.current, { attributes: true });
      return observer.disconnect;
    }
  }, [element, callback]);
};

export default useAttributesMutationObserver;

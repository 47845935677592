import React from 'react';
import useAdminTabContext from '../hooks/useAdminTabContext';

const AdminHeader = () => {
  const { tabs, activeTab } = useAdminTabContext();
  return (
    <h3 className="text-capitalize mx-2 mt-5 text-center">
      <strong>
        {tabs.map(tab => (tab.key === activeTab?.key ? tab.label : null))}
      </strong>
    </h3>
  );
};

export default AdminHeader;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '../../../../hoc/withErrorHandler';
import Certificate from './Certificate';

const MyCertifications = ({ certifications: { data }, term }) => {
  return (
    <div className="display-grid">
      {data.map(certificate => (
        <Certificate
          certificate={certificate}
          key={certificate.id}
          term={term}
        />
      ))}
    </div>
  );
};

MyCertifications.propTypes = {
  certifications: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.shape({}),
  }),
  term: PropTypes.shape({
    plural: PropTypes.string,
    singular: PropTypes.string,
  }),
};

export default withErrorHandler(memo(MyCertifications));

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AssetManager from '../filestack/AssetManager';
import Spinner from './presentational/spinner';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import Http from './Http';

class SubmissionCreator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      existingAssets: this.props.existingAssets,
      assets: [],
      loading: false,
      removePageListener: false,
    };
  }

  onFileChange = (assets, existingAssets) => {
    this.setState(
      {
        assets: assets,
        existingAssets: existingAssets,
      },
      () => {
        if (_.isEmpty(this.state.existingAssets) && _.isEmpty(this.state.assets)) {
          window.location = window.location.href;
        }
        if (!_.isNil(this.props.onFilePickClick)) {
          if (this.state.assets.length > 0) {
            this.props.onFilePickClick('open');
          } else {
            this.props.onFilePickClick('close');
          }
        }
      }
    );
  };

  onSubmit = async event => {
    event.preventDefault();

    const { assets } = this.state;
    const { resourceType, resourceId, links } = this.props;

    const assetAttributes = getAssetAttributes(assets);

    let Request = new Http(this)
      .setToken(this.props.csrfToken)
      .setLoading()
      .useAlerts()
      .post(links.createSubmission)
      .setPostData({
        [_.snakeCase(resourceType)]: {
          assets: assetAttributes,
        },
      })
      .onSuccess(response => {
        this.setState(
          prevState => ({ removePageListener: true, loading: true }),
          () => {
            window.location = window.location.href;
          }
        );
      });

    await Request.exec();
  };

  renderUploader = ({ onPick }) => (
    <div className="form-group kt-form__group">
      <div className="col-md-4 offset-md-4">
        <button className="app-btn-primary btn-block" onClick={onPick}>
          Select a File
        </button>
      </div>
    </div>
  );

  render() {
    const { csrfToken, showUploader, filestackConfig, allowAssetDelete } = this.props;
    const { assets, loading, existingAssets, removePageListener } = this.state;

    const renderableDOMSection = loading ? (
      <Spinner />
    ) : (
      <div>
        {!removePageListener && (
          <AssetManager
            label={''}
            inputColClassName={'col-md-12'}
            showFileDeleteOption={allowAssetDelete}
            filestackOptions={filestackConfig}
            showUploader={showUploader}
            renderUploader={this.renderUploader}
            assets={assets}
            existingAssets={existingAssets}
            csrfToken={csrfToken}
            onFileChange={this.onFileChange}
          />
        )}
        <br />
        {!_.isEmpty(assets) && (
          <div className="form-group kt-form__group row">
            <label className="col-md-2 col-form-label" />
            <div className="col-md-10">
              <div className="form-group kt-form__group">
                <div className="col-md-4 offset-md-2">
                  {!_.isNil(this.props.onFilePickClick) && (
                    <button
                      className="btn btn-darkred btn-block"
                      onClick={() => {
                        if (this.state.assets.length > 0) {
                          if (
                            window.confirm('Are you sure you want to delete this asset?')
                          ) {
                            this.setState(
                              {
                                assets: [],
                              },
                              () => {
                                this.props.onFilePickClick('close');
                              }
                            );
                          }
                        }
                      }}
                    >
                      Cancel
                    </button>
                  )}
                  <button className="btn btn-success btn-block ml-2" onClick={this.onSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );

    return renderableDOMSection;
  }
}

SubmissionCreator.propTypes = {
  onFilePickClick: PropTypes.func,
  resourceId: PropTypes.number.isRequired,
  resourceType: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  showUploader: PropTypes.bool,
  allowAssetDelete: PropTypes.bool,
  filestackConfig: PropTypes.object.isRequired,
  existingAssets: PropTypes.array.isRequired,

  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    createSubmission: PropTypes.string.isRequired,
  }),
};

SubmissionCreator.defaultProps = {
  showUploader: true,
  onFilePickClick: null,
  allowAssetDelete: true,
};

export default SubmissionCreator;

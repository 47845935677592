import React from 'react';
import PropTypes from 'prop-types';

const EvaluateForm = props => {
  const {
    media,
    statusUid,
    apiKey,
    resource,
    returnUrl,
    evaluatorId,
    apiPostBackParams,
    disabled,
  } = props;

  return (
    <div>
      <form action="https://evaluate.mentorhq.com/evaluate" method="POST">
        <input type="hidden" name="media" value={JSON.stringify(media)} />
        <input type="hidden" name="status_uid" value={statusUid} />
        <input type="hidden" name="apiKey" value={apiKey} />
        <input type="hidden" name="resource" value={resource} />
        <input type="hidden" name="return_url" value={returnUrl} />
        <input type="hidden" name="evaluator_id" value={evaluatorId} />
        <input
          type="hidden"
          name="api_post_back_params"
          value={apiPostBackParams}
        />
        <input
          type="submit"
          value="Evaluate Now"
          className="app-btn-primary"
          disabled={disabled}
        />
      </form>
    </div>
  );
};

EvaluateForm.defaultProps = {
  disabled: false,
};

EvaluateForm.propTypes = {
  disabled: PropTypes.bool,
  media: PropTypes.object.isRequired,
  statusUid: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  apiKey: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  evaluatorId: PropTypes.number.isRequired,
  apiPostBackParams: PropTypes.string.isRequired,
};

export default EvaluateForm;

import React from 'react';
import PropTypes from 'prop-types';
import { getImpressionEmojiLink, IMPRESSION_MAP } from '../forms/constants';

const ImpressionEmoji = ({ impression }) => (
  <img
    src={getImpressionEmojiLink(IMPRESSION_MAP[impression])}
    alt={IMPRESSION_MAP[impression]}
    width="30"
    height="30"
    title={`Impression - ${impression}`}
  />
);

ImpressionEmoji.propTypes = {
  impression: PropTypes.oneOf(['Positive', 'Neutral', 'Negative']).isRequired,
};

export default ImpressionEmoji;

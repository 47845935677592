import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextInput from '../../common/inputs/text';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import Http from '../../common/Http';
import Categories from './Categories';
import SearchResults from './SearchResults';
import Header from './common/Header';
import { alertErrorNotifications } from '../../folders/utils';
import './styles.scss';

const Index = ({ topicCategories, links, csrfToken }) => {
  const [topicSearch, setTopicSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [isSearchedResult, setIsSearchedResult] = useState(false);

  const handleSearch = (e) => {
    e.preventDefault();

    if (_.isEmpty(topicSearch)) {
      setIsSearchedResult(false);
      return;
    }

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .get(links.searchTopics)
      .setQueryParams({
        searchTerm: topicSearch,
      })
      .useAPIDataFormatters({
        camelizeResponseData: true,
        snakifyRequestData: true,
      })
      .onSuccess(({ data }) => {
        setSearchResult(data.topics);
        setLoading(false);
        setIsSearchedResult(true);
      })
      .onError((err) => {
        alertErrorNotifications(err);
        setLoading(false);
      })
      .exec();
  };

  const clearSearch = () => {
    setIsSearchedResult(false);
    setTopicSearch('');
  };

  return (
    <div className="text-black-50 knowledge_base">
      <Header>
        <h2>How can we help?</h2>
        <form onSubmit={handleSearch} className="search-form">
          <TextInput
            name="question"
            placeholder="Enter a Keyword, topic or question"
            value={topicSearch}
            onInputChange={({ target: { value } }) => setTopicSearch(value)}
          />
          <button className="btn btn-secondary" disabled={loading}>
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </form>
      </Header>

      {isSearchedResult ? (
        <SearchResults
          categories={topicCategories}
          topics={searchResult}
          clearSearch={clearSearch}
          searchText={topicSearch}
        />
      ) : (
        <Categories categories={topicCategories} />
      )}
    </div>
  );
};

Index.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    searchTopics: PropTypes.string.isRequired,
  }).isRequired,
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default withErrorHandler(Index);

import React from 'react';
import PropTypes from 'prop-types';

const FolderListRow = ({ folderName, folderUrl }) => (
  <li className="folder-list-li">
    <span>
      <span>
        <a
          href={folderUrl}
          style={{
            textDecoration: 'none',
          }}
        >
          <i className="fa fa-folder" />
          {folderName}
        </a>
      </span>
    </span>
  </li>
);

FolderListRow.propTypes = {
  folderName: PropTypes.string.isRequired,
  folderUrl: PropTypes.string.isRequired,
};

export default FolderListRow;

import React from 'react';
import './style.scss';

const index = ({ label, id, ...props }) => {
  return (
    <div className="quiz-options-container">
      <input name="option" type="radio" id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default index;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../../common/inputs/Toggle';
import {
  getSnakeCaseKeyedObject,
  renderOptions,
  removePageLeavePreventDialog,
  addPageLeavePreventDialog,
} from '../../common/utils';
import Spinner from '../../common/presentational/spinner';
import FormRow from '../../common/presentational/formRow';
import Text from '../../common/inputs/text';
import SubmitButton from '../../common/presentational/submitButton';
import CancelButton from '../../common/presentational/cancelButton';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import SelectInput from '../../common/inputs/select';
import FormElementHelperText from '../../common/presentational/FormElementHelperText';

class CourseQuizForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      [props.fieldsPrefix]: Object.assign({}, props.attributes),
    };
  }

  componentDidMount() {
    addPageLeavePreventDialog();
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { csrfToken, courseId, courseQuizId, links } = this.props;

    const updatedAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );

    let Requester = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedAttributes,
        },
      })
      .onSuccess(({ data: response }) => {
        removePageLeavePreventDialog();
        if (response) {
          window.location.href = response.meta.redirection_url;
        } else {
          window.location.href = links.listQuizzes;
        }
      });

    if (_.isNil(courseQuizId)) {
      Requester = Requester.post(links.createQuiz);
    } else {
      Requester = Requester.patch(links.updateQuiz);
    }

    await Requester.exec();
  };

  getModelAttributeValue = attribute =>
    this.state[this.props.fieldsPrefix][attribute] || '';

  setModelAttributeState = (fieldName, value) => {
    const updatedModelAttributes = {
      ...this.state[this.props.fieldsPrefix],
      [fieldName]: value,
    };

    this.setState((s, p) => ({
      [this.props.fieldsPrefix]: updatedModelAttributes,
    }));
  };

  handleModelAttributeChange = (attributeName = '') => {
    return event => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, option => option.value)
          : event.value;
        this.setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue =
          target.type !== 'checkbox' ? target.value : target.checked;
        this.setModelAttributeState(targetName, targetValue);
      }
    };
  };

  render() {
    const { loading } = this.state;

    const {
      courseForumEnabled,
      tenantTerms: { termCourseQuiz },
    } = this.props;
    const allowRetake = this.getModelAttributeValue('allowRetake');
    const minimumPassingPercentage = this.getModelAttributeValue(
      'minimumPassingPercentage'
    );

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {termCourseQuiz.singular}
            </h3>
          </div>
        </div>
        {loading && <Spinner />}
        <div className="kt-portlet__body">
          <form
            className="kt-form kt-form--label-right kt-form--fit"
            onSubmit={this.handleSubmit}
          >
            <FormRow label={'Name'}>
              <Text
                name={'name'}
                value={this.getModelAttributeValue('name')}
                numberAttributes={{
                  required: false,
                }}
                onInputChange={this.handleModelAttributeChange('name')}
              />
            </FormRow>
            <FormRow label={'Allow Retake?'}>
              <Toggle
                name={'allowRetake'}
                onClick={value =>
                  this.setModelAttributeState('allowRetake', value)
                }
                on={<span>Yes</span>}
                off={<span>No</span>}
                size="sm"
                width={100}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={allowRetake || false}
              />
            </FormRow>
            {allowRetake && (
              <FormRow label={'Minimum Pass Percentage'}>
                <Text
                  name={'minimumPassingPercentage'}
                  value={minimumPassingPercentage}
                  inputType={'number'}
                  numberAttributes={{
                    min: 0,
                    max: 100,
                    step: 1,
                    required: allowRetake
                  }}
                  onInputChange={this.handleModelAttributeChange(
                    'minimumPassingPercentage'
                  )}
                />
                {minimumPassingPercentage &&
                  Number(minimumPassingPercentage) === 0 && (
                    <FormElementHelperText text="Setting as zero defeats re-take purpose" />
                  )}
              </FormRow>
            )}
            {courseForumEnabled && (
              <FormRow label={'Discussion Enabled?'}>
                <Toggle
                  name={'discussionEnabled'}
                  onClick={value =>
                    this.setModelAttributeState('discussionEnabled', value)
                  }
                  on={<span>Yes</span>}
                  off={<span>No</span>}
                  size="sm"
                  width={100}
                  height={30}
                  recalculateOnResize
                  offstyle="default"
                  active={
                    this.getModelAttributeValue('discussionEnabled') || false
                  }
                />
              </FormRow>
            )}
            <br />
            <div className="row">
              <div className="col-lg-12 text-center">
                <CancelButton confirmCancel={false} />
                <SubmitButton buttonText={'Next'} />
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

CourseQuizForm.defaultProps = {
  fieldsPrefix: 'course_quiz',
  attributes: {},
};

CourseQuizForm.propTypes = {
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
  courseQuizId: PropTypes.number,
  courseId: PropTypes.number.isRequired,
  fieldsPrefix: PropTypes.string,
  attributes: PropTypes.object,
  links: PropTypes.shape({
    createQuiz: PropTypes.string.isRequired,
    listQuizzes: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseQuizForm);

import React from 'react';
import PropTypes from 'prop-types';

import FolderList from './FolderList';

import { myFolderUrlProvider } from '../../../../utils';

const MyFolderList = ({
  folders,
  folderUpdateEnablerPredicate,
  onFolderRenameClick,
  onFolderShareClick,
}) => (
  <FolderList
    folders={folders}
    folderUrlProvider={myFolderUrlProvider}
    folderUpdateEnablerPredicate={folderUpdateEnablerPredicate}
    onFolderRenameClick={onFolderRenameClick}
    onFolderShareClick={onFolderShareClick}
  />
);

MyFolderList.propTypes = {
  folders: PropTypes.array,
  onFolderRenameClick: PropTypes.func,
  onFolderShareClick: PropTypes.func,
  folderUpdateEnablerPredicate: PropTypes.func.isRequired,
};

MyFolderList.defaultProps = {
  folders: [],
  onFolderRenameClick: () => { console.log('Default Props Rename Handler') },
  onFolderShareClick: () => { console.log('Default Props Share Handler') }
};

export default MyFolderList;

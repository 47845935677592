export const getStatusBadgeClass = status => {
  let statusBadgeClass = 'kt-badge kt-badge--inline kt-badge--pill';
  if (status.toLowerCase() === 'closed') {
    statusBadgeClass += ' kt-badge--success';
  } else if (status.toLowerCase() === 'completed') {
    statusBadgeClass += ' kt-badge--warning';
  } else {
    statusBadgeClass += ' badge-darkred';
  }
  return statusBadgeClass;
};

export const getStatusIconClass = status => {
  const map = {
    incomplete: 'border-metal kt-font-metal',
    completed: 'border-warning kt-font-warning btn-label-warning',
    closed: 'border-success kt-font-success btn-label-success',
    def: '',
  };
  return map[status.toLowerCase()];
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getSnakeCaseKeyedObject, removePageLeavePreventDialog, addPageLeavePreventDialog } from '../../common/utils';
import { getAssetAttributes } from '../../filestack/utils/getAssetAttributes';
import Spinner from '../../common/presentational/spinner';
import FormRow from '../../common/presentational/formRow';
import SubmitButton from '../../common/presentational/submitButton';
import TextArea from '../../common/inputs/textArea';
import AssetManager from '../../filestack/AssetManager';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import AnswerInputWithCheckbox from './QuizQuestionForm/AnswerInputWithCheckbox';

class QuizQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      questionAttachments: [],
      existingQuestionAttachments: props.attributes.existingAttachments || [],
      options: props.attributes.answerOptions || [
        ..._.times(props.answerOptionConfig.answerOptionsLength.minimum, i => ({
          ...props.answerOptionConfig.attributes,
          uniqKey: i + 1,
        })),
      ],

      [props.fieldsPrefix]: props.attributes || props.quizQuestionConfig.attributes || {},
    };
  }

  componentDidMount() {
    addPageLeavePreventDialog()
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  resetQuizForm = () => {
    this.props.onCancel();
  };

  renderUploader = ({ onPick }) => (
    <button
      type="button"
      style={{
        borderRadius: 40,
        backgroundColor: '#f4f5f8',
        color: '#919099',
      }}
      className="btn btn-icon"
      onClick={onPick}
    >
      <i className="la la-paperclip" />
    </button>
  );

  getOptionUniqKey = option => {
    return option.id || option.uniqKey;
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { csrfToken, courseId, courseQuizId, links } = this.props;
    const { questionAttachments, options } = this.state;

    const updatedAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );
    const assetAttributes = getAssetAttributes(questionAttachments);

    let mappedAnswerOptions = [];
    if (!_.isNil(updatedAttributes.id)) {
      mappedAnswerOptions = _.map(options, opt => {
        const incorrectMessage = opt.correct ? '' : opt.incorrectMessage;

        if (opt.isNew === true) {
          return { ..._.pick(opt, ['title', 'correct', 'attachments']),  'incorrect_message': incorrectMessage };
        }
          return { ..._.pick(opt, ['id', 'title', 'correct', 'attachments']),  'incorrect_message': incorrectMessage };
      });
    } else {
      mappedAnswerOptions = _.map(options, opt => (
        {
          ..._.pick(opt, ['title', 'correct', 'attachments']),
          'incorrect_message': opt.correct ? '' : opt.incorrectMessage
        })
      );
    }

    let Requester = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .doesRedirect(true)
      .useAlerts()
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedAttributes,
          attachments: assetAttributes,
          answer_options: mappedAnswerOptions,
        },
      })
      .onSuccess(res => {
        removePageLeavePreventDialog();
        window.location.href = links.showQuiz;
      });

    if (_.isNil(updatedAttributes.id)) {
      Requester = Requester.post(links.createQuizQuestion);
    } else {
      Requester = Requester.patch(updatedAttributes.links.updateQuestion);
    }

    await Requester.exec();
  };

  getModelAttributeValue = (attribute, optId) => {
    if (attribute !== 'option') {
      return this.state[this.props.fieldsPrefix][attribute] || '';
    } else {
      try {
        return (
          _.filter(this.state.options, opt => {
            return opt.id === optId || opt.uniqKey === optId;
          })[0].title || ''
        );
      } catch (e) {
        return '';
      }
    }
  };

  handleModelAttributeChange = (attributeName = '') => {
    const setModelAttributeState = (fieldName, value) => {
      const updatedModelAttributes = {
        ...this.state[this.props.fieldsPrefix],
        [fieldName]: value,
      };

      this.setState((s, p) => ({
        [this.props.fieldsPrefix]: updatedModelAttributes,
      }));
    };

    return event => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, option => option.value)
          : event.value;
        setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(targetName, targetValue);
      }
    };
  };

  handleOptionAttributeChange = (attributeName = '', optionId) => {
    const setModelAttributeState = (optionIndex, value) => {
      const updatedModelAttributes = _.map(this.state.options, option => {
        if (this.getOptionUniqKey(option) === optionId) {
          return {
            ...option,
            [attributeName]: value,
          };
        } else {
          return option;
        }
      });

      this.setState((s, p) => ({
        options: updatedModelAttributes,
      }));
    };

    return event => {
      const { target } = event;
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, option => option.value)
          : event.value;
        setModelAttributeState(optionId, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(optionId, targetValue);
      }
    };
  };

  addNewOption = () => {
    this.setState(prevState => ({
      options: [
        ...prevState.options,
        {
          isNew: true,
          uniqKey: (prevState.options.length > 0 ? Math.random() : 0) + 1,
          ...this.props.answerOptionConfig.attributes,
          showIncorrectMessageTextarea: false,
        },
      ],
    }));
  };

  handleOptionDeleteFromServer = option => async event => {
    if (this.state.options.length > this.props.answerOptionConfig.answerOptionsLength.minimum) {
      const { attributes, csrfToken } = this.props;

      const questionId = attributes.id;

      const Requester = await new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .delete(option.links.deleteOption)
        .onSuccess(rs => {
          this.handleOptionDelete(option.id)();
        })
        .exec();
    }
  };

  handleToggleIncorrectMessage = (optionId) => {
    return (event) => {
      const newShowIncorrectMessageTextareaState = event.target.checked;
      this.setState(prevState => ({
        options: prevState.options.map(option => {
          if (this.getOptionUniqKey(option) === optionId) {
            return {
              ...option,
              showIncorrectMessageTextarea: newShowIncorrectMessageTextareaState,
            };
          }
          return option;
        }),
      }));
    };
  };

  handleOptionDelete = optId => {
    return event => {
      if (this.state.options.length > this.props.answerOptionConfig.answerOptionsLength.minimum) {
        this.setState(prevState => ({
          options: _.filter(
            prevState.options,
            opt => this.getOptionUniqKey(opt) !== optId
          ),
        }));
      }
    };
  };

  onAttachmentChange = (type, optId) => (assets, existingAssets) => {
    if (type === 'question') {
      this.setState({
        questionAttachments: assets,
        existingQuestionAttachments: existingAssets,
      });
    } else {
      const updatedModelAttributes = _.map(this.state.options, option => {
        if (this.getOptionUniqKey(option) === optId) {
          return {
            ...option,
            attachments: assets,
            existingAttachments: existingAssets || [],
          };
        } else {
          return option;
        }
      });

      this.setState(prevState => ({
        options: updatedModelAttributes,
      }));
    }
  };

  renderOptionDeleteButton = option => {
    if (_.isNil(option.id)) {
      return (
        <button
          type="button"
          disabled={this.state.options.length <= this.props.answerOptionConfig.answerOptionsLength.minimum}
          className="btn btn-icon text-darkred answer-delete-btn answer-delete-btn--disabled"
          onClick={this.handleOptionDelete(this.getOptionUniqKey(option))}
        >
          <i className="la la-trash" />
        </button>
      );
    } else {
      return (
        <button
          type="button"
          disabled={this.state.options.length <= this.props.answerOptionConfig.answerOptionsLength.minimum}
          className="btn btn-icon text-darkred answer-delete-btn"
          onClick={this.handleOptionDeleteFromServer(option)}
        >
          <i className="la la-trash" />
        </button>
      );
    }
  };

  renderQuizForm = () => {
    const {
      options: questionOptions,
      questionAttachments,
      existingQuestionAttachments,
    } = this.state;

    const {
      csrfToken,
      quizQuestionConfig: { attachmentOptions: questionAttachmentOptions },
      answerOptionConfig: {
        attachmentOptions: answerAttachmentOptions,
        answerOptionsLength
       }
    } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        className="kt-form kt-form--fit quiz-form"
      >
        <div>
          <div className="kt-section kt-section--first">
            <FormRow label={'Question'} inputColClassName={'col-md-10'}>
              <div className="row">
                <TextArea
                  className={'form-control col-md-11'}
                  name={'title'}
                  placeholder="Write question title here"
                  rows={8}
                  value={this.getModelAttributeValue('title')}
                  numberAttributes={{
                    required: false,
                  }}
                  onInputChange={this.handleModelAttributeChange('title')}
                />
                <div className="col-md-1">
                  <AssetManager
                    wrapperClassName="mt-3 mt-md-0"
                    showGallery={false}
                    showLabel={false}
                    renderUploader={this.renderUploader}
                    csrfToken={csrfToken}
                    filestackOptions={questionAttachmentOptions}
                    onFileChange={this.onAttachmentChange('question')}
                  />
                </div>
              </div>
              <div className="row col-md-12">
                <AssetManager
                  showUploader={false}
                  showLabel={false}
                  showGallery={true}
                  csrfToken={csrfToken}
                  filestackOptions={questionAttachmentOptions}
                  existingAssets={existingQuestionAttachments}
                  assets={questionAttachments}
                  onFileChange={this.onAttachmentChange('question')}
                />
              </div>
            </FormRow>
          </div>
          <hr
            style={{
              background: '#eee',
              height: 2,
            }}
          />
          <div className="kt-section">
            {_.map(questionOptions, (option, index) => {
              return (
                <React.Fragment key={`Option_${this.getOptionUniqKey(option)}`}>
                  <FormRow label={`Answer Option ${index + 1}`} className="mb-0">
                    <AnswerInputWithCheckbox
                      isChecked={option.correct}
                      onCheckboxChange={this.handleOptionAttributeChange(
                        'correct',
                        this.getOptionUniqKey(option)
                      )}
                      name={`option${option.id}`}
                      placeholder="Write option title here"
                      value={this.getModelAttributeValue(
                        'option',
                        this.getOptionUniqKey(option)
                      )}
                      onTextChange={this.handleOptionAttributeChange(
                        'title',
                        this.getOptionUniqKey(option)
                      )}
                    />

                    <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center">
                      {!option.correct && (
                        <div className="form-group mb-0">
                          <label className="mb-0">
                            <input
                              type="checkbox"
                              checked={option.showIncorrectMessageTextarea || !!option.incorrectMessage}
                              onChange={this.handleToggleIncorrectMessage(this.getOptionUniqKey(option))}
                              style={{ marginRight: '5px' }}
                            />
                            {'Describe why this option is incorrect'}
                          </label>
                        </div>
                      )}

                      <div className="d-flex align-items-center mr-auto ml-sm-auto mr-sm-0 mb-3 mb-sm-0">
                        <AssetManager
                          showGallery={false}
                          showLabel={false}
                          renderUploader={this.renderUploader}
                          csrfToken={csrfToken}
                          filestackOptions={answerAttachmentOptions}
                          onFileChange={this.onAttachmentChange(
                            `option`,
                            this.getOptionUniqKey(option)
                          )}
                        />
                        <div className="ml-3">
                          {this.renderOptionDeleteButton(option)}
                        </div>
                      </div>
                    </div>
                    {!option.correct &&
                      (option.showIncorrectMessageTextarea ||
                        option.incorrectMessage) && (
                        <div className="form-group mt-2 mt-sm-4">
                          <TextArea
                            name={`incorrectMessage${option.uniqKey}`}
                            placeholder="Provide explanation why this option is incorrect"
                            rows={3}
                            value={option.incorrectMessage}
                            onInputChange={this.handleOptionAttributeChange(
                              'incorrectMessage',
                              this.getOptionUniqKey(option)
                            )}
                          />
                        </div>
                      )}
                    <AssetManager
                      showGallery
                      showLabel={false}
                      showUploader={false}
                      label={'Attachments'}
                      csrfToken={csrfToken}
                      filestackOptions={answerAttachmentOptions || []}
                      existingAssets={option.existingAttachments || []}
                      assets={option.attachments}
                      onFileChange={this.onAttachmentChange(
                        `option`,
                        this.getOptionUniqKey(option)
                      )}
                    />
                  </FormRow>
                  <div key="separator" className="kt-seperator kt-seperator--dashed mb-4" />
                </React.Fragment>
              );
            })}
          </div>

          {_.size(questionOptions) < answerOptionsLength.maximum && (
            <div className="kt-section">
              <div className="form-group kt-form__group text-right">
                <button
                  type="button"
                  className="app-btn-primary"
                  onClick={this.addNewOption}
                >
                  <span>
                    <i className="flaticon2-plus"></i>
                  </span>
                  Add New Option
                </button>
              </div>
            </div>
          )}
          <div className="mt-5 d-flex align-items-center justify-content-center">
            <SubmitButton
              buttonText="Save"
              className="mr-3 flex-grow-1 flex-sm-grow-0 px-sm-5"
            />
            <button
              type="button"
              className="app-btn-secondary flex-grow-1 flex-sm-grow-0 px-sm-5"
              onClick={this.resetQuizForm}
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    );
  };

  render() {
    const { loading } = this.state;

    const { courseQuiz } = this.props;

    const quizQuestionsArray = [];

    if (loading) {
      return <Spinner />;
    }

    return (
      <div>
        <div>{this.renderQuizForm()}</div>
      </div>
    );
  }
}

QuizQuestionForm.defaultProps = {
  fieldsPrefix: 'quiz_question',
  attributes: {},
};

QuizQuestionForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  courseQuizId: PropTypes.number.isRequired,
  fieldsPrefix: PropTypes.string,
  attributes: PropTypes.object,

  quizQuestionConfig: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
    attachmentOptions: PropTypes.object.isRequired,
  }).isRequired,

  answerOptionConfig: PropTypes.shape({
    attributes: PropTypes.object.isRequired,
    attachmentOptions: PropTypes.object.isRequired,
    answerOptionsLength: PropTypes.shape({
      minimum: PropTypes.number.isRequired,
      maximum: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,

  onCancel: PropTypes.func.isRequired,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    createQuiz: PropTypes.string.isRequired,
    listQuizzes: PropTypes.string.isRequired,
    updateQuiz: PropTypes.string.isRequired,
    showQuiz: PropTypes.string.isRequired,
    courseSettingsPath: PropTypes.string.isRequired,
    createQuizQuestion: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(QuizQuestionForm);

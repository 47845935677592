import { getFilterParams } from './utils';

export const INITIAL_LOADER_STATE = {
  loading: false,
  filterLoader: false,
  isPaginationLoading: false,
  isSuggestionsLoading: false,
};

export const INITIAL_LEARNING_FORUM_STATE = {
  allTopics: [],
  newTopicTitle: '',
  topicSuggestions: [],
  categoryFilter: getFilterParams({ key: 'categoryId', parseToInt: true }),
  topicFilter: getFilterParams({ key: 'topic' }),
};

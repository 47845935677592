import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AssetManager from '../../filestack/AssetManager';
import FolderContainer from './FolderContainer';
import AssetsContainer from './FolderContainer/AssetsContainer';
import FolderSidebar from './FolderContainer/FolderSidebar';
import FolderHeader from './FolderHeader';

import { ASSET_TYPE_HASH_PARAM, ASSET_MIMETYPE_FILTER_PREDICATES, DEFAULT_ASSET_FILTER_TYPE } from '../common/constants';
import { getAssetTypeFromLocationHash, getTenantDisplayTerm } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class ProtectedRootFolderList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      displayAssetType: DEFAULT_ASSET_FILTER_TYPE,
    }
  }

  componentDidMount = async () => {
    this.setDisplayAssetTypeFromLocationHash();

    window.onpopstate = (_event) => {
      this.setDisplayAssetTypeFromLocationHash();
    };
  }

  setDisplayAssetTypeFromLocationHash = () => {
    const displayAssetType = getAssetTypeFromLocationHash();

    this.setState({
      displayAssetType,
    });
  }

  handleAssetFilterClick = ({ assetType }) => {
    window.location.hash = `${ASSET_TYPE_HASH_PARAM}${assetType}`;
  }

  getFilteredAssets = ({ assetType }) => {
    const { assets: existingAssets } =  this.props;

    const assetTypeFilterPredicate = ASSET_MIMETYPE_FILTER_PREDICATES[assetType];

    const filteredAssets = _.filter(existingAssets, (asset) => (
      assetTypeFilterPredicate(asset.mimetype)
    ));

    return filteredAssets;
  }

  urlProvider = (folderType) => {
    const { parentType, parentId } = this.props;

    if (!_.isNil(parentType) && !_.isNil(parentId)) {
      return (
        `/folders/protected/resource_list/${parentType}/${parentId}/${folderType}`
      );
    } else {
      return (
        `/folders/protected/resource_list/${folderType}`
      );
    }
  }

  getDisplayProcessedFoldersObject = () => {
    const { folders, tenantDisplayTerms } = this.props;

    const folderObjects = _.map(folders, folderName => ({
      id: folderName,
      name: getTenantDisplayTerm({ tenantDisplayTerms, resourceTerm: folderName }),
    }));

    return folderObjects;
  }

  getFolderHeaderTitle = () => {
    const { parentName, parentType, tenantDisplayTerms } = this.props;
    const displayParentType = getTenantDisplayTerm({ tenantDisplayTerms, resourceTerm: parentType });

    return `${displayParentType} - ${parentName}`;
  }

  render() {
    const { displayAssetType } = this.state;
    const folderObjects = this.getDisplayProcessedFoldersObject();
    const filteredAssets = this.getFilteredAssets({ assetType: displayAssetType });
    const folderHeaderTitle = this.getFolderHeaderTitle();

    return (
      <div>
        <FolderHeader
          title={
            folderHeaderTitle
          }
        />

        {/* Content */}
        <FolderContainer
          folderSidebar={
            <FolderSidebar
              showAssetFilterOption={true}
              fileProps={{
                handlers: {
                  onAssetFilterClick: this.handleAssetFilterClick,
                },
              }}
              folderProps={{
                data: {
                  folders: folderObjects,
                  foldersUrlProvider: (folderType) => (
                    this.urlProvider(folderType)
                  ),
                },
              }}
            />
          }

          assetsHolder={
            <AssetsContainer>
              {
                !_.isEmpty(filteredAssets) ? (
                  <AssetManager
                    showUploader={false}
                    assets={[]}
                    existingAssets={filteredAssets}
                    shareEnablerPredicate={(_assetId) => (false)}
                  />
                ) : (
                    <span>
                      No Files Found
                    </span>
                  )
              }
            </AssetsContainer>
          }
        />
      </div>
    )
  }
}

ProtectedRootFolderList.propTypes = {
  assets: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  parentType: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  tenantDisplayTerms: PropTypes.object.isRequired,
};

ProtectedRootFolderList.defaultProps = {
  assets: [],
  tenantDisplayTerms: {},
};

export default withErrorHandler(ProtectedRootFolderList);

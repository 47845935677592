import React, { useState } from 'react';
import PropTypes from 'prop-types';
import BulkCSVProjectsTable from './BulkCSVProjectsTable';
import Http from '../common/Http';
import { convertArrayOfObjectsToHash } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import isNull from 'lodash/isNull';
import snakeCase from 'lodash/snakeCase';

const BulkCSVProjectsAssignment = ({
  tenantTerms,
  csrfToken,
  data: { categories, timeZones, links, importedProjects },
}) => {
  const [projectsData, setProjectsData] = useState(
    convertArrayOfObjectsToHash(importedProjects, 'id')
  );
  const [loading, setLoading] = useState(false);
  //https://stackoverflow.com/questions/5773950
  // to maintain JS objects in order.
  const projectsInOrder = map(importedProjects, 'id');
  const { termProject } = tenantTerms;
  const { projectsPath } = links;

  const updateProjectData = ({
    projectId,
    attributeName,
    attributeValue,
    stateCallback = null,
  }) => {
    const updatedAttributes = {
      ...projectsData[projectId],
      [attributeName]: attributeValue,
    };

    setProjectsData(prevState => ({
      ...prevState,
      [projectId]: {
        ...updatedAttributes,
      },
    }));

    if (!isNull(stateCallback)) {
      stateCallback();
    }
  };

  const submitAttributeChange = async ({
    postData: { projectId, attributeName, attributeValue },
    projectFallbackData,
  }) => {
    const updatedPostData = isEqual(attributeName, 'categories')
      ? {
          category_names: map(attributeValue, 'name'),
        }
      : { [snakeCase(attributeName)]: attributeValue };

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .post(links.bulkAssignment)
      .setPostData({
        bulk_project_import: { project_ids: [projectId], ...updatedPostData },
      })
      .onSuccess(() => {
        setLoading(false);
      })
      .onError(() => {
        setLoading(false);
        updateProjectData({
          projectId,
          attributeName,
          projectFallbackData,
        });
      })
      .exec();
  };

  const handleProjectAttributeChange = (
    projectId,
    attributeName,
    attributeValue
  ) => {
    const projectFallbackData = projectsData[projectId][attributeName];

    const stateCallback = () =>
      submitAttributeChange({
        postData: { projectId, attributeName, attributeValue },
        projectFallbackData,
      });

    updateProjectData({
      projectId,
      attributeName,
      projectFallbackData,
      stateCallback,
    });

    const updatedAttributes = {
      ...projectsData[projectId],
      [attributeName]: attributeValue,
    };

    setProjectsData(prevState => ({
      ...prevState,
      [projectId]: {
        ...updatedAttributes,
      },
    }));
  };

  const getProjectAttributeValue = (projectId, attributeName) => {
    let attributeValue = projectsData[projectId][attributeName];

    if (isEqual(attributeName, 'categories')) {
      // Initial Project Data will not have categories
      // categories is set only on change, hence using categoryIds for getting it.
      if (isUndefined(projectsData[projectId]['categories'])) {
        attributeValue = map(projectsData[projectId]['categoryIds'], id =>
          find(categories, catagoryValue => catagoryValue.id === id)
        );
      }
    }

    return attributeValue;
  };

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            Bulk {termProject.plural} Import - Properties Assignment
            {loading && (
              <span className="text-center kt-font-info font-14 px-2">
                Updating......
              </span>
            )}
          </h3>
        </div>
        {!isNil(projectsPath) && (
          <div className="kt-portlet__head-toolbar">
            <a
              href={projectsPath}
              className="btn-sm app-btn-outline-primary m-1"
            >
              Go to {termProject.plural}
            </a>
          </div>
        )}
      </div>
      <div className="kt-portlet__body">
        <BulkCSVProjectsTable
          projectsData={projectsData}
          projectsInOrder={projectsInOrder}
          categories={categories}
          timeZones={timeZones}
          tenantTerms={tenantTerms}
          getProjectAttributeValue={getProjectAttributeValue}
          handleProjectAttributeChange={handleProjectAttributeChange}
        />
      </div>
    </div>
  );
};

BulkCSVProjectsAssignment.propTypes = {
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })
    ).isRequired,
    timeZones: PropTypes.arrayOf(PropTypes.string).isRequired,
    importedProjects: PropTypes.arrayOf(PropTypes.object).isRequired,
    links: PropTypes.shape({
      bulkAssignment: PropTypes.string.isRequired,
      projectsPath: PropTypes.string,
    }).isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termTicket: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(BulkCSVProjectsAssignment);

import React from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import Modal from '../Modal/Modal';
import SmoothImage from '../presentational/SmoothImage';
import AttachmentCard from './AttachmentCard';
import { calculateFileSize } from './utils';
import PreviewSlider from './PreviewSlider';

import './styles.scss';

const Attachments = ({ attachments, previewSlider }) => {
  const renderModalBody = () => (
    <div className="row">
      {attachments.map(({ filename, id, links, url, size, mimetype, poster }) => (
        <div className="col-md-4" key={url}>
          <Modal
            header="Attachment Preview"
            trigger={
              <AttachmentCard
                url={url}
                filename={filename}
                links={links}
                size={calculateFileSize(size)}
                mimetype={mimetype}
                poster={poster}
              />
            }
            content={renderAttachmentPreview(mimetype, url)}
          />
        </div>
      ))}
    </div>
  );

  const renderWithPreviewSlider = () => <PreviewSlider attachments={attachments} />;

  const renderAttachmentPreview = (mimetype, url) => {
    if (mimetype.includes('image')) {
      return <SmoothImage src={url} alt="preview" />;
    } else if (mimetype.includes('video')) {
      return <video src={url} className="w-100" controls />;
    }
  };

  const hasMoreView = attachments.length > 3;
  let reviewAttachments = hasMoreView ? attachments.slice(0, 2) : attachments;

  return (
    <div className="custom-attachment-preview">
      <div className="row">
        {reviewAttachments.map(({ filename, id, links, url, size, mimetype }) => (
          <div className="col-md-4" key={url}>
            <Modal
              header="Attachment Preview"
              trigger={
                <div className="custom_attachment-preview m-1">
                  <div className="d-flex align-items-center">
                    <SmoothImage src={url} alt="preview" />
                    <div
                      className="p-2 d-flex justify-content-between"
                      style={{ minWidth: 0, flex: 1 }}
                    >
                      <div className="text-truncate">
                        <span className="font-weight-normal">{filename}</span>
                        <div className="text-muted font-11">
                          {calculateFileSize(size)}
                        </div>
                      </div>
                      {links?.download && (
                        <a
                          href={links.download}
                          className="custom-secondary-btn"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <i className="fas fa-cloud-download-alt"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              }
              content={renderAttachmentPreview(mimetype, url)}
            />
          </div>
        ))}
        {hasMoreView && (
          <div className="col-md-4">
            <Modal
              header="Attachment Preview"
              trigger={
                <div className="custom_attachment-preview p-2 text-center m-1">
                  + {attachments.length - 2}
                  <div>View All</div>
                </div>
              }
              content={previewSlider ? renderWithPreviewSlider() : renderModalBody()}
              size="lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  previewSlider: PropTypes.bool,
};

Attachments.defaultProps = {
  previewSlider: true,
};

export default withErrorHandler(Attachments);

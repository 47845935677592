import React from 'react';
import PropTypes from 'prop-types';

import isNull from 'lodash/isNull';

import FormRow from '../common/presentational/formRow';
import TextInput from '../common/inputs/text';
import Toggle from '../common/inputs/Toggle';
import { CirclePicker } from 'react-color';
import SubmitButton from '../common/presentational/submitButton';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

const ProjectTypeForm = props => {
  const [loading, setLoading] = React.useState(false);
  const [formState, setFormState] = React.useState({
    [props.modelFieldsPrefix]: {
      ...props.attributes,
    },
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const { links, projectTypeId, csrfToken, modelFieldsPrefix } = props;

    const Requestor = new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .doesRedirect(true);

    if (isNull(projectTypeId)) {
      Requestor.post(links.create);
    } else {
      Requestor.put(links.update);
    }

    Requestor.setPostData({
      project_type: {
        ...formState[modelFieldsPrefix],
      },
    })
      .onSuccess(res => {
        const { data } = res;
        setLoading(false);
        window.location.href = data.redirection_url;
      })
      .onError(err => {
        setLoading(false);
        alertErrorNotifications(err);
      });

    await Requestor.exec();
  };

  const getModelAttributeValue = attribute =>
    formState[props.modelFieldsPrefix][attribute] || '';

  const handleModelAttributeChange = (attributeName, value) => {
    const { modelFieldsPrefix } = props;

    setFormState(prevState => ({
      [modelFieldsPrefix]: {
        ...prevState[modelFieldsPrefix],
        [attributeName]: value,
      },
    }));
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <form
        className="kt-form kt-form--label-right kt-form--fit p-3"
        onSubmit={e => handleSubmit(e)}
      >
        <div className="form-group kt-form__group">
          <FormRow label="Name">
            <TextInput
              name={'name'}
              value={getModelAttributeValue('name')}
              onInputChange={({ target }) =>
                handleModelAttributeChange('name', target.value)
              }
            />
          </FormRow>
          <FormRow label="Enabled">
            <Toggle
              name={'enabled'}
              onClick={value => handleModelAttributeChange('enabled', value)}
              on={'Yes'}
              off={'No'}
              size="sm"
              width={70}
              height={30}
              recalculateOnResize
              active={!!getModelAttributeValue('enabled')}
            />
          </FormRow>
          <FormRow label="Color">
            <CirclePicker
              color={getModelAttributeValue('color')}
              onChangeComplete={color =>
                handleModelAttributeChange('color', color.hex)
              }
            />
          </FormRow>
          <FormRow label=" ">
            <SubmitButton />
          </FormRow>
        </div>
      </form>
    </React.Fragment>
  );
};

ProjectTypeForm.propTypes = {
  csrfToken: PropTypes.string,
  attributes: PropTypes.object,
  projectTypeId: PropTypes.number,
  links: PropTypes.shape({
    create: PropTypes.string,
    update: PropTypes.string,
  }).isRequired,
  modelFieldsPrefix: PropTypes.string,
};

ProjectTypeForm.defaultProps = {
  modelFieldsPrefix: 'attributes',
};

export default withErrorHandler(ProjectTypeForm);

import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { validateHTMLColor } from 'validate-color';
import TextInput from '../../common/inputs/text';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ColorInput = ({ color: colorCode, name, onChange, setSaveButtonStatus }) => {
  const [color, setColor] = useState(colorCode);
  const [isValidColor, setIsValidColor] = useState(validateHTMLColor(color));

  useEffect(() => {
    setColor(colorCode);
  }, [colorCode]);

  const checkIfColorCodeValid = (e) => {
    const {
      target: { value },
    } = e;
    setColor(value);

    if (validateHTMLColor(value)) {
      setSaveButtonStatus(true);
      setIsValidColor(true);
      onChange(e);
    } else {
      setIsValidColor(false);
      setSaveButtonStatus(false);
    }
  };

  return (
    <div>
      <div className="d-flex">
        <TextInput
          type="text"
          name={name}
          value={color}
          onInputChange={checkIfColorCodeValid}
        />
        <input
          type="color"
          name={name}
          className="ml-2"
          value={color}
          onChange={checkIfColorCodeValid}
        />
      </div>
      {!isValidColor && (
        <div className="text-danger">Please enter a valid color code</div>
      )}
    </div>
  );
};

ColorInput.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setSaveButtonStatus: PropTypes.func.isRequired,
};

export default withErrorHandler(memo(ColorInput));

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import keys from 'lodash/keys';

import ActivitiesTable from './ActivitiesTable';
import ActivityPagination from './ActivityPagination';
import Http from '../common/Http';
import SelectInput from '../common/inputs/select';
import DatePicker from 'react-datepicker';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';

export const Sorted = Object.freeze({
  typeAsc: 0,
  typeDesc: 1,
  dateAsc: 2,
  dateDesc: 3,
});

const ActivityTable = ({ csrfToken, filterOptions, links }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortedActivities, setSortedActivities] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentSortBy, setCurrentSortBy] = useState(Sorted.dateDesc);
  const [sortQuery, setSortQuery] = useState('created_at+desc');

  const getPageData = React.useCallback(
    async (pageNum = null) => {
      const { index } = links;

      const dataUrl = index + '?q[s]=' + sortQuery;

      let filterParams = {};
      if (!isEmpty(selectedFilters)) {
        if (!isEmpty(selectedFilters['trackableType'])) {
          if (selectedFilters['trackableType'] === '-') {
            filterParams['q[trackable_type_null]'] = 1;
          } else {
            filterParams['q[trackable_type_eq]'] =
              selectedFilters['trackableType'] || null;
          }
        }

        if (!isNil(selectedFilters['ownerId'])) {
          if (selectedFilters['ownerId'] === '-') {
            filterParams['q[owner_id_null]'] = 1;
          } else {
            filterParams['q[owner_id_eq]'] = selectedFilters['ownerId'] || null;
          }
        }

        filterParams = {
          ...filterParams,
          ...(!isNil(selectedFilters['startDate']) && {
            'q[created_at_gteq]': selectedFilters['startDate'].toString() || '',
          }),
          ...(!isNil(selectedFilters['endDate']) && {
            'q[created_at_lteq]': selectedFilters['endDate'].toString() || '',
          }),
        };
      }
      await new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .get(dataUrl, {
          page: pageNum || currentPage,
          ...filterParams,
        })
        .onSuccess(response => {
          const { data } = response;
          setSortedActivities(data.activities);
          setCurrentPage(data.meta.currentPage);
          setTotalPages(data.meta.totalPages);
          setLoading(false);
        })
        .onError(err => {
          console.log(err);
          setLoading(false);
          alertErrorNotifications(err || 'Error getting Activities!');
        })
        .exec();
    },
    [csrfToken, currentPage, links, selectedFilters, sortQuery]
  );

  React.useEffect(() => {
    if (currentPage === 0) {
      getPageData(1);
    }
  }, [currentPage, getPageData]);

  const handleSortBy = sortBy => () => {
    let sorter = 'trackable_type';
    let ascDesc = 'asc';

    if (sortBy === 0 || sortBy === 2) {
      ascDesc = 'asc';
    } else if (sortBy === 1 || sortBy === 3) {
      ascDesc = 'desc';
    }

    if (sortBy === 0 || sortBy === 1) {
      sorter = 'trackable_type';
    } else if (sortBy === 2 || sortBy === 3) {
      sorter = 'created_at';
    }

    setCurrentSortBy(sortBy);
    setSortQuery(`${sorter}+${ascDesc}`);
  };

  React.useEffect(() => {
    getPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSortBy, sortQuery]);

  const handleFilterSelect = attributeName => selectedValues => {
    if (attributeName.includes('Date')) {
      setSelectedFilters(prevState => ({
        ...prevState,
        [attributeName]: selectedValues,
      }));
    } else {
      setSelectedFilters(prevState => ({
        ...prevState,
        [attributeName]: selectedValues.value,
      }));
    }
  };

  const handleFilterAction = () => {
    getPageData(1);
  };

  const getPageLink = num => () => {
    getPageData(num);
  };

  const renderFilterRow = () => {
    let selectedStartDate = null;
    let selectedEndDate = null;

    if (!isNil(selectedFilters.startDate)) {
      selectedStartDate = selectedFilters.startDate;
    }

    if (!isNil(selectedFilters.endDate)) {
      selectedEndDate = selectedFilters.endDate;
    }

    if (!isNil(filterOptions)) {
      return (
        <div className="mb-5">
          <div className="row filters-select-row">
            {map(keys(filterOptions), filterKey => {
              return (
                <div className="col-lg-3" key={filterKey}>
                  <SelectInput
                    placeholder={`Select ${
                      filterKey === 'ownerId' ? 'Owner' : 'Resource Type'
                    }`}
                    name={filterKey}
                    options={[{ label: 'None', value: '-' }].concat(
                      filterOptions[filterKey]
                    )}
                    value={selectedFilters[filterKey] || ''}
                    onChange={handleFilterSelect(filterKey)}
                    optionIdentifier="value"
                  />
                </div>
              );
            })}
            <div className="col-lg-3 datepicker-tweaks">
              <DatePicker
                isClearable={true}
                dropdownMode={'select'}
                selected={selectedStartDate}
                showTimeSelect={false}
                maxDate={selectedEndDate}
                onChange={handleFilterSelect('startDate')}
                className={'form-control Select-control'}
                placeholderText="From"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
              />
            </div>
            <div className="col-lg-3 datepicker-tweaks">
              <DatePicker
                isClearable={true}
                dropdownMode={'select'}
                selected={selectedEndDate}
                showTimeSelect={false}
                minDate={selectedStartDate}
                onChange={handleFilterSelect('endDate')}
                className={'form-control Select-control'}
                placeholderText="To"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
              />
            </div>
          </div>
          <div className="mt-5 row">
            <div className="col-lg-12 text-center">
              <button
                type="button"
                disabled={isEmpty(selectedFilters)}
                className="app-btn-primary"
                onClick={handleFilterAction}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="kt-section">
      <div className="kt-section__content">
        {loading && <Spinner />}
        {renderFilterRow()}

        <div className="kt-pagination  kt-pagination--brand d-flex justify-content-center">
          <ActivityPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={getPageLink}
          />
        </div>
        <br />

        <ActivitiesTable
          activities={sortedActivities}
          currentSortBy={currentSortBy}
          handleSortBy={handleSortBy}
        />
        <br />
        <div className="kt-pagination  kt-pagination--brand d-flex justify-content-center">
          <ActivityPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={getPageLink}
          />
        </div>
      </div>
    </div>
  );
};

ActivityTable.propTypes = {
  links: PropTypes.shape({
    index: PropTypes.string,
  }),
  filterOptions: PropTypes.object,
  csrfToken: PropTypes.string,
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      owner: PropTypes.string.isRequired,
      description: PropTypes.string,
      activity: PropTypes.string,
      date: PropTypes.string,
      links: PropTypes.object,
    })
  ),
};

export default withErrorHandler(ActivityTable);

export const STEP_FORM_DETAILS_ID = 1;
export const STEP_FORM_GENERATOR_ID = 2;
export const STEP_RESPONSE_MESSAGES_ID = 3;

export const SUBMIT_BUTTON_STYLE_OPTIONS = Object.freeze({
  colors: [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    'danger',
    'light',
    'dark',
  ],
  alignments: [
    { label: 'Left', value: 'start' },
    { label: 'Center', value: 'center' },
    { label: 'Right', value: 'end' },
  ],
});

export const DEFAULT_SUBMIT_BUTTON_OPTIONS = Object.freeze({
  text: 'Submit',
  color: 'success',
  alignment: 'start',
});

export const SUBMIT_BUTTON_ELEMENT_IDENTIFIER = 'CustomSubmitButtonOptions';

export const DEFAULT_RESPONSE_CONFIRMATION_MESSAGES = Object.freeze({
  defaultConfirmationMessage: '',
  positiveConfirmationMessage: '',
  negativeConfirmationMessage: '',
});

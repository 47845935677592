import { useContext } from 'react';
import { DashboardContext } from '../dashboardTabs/DashboardTab';

const useLearnerTabContext = () => {
  const learnerTabContext = useContext(DashboardContext);
  if (learnerTabContext === undefined) {
    throw new Error(
      'useLearnerTabContext must be used within a AdminTabProvider'
    );
  }
  return learnerTabContext;
};

export default useLearnerTabContext;

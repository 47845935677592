import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import omit from 'lodash/omit';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';

import ReactDraftWysiwygEditor from '../wysiwyg_editor';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import TextArea from '../common/inputs/textArea';
import Text from '../common/inputs/text';
import FormRow from '../common/presentational/formRow';
import {
  SIMPLE_WYSIWYG_EDITOR_OPTIONS,
  SMS_CHARACTER_LIMIT,
} from '../common/constants';
import Http from '../common/Http';
import {
  getSnakeCaseKeyedObject,
  getCamelCaseKeyedObject,
  removePageLeavePreventDialog,
} from '../common/utils';
import Spinner from '../common/presentational/spinner';
import Select from '../common/inputs/select';
import AssetManager from '../filestack/AssetManager';
import { withErrorHandler } from '../hoc/withErrorHandler';

const RESET_RESPONSE_TEMPLATE = Object.freeze({
  id: null,
  title: '',
  body: '',
  templateType: 0,
  emailBody: EditorState.createEmpty(),
});

const ResponseTemplateForm = props => {
  const [template, setTemplate] = React.useState({
    ...RESET_RESPONSE_TEMPLATE,
  });
  const [assets, setAssets] = React.useState([]);
  const [existingAssets, setExistingAssets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const templateTypeOptions = React.useMemo(
    () =>
      map(keys(props.permittedTemplateTypes), type => ({
        label: isEqual(type, 'sms') ? 'SMS' : startCase(type),
        value: props.permittedTemplateTypes[type],
      })),
    [props.permittedTemplateTypes]
  );

  const placeholderOptions = React.useMemo(
    () =>
      map(props.placeholderOptions, option => ({
        label: option[1],
        value: option[0],
      })),
    [props.placeholderOptions]
  );

  React.useEffect(() => {
    const { responseTemplate, permittedTemplateTypes } = props;
    // Not required to set emailBody or update template from props for a new template
    if (isNull(responseTemplate.id)) return;

    let camelizedResponseTemplate = getCamelCaseKeyedObject(responseTemplate);

    try {
      if (camelizedResponseTemplate.body) {
        const contentBlock = htmlToDraft(camelizedResponseTemplate.body);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          camelizedResponseTemplate.emailBody = editorState;
        }
      } else {
        camelizedResponseTemplate.emailBody =
          RESET_RESPONSE_TEMPLATE['emailBody'];
      }
    } catch (e) {
      console.log(e);
    }
    setTemplate({
      ...camelizedResponseTemplate,
      templateType:
        permittedTemplateTypes[
          camelCase(camelizedResponseTemplate.templateType)
        ],
    });
    setExistingAssets(camelizedResponseTemplate.attachments);

    // componentDidMount equivalent useEffect to trigger only on the component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTemplateAttributeChange = (attributeValue, attributeName) => {
    setTemplate(prevState => ({
      ...prevState,
      [attributeName]: attributeValue,
    }));
  };

  const handleTemplateTypeChange = type => {
    const { responseTemplate } = props;

    const isNewTemplate = !responseTemplate.id;

    const resetCommentBody =
      isEqual(responseTemplate.templateType, 'email') &&
      isEqual(props.permittedTemplateTypes['sms'], type);

    setTemplate(prevState => ({
      ...prevState,
      templateType: type,
      ...(!isNewTemplate &&
        resetCommentBody && {
          body: prevState.emailBody.getCurrentContent().getPlainText(' '),
        }),
    }));
  };

  const onFileChange = (assets, existingAssets) => {
    setAssets(assets);
    setExistingAssets(existingAssets);
  };

  const onEditorStateChange = editorState => {
    setTemplate(prevState => ({
      ...prevState,
      emailBody: editorState,
    }));
  };

  const isSMSTemplateType = () =>
    isEqual(props.permittedTemplateTypes['sms'], template.templateType);

  const isAutoReplyTemplateType = () =>
    isEqual(props.permittedTemplateTypes['autoReply'], template.templateType);

  const handleSubmit = async e => {
    e.preventDefault();

    const { links, csrfToken } = props;

    let snakeCasedTemplate = getSnakeCaseKeyedObject(template);

    if (!isSMSTemplateType()) {
      const htmlConvertedBody = draftToHtml(
        convertToRaw(template.emailBody.getCurrentContent())
      );
      snakeCasedTemplate.body = htmlConvertedBody.replace('<p></p>\n', '');
    }

    snakeCasedTemplate = omit(snakeCasedTemplate, 'email_body');

    let Request = await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .setPostData({
        response_template: {
          ...snakeCasedTemplate,
          title: template.title.trim(),
          attachments: [...assets, ...existingAssets],
        },
      })
      .doesRedirect(true)
      .onSuccess(res => {
        removePageLeavePreventDialog();
        const {
          data: { meta },
        } = res;
        setTemplate({ ...RESET_RESPONSE_TEMPLATE });
        setLoading(false);
        window.location.href = meta.redirection_url;
      })
      .onError(() => {
        setLoading(false);
      })
      .useAlerts();

    if (!isNull(template.id)) {
      Request = Request.patch(links.update);
    } else {
      Request = Request.post(links.create);
    }

    await Request.exec();
  };

  const isEmailBodyEmpty = emailBody =>
    !emailBody.getCurrentContent().hasText();

  const insertCustomPlaceholder = (text, isSMSTemplate = false) => {
    if (isEmpty(text)) return;

    const { emailBody } = template;

    if (isSMSTemplate) {
      setTemplate(prevState => ({
        ...prevState,
        body: `${prevState.body} ${text}`,
      }));
    } else {
      const selection = emailBody.getSelection();

      let newContentState;

      if (selection.isCollapsed()) {
        newContentState = Modifier.insertText(
          emailBody.getCurrentContent(),
          selection,
          text
        );
      } else {
        newContentState = Modifier.replaceText(
          emailBody.getCurrentContent(),
          selection,
          text
        );
      }
      const editorState = EditorState.createWithContent(newContentState);
      onEditorStateChange(editorState);
    }
  };

  const { attachmentOptions, csrfToken } = props;
  const {
    title,
    body,
    emailBody,
    templateType,
    minNetSentimentScore,
    maxNetSentimentScore,
  } = template;

  const isSMSTemplate = isSMSTemplateType();

  const isEmptyBody = isSMSTemplate
    ? isEmpty(body)
    : isEmailBodyEmpty(emailBody);

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Response Template Form</h3>
        </div>
      </div>
      {loading && <Spinner />}
      <div className="kt-portlet__body">
        <div className="kt-section">
          <div className="kt-section__content">
            <form
              encType="multipart/form-data"
              className="kt-form kt-form--label-right"
              onSubmit={handleSubmit}
            >
              <div className="kt-section kt-section--first">
                <FormRow label={'Title *'}>
                  <Text
                    name={'title'}
                    value={title || ''}
                    placeholder="We are Sorry!"
                    onInputChange={({ target }) =>
                      handleTemplateAttributeChange(target.value, 'title')
                    }
                    numberAttributes={{
                      required: true,
                    }}
                  />
                </FormRow>

                <FormRow label={'Type *'}>
                  <Select
                    name={'templateType'}
                    value={templateType || 0}
                    options={templateTypeOptions}
                    isClearable={false}
                    onChange={({ value }) => handleTemplateTypeChange(value)}
                    optionIdentifier="value"
                  />
                </FormRow>

                {isAutoReplyTemplateType() && (
                  <>
                    <FormRow label={'Min Sentiment Score *'}>
                      <Text
                        numberAttributes={{
                          step: 1,
                          min: -100,
                          max: 100,
                        }}
                        inputType={'number'}
                        name={'minNetSentimentScore'}
                        placeholder={'Insert a value -100 and 100'}
                        value={minNetSentimentScore}
                        onInputChange={({ target }) =>
                          handleTemplateAttributeChange(
                            target.value,
                            'minNetSentimentScore'
                          )
                        }
                      />
                    </FormRow>
                    <FormRow label={'Max Sentiment Score *'}>
                      <Text
                        numberAttributes={{
                          step: 1,
                          min: -100,
                          max: 100,
                        }}
                        name={'maxNetSentimentScore'}
                        placeholder={'Insert a value -100 and 100'}
                        inputType={'number'}
                        value={maxNetSentimentScore}
                        onInputChange={({ target }) =>
                          handleTemplateAttributeChange(
                            target.value,
                            'maxNetSentimentScore'
                          )
                        }
                      />
                    </FormRow>
                  </>
                )}

                <FormRow label={'Body *'}>
                  <div className="response-template">
                    <Select
                      name={'templatePlaceholder'}
                      value={''}
                      placeholder={'Insert placeholder'}
                      options={placeholderOptions}
                      onChange={({ value }) =>
                        insertCustomPlaceholder(value, isSMSTemplate)
                      }
                      optionIdentifier="value"
                    />
                    {isSMSTemplate ? (
                      <React.Fragment>
                        <TextArea
                          name={'body'}
                          value={body || ''}
                          onInputChange={({ target }) =>
                            handleTemplateAttributeChange(target.value, 'body')
                          }
                          required={true}
                          placeholder="I'm sorry you were not happy with the service provided. In looking at the photo you sent, I can see that the standard of excellence we strive for was not met. I have communicated this back to the respective manager and we will work on ensuring better service in the future."
                        />
                        {body.length > SMS_CHARACTER_LIMIT && (
                          <p className="font-12 text-darkred">
                            *Content will be sent in Multiple (&nbsp;
                            <strong>
                              {Math.ceil(body.length / SMS_CHARACTER_LIMIT)}
                            </strong>
                            &nbsp;) SMSes
                          </p>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <ReactDraftWysiwygEditor
                          editorState={emailBody}
                          onEditorStateChange={onEditorStateChange}
                          toolbarConfig={{
                            options: SIMPLE_WYSIWYG_EDITOR_OPTIONS,
                          }}
                        />
                        <AssetManager
                          csrfToken={csrfToken}
                          showLabel={false}
                          filestackOptions={attachmentOptions}
                          assets={assets}
                          existingAssets={existingAssets}
                          onFileChange={onFileChange}
                          renderUploader={({ onPick }) => (
                            <button
                              title="Attachments"
                              className="btn btn-outline-brand btn-elevate btn-icon btn-md mt-15"
                              onClick={onPick}
                            >
                              <i className="flaticon2-photograph" />
                            </button>
                          )}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </FormRow>
              </div>

              <div className="kt-separator kt-separator--dashed" />
              <div className="kt-section">
                <div className="text-center">
                  <CancelButton />
                  <SubmitButton disabled={isEmpty(title) || isEmptyBody} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

ResponseTemplateForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    create: PropTypes.string,
    update: PropTypes.string,
  }).isRequired,
  responseTemplate: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    body: PropTypes.string,
    templateType: PropTypes.string,
  }),
  permittedTemplateTypes: PropTypes.shape({
    email: PropTypes.number.isRequired,
    sms: PropTypes.number,
    autoReply: PropTypes.number,
  }).isRequired,
  placeholderOptions: PropTypes.array,
  attachmentOptions: PropTypes.object,
};

export default withErrorHandler(ResponseTemplateForm);

import React from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';

import Select from '../../common/inputs/select';
import { customFilterOptions } from '../../common/utils';

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.
class CustomSelectDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.filterOptionKeysProvided =
      !isNil(props.customFilterOptionKeys) &&
      !isEmpty(props.customFilterOptionKeys);

    this.state = {
      value: '',
      options: props.optionsList,
    };
  }

  onChange = ({ id }) => {
    this.setState({
      value: id,
    });
  };

  renderFilterHelperText = () => {
    const { customFilterOptionKeys } = this.props;
    const humanizedAttributes = reduce(
      customFilterOptionKeys,
      (humanizedString, key, index) => {
        if (!isEqual('address_2', key)) {
          humanizedString += `${index > 0 ? ', ' : ''}${startCase(key)}`;
        }
        return humanizedString;
      },
      ''
    );
    return (
      <p className="kt-font-info">{`* filter by ${humanizedAttributes}`}</p>
    );
  };

  render() {
    const { options, value: selectedValue } = this.state;

    const { data, answerData, defaultValue, customFilterOptionKeys } =
      this.props;
    const { field_name, read_only } = data;
    let prefilledValue = defaultValue || '';
    if (!isNil(answerData) && !isNil(answerData[field_name])) {
      prefilledValue = answerData[field_name];
    }

    const customProps = this.filterOptionKeysProvided
      ? {
          filterOption: customFilterOptions(customFilterOptionKeys),
        }
      : {};

    return (
      <React.Fragment>
        {!read_only &&
          this.filterOptionKeysProvided &&
          this.renderFilterHelperText()}
        <Select
          name={'Members'}
          isDisabled={read_only}
          options={options}
          value={selectedValue || prefilledValue}
          onChange={this.onChange}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          optionIdentifier="id"
          {...customProps}
        />
      </React.Fragment>
    );
  }
}

CustomSelectDropDown.propTypes = {
  mobileValidationLink: PropTypes.string,
  data: PropTypes.shape({
    field_name: PropTypes.string,
    read_only: PropTypes.bool,
  }),
  optionsList: PropTypes.array,
  defaultValue: PropTypes.string,
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  customFilterOptionKeys: PropTypes.array,
};
CustomSelectDropDown.defaultProps = {
  optionsList: [],
  defaultValue: '',
};

export default CustomSelectDropDown;

import React from 'react';
import PropTypes from 'prop-types';

import NoItemMessage from './common/NoTopics';
import { withErrorHandler } from '../../hoc/withErrorHandler';

import KbLink from './common/KbLink';

const Categories = ({ categories }) => {
  if (_.isEmpty(categories)) {
    return <NoItemMessage />;
  }

  return (
    <div className="knowledge_base-container">
      <div className="knowledge_base-content">
        {categories.map(({ links, name, id }) => (
          <KbLink link={links.viewCategory} name={name} key={id} />
        ))}
      </div>
    </div>
  );
};

Categories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default withErrorHandler(Categories);

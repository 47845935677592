import React, { memo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextArea from '../../common/inputs/textArea';
import AssetManager from '../../filestack/AssetManager';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ReplyForm = ({
  onSubmit,
  loading,
  values,
  placeholder,
  isFocused,
  onCancel,
  replyAttachmentsConfig,
  csrfToken,
  clearFormValues,
}) => {
  const [formData, setFormData] = useState({
    body: values.body || '',
    attachments: [],
    existingAttachments: values.attachments || [],
  });
  const inputRef = useRef();
  const initialRenderRef = useRef(true);

  useEffect(() => {
    setFormData({
      body: values.body || '',
      attachments: [],
      existingAttachments: values.attachments || [],
    });
  }, [values]);

  useEffect(() => {
    if (initialRenderRef.current) {
      initialRenderRef.current = false;
    } else {
      if (clearFormValues) {
        // Reset form
        setFormData({
          body: '',
          attachments: [],
          existingAttachments: [],
        });
      }
    }
  }, [clearFormValues]);

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleInputChange = ({ target: { value } }) => {
    setFormData((prev) => ({
      ...prev,
      body: value,
    }));
  };

  const onAttachmentChange = (attachments, existingAttachments) => {
    setFormData((prev) => ({
      ...prev,
      attachments,
      existingAttachments,
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="reply-form">
      <TextArea
        name="newReply"
        className="w-100 reply-input"
        onInputChange={handleInputChange}
        value={formData.body}
        placeholder={placeholder}
        disabled={loading}
        reference={inputRef}
        required={true}
        rows={5}
      />
      <AssetManager
        showLabel={false}
        showUploader={true}
        csrfToken={csrfToken}
        filestackOptions={replyAttachmentsConfig}
        existingAssets={formData.existingAttachments}
        assets={formData.attachments}
        showAssetsAsListItems
        onFileChange={onAttachmentChange}
        renderUploader={({ onPick }) => (
          <button
            className="btn-sm app-btn-primary"
            onClick={!loading ? onPick : null}
            disabled={loading}
          >
            <i className="la la-plus"></i> Select Files
          </button>
        )}
      />

      <div className="d-flex justify-content-end align-items-center">
        {!_.isNil(onCancel) && (
          <button
            className="reply-button btn-sm btn-outline-darkred mr-2"
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            <i className="fas fa-times mr-1"></i>
            Cancel
          </button>
        )}
        <button className="reply-button btn btn-sm btn-outline-info" disabled={loading}>
          <i className="fas fa-location-arrow mr-1"></i>
          {loading ? 'Posting...' : 'Post'}
        </button>
      </div>
    </form>
  );
};

ReplyForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  isFocused: PropTypes.bool,
  clearFormValues: PropTypes.bool,
  values: PropTypes.shape({
    body: PropTypes.string.isRequired,
    attachments: PropTypes.array.isRequired,
  }),
};

ReplyForm.defaultProps = {
  placeholder: 'Post a reply...',
  loading: false,
  isFocused: false,
  onCancel: null,
  values: {
    body: '',
    attachments: [],
  },
  clearFormValues: false,
};

export default withErrorHandler(ReplyForm);

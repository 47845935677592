import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Topic from './Topic';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import './topicCategoriesStyles.scss';
import TextInput from '../../common/inputs/text';
import TopicModal from './TopicModal';

const TopicCategory = ({
  topicCategory: { name: topicCategoryName, topics: initialTopics },
  links: { viewTopicCategories, createTopic, viewFeedbackFormTypes },
  topicAttachmentsConfig,
  csrfToken,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [topics, setTopics] = useState(initialTopics);
  const [filteredTopics, setFilteredTopics] = useState(topics);
  const [activeTopicId, setActiveTopicId] = useState(topics[0]?.id || null);
  const [questionFilterText, setQuestionFilterText] = useState('');

  useEffect(() => {
    if (!questionFilterText) {
      setFilteredTopics(topics);
    } else {
      const topicsToList = topics.filter(({ question }) =>
        question.toLowerCase().includes(questionFilterText.toLowerCase())
      );
      setFilteredTopics(topicsToList);
    }
  }, [topics, questionFilterText]);

  const closeModal = () => setIsModalOpen(false);

  const afterDelete = deletedTopicId => {
    const availableTopics = topics.filter(topic => topic.id !== deletedTopicId);
    setTopics(availableTopics);
  };

  const afterUpdate = updatedTopic => {
    const availableTopics = topics.map(topic =>
      topic.id === updatedTopic.id ? updatedTopic : topic
    );
    setTopics(availableTopics);
  };

  const handleSetActiveId = id => setActiveTopicId(id);

  return (
    <div className="my-4">
      <div className="mb-2 font-14 font-weight-normal">
        <a href={viewFeedbackFormTypes} className="kt-link text-dark">
          Feedback Form Types /
        </a>
        <a href={viewTopicCategories} className="kt-link text-dark ml-2">
          All Categories /
        </a>
        <span className="text-dark ml-2">{topicCategoryName}</span>
      </div>
      <div className="d-flex flex-wrap justify-content-between align-items-center">
        <h3 className="category-name">{`Knowledge Base - ${topicCategoryName}`}</h3>
        <button
          className="app-btn-primary m-2"
          onClick={() => setIsModalOpen(true)}
        >
          Add New Question
        </button>
      </div>
      {isEmpty(topics) ? (
        <h4 className="my-3">No Questions Added Yet!</h4>
      ) : (
        <Fragment>
          <div className="my-4">
            <TextInput
              name="questionFilter"
              placeholder="Search Questions..."
              value={questionFilterText}
              onInputChange={({ target: { value } }) =>
                setQuestionFilterText(value)
              }
            />
          </div>
          <div className="topic-list">
            {isEmpty(filteredTopics) ? (
              <h4 className="my-3">No Questions Matching Search.</h4>
            ) : (
              filteredTopics.map(topic => (
                <Topic
                  key={topic.id}
                  topic={topic}
                  csrfToken={csrfToken}
                  afterDelete={afterDelete}
                  afterUpdate={afterUpdate}
                  isActive={activeTopicId === topic.id}
                  setActiveTopicId={handleSetActiveId}
                  topicAttachmentsConfig={topicAttachmentsConfig}
                />
              ))
            )}
          </div>
        </Fragment>
      )}
      {isModalOpen && (
        <TopicModal
          title="Add New Question and Answer"
          onModalClose={closeModal}
          link={createTopic}
          handleOnSuccess={({ data: { topic } }) => {
            setTopics(prevTopics => [topic, ...prevTopics]);
            setQuestionFilterText('');
          }}
          csrfToken={csrfToken}
          topicAttachmentsConfig={topicAttachmentsConfig}
        />
      )}
    </div>
  );
};

TopicCategory.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  topicCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string.isRequired,
        answer: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        links: PropTypes.shape({
          update: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    createTopic: PropTypes.string.isRequired,
    viewTopicCategories: PropTypes.string.isRequired,
    viewFeedbackFormTypes: PropTypes.string.isRequired,
  }).isRequired,
  topicAttachmentsConfig: PropTypes.shape({}).isRequired,
};

export default withErrorHandler(TopicCategory);

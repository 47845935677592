import React, { useCallback, useEffect, useMemo, useState } from "react";
import AsyncSelect from 'react-select/async';
import debounce from "lodash/debounce";

import Http from "../../common/Http";
import Select from "../../common/inputs/select";
import UsersProgress from './usersProgress';
import FeedbackFormProgress from './feedbackFormProgress';
import QuizProgress from "./quizProgress";
import CurriculumProgress from './curriculumProgress';

const TABS = {
  'USER_PROGRESS': 'User Progress',
  'FORM_RESPONSE': 'Form Response',
  'QUIZ': 'Quiz',
};

const CourseReporting = ({ csrfToken, curriculumsPath, coursesPath }) => {
  const [isCourseTabSelected, setCourseTabSelected] = useState(false);
  const [selectedCurriculum, setSelectedCurriculum] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCurriculumCourses, setSelectedCurriculumCourses] = useState([]);
  const [selectedTabKey, setSelectedTabKey] = useState('USER_PROGRESS');

  useEffect(() => {
    if (selectedCurriculum) {
      setSelectedCourse(null);

      new Http()
        .setToken(csrfToken)
        .get(`${coursesPath}?curriculum_id=${selectedCurriculum.id}`)
        .onSuccess(response => {
          const { courses = [] } = response.data;
          setSelectedCurriculumCourses(courses);
        })
        .onError(err => {
          console.log(err);
          setSelectedCurriculumCourses([]);
        })
        .exec();
    }
  }, [selectedCurriculum]);

  const selectCoursesTab = useCallback(() => {
    setCourseTabSelected(true);
    setSelectedCurriculum(null);
    setSelectedCourse(null);
  }, []);

  const selectCurriculumsTab = useCallback(() => {
    setCourseTabSelected(false);
    setSelectedCurriculum(null);
    setSelectedCourse(null);
  }, []);

  const loadCurriculums = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`${curriculumsPath}?keyword=${inputValue}`)
      .onSuccess(response => {
        const { curriculums = [] } = response.data;
        callback(curriculums);
      })
      .onError(err => {
        console.log(err);
        callback([]);
      })
      .exec();
  }, [csrfToken, curriculumsPath]);

  const loadCourses = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`${coursesPath}?keyword=${inputValue}&stand_alone_courses_only=true`)
      .onSuccess(response => {
        const { courses = [] } = response.data;
        callback(courses);
      })
      .onError(err => {
        console.log(err);
        callback([]);
      })
      .exec();
  }, [csrfToken, coursesPath]);

  const debouncedLoadCurriculums = useMemo(() => debounce(loadCurriculums, 500), [loadCurriculums]);
  const debouncedLoadCourses = useMemo(() => debounce(loadCourses, 500), [loadCourses]);

  const renderContent = () => {
    if ((!isCourseTabSelected && !selectedCurriculum) || (isCourseTabSelected && !selectedCourse)) {
      return null;
    }

    if (!isCourseTabSelected && selectedCurriculum && (!selectedCourse || !selectedCourse.value)) {
      return (
        <CurriculumProgress
          curriculum={selectedCurriculum}
          csrfToken={csrfToken}
        />
      );
    }

    if (!selectedTabKey) {
      return null;
    }

    if (selectedTabKey === 'FORM_RESPONSE') {
      return (
        <FeedbackFormProgress
          csrfToken={csrfToken}
          course={selectedCourse}
        />
      );
    } else if (selectedTabKey === 'QUIZ') {
      return (
        <QuizProgress
          csrfToken={csrfToken}
          course={selectedCourse}
        />
      );
    } else {
      return (
        <UsersProgress
          csrfToken={csrfToken}
          course={selectedCourse}
        />
      );
    }
  };

  return (
    <div className="kt-portlet course-reporting">
      <div className="kt-portlet__head-label">
        <h4 className="text-center mt-5">Course Reporting</h4>
      </div>

      <div className="kt-portlet__body">
        <ul className="learner-dashboard__tabs mb-3 mx-2 p-0">
          <li
            className={`text-capitalize cursor-pointer ${
              isCourseTabSelected ? '' : 'app-text-primary'
            }`}
            onClick={selectCurriculumsTab}
          >
            Curriculums
          </li>
          <li
            className={`text-capitalize cursor-pointer ${
              isCourseTabSelected ? 'app-text-primary' : ''
            }`}
            onClick={selectCoursesTab}
          >
            Courses
          </li>
        </ul>

        {
          isCourseTabSelected
            ?
              <div className="mt-2 course-selector-container">
                <AsyncSelect
                  key="course-selector"
                  name="course-selector"
                  placeholder="Search for a Course"
                  noOptionsMessage={() => "No courses found"}
                  loadOptions={debouncedLoadCourses}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  onChange={setSelectedCourse}
                  defaultOptions
                  cacheOptions
                  isClearable
                />
              </div>
            :
              (
                <div className="mt-2 course-selector-container">
                  <AsyncSelect
                    key="curriculum-selector"
                    name="curriculum-selector"
                    placeholder="Search for a Curriculum"
                    noOptionsMessage={() => "No curriculums found"}
                    loadOptions={debouncedLoadCurriculums}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    onChange={setSelectedCurriculum}
                    defaultOptions
                    cacheOptions
                    isClearable
                  />

                  <div className="pt-4" />

                  {
                    selectedCurriculum && (
                      <Select
                        name="course-selected"
                        options={map(selectedCurriculumCourses, course => ({
                          ...course,
                          label: course.name,
                          value: course.id,
                        }))}
                        value={selectedCourse?.id}
                        onChange={setSelectedCourse}
                        placeholder="Select Course"
                        className="mt-2 mt-lg-0"
                        optionIdentifier="value"
                      />
                    )
                  }
                </div>
              )
        }

        {
          (!isCourseTabSelected && selectedCurriculum && selectedCourse?.value) || (isCourseTabSelected && selectedCourse)
            ?
              (
                <ul className="report-tabs mb-3 mx-2 p-0">
                  {
                    Object.keys(TABS).map((tabKey) => (
                      <li
                        key={tabKey}
                        className={`text-capitalize cursor-pointer ${tabKey === selectedTabKey ? 'app-text-primary' : ''}`}
                        onClick={() => setSelectedTabKey(tabKey)}
                      >
                        {TABS[tabKey]}
                      </li>
                    ))
                  }
                </ul>
              )
            : null
        }

        {renderContent()}
      </div>
    </div>
  );
};

export default CourseReporting;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormRow from '../common/presentational/formRow';
import Text from '../common/inputs/text';
import Spinner from '../common/presentational/spinner';
import { alertErrorNotifications, alertSuccessNotifications } from '../folders/utils';
import Http from '../common/Http';
import Select from '../common/inputs/select';
import MultiSelect from '../common/inputs/multiSelect';
import {
  renderOptions,
  convertArrayOfObjectsToHash,
  formatDateTime,
} from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

class CategoriesHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: props.categories,
      currentlyEditing: {},
      newCategory: {
        name: '',
        fileNameConvention: '',
        type: '',
        userIds: [],
      },
      newCategoryLoading: false,
      currentlyEditingLoading: false,
    };
    this.categoryTypesOptions = [];
    this.usersHash = convertArrayOfObjectsToHash(props.userList, 'id');
  }

  componentDidMount() {
    const { resourceTypeMappers } = this.props;
    _.map(_.keys(resourceTypeMappers), type => {
      this.categoryTypesOptions.push({
        label: _.startCase(type),
        value: resourceTypeMappers[type],
      });
    });
    this.setState(prevState => ({
      newCategory: {
        ...prevState.newCategory,
        type: 0,
      },
    }));
  }

  handleCategoryValueChange = (type, attributeName) => event => {
    if (event.target) {
      const { target } = event;
      this.setState(prevState => ({
        [type]: {
          ...prevState[type],
          [attributeName]: target.value,
        },
      }));
    }
  };

  handleCategoryAttributeSelect = (type, attributeName, attributeValue) => {
    this.setState(prevState => ({
      [type]: {
        ...prevState[type],
        [attributeName]: _.isEqual(attributeName, 'userIds')
          ? _.map(attributeValue, 'value')
          : attributeValue,
      },
    }));
  };

  handleDeleteCategory = async category => {
    const confirmDelete = confirm('Are you sure to delete this category?');
    if (confirmDelete) {
      const { csrfToken, links } = this.props;

      const Request = await new Http()
        .setToken(csrfToken)
        .onBegin(() => {
          this.setState({
            currentlyEditingLoading: true,
          });
        })
        .delete(category.links.delete)
        .onSuccess(response => {
          alertSuccessNotifications('Category Successfully deleted.');
          window.location.href = window.location.origin + links.categoriesList;
        })
        .onError(err => {
          this.setState({
            currentlyEditingLoading: false,
          });
        })
        .exec();
    }
  };

  validateFileNameConvention = categoryMode => {
    const { newCategory, currentlyEditing } = this.state;
    let fileName = '';
    if (categoryMode === 'newCategory') {
      fileName = _.trim(newCategory.fileNameConvention);
    } else {
      fileName = _.trim(currentlyEditing.fileNameConvention);
    }
    if (_.isEmpty(fileName)) {
      return false;
    } else {
      let bracketCount = 0;
      const stringArray = _.toArray(fileName);

      if (stringArray.length === 0) {
        return false;
      }

      for (let i = 0; i < stringArray.length; i += 1) {
        let char = stringArray[i];
        if (char === '[') {
          bracketCount += 1;

          let insideChars = '';
          let j = i + 1;

          while (
            stringArray[j] !== ']' &&
            stringArray[j] !== undefined &&
            j < stringArray.length
          ) {
            insideChars += stringArray[j];
            j += 1;
          }
          i = j - 1;

          if (!_.isEqual(insideChars, 'project')) {
            return false;
          }
        } else if (char === ']') {
          bracketCount -= 1;
        }
      }
      return bracketCount === 0;
    }
  };

  /**
   *
   * @param categoryMode is either newCategory or currentlyEditing, (variable name stored in state)
   * @returns {Function}
   */
  handleCategoryFormSubmit = categoryMode => {
    return async event => {
      event.preventDefault();
      const { allowCustomFileNameConvention } = this.props;

      if (
        allowCustomFileNameConvention &&
        !this.validateFileNameConvention(categoryMode)
      ) {
        alert('Category File Name is in invalid format');
        return;
      }

      if (!_.isNil(categoryMode)) {
        const { csrfToken, links } = this.props;
        const categoryObj = this.state[categoryMode];

        let Request = new Http()
          .setToken(csrfToken)
          .onBegin(() => {
            this.setState({
              [`${categoryMode}Loading`]: true,
            });
          })
          .setPostData({
            category: {
              name: _.trim(categoryObj.name),
              resource_type: categoryObj.type,
              user_ids: categoryObj.userIds,
              ...(allowCustomFileNameConvention && {
                file_name_convention: _.trim(categoryObj.fileNameConvention),
              }),
            },
          })
          .onSuccess(response => {
            let alertMessage = '';

            let newCategories = _.map(this.state.categories, cat => {
              if (cat.id === categoryObj.id) {
                return response.data.category || categoryObj;
              } else {
                return cat;
              }
            });

            if (categoryMode === 'newCategory') {
              if (response.data && response.data.category) {
                newCategories.unshift(response.data.category);
                alertMessage = 'Category created successfully.';
              }
            } else {
              alertMessage = 'Category updated successfully.';
            }

            this.setState({
              [`${categoryMode}Loading`]: false,
              [categoryMode]: {},
              categories: _.uniqBy(newCategories, 'id'),
            });

            if (!_.isEmpty(alertMessage)) {
              alertSuccessNotifications(alertMessage);
            }
          })
          .onError(err => {
            alertErrorNotifications(err);
            this.setState({
              [`${categoryMode}Loading`]: false,
            });
          });

        if (!_.isNil(categoryObj.id)) {
          Request = Request.put(categoryObj.links.update);
        } else {
          Request = Request.post(links.categoriesList);
        }

        await Request.exec();
      }
    };
  };

  setCurrentlyEditingCategory = (event, cat) => {
    event.preventDefault();
    this.setState({
      currentlyEditing: cat,
    });
  };

  handleCategoryEditCancel = event => {
    this.setState({
      currentlyEditing: {},
    });
  };

  getReadableUserNamesList = userIds => {
    if (_.isEmpty(userIds)) return '-';

    return _.map(_.pick(this.usersHash, userIds), 'name').join(', ');
  };

  renderAddNewCategoryForm = () => {
    const {
      userList,
      tenantTerms: { termUser },
      allowCustomFileNameConvention,
    } = this.props;
    const { newCategory, newCategoryLoading } = this.state;

    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Create New Category</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-section">
            <div className="kt-section__content">
              {newCategoryLoading && <Spinner />}
              {!newCategoryLoading && (
                <form onSubmit={this.handleCategoryFormSubmit('newCategory')}>
                  <FormRow label={'Name'} labelClassName={'col-md-2 text-right'}>
                    <div className="input-group">
                      <Text
                        placeholder={'Enter category name'}
                        className={'form-control'}
                        name={'newCategoryName'}
                        value={newCategory.name || ''}
                        numberAttributes={{
                          required: false,
                        }}
                        onInputChange={this.handleCategoryValueChange(
                          'newCategory',
                          'name'
                        )}
                      />
                    </div>
                  </FormRow>
                  {allowCustomFileNameConvention && (
                    <FormRow
                      label={'File Name Convention'}
                      labelClassName={'col-md-2 text-right'}
                    >
                      <div className="input-group">
                        <Text
                          className={'form-control'}
                          placeholder={'Enter file name convention'}
                          name={'fileNameConvention'}
                          value={newCategory.fileNameConvention || ''}
                          onInputChange={this.handleCategoryValueChange(
                            'newCategory',
                            'fileNameConvention'
                          )}
                        />
                      </div>
                      <br />
                      <span className="text-danger">
                        File name format must contain only "[project], A-Z, 0-9, @, _ , -"
                        <br />
                        <br />
                      </span>
                    </FormRow>
                  )}
                  <FormRow label={'Type'} labelClassName={'col-md-2 text-right'}>
                    <Select
                      name={'categoryType'}
                      options={this.categoryTypesOptions}
                      value={newCategory.type}
                      optionIdentifier="value"
                      isClearable={false}
                      placeholder={'Select a category type'}
                      onChange={({ value }) =>
                        this.handleCategoryAttributeSelect('newCategory', 'type', value)
                      }
                    />
                  </FormRow>
                  <FormRow label={termUser.plural} labelClassName={'col-md-2 text-right'}>
                    <MultiSelect
                      name={'users'}
                      options={renderOptions(userList)}
                      value={newCategory.userIds || []}
                      optionIdentifier="value"
                      placeholder={`Assign ${termUser.plural}`}
                      onChange={values =>
                        this.handleCategoryAttributeSelect(
                          'newCategory',
                          'userIds',
                          values
                        )
                      }
                    />
                  </FormRow>
                  <div className="row col-md-12 offset-md-4">
                    <input
                      type="submit"
                      className={'btn app-btn-primary'}
                      value="Add Category"
                    />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderCategoryTable = () => {
    const { allowCustomFileNameConvention } = this.props;
    const { categories } = this.state;
    return (
      <div className="dataTables_scroll table-responsive">
        <form onSubmit={this.handleCategoryFormSubmit('currentlyEditing')}>
          <table
            className="col-md-12 table table-striped- table-bordered table-hover dataTable"
            role="grid"
          >
            <thead>
              <tr role="row">
                <th width="200" rowSpan="1" colSpan="1">
                  Name
                </th>
                {allowCustomFileNameConvention && (
                  <th width="200" rowSpan="1" colSpan="1">
                    File Name Convention
                  </th>
                )}
                <th width="150" rowSpan="1" colSpan="1">
                  Type
                </th>
                <th width="300" rowSpan="1" colSpan="1">
                  Users
                </th>
                <th width="130" rowSpan="1" colSpan="1">
                  Updated At
                </th>
                <th
                  className="sorting_disabled"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Actions"
                  width="120"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {_.map(categories, (cat, index) => {
                return this.renderTableItem(cat, index);
              })}
            </tbody>
          </table>
        </form>
      </div>
    );
  };

  renderTableItem = (category, index) => {
    const {
      userList,
      tenantTerms: { termUser },
      allowCustomFileNameConvention,
    } = this.props;
    const { currentlyEditing } = this.state;

    if (currentlyEditing !== null && currentlyEditing.id === category.id) {
      return (
        <tr
          key={category.name + category.id}
          role="row"
          className={index % 2 === 0 ? 'even' : 'odd'}
        >
          <td>
            <Text
              className={'form-control'}
              name={'editCategoryName'}
              value={currentlyEditing.name || ''}
              numberAttributes={{
                required: false,
              }}
              onInputChange={this.handleCategoryValueChange('currentlyEditing', 'name')}
            />
          </td>
          {allowCustomFileNameConvention && (
            <td>
              <Text
                className={'form-control'}
                name={'editCategoryFileNameConvention'}
                value={currentlyEditing.fileNameConvention || ''}
                numberAttributes={{
                  required: false,
                }}
                onInputChange={this.handleCategoryValueChange(
                  'currentlyEditing',
                  'fileNameConvention'
                )}
              />
            </td>
          )}
          {/** Category type can be made editable on demand */}
          <td>{category.resourceType || '-'}</td>
          <td>
            <MultiSelect
              name={'users'}
              options={renderOptions(userList)}
              value={currentlyEditing.userIds || []}
              optionIdentifier="value"
              placeholder={`Assign ${termUser.plural}`}
              onChange={values =>
                this.handleCategoryAttributeSelect('currentlyEditing', 'userIds', values)
              }
            />
          </td>
          <td>&nbsp;</td>
          <td nowrap="true">
            <button type={'submit'} className="app-btn-primary btn-elevate btn-circle btn-icon" >
              <i className="fas fa-check"></i>
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type={'button'}
              onClick={this.handleCategoryEditCancel}
              className="btn-darkred btn btn-elevate btn-circle btn-icon"
            >
              <i className="fas fa-times"></i>
            </button>
          </td>
        </tr>
      );
    }

    return (
      <tr
        key={category.name + category.id}
        role="row"
        className={index % 2 === 0 ? 'even' : 'odd'}
      >
        <td>{category.name}</td>
        {allowCustomFileNameConvention && <td>{category.fileNameConvention || '-'}</td>}
        <td>{category.resourceType || '-'}</td>
        <td>{this.getReadableUserNamesList(category.userIds)}</td>
        <td>{formatDateTime({ date: category.updatedAt, formatTime: false })}</td>
        <td nowrap="true">
          <button
            type={'button'}
            onClick={ev => this.setCurrentlyEditingCategory(ev, category)}
            className="btn btn-outline-brand btn-elevate btn-circle btn-icon"
          >
            <i className="la la-edit" />
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            type={'button'}
            onClick={ev => this.handleDeleteCategory(category)}
            className="btn-outline-darkred btn-elevate btn-circle btn-icon"
          >
            <i className="la la-trash" />
          </button>
        </td>
      </tr>
    );
  };

  renderCategoryListSection = () => {
    const { currentlyEditingLoading } = this.state;
    return (
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Categories</h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="kt-section">
            <div className="kt-section__content">
              {!currentlyEditingLoading && this.renderCategoryTable()}
              {currentlyEditingLoading && <Spinner />}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.renderAddNewCategoryForm()}
        {this.renderCategoryListSection()}
      </React.Fragment>
    );
  }
}

CategoriesHome.propTypes = {
  categories: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    categoriesList: PropTypes.string.isRequired,
  }).isRequired,
  allowCustomFileNameConvention: PropTypes.bool.isRequired,
  resourceTypeMappers: PropTypes.object,
  userList: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(CategoriesHome);

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import MultiSelect from '../common/inputs/multiSelect';
import Select from '../common/inputs/select';
import Spinner from '../common/presentational/spinner';
import { SkeletonRow, SkeletonWrap } from '../SkeletonLoading/index';
import Http from '../common/Http';
import PaginationWrapper from '../hoc/PaginationWrapper';
import { renderOptions, formatDateTime } from '../common/utils';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

const EventsTable = props => {
  const [loading, setLoading] = React.useState(false);
  const [paginationObject, setPaginationObject] = React.useState({});
  const [eventsToShow, setEventsToShow] = React.useState([]);
  const filterOptions = React.useMemo(
    () => ({
      userList: props.userList,
      resourceList: props.resourceList,
    }),
    [props.resourceList, props.userList]
  );
  const [selectedFilters, setSelectedFilters] = React.useState({
    selectedUserIds: [],
    selectedResource: '',
    startDate: null,
    endDate: null,
  });
  const isVisitSession = React.useMemo(
    () => props.visit !== null,
    [props.visit]
  );

  const fetchData = React.useCallback(
    async (pageNum = 1) => {
      const {
        links: { getEvents: getEventsLink },
        csrfToken,
        visit,
      } = props;

      let newFilterOptions = {
        visit_id: isVisitSession ? visit.id : null,
        user_ids: selectedFilters['selectedUserIds'],
        resource: selectedFilters['selectedResource'],
        ...(!isNil(selectedFilters['startDate']) && {
          start_date:
            moment(selectedFilters['startDate']).format('DD/MM/YYYY') || '',
        }),
        ...(!isNil(selectedFilters['endDate']) && {
          end_date:
            moment(selectedFilters['endDate']).format('DD/MM/YYYY') || '',
        }),
      };

      await new Http()
        .onBegin(() => setLoading(true))
        .get(`${getEventsLink}`)
        .setQueryParams({
          page: pageNum,
          ...newFilterOptions,
        })
        .setToken(csrfToken)
        .onSuccess(res => {
          const { data } = res;
          setEventsToShow(data.events);
          setPaginationObject(data.meta.pagination);
          setLoading(false);
        })
        .onError(() => setLoading(false))
        .exec();
    },

    // whole props don't need to affect the callBack, So removed the props from depArray.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisitSession, selectedFilters]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getFilteredEvents = () => {
    fetchData();
  };

  const handleFilterSelect = attributeName => selectedValues => {
    let newSelectedFilters;

    if (attributeName === 'selectedResource') {
      // It is a single select type.
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: selectedValues.value,
      };
    } else if (attributeName.includes('Date')) {
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: selectedValues,
      };
    } else {
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: map(selectedValues, 'value'),
      };
    }

    setSelectedFilters(newSelectedFilters);
  };

  const renderFilters = () => {
    let selectedStartDate = null;
    let selectedEndDate = null;

    if (!isNil(selectedFilters.startDate)) {
      selectedStartDate = selectedFilters.startDate;
    }

    if (!isNil(selectedFilters.endDate)) {
      selectedEndDate = selectedFilters.endDate;
    }

    return (
      !isEmpty(filterOptions) && (
        <React.Fragment>
          <div className="form-group m-form__group row visit-gantt__filter-row d-flex justify-content-center ml-15 mr-15">
            {!isVisitSession && (
              <div className="col-lg-4">
                <MultiSelect
                  placeholder={'Select Users'}
                  options={renderOptions(filterOptions.userList)}
                  value={selectedFilters.selectedUserIds}
                  onChange={handleFilterSelect('selectedUserIds')}
                  optionIdentifier="value"
                />
              </div>
            )}
            <div className={`col-lg-${isVisitSession ? '6' : '4'}`}>
              <Select
                name="resources"
                options={map(filterOptions.resourceList, resource => ({
                  label: startCase(resource),
                  value: resource,
                }))}
                value={selectedFilters.selectedResource || ''}
                onChange={handleFilterSelect('selectedResource')}
                placeholder="Select Resources"
                className="mt-2 mt-lg-0"
                optionIdentifier="value"
              />
            </div>
            <div className={`col-lg-${isVisitSession ? '6' : '4'} d-lg-flex`}>
              <DatePicker
                isClearable={true}
                dropdownMode={'select'}
                selected={selectedStartDate}
                showTimeSelect={false}
                onChange={handleFilterSelect('startDate')}
                className={'form-control form-filter m-input mt-2 mt-lg-0'}
                placeholderText="From"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
              />
              <DatePicker
                isClearable={true}
                dropdownMode={'select'}
                selected={selectedEndDate}
                showTimeSelect={false}
                onChange={handleFilterSelect('endDate')}
                className={
                  'form-control form-filter m-input mt-2 mt-lg-0 ml-lg-2'
                }
                placeholderText="To"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
              />
            </div>
          </div>

          <div className="text-center">
            <button
              className="app-btn-primary m-btn--wide"
              onClick={getFilteredEvents}
            >
              Filter
            </button>
          </div>
        </React.Fragment>
      )
    );
  };

  const renderEventRow = (event, index) => (
    <tr key={index} style={{ height: 42 }}>
      <td className="td-110">
        <span className="pl-15">{event.deviceType}</span>
      </td>
      <td className="td-110">{event.browser}</td>
      <td>{event.name}</td>
      <td className="td-110">{event.userName}</td>
      <td className="td-150">{formatDateTime({ date: event.time })}</td>
    </tr>
  );

  const renderPagination = () => {
    if (!isEmpty(eventsToShow) && !isEmpty(paginationObject)) {
      return (
        <PaginationWrapper
          paginationObject={paginationObject}
          fetchData={fetchData}
        />
      );
    }
    return <br />;
  };

  if (loading) {
    return (
      <div>
        <SkeletonWrap>
          <SkeletonRow header />
          <br />
          <SkeletonRow rows={8} />
        </SkeletonWrap>
        <Spinner text="Please wait while we load your assets" />
      </div>
    );
  }

  return (
    <div className="kt-section">
      <div className="kt-section__content">
        {renderFilters()}
        {renderPagination()}
        <div className="table-responsive">
          <table className="table table-striped kt-table__row-equal-width">
            <thead>
              <tr>
                <th className="td-110">
                  <span className="pl-15">Device Type</span>
                </th>
                <th className="td-110">Browser</th>
                <th>Page</th>
                <th className="td-110">User</th>
                <th className="td-150">Date & Time</th>
              </tr>
            </thead>

            <tbody>
              {map(eventsToShow, (event, index) =>
                renderEventRow(event, index)
              )}
              {isEmpty(eventsToShow) && (
                <tr>
                  <td colSpan={7} className="p-4 text-center">
                    <h4>No event for the filters</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {renderPagination()}
      </div>
    </div>
  );
};

EventsTable.defaultProps = {
  visit: null,
  userList: [],
  resourceList: [],
};

EventsTable.propTypes = {
  visit: PropTypes.shape({
    id: PropTypes.number,
    userName: PropTypes.string,
    startedAt: PropTypes.string,
    landingPage: PropTypes.string,
    deviceType: PropTypes.string,
    browser: PropTypes.string,
    os: PropTypes.string,
  }),
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  userList: PropTypes.array,
  resourceList: PropTypes.array,
};

export default withErrorHandler(EventsTable);

import React, { createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import useFetchQueryParams from '../../../common/hooks/useFetchParams';

import AdminTabContextProvider from '../../../common/ContextProvider';

import AdminHeader from './AdminHeader';
import AdminRenderTabs from './AdminRenderTabs';
import TabContent from './AdminTabContent';

export const AdminTabContext = createContext();

const AdminTabs = ({ children, tabs }) => {
  const [activeTab, setActiveTab, getQueryParamStatus] =
    useFetchQueryParams(tabs);

  const redirectPrev = e => {
    e.preventDefault();

    getQueryParamStatus();
  };

  useEffect(() => {
    window.addEventListener('popstate', redirectPrev, false);
    return () => {
      window.removeEventListener('popstate', redirectPrev, false);
    };
  }, []);

  const value = useMemo(
    () => ({
      tabs: tabs,
      activeTab: activeTab,
      setActiveTab: setActiveTab,
    }),
    [tabs, activeTab, setActiveTab]
  );

  return (
    <AdminTabContextProvider ComponentContext={AdminTabContext} value={value}>
      <div className="new-theme-tabs-container">{children}</div>
    </AdminTabContextProvider>
  );
};

AdminTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AdminTabs.Header = AdminHeader;
AdminTabs.RenderTabs = AdminRenderTabs;
AdminTabs.TabsBody = TabContent;

export default AdminTabs;

import React from 'react';
import Select from './select';
import { customFilterOptions } from '../utils';
import { DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS } from '../constants';

const ProjectSelect = props => (
  <React.Fragment>
    <Select
      filterOption={customFilterOptions(DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS)}
      {...props}
    />
    {!props.disabled && <p className="kt-font-info">Search by Name or Address</p>}
  </React.Fragment>
);

export default ProjectSelect;

import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import AttachmentPreviewSlider from '../../common/AttachmentPreviewSlider';

// reverted the functional to class component.
// cb-react-form-builder package accepts the class component to access the ref, where the functional components throw ref issue which leads to data loss.
class CustomAttachmentSlider extends React.Component {
  render() {
    const { answerData, data } = this.props;
    const attachments = !isEmpty(answerData) ? answerData[data.field_name] : [];
    return <AttachmentPreviewSlider attachments={attachments} />;
  }
}

CustomAttachmentSlider.propTypes = {
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.shape({
    field_name: PropTypes.string,
  }),
};

export default CustomAttachmentSlider;

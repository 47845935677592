import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import filter from 'lodash/filter';

import './styles.scss';
import MultiSelect from '../../common/inputs/multiSelect';
import Spinner from '../../common/presentational/spinner';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../../common/constants';
import { formatDateTime, getDateInstance } from '../../common/utils';

class CourseEvaluator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterOptions: null,
      filteredResults: props.completedAssessmentItems,
      activeTab: 'all',
      selectedFilters: {
        userIds: [],
        courseIds: [],
        assessmentIds: [],
        endDate: null,
        startDate: null,
      },
      loading: false,
      enableFilterSubmit: false,
    };
  }

  componentDidMount() {
    this.getFilterOptions();
  }

  getLinkObject = tab => {
    if (isEmpty(tab)) return;

    const { links } = this.props;

    return {
      all: links,
      completed: links.finalized,
      rejected: links.rejected,
    }[tab];
  };

  getFilterOptions = () => {
    const { activeTab } = this.state;

    const linkObj = this.getLinkObject(activeTab);

    new Http(this)
      .setToken(this.props.csrfToken)
      .get(linkObj.evaluatorFilterOptions)
      .onSuccess(response => {
        this.setState({
          filterOptions: response.data.filter_options || null,
        });
      })
      .exec();
  };

  getFilteredResults = async () => {
    const { activeTab } = this.state;
    const { selectedFilters } = this.state;
    const linkObj = this.getLinkObject(activeTab);

    await new Http(this)
      .setToken(this.props.csrfToken)
      .setLoading()
      .get(linkObj.filterResults, {
        filter_options: {
          uploader_ids: map(selectedFilters.userIds, i => i.id) || [],
          course_ids: map(selectedFilters.courseIds, i => i.id) || [],
          assessment_ids: map(selectedFilters.assessmentIds, i => i.id) || [],
          uploaded_date_range: {
            start_date: selectedFilters.startDate || '',
            end_date: selectedFilters.endDate || '',
          },
        },
      })
      .onSuccess(response => {
        this.setState({
          loading: false,
          filteredResults:
            response.data.filtered_completed_assessment_items || null,
        });
      })
      .exec();
  };

  handleTabChange = tabName => () => {
    if (this.state.activeTab !== tabName) {
      this.setState(
        {
          selectedFilters: {
            userIds: [],
            courseIds: [],
            assessmentIds: [],
            endDate: null,
            startDate: null,
          },
          activeTab: tabName,
        },
        () => {
          this.getFilterOptions();
          this.getFilteredResults();
        }
      );
    }
  };

  handleOptionSelect = attributeName => value => {
    if (attributeName === 'startDate' || attributeName === 'endDate') {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          [attributeName]: isNil(value) ? null : value,
        },
        enableFilterSubmit: true,
      }));
    } else {
      this.setState(prevState => ({
        selectedFilters: {
          ...prevState.selectedFilters,
          [attributeName]: value || [],
        },
        enableFilterSubmit: true,
      }));
    }
  };

  renderTableHeaders = () => {
    const { tenantTerms } = this.props;
    const { termCourse, termAssignment, termUser } = tenantTerms;
    return (
      <tr role="row">
        <th>
          <span className="pl-15">{termUser.singular}</span>
        </th>
        <th>{termCourse.singular} Name</th>
        <th className="td-250">{termAssignment.singular} Name</th>
        <th className="td-280">Date Submitted</th>
        <th>&nbsp;</th>
      </tr>
    );
  };

  renderFilterHeaderRow = () => {
    const { tenantTerms } = this.props;
    const { termCourse, termUser, termAssignment } = tenantTerms;
    const { filterOptions, selectedFilters, enableFilterSubmit } = this.state;

    if (!isNil(filterOptions) && !isEmpty(filterOptions)) {
      const userNameOptions = filterOptions.uploaders;
      const courseNameOptions = filterOptions.courses;

      const selectedCourseIds = selectedFilters.courseIds.map(c => c.id);

      let assessmentNameOptions = filterOptions.assessments;

      if (!isEmpty(selectedFilters.courseIds)) {
        assessmentNameOptions = filter(assessmentNameOptions, asses =>
          selectedCourseIds.includes(asses.course_id)
        );
      }

      const dateRange = filterOptions.uploaded_date_range;

      let selectedStartDate = null;
      let selectedEndDate = null;

      if (!isNil(selectedFilters.startDate)) {
        selectedStartDate = selectedFilters.startDate;
      }

      if (!isNil(selectedFilters.endDate)) {
        selectedEndDate = selectedFilters.endDate;
      }

      return (
        <tr className="filter">
          <th>
            <div className="pl-15">
              <MultiSelect
                placeholder={`Select ${termUser.singular}`}
                options={userNameOptions}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                optionIdentifier="id"
                value={selectedFilters.userIds}
                onChange={this.handleOptionSelect('userIds')}
              />
            </div>
          </th>
          <th>
            <MultiSelect
              placeholder={`Select ${termCourse.singular}`}
              options={courseNameOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              optionIdentifier="id"
              value={selectedFilters.courseIds}
              onChange={this.handleOptionSelect('courseIds')}
            />
          </th>
          <th className="td-250">
            <MultiSelect
              placeholder={`Select ${termAssignment.singular}`}
              options={assessmentNameOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              optionIdentifier="id"
              value={selectedFilters.assessmentIds}
              onChange={this.handleOptionSelect('assessmentIds')}
            />
          </th>
          <th className="position-relative td-280 d-flex">
            <DatePicker
              dropdownMode={'select'}
              selected={selectedStartDate}
              showTimeSelect={false}
              onChange={this.handleOptionSelect('startDate')}
              minDate={getDateInstance(dateRange.start_date)}
              maxDate={getDateInstance(dateRange.end_date)}
              className={'form-control form-filter mb-1'}
              placeholderText="From"
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
            />
            <div className="mx-1"></div>
            <DatePicker
              dropdownMode={'select'}
              selected={selectedEndDate}
              showTimeSelect={false}
              onChange={this.handleOptionSelect('endDate')}
              minDate={getDateInstance(dateRange.start_date)}
              maxDate={getDateInstance(dateRange.end_date)}
              className={'form-control form-filter'}
              placeholderText="To"
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
            />
          </th>
          <th className="text-center td-80">
            <button
              disabled={!enableFilterSubmit}
              onClick={this.getFilteredResults}
              type="button"
              className="btn btn-success"
            >
              Filter
            </button>
          </th>
        </tr>
      );
    }
    return null;
  };

  renderItemRow = item => {
    const reviewUrl = item.links.review;

    return (
      <tr key={item.id}>
        <td>
          <div className="pl-15">{item.user.name}</div>
        </td>
        <td>{item.course.name}</td>
        <td className="td-250 text-truncate">{item.assessment.name}</td>
        <td className="td-280">
          {formatDateTime({ date: item.uploaded_date, formatTime: false })}
        </td>
        <td className="td-80 text-center">
          <a
            href={reviewUrl}
            target="_blank"
            className="app-btn-primary"
            rel="noreferrer"
          >
            Review
          </a>
        </td>
      </tr>
    );
  };

  renderPortletHeader = () => {
    const { activeTab } = this.state;
    const { tenantTerms } = this.props;

    const { termCourse, termSubmission } = tenantTerms;

    return (
      <div className="kt-portlet__head flex-column flex-sm-row">
        <div className="kt-portlet__head-label pt-3 pt-sm-0 pb-2 pb-sm-0">
          <h3 className="kt-portlet__head-title">
            {`${termCourse.plural} - ${termSubmission.plural}`}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <ul
            className="nav nav-tabs nav-tabs-bold nav-tabs-line   nav-tabs-line-right nav-tabs-line-brand"
            role="tablist"
          >
            <li className="nav-item">
              <button
                onClick={this.handleTabChange('all')}
                className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                data-toggle="tab"
                role="tab"
                aria-selected={activeTab === 'all'}
              >
                Under Review
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={this.handleTabChange('completed')}
                className={`nav-link ${
                  activeTab === 'completed' ? 'active' : ''
                }`}
                data-toggle="tab"
                role="tab"
                aria-selected={activeTab === 'completed'}
              >
                Completed
              </button>
            </li>
            <li className="nav-item">
              <button
                onClick={this.handleTabChange('rejected')}
                className={`nav-link ${
                  activeTab === 'rejected' ? 'active' : ''
                }`}
                data-toggle="tab"
                role="tab"
                aria-selected={activeTab === 'rejected'}
              >
                Rejected
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  render() {
    const { filteredResults, loading } = this.state;

    const resultsToShow = filteredResults;

    return (
      <div className="kt-portlet kt-portlet--tabs">
        {this.renderPortletHeader()}
        <div className="kt-portlet__body plr-0 py-0">
          <div
            style={{
              overflow: 'auto',
            }}
          >
            {loading && <Spinner />}
            <div className="table-responsive">
              <table
                className="table table-striped kt-table__row-equal-width mb-0"
                role="grid"
              >
                <thead>
                  {this.renderTableHeaders()}
                  {this.renderFilterHeaderRow()}
                </thead>

                <tbody>
                  {map(resultsToShow, (item, idx) => {
                    return this.renderItemRow(item, idx);
                  })}
                  {isEmpty(resultsToShow) && (
                    <tr>
                      <td colSpan={5}>
                        <div className="mt-20 mb-20 d-flex align-items-center justify-content-center">
                          <h2 className="d-none d-sm-block">No results matching the filters.</h2>
                          <h3 className="d-sm-none">No results matching the filters.</h3>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CourseEvaluator.propTypes = {
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    evaluatorFilterOptions: PropTypes.string.isRequired,
    filterResults: PropTypes.string.isRequired,
    finalized: PropTypes.shape({
      evaluatorFilterOptions: PropTypes.string.isRequired,
      filterResults: PropTypes.string.isRequired,
    }),
    rejected: PropTypes.shape({
      evaluatorFilterOptions: PropTypes.string.isRequired,
      filterResults: PropTypes.string.isRequired,
    }),
  }),

  completedAssessmentItems: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default withErrorHandler(CourseEvaluator);

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import PortletBlocker from '../common/presentational/PortletBlocker';
import SubmitButton from '../common/presentational/submitButton';
import ParticipantSelectWithList from './../courses/ParticipantSelectWithList';
import CourseSelect from './CourseSelect';

const CurriculumRetakeForm = ({
  learnerList,
  courseList,
  links = {},
  curriculumId,
  token,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedCourseUserIds, setSelectedCourseUserIds] = useState([]);
  const [selectedCourseIds, setSelectedCourseIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleCurriculumRetakeSubmit = async e => {
    e.preventDefault();
    const proceed = confirm(
      'Current progress will be erased for the selected people when they retake the Curriculum. Are you sure ?'
    );

    if (proceed) {
      await new Http()
        .setToken(token)
        .onBegin(() => setLoading(true))
        .doesRedirect(true)
        .post(links.resetCurriculumCompletion, {
          id: curriculumId,
          curriculum: {
            user_ids: selectedUserIds,
          },
        })
        .onSuccess(() => {
          setLoading(false);
          window.location.href = links.curriculumShow;
        })
        .onError(err => {
          alertErrorNotifications(err || 'Something went wrong!');
          setLoading(false);
        })
        .exec();
    }
  };

  const handleCourseRetakeSubmit = async e => {
    e.preventDefault();
    const proceed = confirm(
      'Current progress will be erased for the selected people when they retake the courses. Are you sure ?'
    );

    if (proceed) {
      await new Http()
        .setToken(token)
        .onBegin(() => setLoading(true))
        .doesRedirect(true)
        .post(links.resetCurriculumCompletion, {
          id: curriculumId,
          curriculum: {
            course_ids: selectedCourseIds,
            user_ids: selectedCourseUserIds,
          },
        })
        .onSuccess(() => {
          setLoading(false);
          window.location.href = links.curriculumShow;
        })
        .onError(err => {
          alertErrorNotifications(err || 'Something went wrong!');
          setLoading(false);
        })
        .exec();
    }
  };

  return (
    <PortletBlocker loading={loading}>
      <div>
        <form
          onSubmit={handleCurriculumRetakeSubmit}
          className="kt-form kt-form--label-right kt-form--fit"
        >
          <ParticipantSelectWithList
            onChange={participantIds => setSelectedUserIds(participantIds)}
            participantList={learnerList}
            selectedUsers={selectedUserIds}
            participantListLabel={'People'}
            headerText={'Retake Curriculum'}
            participantLabel={'People'}
            showList={false}
            mapUserIdWithList
          />

          <div className="kt-section">
            <div className="form-group kt-form__group">
              <SubmitButton value="Reset Progress" />
            </div>
          </div>
        </form>
      </div>

      <br />
      <br />
      <div className="kt-separator kt-separator--dashed"></div>

      <div>
        <form
          onSubmit={handleCourseRetakeSubmit}
          className="kt-form kt-form--label-right kt-form--fit"
        >
          <CourseSelect
            onChange={courseIds => setSelectedCourseIds(courseIds)}
            courseList={courseList}
            selectedCourses={selectedCourseIds}
            headerText={'Retake Courses In Curriculum'}
            courseLabel={'Courses'}
          />

          <ParticipantSelectWithList
            onChange={participantIds =>
              setSelectedCourseUserIds(participantIds)
            }
            participantList={learnerList}
            selectedUsers={selectedCourseUserIds}
            participantListLabel={'People'}
            participantLabel={'People'}
            showHeaderText={false}
            showList={false}
          />

          <div className="kt-section">
            <div className="form-group kt-form__group">
              <SubmitButton value="Reset Progress" />
            </div>
          </div>
        </form>
      </div>
    </PortletBlocker>
  );
};

CurriculumRetakeForm.propTypes = {
  curriculumId: PropTypes.number.isRequired,
  learnerList: PropTypes.array.isRequired,
  courseList: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  links: PropTypes.shape({
    resetCurriculumCompletion: PropTypes.string.isRequired,
    curriculumShow: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CurriculumRetakeForm);

import React from 'react';
import PropTypes from 'prop-types';

import AlertDialog from './AlertDialog';
import ClearAlertDialog from './ClearAlertDialog';

import { ALERT_TIMEOUTS, REACT_FLASH_CONTAINER_WINDOW_REF_KEY } from '../constants';

class AlertMessagesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: props.messages,
    };

    window[this.props.componentRefKey] = this;
  }

  componentDidMount() {
    // ALertMessagesContainer Can be use in multiple components at same Time.
    // So, Always provide latest reference through `window[this.props.componentRefKey]`
    window[this.props.componentRefKey] = this;
  }

  componentWillUnmount() {
    window[this.props.componentRefKey] = null;
  }

  // NOTE:
  // public API used across all react components
  /**
   * addMessage to AlertContainer, publicly available to other components
   * via window[REACT_FLASH_CONTAINER_WINDOW_REF_KEY] object
   *
   * @param message error message, {string|object}
   * if string is passed, type is mandatory to pass,
   * if object is passed its shape will be {id, payload, type}
   *
   * @param type {ALERT_CLASSES}, will be use to create a error object
   * from string error message and type as specified
   */
  addMessage(message, type = null) {
    // type is null, message passed is already in format
    // no conversion/mapping required
    if (_.isNil(type)) {
      const updatedMessages = [...this.state.messages, message];
      this.setState({ messages: updatedMessages });
    } else {
      // map the message
      const mappedMessage = {
        id: Math.random()
          .toString(36)
          .substring(7),
        payload: message,
        type: type,
      };
      const updatedMessages = [...this.state.messages, mappedMessage];
      this.setState({ messages: updatedMessages });
    }
  }

  removeMessage(message) {
    const removableMessageIndex = _.indexOf(this.state.messages, message);

    const splicedMessages = _.filter(this.state.messages, (_message, messageIndex) => {
      return !(messageIndex === removableMessageIndex);
    });

    this.setState({ messages: splicedMessages });
  }

  clearMessages = () => {
    this.setState({
      messages: [],
    });
  };

  isMultipleMessagesPresent = () => _.size(this.state.messages) > 1;

  getAlertTimeoutValue = alertType => ALERT_TIMEOUTS[alertType];

  render() {
    const alertDialogs = _.map(this.state.messages, (message, messageIndex) => {
      const mappedMessage = {
        id:
          message.id ||
          Math.random()
            .toString(36)
            .substring(7),
        payload: message.payload || message,
        type: message.type || this.props.notificationType,
      };

      return (
        <AlertDialog
          key={mappedMessage.id}
          message={mappedMessage}
          onClose={() => this.removeMessage(message)}
          timeout={this.getAlertTimeoutValue(mappedMessage.type)}
          dialogPosition={messageIndex}
        />
      );
    });

    return (
      <div
        style={{
          margin: 'auto',
          position: 'fixed',
          zIndex: '99999',
          bottom: '30px',
          left: '50%',
          height: 'auto',
          transform: 'translateX(-50%)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="d-flex flex-column-reverse">{alertDialogs}</div>
        <div className="text-center">
          {this.isMultipleMessagesPresent() && (
            <ClearAlertDialog onClick={this.clearMessages} />
          )}
        </div>
      </div>
    );
  }
}

AlertMessagesContainer.defaultProps = {
  notificationType: 'error',
  componentRefKey: REACT_FLASH_CONTAINER_WINDOW_REF_KEY,
};

AlertMessagesContainer.propTypes = {
  notificationType: PropTypes.oneOf(['alert', 'notice', 'error', 'success']),
  messages: PropTypes.array.isRequired,
  componentRefKey: PropTypes.string.isRequired,
};

export default AlertMessagesContainer;

import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({
  name,
  value,
  handleOnChange,
  className,
  labelClassName,
  showLabel,
  label,
  disabled,
  style,
}) => (
  <div className="kt-checkbox-inline" style={style}>
    <label
      className={`kt-checkbox ${labelClassName} ${
        disabled ? 'kt-checkbox--disabled' : ''
      }`}
    >
      <input
        disabled={disabled}
        type="checkbox"
        className={className}
        checked={value}
        name={name}
        onChange={handleOnChange}
      />
      {showLabel && typeof label === 'function' ? label() : label}
      <span />
    </label>
  </div>
);

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  value: PropTypes.bool,
  handleOnChange: PropTypes.func.isRequired,
  showLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
};

CheckBox.defaultProps = {
  disabled: false,
  value: false,
  style: {},
  className: '',
  labelClassName: '',
  showLabel: false,
};

export default CheckBox;

import React from "react";
import Header from "./Header";
import Body from "./Body";

const CertificationStatus = ({ links, csrfToken }) => {
  return (
    <div className="kt-portlet kt-portlet--mobile">
      <Header links={links} />
      <Body csrfToken={csrfToken} links={links} />
    </div>
  )
}

export default CertificationStatus;
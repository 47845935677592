import React, { Fragment, useCallback } from 'react';
import PropTypes from 'prop-types';
import Replies from '../replies/ReplyList';
import { getReadableTimeDiffFromNowUntilAWeek } from '../../common/utils';
import UserInfo from '../UserInfo';
import Notification from '../Notification';
import Breadcrumbs from '../../common/Breadcrumbs';
import '../forum.scss';
import Attachments from '../../common/AttachmentPreview/Attachments';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const TopicShow = ({
  topic,
  csrfToken,
  currentUserId,
  isCourseAdmin,
  links,
  tenantTerms,
  notificationsSubscribed,
  replyAttachmentsConfig,
}) => {
  const {
    title,
    body,
    poster,
    recentActivityAt,
    postedAt,
    category: { name: categoryName },
    editedAt,
    attachments,
  } = topic;

  const getForumLink = useCallback((link) => {
    const queryParams = window.location.search;
    return !!queryParams ? `${link}${queryParams}` : link;
  }, []);

  return (
    <Fragment>
      <Breadcrumbs
        data={[
          {
            label: tenantTerms?.termCourse?.singular,
            link: links?.viewCourse,
          },
          {
            label: 'Forum',
            link: getForumLink(links?.viewForum),
          },
          {
            label: title,
            link: null,
          },
        ]}
      />
      <div className="kt-portlet forum-container text-black-50 ">
        <div className="border-bottom p-4">
          <span className="badge badge-primary">{categoryName}</span>
          <h1 className="font-24 mt-3 mb-2" style={{ whiteSpace: 'pre-line' }}>
            {title}
          </h1>
          <p className="font-weight-normal font-14 description topic-body mt-2">{body}</p>
          {editedAt && (
            <div className="pr-2 font-12 mb-2 text-black-50 font-weight-normal">
              Edited:
              <span className="pl-2 kt-font-info">
                {getReadableTimeDiffFromNowUntilAWeek(editedAt)}
              </span>
            </div>
          )}
          {recentActivityAt && (
            <div className="font-12 mb-2 text-black-50 font-weight-normal">
              Last Activity:
              <span className="pl-2 kt-font-info">
                {getReadableTimeDiffFromNowUntilAWeek(recentActivityAt)}
              </span>
            </div>
          )}

          {!_.isEmpty(attachments) && (
            <div className="my-4">
              <Attachments attachments={attachments} />
            </div>
          )}

          <div className="d-flex justify-content-between">
            <Notification
              isSubscribed={notificationsSubscribed}
              link={links.toggleNotifications}
              csrfToken={csrfToken}
            />
            <UserInfo
              avatar={poster.avatar}
              name={poster.name}
              date={getReadableTimeDiffFromNowUntilAWeek(postedAt)}
              className="justify-content-end my-2"
            />
          </div>
        </div>
        <div className="kt-portlet__body">
          <Replies
            topicInfo={{
              body,
              recentActivityAt,
              postedAt,
            }}
            links={links}
            currentUserId={currentUserId}
            csrfToken={csrfToken}
            replyAttachmentsConfig={replyAttachmentsConfig}
            isCourseAdmin={isCourseAdmin}
          />
        </div>
      </div>
    </Fragment>
  );
};

TopicShow.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    body: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    postedAt: PropTypes.string.isRequired,
    poster: PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
    }),
    recentActivityAt: PropTypes.string,
    repliesCount: PropTypes.number.isRequired,
  }),
  isCourseAdmin: PropTypes.bool.isRequired,
  links: PropTypes.shape({
    postReply: PropTypes.string.isRequired,
    viewReplies: PropTypes.string.isRequired,
    toggleNotifications: PropTypes.string.isRequired,
  }).isRequired,
  notificationsSubscribed: PropTypes.bool.isRequired,
  replyAttachmentsConfig: PropTypes.shape({}).isRequired,
};

export default withErrorHandler(TopicShow);

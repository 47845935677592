import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import FolderModal from '../filestack/modal';
import Text from '../common/inputs/text';
import FormRow from '../common/presentational/formRow';
import SubmitButton from '../common/presentational/submitButton';
import AssetManager from './../filestack/AssetManager';
import Spinner from '../common/presentational/spinner';
import ShareModalContainer from './common/components/ShareModalContainer';

import FolderContainer from './common/components/FolderContainer';
import AssetsContainer from './common/components/FolderContainer/AssetsContainer';
import FolderSidebar from './common/components/FolderContainer/FolderSidebar';
import FolderHeader from './common/components/FolderHeader';
import Breadcrumbs from './common/components/Breadcrumbs';

import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import {
  userAccessTypes,
  getUserAccessTypeOptions,
  getAssetTypeFromLocationHash,
  myFolderUrlProvider,
  alertSuccessNotifications,
  alertErrorNotifications,
} from './utils';
import {
  ASSET_TYPE_HASH_PARAM,
  ASSET_MIMETYPE_FILTER_PREDICATES,
  DEFAULT_ASSET_FILTER_TYPE,
  NOTIFICATION_MESSAGES_HELPER,
} from './common/constants';

import './folderModalStyle.scss';
import './common/styles/folder.scss';
import { withErrorHandler } from '../hoc/withErrorHandler';

class MyFolder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      parentFolder: this.props.folder,
      folders: this.props.folders,
      existingAssets: this.props.existingAssets,
      displayAssetType: DEFAULT_ASSET_FILTER_TYPE,
      inputFolderName: '',
      inputFolderId: null,
      assets: [],
      loading: false,
      showFolderModal: false,
      showShareModal: false,
      shareListData: {
        fileId: null,
        fileType: null,
        selectedUsers: [],
        selectedUsersRole: _.head(getUserAccessTypeOptions()),
        postableUsersSharingData: [],
        notifyUsersByEmail: false,
      },
    };
  }

  componentDidMount = async () => {
    this.setDisplayAssetTypeFromLocationHash();

    window.onpopstate = _event => {
      this.setDisplayAssetTypeFromLocationHash();
    };
  };

  setDisplayAssetTypeFromLocationHash = () => {
    const displayAssetType = getAssetTypeFromLocationHash();

    this.setState({
      displayAssetType,
    });
  };

  getFoldersCollection = () => [this.state.parentFolder, ...this.state.folders];

  getFilesCollection = () => this.state.existingAssets;

  getContent = ({ fileId, fileType }) => {
    const searchableCollection =
      fileType === 'Folder' ? this.getFoldersCollection() : this.getFilesCollection();

    return _.find(searchableCollection, {
      id: fileId,
    });
  };

  getContentName = ({ fileId, fileType }) => {
    const content = this.getContent({ fileId, fileType });

    return _.get(content, 'name') || _.get(content, 'filename') || fileType;
  };

  isParentFolder = folderId => this.props.folder.id === folderId;

  handleFolderModalState = () => {
    this.setState(({ showFolderModal }, _props) => ({
      showFolderModal: !!!showFolderModal,
      ...(!!!showFolderModal == false && { inputFolderName: '' }),
      ...(!!!showFolderModal == false && { inputFolderId: null }),
    }));
  };

  openShareModal = () => {
    this.setState({
      showShareModal: true,
    });
  };

  closeShareModal = () => {
    this.setState({
      showShareModal: false,
    });
  };

  showSpinner = () => {
    this.setState({
      loading: true,
    });
  };

  hideSpinner = () => {
    this.setState({
      loading: false,
    });
  };

  handleAssetFilterClick = ({ assetType }) => {
    window.location.hash = `${ASSET_TYPE_HASH_PARAM}${assetType}`;
  };

  getFilteredAssets = ({ assetType }) => {
    const assetTypeFilterPredicate = ASSET_MIMETYPE_FILTER_PREDICATES[assetType];

    const filteredAssets = _.filter(this.state.existingAssets, asset =>
      assetTypeFilterPredicate(asset.mimetype)
    );

    return filteredAssets;
  };

  canUserUpdateContent = ({ _fileId, _fileType }) => {
    // All contents in My Folder View will be owned by user
    // So, user can always update any content inside this view
    return true;
  };

  fetchSharedUsers = async (fileId, fileType) => {
    const sharedUsersEndpointConfig = {
      method: 'get',
      url: '/folders/shared_users',
      params: {
        file_id: fileId,
        file_type: fileType,
      },
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': this.props.token,
      },
    };

    try {
      const response = await axios.request(sharedUsersEndpointConfig);

      return {
        response,
        error: false,
      };
    } catch (e) {
      return {
        response: e.response,
        error: true,
      };
    }
  };

  handleShareFormClick = async ({ fileId, fileType }) => {
    this.setState({
      shareListData: {
        ...this.state.shareListData,
        fileId,
        fileType,
      },
    });

    this.showSpinner();
    const { response, error } = await this.fetchSharedUsers(fileId, fileType);

    if (!!!error) {
      const usersSharingData = _.map(response.data.sharedUsers, sharedUserData => ({
        // TODO: DataType keep in check with backend (or) type cast to common type
        id: _.toInteger(sharedUserData.consumer_id),
        email: sharedUserData.consumer_email,
        access_level: sharedUserData.access_level,
      }));

      this.setState({
        shareListData: {
          ...this.state.shareListData,
          postableUsersSharingData: usersSharingData,
        },
      });

      this.hideSpinner();
      this.openShareModal();
    } else {
      this.hideSpinner();
      alertErrorNotifications(response.data.errors);
    }
  };

  handleShareFormChange = value => {
    this.setState({
      shareListData: {
        ...this.state.shareListData,
        selectedUsers: value,
      },
    });
  };

  handleNewRoleSelect = value => {
    this.setState({
      shareListData: {
        ...this.state.shareListData,
        selectedUsersRole: value,
      },
    });
  };

  handleSelectedUsers = event => {
    event.preventDefault();

    const {
      postableUsersSharingData,
      selectedUsers,
      selectedUsersRole,
    } = this.state.shareListData;

    const selectedUsersData = _.map(selectedUsers, selectedUserOption => ({
      id: selectedUserOption.value,
      email: selectedUserOption.label,
      access_level: _.get(selectedUsersRole, 'value', _.head(userAccessTypes)),
    }));

    const postableSelectedUsersData = _.concat(
      postableUsersSharingData,
      selectedUsersData
    );

    this.setState({
      shareListData: {
        ...this.state.shareListData,
        postableUsersSharingData: postableSelectedUsersData,
        selectedUsers: [],
        selectedUsersRole: _.head(getUserAccessTypeOptions()),
      },
    });
  };

  handleSelectedUserAccessChange = ({ userId, selectedOption }) => {
    const { postableUsersSharingData } = this.state.shareListData;

    const accessChangedPostableUsersSharingData = _.map(
      postableUsersSharingData,
      shareableUserData =>
        shareableUserData.id !== userId
          ? shareableUserData
          : {
              ...shareableUserData,
              access_level: selectedOption.value,
            }
    );

    this.setState({
      shareListData: {
        ...this.state.shareListData,
        postableUsersSharingData: accessChangedPostableUsersSharingData,
      },
    });
  };

  handleSelectedUserRemoval = ({ userId }) => {
    const { postableUsersSharingData } = this.state.shareListData;

    const updatedPostableUsersSharingData = _.filter(
      postableUsersSharingData,
      shareableUserData => shareableUserData.id !== userId
    );

    this.setState({
      shareListData: {
        ...this.state.shareListData,
        postableUsersSharingData: updatedPostableUsersSharingData,
      },
    });
  };

  handleNotifyUsersChange = event => {
    this.setState({
      shareListData: {
        ...this.state.shareListData,
        notifyUsersByEmail: event.target.checked,
      },
    });
  };

  handleShareFormSubmit = async event => {
    event.preventDefault();

    /* create endpoint config based on the action like - creating new folder/editing the existing folder*/
    const endpointConfig = {
      method: 'post',
      url: '/folders/populate_share_list',
    };

    const { fileId, fileType } = this.state.shareListData;
    const sharedContentName = this.getContentName({ fileId, fileType });

    const apiPostData = {
      file_id: this.state.shareListData.fileId,
      file_type: this.state.shareListData.fileType,
      shareable_user_data: this.state.shareListData.postableUsersSharingData,
      notify_users_by_email: this.state.shareListData.notifyUsersByEmail,
    };

    this.setState({ loading: true });

    try {
      const response = await axios({
        ...endpointConfig,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': this.props.token,
        },
        data: apiPostData,
      });

      this.setState({
        loading: false,
        shareListData: {
          selectedUsersRole: _.head(getUserAccessTypeOptions()),
        },
      });

      // TODO: find file entity name to display in notification
      const shareListUpdateErrorsCollection = _.castArray(
        _.get(response.data, 'errors', 'Internal Server Error')
      );

      if (_.isEmpty(shareListUpdateErrorsCollection)) {
        const shareListUpdateSuccessMessage = NOTIFICATION_MESSAGES_HELPER[
          'shareListUpdate'
        ]({ contentIdentifier: sharedContentName });
        alertSuccessNotifications(shareListUpdateSuccessMessage);
      } else {
        alertErrorNotifications(shareListUpdateErrorsCollection);
      }
    } catch (e) {
      this.setState({ loading: false });

      const shareListUpdateFailureMessage = _.get(
        e.response,
        'data.errors',
        'Internal Server Error'
      );
      alertErrorNotifications(shareListUpdateFailureMessage);
    }

    this.closeShareModal();
  };

  handleFolderEdit = id => {
    const editableFolders = this.getFoldersCollection();

    this.setState({
      inputFolderId: id,
      inputFolderName: _.find(editableFolders, { id: id })['name'],
      showFolderModal: true,
    });
  };

  handleFolderNameInputChange = event => {
    this.setState({
      inputFolderName: event.target.value,
    });
  };

  handleFolderFormSubmit = async event => {
    event.preventDefault();

    const { folders, inputFolderId, inputFolderName, parentFolder } = this.state;

    const submittedFolderIsParent = this.isParentFolder(inputFolderId);

    const endpointConfig = _.isNil(inputFolderId)
      ? {
          method: 'post',
          url: '/folders',
        }
      : {
          method: 'put',
          url: `/folders/${inputFolderId}`,
        };

    const formData = {
      name: inputFolderName,
      ...(!submittedFolderIsParent && { parent_id: parentFolder['id'] }),
    };

    this.setState({ loading: true });

    try {
      const response = await axios({
        ...endpointConfig,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': this.props.token,
        },
        data: formData,
      });

      if (response.status == 200) {
        if (submittedFolderIsParent) {
          const updatedParentFolder = response.data['folder'];

          this.setState({
            parentFolder: updatedParentFolder,
          });
        } else {
          /* caluculate new folders to set in state */
          const newFolders = _.isNil(inputFolderId)
            ? /* if new folder created, add it to existing state */
              _.flatten([...folders, response.data['folder']])
            : /* if existing folder updated - update specific folder in the state */
              _.map(folders, folder =>
                folder.id === inputFolderId ? response.data['folder'] : folder
              );

          this.setState({
            folders: newFolders,
          });
        }

        const notificationMessage = _.isNil(inputFolderId)
          ? NOTIFICATION_MESSAGES_HELPER['folderCreation']({
              folderName: inputFolderName,
            })
          : NOTIFICATION_MESSAGES_HELPER['folderUpdate']({ folderName: inputFolderName });

        this.setState({
          inputFolderName: '',
          inputFolderId: null,
          showFolderModal: false,
          loading: false,
        });

        alertSuccessNotifications(notificationMessage);
      }
    } catch (error) {
      if (error.response.status == 400) {
        this.setState({
          inputFolderName: '',
          inputFolderId: null,
          showFolderModal: false,
          loading: false,
        });
      }

      this.hideSpinner();
      const folderApiError = _.get(
        error.response,
        'data.errors',
        'Internal Server Error'
      );
      alertErrorNotifications(folderApiError);
    }
  };

  getFolderFormTitle = () => {
    if (_.isNil(this.state.inputFolderId)) {
      return 'Create Folder';
    }

    const editableFolderName = this.getContentName({
      fileId: this.state.inputFolderId,
      fileType: 'Folder',
    });

    return `Update Folder - ${editableFolderName}`;
  };

  getShareFormTitle = ({ fileId, fileType }) => {
    const title = 'Share With Users';

    if (_.isNil(fileId) || _.isNil(fileType)) {
      return title;
    }

    const contentName = this.getContentName({ fileId, fileType });
    return `${title} - ${contentName}`;
  };

  onFileUploadSuccess = ({ filesUploaded }) => {
    this.uploadAssetToParentFolder(filesUploaded);
  };

  onFileUploadFailure = error => {
    alertErrorNotifications(error);
  };

  onFileChange = (assets, existingAssets) => {
    this.setState({
      assets: assets,
      existingAssets: existingAssets,
    });
  };

  uploadAssetToParentFolder = async filesUploaded => {
    const { token } = this.props;
    const { parentFolder, existingAssets } = this.state;

    const assetAttributes = getAssetAttributes(filesUploaded);
    const addAssetsUrl = `/folders/${parentFolder['id']}/add_assets`;

    this.setState({ loading: true });

    try {
      const { data: response } = await axios({
        method: 'post',
        url: addAssetsUrl,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': token,
        },
        data: {
          assets: assetAttributes,
        },
      });

      const newExistingAssets = _.flatten([...existingAssets, response.assets]);

      this.setState({
        loading: false,
        existingAssets: newExistingAssets,
        assets: [],
      });

      const assetUploadErrors = _.castArray(response.errors);

      if (_.isEmpty(assetUploadErrors)) {
        const fileUploadSuccessMessage = NOTIFICATION_MESSAGES_HELPER['fileUpload']();
        alertSuccessNotifications(fileUploadSuccessMessage);
      } else {
        alertErrorNotifications(assetUploadErrors);
      }
    } catch (error) {
      this.setState({ loading: false });
      alertErrorNotifications(error);
    }
  };

  syncedBreadcrumbsFolders = () => {
    const { breadcrumbsFolders } = this.props;
    const { parentFolder: updatedParentFolder } = this.state;

    const grandParentFolders = _.dropRight(breadcrumbsFolders);
    const mainParentFolder = _.last(breadcrumbsFolders);

    const updatedBreadcrumbsFolders = [
      ...grandParentFolders,
      {
        ...mainParentFolder,
        name: updatedParentFolder.name,
      },
    ];

    return updatedBreadcrumbsFolders;
  };

  render() {
    const {
      folders,
      showFolderModal,
      assets,
      loading,
      showShareModal,
      shareListData,
      displayAssetType,
      parentFolder,
    } = this.state;
    const {
      token,
      shareableUsers,
      tenantDisplayTerms,
      appFoldersDisplayConfig,
    } = this.props;
    const syncedBreadcrumbsFolders = this.syncedBreadcrumbsFolders();
    const filteredAssets = this.getFilteredAssets({ assetType: displayAssetType });

    return (
      <React.Fragment>
        {loading && <Spinner />}

        <div
          className={classNames({
            'non-active-background': loading,
          })}
        >
          {/* My Folder View */}
          <FolderHeader
            title={'My Folder'}
            showFolderUpdateActions
            onFolderRenameClick={() => this.handleFolderEdit(parentFolder.id)}
            onFolderShareClick={() =>
              this.handleShareFormClick({
                fileId: parentFolder.id,
                fileType: 'Folder',
              })
            }
          >
            <Breadcrumbs
              homeUrl={'/folders'}
              folderItems={syncedBreadcrumbsFolders}
              urlProvider={myFolderUrlProvider}
            />
          </FolderHeader>

          <FolderContainer
            folderSidebar={
              <FolderSidebar
                fileProps={{
                  handlers: {
                    onFileUploadSuccess: this.onFileUploadSuccess,
                    onFileUploadFailure: this.onFileUploadFailure,
                    onAssetFilterClick: this.handleAssetFilterClick,
                  },
                }}
                folderProps={{
                  handlers: {
                    onNewFolderClick: this.handleFolderModalState,
                    onRenameClick: this.handleFolderEdit,
                    onShareClick: folderId =>
                      this.handleShareFormClick({
                        fileId: folderId,
                        fileType: 'Folder',
                      }),
                  },
                  predicates: {
                    updateEnabler: folderId =>
                      this.canUserUpdateContent({
                        fileId: folderId,
                        fileType: 'Folder',
                      }),
                  },
                  data: {
                    // sending folders as individual collections
                    // as url will be different for each collection
                    myFolders: folders,
                    appFoldersConfig: {
                      displayTerms: tenantDisplayTerms,
                      foldersDisplayConfig: appFoldersDisplayConfig,
                    },
                  },
                }}
              />
            }
            assetsHolder={
              <AssetsContainer>
                {!_.isEmpty(filteredAssets) ? (
                  <AssetManager
                    showUploader={false}
                    assets={assets}
                    existingAssets={filteredAssets}
                    csrfToken={token}
                    onFileChange={this.onFileChange}
                    showFileShareOption
                    shareEnablerPredicate={assetId =>
                      this.canUserUpdateContent({
                        fileId: assetId,
                        fileType: 'Asset',
                      })
                    }
                    onFileShareClick={this.handleShareFormClick}
                  />
                ) : (
                  <span>No Assets Found For Selected File Type</span>
                )}
              </AssetsContainer>
            }
          />

          {/* My Folder View - End */}

          {showFolderModal && (
            <FolderModal
              title={this.getFolderFormTitle()}
              onClose={this.handleFolderModalState}
              renderContent={() => (
                <form
                  onSubmit={this.handleFolderFormSubmit}
                  className="kt-form kt-form--label-right"
                >
                  <FormRow label="Name">
                    <Text
                      name={'inputFolderName'}
                      value={this.state.inputFolderName}
                      onInputChange={this.handleFolderNameInputChange}
                    />
                  </FormRow>
                  <SubmitButton />
                </form>
              )}
            />
          )}

          {showShareModal && (
            <ShareModalContainer
              title={this.getShareFormTitle({
                fileId: shareListData.fileId,
                fileType: shareListData.fileType,
              })}
              selectedUsers={shareListData.selectedUsers}
              postableUsersSharingData={shareListData.postableUsersSharingData}
              selectedUsersRole={shareListData.selectedUsersRole}
              notifyUsersByEmail={shareListData.notifyUsersByEmail}
              shareableUsers={shareableUsers}
              closeModalHandler={this.closeShareModal}
              handleSelectedUserAccessChange={this.handleSelectedUserAccessChange}
              handleSelectedUserRemoval={this.handleSelectedUserRemoval}
              handleShareFormSubmit={this.handleShareFormSubmit}
              handleShareFormChange={this.handleShareFormChange}
              handleNewRoleSelect={this.handleNewRoleSelect}
              handleNotifyUsersChange={this.handleNotifyUsersChange}
              handleSelectedUsers={this.handleSelectedUsers}
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}

MyFolder.propTypes = {
  folder: PropTypes.object.isRequired,
  folders: PropTypes.array,
  existingAssets: PropTypes.array,
  token: PropTypes.string.isRequired,
  shareableUsers: PropTypes.array,
  breadcrumbsFolders: PropTypes.array,
  tenantDisplayTerms: PropTypes.object.isRequired,
  appFoldersDisplayConfig: PropTypes.object.isRequired,
};

MyFolder.defaultProps = {
  folders: [],
  existingAssets: [],
  shareableUsers: [],
  breadcrumbsFolders: [],
  tenantDisplayTerms: {},
  appFoldersDisplayConfig: {},
};

export default withErrorHandler(MyFolder);

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import InitialsAvatar from '../common/Avatar';

const UserInfo = ({ avatar, name, date, className }) => {
  return (
    <div className={`profile-info d-flex align-items-center ${className}`}>
      <InitialsAvatar
        url={avatar}
        className="profile-info-image"
        name={name}
        size="medium"
      />
      <div className="profile-info-name ml-2">
        <b>{name || '-'}</b>
        <div className="profile-info-date">{date}</div>
      </div>
    </div>
  );
};

UserInfo.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string,
  date: PropTypes.string,
  className: PropTypes.string,
};

UserInfo.defaultProps = {
  avatar: null,
  name: '-',
  date: null,
};

export default memo(UserInfo);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import Toggle from '../common/inputs/Toggle';
import ReactDraftWysiwygEditor from '../wysiwyg_editor/index';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';

import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Text from '../common/inputs/text';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  constructOptionsFromRailsSelect,
} from '../common/utils';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import SelectInput from '../common/inputs/select';
import AssetManager from '../filestack/AssetManager';

class CertificationsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      headerImage: [],
      existingHeaderImage: _.compact(_.castArray(props.headerImage)),
      [props.fieldsPrefix]: Object.assign({}, props.attributes, {
        description: EditorState.createEmpty(), // Make it empty and later pass in the data
      }),
      enableCertificationWebhook: props.enableCertificationWebhook,
      certificateType: props.certificateType,
    };
  }

  componentDidMount() {
    const {
      attributes: { description },
    } = this.props;
    // if description is present do conversion and save and display in editor
    try {
      if (description) {
        const contentBlock = htmlToDraft(description);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setModelAttributeState('description', editorState);
        }
      }
    } catch (e) {
      //
    }
  }

  setModelAttributeState = (fieldName, value) => {
    const updatedModelAttributes = {
      ...this.state[this.props.fieldsPrefix],
      [fieldName]: value,
    };

    this.setState({
      [this.props.fieldsPrefix]: updatedModelAttributes,
    });
  };

  onEditorStateChange = (editorState) => {
    this.setModelAttributeState('description', editorState);
  };

  getModelAttributeValue = (attribute) =>
    this.state[this.props.fieldsPrefix][attribute] || '';

  handleModelAttributeChange = (attributeName = '') => {
    return (event) => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, (option) => option.value)
          : event.value;

        this.setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        this.setModelAttributeState(targetName, targetValue);
      }
    };
  };

  handleToggle = (value, type) => {
    this.setModelAttributeState(type, value);
  };

  handleEnableCertificationWebhookToggle = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      enableCertificationWebhook: value,
    }));
  };

  handleCertificationTypeChange = (event) => {
    const { value } = event.target;
    this.setState({ certificateType: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { certificationId, csrfToken, entityId, entityType, links } = this.props;
    const { headerImage, enableCertificationWebhook, certificateType } = this.state;

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );

    // description is not in html format, convert it
    let description = draftToHtml(
      convertToRaw(updatedModelAttributes.description.getCurrentContent())
    );
    description = description.replace('<p></p>\n', '');
    updatedModelAttributes.description = description;

    updatedModelAttributes.certification_webhook = {
      enabled: enableCertificationWebhook,
      certificate_type: certificateType
    };

    updatedModelAttributes.entity = {
      id: entityId,
      type: entityType
    };

    let Request = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedModelAttributes,
          header_image: headerImage[0] || {},
        },
      })
      .onSuccess((_response) => {
        window.location.href = links.certificationEndpoint;
      });

    if (!_.isNil(certificationId)) {
      Request = Request.patch(links.certificationEndpoint);
    } else {
      Request = Request.post(links.certificationEndpoint);
    }

    await Request.exec();
  };

  handleDelete = async () => {
    const { csrfToken, links } = this.props;

    if (window.confirm('Are you sure you want to delete this certification?')) {
      let Request = new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .useAlerts()
        .doesRedirect(true)
        .onSuccess((_response) => {
          window.location.href = links.certificationEndpoint;
        });

      Request = Request.delete(links.certificationEndpoint);

      await Request.exec();
    }
  };

  onHeaderImageChange = (headerImage, existingHeaderImage) => {
    this.setState({
      headerImage: headerImage || [],
      existingHeaderImage: existingHeaderImage || [],
    });
  };

  render() {
    const { loading, headerImage, existingHeaderImage, enableCertificationWebhook, certificateType } = this.state;

    const {
      csrfToken,
      links,
      expiryTimeWindowOptions,
      headerImageOptions,
      certificationWebhooksFeatureEnabled,
    } = this.props;
    const { directUploadUrl } = links;

    if (loading) {
      return <Spinner />;
    }

    const showHeaderImageUploader = _.isEmpty(existingHeaderImage) && _.isEmpty(headerImage);

    const issueDateValue =
      this.getModelAttributeValue('requireIssueDate') === ''
        ? false
        : this.getModelAttributeValue('requireIssueDate');
    const expireDateValue =
      this.getModelAttributeValue('requireExpireDate') === ''
        ? false
        : this.getModelAttributeValue('requireExpireDate');

    return (
      <div className={'kt-portlet__body mb-5 pb-5'}>
        <form
          encType="multipart/form-data"
          className="kt-form kt-form--label-right "
          onSubmit={this.handleSubmit}
        >
          {!_.isNil(this.props.certificationId) && (
            <div className="text-right mb-3" style={{ marginTop: "-35px" }}>
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.handleDelete}
              >
                  <i className="kt-nav__link-icon flaticon-delete"></i>
                Delete Certificate
              </button>
            </div>
          )}
          <div className="kt-section kt-section--first">
            <FormRow label={'Name'}>
              <Text
                numberAttributes={{
                  required: true,
                }}
                name={'name'}
                value={this.getModelAttributeValue('name')}
                onInputChange={this.handleModelAttributeChange('name')}
              />
            </FormRow>

            <FormRow label={'Description'}>
              <ReactDraftWysiwygEditor
                editorState={this.getModelAttributeValue('description')}
                onEditorStateChange={this.onEditorStateChange}
                token={csrfToken}
                directUploadUrl={directUploadUrl}
              />
            </FormRow>

            <FormRow label="Image">
              <AssetManager
                showLabel={false}
                csrfToken={csrfToken}
                filestackOptions={headerImageOptions}
                existingAssets={existingHeaderImage || []}
                assets={headerImage || []}
                onFileChange={this.onHeaderImageChange}
                showUploader={showHeaderImageUploader}
              />
            </FormRow>
          </div>
          <div className="kt-separator kt-separator--dashed" />
          <div className="kt-section kt-section--second mb-30">
            <FormRow label={'Require Issue Date'}>
              <Toggle
                name={'requireIssueDate'}
                onClick={(val) => this.handleToggle(val, 'requireIssueDate')}
                on={<span>Yes</span>}
                off={<span>No</span>}
                size="sm"
                height={30}
                recalculateOnResize
                offstyle="default"
                active={issueDateValue}
              />
            </FormRow>

            <FormRow label={'Require Expiration Date'}>
              <Toggle
                name={'requireExpireDate'}
                onClick={(val) => this.handleToggle(val, 'requireExpireDate')}
                on={<span>Yes</span>}
                off={<span>No</span>}
                size="sm"
                height={30}
                recalculateOnResize
                offstyle="default"
                active={expireDateValue}
              />
            </FormRow>
            <FormRow label={'Expires In'}>
              <SelectInput
                placeholder="Select Validity Period"
                options={constructOptionsFromRailsSelect(expiryTimeWindowOptions)}
                value={this.getModelAttributeValue('expiresIn')}
                optionIdentifier="value"
                onChange={({ value }) =>
                  this.setModelAttributeState('expiresIn', value)
                }
              />
            </FormRow>
            {certificationWebhooksFeatureEnabled && (
              <>
                <FormRow label={'Enable Certificate Webhook'}>
                  <Toggle
                    name={'enableCertificationWebhook'}
                    onClick={(val) => this.handleEnableCertificationWebhookToggle(val)}
                    on={<span>Yes</span>}
                    off={<span>No</span>}
                    size="sm"
                    height={30}
                    recalculateOnResize
                    offstyle="default"
                    active={enableCertificationWebhook}
                  />
                </FormRow>
                {enableCertificationWebhook && (
                  <FormRow label={'Certification Type'}>
                    <Text
                      name={'certificateType'}
                      type="number"
                      value={certificateType}
                      onInputChange={this.handleCertificationTypeChange}
                    />
                  </FormRow>
                )}
              </>
            )}
          </div>

          <div className="kt-section">
            <div className="col-lg-12 text-center">
              <CancelButton />
              <SubmitButton
                buttonText={_.isNil(this.props.certificationId) ? 'Create Certificate' : 'Update Certificate'}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CertificationsForm.defaultProps = {
  fieldsPrefix: 'certification',
  attributes: {},
};

CertificationsForm.propTypes = {
  fieldsPrefix: PropTypes.string,

  csrfToken: PropTypes.string.isRequired,
  certificationId: PropTypes.number,

  attributes: PropTypes.object.isRequired,

  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    certificationEndpoint: PropTypes.string,
    directUploadUrl: PropTypes.string.isRequired,
  }),
  certificationWebhooksFeatureEnabled: PropTypes.bool,
  enableCertificationWebhook: PropTypes.bool,
  certificateType: PropTypes.number,
};

export default withErrorHandler(CertificationsForm);

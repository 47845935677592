import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Modal from '../filestack/modal';
import QRCodeWrapper from '../common/QRCodeWrapper';
import { withErrorHandler } from '../hoc/withErrorHandler';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { formatDateTime } from '../common/utils';

const FeedbackFormsTable = props => {
  const [loading, setLoading] = useState(false);
  const [showQRCodeForForm, setShowQRCodeForForm] = useState(null);

  const openQRCodeModal = ({ name, publicUrl }) => {
    setShowQRCodeForForm({
      name,
      publicUrl,
    });
  };

  const closeQRCodeModal = () => setShowQRCodeForForm(null);

  const copyLinkToClipBoard = link => {
    // Ref: https://stackoverflow.com/a/30810322

    const textArea = document.createElement('textarea');
    textArea.value = link;
    textArea.style.position = 'fixed'; //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      console.log(err);
    }

    document.body.removeChild(textArea);
  };

  const handleFormDelete = async ({ deleteLink, canDelete, formTypeName }) => {
    const { csrfToken } = props;

    if (!canDelete) {
      alert(`
        Cannot be deleted when assigned as a query form for a knowledge base topic category!

        Please assign a different query form for ${formTypeName} in feedback form types settings to be able to delete this form!
      `);
      return;
    }

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(true)
      .delete(deleteLink)
      .onSuccess(({ data }) => {
        setLoading(false);
        window.location.href = data.meta.redirection_url;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const renderFormsIndexSection = () => {
    const { feedbackForms, links, showDraftState } = props;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center px-3 mb-3">
          <div>
            <h4 className="text-dark">Forms</h4>
          </div>
          <div>
            <a
              href={links.newForm}
              data-method="post"
              className="pull-right btn app-btn-primary"
            >
              New Form
            </a>
          </div>
        </div>
        {!isNil(showQRCodeForForm) && (
          <Modal
            title={showQRCodeForForm.name}
            onClose={closeQRCodeModal}
            renderContent={() => (
              <div className="mb-30 d-flex flex-column align-items-center">
                <p>Scan below QR code to open public form.</p>
                <QRCodeWrapper value={showQRCodeForForm.publicUrl} />
              </div>
            )}
          />
        )}
        <div className="mt-2" style={{ overflowX: 'auto' }}>
          {!isEmpty(feedbackForms) ? (
            <div className="table-responsive">
              <table className="custom-table-v2">
                <thead>
                  <tr>
                    <th className="td-280">
                      <span className="pl-15">Name</span>
                    </th>
                    <th className="td-180">Created On</th>
                    <th className="td-180">Updated On</th>
                    <th className="text-center td-80">Public Views</th>
                    <th className="text-center td-50">Responses</th>
                    <th className="text-center td-50">Results</th>
                    <th className="text-center td-50"></th>
                  </tr>
                </thead>

                <tbody>
                  {map(
                    feedbackForms,
                    ({
                      id,
                      name,
                      createdAt,
                      updatedAt,
                      publicVisits,
                      userResponses,
                      hasKnowledgeBaseTopicCategory,
                      formTypeName,
                      drafted,
                      published,
                      links: formLinks,
                    }) => (
                      <tr key={id}>
                        <td>
                          <span className="pl-15">
                            <a
                              className="table-link"
                              href={formLinks.summaryTab}
                            >
                              {name}
                            </a>
                            {showDraftState && drafted && (
                              <span className="mx-2 kt-badge kt-badge--inline kt-badge--rounded app-badge-primary kt-font-bold">
                                Draft
                              </span>
                            )}
                          </span>
                        </td>
                        <td>{formatDateTime({ date: createdAt })}</td>
                        <td>{formatDateTime({ date: updatedAt })}</td>
                        <td className="text-center">{publicVisits}</td>
                        <td className="text-center">
                          <a
                            className="table-link"
                            href={formLinks.responsesTab}
                          >
                            {userResponses}
                          </a>
                        </td>
                        <td className="text-center">
                          <a className="table-link" href={formLinks.summaryTab}>
                            <i className="fa fa-chart-bar font-16" />
                          </a>
                        </td>

                        <td className="text-center">
                          <div className="">
                            <button
                              type="button"
                              className="btn-sm btn-icon app-btn-hover-secondary btn-circle"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="la la-ellipsis-h"></i>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              x-placement="bottom-start"
                            >
                              <a
                                href={formLinks.preview}
                                className="dropdown-item"
                              >
                                Preview
                              </a>
                              {published && (
                                <Fragment>
                                  <div
                                    className="dropdown-item cursor-pointer"
                                    onClick={() =>
                                      openQRCodeModal({
                                        name: name,
                                        publicUrl: formLinks.publicUrl,
                                      })
                                    }
                                  >
                                    QR code
                                  </div>
                                  <a
                                    href={formLinks.public}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="dropdown-item"
                                  >
                                    Open Public Form
                                  </a>
                                  <div
                                    className="dropdown-item cursor-pointer"
                                    onClick={() =>
                                      copyLinkToClipBoard(formLinks.publicUrl)
                                    }
                                  >
                                    Copy Link
                                  </div>
                                </Fragment>
                              )}
                              <a
                                href={formLinks.edit}
                                className="dropdown-item"
                              >
                                Edit
                              </a>
                              <div
                                onClick={() =>
                                  handleFormDelete({
                                    deleteLink: formLinks.delete,
                                    canDelete: !hasKnowledgeBaseTopicCategory,
                                    formTypeName,
                                  })
                                }
                                className="dropdown-item cursor-pointer text-danger"
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className="text-center text-black-50"
              style={{ marginTop: '80px', marginBottom: '80px' }}
            >
              <i className="far fa-newspaper" style={{ fontSize: '70px' }}></i>
              <h4 className="no_submissions">
                There are no forms at this time.
              </h4>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div className="new-theme-tabs-container">
      {loading && <Spinner />}
      {renderFormsIndexSection()}
    </div>
  );
};

FeedbackFormsTable.propTypes = {
  feedbackForms: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    newForm: PropTypes.string.isRequired,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
  showDraftState: PropTypes.bool,
};

FeedbackFormsTable.defaultProps = {
  showDraftState: false,
};

export default withErrorHandler(FeedbackFormsTable);

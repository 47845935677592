import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import './style.scss';

// Max 4 steps for better responsiveness

// Props
// steps: array of obj - [{ name: 'example name', iconClassName: 'fontAwesome icon class name', id: 1 }]
// activeCount: number - to set active state of step
// onStepChange: function - callback func with step index

// Note: Maintain steps Id in consecutive numbers

const FormStep = ({
  steps,
  activeId,
  onStepChange,
  startingStepId,
  endingStepId,
}) => {
  const [activeStep, setActiveStep] = useState(activeId);
  const countRef = useRef([activeId]);

  const [stepsId] = useState(steps.map(s => s.id));

  useEffect(() => {
    countRef.current = uniq([...countRef.current, activeId]);
    setActiveStep(activeId);
  }, [activeId]);

  const getCurrentActiveStepPosition = () => stepsId.indexOf(activeId);

  // Next step form navigation
  const nextStep = () => {
    const position = getCurrentActiveStepPosition();
    onStepChange(steps[position + 1]['id']);
  };

  // Previous step form navigation
  const previousStep = () => {
    const position = getCurrentActiveStepPosition();
    onStepChange(steps[position - 1]['id']);
  };

  return (
    <div>
      <div className="form-steps-container d-flex justify-content-center align-items-baseline mb-3">
        {steps.map(step => {
          const isStepClickable = countRef?.current?.includes(step?.id);

          return (
            <div
              key={step?.id}
              className={`step-container${
                activeStep >= step?.id ? ' selected' : ''
              }${isStepClickable ? ' cursor-pointer' : ' cursor-not-allowed'}`}
              onClick={() => isStepClickable && onStepChange(step?.id)}
            >
              <div className="step flex-center">
                <div className="step-icon">
                  <i className={step?.iconClassName || 'fab fa-wpforms'}></i>
                </div>
              </div>
              <div className="step-name">{step.name}</div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between">
        <div>
          {activeId !== startingStepId && (
            <button className="app-btn-outline-primary" onClick={previousStep}>
              <i className="fas fa-chevron-left mr-1"></i>
              Previous
            </button>
          )}
        </div>
        {activeId !== endingStepId && (
          <button className="app-btn-outline-primary" onClick={nextStep}>
            Next
            <i className="fas fa-chevron-right ml-2"></i>
          </button>
        )}
      </div>
    </div>
  );
};

FormStep.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      iconClassName: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
  activeId: PropTypes.number,
  startingStepId: PropTypes.number,
  endingStepId: PropTypes.number,
  onStepChange: PropTypes.func,
};

FormStep.defaultProps = {
  activeId: 1,
};

export default FormStep;

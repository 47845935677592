import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({
  onInputChange,
  className,
  placeholder,
  value,
  name,
  rows,
  maxLength,
  required,
  disabled,
  reference,
}) => (
  <textarea
    className={className}
    placeholder={placeholder}
    value={value}
    name={name}
    onChange={onInputChange}
    rows={rows}
    maxLength={maxLength}
    required={required}
    disabled={disabled}
    ref={reference}
  />
);

TextArea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  // ref PropTypes Ref: https://stackoverflow.com/a/51127130/10208226
  reference: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLTextAreaElement) }),
  ]),
};

TextArea.defaultProps = {
  className: 'form-control',
  value: '',
  placeholder: '',
  rows: 8,
  maxLength: null,
  required: false,
  disabled: false,
  reference: null,
};

export default TextArea;

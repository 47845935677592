import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import forEach from 'lodash/forEach';
import isEqual from 'lodash/isEqual';
import lowerCase from 'lodash/lowerCase';

import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import SelectInput from '../common/inputs/select';
import { GoogleCharts } from 'google-charts';
import {
  convertArrayOfObjectsToHash,
  PERIOD_FILTER_OPTIONS,
  constructMultiSelectOptions,
} from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { impressionHeatMapReducer } from './formsReducers';
import 'react-virtualized/styles.css';
import { getHeatMapFilterOptionsQueryParams } from './utils';

const IMPRESSION_MARKER_MAP = Object.freeze({
  Positive: 'green',
  Negative: 'red',
  Neutral: 'yellow',
});

const MAP_ICON_BASE_URL = 'http://maps.google.com/mapfiles/ms/icons/';

const HEAT_MAP_INITIAL_STATE = {
  impressionMapData: [],
  projects: {},
  projectImpressions: {},
  projectCategories: [],
  loading: false,
  metaDataLoading: false,
  filterOptions: {
    selectedResponseChannels: null,
    selectedProjectCategories: null,
    selectedResponsePeriod: null,
  },
};

const ImpressionHeatMap = props => {
  const [heatMapState, dispatchHeatMapActions] = useReducer(
    impressionHeatMapReducer,
    HEAT_MAP_INITIAL_STATE
  );

  const loadMap = mapData => {
    const {
      mapKey,
      tenantTerms: { termProject },
    } = props;

    const drawChart = () => {
      // Standard google charts functionality is available as GoogleCharts.api after load
      const data = GoogleCharts.api.visualization.arrayToDataTable([
        [
          'Latitude',
          'Longitude',
          `(Impression) ${termProject.singular}`,
          'Marker',
        ],
        ...mapData,
      ]);

      const options = {
        mapType: 'terrain',
        showTooltip: true,
        showInfoWindow: true,
        useMapTypeControl: true,
        enableScrollWheel: true,
        icons: {
          red: {
            normal: `${MAP_ICON_BASE_URL}/red-dot.png`,
            selected: `${MAP_ICON_BASE_URL}/red-dot.png`,
          },
          green: {
            normal: `${MAP_ICON_BASE_URL}/green-dot.png`,
            selected: `${MAP_ICON_BASE_URL}/green-dot.png`,
          },
          yellow: {
            normal: `${MAP_ICON_BASE_URL}/yellow-dot.png`,
            selected: `${MAP_ICON_BASE_URL}/yellow-dot.png`,
          },
        },
      };

      const map = new GoogleCharts.api.visualization.Map(
        document.getElementById('heatMapContainer')
      );
      map.draw(data, options);
    };

    // Load the charts library with a callback
    // GoogleCharts is singleton and safe to call multiple times without triggering the script to load each time
    GoogleCharts.load(drawChart, {
      packages: ['map'],
      mapsApiKey: mapKey,
    });
  };

  const constructMapsData = useCallback(() => {
    const { projects, projectImpressions } = heatMapState;

    let mapData = [];

    forEach(projectImpressions, (impression, projectId) => {
      const impressionedProject = projects[projectId];

      if (!isNil(impressionedProject)) {
        const {
          location: { latitude, longitude },
          name,
        } = impressionedProject;

        if (!isNil(latitude) && !isNil(longitude)) {
          mapData.push([
            Number(latitude),
            Number(longitude),
            `(${impression}) ${name}`,
            IMPRESSION_MARKER_MAP[impression],
          ]);
        }
      }
    });

    // empty mapData will create google map errors, while loading map.
    if (!isEmpty(mapData)) {
      dispatchHeatMapActions({ type: 'updateHeatMapData', payload: mapData });
      loadMap(mapData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatMapState.projects, heatMapState.projectImpressions]);

  useEffect(() => {
    constructMapsData();
  }, [constructMapsData]);

  const fetchImpressionsHeatMapData = async options => {
    const { links, csrfToken } = props;

    let queryParams = {};

    if (!isNil(options)) {
      if (!!options.isFilterSubmit && !isEmpty(options.filterOptions)) {
        queryParams = {
          ...getHeatMapFilterOptionsQueryParams(options.filterOptions),
        };
      }
    }

    // .get() has been moved to the top of the http call.
    // After that only queryParams has been added to the request url.
    await new Http()
      .onBegin(() =>
        dispatchHeatMapActions({ type: 'initiateHeatMapDataFetch' })
      )
      .get(links.impressions)
      .setToken(csrfToken)
      .setQueryParams({
        ...queryParams,
      })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .useAlerts()
      .onSuccess(({ data }) => {
        dispatchHeatMapActions({
          type: 'resolvedHeatMapDataFetch',
          payload: { projectImpressions: data },
        });
      })
      .onError(() => {
        dispatchHeatMapActions({ type: 'rejectedHeatMapDataFetch' });
      })
      .exec();
  };

  const fetchMetaData = async () => {
    const { links, csrfToken } = props;

    await new Http()
      .onBegin(() => dispatchHeatMapActions({ type: 'initiateMetaDataFetch' }))
      .setToken(csrfToken)
      .get(links.metadata)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .useAlerts()
      .onSuccess(({ data }) => {
        const { projects, projectCategories } = data;
        dispatchHeatMapActions({
          type: 'resolvedMetaDataFetch',
          payload: {
            projects: convertArrayOfObjectsToHash(projects, 'id'),
            projectCategories: projectCategories,
          },
        });
      })
      .onError(() => {
        dispatchHeatMapActions({ type: 'rejectedMetaDataFetch' });
      })
      .exec();
  };

  const fetchInitialData = async () => {
    await fetchMetaData();
    await fetchImpressionsHeatMapData();
  };

  useEffect(() => {
    fetchInitialData();

    // to trigger only on initial component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterAttributeChange = attributeName => atributeValue => {
    let optionValue = atributeValue;
    if (isEqual('selectedResponsePeriod', attributeName)) {
      optionValue = atributeValue.key;
    }
    dispatchHeatMapActions({
      type: 'handleFilterOptions',
      payload: {
        [attributeName]: optionValue,
      },
    });
  };

  const responseChannelsOptionRenderer = option => {
    let fontClass = '';
    let iconClass = 'fab fa-wpforms';
    if (!isEqual(option.value, 'Form')) {
      fontClass = `font-${lowerCase(option.value)}`;
      iconClass = `fa fa-${lowerCase(option.value)}`;
    }
    return (
      <div className={fontClass}>
        <i className={iconClass} />
        <span className="ml-2">{option.label}</span>
      </div>
    );
  };

  const renderFilters = () => {
    const {
      featureFlags: { projectCategoriesEnabled },
      tenantTerms: { termProject },
      responseChannels,
    } = props;

    const { projectCategories, filterOptions, loading, metaDataLoading } =
      heatMapState;
    const {
      selectedResponseChannels,
      selectedProjectCategories,
      selectedResponsePeriod,
    } = filterOptions;

    const disabledFiltering = loading || metaDataLoading;

    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-end"
          style={{ paddingRight: '20px' }}
        >
          <button
            className="app-btn-outline-primary"
            style={{ width: 'max-content' }}
            type="button"
            data-toggle="collapse"
            data-target="#impressionHeatMapFilterCollapse"
            aria-expanded="false"
            aria-controls="impressionHeatMapFilterCollapse"
          >
            <i className="fas fa-filter"></i> Filter
          </button>
        </div>
        <div
          className="collapse multi-collapse"
          id="impressionHeatMapFilterCollapse"
        >
          <div className="kt-portlet m-4 p-2">
            <div className="row ml-15 mr-15 mb-4 justify-content-center">
              <div className="col-md-3 p-2">
                <MultiSelect
                  placeholder={'Select Response Channels'}
                  name={'selectedResponseChannels'}
                  isSearchable={false}
                  options={constructMultiSelectOptions(responseChannels)}
                  value={selectedResponseChannels || []}
                  optionIdentifier="value"
                  onChange={handleFilterAttributeChange(
                    'selectedResponseChannels'
                  )}
                  isDisabled={disabledFiltering}
                  formatOptionLabel={responseChannelsOptionRenderer}
                />
              </div>
              <div className="col-md-3 p-2">
                <SelectInput
                  placeholder={'Select Response Period'}
                  name={'selectedResponsePeriod'}
                  isSearchable={false}
                  getOptionValue={option => option.key}
                  optionIdentifier="key"
                  options={PERIOD_FILTER_OPTIONS}
                  value={selectedResponsePeriod || ''}
                  onChange={handleFilterAttributeChange(
                    'selectedResponsePeriod'
                  )}
                  isDisabled={disabledFiltering}
                />
              </div>
              {projectCategoriesEnabled && (
                <div className="col-md-3 p-2">
                  <MultiSelect
                    placeholder={`${disabledFiltering ? 'Loading' : 'Select'} ${
                      termProject.singular
                    } Categories`}
                    options={projectCategories || []}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    optionIdentifier="id"
                    value={selectedProjectCategories || []}
                    onChange={handleFilterAttributeChange(
                      'selectedProjectCategories'
                    )}
                    isDisabled={disabledFiltering}
                  />
                </div>
              )}
              <div className="col-md-12 d-flex justify-content-center px-1 py-2">
                <button
                  onClick={() =>
                    fetchImpressionsHeatMapData({
                      filterOptions,
                      isFilterSubmit: true,
                    })
                  }
                  className="app-btn-primary"
                  disabled={disabledFiltering}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderLoadingStatus = status => (
    <div className="d-flex justify-content-center">
      <h3 className=" m-4 kt-font-info">{status}</h3>
    </div>
  );

  const { impressionMapData, loading, metaDataLoading } = heatMapState;

  return (
    <div className="kt-portlet mb-0">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Impression Heat Map</h3>
        </div>
      </div>
      <div className="kt-portlet__body plr-0">
        <div className="kt-section">
          <div className="kt-section__content">{renderFilters()}</div>
        </div>
        {loading || metaDataLoading ? (
          renderLoadingStatus(
            `Fetching ${loading ? 'Impressions' : 'Coordinates'}...`
          )
        ) : !isEmpty(impressionMapData) ? (
          <div className="kt-section">
            <div className="kt-section__content my-2">
              {map(IMPRESSION_MARKER_MAP, (color, impression) => (
                <span key={impression} className="m-2">
                  <img
                    height="20px"
                    src={`${MAP_ICON_BASE_URL}/${color}-dot.png`}
                    alt={`${impression} impression map icon indicator`}
                  />
                  <span>{impression}</span>
                </span>
              ))}
            </div>
            <div className="kt-section__content">
              <div
                id="heatMapContainer"
                className="m-auto"
                style={{ minHeight: '500px' }}
              ></div>
            </div>
          </div>
        ) : (
          <h2 className="text-center m-5">No Impression Data to View</h2>
        )}
      </div>
    </div>
  );
};

ImpressionHeatMap.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  mapKey: PropTypes.string.isRequired,
  featureFlags: PropTypes.shape({
    projectCategoriesEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  links: PropTypes.shape({
    metadata: PropTypes.string.isRequired,
    impressions: PropTypes.string.isRequired,
  }).isRequired,
  responseChannels: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(ImpressionHeatMap);

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
// import toInteger from 'lodash/toInteger';

import { renderOptions } from '../common/utils';
import MembersTable from './members_table';
import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { teamsFormReducer } from './reducers';

const getInitialFormState = props => {
  return {
    name: '',
    selectedUsers: [],
    ...(props.userHasRoles && { userRoles: {} }),
    memberList: [],
    loading: false,
  };
};

const TeamForm = props => {
  const INITIAL_FORM_STATE = getInitialFormState(props);
  const [teamsFormState, dispatchTeamsFormActions] = React.useReducer(
    teamsFormReducer,
    INITIAL_FORM_STATE
  );

  React.useEffect(() => {
    const { teamId, token, links, userHasRoles } = props;
    const getUserData = async () => {
      await new Http()
        .setToken(token)
        .onBegin(() => dispatchTeamsFormActions({ type: 'onBegin' }))
        .useAlerts()
        .get(links.fetchTeamData)
        .onSuccess(({ data: response }) => {
          dispatchTeamsFormActions({
            type: 'onGetUserDataSuccess',
            payload: { response, userHasRoles },
          });
        })
        .onError(() => dispatchTeamsFormActions({ type: 'onError' }))
        .exec();
    };
    if (teamId) {
      getUserData();
    }

    // the effect only needs to run on the component mount and not on every update
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Move to utils later
  // const getSelectedOptions = (options = []) => filter(options, 'selected');

  // const getSelectedOptionsIntegerValues = (options = []) =>
  //   map(getSelectedOptions(options), selectedOption =>
  //     toInteger(selectedOption.value)
  //   );

  const handleSubmit = async () => {
    const { teamId, token, links, userHasRoles } = props;
    const { name, selectedUsers, userRoles } = teamsFormState;

    const Requestor = new Http()
      .onBegin(() => dispatchTeamsFormActions({ type: 'onBegin' }))
      .setToken(token)
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        team: {
          name: !isEmpty(name) ? name.trim() : '',
          user_ids: selectedUsers,
          ...(userHasRoles && { roles: userRoles }),
        },
      })
      .onSuccess(response => {
        dispatchTeamsFormActions({ type: 'onSubmitSuccess' });
        window.location.href = response.data.meta.redirection_url;
      })
      .onError(() => dispatchTeamsFormActions({ type: 'onError' }));

    if (teamId) {
      Requestor.patch(links.upsertTeam);
    } else {
      Requestor.post(links.upsertTeam);
    }

    await Requestor.exec();
  };

  const handleChange = event => {
    dispatchTeamsFormActions({
      type: 'handleAttributeChange',
      payload: {
        [event.target.getAttribute('_name')]: event.target.value,
      },
    });
  };

  const handleUserSelect = users => {
    const { assignableUsers, userHasRoles } = props;
    const { selectedUsers, userRoles } = teamsFormState;

    const newSelectedUsers = map(users, 'value');
    const newMembers = filter(assignableUsers, user =>
      includes(newSelectedUsers, user.id)
    );

    let updatedState = {
      selectedUsers: newSelectedUsers,
      memberList: newMembers,
    };

    if (userHasRoles) {
      const newUserRoles = pick(userRoles, selectedUsers);
      updatedState.userRoles = newUserRoles;
    }

    dispatchTeamsFormActions({
      type: 'handleUserSelect',
      payload: updatedState,
    });
  };

  const handleUserRoleChange = (userId, role) => {
    const { userRoles } = teamsFormState;
    userRoles[`${userId}`] = role;
    dispatchTeamsFormActions({
      type: 'handleUserRoleChange',
      payload: userRoles,
    });
  };

  const handleOnDelete = userId => {
    const { assignableUsers } = props;
    const { selectedUsers, userRoles } = teamsFormState;

    const newSelectedUsers = filter(
      selectedUsers,
      user_id => user_id !== userId
    );
    const newUserRoles = omit(userRoles, [`${userId}`]);
    const newMembers = filter(assignableUsers, user =>
      includes(newSelectedUsers, user.id)
    );

    dispatchTeamsFormActions({
      type: 'handleOnDelete',
      payload: {
        selectedUsers: newSelectedUsers,
        userRoles: newUserRoles,
        memberList: newMembers,
      },
    });
  };

  const { assignableUsers, assignableUserRoles, userHasRoles } = props;
  const { loading, name, selectedUsers, memberList, userRoles } =
    teamsFormState;

  const renderableDOMSection = loading ? (
    <Spinner text="Submitting" />
  ) : (
    <div className="kt-content">
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Teams</h3>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="kt-form kt-form--label-right">
          <div className="kt-portlet__body">
            <div className="form-group kt-form__group row">
              <label className="col-2 col-form-label">Name*</label>

              <div className="col-8">
                <input
                  className="form-control"
                  type="text"
                  _name="name"
                  name="team['name']"
                  value={name || ''}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="form-group kt-form__group row">
              <label className="col-2 col-form-label">Users*</label>

              <div className="col-8">
                <MultiSelect
                  options={renderOptions(assignableUsers)}
                  onChange={handleUserSelect}
                  value={selectedUsers}
                  optionIdentifier="value"
                />
              </div>
            </div>

            <div className="form-group  kt-form__group row">
              <label className="col-2 col-form-label">Team Members</label>
              <div className="col-8">
                <MembersTable
                  members={memberList}
                  userRoles={userHasRoles ? userRoles : {}}
                  allRoles={userHasRoles ? assignableUserRoles : []}
                  handleOnDelete={handleOnDelete}
                  handleRoleChange={handleUserRoleChange}
                />
              </div>
            </div>
          </div>
          <div className="kt-portlet__foot">
            <div className="row">
              <div className="col-lg-6 offset-3 text-center">
                <input
                  className="btn btn-success"
                  type="submit"
                  name="Submit"
                  disabled={isEmpty(name) || isEmpty(selectedUsers)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  return renderableDOMSection;
};

TeamForm.propTypes = {
  assignableUsers: PropTypes.array.isRequired,
  assignableUserRoles: PropTypes.array,
  teamId: PropTypes.number,
  userHasRoles: PropTypes.bool,
  links: PropTypes.shape({
    fetchTeamData: PropTypes.string,
    upsertTeam: PropTypes.string,
  }),
};

TeamForm.defaultProps = {
  assignableUserRoles: [],
  userHasRoles: true,
};

export default withErrorHandler(TeamForm);

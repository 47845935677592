import React from 'react';
import PropTypes from 'prop-types';

import FolderListRow from './FolderListRow';

const FolderList = ({
  folders,
  folderUrlProvider,
  folderUpdateEnablerPredicate,
  onFolderRenameClick,
  onFolderShareClick,
}) => (
  _.map(folders, (folder, index) => (
    <FolderListRow
      key={`folderListRow-${index}`}
      folder={folder}
      urlProvider={folderUrlProvider}
      updateEnablerPredicate={folderUpdateEnablerPredicate}
      onRenameClick={onFolderRenameClick}
      onShareClick={onFolderShareClick}
    />
  ))
);

FolderList.propTypes = {
  folders: PropTypes.array,
  folderUrlProvider: PropTypes.func,
  onFolderRenameClick: PropTypes.func,
  onFolderShareClick: PropTypes.func,
  folderUpdateEnablerPredicate: PropTypes.func.isRequired,
};

FolderList.defaultProps = {
  folders: [],
  folderUrlProvider: () => { console.log('Default Props Folder url Provider') },
  onFolderRenameClick: () => { console.log('Default Props Rename Handler') },
  onFolderShareClick: () => { console.log('Default Props Share Handler') },
};

export default FolderList;

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import CloneLink from '../../courses/admin/cloning/CloneLink';
import {
  checkIsForumClonable,
  getForumCloningTitle,
} from '../../courses/admin/cloning/utils';

export const NormalCourseTableBody = ({
  courses,
  csrfToken,
  toggleLoader,
  tenantTerms,
  curriculumCloneConfig,
  handlePublishedStatus,
  handleArchiveRequest,
  handleUnArchiveRequest,
}) => (
  <tbody>
    {map(
      courses,
      ({ id, name, links, cloneConfig, published, archived }, index) => {
        const isForumClonable = checkIsForumClonable(
          cloneConfig.forumCloningCompleted
        );
        const forumCloningTitle = getForumCloningTitle(
          cloneConfig.forumCloningCompleted
        );
        return (
          <tr key={id}>
            <td className="text-center td-50">{index + 1}</td>
            <td>{name}</td>
            <td className="text-right td-110">
              <div className="btn-group dropleft">
                <button
                  type="button"
                  className="btn-sm app-btn-outline-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Options
                </button>
                <div className="dropdown-menu">
                  <a href={links.edit} className="dropdown-item">
                    Edit
                  </a>
                  <CloneLink
                    link={links.clone}
                    subjectName={name}
                    csrfToken={csrfToken}
                    toggleLoader={toggleLoader}
                    tenantTerms={tenantTerms}
                    inManageCourses={true}
                    curriculumCloneConfig={curriculumCloneConfig}
                  />
                  <a
                    href={
                      isForumClonable ? links.initiateForumPostsCloning : null
                    }
                    className={`dropdown-item ${
                      !isForumClonable && 'cursor-disabled'
                    }`}
                    title={forumCloningTitle}
                  >
                    Initiate Forum Cloning
                  </a>
                  {!archived &&
                    (published ? (
                      <button
                        className="dropdown-item"
                        onClick={handlePublishedStatus(links.unpublish)}
                      >
                        Mark as Unpublished
                      </button>
                    ) : (
                      <button
                        className="dropdown-item"
                        onClick={handlePublishedStatus(links.publish)}
                      >
                        Mark as Published
                      </button>
                    ))}
                  {!published && !archived && (
                    <button
                      className="dropdown-item text-darkred"
                      onClick={handleArchiveRequest(links.archive)}
                    >
                      Archive
                    </button>
                  )}
                  {archived && (
                    <button
                      className="dropdown-item text-danger"
                      onClick={handleUnArchiveRequest(links.unarchive)}
                    >
                      UnArchive
                    </button>
                  )}
                </div>
              </div>
            </td>
          </tr>
        );
      }
    )}
  </tbody>
);

NormalCourseTableBody.propTypes = {
  courses: PropTypes.array,
  csrfToken: PropTypes.string,
  toggleLoader: PropTypes.func,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  handlePublishedStatus: PropTypes.func,
  handleArchiveRequest: PropTypes.func,
  handleUnArchiveRequest: PropTypes.func,
  curriculumCloneConfig: PropTypes.shape({
    currentCurriculum: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    remainingCloneTargetCurriculums: PropTypes.array,
  }),
};

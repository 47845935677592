import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import PortletBlocker from '../common/presentational/PortletBlocker';
import SubmitButton from '../common/presentational/submitButton';
import ParticipantSelectWithList from './ParticipantSelectWithList';

const CourseRetakeForm = ({
  userList,
  tenantTerms: { termUser, termCourse },
  courseId,
  links,
  token,
}) => {
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const proceed = confirm(
      `Current progress will be erased for the selected people when they retake the ${termCourse.singular.toLowerCase()}. Are you sure ?`
    );

    if (proceed) {
      await new Http()
        .setToken(token)
        .onBegin(() => setLoading(true))
        .doesRedirect(true)
        .post(links.resetCourseCompletion, {
          id: courseId,
          course: {
            user_ids: selectedUserIds,
          },
        })
        .onSuccess(() => {
          setLoading(false);
          window.location.href = links.courseShow;
        })
        .onError(err => {
          alertErrorNotifications(err || 'Something went wrong!');
          setLoading(false);
        })
        .exec();
    }
  };

  return (
    <PortletBlocker loading={loading}>
      <div>
        <form
          onSubmit={handleSubmit}
          className="kt-form kt-form--label-right kt-form--fit"
        >
          <ParticipantSelectWithList
            onChange={participantIds => setSelectedUserIds(participantIds)}
            participantList={userList}
            selectedUsers={selectedUserIds}
            participantListLabel={termUser.plural}
            headerText={'Retake Course '}
            participantLabel={'People'}
            showList={false}
            mapUserIdWithList
          />

          <div className="kt-separator kt-separator--dashed"></div>
          <div className="kt-section">
            <div className="form-group kt-form__group">
              <SubmitButton />
            </div>
          </div>
        </form>
      </div>
    </PortletBlocker>
  );
};

CourseRetakeForm.propTypes = {
  courseId: PropTypes.number.isRequired,
  userList: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  links: PropTypes.shape({
    resetCourseCompletion: PropTypes.string.isRequired,
    courseShow: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseRetakeForm);

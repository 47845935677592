export const SURVEY_SUMMARY_QUERY_STRING = 'survey_form_id';
export const SURVEY_SUMMARY_TAB = 'tab_summary';
export const SURVEY_RESPONSES_TAB = 'tab_responses';

export const CHART_REPRESENTING_TYPES = [
  'Dropdown',
  'Tags',
  'Checkboxes',
  'RadioButtons',
  'Rating',
  'Range',
  'FormToggleBox',
  'Signature',
];

export const RECORD_GROUPABLE_ELEMENTS = [
  'NameTextInput',
  'EmailTextInput',
  'CustomProjectDropdown',
  'CustomPhoneInput',
  'DatePicker',
  'Time',
  'DateTime',
];

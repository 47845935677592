import React from 'react';
import PropTypes from 'prop-types';

const CancelButton = ({ confirmCancel, className }) => {
  const goBack = e => {
    e.preventDefault();

    let canGoBack = true;
    if (confirmCancel) {
      canGoBack = confirm("Are you sure you want to leave this page?");
    }
    canGoBack && history.back();
  }
  return (
    <button
      className={`app-btn-secondary ${className} mx-2`}
      onClick={goBack}
    >
      Cancel
    </button>
  );
}

CancelButton.propTypes = {
  confirmCancel: PropTypes.bool,
  className: PropTypes.string
}

CancelButton.defaultProps = {
  confirmCancel: true,
  className: ''
}

export default CancelButton;

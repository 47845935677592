import React from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Http from '../../common/Http';
import {
  getRawPhoneNumber,
  getUnformattedPhoneNumber,
} from '../../common/utils';

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.
class CustomPhoneInput extends React.Component {
  constructor(props) {
    super(props);

    let prefilledValue = props.defaultValue || '';
    if (
      !isNil(props.answerData) &&
      !isNil(props.answerData[props.data.field_name])
    ) {
      prefilledValue = props.answerData[props.data.field_name];
    }

    this.state = { value: prefilledValue, isValid: true };
  }

  debouncedPhoneValidator = debounce(
    (rawPhone, dialCode) => this.validatePhoneNumber(rawPhone, dialCode),
    500
  );

  handleOnChange = (value, data) => {
    const updatedValue = getUnformattedPhoneNumber(value, data);

    let rawPhone = '';
    let dialCode = '';
    if (!isEqual(value, '+')) {
      dialCode = data.dialCode;
      rawPhone = getRawPhoneNumber(value);
    }
    this.setState(
      {
        value: updatedValue,
      },
      () => this.debouncedPhoneValidator(rawPhone, dialCode)
    );
  };

  validatePhoneNumber = async (rawPhone, dialCode) => {
    const {
      data: { props },
    } = this.props;

    if (isUndefined(props.mobileValidationLink)) return true;

    let isValidNumber = true;

    if (rawPhone.length && rawPhone.length > dialCode.length) {
      await new Http(this)
        .get(props.mobileValidationLink, {
          mobile: `+${rawPhone}`,
        })
        .onSuccess(() => {
          isValidNumber = true;
        })
        .onError(() => {
          isValidNumber = false;
        })
        .exec();
    }
    this.setState({
      isValid: isValidNumber,
    });
    return isValidNumber;
  };

  render() {
    const { value, isValid } = this.state;
    const { data, inputProps } = this.props;
    const { field_name, read_only } = data;

    return (
      <React.Fragment>
        <PhoneInput
          country={'us'}
          value={value}
          onChange={this.handleOnChange}
          disabled={read_only}
          countryCodeEditable={false}
          autoFormat={false}
          enableSearch
          inputProps={{
            name: field_name,
            ...inputProps,
          }}
        />
        {!read_only && !isValid && (
          <div className="text-darkred">
            <strong>Invalid Number</strong>
          </div>
        )}
      </React.Fragment>
    );
  }
}

CustomPhoneInput.propTypes = {
  data: PropTypes.shape({
    field_name: PropTypes.string,
    props: PropTypes.shape({
      mobileValidationLink: PropTypes.string,
    }),
    read_only: PropTypes.bool,
  }).isRequired,
  defaultValue: PropTypes.string,
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  inputProps: PropTypes.object,
};
CustomPhoneInput.defaultProps = {
  data: {
    field_name: 'CustomPhoneInput',
  },
};

export default CustomPhoneInput;

import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SmoothImage from '../common/presentational/SmoothImage';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime, convertArrayOfObjectsToHash } from '../common/utils';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import { Transition, TransitionContainer } from '../common/animations';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import filter from 'lodash/filter';

const Library = ({ links, csrfToken }) => {
  const { earnedBadges: earnedBadgesUrl } = links;
  const [loading, setLoading] = useState(false);
  const [earnedBadges, setEarnedBadges] = useState(null);
  const [involvedBadges, setInvolvedBadges] = useState(null);
  const [badgeFilterText, setBadgeFilterText] = useState(null);

  useEffect(() => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(earnedBadgesUrl)
      .doesRedirect(false)
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { earnedBadges, involvedBadges } }) => {
        setEarnedBadges(earnedBadges);
        setInvolvedBadges(convertArrayOfObjectsToHash(involvedBadges, 'id'));
        setLoading(false);
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  }, [csrfToken, earnedBadgesUrl]);

  const onInputChange = event => {
    const { target } = event;
    if (!isNil(target.name)) {
      setBadgeFilterText(target.value);
    }
  };

  const renderNoBadgesView = () => (
    <TransitionContainer>
      <Transition classNames="slide-up">
        <div className="kt-portlet text-center p-5">
          <h2>No badges found</h2>
        </div>
      </Transition>
    </TransitionContainer>
  );

  const renderBadges = earnedBadges => {
    if (isEmpty(earnedBadges)) {
      return renderNoBadgesView();
    }
    return (
      <TransitionContainer className="d-flex flex-row flex-wrap">
        {map(earnedBadges, badge => {
          const { attachment, name } = involvedBadges[badge.badgeId];

          return (
            <Transition key={`${badgeFilterText}-${badge.id}`}>
              <div className="card badge-card" key={badge.id}>
                <SmoothImage
                  src={attachment.url}
                  className="card-img-top"
                  alt="badge"
                />
                <div className="card-body">
                  <h5 className="card-title text-center m-1 text-truncate">
                    {name}
                  </h5>
                  <h6 className="text-muted text-center" title="Badge type">
                    {badge.badgeType}
                  </h6>

                  <div className="kt-stack kt-stack--ver kt-stack--general kt-stack--demo">
                    <div className="kt-stack__item kt-stack__item--center kt-stack__item--middle kt-stack__item--course-card">
                      <div>
                        <i
                          className="fas fa-people-carry text-muted"
                          title="Earned through"
                          style={{ fontSize: '28px' }}
                        ></i>
                      </div>
                      {badge.completedLearningModule.name}
                    </div>

                    <div className="kt-stack__item kt-stack__item--center kt-stack__item--middle kt-stack__item--course-card">
                      <div>
                        <i
                          className="far fa-calendar-alt text-muted"
                          title="Earned at"
                          style={{ fontSize: '28px' }}
                        ></i>
                      </div>
                      {formatDateTime({
                        date: badge.earnedAt,
                        formatTime: false,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Transition>
          );
        })}
      </TransitionContainer>
    );
  };

  let allBadges = earnedBadges;
  if (!isNil(badgeFilterText) && !isEmpty(badgeFilterText)) {
    allBadges = filter(earnedBadges, badge =>
      badge.badgeType.toLowerCase().includes(badgeFilterText.toLowerCase())
    );
  }

  return (
    <Fragment>
      <div className="kt-portlet">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label justify-content-between w-100">
            <h3 className="kt-portlet__head-title">Earned Badges</h3>
            <div className="d-flex align-items-center ">
              <b>Filter: </b>
              <input
                name="badgeFilterText"
                onChange={onInputChange}
                type="text"
                title="Filter by Badge type"
                className="form-control kt-input ml-3"
                placeholder="Filter by Badge type"
                style={{ width: 'max-content' }}
              />
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          {loading ? <Spinner /> : renderBadges(allBadges)}
        </div>
      </div>
    </Fragment>
  );
};

Library.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    earnedBadges: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(Library);

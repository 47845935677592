import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Http from '../common/Http';
import SelectInput from '../common/inputs/select';
import { withErrorHandler } from '../hoc/withErrorHandler';
import Spinner from '../common/presentational/spinner';
import { alertSuccessNotifications } from '../folders/utils';
import Modal from '../filestack/modal';

const TABLE_HEADER = Object.freeze([
  'Name',
  'Process Sentiment Analysis',
  'Use Tickets',
  'Positive Responses',
  'Neutral Responses',
  'Negative Responses',
  '',
]);

const FeedbackFormTypes = ({ feedbackFormTypes: formTypes, links, csrfToken }) => {
  const [feedbackFormTypes, setFeedbackFormTypes] = useState(formTypes);
  const [selectedFormType, setSelectedFormType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFormTypeDelete = (formTypeId, deleteLink) => {
    const confirmDelete = confirm('Are you sure to delete Form Type?');

    if (confirmDelete) {
      new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .useAlerts()
        .doesRedirect(true)
        .useAPIDataFormatters({
          snakifyRequestData: true,
          camelizeResponseData: true,
        })
        .delete(deleteLink)
        .onSuccess(() => {
          setFeedbackFormTypes((types) => types.filter((type) => type.id !== formTypeId));
          setLoading(false);
          alertSuccessNotifications('Form Type Successfully Deleted!');
        })
        .onError(() => setLoading(false))
        .exec();
    }
  };

  const handleTopicCategoryFormAssignment = (formType) => {
    setSelectedFormType(formType);
    setIsModalOpen(true);
  }

  const handleModalClose = () => {
    setSelectedFormType(null);
    setIsModalOpen(false);
  }

  const submitQueryFormAssigment = async (formId) => {
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData({
        feedbackFormId: formId
      })
      .patch(selectedFormType.links.assignKnowledgeBaseCategoryForm)
      .onSuccess(() => {
        const formTypeToUpdate = {
          ...selectedFormType,
          topicCategoryFeedbackFormId: formId
        };

        setSelectedFormType(formTypeToUpdate);
        setFeedbackFormTypes((types) => (
          types.map((type) => type.id === selectedFormType.id ? formTypeToUpdate : type)
        ));
        setLoading(false);
      })
      .onError(() => setLoading(false))
      .exec();
  }

  const renderCheckIcon = (checked) => (
    <i
      className={`far fa-check-circle font-18 ${
        checked ? 'text-success' : 'text-secondary'
      }`}
    ></i>
  );

  const tableHeader = () => (
    <thead>
      <tr className="text-black-50">
        <th colSpan="3"></th>
        <th colSpan="3" className="border-secondary border-bottom text-center">
          Create Ticket For
        </th>
      </tr>
      <tr className="text-black-50">
        {TABLE_HEADER.map((header) => (
          <th key={header} className={`${header !== "Name" ? "text-center" : ""}`} style={{ minWidth: '100px' }}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  const tableBody = () => (
    <tbody>
      {feedbackFormTypes.map((feedbackFormType) => (
        <tr key={feedbackFormType.id}>
          <td className="text-black-50" style={{ minWidth: '250px' }}>
            <strong title={feedbackFormType.name}>{feedbackFormType.name}</strong>
          </td>
          <td className="text-center">{renderCheckIcon(feedbackFormType.processSentimentAnalysis)}</td>
          <td className="text-center">{renderCheckIcon(feedbackFormType.useTickets)}</td>
          <td className="text-center">{renderCheckIcon(feedbackFormType.ticketForPositiveResponses)}</td>
          <td className="text-center">{renderCheckIcon(feedbackFormType.ticketForNeutralResponses)}</td>
          <td className="text-center">{renderCheckIcon(feedbackFormType.ticketForNegativeResponses)}</td>
          <td className="text-center">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v text-black-50"></i>
              </button>
              <div className="dropdown-menu">
                {!_.isNil(feedbackFormType.links.publicKnowledgeBase) && (
                  <a
                    href={feedbackFormType.links.publicKnowledgeBase}
                    className="dropdown-item text-black-50"
                  >
                    <i className="fa fa-question"></i> Public Knowledge Base (FAQ's)
                  </a>
                )}
                {!_.isNil(feedbackFormType.links.manageKnowledgeBase) && (
                  <a
                    href={feedbackFormType.links.manageKnowledgeBase}
                    className="dropdown-item text-black-50"
                  >
                    <i className="fa fa-gear"></i> Manage Knowledge Base (FAQ's)
                  </a>
                )}
                {!_.isEmpty(feedbackFormType.feedbackForms) && (
                  <button
                    className="dropdown-item text-black-50"
                    onClick={() => handleTopicCategoryFormAssignment(feedbackFormType)}
                  >
                    <i className="fab fa-wpforms"></i> Assign Knowledge Base Query Form
                  </button>
                )}
                <a
                  href={feedbackFormType.links?.edit}
                  className="dropdown-item text-black-50"
                >
                  <i className="far fa-edit"></i> Edit
                </a>
                <button
                  onClick={() =>
                    handleFormTypeDelete(
                      feedbackFormType.id,
                      feedbackFormType.links?.delete
                    )
                  }
                  className="dropdown-item text-black-50"
                >
                  <i className="far fa-trash-alt"></i> Delete
                </button>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  const renderNoData = () => (
    <div className="p-4 my-5 text-center">
      <img src={links.noFormTypeIcon} alt="no content" width="300" />
      <h3>No Feedback Form Type Available</h3>
    </div>
  );

  const renderModalBody = () => (
    <div>
      <p className="kt-font-info">* Only the Feedback Forms with <b>{selectedFormType.name}</b> Type can be assigned as Knowledge Base Query Form.</p>
      <SelectInput
        placeholder={`Select Form...`}
        options={selectedFormType.feedbackForms || []}
        name="topicCategoryFeedbackFormId"
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={({ id }) => submitQueryFormAssigment(id)}
        value={selectedFormType.topicCategoryFeedbackFormId || ''}
        optionIdentifier="id"
        isClearable={false}
      />
    </div>
  );

  return (
    <div className="p-4">
      {loading && <Spinner />}
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-4">Feedback Form Types</h3>
        <a href={links.new} className="app-btn-outline-primary">
          Add New
        </a>
      </div>

      {_.isEmpty(feedbackFormTypes) ? (
        renderNoData()
      ) : (
        <div className="table-responsive mt-3">
          <table className="table table-borderless table-striped">
            {tableHeader()}
            {tableBody()}
          </table>
        </div>
      )}

      {isModalOpen && !!selectedFormType && (
        <Modal
          title="Assign Knowledge Base Topic Category Query Form"
          renderContent={renderModalBody}
          renderFooter={() => (
            <div className="text-right w-100">
              <button
                className="app-btn-primary"
                onClick={handleModalClose}
              >Done</button>
            </div>
          )}
          onClose={handleModalClose}
          closeOnBackdropClick={!loading}
          closeOnEscKey={!loading}
          hideClose={loading}
        />
      )}
    </div>
  );
};

FeedbackFormTypes.propTypes = {
  links: PropTypes.shape({
    noFormTypeIcon: PropTypes.string.isRequired,
    new: PropTypes.string.isRequired,
  }).isRequired,
  feedbackFormTypes: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default withErrorHandler(FeedbackFormTypes);

import React from 'react';
import PropTypes from 'prop-types';
import './spinner.scss';

const Spinner = ({ text }) => (
  <div
    className="modal-backdrop kt-animate-fade-in"
    style={{
      backgroundColor: '#00000099',
      zIndex: 99998,
    }}
  >
    <div className="loader-container">
      <div className="loader" />
      <div className="loader-text">{text}...</div>
    </div>
  </div>
);

Spinner.propTypes = {
  text: PropTypes.string,
};

Spinner.defaultProps = {
  text: 'Processing',
};

export default Spinner;

import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import './topicCategoriesStyles.scss';

const TopicCategories = ({ topicCategories, links }) => {
  return (
    <div className="my-4">
      <h3>Knowledge Base Topic Categories</h3>
      <p className="font-16 mt-4">
        <a className="kt-link" href={links.viewFeedbackFormTypes}>
          Create a Feedback Form Type
        </a>{' '}
        to auto generate a new category.
      </p>
      {isEmpty(topicCategories) ? (
        <div className="my-5">
          <h4 className="my-3">No Categories Added Yet!</h4>
        </div>
      ) : (
        <div className="categories-list mt-2 row">
          {topicCategories.map(
            ({ id, name, topicsCount, links: categoryLinks }) => (
              <div key={`${name}-${id}`} className="category-item col-md-3">
                <h4>{name}</h4>
                <div className="category-details mt-3">
                  <p className="topics-count mb-0 font-16">{`${topicsCount} topics`}</p>
                  <a
                    href={categoryLinks.viewCategory}
                    className="topic-view-link app-text-primary kt-link text-center"
                  >
                    <i className="fa fa-eye mr-2" />
                    View
                  </a>
                </div>
              </div>
            )
          )}
        </div>
      )}
    </div>
  );
};

TopicCategories.propTypes = {
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      topicsCount: PropTypes.number.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  links: PropTypes.shape({
    viewFeedbackFormTypes: PropTypes.string,
  }),
};

export default withErrorHandler(TopicCategories);

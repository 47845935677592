import map from 'lodash/map';

export const VALID_REVIEW_PARAMS = Object.freeze([]);

export const IMPRESSION_MAP = {
  // Order of keys used while rendering
  Positive: 'happy',
  Neutral: 'neutral',
  Negative: 'sad',
};

export const getImpressionEmojiLink = impression =>
  `https://mentor-assets.s3.amazonaws.com/reaction-${impression}.png`;

export const IMPRESSION_OPTIONS = map(
  IMPRESSION_MAP,
  (_impressionValue, impressionKey) => ({
    label: impressionKey,
    value: impressionKey,
  })
);

export const ANONYMOUS_USER = Object.freeze({
  id: -1,
  name: 'Anonymous',
});

export const RESPONSE_KEY = 'responses';
export const HEAT_MAP_KEY = 'heatMap';

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import Toggle from '../common/inputs/Toggle';
import TextInput from '../common/inputs/text';
import FormRow from '../common/presentational/formRow';
import SubmitButton from '../common/presentational/submitButton';
import Select from '../common/inputs/select';
import { alertErrorNotifications } from '../folders/utils';

const FeedbackTypesForm = ({
  feedbackFormTypeId,
  links,
  csrfToken,
  attributes: formAttributes,
  assignableTeams,
}) => {
  const [attributes, setAttributes] = useState(formAttributes);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!attributes.teamId) {
      alertErrorNotifications('Team is Required');
      return;
    }

    let Request = new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData(attributes)
      .onSuccess(({ data: { meta } }) => {
        window.location.href = meta.redirectionUrl;
      })
      .onError(() => setLoading(false));

    if (!!feedbackFormTypeId) {
      Request = Request.put(links?.update);
    } else {
      Request = Request.post(links?.create);
    }

    await Request.exec();
  };

  const handleNameChange = ({ target: { name, value } }) => {
    setAttributes((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSelectChange = (value) => {
    setAttributes((values) => ({
      ...values,
      teamId: value.id,
    }));
  };

  const handleCheckboxChange = (name, checked) => {
    if (['useTickets', 'processSentimentAnalysis'].includes(name) && !checked) {
      setAttributes((values) => ({
        ...values,
        [name]: checked,
        ticketForPositiveResponses: false,
        ticketForNeutralResponses: false,
        ticketForNegativeResponses: false,
      }));
    } else {
      setAttributes((values) => ({
        ...values,
        [name]: checked,
      }));
    }
  };

  const getAttributesValues = (name) => attributes[name];

  const disableResponseSection =
    !getAttributesValues('processSentimentAnalysis') ||
    !getAttributesValues('useTickets');

  return (
    <div className="p-4">
      {loading && <Spinner />}
      <h2 className="mb-5">Feedback Form Type</h2>
      <form onSubmit={handleSubmit}>
        <FormRow label="Name *">
          <TextInput
            numberAttributes={{
              required: true,
            }}
            name="name"
            value={getAttributesValues('name') || ''}
            onInputChange={handleNameChange}
            placeholder=""
          />
        </FormRow>
        <FormRow label="Team *">
          <Select
            name={'team'}
            placeholder="Select a team"
            options={assignableTeams}
            value={getAttributesValues('teamId') || ''}
            onChange={handleSelectChange}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            optionIdentifier="id"
            isClearable={false}
          />
        </FormRow>
        <FormRow label="Process Sentiment Analysis">
          <Toggle
            name={'processSentimentAnalysis'}
            onClick={(val) => handleCheckboxChange('processSentimentAnalysis', val)}
            on={<span>Yes</span>}
            off={<span>No</span>}
            size="md"
            height={30}
            recalculateOnResize
            offstyle="default"
            active={getAttributesValues('processSentimentAnalysis')}
          />
        </FormRow>
        <FormRow label="Use Tickets">
          <Toggle
            name="useTickets"
            onClick={(val) => handleCheckboxChange('useTickets', val)}
            on={<span>Yes</span>}
            off={<span>No</span>}
            size="md"
            height={30}
            recalculateOnResize
            offstyle="default"
            active={getAttributesValues('useTickets')}
          />
        </FormRow>
        <div className="d-flex flex-wrap justify-content-between align-items-center">
          <h5>Auto Create Ticket For</h5>
          <div className="text-info">
            * Both <b>Sentiment Analysis</b> and <b>Tickets</b> must be enabled to use
            this!
          </div>
        </div>
        <div className={`border border-secondary rounded p-4`}>
          <FormRow label="Positive Responses">
            <Toggle
              name="ticketForPositiveResponses"
              onClick={(val) => handleCheckboxChange('ticketForPositiveResponses', val)}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="md"
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getAttributesValues('ticketForPositiveResponses')}
              disabled={disableResponseSection}
            />
          </FormRow>
          <FormRow label="Neutral Responses">
            <Toggle
              name="ticketForNeutralResponses"
              onClick={(val) => handleCheckboxChange('ticketForNeutralResponses', val)}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="md"
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getAttributesValues('ticketForNeutralResponses')}
              disabled={disableResponseSection}
            />
          </FormRow>
          <FormRow label="Negative Responses" className="mb-0">
            <Toggle
              name="ticketForNegativeResponses"
              onClick={(val) => handleCheckboxChange('ticketForNegativeResponses', val)}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="md"
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getAttributesValues('ticketForNegativeResponses')}
              disabled={disableResponseSection}
            />
          </FormRow>
        </div>
        <div className="mt-4 text-right">
          <SubmitButton
            buttonText={'Save'}
            disabled={!attributes.teamId || !attributes.name}
          />
        </div>
      </form>
    </div>
  );
};

FeedbackTypesForm.propTypes = {
  attributes: PropTypes.shape({
    name: PropTypes.string,
    processSentimentAnalysis: PropTypes.bool,
    ticketForNegativeResponses: PropTypes.bool,
    ticketForNeutralResponses: PropTypes.bool,
    ticketForPositiveResponses: PropTypes.bool,
    useTickets: PropTypes.bool,
  }).isRequired,
  links: PropTypes.shape({
    update: PropTypes.string,
    create: PropTypes.string,
  }).isRequired,
  feedbackFormTypeId: PropTypes.number,
  csrfToken: PropTypes.string.isRequired,
  assignableTeams: PropTypes.array.isRequired,
};

export default withErrorHandler(FeedbackTypesForm);

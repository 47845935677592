import React from 'react';
import PropTypes from 'prop-types';

import Header from './common/Header';
import { formatDateTime } from '../../common/utils';
import InitialsAvatar from '../../common/Avatar';
import AttachmentPreview from '../../common/AttachmentPreview/Attachments';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const Topic = ({ links, topic, topicCategory }) => {
  return (
    <div className="knowledge_base">
      <Header queryFormLink={topicCategory.links.publicForm}>
        <div className="d-flex align-items-center text-black-50">
          <a className="category-link text-dark" href={links?.viewTopicCategories}>
            Home
          </a>
          <i className="fas fa-chevron-right mx-2 font-12"></i>
          <a
            className="category-link text-dark"
            href={topicCategory?.links?.viewCategory}
          >
            {topicCategory.name}
          </a>
          <i className="fas fa-chevron-right mx-2 font-12"></i>
        </div>
        <h1 className="header_section-title">{topic?.question}</h1>
      </Header>

      <div className="knowledge_base-container">
        <div className="knowledge_base-content">
          <div className="row">
            <div className="col-md-3">
              <div className="pb-4">
                <strong className="font-16">Written by</strong>
                <div className="d-flex align-items-center">
                  <InitialsAvatar
                    url={topic.postedBy?.avatar}
                    className="rounded-circle"
                    name={topic.postedBy?.name}
                  />
                  <p className="mb-0 ml-2 text-black-50">{topic.postedBy?.name}</p>
                </div>
              </div>
              <div className="pb-4">
                <strong className="font-16">Last updated</strong>
                <p className="text-black-50">
                  {formatDateTime({ date: topic.lastUpdatedAt, formatTime: false })}
                </p>
              </div>
            </div>
            <div className="col-md-6 text-black-50">
              <p
                className="description"
                dangerouslySetInnerHTML={{ __html: `${topic.answer}` }}
              />
              {!_.isEmpty(topic.attachments) && (
                <div className="mt-3" style={{ maxWidth: '700px' }}>
                  <AttachmentPreview attachments={topic.attachments} />
                </div>
              )}
              {!_.isNil(topicCategory.links.publicForm) && (
                <div className="knowledge_base-footer mt-5 pt-5">
                  <span>Didn't answer your question?</span>
                  <a href={topicCategory.links.publicForm} className="px-2 kt-link">Please Submit Your Query.</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Topic.propTypes = {
  links: PropTypes.shape({
    viewTopicCategories: PropTypes.string.isRequired,
  }).isRequired,
  topic: PropTypes.shape({
    id: PropTypes.number.isRequired,
    answer: PropTypes.string.isRequired,
    question: PropTypes.string.isRequired,
    lastUpdatedAt: PropTypes.string.isRequired,
    links: PropTypes.shape({
      viewTopic: PropTypes.string.isRequired,
    }).isRequired,
    postedBy: PropTypes.shape({
      avatar: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
};

export default withErrorHandler(Topic);

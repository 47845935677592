import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import ProjectTable from './ProjectsTable/index';
import ProjectGrid from './ProjectsGrid/index';
import ProjectsCategoryGroup from './ProjectsCategoryGroup';
import { withErrorHandler } from '../hoc/withErrorHandler';

const DEFAULT_TAB = 'table';
const switchViewStateText = 'projectViewState';
const CATEGORY_GROUP = 'categoryGroup';

const ProjectSwitchView = props => {
  const [currentTab, setCurrentTab] = React.useState('');
  // commented for future reference.
  // const [tabData, setTabData] = React.useState({ tableData: null });

  React.useEffect(() => {
    const tab = localStorage.getItem(switchViewStateText) || DEFAULT_TAB;
    setCurrentTab(tab);
    return () => {
      localStorage.removeItem(switchViewStateText);
    };
  }, []);

  const handleCurrentTab = tabName => {
    setCurrentTab(tabName);
    // Set the current view state to local storage for persisting view type on reload.
    localStorage.setItem(switchViewStateText, tabName);
  };

  const tabClass = React.useCallback(
    tab => {
      let className = 'btn btn-icon';
      if (tab === currentTab) {
        className += ' app-btn-primary';
      } else {
        className += ' app-btn-outline-secondary';
      }
      return className;
    },
    [currentTab]
  );

  const getCategoryGroupedProjectsList = React.useCallback(() => {
    const { projectList } = props;

    let categoriesList = ['NA'];
    let naProjectsList = [];
    map(projectList, project => {
      categoriesList = flatten([...categoriesList, project['categories']]);
      if (isEmpty(project['categories'])) {
        naProjectsList = [...naProjectsList, project];
      }
    });
    const allCategories = uniq(compact(categoriesList));

    let categoryGroupedProjectsList = {};
    map(allCategories, categoryName => {
      const projectsWithCategory = filter(projectList, project => {
        return includes(project['categories'], categoryName);
      });
      categoryGroupedProjectsList = {
        ...categoryGroupedProjectsList,
        [categoryName]: projectsWithCategory,
      };
      categoryGroupedProjectsList['NA'] = naProjectsList;
    });

    return categoryGroupedProjectsList;
  }, [props]);

  const renderTabs = React.useCallback(
    () => (
      <div className="btn-group m-auto">
        {props.isProjectsIndex && (
          <button
            className={tabClass('categoryGroup')}
            onClick={() => handleCurrentTab('categoryGroup')}
          >
            <i className="la la-object-group" />
          </button>
        )}
        <button
          className={tabClass('table')}
          onClick={() => handleCurrentTab('table')}
        >
          <i className="la la-th-list" />
        </button>
        <button
          className={tabClass('grid')}
          onClick={() => handleCurrentTab('grid')}
        >
          <i className="flaticon-squares-3" />
        </button>
      </div>
    ),
    [props.isProjectsIndex, tabClass]
  );

  const renderCategoryGroupView = React.useCallback(() => {
    const { tenantTerms, links, csrfToken } = props;

    const categoryProjectsList = getCategoryGroupedProjectsList();

    return (
      <div className="kt-portlet kt-portlet--full-height">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              My {tenantTerms.termProject.plural}{' '}
              <small className="kt-font-brand">( Category Grouped )</small>
            </h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-group">{renderTabs()}</div>
          </div>
        </div>
        <div className="kt-portlet__body plr-0 pb-0">
          <div className="col-md-12 plr-0">
            <ProjectsCategoryGroup
              tenantTerms={tenantTerms}
              links={links}
              projectList={categoryProjectsList}
              csrfToken={csrfToken}
            />
          </div>
        </div>
      </div>
    );
  }, [getCategoryGroupedProjectsList, props, renderTabs]);

  const renderTableView = React.useCallback(() => {
    const { tenantTerms, links, viewAll, projectList, csrfToken } = props;

    return (
      <div className="kt-portlet kt-portlet--full-height">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              My {tenantTerms.termProject.plural}
            </h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-group">{renderTabs()}</div>
          </div>
        </div>
        <div className="kt-portlet__body plr-0">
          <div className="col-md-12 plr-0">
            <ProjectTable
              projectList={projectList}
              tenantTerms={tenantTerms}
              links={links}
              viewAll={viewAll}
              csrfToken={csrfToken}
            />
          </div>
        </div>
      </div>
    );
  }, [props, renderTabs]);

  const renderGridView = React.useCallback(() => {
    const { tenantTerms, links, viewAll } = props;

    const categoryGroupedProjectsList = getCategoryGroupedProjectsList();

    return (
      <ProjectGrid
        renderHeaderTools={renderTabs}
        projectList={categoryGroupedProjectsList}
        groupProjectsByCategory={true}
        tenantTerms={tenantTerms}
        links={links}
        viewAll={viewAll}
      />
    );
  }, [getCategoryGroupedProjectsList, props, renderTabs]);

  const { isProjectsIndex } = props;

  React.useEffect(() => {
    if (!isProjectsIndex && isEqual(currentTab, CATEGORY_GROUP)) {
      setCurrentTab(DEFAULT_TAB);
    }
  }, [currentTab, isProjectsIndex]);

  const getTabLayout = React.useCallback(() => {
    switch (currentTab) {
      case 'table': {
        return renderTableView();
      }
      case 'grid': {
        return renderGridView();
      }
      case 'categoryGroup': {
        return renderCategoryGroupView();
      }
      default: {
        return renderTableView();
      }
    }
  }, [currentTab, renderCategoryGroupView, renderGridView, renderTableView]);

  let tabLayout = getTabLayout();

  return <React.Fragment>{tabLayout}</React.Fragment>;
};

ProjectSwitchView.defaultProps = {
  isProjectsIndex: true,
};

ProjectSwitchView.propTypes = {
  links: PropTypes.shape({
    projectsUrl: PropTypes.string,
  }),
  csrfToken: PropTypes.string,
  isProjectsIndex: PropTypes.bool,
  viewAll: PropTypes.bool,
  projectList: PropTypes.array,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(ProjectSwitchView);

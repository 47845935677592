export const projectFormReducer = (state, action) => {
  switch (action.type) {
    case 'handleModelAttributeChange':
    case 'toggleFlag':
    case 'handleCustomMultiSelectChange': {
      return { ...state, ...action.payload };
    }
    case 'initiateSubmit': {
      return { ...state, loading: true };
    }
    case 'submitSucceed':
    case 'submitFailed': {
      return { ...state, loading: false };
    }
    case 'onFileChange': {
      return {
        ...state,
        assets: action.assets,
        existingAssets: action.existingAssets,
      };
    }
    case 'onAssetFolderChange': {
      return { ...state, assetsWithFolders: action.payload };
    }
    case 'handleBusinessTableValueChange': {
      const { day, type, newValuesByType } = action.payload;
      return {
        ...state,
        businessHourState: {
          ...state.businessHourState,
          [day]: {
            ...state.businessHourState[day],
            [type]: newValuesByType,
          },
        },
      };
    }
    case 'handleOwnersAssignment': {
      return { ...state, selectedOwners: action.payload };
    }
    default: {
      return state;
    }
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

const QRCodeWrapper = ({ value, ...props }) => <QRCode value={value} {...props} />;

QRCodeWrapper.propTypes = {
  value: PropTypes.string.isRequired,
};

export default QRCodeWrapper;

import { useContext } from 'react';
import { RepliesContext } from '../replies/ReplyList';

const useRepliesContext = () => {
  const repliesContext = useContext(RepliesContext);
  if (repliesContext === undefined) {
    throw new Error(
      'useRepliesContext must be used within a LearningForumProvider'
    );
  }
  return repliesContext;
};

export default useRepliesContext;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../common/inputs/text';
import Http from '../common/Http';
import { convertArrayOfObjectsToHash } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

const RoleMapper = ({
  roles, roleMappedGroups, csrfToken, links
}) => {
  const [loading, setLoading] = useState(false);
  const [mappedRoles, setMappedRoles] = useState(convertArrayOfObjectsToHash(roleMappedGroups, "rbacRoleId"));

  const handleRoleMapping = (roleId, groupName) => {
    setMappedRoles(prevMappedRoles => ({
      ...prevMappedRoles,
      [roleId]: {
        id: !_.isNil(prevMappedRoles[roleId]) ? prevMappedRoles[roleId]['id'] : null,
        groupName,
        rbacRoleId: roleId
      }
    }));
  }

  const handleSave = () => {
    if (loading) return;

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .post(links.mapRoles)
      .setPostData({ mappedRoles })
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(
        ({
          data: {
            meta: { redirectionUrl },
          },
        }) => {
          window.location.href = redirectionUrl;
        }
      )
      .onError((_err) => {
        setLoading(false);
      })
      .exec();
  }
  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            Azure Role Mapper
          </h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div className="table-responsive">
          <table className="table table-striped">
            <thead>
              <tr>
                <th><span className="pl-15">Rbac Role Name</span></th>
                <th>Azure Group Name</th>
              </tr>
            </thead>
            <tbody>
            {
              _.map(roles, role => (
                <tr key={role.id}>
                  <td><span className="pl-15 kt-font-bold">{role.name}</span></td>
                  <td>
                    <TextInput
                      placeholder="Ex: Admin Group"
                      name="roleMapper"
                      onInputChange={({ target }) =>
                        handleRoleMapping(role.id, target.value)
                      }
                      value={!_.isNil(mappedRoles[role.id]) ? mappedRoles[role.id]['groupName'] : ''}
                      disabled={loading}
                    />
                  </td>
                </tr>
              ))
            }
            </tbody>
          </table>
        </div>
        <div className="kt-portlet__foot d-flex justify-content-end">
            <button
              className="btn btn-success btn-sm"
              onClick={handleSave}
              disabled={loading}
            >{loading ? "Saving..." : "Save"}</button>
        </div>
      </div>
    </div>
  )
}

RoleMapper.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  roleMappedGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    groupName: PropTypes.string.isRequired,
    rbacRoleId: PropTypes.number.isRequired,
  })).isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    mapRoles: PropTypes.string.isRequired
  }).isRequired,
};

export default withErrorHandler(RoleMapper);

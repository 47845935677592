import React from 'react';
import PropTypes from 'prop-types';
import EventsTable from './EventsTable';
import { withErrorHandler } from '../hoc/withErrorHandler';

const EventsPage = props => (
  <div className="kt-portlet kt-portlet--mobile">
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">Events</h3>
      </div>
    </div>
    <div className="kt-portlet__body plr-0">
      <div className="kt-section">
        <div className="kt-section__content">
          <EventsTable {...props} />
        </div>
      </div>
    </div>
  </div>
);

EventsPage.defaultProps = {
  userList: [],
  resourceList: [],
};

EventsPage.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  userList: PropTypes.array,
  resourceList: PropTypes.array,
};

export default withErrorHandler(EventsPage);

import includes from 'lodash/includes';
import toLower from 'lodash/toLower';

export const APP_FOLDERS_URL_CONFIG = {
  // key format is in snake case
  courses: '/folders/protected/resource_list/courses',
  projects: '/folders/protected/resource_list/projects',
};

export const ASSET_FILTER_OPTIONS = {
  All: 'any',
  Videos: 'video',
  Images: 'image',
  Documents: 'document',
};

export const ASSET_TYPE_HASH_PARAM = '#asset_type=';
export const DEFAULT_ASSET_FILTER_TYPE = 'any';
export const ASSET_MIMETYPE_FILTER_PREDICATES = {
  any: () => true,
  image: mimeType => includes(toLower(mimeType), 'image'),
  video: mimeType => includes(toLower(mimeType), 'video'),
  document: mimeType =>
    !includes(toLower(mimeType), 'video') &&
    !includes(toLower(mimeType), 'image'),
};

export const NOTIFICATION_MESSAGES_HELPER = {
  folderCreation: ({ folderName }) =>
    `New Folder: ${folderName} Created Successfully`,
  folderUpdate: ({ folderName }) =>
    `Folder: ${folderName} Renamed Successfully`,
  fileUpload: () => 'Files Uploaded Successfully',
  shareListUpdate: ({ contentIdentifier }) =>
    `${contentIdentifier} - Shared Successfully`,
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';

import DatePicker from 'react-datepicker';
import { getDateInstance } from '../../common/utils';
import {
  DATE_TIME_FORMATS,
  DATE_TIME_PICKER_FORMATS,
} from '../../common/constants';

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.
class CustomDatePicker extends Component {
  constructor(props) {
    super(props);

    const { data, answerData } = props;
    const { field_name } = data;

    let prefilledValue = null;
    if (
      !isNil(answerData) &&
      !isNil(answerData[field_name]) &&
      !!answerData[field_name]
    ) {
      prefilledValue = getDateInstance(answerData[field_name] || '');
    }

    this.state = {
      value: prefilledValue,
    };
  }

  handleChange = value => {
    this.setState({
      value: value,
    });
  };

  getCompatibleDateFormat = format =>
    format.replace(DATE_TIME_FORMATS.date, DATE_TIME_PICKER_FORMATS.date);

  render() {
    const { value } = this.state;
    const { data, dateFormat } = this.props;
    const { read_only } = data;

    let updatedDateFormat = dateFormat;
    if (dateFormat) {
      updatedDateFormat = this.getCompatibleDateFormat(dateFormat);
    }

    return (
      <React.Fragment>
        <DatePicker
          {...this.props}
          selected={value}
          onChange={this.handleChange}
          disabled={read_only}
          shouldCloseOnSelect={true}
          preventOpenOnFocus={true}
          dateFormat={updatedDateFormat}
        />
      </React.Fragment>
    );
  }
}

CustomDatePicker.propTypes = {
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  data: PropTypes.shape({
    field_name: PropTypes.string,
    read_only: PropTypes.bool,
  }),
  dateFormat: PropTypes.string,
};
export default CustomDatePicker;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { renderTopicBody } from './utils';

const TopicSuggestion = ({
  topic: {
    repliesCount,
    body,
    recentActivityAt,
    postedAt,
    links: { show },
  },
  category,
}) => {
  return (
    <a href={show} target="_blank" rel="noreferrer">
      <div className="alert mb-2 topic-suggestion">
        <p>
          <b>{renderTopicBody(body)}</b>
        </p>
        <div className="row font-11">
          <div className="col-md-6">
            <b>Category :</b> {category.name}
            <br />
            <b>Posted At :</b> {postedAt}
          </div>
          <div className="col-md-6">
            <b>Reply Count :</b> {repliesCount}
            <br />
            {recentActivityAt && (
              <Fragment>
                <b>Recent Activity At :</b> {recentActivityAt}
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};

TopicSuggestion.propTypes = {
  topic: PropTypes.shape({
    body: PropTypes.string.isRequired,
    links: PropTypes.shape({
      show: PropTypes.string.isRequired,
    }),
    postedAt: PropTypes.string.isRequired,
    recentActivityAt: PropTypes.string,
    repliesCount: PropTypes.number.isRequired,
  }).isRequired,
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default TopicSuggestion;

import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';

import { ANONYMOUS_USER } from './constants';
import { getStartAndEndDatesForAGivenPeriod } from '../common/utils';

export const constructUserResponses = responses =>
  map(responses, userResponse => ({
    ...userResponse,
    user: isNil(userResponse['user']) ? ANONYMOUS_USER : userResponse['user'],
  }));

export const constructUserResponseSentimentsHash = props => {
  const { userResponse } = props;

  if (isEmpty(userResponse.sentiments)) return {};

  return groupBy(userResponse.sentiments, 'field_name');
};

// getTab function has no usage on the latest UserResponsesIndex Component.
// getTab has been left for the future reference of the component.

// export const getTab = props => {
//   return {
//     ...(props.featureFlags.isFormsEnabled && {
//       responses: 'responses',
//     }),
//     ...(props.socialReviews.isYelpBusinessEnabled && {
//       yelpReviews: 'yelpReviews',
//     }),
//     ...(props.socialReviews.isFacebookBusinessEnabled && {
//       facebookReviews: 'facebookReviews',
//     }),
//     ...(props.socialReviews.isGoogleBusinessEnabled && {
//       googleReviews: 'googleReviews',
//     }),
//     ...(!props.featureFlags.isSingleProjectTenant && {
//       heatMap: 'heatMap',
//     }),
//   };
// };

// updated the tabs array conditional process, the previous method will create a false as item in the array, which leads to invisible tab.
// now the issue has been fixed by conditionally pushing the object.
export const getTabs = props => {
  const Tabs = [];

  if (props.featureFlags.isFormsEnabled) {
    Tabs.push({
      key: 'responses',
      label: 'RESPONSES',
    });
  }

  if (!props.featureFlags.isSingleProjectTenant) {
    Tabs.push({
      key: 'heatMap',
      label: 'HEAT MAP',
    });
  }

  return Tabs;
};

export const getFilterOptionsQueryParams = filterOptions => {
  const {
    selectedForms,
    selectedProjects,
    selectedProjectCategories,
    impression,
    selectedResponsePeriod,
  } = filterOptions;

  let filterQueries = {};

  if (!isEmpty(selectedForms)) {
    filterQueries.formIds = map(selectedForms, 'id');
  }
  if (!isEmpty(selectedProjects)) {
    filterQueries.projectIds = map(selectedProjects, 'id');
  }
  if (!isEmpty(selectedProjectCategories)) {
    filterQueries.projectCategoryIds = map(selectedProjectCategories, 'id');
  }
  if (!isEmpty(impression)) {
    filterQueries.sentiment = impression;
  }
  if (!isEmpty(selectedResponsePeriod)) {
    const { startDate, endDate } = getStartAndEndDatesForAGivenPeriod(
      selectedResponsePeriod
    );
    filterQueries = {
      ...filterQueries,
      timeRange: {
        startDateTime: startDate.format(),
        endDateTime: endDate.format(),
      },
    };
  }

  return filterQueries;
};

export const getHeatMapFilterOptionsQueryParams = filterOptions => {
  const {
    selectedResponsePeriod,
    selectedResponseChannels,
    selectedProjectCategories,
  } = filterOptions;

  let filterQueries = {};
  if (!isEmpty(selectedResponseChannels)) {
    filterQueries.responseChannels = map(selectedResponseChannels, 'value');
  }
  if (!isEmpty(selectedProjectCategories)) {
    filterQueries.projectCategoryIds = map(selectedProjectCategories, 'id');
  }
  if (!isEmpty(selectedResponsePeriod)) {
    const { startDate, endDate } = getStartAndEndDatesForAGivenPeriod(
      selectedResponsePeriod
    );
    filterQueries = {
      ...filterQueries,
      timeRange: {
        startDateTime: startDate.format(),
        endDateTime: endDate.format(),
      },
    };
  }

  return filterQueries;
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../common/inputs/multiSelect';
import { renderOptions } from '../common/utils';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import SubmitButton from '../common/presentational/submitButton';

const CourseTeamForm = ({
  selectedTeams,
  courseId,
  links,
  totalTeams,
  token,
}) => {
  const [selectedTeamIds, setSelectedTeamIds] = useState(selectedTeams);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();

    await new Http()
      .setToken(token)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .post(links.addTeams, {
        id: courseId,
        course: {
          team_ids: selectedTeamIds,
        },
      })
      .onSuccess(_response => {
        window.location.href = links.courseShow;
      })
      .onError(() => setLoading(false))
      .exec();
  };
  const handleSelectedTeamIds = values => {
    const selectedValues = _.map(values, 'value');
    setSelectedTeamIds(selectedValues);
  };

  const renderableDOMSection = loading ? (
    <Spinner />
  ) : (
    <div>
      <form
        onSubmit={handleSubmit}
        className="kt-form kt-form--label-right kt-form--fit"
      >
        <div className="kt-form__heading">
          <h3 className="kt-form__heading-title">Teams</h3>
        </div>
        <div className="form-group kt-form__group row">
          <label className="col-2 col-form-label">Team Sections</label>

          <div className="col-8">
            <MultiSelect
              options={renderOptions(totalTeams)}
              onChange={values => handleSelectedTeamIds(values)}
              value={selectedTeamIds}
              optionIdentifier="value"
            />
          </div>
        </div>

        <div className="kt-separator kt-separator--dashed"></div>
        <div className="kt-form__section">
          <div className="form-group kt-form__group">
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );

  return renderableDOMSection;
};

CourseTeamForm.propTypes = {
  courseId: PropTypes.number,
  totalTeams: PropTypes.array.isRequired,
  selectedTeams: PropTypes.array.isRequired,
  token: PropTypes.string.isRequired,
  links: PropTypes.shape({
    addTeams: PropTypes.string.isRequired,
    courseShow: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseTeamForm);

import React, { useEffect, useCallback, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { getScrollPercentage } from './utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

// Advisable to keep the paging data in a ref - parent component

const InfinityLoader = ({
  renderer,
  loadMoreRows,
  hasMoreRows,
  data,
  isLoading,
  enableLoadMoreButton,
  loadMoreButtonText,
  scrollPercentage,
  isWrapperTable,
}) => {
  const loadMoreRowsRef = useRef(loadMoreRows);
  const hasMoreRowsRef = useRef(hasMoreRows);

  const debouncedOnScroll = useCallback(
    _.debounce((fn) => fn(), 400, { maxWait: 1000 }),
    []
  );

  useEffect(() => {
    !enableLoadMoreButton && window.addEventListener('scroll', onScrollListener);

    return () => {
      !enableLoadMoreButton && window.removeEventListener('scroll', onScrollListener);
    };
  }, []);

  useEffect(() => {
    hasMoreRowsRef.current = hasMoreRows;
  }, [hasMoreRows]);

  useEffect(() => {
    loadMoreRowsRef.current = loadMoreRows;
  }, [loadMoreRows]);

  const onScrollListener = () => {
    debouncedOnScroll(onScroll);
  };

  const onScroll = () => {
    const scrollPercent = getScrollPercentage();
    if (scrollPercent >= scrollPercentage) {
      fetchData();
    }
  };

  const hasMoreRowsToLoad = hasMoreRowsRef.current();

  const fetchData = () => {
    if (hasMoreRowsToLoad && !isLoading) {
      loadMoreRowsRef.current();
    }
  };

  const WrappingElement = isWrapperTable ? 'tr' : 'div';
  const ChildElement = isWrapperTable ? 'td' : 'div';
  const childProps = isWrapperTable ? { colSpan: 6 } : {};

  return (
    <Fragment>
      {data.map((value, idx) => {
        return renderer(value);
      })}

      {enableLoadMoreButton && hasMoreRowsToLoad && !isLoading && (
        <WrappingElement className="text-center my-2">
          <ChildElement {...childProps}>
            <button
              className="btn app-btn-primary btn-sm"
              onClick={() => {
                fetchData();
              }}
            >
              {loadMoreButtonText}
            </button>
          </ChildElement>
        </WrappingElement>
      )}

      {isLoading && (
        <WrappingElement className="text-center my-2">
          <ChildElement {...childProps}>
            <div className="d-flex align-items-center justify-content-center my-4">
              <div className="spinner-border text-darkred" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <h4 className="text-dark-50 ml-3">Loading...</h4>
            </div>
          </ChildElement>
        </WrappingElement>
      )}
    </Fragment>
  );
};

InfinityLoader.propTypes = {
  renderer: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  hasMoreRows: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isWrapperTable: PropTypes.bool,
  enableLoadMoreButton: PropTypes.bool,
  loadMoreButtonText: PropTypes.string,
  scrollPercentage: PropTypes.number,
};

InfinityLoader.defaultProps = {
  enableLoadMoreButton: false,
  isLoading: false,
  isWrapperTable: false,
  loadMoreButtonText: 'Load More',
  scrollPercentage: 80,
};

export default withErrorHandler(InfinityLoader);

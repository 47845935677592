import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormStep from '../../common/Formstep';
import NewFormGenerator from './NewFormGenerator';

import { withErrorHandler } from '../../hoc/withErrorHandler';

import {
  STEP_FORM_DETAILS_ID,
  STEP_RESPONSE_MESSAGES_ID,
  STEP_FORM_GENERATOR_ID,
} from './constants';

const FormGenerator = ({ enableConfirmationMessagesSection, ...props }) => {
  const [stepId, setStepId] = useState(STEP_FORM_DETAILS_ID);

  const steps = [
    {
      name: 'Form Details',
      id: STEP_FORM_DETAILS_ID,
      iconClassName: 'fas fa-info',
    },
    {
      name: 'Form Generator',
      id: STEP_FORM_GENERATOR_ID,
      iconClassName: 'fab fa-wpforms',
    },
    ...(enableConfirmationMessagesSection
      ? [
          {
            name: 'Response Messages',
            id: STEP_RESPONSE_MESSAGES_ID,
            iconClassName: 'far fa-comment-dots',
          },
        ]
      : []),
  ];

  return (
    <div className="mt-3">
      <h3 className="kt-subheader__title py-3 text-black-50">Form Builder</h3>
      <FormStep
        steps={steps}
        onStepChange={stepId => setStepId(stepId)}
        activeId={stepId}
        startingStepId={STEP_FORM_DETAILS_ID}
        endingStepId={steps[steps.length - 1]['id']}
      />

      <NewFormGenerator
        {...props}
        enableConfirmationMessagesSection={enableConfirmationMessagesSection}
        activeFormStep={stepId}
        lastFormStepId={steps[steps.length - 1]['id']}
      />
    </div>
  );
};

FormGenerator.propTypes = {
  enableConfirmationMessagesSection: PropTypes.bool,
};

FormGenerator.defaultProps = {
  enableConfirmationMessagesSection: true,
};

export default withErrorHandler(FormGenerator);

import { useEffect } from 'react';

const useKeyboardKey = (key, callBack) => {
  useEffect(() => {
    function onKeyPress(e) {
      if (e.keyCode === key) callBack();
    }
    document.addEventListener('keyup', onKeyPress, false);
    return () => document.removeEventListener('keyup', onKeyPress, false);
  }, [callBack, key]);
};

export default useKeyboardKey;

import React, { useMemo, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';
import map from 'lodash/map';
import includes from 'lodash/includes';
import debounce from 'lodash/debounce';

import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';
import TransitionModal from '../common/Modal/Modal';

const DEFAULT_STORES_VISIBILITY_COUNT = 5;
const Stores = ({ memberName, stores }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isExpandable = useMemo(() => (
    stores.length > DEFAULT_STORES_VISIBILITY_COUNT
  ), [stores]);

  const toggleVisibility = useCallback(() => setIsExpanded(v => !v), []);

  const renderStores = (storesToRender) => (
    <div style={{ maxHeight: '60vh', overflow: 'auto' }}>
      {
        storesToRender.map(
          (store) => (
            <span
              key={store}
              className='app-btn-outline-secondary p-2 mx-1 my-1 disabled'
              style={{ opacity: 1 }}
            >
              #{store}
            </span>
          )
        )
      }
    </div>
  )

  return (
    <>
      {renderStores(stores.slice(0, DEFAULT_STORES_VISIBILITY_COUNT))}
      {
        isExpandable
          ?
            (
              <a
                className="nav-link active px-1"
                onClick={toggleVisibility}
                style={{ textDecoration: 'underline !important' }}
              >
                Show all
              </a>
            )
          : null
      }

      <TransitionModal
        header={`Stores where ${memberName} is a member:`}
        content={isExpanded ? renderStores(stores) : ""}
        modalState={isExpanded}
        onClose={toggleVisibility}
      />
    </>
  );
};

const CustomRoles = ({ roles }) => (
  roles.map(
    (role) => (
      <span
        key={role}
        className='app-btn-outline-primary p-2 mx-1 my-1 disabled'
        style={{ opacity: 1 }}
      >
        {role}
      </span>
    )
  )
);

const MemberTableItem = ({
  member,
  roles,
  onResend,
  onActiveInactive,
  hasCompanyName,
  onDelete,
  csrfToken,
  showStores = false,
  showCustomRoles = false,
  showBusinessId = false,
}) => {
  const {
    firstName = '',
    lastName = '',
    email = '',
    lastActiveAt,
    links = {},
    companyName = '',
    inactive = false,
    roleIds = [],
    stores = [],
    customRoles = [],
    businessId,
  } = member;

  const {
    resendConfirmation,
    memberToggleActiveness,
    loginAs,
    viewMember,
    assignRoles,
    deleteMember: deleteMemberLink,
  } = links;

  const existingRoles = filter(roles, role => includes(roleIds, role.id));

  const [selectedRoles, setSelectedRoles] = useState(existingRoles);
  const [loading, setLoading] = useState(false);
  // To restore old data when API request fails
  const previousRolesChangesRef = useRef(selectedRoles);

  const onRoleChange = async (assignedRoles, assignRolesLink) => {
    await new Http()
      .useAlerts()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .post(assignRolesLink)
      .setPostData({
        roleIds: map(assignedRoles, 'id'),
      })
      .onSuccess(() => {
        setLoading(false);
        previousRolesChangesRef.current = assignedRoles;
      })
      .onError(() => {
        setLoading(false);
        setSelectedRoles(previousRolesChangesRef.current);
      })
      .exec();
  };

  const debouncedRolesAssigner = debounce(
    val => onRoleChange(val, assignRoles),
    1000
  );

  const handleRoleChange = values => {
    setSelectedRoles(values);
    debouncedRolesAssigner(values);
  };

  return (
    <tr>
      <td className="pl-15 td-200">
        <div>
          <span style={{ fontWeight: 400 }}>
            {firstName} {lastName}
          </span>
        </div>
        <div>
          <i>{email}</i>
        </div>
      </td>
      {hasCompanyName && <td>{companyName}</td>}
      <td className="td-180">
        {formatDateTime({ date: lastActiveAt })}
      </td>
      <td>
        <MultiSelect
          placeholder={loading ? 'Saving...' : 'Assign Roles'}
          options={roles}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          value={selectedRoles}
          onChange={handleRoleChange}
          isDisabled={loading}
        />
      </td>
      {
        showBusinessId && (
          <td className="td-110">
            {businessId ? businessId : '-'}
          </td>
        )
      }
      {
        showStores && (
          <td>
            <Stores memberName={`${firstName} ${lastName}`} stores={stores} />
          </td>
        )
      }
      {
        showCustomRoles && (
          <td>
            <CustomRoles roles={customRoles} />
          </td>
        )
      }
      <td className="td-110">
        <div className="btn-group dropleft">
          <button
            type="button"
            className="btn-sm app-btn-outline-primary dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Options
          </button>
          <div className="dropdown-menu" x-placement="left-start">
            {viewMember && (
              <a className="dropdown-item" href={viewMember}>
                View
              </a>
            )}
            {loginAs && (
              <a className="dropdown-item" href={loginAs}>
                Login As
              </a>
            )}
            {resendConfirmation && (
              <button
                className="dropdown-item"
                onClick={onResend(resendConfirmation)}
              >
                Resend Confirmation
              </button>
            )}
            {memberToggleActiveness && (
              <button
                className={`dropdown-item ${
                  inactive ? 'text-success' : 'text-danger'
                }`}
                onClick={onActiveInactive(memberToggleActiveness)}
              >
                {inactive ? 'Active' : 'Inactive'}
              </button>
            )}
            {deleteMemberLink && (
              <button
                className="dropdown-item"
                onClick={onDelete(deleteMemberLink)}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

MemberTableItem.defaultProps = {
  employee: {
    firstName: '',
    lastName: '',
    email: '',
    confirmedAt: '',
    links: {
      viewMember: null,
      loginAs: null,
      resendConfirmation: null,
      active: null,
      memberToggleActiveness: null,
      deleteMember: null,
    },
  },
  hasCompanyName: false,
  showStores: false,
  showCustomRoles: false,
};

MemberTableItem.propTypes = {
  hasCompanyName: PropTypes.bool,
  member: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    confirmedAt: PropTypes.string.isRequired,
    links: PropTypes.object.isRequired,
    companyName: PropTypes.string,
    inactive: PropTypes.bool,
    roleIds: PropTypes.array,
    businessId: PropTypes.string,
  }),
  roles: PropTypes.array.isRequired,
  onActiveInactive: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  csrfToken: PropTypes.string.isRequired,
  showStores: PropTypes.bool,
  showCustomRoles: PropTypes.bool,
  showBusinessId: PropTypes.bool,
};

export default withErrorHandler(MemberTableItem);

import React from 'react';
import fiveHunderedImg from './500.png';
import './style.scss';

const ServerError = ({ links: { homePage } }) => {
  return (
    <div className="server-error-page">
      <img src={fiveHunderedImg} alt="500" />

      <h1>
        <span>500</span> <br />
        Server error
      </h1>

      <h5 className="m-3">Sorry, it’s not your fault. It’s the system.</h5>
      <h5 className="mt-4">Development Team have been automatically notified</h5>
      <h5>We are currently trying to fix the problem.</h5>
      <button
        className="btn-outline-darkred"
        onClick={() => {
          window.location.href = homePage;
        }}
      >
        Back to homepage
      </button>
    </div>
  );
};

export default ServerError;

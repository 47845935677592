import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import CurriculumsIndex from '../../curriculums/index';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';

const CURRICULUM_SUB_TABS = ['published', 'unpublished'];

const CurriculumTab = ({
  fetchUrl,
  tenantTerms,
  csrfToken,
}) => {
  const [isLoading, setLoading] = useState(true);
  const [curriculums, setCurriculums] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [links, setLinks] = useState({});
  const [activeTab, setActiveTab] = useState(CURRICULUM_SUB_TABS[0]);
  const keywordSearchTimeoutRef = useRef(null);

  const renderTabs = () => (
    <ul className="nav nav-tabs justify-content-center" role="tablist">
      {CURRICULUM_SUB_TABS.map((tab) => (
        <li className="nav-item" key={tab}>
          <a
            className={`nav-link${activeTab === tab ? ' active' : ''}`}
            onClick={() => {
              setActiveTab(tab);
            }}
          >
            <strong>{tab}</strong>
          </a>
        </li>
      ))}
    </ul>
  );

  const fetchCurriculums = useCallback((keyword = '') => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${fetchUrl}?status=${activeTab}&keyword=${keyword}`)
      .onSuccess((res) => {
        setLinks(res.data.links);
        setCurriculums(res.data.curriculums);
        setLoading(false);
      })
      .onError(() => setLoading(false))
      .exec();
  }, [activeTab])

  useEffect(() => {
    fetchCurriculums();
  }, [activeTab]);

  useEffect(() => {
    clearTimeout(keywordSearchTimeoutRef.current);
    keywordSearchTimeoutRef.current = setTimeout(() => fetchCurriculums(keyword), 500);
  }, [keyword]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="d-flex justify-content-center flex-wrap-reverse mb-2">
        <div className="new-theme-tabs align-self-baseline courses-admin-tabs">
          <div className="d-flex">{renderTabs()}</div>
        </div>
      </div>
      <div className="tab-content">
        <CurriculumsIndex
          curriculums={curriculums}
          tenantTerms={tenantTerms}
          csrfToken={csrfToken}
          links={links}
          keyword={keyword}
          onKeywordChange={setKeyword}
          showNewCurriculumLink
        />
      </div>
    </div>
  );
};

CurriculumTab.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({}).isRequired,
};

export default withErrorHandler(CurriculumTab);

import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import Http from '../../common/Http';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import values from 'lodash/values';
import size from 'lodash/size';
import startCase from 'lodash/startCase';
import capitalize from 'lodash/capitalize';

import {
  InfiniteLoader,
  WindowScroller,
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import './virtualizedStyles.scss';

import { SkeletonRow, SkeletonWrap } from '../../SkeletonLoading/index';
import Select from '../../common/inputs/select';
import InitialsAvatar from '../../common/Avatar';
import Popover from '../../common/presentational/Popover';
import ReviewRating from './ReviewRating';
import SentimentSelect from '../../common/inputs/SentimentSelect';
import ProjectSelect from '../../common/inputs/ProjectSelect';
import MultiSelect from '../../common/inputs/multiSelect';

import {
  PERIOD_FILTER_OPTIONS,
  getStartAndEndDatesForAGivenPeriod,
  formatDateTime,
  getSentimentColor,
} from '../../common/utils';
import { getReviewSourceLabel } from './utils';
import { REVIEW_BODY_CHAR_LIMIT } from './constants';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import {
  reviewsDataReducer,
  reviewsMetaDataReducer,
} from './socialReviewsReducer';

const RESET_REVIEWS_DATA = Object.freeze({
  reviewers: {},
  projects: {},
  projectCategories: {},
});

const getReviewsDataInitialState = props => {
  return {
    reviews: [],
    reviewFilters: {
      selectedProjects: props.isProjectShowPage
        ? [{ id: props.projectId }]
        : null,
      selectedProjectCategories: null,
      reviewedAt: null,
      selectedSentiment: null,
    },
    loadingReviews: true,
    pagingMeta: {
      rowsLoading: false,
      hasNextPage: true,
      startIndex: 0,
    },
  };
};

const REVIEWS_META_DATA_INITIAL_STATE = {
  ...RESET_REVIEWS_DATA,
  loadingReviewsMetaData: true,
};

const SocialReviews = props => {
  const [expandedReviewId, setExpandedReviewId] = useState(null);
  const REVIEWS_DATA_INITIAL_STATE = getReviewsDataInitialState(props);

  const [reviewsMetaDataState, dispatchReviewsMetaDataActions] = useReducer(
    reviewsMetaDataReducer,
    REVIEWS_META_DATA_INITIAL_STATE
  );
  const [reviewsDataState, dispatchReviewsDataActions] = useReducer(
    reviewsDataReducer,
    REVIEWS_DATA_INITIAL_STATE
  );

  const isValidFilterValue = filterValue =>
    !isNil(filterValue) && !isEmpty(filterValue);

  const fetchReviewsData = async (options = {}) => {
    const { links, csrfToken, reviewSource } = props;
    const {
      reviewFilters: {
        selectedProjects,
        selectedProjectCategories,
        reviewedAt,
        selectedSentiment,
      },
      pagingMeta: { startIndex },
    } = reviewsDataState;
    const isInfiniteLoader = !isEmpty(options) && options.isInfiniteLoader;

    let filterQueryParams = { source: reviewSource };
    if (isValidFilterValue(selectedProjects)) {
      filterQueryParams.projectIds = map(selectedProjects, 'id');
    }
    if (isValidFilterValue(selectedProjectCategories)) {
      filterQueryParams.projectCategoryIds = map(
        selectedProjectCategories,
        'id'
      );
    }
    if (isValidFilterValue(selectedSentiment)) {
      filterQueryParams.sentiment = selectedSentiment;
    }
    if (isValidFilterValue(reviewedAt)) {
      const { startDate, endDate } =
        getStartAndEndDatesForAGivenPeriod(reviewedAt);
      filterQueryParams = {
        ...filterQueryParams,
        timeRange: {
          startDateTime: startDate.format(),
          endDateTime: endDate.format(),
        },
      };
    }

    if (isInfiniteLoader && startIndex > 0) {
      filterQueryParams.paging = { startIndex };
      dispatchReviewsDataActions({ type: 'initiateReviewsDataRowsLoading' });
    } else {
      dispatchReviewsDataActions({ type: 'initiateReviewsDataLoading' });
    }

    await new Http()
      .setToken(csrfToken)
      .get(links.socialReviews)
      .setQueryParams({
        ...filterQueryParams,
      })
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        dispatchReviewsDataActions({
          type: 'resolvedReviewsDataFetch',
          payload: {
            isInfiniteLoader,
            data,
          },
        });
      })
      .onError(() => {
        dispatchReviewsDataActions({
          type: 'rejectedReviewsDataFetch',
        });
      })
      .exec();
  };

  const fetchReviewsMetaData = async () => {
    const { links, csrfToken, reviewSource } = props;

    await new Http()
      .onBegin(() =>
        dispatchReviewsMetaDataActions({ type: 'initiateReviewsMetaDataFetch' })
      )
      .setToken(csrfToken)
      .get(links.reviewsMetaData)
      .setQueryParams({
        source: reviewSource,
      })
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        dispatchReviewsMetaDataActions({
          type: 'resolvedReviewsMetaDataFetch',
          payload: data,
        });
      })
      .onError(() => {
        dispatchReviewsMetaDataActions({
          type: 'rejectedReviewsMetaDataFetch',
        });
      })
      .exec();
  };

  const getDataOnMount = async () => {
    await fetchReviewsData();
    await fetchReviewsMetaData();
  };

  React.useEffect(() => {
    getDataOnMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const expandReviewBody = reviewId => setExpandedReviewId(reviewId);
  const hideReviewBody = () => setExpandedReviewId(null);

  const handleReviewFilterSelect = (attributeName, attributeValue) => {
    dispatchReviewsDataActions({
      type: 'handleReviewFilters',
      payload: { attributeName, attributeValue },
    });
  };

  const loadMoreRows = () => {
    const {
      pagingMeta: { startIndex, rowsLoading },
    } = reviewsDataState;
    // Do not initiate another request when one is in progress
    if (rowsLoading || !(startIndex > 0)) return;

    fetchReviewsData({ isInfiniteLoader: true });
  };

  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) => {
    const {
      reviews,
      pagingMeta: { hasNextPage },
    } = reviewsDataState;

    return !hasNextPage || index < size(reviews);
  };

  // Render a list item or a loading indicator.
  const tableRowRenderer = ({ index, style, ...restProps }) => {
    const customRowStyle = {
      ...style,
      backgroundColor: index % 2 === 0 ? '#f7f8fa' : '#fff',
    };

    if (!isRowLoaded({ index })) {
      return (
        <div style={customRowStyle} key={index}>
          <div className="w-100">
            <SkeletonWrap>
              <SkeletonRow />
            </SkeletonWrap>
          </div>
        </div>
      );
    }

    return defaultTableRowRenderer({
      style: customRowStyle,
      ...restProps,
    });
  };

  const renderSentimentLabel = sentimentLabel => (
    <span className={`font-12 kt-font-${getSentimentColor(sentimentLabel)}`}>
      <i className="fa fa-circle mr-2 font-10" />
      {sentimentLabel}
    </span>
  );

  const renderFilters = () => {
    const {
      tenantTerms: { termProject },
      isProjectShowPage,
      isSingleProjectTenant,
    } = props;
    const {
      reviews,
      reviewFilters: {
        selectedProjects,
        selectedProjectCategories,
        reviewedAt,
        selectedSentiment,
      },
      loadingReviews,
    } = reviewsDataState;
    const { projects, projectCategories, loadingReviewsMetaData } =
      reviewsMetaDataState;
    const disableFiltering = loadingReviews || loadingReviewsMetaData;
    const isReviewsEmpty = isEmpty(reviews);
    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-end"
          style={{ paddingRight: '40px' }}
        >
          <button
            className="app-btn-outline-primary"
            style={{ width: 'max-content' }}
            type="button"
            disabled={isReviewsEmpty}
            data-toggle="collapse"
            data-target="#socialReviewsFilterCollapse"
            aria-expanded="false"
            aria-controls="socialReviewsFilterCollapse"
          >
            <i className="fas fa-filter"></i> Filter
          </button>
        </div>
        <div
          className="collapse multi-collapse"
          id="socialReviewsFilterCollapse"
        >
          <div className="kt-portlet m-4 p-4">
            <div className="col-xl-8 col-lg-9 col-md-10 m-auto row justify-content-center">
              {!isSingleProjectTenant && !isProjectShowPage && (
                <div className="col-md-3 col-sm-10 p-2">
                  <ProjectSelect
                    isMulti
                    placeholder={`${
                      loadingReviewsMetaData ? 'Loading' : 'Select'
                    } ${termProject.plural}`}
                    isDisabled={disableFiltering}
                    name={'selectedProjects'}
                    options={values(projects)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    value={selectedProjects || []}
                    onChange={options =>
                      handleReviewFilterSelect('selectedProjects', options)
                    }
                    noOptionsMessage={() => `No ${termProject.plural}`}
                    closeMenuOnSelect={false}
                  />
                </div>
              )}
              {!isSingleProjectTenant && (
                <div className="col-md-3 col-sm-10 p-2">
                  <MultiSelect
                    placeholder={`${
                      loadingReviewsMetaData ? 'Loading' : 'Select'
                    } Categories`}
                    name={'selectedProjectCategories'}
                    options={values(projectCategories)}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => option.id}
                    optionIdentifier="id"
                    value={selectedProjectCategories || []}
                    onChange={options =>
                      handleReviewFilterSelect(
                        'selectedProjectCategories',
                        options
                      )
                    }
                    isDisabled={disableFiltering}
                  />
                </div>
              )}
              <div className="col-md-3 col-sm-10 p-2">
                <SentimentSelect
                  name={'selectedSentiment'}
                  value={selectedSentiment || ''}
                  onChange={({ value }) =>
                    handleReviewFilterSelect('selectedSentiment', value)
                  }
                  isDisabled={disableFiltering}
                />
              </div>
              <div className="col-md-3 col-sm-10 p-2">
                <Select
                  placeholder={'Time Reviewed'}
                  name={'reviewedAt'}
                  isSearchable={false}
                  getOptionLabel={option => option.label}
                  getOptionValue={option => option.key}
                  options={PERIOD_FILTER_OPTIONS}
                  value={reviewedAt || ''}
                  optionIdentifier="key"
                  onChange={({ key }) =>
                    handleReviewFilterSelect('reviewedAt', key)
                  }
                  isDisabled={disableFiltering}
                />
              </div>
              <div className="col-md-12 d-flex justify-content-center px-1 py-2">
                <button
                  onClick={fetchReviewsData}
                  className="app-btn-primary"
                  disabled={disableFiltering}
                >
                  Filter Reviews
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderEmotions = emotions => {
    const sortedEmotions = map(emotions, (score, emotion) => [
      emotion,
      score,
    ]).sort((current, next) => next[1] - current[1]);

    return (
      <span className="comment-emotions d-flex flex-column">
        {map(sortedEmotions, ([emotion, score]) => (
          <span
            className="emotion-wrapper d-flex justify-content-between"
            key={emotion}
          >
            <span className="emotion-name">{capitalize(emotion)}</span>
            <span>
              <span className="emotion-bar align-self-end">
                <span
                  style={{
                    width: `${score * 100}%`,
                  }}
                ></span>
              </span>
              {Number.parseFloat(score).toFixed(2)}
            </span>
          </span>
        ))}
      </span>
    );
  };

  const renderReviewSourceIcon = source => {
    const reviewSource = getReviewSourceLabel(source);
    if (isEmpty(reviewSource)) return null;

    return (
      <i className={`font-16 fa fa-${reviewSource} font-${reviewSource}`} />
    );
  };

  const renderReviewerData = ({ reviewerId, sentiment }) => {
    const reviewer = reviewsMetaDataState.reviewers[reviewerId];

    let ProfileNameElement = 'span';
    let profileNameElementProps = {};
    if (!isEmpty(reviewer.profileUrl)) {
      ProfileNameElement = 'a';
      profileNameElementProps = {
        href: reviewer.profileUrl,
        target: '_blank',
        rel: 'noreferrer noopener',
      };
    }
    return (
      <div className="d-flex align-items-start">
        <InitialsAvatar name={reviewer.name} url={reviewer.imageUrl} />
        <div className="mx-2 d-flex flex-column">
          <ProfileNameElement
            className="kt-font-bold"
            {...profileNameElementProps}
          >
            {reviewer.name || 'Anonymous'}
          </ProfileNameElement>
          {!isNil(sentiment) &&
            !isEmpty(sentiment.label) &&
            renderSentimentLabel(sentiment.label)}
        </div>
      </div>
    );
  };

  const renderReviewBody = ({ id, body, sentiment }) => {
    // Review Body can be null when review contains only rating but no text content
    // Handle body: null scenario using simple N/A String
    const processableBody = body ?? 'N/A';
    const isBodyTextLarge = processableBody.length > REVIEW_BODY_CHAR_LIMIT;
    const isBodyTextExpanded = isEqual(id, expandedReviewId);
    let bodyText = processableBody;

    if (!isBodyTextExpanded) {
      bodyText = processableBody.slice(0, REVIEW_BODY_CHAR_LIMIT);
      if (isBodyTextLarge) {
        bodyText += '...';
      }
    }

    const renderBodyText = () => (
      <span title={processableBody}>
        {bodyText}
        {isBodyTextLarge &&
          (isBodyTextExpanded ? (
            <span className="kt-link" onClick={hideReviewBody}>
              Read less
            </span>
          ) : (
            <span className="kt-link" onClick={() => expandReviewBody(id)}>
              Read more
            </span>
          ))}
      </span>
    );

    return !isNil(sentiment) && !isNil(sentiment.emotions) ? (
      <Popover
        title="Emotions"
        content={renderEmotions(sentiment.emotions)}
        position="right"
      >
        {renderBodyText()}
      </Popover>
    ) : (
      renderBodyText()
    );
  };

  const renderRatingInfo = rating =>
    !isNil(rating) ? <ReviewRating rating={rating} /> : null;

  const renderOptions = ({ type, links }) => {
    const {
      tenantTerms: { termTicket },
    } = props;

    const reviewSource = getReviewSourceLabel(type);

    return (
      <div className="dropdown dropleft">
        <button
          className="app-btn-hover-secondary btn-icon btn-circle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="la la-ellipsis-h" />
        </button>
        <div className="dropdown-menu" x-placement="left-start">
          {!isNil(links.source) && !isEmpty(reviewSource) && (
            <a
              href={links.source}
              target="_blank"
              rel="noreferrer noopener"
              className="dropdown-item"
            >
              <span>
                <i className={`fa fa-${reviewSource} mr-2`} />
                {`View on ${capitalize(reviewSource)}`}
              </span>
            </a>
          )}
          {!isNil(links.ticket) && (
            <a
              href={links.ticket}
              target="_blank"
              rel="noreferrer noopener"
              className="dropdown-item"
            >
              <span>
                <i className="flaticon-security mr-2" /> View{' '}
                {termTicket.singular}
              </span>
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderReviewsTable = reviews => {
    const {
      tenantTerms: { termProject },
    } = props;
    const {
      pagingMeta: { hasNextPage },
    } = reviewsDataState;
    const { projects } = reviewsMetaDataState;
    const { isSingleProjectTenant } = props;

    const reviewsCount = size(reviews);
    const reviewsCountOffset = hasNextPage ? 30 : 0;

    return (
      <div className="kt-section reviews-table virtualized-table-overflow__visible">
        <div
          className="kt-section__content"
          style={{ overflowX: 'auto', minHeight: '300px' }}
        >
          <InfiniteLoader
            isRowLoaded={isRowLoaded}
            loadMoreRows={loadMoreRows}
            rowCount={reviewsCount + reviewsCountOffset}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, onChildScroll, scrollTop }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <Table
                        ref={registerChild}
                        onRowsRendered={onRowsRendered}
                        headerHeight={60}
                        width={width < 1200 ? 1200 : width}
                        height={height}
                        autoHeight
                        rowCount={reviewsCount + reviewsCountOffset}
                        rowGetter={({ index }) => reviews[index] || {}} // Ref: https://github.com/bvaughn/react-virtualized/issues/1012
                        rowHeight={70}
                        rowRenderer={tableRowRenderer}
                        isScrolling={isScrolling}
                        onScroll={onChildScroll}
                        overscanRowCount={10}
                        scrollTop={scrollTop}
                        noRowsRenderer={() => (
                          <h3
                            className="text-center p-3"
                            style={{ backgroundColor: '#f7f8fa' }}
                          >
                            No Reviews
                          </h3>
                        )}
                      >
                        <Column
                          dataKey="type"
                          disableSort
                          width={30}
                          flexShrink={0}
                          headerClassName="text-center"
                          className="text-center"
                          cellRenderer={({ cellData }) =>
                            renderReviewSourceIcon(cellData)
                          }
                        />
                        <Column
                          disableSort
                          label="Reviewed By"
                          dataKey="reviewerId"
                          width={220}
                          flexShrink={0}
                          cellRenderer={({ rowData }) =>
                            !isEmpty(rowData) && renderReviewerData(rowData)
                          }
                        />
                        <Column
                          disableSort
                          label="Review"
                          dataKey="body"
                          width={300}
                          flexShrink={0}
                          flexGrow={1}
                          className="text-justify"
                          cellRenderer={({ rowData }) =>
                            !isEmpty(rowData) && renderReviewBody(rowData)
                          }
                        />
                        <Column
                          dataKey="reviewedAt"
                          disableSort
                          label="Reviewed On"
                          headerClassName="text-center"
                          className="text-center"
                          width={130}
                          flexShrink={0}
                          cellRenderer={({ cellData }) => (
                            <span>
                              {!!cellData &&
                                formatDateTime({
                                  date: cellData,
                                  formatTime: false,
                                })}
                            </span>
                          )}
                        />
                        {!isSingleProjectTenant && (
                          <Column
                            dataKey="projectId"
                            disableSort
                            label={termProject.singular}
                            width={180}
                            flexShrink={0}
                            headerClassName="text-center"
                            className="text-center"
                            cellRenderer={({ cellData }) =>
                              !!projects[cellData] && (
                                <span className="btn btn-sm btn-bold btn-label-primary my-2">
                                  {projects[cellData]['name']}
                                </span>
                              )
                            }
                          />
                        )}
                        <Column
                          dataKey="rating"
                          disableSort
                          label="Rating"
                          width={180}
                          flexShrink={0}
                          cellRenderer={({ cellData }) =>
                            renderRatingInfo(cellData)
                          }
                        />
                        <Column
                          dataKey="permalink"
                          disableSort
                          width={80}
                          flexShrink={0}
                          headerClassName="text-center"
                          className="text-center"
                          cellRenderer={({ rowData }) =>
                            !isEmpty(rowData) && renderOptions(rowData)
                          }
                        />
                      </Table>
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        </div>
      </div>
    );
  };

  const renderLoadingScreen = () => (
    <React.Fragment>
      <SkeletonWrap>
        <SkeletonRow header />
        <br />
        <SkeletonRow rows={8} />
      </SkeletonWrap>
    </React.Fragment>
  );

  const { reviewSource } = props;
  const { reviews, loadingReviews } = reviewsDataState;
  const { loadingReviewsMetaData } = reviewsMetaDataState;

  return (
    <div className="kt-portlet mb-0">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{`${startCase(
            reviewSource
          )} Reviews`}</h3>
        </div>
      </div>
      <div className="kt-portlet__body plr-0">
        <div className="kt-section my-3">{renderFilters()}</div>
        <div className="kt-section">
          <div className="kt-section__content my-3">
            {loadingReviews || loadingReviewsMetaData
              ? renderLoadingScreen()
              : renderReviewsTable(reviews)}
          </div>
        </div>
      </div>
    </div>
  );
};

SocialReviews.propTypes = {
  reviewSource: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    socialReviews: PropTypes.string.isRequired,
    reviewsMetaData: PropTypes.string.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termTicket: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  projectId: PropTypes.number,
  isProjectShowPage: PropTypes.bool,
  isSingleProjectTenant: PropTypes.bool.isRequired,
};

SocialReviews.defaultProps = {
  projectId: null,
  isProjectShowPage: false,
};

export default withErrorHandler(SocialReviews);

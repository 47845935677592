import React from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import DatePicker from 'react-datepicker';
import Select from '../common/inputs/select';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
  getDateInstance,
} from '../common/utils';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import AssetManager from '../filestack/AssetManager';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';

const MemberCertificationForm = props => {
  const [loading, setLoading] = React.useState(false);
  const [assetsState, setAssetsState] = React.useState({
    assets: [],
    existingAssets: props.existingAttachments || [],
  });
  const [formState, setFormState] = React.useState({
    [props.fieldsPrefix]: Object.assign(
      {},
      {
        ...props.attributes,
        ...(!isNil(props.attributes.issuedDate) && {
          issuedDate: getDateInstance(props.attributes.issuedDate),
        }),
        ...(!isNil(props.attributes.expirationDate) && {
          expirationDate: getDateInstance(props.attributes.expirationDate),
        }),
      }
    ),
  });
  const mappedCertifications = React.useMemo(() => {
    return props.eligibleCertifications.map(c => ({
      ...c,
      label: c.name,
      value: c.id,
    }));
  }, [props.eligibleCertifications]);

  const setModelAttributeState = React.useCallback(
    (fieldName, value) => {
      const updatedModelAttributes = {
        ...formState[props.fieldsPrefix],
        [fieldName]: value,
      };

      setFormState({
        [props.fieldsPrefix]: updatedModelAttributes,
      });
    },
    [formState, props.fieldsPrefix]
  );

  const getModelAttributeValue = React.useCallback(
    attribute => formState[props.fieldsPrefix][attribute] || '',
    [formState, props.fieldsPrefix]
  );

  const [selectedCertificationId, setSelectedCertificationId] = React.useState(
    getModelAttributeValue('certificationId') || ''
  );

  const handleDateSelect = (attributeName = '') => {
    return date => {
      setModelAttributeState(attributeName, date);
    };
  };

  const handleModelAttributeChange = (attributeName = '') => {
    return event => {
      const { target } = event;

      // Select Input Changed
      if (isNil(target)) {
        const targetValue = isArray(event)
          ? map(event, option => option.value)
          : event.value;
        setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue =
          target.type !== 'checkbox' ? target.value : target.checked;
        setModelAttributeState(targetName, targetValue);
      }
    };
  };

  const handleSubmit = async event => {
    event.preventDefault();

    const { csrfToken, memberCertificationId, links } = props;
    const { assets } = assetsState;

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      formState[props.fieldsPrefix]
    );
    const assetAttributes = getAssetAttributes(assets);

    let Requester = new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [props.fieldsPrefix]: {
          ...updatedModelAttributes,
          attachments: assetAttributes,
        },
      })
      .onSuccess(() => {
        removePageLeavePreventDialog();
        setLoading(false);
        window.location.href = links.certificationsStatusList;
      })
      .onError(() => setLoading(false));

    if (isNil(memberCertificationId)) {
      Requester = Requester.post(links.create);
    } else {
      Requester = Requester.patch(links.update);
    }

    await Requester.exec();
  };

  const onFileChange = (assets, existingAssets) => {
    setAssetsState({
      assets: assets,
      existingAssets: existingAssets,
    });
  };

  const getProperDate = attribute => {
    const value = getModelAttributeValue(attribute) || '';
    if (typeof value === 'string' && !isEmpty(value)) {
      return value;
    } else if (typeof value === 'object') {
      return value;
    } else {
      return null;
    }
  };

  const { assets, existingAssets } = assetsState;

  const {
    attachmentOptions: filestackOptions,
    csrfToken,
    tenantTerms: { termCertification },
    memberCertificationId,
  } = props;

  const showIssueDate = React.useMemo(() => {
    return isNil(selectedCertificationId)
      ? false
      : mappedCertifications.filter(
          c => c.id === selectedCertificationId && c.requireIssueDate === true
        ).length > 0;
  }, [mappedCertifications, selectedCertificationId]);

  const showExpiryDate = React.useMemo(() => {
    return isNil(selectedCertificationId)
      ? false
      : mappedCertifications.filter(
          c => c.id === selectedCertificationId && c.requireExpireDate === true
        ).length > 0;
  }, [mappedCertifications, selectedCertificationId]);

  React.useEffect(() => {
    setSelectedCertificationId(getModelAttributeValue('certificationId') || '');
  }, [formState.certificationId, getModelAttributeValue]);

  if (loading) {
    return <Spinner />;
  }

  const issueDateValue = getProperDate('issuedDate') || null;
  const expireDateValue = getProperDate('expirationDate') || null;

  return (
    <div className={'kt-portlet__body'}>
      <form
        encType="multipart/form-data"
        className="kt-form kt-form--label-right kt-form--fit"
        onSubmit={handleSubmit}
      >
        <div className="kt-section kt-section--first">
          <FormRow
            label={termCertification.singular}
            inputColClassName={'col-md-6'}
          >
            <Select
              isDisabled={!isNil(props.memberCertificationId)}
              name={'certificationId'}
              placeholder={`Select a ${termCertification.singular}…`}
              options={mappedCertifications}
              value={selectedCertificationId}
              optionIdentifier="value"
              onChange={handleModelAttributeChange('certificationId')}
            />
          </FormRow>
        </div>
        <div className="kt-separator kt-separator--dashed" />
        <div className="kt-section kt-section--second">
          {showIssueDate && (
            <FormRow label={'Issue Date'}>
              <DatePicker
                className={'form-control kt-date-picker'}
                placeholderText="Select a Date"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
                selected={issueDateValue}
                onChange={handleDateSelect('issuedDate')}
              />
            </FormRow>
          )}
          {showExpiryDate && (
            <FormRow label={'Expiration Date'}>
              <DatePicker
                className={'form-control kt-date-picker'}
                placeholderText="Select a Date"
                dateFormat={DATE_TIME_PICKER_FORMATS.date}
                selected={expireDateValue}
                onChange={handleDateSelect('expirationDate')}
              />
            </FormRow>
          )}
        </div>
        {(!isNil(memberCertificationId) ||
          (!isNil(selectedCertificationId) &&
            !isEmpty(`${selectedCertificationId}`))) && [
          <div key={'AssetManager'} className="kt-section kt-section--second">
            <AssetManager
              label={'Attachments'}
              csrfToken={csrfToken}
              filestackOptions={filestackOptions}
              showGallery={true}
              existingAssets={existingAssets}
              assets={assets}
              onFileChange={onFileChange}
            />
          </div>,
          <div
            key={'Separator'}
            className="kt-separator kt-separator--dashed"
          />,
          <FormRow key={'FormActions'} label=" ">
            <CancelButton />
            <SubmitButton buttonText={'Save'} />
          </FormRow>,
        ]}
      </form>
    </div>
  );
};

MemberCertificationForm.defaultProps = {
  memberCertificationId: null,
  fieldsPrefix: 'member_certification',
  attributes: {},
};

MemberCertificationForm.propTypes = {
  fieldsPrefix: PropTypes.string,
  memberCertificationId: PropTypes.number,
  memberId: PropTypes.number.isRequired,

  eligibleCertifications: PropTypes.array.isRequired,
  attachmentOptions: PropTypes.object.isRequired,
  existingAttachments: PropTypes.array.isRequired,

  csrfToken: PropTypes.string.isRequired,

  attributes: PropTypes.object.isRequired,

  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCertification: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    create: PropTypes.string,
    list: PropTypes.string.isRequired,
    update: PropTypes.string,
  }),
};

export default withErrorHandler(MemberCertificationForm);

import map from 'lodash/map';
import flatten from 'lodash/flatten';

export const sortAssetsInOrder = (assets, existingAssets, prevOrder) => {
  let allAssets = flatten([...existingAssets, ...assets]);
  let sortedAssets = allAssets.sort((firstElement, secondElement) => {
    let firstElementIndex = prevOrder.indexOf(firstElement['handle']);
    let secondElementIndex = prevOrder.indexOf(secondElement['handle']);

    if (firstElementIndex == -1) {
      return 1;
    } else if (secondElementIndex == -1) {
      return -1;
    } else if (firstElementIndex == secondElementIndex) {
      return 0;
    } else {
      return firstElementIndex - secondElementIndex;
    }
  });

  return map(sortedAssets, 'handle');
};

import React from 'react';
import PropTypes from 'prop-types';

import ColumnWrapper from './ColumnWrapper';

const ButtonsInColumn = ({ children, className, ...otherProps }) => {
  const customClassName = [
    'flex-center flex-column align-items-start',
    className,
  ].join(' ');
  return (
    <div className={customClassName} {...otherProps}>
      {children}
    </div>
  );
};

ButtonsInColumn.ButtonWrapper = ColumnWrapper;

ButtonsInColumn.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

export default ButtonsInColumn;

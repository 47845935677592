import React from 'react';
import PropTypes from 'prop-types';

import ReactFilestack from 'filestack-react';

const renderUploader = ({ onPick }) => (
  <a className="dropdown-item" onClick={onPick}>
    <i className="flaticon-attachment" />
    New File
  </a>
);

export const NewFile = ({ onFileUploadSuccess, onFileUploadFailure }) => (
  <React.Fragment>
    {/* Move config to constants */}

    <ReactFilestack
      apikey={process.env['FILESTACK_ID']}
      actionOptions={{
        fromSources: ['local_file_system'],
        maxFiles: 5,
      }}
      onSuccess={onFileUploadSuccess}
      onError={onFileUploadFailure}
      customRender={renderUploader}
    />
  </React.Fragment>
);

export const NewFolder = ({ onNewFolderClick }) => (
  <button type="button" className="dropdown-item" onClick={onNewFolderClick}>
    <i className="flaticon-folder" />
    New Folder
  </button>
);

export const QuickActions = () => (
  <li className="kt-nav__section kt-nav__section--first kt-hide">
    <span className="kt-nav__section-text">Quick Actions</span>
  </li>
);

export const Sharing = ({ onShareClick }) => (
  <li className="kt-nav__item">
    <a href="javascript:;" className="kt-nav__link" onClick={onShareClick}>
      <i className="flaticon-chat-1" />
      <span className="kt-nav__link-text">Sharing</span>
    </a>
  </li>
);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import isEmpty from 'lodash/isEmpty';

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
  },
  fullHeightPaper: {
    height: '80%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing(2, 4, 3),
  },
  fullHeightContent: {
    overflowY: 'auto',
    flexGrow: 1,
  },
  header: {
    borderBottom: '1px solid #ddd',
    padding: theme.spacing(2, 4),
  },
  md: {
    width: '100%',
    maxWidth: '500px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
  lg: {
    width: '100%',
    maxWidth: '700px',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
  },
}));

const TransitionModal = ({
  trigger,
  content,
  header,
  modalState,
  size,
  onClose,
  disableBackdropClick,
  modalProps,
  hideClose,
  fullHeight,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(modalState);

  useEffect(() => {
    setOpen(modalState);
  }, [modalState]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }
    setOpen(false);
    if (typeof onClose === 'function') onClose();
  };

  return (
    <div>
      {trigger && (
        <div className="cursor-pointer" onClick={handleOpen}>
          {trigger}
        </div>
      )}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        {...modalProps}
      >
        <Fade in={open}>
          <div className={`${classes.paper} ${fullHeight ? classes.fullHeightPaper : ''} ${classes[size]}`}>
            {!isEmpty(header) && (
              <div
                className={`${classes.header} font-weight-50 d-flex align-items-center justify-content-between`}
              >
                <div className="text-truncate">{header}</div>
                {!hideClose && (
                  <button className="btn p-0 pl-2" onClick={handleClose}>
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            )}
            <div className={`${classes.content}  ${fullHeight ? classes.fullHeightContent : ''}`}>{content}</div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

TransitionModal.propTypes = {
  modalState: PropTypes.bool,
  size: PropTypes.oneOf(['md', 'lg']),
  trigger: PropTypes.node,
  onClose: PropTypes.func,
  content: PropTypes.node.isRequired,
  header: PropTypes.string.isRequired,
  disableBackdropClick: PropTypes.bool,
  modalProps: PropTypes.shape({}),
  hideClose: PropTypes.bool,
  fullHeight: PropTypes.bool,
};

TransitionModal.defaultProps = {
  modalState: false,
  trigger: null,
  size: 'md',
  onClose: null,
  disableBackdropClick: false,
  modalProps: {},
  hideClose: false,
  fullHeight: false,
};

export default TransitionModal;

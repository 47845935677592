// All the terms are columns of tenant table
// Splitting as organizationalTerms and User Role Terms in frontend only for readability sake

export const getOrganizationTerms = ({ tenantTerms, tenantApps }) => {
  const {
    termAssignment,
    termCertification,
    termCourse,
    termCurriculum,
    termModule,
    termProject,
    termSubmission,
    termSupport,
    termTicket,
    termUser,
  } = tenantTerms;

  const organizationalTerms = [
    {
      label: 'Project',
      key: 'term_project',
      terms: termProject,
      enabled: tenantApps.projects,
    },
    {
      label: 'Submission',
      key: 'term_submission',
      terms: termSubmission,
      enabled: true,
    },
    {
      label: 'Curriculum',
      key: 'term_curriculum',
      terms: termCurriculum,
      enabled: tenantApps.learning,
    },
    {
      label: 'Course',
      key: 'term_course',
      terms: termCourse,
      enabled: tenantApps.learning,
    },
    {
      label: 'Assignment',
      key: 'term_assignment',
      terms: termAssignment,
      enabled: tenantApps.learning,
    },
    {
      label: 'Module',
      key: 'term_module',
      terms: termModule,
      enabled: tenantApps.learning,
    },
    {
      label: 'Certification',
      key: 'term_certification',
      terms: termCertification,
      enabled: tenantApps.learning,
    },
    {
      label: 'Ticket',
      key: 'term_ticket',
      terms: termTicket,
      enabled: tenantApps.support,
    },
    {
      label: 'Support',
      key: 'term_support',
      terms: termSupport,
      enabled: tenantApps.support,
    },
    {
      label: 'User',
      key: 'term_user',
      terms: termUser,
      enabled: true,
    },
  ];

  return organizationalTerms;
};

export const getUserRoleTerms = ({ tenantTerms }) => {
  const { termMember, termReviewer, termAdmin } = tenantTerms;

  const userRoleTerms = [
    {
      label: 'Member',
      key: 'term_member',
      terms: termMember,
      enabled: true,
    },
    {
      label: 'Reviewer',
      key: 'term_reviewer',
      terms: termReviewer,
      enabled: true,
    },
    {
      label: 'Admin',
      key: 'term_admin',
      terms: termAdmin,
      enabled: true,
    },
  ];

  return userRoleTerms;
};

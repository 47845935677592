import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';

import { convertArrayOfObjectsToHash } from '../../../../common/utils';
import { withErrorHandler } from '../../../../hoc/withErrorHandler';

const MyBadges = ({ badges: { data } }) => {
  const [badgesInvolved] = useState(
    convertArrayOfObjectsToHash(data.involvedBadges, 'id')
  );

  return (
    <div className="display-grid">
      {data.earnedBadges.map(earnedBadge => {
        const badge = badgesInvolved[earnedBadge.badgeId];
        return (
          <Badge badge={badge} badgeDetails={earnedBadge} key={badge.id} />
        );
      })}
    </div>
  );
};

MyBadges.propTypes = {
  badges: PropTypes.shape({
    data: PropTypes.shape({
      involvedBadges: PropTypes.array,
      earnedBadges: PropTypes.array,
    }),
    meta: PropTypes.shape({}),
  }),
};

export default withErrorHandler(memo(MyBadges));

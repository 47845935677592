import React from 'react';
import PropTypes from 'prop-types';

import SelectInput from '../../common/inputs/select';
import FormRow from '../../common/presentational/formRow';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const FontCustomization = ({ currentFont, onChange, fontConfig }) => (
  <div className="card mt-3 text-black-50">
    <div className="card-body">
      <h3>Font Style</h3>
      <FormRow label="Font">
        <SelectInput
          name="fontStyle"
          options={fontConfig.permittedFontFamilies.map(f => ({
            value: f,
            label: f,
          }))}
          onChange={({ value }) => {
            onChange && onChange(value);
          }}
          value={currentFont}
          isClearable={false}
          optionIdentifier="value"
        />
      </FormRow>
    </div>
    <div className="card-footer" style={{ fontFamily: currentFont }}>
      <h3>Preview</h3>
      Dummy preview text. Lorem ipsum dolor sit amet consectetur adipisicing
      elit. <br />
      Error, vel necessitatibus! Debitis, saepe magni illo nemo cupiditate natus
      eum odit, rerum repellendus temporibus facere quod? Amet sed
      necessitatibus minus eaque? And If you like this change Don't forget to
      Save!
    </div>
  </div>
);

FontCustomization.propTypes = {
  currentFont: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  fontConfig: PropTypes.shape({
    permittedFontFamilies: PropTypes.array.isRequired,
  }).isRequired,
};

export default withErrorHandler(FontCustomization);

import React, { useCallback, useMemo } from 'react';
import debounce from "lodash/debounce";
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import Http from '../../common/Http';

export const Menu = props => {
  if (props.selectProps.inputValue.length === 0) return null

  return <components.Menu {...props} />;
};

const StoreFilter = React.memo(({ onChange, csrfToken }) => {
  const loadStores = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`/sso/stores?keyword=${inputValue}`)
      .onSuccess(response => {
        callback(response.data.stores);
      })
      .onError(err => console.log(err))
      .exec();
  }, [csrfToken]);

  const debouncedLoadStores = useMemo(() => debounce(loadStores, 500), [loadStores]);

  return (
    <AsyncSelect
      name="store-selector"
      placeholder="Filter by store"
      noOptionsMessage={() => "No stores found"}
      loadOptions={debouncedLoadStores}
      getOptionLabel={option => `Store-#${option['value']}`}
      getOptionValue={option => option.id}
      components={{ Menu }}
      onChange={onChange}
      cacheOptions
      isClearable
      isMulti
    />
  );
});

export default StoreFilter;

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';

import MultiSelect from '../common/inputs/multiSelect';
import Spinner from '../common/presentational/spinner';
import { SkeletonRow, SkeletonWrap } from '../SkeletonLoading/index';
import Http from '../common/Http';
import PaginationWrapper from '../hoc/PaginationWrapper';
import { renderOptions, formatDateTime } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';

const VisitsPage = ({ csrfToken, links, userList }) => {
  const [loading, setLoading] = React.useState(false);
  const [visitsToShow, setVisitsToShow] = React.useState([]);
  const [paginationObject, setPaginationObject] = React.useState({});
  const [selectedFilters, setSelectedFilters] = React.useState({
    selectedUserIds: [],
    startDate: null,
    endDate: null,
  });

  // const mapToIds = data => map(data, p => p.id);

  const fetchData = React.useCallback(
    async (pageNum = 1) => {
      const { getVisits: getVisitsLink } = links;

      let newFilterOptions = {
        user_ids: selectedFilters['selectedUserIds'],
        resource: selectedFilters['selectedResource'],
        ...(!isNil(selectedFilters['startDate']) && {
          start_date: selectedFilters['startDate'].toString() || '',
        }),
        ...(!isNil(selectedFilters['endDate']) && {
          end_date: selectedFilters['endDate'].toString() || '',
        }),
      };

      await new Http()
        .onBegin(() => setLoading(true))
        .get(`${getVisitsLink}`)
        .setQueryParams({
          page: pageNum,
          ...newFilterOptions,
        })
        .setToken(csrfToken)
        .onSuccess(res => {
          const { data } = res;
          setVisitsToShow(data.visits);
          setPaginationObject(data.meta.pagination);
          setLoading(false);
        })
        .onError(() => setLoading(false))
        .exec();
    },
    [csrfToken, links, selectedFilters]
  );

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getFilteredVisits = () => {
    fetchData();
  };

  const handleFilterSelect = attributeName => selectedValues => {
    let newSelectedFilters;

    if (attributeName === 'selectedResource') {
      // It is a single select type.
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: selectedValues.value,
      };
    } else if (attributeName.includes('Date')) {
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: selectedValues,
      };
    } else {
      newSelectedFilters = {
        ...selectedFilters,
        [attributeName]: map(selectedValues, 'value'),
      };
    }

    setSelectedFilters(newSelectedFilters);
  };

  const renderFilters = () => {
    let selectedStartDate = null;
    let selectedEndDate = null;

    if (!isNil(selectedFilters.startDate)) {
      selectedStartDate = selectedFilters.startDate;
    }

    if (!isNil(selectedFilters.endDate)) {
      selectedEndDate = selectedFilters.endDate;
    }

    return (
      <React.Fragment>
        <div className="form-group kt-form__group row visit-gantt__filter-row justify-content-center ml-15 mr-15">
          <div className="col-lg-4">
            <MultiSelect
              placeholder={'Select Users'}
              options={renderOptions(userList)}
              value={selectedFilters.selectedUserIds}
              optionIdentifier="value"
              onChange={handleFilterSelect('selectedUserIds')}
            />
          </div>
          <div className="col-lg-4 d-lg-flex">
            <DatePicker
              isClearable={true}
              dropdownMode={'select'}
              selected={selectedStartDate}
              showTimeSelect={false}
              onChange={handleFilterSelect('startDate')}
              className={'form-control form-filter m-input mt-2 mt-lg-0'}
              placeholderText="From"
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
            />
            <DatePicker
              isClearable={true}
              dropdownMode={'select'}
              selected={selectedEndDate}
              showTimeSelect={false}
              onChange={handleFilterSelect('endDate')}
              className={
                'form-control form-filter m-input mt-2 mt-lg-0 ml-lg-2'
              }
              placeholderText="To"
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
            />
          </div>
        </div>

        <div className="text-center">
          <button
            className="app-btn-primary m-btn--wide"
            onClick={getFilteredVisits}
          >
            Filter
          </button>
        </div>
      </React.Fragment>
    );
  };

  const renderVisitRow = (visit, index) => (
    <tr key={index}>
      <td>
        <span className="pl-15">{visit.userName}</span>
      </td>
      <td className="text-truncate td-250">{visit.landingPage}</td>
      <td className="td-110">{visit.deviceType}</td>
      <td className="td-110">{visit.browser}</td>
      <td className="td-110">{visit.os}</td>
      <td className="td-150">{formatDateTime({ date: visit.startedAt })}</td>
      <td className="td-110">
        <a
          type="button"
          href={visit.links.viewVisit}
          target="blank"
          className="btn btn-outline-brand btn-sm"
        >
          View Session
        </a>
      </td>
    </tr>
  );

  const renderPagination = () => {
    if (!isEmpty(visitsToShow) && !isEmpty(paginationObject)) {
      return (
        <PaginationWrapper
          paginationObject={paginationObject}
          fetchData={fetchData}
        />
      );
    }
    return <br />;
  };

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Visits</h3>
        </div>
      </div>
      <div className="kt-portlet__body plr-0">
        <div className="kt-section">
          <div className="kt-section__content">
            {loading ? (
              <React.Fragment>
                <SkeletonWrap>
                  <SkeletonRow header />
                  <br />
                  <SkeletonRow rows={8} />
                </SkeletonWrap>
                <Spinner text="Please wait while we load your assets" />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {renderFilters()}
                {renderPagination()}
                <div className="table-responsive">
                  <table className="table table-striped kt-table__row-equal-width">
                    <thead>
                      <tr>
                        <th>
                          <span className="pl-15">User</span>
                        </th>
                        <th className="td-110">Landing Page</th>
                        <th className="td-110">Device Type</th>
                        <th className="td-110">Browser</th>
                        <th className="td-110">OS</th>
                        <th className="td-150">Started At</th>
                        <th className="td-110">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {map(visitsToShow, (visit, index) =>
                        renderVisitRow(visit, index)
                      )}
                      {isEmpty(visitsToShow) && (
                        <tr>
                          <td colSpan={7} className="p-4 text-center">
                            <h4>No visit for the filters</h4>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {renderPagination()}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

VisitsPage.defaultProps = {
  userList: [],
};

VisitsPage.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  userList: PropTypes.array,
};

export default withErrorHandler(VisitsPage);

import React from 'react';
import PropTypes from 'prop-types';

const FolderActions = ({
  dropdownLinkType,
  onRenameClick: folderRenameHandler,
  onShareClick: folderShareHandler,
}) => {
  const isFlatIconType = _.isEqual(dropdownLinkType, 'flat');

  return (
    <div className={`dropdown ${isFlatIconType ? 'w-100 h-100' : ''}`}>
      <button
        className={`btn ${
          isFlatIconType
            ? 'btn-outline-secondary w-100 h-100 pr-0 border-0 folder-actions-dropdown'
            : 'btn-outline-primary btn-icon'
        }`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <i className="la la-ellipsis-v" />
      </button>
      <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end">
        <button
          className="dropdown-item"
          type="button"
          onClick={event => {
            event.preventDefault();
            folderRenameHandler();
          }}
        >
          <i className="flaticon-edit-1" />
          Rename
        </button>
        <button
          className="dropdown-item"
          type="button"
          onClick={event => {
            event.preventDefault();
            folderShareHandler();
          }}
        >
          <i className="flaticon-share" />
          Share
        </button>
      </div>
    </div>
  );
};

FolderActions.propTypes = {
  dropdownLinkType: PropTypes.oneOf(['flat', 'iconOnly']),
  onRenameClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
};

FolderActions.defaultProps = {
  dropdownLinkType: 'flat',
  onRenameClick: () => {},
  onShareClick: () => {},
};

export default FolderActions;

import React, { useEffect } from 'react';

const DateRangeFilter = () => {
  useEffect(() => {
    jQuery('.input-daterange').datepicker({ format: "yyyy/mm/dd" });
  }, []);

  return (
    <div className="input-daterange input-group" id="datepicker">
      <input
        type="text"
        className="input-sm form-control"
        name="start"
        placeholder="Start Date"
      />
      <span className="input-group-addon">
        &nbsp;to&nbsp;
      </span>
      <input
        type="text"
        className="input-sm form-control"
        name="end"
        placeholder="End Date"
      />
    </div>
  );
};

export default DateRangeFilter;

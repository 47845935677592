import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ActionCableConsumer,
  ActionCableProvider,
} from '@thrash-industries/react-actioncable-provider';

import Modal from '../../../common/Modal/Modal';
import { withErrorHandler } from '../../../hoc/withErrorHandler';

const StatusModal = ({
  onCloseCallback,
  data: socketData,
  fetchData,
  isOngoing,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    status: 'pending',
    meta: {},
  });

  const socketReceived = socData => {
    setData(socData);
  };

  const { socketConfig, clonableResource } = socketData;

  const renderIcon = () => {
    const cloneInfo = (
      <h4 className="mt-4 text-black-50">
        {clonableResource.type} Name: {clonableResource.name}
      </h4>
    );

    switch (data.status) {
      case 'running':
        return (
          <div>
            <div className="text-warning">
              <i className="fas fa-cog" style={{ fontSize: '60px' }}></i>
              <h4>Running...</h4>
            </div>
            <p>
              Please wait... <br />
              May take upto few mins
            </p>
            {cloneInfo}
          </div>
        );
      case 'succeeded':
        return (
          <div>
            <div className="text-success">
              <i
                className="far fa-check-circle"
                style={{ fontSize: '60px' }}
              ></i>
              <h4>Success!</h4>
            </div>
            {cloneInfo}
            <div className="border rounded app-text-primary p-2">
              <strong>{data.meta?.cloned_resource.name}</strong>
              <br />
              <br />
              <div className="d-flex align-items-center justify-content-around">
                <a
                  href={data.meta?.cloned_resource.links.initiate_forum_cloning}
                  className="app-btn-secondary"
                >
                  Clone Forum & Publish{' '}
                  <i className="fas fa-arrow-right ml-1"></i>
                </a>
                <a
                  href={data.meta?.cloned_resource.links.edit}
                  className="app-btn-primary"
                >
                  Publish Now <i className="fas fa-arrow-right ml-1"></i>
                </a>
              </div>
            </div>
          </div>
        );
      case 'failed':
        return (
          <div>
            <div className="text-darkred">
              <i
                className="far fa-times-circle"
                style={{ fontSize: '60px' }}
              ></i>
              <h4>Failed</h4>
            </div>
            <div className="text-darkred">
              {data.meta?.errors.map((error, i) => (
                <p key={i}>{error}</p>
              ))}
            </div>
            <button className="btn-outline-darkred" onClick={() => fetchData()}>
              Try Again
            </button>
          </div>
        );

      default:
        return (
          <div>
            {isOngoing && (
              <h4 className="text-black-50">
                Previous Clone Initiation Request for this resource is already
                in progress. Kindly continue after its review.
              </h4>
            )}
            <br />
            <div className="text-black-50">
              <i className="fas fa-stopwatch" style={{ fontSize: '60px' }}></i>
              <h4>Pending</h4>
            </div>
            {cloneInfo}
          </div>
        );
    }
  };

  return (
    <div>
      <Modal
        modalState={true}
        header={'Cloning Status'}
        content={
          <ActionCableProvider
            url={`${socketConfig.connection.origin}${socketConfig.connection.path}`}
          >
            <ActionCableConsumer
              channel={{
                channel:
                  socketConfig.channelSubscriptions.resourceCloneEventsProvider
                    .channel,
                clone_log_id:
                  socketConfig.channelSubscriptions.resourceCloneEventsProvider
                    .cloneLogId,
              }}
              onConnected={() => setLoading(false)}
              onReceived={socketReceived}
            >
              <div className="text-center">
                {loading ? (
                  <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div>{renderIcon()}</div>
                )}
              </div>

              <div className="text-right mt-4">
                <button
                  className="btn-outline-darkred"
                  onClick={onCloseCallback}
                >
                  Close
                </button>
              </div>
            </ActionCableConsumer>
          </ActionCableProvider>
        }
        onClose={onCloseCallback}
        disableBackdropClick
        hideClose={true}
      />
    </div>
  );
};

StatusModal.propTypes = {
  onCloseCallback: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  data: PropTypes.shape({
    status: PropTypes.string,
    meta: PropTypes.object,
  }).isRequired,
  isOngoing: PropTypes.bool,
};

export default withErrorHandler(StatusModal);

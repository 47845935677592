import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import castArray from 'lodash/castArray';
import isNull from 'lodash/isNull';
import map from 'lodash/map';

import Toggle from '../common/inputs/Toggle';
import { withErrorHandler } from '../hoc/withErrorHandler';
import FormRow from '../common/presentational/formRow';
import Text from '../common/inputs/text';
import TextArea from '../common/inputs/textArea';
import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import SelectInput from '../common/inputs/select';
import AssetManager from '../filestack/AssetManager';
import { removePageLeavePreventDialog } from '../common/utils';
import Spinner from '../common/presentational/spinner';
import ParticipantSelectWithList from '../courses/ParticipantSelectWithList';
import FormElementHelperText from '../common/presentational/FormElementHelperText';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants.js';

const CurriculumForm = props => {
  const [attributes, setAttributes] = React.useState(() => ({
    ...props.attributes,
    expiryDate: props.attributes.expiryDate
      ? new Date(props.attributes.expiryDate)
      : null
  }));
  const [selectedCourses, setSelectedCourses] = React.useState(
    props.curriculumCourses
  );
  const [loading, setLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('Saving');
  const [headerImage, setHeaderImage] = React.useState([]);
  const [thumbnailImage, setThumbnailImage] = React.useState([]);
  const [existingHeaderImage, setExistingHeaderImage] = React.useState(
    compact(castArray(props.existingHeaderImage))
  );
  const [existingThumbnailImage, setExistingThumbnailImage] = React.useState(
    compact(castArray(props.existingThumbnailImage))
  );

  const [selectedOwners, setSelectedOwners] = React.useState(props.owners);

  const handleToggle = attribute => value => {
    setAttributes((prevAttrs) => ({ ...prevAttrs, [attribute]: value }));
  };

  const handleSubmit = redirectionUrl => async e => {
    e.preventDefault();
    const {
      name,
      description,
      public: isPublic,
      published,
      managerExclusive,
      enableLearningProgressForManagers,
    } = attributes;
    const {
      links: { create, update },
      curriculumId,
      csrfToken,
    } = props;

    const Requestor = new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      });

    if (isNull(curriculumId)) {
      Requestor.post(create);
    } else {
      Requestor.put(update);
    }

    const isWysiwygEditableDescription = !!getAttributeValue('isWysiwygEditableDescription');

    Requestor.setPostData({
      curriculum: {
        name,
        description,
        public: isPublic,
        published,
        managerExclusive,
        enableLearningProgressForManagers,
        headerImage: headerImage,
        thumbnailImage: thumbnailImage,
        courseIds: map(selectedCourses, 'id'),
        OwnerIds: selectedOwners,
        is_wysiwyg_editable_description: isWysiwygEditableDescription,
        pinned: getAttributeValue('pinned'),
        expiryDate: getAttributeValue('expiryDate'),
      },
    })
      .onSuccess(({ data }) => {
        removePageLeavePreventDialog();
        setLoadingMessage('Redirecting');
        setLoading(false);
        window.location.href = redirectionUrl || data.redirectionUrl;
      })
      .onError(() => setLoading(false));

    await Requestor.exec();
  };

  const onHeaderImageChange = (headerImage, existingHeaderImage) => {
    setHeaderImage(headerImage || []);
    setExistingHeaderImage(existingHeaderImage || []);
  };

  const onThumbnailImageChange = (thumbnailImage, existingThumbnailImage) => {
    setThumbnailImage(thumbnailImage || []);
    setExistingThumbnailImage(existingThumbnailImage || []);
  };

  const setAttributeValue = (attributeName, attributeValue) => {
    setAttributes(prevState => ({
      ...prevState,
      [attributeName]: attributeValue,
    }));
  };

  const getAttributeValue = attribute => {
    return attributes[attribute] || '';
  };

  const handleOnChange = attrName => {
    if (attrName === 'selectedCourses') {
      return values => {
        setSelectedCourses(values);
      };
    }

    return ({ target: { name, value } }) => setAttributeValue(name, value);
  };

  const handleSetSelectedOwners = ownerIds => {
    setSelectedOwners(ownerIds);
  };

  const handlePublicFlag = isPublic => {
    setAttributes(prevState => ({
      ...prevState,
      public: isPublic,
    }));
  };

  const {
    csrfToken,
    coursesOptions,
    headerImageOptions,
    thumbnailImageOptions,
    links,
    tenantTerms: { termCourse },
    meta: { learningProgressEnabledForManagers },
  } = props;

  const isPublic = getAttributeValue('public');
  const isManagerExclusive = getAttributeValue('managerExclusive');
  const showHeaderImageUploader =
    isEmpty(existingHeaderImage) && isEmpty(headerImage);
  const showThumbnailImageUploader =
    isEmpty(existingThumbnailImage) && isEmpty(thumbnailImage);

  return (
    <Fragment>
      {loading && <Spinner text={loadingMessage} />}
      <form
        encType="multipart/form-data"
        className="kt-form kt-form--label-right kt-form--fit pt-20"
        onSubmit={handleSubmit()}
      >
        <div className="kt-section">
          <FormRow label={'Status'}>
            <Toggle
              name={'published'}
              onClick={value => setAttributeValue('published', value)}
              on={<span>Published</span>}
              off={<span>Unpublished</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={getAttributeValue('published') || false}
            />
          </FormRow>

          <FormRow label="Name" className="required-setting">
            <Text
              numberAttributes={{
                required: true,
              }}
              name="name"
              value={getAttributeValue('name')}
              onInputChange={handleOnChange('name')}
            />
          </FormRow>

          <FormRow
            label="Cover Page"
          >
          <Toggle
            name={'isWysiwygEditableDescription'}
            onClick={handleToggle('isWysiwygEditableDescription')}
            on={<span>Enabled</span>}
            off={<span>Disabled</span>}
            size="sm"
            width={100}
            height={30}
            recalculateOnResize
            offstyle="default"
            active={!!getAttributeValue('isWysiwygEditableDescription')}
          />
            <FormElementHelperText text="Create a custom designed page for the curriculum." />
          </FormRow>

          <FormRow label="Description" className="required-setting">
            <TextArea
              name="description"
              value={getAttributeValue('description')}
              onInputChange={handleOnChange('description')}
            />
          </FormRow>

          <FormRow label={'Expiry Date'}>
            <DatePicker
              selected={getAttributeValue('expiryDate')}
              showTimeSelect={false}
              minDate={new Date()}
              onChange={(v) => {
                console.log(v);
                setAttributeValue('expiryDate', v)
              }}
              className={'form-control input-sm'}
              placeholderText="Expiry Date"
              dateFormat={DATE_TIME_PICKER_FORMATS.date}
            />
          </FormRow>

          <ParticipantSelectWithList
            onChange={handleSetSelectedOwners}
            participantList={props.userList}
            selectedUsers={selectedOwners}
            participantListLabel={'Users'}
            participantLabel={'Curriculum Owners'}
            showList={false}
            showHeaderText={false}
            helperText={'These users are able to edit the curriculum.'}
            requiredField={true}
            mapUserIdWithList
          />

          <FormRow label="Banner Image">
            <AssetManager
              showLabel={false}
              csrfToken={csrfToken}
              filestackOptions={headerImageOptions}
              existingAssets={existingHeaderImage || []}
              assets={headerImage || []}
              onFileChange={onHeaderImageChange}
              showUploader={showHeaderImageUploader}
            />
            <FormElementHelperText text="This is the top banner image on the curriculum." />
          </FormRow>

          <FormRow label="Thumbnail Image">
            <AssetManager
              showLabel={false}
              csrfToken={csrfToken}
              filestackOptions={thumbnailImageOptions}
              existingAssets={existingThumbnailImage || []}
              assets={thumbnailImage || []}
              onFileChange={onThumbnailImageChange}
              showUploader={showThumbnailImageUploader}
            />
            <FormElementHelperText text="This is the small thumbnail image on the course listings page." />
          </FormRow>

          <FormRow label={termCourse.plural}>
            <MultiSelect
              name="course_ids"
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              optionIdentifier="id"
              options={coursesOptions}
              value={selectedCourses || []}
              onChange={handleOnChange('selectedCourses')}
            />
            {!isNil(links.course) && (
              <div className="my-1">
                <span className="m-2">
                  <strong>or</strong>
                </span>
                <button
                  className="btn btn-outline-info btn-sm my-2"
                  onClick={handleSubmit(links.course.new)}
                >
                  Assign New {termCourse.singular}
                </button>
              </div>
            )}
          </FormRow>

          {learningProgressEnabledForManagers && (
            <FormRow label={'Enable Learning Progress For Managers'}>
              <Toggle
                name={'enableLearningProgressForManagers'}
                onClick={value =>
                  setAttributeValue('enableLearningProgressForManagers', value)
                }
                on={<span>Enabled</span>}
                off={<span>Disabled</span>}
                size="sm"
                width={100}
                height={30}
                recalculateOnResize
                offstyle="default"
                active={getAttributeValue('enableLearningProgressForManagers')}
              />
            <FormElementHelperText text="Create a custom designed page for the curriculum." />
            </FormRow>
          )}

          <FormRow label={'Manager Exclusive'}>
            <Toggle
              name={'managerExclusive'}
              onClick={value => setAttributeValue('managerExclusive', value)}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="sm"
              width={60}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={!!isManagerExclusive}
            />
            <FormElementHelperText text="Only users who are a Manager of above can view and enroll in this course." />
          </FormRow>

          <FormRow label={'Public'}>
            <Toggle
              name={'public'}
              onClick={handlePublicFlag}
              on={<span>Yes</span>}
              off={<span>No</span>}
              size="sm"
              width={60}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={!!isPublic}
            />
            <FormElementHelperText
              text={
                isManagerExclusive
                  ? "A Public curriculum can be seen and enrolled by any manager."
                  : "A Public curriculum can be seen and enrolled by any user."
              }
            />
          </FormRow>

          <FormRow label={'Pin this curriculum'}>
            <Toggle
              name={'pinned'}
              onClick={handleToggle('pinned')}
              on={<span>Pinned</span>}
              off={<span>Unpinned</span>}
              size="sm"
              width={100}
              height={30}
              recalculateOnResize
              offstyle="default"
              active={!!getAttributeValue('pinned')}
            />
            <FormElementHelperText text="Pinned curricula will appear at the top of the curriculum list." />
          </FormRow>

          <div className="kt-section row">
            <div className="col-md-2" />
            <div className="col-md-10">
              {/* <SubmitButton
                  buttonText={loading ? 'Saving...' : 'Submit'}
                  disabled={loading}
                /> */}
              <button className="app-btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};

CurriculumForm.propTypes = {
  attributes: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    public: PropTypes.boolean,
    published: PropTypes.boolean,
  }).isRequired,
  curriculumCourses: PropTypes.array.isRequired,
  coursesOptions: PropTypes.array.isRequired,
  meta: PropTypes.shape({
    learningProgressEnabledForManagers: PropTypes.bool,
  }).isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  csrfToken: PropTypes.string,
  headerImageOptions: PropTypes.object,
  thumbnailImageOptions: PropTypes.object,
  links: PropTypes.shape({
    course: PropTypes.shape({
      new: PropTypes.string,
    }),
    create: PropTypes.string,
    update: PropTypes.string,
  }),
  curriculumId: PropTypes.number,
  existingHeaderImage: PropTypes.object,
  existingThumbnailImage: PropTypes.object,
};

export default withErrorHandler(CurriculumForm);

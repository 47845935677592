import React from 'react';
import PropTypes from 'prop-types';

import isNull from 'lodash/isNull';

import { formatDateTime } from '../common/utils';

const ResponseTable = ({ termUser, userResponseData }) => (
  <div className="kt-section">
    <h3 className="kt-section__heading">{`Associated ${termUser.singular} Response`}</h3>
    <div className="kt-section__content">
      <div className="table-responsive">
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr style={{ height: 42 }}>
              <th>
                <span className="pl-15">Form Name</span>
              </th>
              <th>
                <span>{termUser.singular}</span>
              </th>
              <th className="td-110">
                <span>Submitted At</span>
              </th>
              <th className="td-80">
                <span>&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="pl-15">{userResponseData.formName}</span>
              </td>
              <td>
                <span>
                  {!isNull(userResponseData.user)
                    ? `${userResponseData.user.name}`
                    : 'Anonymous'}
                </span>
              </td>
              <td className="td-110">
                <span>
                  {formatDateTime({
                    date: userResponseData.submittedAt,
                    formatTime: false,
                  })}
                </span>
              </td>
              <td className="td-80">
                <a href={userResponseData.links.show}>View</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

ResponseTable.propTypes = {
  userResponseData: PropTypes.object.isRequired,
  termUser: PropTypes.object.isRequired,
};

ResponseTable.defaultProps = {
  userResponseData: {},
  termUser: {},
};

export default ResponseTable;

import React from 'react';
import PropTypes from 'prop-types';
import SubmissionCreator from '../../common/SubmissionCreator';
import UnassignedSubmissionGallery from './UnassignedSubmissionGallery';
import { withErrorHandler } from '../../hoc/withErrorHandler';

import isEmpty from 'lodash/isEmpty';

const SubmissionsFileUploadButtons = ({
  resourceId,
  resourceType,
  csrfToken,
  filestackConfig,
  existingAssets,
  showUploader,
  allowAssetLinking,
  enableAssetLinking,
  allowAssetDelete,
  tenantTerms,
  links,
}) => {
  const { termSubmission } = tenantTerms;
  return (
    <div>
      {!isEmpty(existingAssets) && (
        <React.Fragment>
          <div className="kt-separator kt-separator--dashed" />
          <h4>{termSubmission.plural}:</h4>
        </React.Fragment>
      )}
      {(!allowAssetLinking || !enableAssetLinking) && (
        <SubmissionCreator
          allowAssetDelete={allowAssetDelete}
          resourceId={resourceId}
          resourceType={resourceType}
          csrfToken={csrfToken}
          filestackConfig={filestackConfig}
          existingAssets={existingAssets}
          showUploader={showUploader}
          tenantTerms={tenantTerms}
          links={links.submissionCreator}
        />
      )}
      {allowAssetLinking && enableAssetLinking && (
        <UnassignedSubmissionGallery
          links={links}
          tenantTerms={tenantTerms}
          allowAssetDelete={allowAssetDelete}
          filestackConfig={filestackConfig}
          existingAssets={existingAssets}
          courseAssessmentId={resourceId}
          csrfToken={csrfToken}
          showUploader={showUploader}
          onSelectButtonClick={() => {}}
        />
      )}
    </div>
  );
};

SubmissionsFileUploadButtons.defaultProps = {
  resourceType: 'CourseAssessment',
};

SubmissionsFileUploadButtons.propTypes = {
  resourceType: PropTypes.string.isRequired,
  resourceId: PropTypes.number.isRequired,
  filestackConfig: PropTypes.object.isRequired,
  existingAssets: PropTypes.array.isRequired,
  showUploader: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired,
  allowAssetDelete: PropTypes.bool.isRequired,
  // Gallery Component Props

  allowAssetLinking: PropTypes.bool.isRequired,
  enableAssetLinking: PropTypes.bool.isRequired,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    fetchUnassignedAssessmentSubmissions: PropTypes.string.isRequired,
    assessmentLinkSubmissions: PropTypes.string.isRequired,
    submissionCreator: PropTypes.shape({
      createSubmission: PropTypes.string.isRequired,
    }),
  }),
};

export default withErrorHandler(SubmissionsFileUploadButtons);

import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import SmoothImage from '../presentational/SmoothImage';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import Asset from '../../filestack/Asset';
import { renderAttachmentThumbnail } from '../utils';

import './style.scss';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'scroll',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  paper: {
    borderRadius: '4px',
    width: '100%',
    maxWidth: '700px',
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '95%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
    },
  },
  content: {
    overflow: 'hidden',
    borderRadius: '6px',
    height: '100%',
  },
  closeIconButton: {
    position: 'absolute',
    right: '15px',
    top: '15px',
    zIndex: '9999',
  },
  downloadIconButton: {
    position: 'absolute',
    right: '75px',
    top: '15px',
    zIndex: '9999',
  },
}));

const AttachmentSlider = ({ attachments, onClose, activeState }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState({
    activeIndex: activeState,
    translate: 0,
    transition: 0.45,
  });
  const slideRef = useRef(null);
  const myStateRef = useRef(activeSlide);

  // Preserve current state to access in event handlers
  useEffect(() => {
    myStateRef.current = activeSlide;
  }, [activeSlide]);

  const getWidth = () => slideRef?.current?.getBoundingClientRect()?.width || 0;

  useEffect(() => {
    if (
      _.isNumber(activeState) &&
      activeState >= 1 &&
      activeState <= attachments.length
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [activeState]);

  useEffect(() => {
    setTimeout(() => {
      setActiveSlide((prev) => ({
        ...prev,
        transition: 'none',
        activeIndex: activeState || 1,
        translate: (activeState - 1) * getWidth(),
      }));
    }, 1);

    // Attach event handlers on preview open
    if (open) {
      document.addEventListener('keyup', handleKeyUp);
    }
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [open]);

  const handleKeyUp = (e) => {
    if (e.key === 'ArrowLeft') {
      previousClick(myStateRef.current);
    } else if (e.key === 'ArrowRight') {
      nextClick(myStateRef.current);
    } else if (e.key === 'Escape') {
      handleClose();
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };

  // Handle Previous Button Click
  const previousClick = (previousAttachment = null) => {
    const prevState = previousAttachment || activeSlide;

    if (isSliderAtBeginning(prevState.activeIndex)) {
      setActiveSlide({
        transition: 0.45,
        activeIndex: prevState.activeIndex - 1,
        translate: (prevState.activeIndex - 2) * getWidth(),
      });
    }
  };

  // Handle Next Button Click
  const nextClick = (previousAttachment = null) => {
    const prevState = previousAttachment || activeSlide;

    if (isSliderReachedEnd(prevState.activeIndex)) {
      setActiveSlide({
        transition: 0.45,
        activeIndex: prevState.activeIndex + 1,
        translate: prevState.activeIndex * getWidth(),
      });
    }
  };

  const renderContent = ({ url, mimetype }) => (
    <div className="attachment-slider-preview-item" key={url}>
      {mimetype.includes('image') ? (
        <SmoothImage src={url} alt="preview" />
      ) : mimetype.includes('video') ? (
        <video src={url} className="w-100" controls />
      ) : (
        <div
          className={`icon`}
          style={{
            backgroundImage: `url(${renderAttachmentThumbnail(mimetype)})`,
            width: '50%',
            height: '50%',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
    </div>
  );

  const isSliderAtBeginning = (attachmentIndex) => attachmentIndex !== 1;

  const isSliderReachedEnd = (attachmentIndex) => attachmentIndex !== attachments.length;

  if (_.isEmpty(attachments)) {
    return <Fragment />;
  }

  const downloadLink = attachments?.[activeSlide.activeIndex - 1]?.links?.download;

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick
      >
        <Fade in={open}>
          <>
            {isSliderAtBeginning(activeSlide.activeIndex) && (
              <div
                className="text-white nav_arrow-left cursor-pointer"
                onClick={() => previousClick()}
              >
                <i className="fas fa-chevron-left"></i>
              </div>
            )}

            {isSliderReachedEnd(activeSlide.activeIndex) && (
              <div
                className="text-white nav_arrow-right cursor-pointer"
                onClick={() => nextClick()}
              >
                <i className="fas fa-chevron-right"></i>
              </div>
            )}

            {downloadLink && (
              <Fab
                size="medium"
                color="primary"
                aria-label="add"
                className={classes.downloadIconButton}
                onClick={() => {
                  window.location.href = downloadLink;
                }}
              >
                <CloudDownloadIcon />
              </Fab>
            )}
            <Fab
              size="medium"
              color="secondary"
              aria-label="add"
              className={classes.closeIconButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </Fab>

            <div className={`${classes.paper}`}>
              <div className={classes.content}>
                <div
                  className="d-flex align-items-center"
                  ref={slideRef}
                  style={{
                    height: '100%',
                    transform: `translateX(-${activeSlide.translate}px)`,
                    transition: `transform ease-out ${activeSlide.transition}s`,
                    width: '100%',
                  }}
                >
                  {attachments.map(renderContent)}
                </div>
              </div>
            </div>
          </>
        </Fade>
      </Modal>
    </div>
  );
};

AttachmentSlider.propTypes = {
  attachments: PropTypes.array.isRequired,
  activeState: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

AttachmentSlider.defaultProps = {
  activeState: 1,
};

export default withErrorHandler(AttachmentSlider);

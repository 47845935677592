import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '../../hoc/withErrorHandler';

const Preview = ({ theme: { color, textColor }, isHoverColorInverted }) => {
  const styles = {
    backgroundColor: color,
    color: textColor,
    borderColor: color,
  };

  const hoverViewStyles = {
    backgroundColor: '#fff',
    color: isHoverColorInverted ? textColor : color,
    borderColor: isHoverColorInverted ? textColor : color,
  };

  return (
    <div className="p-5 my-3 text-center">
      <button className="btn mr-md-2 mb-2 mb-md-0" style={styles}>
        Sample Button
      </button>

      <button className="btn" style={hoverViewStyles}>
        Hovered State
      </button>

      <h3
        className="mt-3"
        style={{ color: isHoverColorInverted ? textColor : color }}
      >
        Text Color Preview
      </h3>
    </div>
  );
};

Preview.propTypes = {
  theme: PropTypes.shape({}).isRequired,
  isHoverColorInverted: PropTypes.bool,
};

Preview.defaultProps = {
  isHoverColorInverted: false,
};

export default withErrorHandler(memo(Preview));

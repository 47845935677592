import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';

const ResetCourseCompletion = ({ resetCourseCompletionNowUrl, csrfToken }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const proceed = confirm(
      'Your current progress will be erased. Are you sure want to retake ?'
    );
    if (proceed) {
      await new Http()
        .setToken(csrfToken)
        .onBegin(() => setLoading(true))
        .doesRedirect(true)
        .post(resetCourseCompletionNowUrl, {})
        .onSuccess(({ data }) => {
          setLoading(false);
          window.location.href = data.redirection_url;
        })
        .onError(err => {
          alertErrorNotifications(err || 'Something went wrong!');
          setLoading(false);
        })
        .exec();
    }
  };
  return (
    <button
      type="button"
      className="btn btn-outline-primary btn-sm"
      onClick={handleSubmit}
      disabled={loading}
    >
      <span className="font-weight-bold">
        {loading ? 'Loading...' : 'Retake'}
      </span>
    </button>
  );
};

ResetCourseCompletion.propTypes = {
  resetCourseCompletionNowUrl: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
};

export default ResetCourseCompletion;

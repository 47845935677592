import isNil from 'lodash/isNil';
import { convertArrayOfObjectsToHash } from '../../common/utils';

export const reviewsDataReducer = (state, action) => {
  switch (action.type) {
    case 'initiateReviewsDataLoading': {
      return {
        ...state,
        loadingReviews: true,
      };
    }
    case 'initiateReviewsDataRowsLoading': {
      return {
        ...state,
        pagingMeta: {
          ...state.pagingMeta,
          rowsLoading: true,
        },
      };
    }
    case 'resolvedReviewsDataFetch': {
      const { isInfiniteLoader, data } = action.payload;
      return {
        ...state,
        reviews: isInfiniteLoader
          ? [...state.reviews, ...data.reviews]
          : data.reviews,
        pagingMeta: {
          rowsLoading: false,
          ...(!isNil(data.paging) && {
            hasNextPage: data.paging.hasNextPage,
            startIndex: data.paging.startIndex,
          }),
        },
        loadingReviews: false,
      };
    }
    case 'rejectedReviewsDataFetch': {
      return {
        ...state,
        reviews: [],
        pagingMeta: {
          ...state.pagingMeta,
          rowsLoading: false,
        },
        loadingReviews: false,
      };
    }
    case 'handleReviewFilters': {
      const { attributeName, attributeValue } = action.payload;
      return {
        ...state,
        reviewFilters: {
          ...state.reviewFilters,
          [attributeName]: !isNil(attributeValue) ? attributeValue : null,
        },
      };
    }
    default:
      throw new Error('Unhandled Social Reviews Data Action Type.');
  }
};

export const reviewsMetaDataReducer = (state, action) => {
  switch (action.type) {
    case 'initiateReviewsMetaDataFetch': {
      return {
        ...state,
        loadingReviewsMetaData: true,
      };
    }
    case 'resolvedReviewsMetaDataFetch': {
      const { projects, projectCategories, reviewers } = action.payload;
      return {
        ...state,
        loadingReviewsMetaData: false,
        projects: convertArrayOfObjectsToHash(projects, 'id'),
        projectCategories: convertArrayOfObjectsToHash(projectCategories, 'id'),
        reviewers: convertArrayOfObjectsToHash(reviewers, 'id'),
      };
    }
    case 'rejectedReviewsDataFetch': {
      return {
        ...state,
        loadingReviewsMetaData: false,
        reviewers: {},
        projects: {},
        projectCategories: {},
      };
    }
    default:
      throw new Error('Unhandled Social Reviews Meta Data Action Type.');
  }
};

import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import Preview from './Preview';
import ColorInput from './ColorInput';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ThemeColor = ({ themeKey, onChange, theme, setSaveButtonStatus }) => {
  const onHandleChange = ({ target: { name, value } }) => {
    const newTheme = {
      ...theme,
      [name]: value,
    };
    onChange(themeKey, newTheme);
    setSaveButtonStatus(true);
  };

  return (
    <div className="theme col-md-6 text-black-50">
      <div className="card">
        <div className="card-body">
          <h3 className="text-capitalize card-title mb-4">{themeKey}</h3>
          <div className="mb-3 ">
            {theme?.color && (
              <div className="mb-4">
                <div className="text-capitalize font-weight-bold mr-2 mb-3">
                  {themeKey} Color
                </div>
                <div className="d-flex pl-5">
                  <ColorInput
                    color={theme.color}
                    name="color"
                    onChange={onHandleChange}
                    setSaveButtonStatus={setSaveButtonStatus}
                  />
                </div>
              </div>
            )}

            {theme?.textColor && (
              <Fragment>
                <div className="text-capitalize font-weight-bold mr-2 mb-3">
                  Button Text Color
                </div>
                <div className="d-flex pl-5">
                  <ColorInput
                    color={theme.textColor}
                    name="textColor"
                    onChange={onHandleChange}
                    setSaveButtonStatus={setSaveButtonStatus}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className="card-footer">
          <Preview theme={theme} />
        </div>
      </div>
    </div>
  );
};

ThemeColor.propsTypes = {
  onChange: PropTypes.func.isRequired,
  themeKey: PropTypes.string.isRequired,
  theme: PropTypes.shape({}),
  setSaveButtonStatus: PropTypes.func.isRequired,
};

export default withErrorHandler(memo(ThemeColor));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetManager from './AssetManager';
import { withErrorHandler } from '../hoc/withErrorHandler';

/*
 Asset Manager to be used in Rails view only
 Handles the file delete scenarios.
 */
class RailsAssetManagerWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assets: [],
      existingAssets: props.initialAssets || [],
    };
  }

  handleAssetChange = (assets, existingAssets) => {
    this.setState({
      assets,
      existingAssets,
    });
  };

  render() {
    const { assets, existingAssets } = this.state;
    const { csrfToken, ...restProps } = this.props;

    const pulledProps = _.pick(restProps, [
      'label',
      'readOnly',
      'showLabel',
      'showGallery',
      'showGalleryInListView',
      'showUploader',
      'hideFileInfo',
      'labelClassName',
      'selectableMode',
      'selectedAssets',
      'renderUploader',
      'assetFolderList',
      'wrapperClassName',
      'assetsWithFolders',
      'inputColClassName',
      'showFileShareOption',
      'showFileDeleteOption',
      'onAssetFolderChange',
    ]);

    return (
      <AssetManager
        csrfToken={csrfToken}
        assets={assets}
        existingAssets={existingAssets}
        onFileChange={this.handleAssetChange}
        {...pulledProps}
      />
    );
  }
}

RailsAssetManagerWrapper.propTypes = {
  label: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputColClassName: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  showUploader: PropTypes.bool,
  showLabel: PropTypes.bool,
  filestackOptions: (props, propName, componentName) => {
    const showUploader = props['showUploader'];
    if (showUploader) {
      if (_.isNil(props[propName])) {
        return new Error(`
          ${componentName} requires the prop "${propName}", if "showUploader" is true.
        `);
      }
    }
  },
  renderUploader: PropTypes.func,

  initialAssets: PropTypes.array.isRequired,
  initialExistingAssets: PropTypes.array,

  showGallery: PropTypes.bool,
  showGalleryInListView: PropTypes.bool,
  showFileShareOption: PropTypes.bool,
  selectableMode: PropTypes.bool,
  selectedAssets: PropTypes.array,
  hideFileInfo: PropTypes.bool,
  showFileDeleteOption: PropTypes.bool,
  assetsWithFolders: PropTypes.object,
  onAssetFolderChange: PropTypes.func,
  assetFolderList: PropTypes.array,
};

export default withErrorHandler(RailsAssetManagerWrapper);

import React from "react";
import Http from '../../common/Http';
import { formatDateTime } from "../../common/utils";

const Results = ({ participants, onDeleteParticipant  }) => (
  <div className="kt-section">
    <div className="kt-section__content">
      <div className="table-responsive">
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr>
              <th colSpan={2}>
                <strong className="pl-15">
                  Name
                </strong>
              </th>
              <th>
                <strong>
                  Added At
                </strong>
              </th>
              <th>
                <strong>
                  Last Accessed At
                </strong>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              participants.length > 0
                ?
                participants.map(({ participant, added_at, last_accessed_at }) => (
                  <tr key={participant.id}>
                    <td colSpan={2}>
                      <span className="pl-15">
                        {participant.name}
                      </span>
                    </td>
                    <td>
                      {added_at ? formatDateTime({ date: added_at }) : 'NA'}
                    </td>
                    <td>
                      {last_accessed_at ? formatDateTime({ date: last_accessed_at }) : 'NA'}
                    </td>
                    <td>
                      <div className="d-flex justify-content-end mr-15">
                        <i
                          title="Remove participant"
                          className="la la-close"
                          style={{ color: 'red', fontSize: '1.2rem', cursor: 'pointer' }}
                          onClick={() => onDeleteParticipant(participant)}
                        />
                      </div>
                    </td>
                  </tr>
                ))
                :
                (
                  <tr>
                    <td colSpan={6} align="center">
                      No Participants found
                    </td>
                  </tr>
                )
            }
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default Results;

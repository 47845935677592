import React from 'react';
import PropTypes from 'prop-types';
import { getReadableTimeDiffFromNowUntilAWeek } from '../common/utils'
import { withErrorHandler } from '../hoc/withErrorHandler';

const ActivityItem = ({
  type,
  owner,
  modelColor = 'default',
  btnColor = 'default',
  description,
  activity,
  createdAt,
  links = {},
}) => (
  <tr>
    <td>
      <a href={links.viewResource}>
        <span className={`kt-badge kt-badge--${modelColor} kt-badge--dot`} />
        &nbsp;&nbsp;
        <span className={`kt--font-bold kt--font-${modelColor}`}>{type}</span>
      </a>
    </td>
    <td>{owner}</td>
    <td>{description}</td>
    <td>
      <span
        className={`kt-badge kt-badge--${btnColor} kt-badge--inline kt-badge--pill`}
      >
        {activity}
      </span>
    </td>
    <td>{getReadableTimeDiffFromNowUntilAWeek(createdAt)}</td>
  </tr>
);

ActivityItem.propTypes = {
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  modelColor: PropTypes.string,
  btnColor: PropTypes.string,
  description: PropTypes.string,
  activity: PropTypes.string,
  createdAt: PropTypes.string,
  links: PropTypes.object,
};

export default withErrorHandler(ActivityItem);

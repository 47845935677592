import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '../../../../common/utils';
import Modal from '../../../../filestack/modal';
import { withErrorHandler } from '../../../../hoc/withErrorHandler';
import BackgroundGradient from '../../../../common/BackgroundGradient';

const Badge = ({ badge, badgeDetails }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const renderModalContent = () => {
    const badgeType = badgeDetails?.badgeType;
    const parentLearningModule = badgeDetails?.parentLearningModule;

    return (
      <div className="text-black-50">
        <h3 className="text-capitalize">{badge.name}</h3>
        <div className="image-preview">
          <BackgroundGradient
            imgUrl={badge.attachment.url}
            text={badge.name}
            objectFit="contain"
          />
        </div>

        <div className="row">
          <div className="col-md-6">
            <strong>Earned For: </strong>
            <p>{badgeType}</p>

            <strong>{badgeType} Name</strong>
            <p>{badgeDetails?.completedLearningModule?.name}</p>
            {badgeType !== parentLearningModule?.type && (
              <Fragment>
                <strong>{parentLearningModule?.type} Name</strong>
                <p>{parentLearningModule?.name}</p>
              </Fragment>
            )}
          </div>

          <div className="col-md-6">
            <strong>Earned At :</strong>
            <p>
              <i className="far fa-calendar-alt mr-2"></i>
              {formatDateTime({
                date: badgeDetails?.earnedAt,
                formatTime: false,
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const closeViewModal = () => setIsInfoModalOpen(false);

  const renderInfoModal = () => (
    <Modal
      onClose={closeViewModal}
      title="Badge Details"
      large
      renderContent={renderModalContent}
      renderFooter={() => (
        <div className="text-right w-100">
          <button className="btn btn-darkred" onClick={closeViewModal}>
            Close
          </button>
        </div>
      )}
    />
  );

  return (
    <Fragment>
      <div className="learning-card" onClick={() => setIsInfoModalOpen(true)}>
        <div className="learning-card__image badge-image">
          <BackgroundGradient
            imgUrl={badge.attachment.url}
            text={badge.name}
            objectFit="contain"
          />
        </div>
        <div className="learning-card__details">
          <h5 className="learning-card__name" title={badge.name}>
            {badge.name}
          </h5>
          <div className="font-11 mt-3">
            Type:{' '}
            <span className="mx-2 kt-font-bold">{badgeDetails?.badgeType}</span>
          </div>
          <div className="font-11 mt-2">
            Earned For:{' '}
            <span className="mx-2 kt-font-bold">
              {badgeDetails?.completedLearningModule?.name}
            </span>
          </div>
        </div>
      </div>
      {isInfoModalOpen && renderInfoModal()}
    </Fragment>
  );
};

Badge.propTypes = {
  badge: PropTypes.shape({
    attachment: PropTypes.shape({
      url: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  badgeDetails: PropTypes.shape({
    badgeId: PropTypes.number.isRequired,
    badgeType: PropTypes.string.isRequired,
    earnedAt: PropTypes.string,
    completedLearningModule: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    parentLearningModule: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
};

export default withErrorHandler(memo(Badge));

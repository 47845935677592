import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import InifinityLoader from '../common/InifinityLoader';
import PortletBlocker from '../common/presentational/PortletBlocker';
import Topic from './Topic';
import { generateFilterParams } from './utils';
import useLearningForumContext from './hooks/useLearningForumContext';

const ForumTable = ({
  csrfToken,
  isCourseAdmin,
  afterDelete,
  afterTogglePin,
  topicAttachmentsConfig,
  loadMoreRows,
  pagingRef,
}) => {
  const {
    forumDataState: { allTopics, topicFilter, categoryFilter },
    forumLoaderState: { filterLoader, loading, isPaginationLoading },
    currentUserId,
    categories,
  } = useLearningForumContext();
  return (
    <PortletBlocker loading={filterLoader}>
      <div className="table-responsive">
        <table className="table kt-table__row-equal-width topics-table">
          <thead>
            <tr className="text-greyish font-600">
              <th className="td-50">Poster</th>
              <th>Title</th>
              <th className="td-250">Category</th>
              <th className="td-80 text-center">Replies</th>
              <th className="td-80 text-center">Activity</th>
              <th className="td-80 text-center"></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(allTopics) ? (
              <InifinityLoader
                data={allTopics}
                renderer={topic => (
                  <Topic
                    key={`${topic.id}-${topic.pinnedAt}`}
                    topic={topic}
                    categories={categories}
                    csrfToken={csrfToken}
                    currentUserId={currentUserId}
                    isCourseAdmin={isCourseAdmin}
                    afterDelete={afterDelete}
                    afterTogglePin={afterTogglePin}
                    breadcrumbQueryParams={generateFilterParams({
                      ...(categoryFilter ? { categoryId: categoryFilter } : {}),
                      ...(topicFilter ? { topic: topicFilter } : {}),
                    })}
                    topicAttachmentsConfig={topicAttachmentsConfig}
                  />
                )}
                loadMoreRows={loadMoreRows}
                hasMoreRows={() =>
                  pagingRef.current ? pagingRef.current.hasNextPage : null
                }
                enableLoadMoreButton={
                  pagingRef.current ? pagingRef.current.hasNextPage : false
                }
                isLoading={isPaginationLoading}
                isWrapperTable
              />
            ) : (
              <tr className="text-md-center">
                <td colSpan="6">
                  {loading && isEmpty(allTopics) ? (
                    <div className="d-flex align-items-center justify-content-md-center my-4">
                      <div
                        className="spinner-border text-darkred"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <h3 className="text-dark-50 ml-3">
                        Please wait while loading...
                      </h3>
                    </div>
                  ) : (
                    <h1>
                      <i
                        className="fab fa-wpforms text-muted"
                        style={{ fontSize: '100px' }}
                      ></i>
                      <p>No topics found</p>
                    </h1>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </PortletBlocker>
  );
};

ForumTable.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  isCourseAdmin: PropTypes.bool,
  afterDelete: PropTypes.func,
  topicAttachmentsConfig: PropTypes.object,
  loadMoreRows: PropTypes.func,
  pagingRef: PropTypes.object,
};

export default ForumTable;

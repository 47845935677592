export const deleteAssetFromFS = async (handle) => {
  const security = {
    policy: process.env['FILESTACK_POLICY'],
    signature: process.env['FILESTACK_SIGNATURE'],
  };
  const client = filestack.init(process.env['FILESTACK_ID'], { security });

  // Removes asset from filestack.
  try {
    return await client.remove(handle);
  } catch (error) {
    console.log(error);
    return error;
  }
};

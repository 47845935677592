import React from 'react';
import PropTypes from 'prop-types';
import './dropDownButton.scss';

const DropdownButton = ({ dropdownTitle, dropdownValues, onClick, name, className }) => (
  <div className={`dropdown ${className}`} name={name}>
    <button
      type="button"
      className="btn-sm app-btn-outline-secondary dropdown-toggle filter-button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <span>
        {dropdownTitle}
        <i className="la la-angle-down" />
      </span>
    </button>
    <div
      className="dropdown-menu p-0"
      aria-labelledby="dropdownMenuButton"
      x-placement="bottom-start"
      onClick={onClick}
    >
      {_.map(dropdownValues, ({ label, className, key, value }) => (
        <button className={`dropdown-item ${className}`} key={key} value={value}>
          {label}
        </button>
      ))}
    </div>
  </div>
);

DropdownButton.propTypes = {
  dropdownTitle: PropTypes.string.isRequired,
  dropdownValues: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DropdownButton;

import React from 'react';
import PropTypes from 'prop-types';

import Header from './common/Header';
import NoItemMessage from './common/NoTopics';
import KbLink from './common/KbLink';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import './styles.scss';

const Category = ({ links, topicCategory: { name, topics, id }, topicCategories }) => {
  return (
    <div className="knowledge_base">
      <Header queryFormLink={links.publicForm}>
        <div className="d-flex align-items-center">
          <a className="category-link text-dark" href={links.viewTopicCategories}>
            Home
          </a>
          <i className="fas fa-chevron-right mx-2 font-12 text-black-50"></i>
        </div>
        <h1 className="header_section-title">{name}</h1>
      </Header>

      <div className="knowledge_base-container">
        <div className="knowledge_base-content">
          <div className="row">
            <div className="col-md-3 d-none d-md-block">
              <div className="sidebar">
                {topicCategories.map((category) => (
                  <div
                    className={`sidebar_link p-2${id === category.id ? ' selected' : ''}`}
                    key={category.id}
                  >
                    <a className="category-link" href={category.links.viewCategory}>
                      {category.name}
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-7 pl-md-5">
              {_.isEmpty(topics) ? (
                <NoItemMessage />
              ) : (
                topics.map((topic) => (
                  <KbLink
                    key={topic.id}
                    link={topic.links.viewTopic}
                    name={topic.question}
                  />
                ))
              )}
              {!_.isNil(links.publicForm) && (
                <div className="knowledge_base-footer mt-5 pt-5">
                  <span>Didn't find a related topic?</span>
                  <a href={links.publicForm} className="px-2 kt-link">Please Submit Your Query.</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Category.propTypes = {
  links: PropTypes.shape({
    viewTopicCategories: PropTypes.string.isRequired,
  }).isRequired,
  topicCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  topicCategory: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    topics: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        answer: PropTypes.string.isRequired,
        question: PropTypes.string.isRequired,
        lastUpdatedAt: PropTypes.string.isRequired,
        links: PropTypes.shape({
          viewTopic: PropTypes.string.isRequired,
        }).isRequired,
        postedBy: PropTypes.shape({
          avatar: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }).isRequired,
      })
    ).isRequired,
  }),
};

export default withErrorHandler(Category);

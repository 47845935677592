import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import './dashboardStyles.scss';

import DashBoardTab from './dashboardTabs/DashboardTab';
import AllCourses from './dashboardTabs/AllCourses';
import MyCertifications from './dashboardTabs/MyCertifications/index';
import MyBadges from './dashboardTabs/MyBadges/index';

import {
  MY_COURSES_TAB,
  PUBLIC_COURSES_TAB,
  BADGES_TAB,
  CERTIFICATIONS_TAB,
} from './utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const LearnerDashboard = ({
  courses,
  curriculums,
  tenantTerms,
  badges,
  certifications,
}) => {
  const { termCourse, termCertification } = tenantTerms;
  const showBadges = !isEmpty(badges?.data?.earnedBadges);
  const showCertifications = !isEmpty(certifications?.data);
  const [showAvailableCourses, dashboardTabs] = React.useMemo(() => {
    const showEnrollableItems =
      !isEmpty(courses?.enrollable) || !isEmpty(curriculums?.enrollable);
    const anyEnrolledCourses =
      courses?.enrolled?.length > 0 || curriculums?.enrolled?.length > 0;
    const myCourses = [
      { key: MY_COURSES_TAB, label: `My ${termCourse.plural}` },
    ];

    return [
      showEnrollableItems,
      [
        ...(anyEnrolledCourses ? myCourses : []),
        ...(showEnrollableItems
          ? [{ key: PUBLIC_COURSES_TAB, label: termCourse.plural }]
          : []),
        ...(anyEnrolledCourses ? [] : myCourses),
      ],
    ];
  }, [
    courses?.enrollable,
    courses?.enrolled?.length,
    curriculums?.enrollable,
    curriculums?.enrolled?.length,
    termCourse.plural,
  ]);

  if (showCertifications) {
    dashboardTabs.push({
      key: CERTIFICATIONS_TAB,
      label: `My ${termCertification.plural}`,
    });
  }

  if (showBadges) {
    dashboardTabs.push({ key: BADGES_TAB, label: 'My Badges' });
  }

  return (
    <DashBoardTab tabs={dashboardTabs}>
      <DashBoardTab.Header />
      <DashBoardTab.TabContent>
        {showAvailableCourses ? (
          <AllCourses
            id={PUBLIC_COURSES_TAB}
            key={PUBLIC_COURSES_TAB}
            curriculums={curriculums.enrollable}
            courses={courses.enrollable}
            tenantTerms={tenantTerms}
          />
        ) : null}
        <AllCourses
          id={MY_COURSES_TAB}
          key={MY_COURSES_TAB}
          curriculums={curriculums.enrolled}
          courses={courses.enrolled}
          tenantTerms={tenantTerms}
          isMyCoursesTab={true}
        />
        {showCertifications ? (
          <MyCertifications
            id={CERTIFICATIONS_TAB}
            certifications={certifications}
            term={tenantTerms.termCertification}
          />
        ) : null}
        {showBadges ? <MyBadges id={BADGES_TAB} badges={badges} /> : null}
      </DashBoardTab.TabContent>
    </DashBoardTab>
  );
};

LearnerDashboard.propTypes = {
  courses: PropTypes.shape({
    enrolled: PropTypes.array.isRequired,
    enrollable: PropTypes.array.isRequired,
  }).isRequired,
  curriculums: PropTypes.shape({
    enrolled: PropTypes.array.isRequired,
    enrollable: PropTypes.array.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCertification: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  badges: PropTypes.shape({
    data: PropTypes.shape({
      earnedBadges: PropTypes.array,
    }),
    meta: PropTypes.shape({}),
  }).isRequired,
  certifications: PropTypes.shape({
    data: PropTypes.array,
    meta: PropTypes.shape({}),
  }).isRequired,
};

export default withErrorHandler(LearnerDashboard);

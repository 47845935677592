import React, { useCallback, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';

import Modal from '../filestack/modal';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import UserIconsGroup from '../common/Avatar/UserIconsGroup';
import Spinner from '../common/presentational/spinner';
import CloneLink from '../courses/admin/cloning/CloneLink';
import { formatDateTime } from '../common/utils';
import InitialsAvatar from '../common/Avatar';
import TextInput from '../common/inputs/text';

const INITIAL_STATE = Object.freeze({
  isModalOpen: false,
  deleteCurriculumOnly: true,
  deletableCurriculumLink: null,
});

const curriculumsReducer = (state, action) => {
  switch (action.type) {
    case 'closeModal': {
      return {
        ...INITIAL_STATE,
      };
    }
    case 'openDeleteModal': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'setDeleteMode': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

const CurriculumsIndex = ({
  tenantTerms,
  links,
  curriculums,
  isCurriculumsIndex,
  showNewCurriculumLink,
  csrfToken,
  keyword,
  onKeywordChange,
}) => {
  const { termCourse, termCurriculum } = tenantTerms;
  const [loading, setLoading] = useState(false);
  const [curriculumsState, dispatch] = useReducer(
    curriculumsReducer,
    INITIAL_STATE
  );
  const { isModalOpen } = curriculumsState;

  const toggleLoader = () => setLoading(prevState => !prevState);
  const openDeleteOptionsModal = link => {
    dispatch({
      type: 'openDeleteModal',
      payload: {
        deletableCurriculumLink: link,
        isModalOpen: true,
      },
    });
  };

  const closeModal = () => dispatch({ type: 'closeModal' });

  const submitDeleteRequest = async () => {
    const { deletableCurriculumLink, deleteCurriculumOnly } = curriculumsState;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .delete(deletableCurriculumLink)
      .setQueryParams({
        include_courses: deleteCurriculumOnly ? 0 : 1,
      })
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        setLoading(false);
        window.location.href = data.redirectionUrl;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handlePublishedStatus = url => async event => {
    event.preventDefault();
    await new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .doesRedirect(true)
      .patch(url)
      .useAlerts()
      .onSuccess(() => {
        setLoading(false);
        window.location.reload();
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const setDeleteMode = ({ target }) =>
    dispatch({
      type: 'setDeleteMode',
      payload: {
        deleteCurriculumOnly: isEqual('curriculumOnly', target.value),
      },
    });

  const renderModalContent = () => {
    const { termCourse, termCurriculum } = tenantTerms;
    const { deleteCurriculumOnly } = curriculumsState;
    return (
      <div>
        <label className="m-2" htmlFor="curriculumOnly">
          <input
            className="mx-2"
            name="curriculumOnly"
            id="curriculumOnly"
            type="radio"
            value="curriculumOnly"
            checked={deleteCurriculumOnly}
            onChange={setDeleteMode}
          />
          <span className="text-capitalize font-weight-bold">
            Delete {termCurriculum.singular} but, keep {termCourse.plural}
          </span>
        </label>
        <label className="m-2" htmlFor="curriculumAndCourses">
          <input
            className="mx-2"
            name="curriculumAndCourses"
            id="curriculumAndCourses"
            type="radio"
            value="curriculumAndCourses"
            checked={!deleteCurriculumOnly}
            onChange={setDeleteMode}
          />
          <span className="text-capitalize font-weight-bold">
            Delete both {termCurriculum.singular} and {termCourse.plural}
          </span>
        </label>
      </div>
    );
  };

  const renderDropDown = (name, published, curriculumLinks) => {
    return (
      <div className="btn-group dropleft">
        <button
          type="button"
          className="btn-sm app-btn-outline-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Options
        </button>
        <div className="dropdown-menu">
          {!isCurriculumsIndex && curriculumLinks.manageCourses ? (
            <a className="dropdown-item" href={curriculumLinks.manageCourses}>
              Manage {termCourse.plural}
            </a>
          ) : (
            <a className="dropdown-item" href={curriculumLinks.show}>
              View
            </a>
          )}
          <a className="dropdown-item" href={curriculumLinks.edit}>
            Edit
          </a>
          <a className="dropdown-item" href={curriculumLinks.manageParticipants}>
            Manage Participants
          </a>
          <a className="dropdown-item" href={curriculumLinks.retake}>
            Retake
          </a>
          <CloneLink
            link={curriculumLinks.clone}
            subjectName={name}
            csrfToken={csrfToken}
            toggleLoader={toggleLoader}
          />
          {published ? (
            <button
              className="dropdown-item"
              onClick={handlePublishedStatus(curriculumLinks.unpublish)}
            >
              Mark as Unpublished
            </button>
          ) : (
            <button
              className="dropdown-item"
              onClick={handlePublishedStatus(curriculumLinks.publish)}
            >
              Mark as Published
            </button>
          )}
          <button
            className="dropdown-item text-darkred"
            onClick={() => openDeleteOptionsModal(curriculumLinks.delete)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{termCurriculum.plural}</h3>
        </div>

        <div className="kt-portlet__head-toolbar" style={{ minWidth: '40%' }}>
          {onKeywordChange && (
            <input
              name="filterText"
              onChange={({ target: { value } }) => onKeywordChange(value)}
              type="text"
              placeholder="Filter by name"
              className="form-control mx-2"
              style={{ width: '100%' }}
              value={keyword}
              autoFocus
            />
          )}

          {isCurriculumsIndex && (
            <a href={links.new} className="btn app-btn-primary btn-sm m-2">
              New {termCurriculum.singular}
            </a>
          )}

          {!isCurriculumsIndex && showNewCurriculumLink && (
            <a href={links.new} className="app-btn-primary m-2" style={{ width: '80%' }}>
              New {termCurriculum.singular}
            </a>
          )}
        </div>
      </div>
      {!isModalOpen && loading && <Spinner />}
      <div className="kt-portlet__body plr-0">
        <div className="table-responsive">
          <table className="table table-striped kt-table__row-equal-width">
            <thead>
              <tr>
                <th>
                  <span className="pl-15">Name</span>
                </th>
                <th className="text-truncate">Description</th>
                <th className="text-capitalize text-center td-150">
                  {termCourse.plural} Count
                </th>
                <th className="td-180">Members</th>
                <th>Last Modified At</th>
                <th>Last Modified By</th>
                <th className="td-80">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {map(
                curriculums,
                ({
                  id,
                  name,
                  description,
                  coursesCount,
                  users,
                  published,
                  links: curriculumLinks,
                  cloneConfig,
                  lastModifiedAt,
                  lastModifiedBy,
                }) => (
                  <tr key={id}>
                    <td>
                      <span className="pl-15">{name}</span>
                    </td>
                    <td className="text-truncate">{description}</td>
                    <td className="text-center td-150">{coursesCount}</td>
                    <td className="td-180">
                      <UserIconsGroup users={users} />
                    </td>
                    <td>
                      {lastModifiedAt
                        ? formatDateTime({
                            date: lastModifiedAt,
                            formatTime: true,
                          })
                        : 'N/A'}
                    </td>
                    <td>
                      {lastModifiedBy ? (
                        <InitialsAvatar
                          url={lastModifiedBy.avatar}
                          name={lastModifiedBy.name}
                        />
                      ) : (
                        'N/A'
                      )}
                    </td>
                    <td className="td-80">
                      {renderDropDown(
                        name,
                        published,
                        curriculumLinks,
                        cloneConfig
                      )}
                    </td>
                  </tr>
                )
              )}
              {isEmpty(curriculums) && (
                <tr>
                  <td colSpan={7}>
                    <h4 className="text-center">No {termCurriculum.plural}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title={'Choose a Deletion Method'}
          onClose={closeModal}
          renderContent={renderModalContent}
          renderFooter={() => (
            <div className="d-flex align-items-center justify-content-end">
              <button
                className="btn btn-darkred btn-sm m-2"
                onClick={submitDeleteRequest}
                disabled={loading}
              >
                {loading ? 'Deleting...' : 'Delete'}
              </button>
              <button
                className="app-btn-outline-secondary btn-sm m-2"
                onClick={closeModal}
                disabled={loading}
              >
                Cancel
              </button>
            </div>
          )}
        />
      )}
    </div>
  );
};

CurriculumsIndex.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  curriculums: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      coursesCount: PropTypes.number.isRequired,
      users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          email: PropTypes.string.isRequired,
          avatar: PropTypes.string.isRequired,
        })
      ).isRequired,
      links: PropTypes.shape({
        edit: PropTypes.string.isRequired,
        show: PropTypes.string.isRequired,
        delete: PropTypes.string.isRequired,
        manageCourses: PropTypes.string,
        manageParticipants: PropTypes.string,
      }).isRequired,
    }).isRequired
  ).isRequired,
  links: PropTypes.shape({
    new: PropTypes.string.isRequired,
  }),
  isCurriculumsIndex: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termCurriculum: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  showNewCurriculumLink: PropTypes.bool,
};

CurriculumsIndex.defaultProps = {
  isCurriculumsIndex: false,
  showNewCurriculumLink: false,
};

export default withErrorHandler(CurriculumsIndex);

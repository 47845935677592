import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

const PaginationWrapper = props => {
  const { paginationObject } = props;

  const handlePageClick = data => {
    let selected = data.selected;
    const { fetchData } = props;
    fetchData(selected + 1);
  };

  if (!isEmpty(paginationObject)) {
    return (
      <nav
        aria-label="Page navigation"
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: '40px auto 0',
        }}
      >
        <ReactPaginate
          pageCount={paginationObject.totalPages}
          forcePage={paginationObject.currentPage - 1 || 0}
          previousLabel={'<<'}
          nextLabel={'>>'}
          breakLabel={'...'}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={'pagination pagination-md pagination--brand'}
          pageClassName={'page-item'}
          nextClassName={'page-item'}
          nextLinkClassName={'page-link'}
          breakClassName={'break-me'}
          previousClassName={'page-item'}
          disabledClassName={'d-none'}
          previousLinkClassName={'page-link'}
          activeClassName={'active'}
          pageLinkClassName={'page-link'}
        />
      </nav>
    );
  }
  return null;
};

PaginationWrapper.propTypes = {
  fetchData: PropTypes.func,
  paginationObject: PropTypes.shape({
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default PaginationWrapper;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';

import Toggle from '../../common/inputs/Toggle';

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.
class FormToggleBox extends Component {
  constructor(props) {
    super(props);

    let prefilledValue = false;

    if (
      !isNil(props.answerData) &&
      !isNil(props.answerData[props.data.field_name])
    ) {
      prefilledValue = props.answerData[props.data.field_name];
    }

    this.state = {
      value: prefilledValue,
    };
  }

  handleToggle = val => {
    this.setState({
      value: val,
    });
  };

  render() {
    const { value: activeValue } = this.state;
    const { data } = this.props;
    const { field_name, read_only } = data;
    return (
      <div>
        <Toggle
          name={field_name}
          onClick={this.handleToggle}
          on={<span>Yes</span>}
          off={<span>No</span>}
          size="sm"
          width={60}
          disabled={read_only}
          height={30}
          recalculateOnResize
          offstyle="default"
          active={activeValue}
        />
      </div>
    );
  }
}

FormToggleBox.propTypes = {
  data: PropTypes.shape({
    field_name: PropTypes.string.isRequired,
    read_only: PropTypes.bool,
  }),
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
export default FormToggleBox;

import React from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

import './taskList.scss';
import { getStatusIconClass } from './utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

const dropdownStyles = {
  position: 'absolute',
  willChange: 'transform',
  top: 0,
  left: 0,
  transform: 'translate3d(-2px, 0px, 0px)',
};

const Sort = {
  Name: 1,
  DateCreatedAsc: 2,
  DateCreatedDesc: 3,
  DueDateAsc: 4,
  DueDateDesc: 5,
};

const tabsList = [
  {
    name: 'All',
    className: '',
    key: 'all',
  },
  {
    name: 'Incomplete',
    className: ' text-darkred nav-link--danger ',
    key: 'incomplete',
  },
  {
    name: 'Completed',
    className: ' kt-font-warning nav-link--warning',
    key: 'completed',
  },
];

const SimpleTaskList = props => {
  const [currentSortBy, setCurrentSortBy] = React.useState(null);
  const [currentActiveTab, setCurrentActiveTab] = React.useState('all');

  const handleStatusTabChange = tabName => {
    if (tabName) {
      setCurrentActiveTab(tabName);
    }
  };

  const handleSortBy = event => {
    if (event && event.target) {
      setCurrentSortBy(parseInt(event.target.value || 0));
    }
  };

  const sortedFilteredTaskList = taskList => {
    let newTaskList = taskList;

    // Filter tasks based on Status
    if (currentActiveTab === 'incomplete') {
      newTaskList = filter(taskList, { status: 'incomplete' });
    } else if (currentActiveTab === 'completed') {
      newTaskList = filter(taskList, { status: 'completed' });
    } else if (currentActiveTab === 'closed') {
      newTaskList = filter(taskList, { status: 'closed' });
    }

    if (isNil(currentSortBy)) {
      return newTaskList;
    } else {
      switch (currentSortBy) {
        case Sort.Name:
          return sortBy(newTaskList, [
            task => {
              if (!isNil(task.name)) {
                return task.name.toLowerCase();
              } else return task.name;
            },
            'id',
          ]);
        case Sort.DueDateAsc:
          return orderBy(newTaskList, ['dueDate', 'name']);
        case Sort.DueDateDesc:
          return orderBy(newTaskList, ['dueDate', 'name'], ['desc', 'asc']);
        default:
          return newTaskList;
      }
    }
  };

  const renderPriorityBadge = priorityId => {
    const { taskPriorities } = props;
    const result = find(taskPriorities, { id: priorityId });

    if (!isNil(result) && !isEmpty(result)) {
      return (
        <span
          className={`kt-badge kt-badge--${result.color} kt-badge--inline kt-badge--rounded`}
        >
          <strong>{result.name}</strong>
        </span>
      );
    }
    return null;
  };

  const renderTaskList = sortedTaskList => {
    const { termSimpleTask, taskParentManage } = props;

    return map(sortedTaskList, task => {
      const taskUrl = task.links.show;
      const editTaskUrl = task.links.edit;

      return (
        <div key={task.id} className="kt-task_row--item">
          <div className="kt-task_row--checkbox">
            <span
              className={`kt-widget4__icon btn btn-circle btn-icon btn-sm ${getStatusIconClass(
                task.status || 'def'
              )}`}
            >
              <i className="la la-check status_icon_class font-weight-bold font-10" />
            </span>
          </div>
          <div className="kt-task_row--name text-truncate">
            <span className="kt-widget4__text">
              <a href={taskUrl}>{task.name}</a>
            </span>
          </div>

          <div className="kt-task_row--category">
            <span className="">{renderPriorityBadge(task.priorityId)}</span>
          </div>

          <div className="kt-task_row--end_date">
            <span className="kt-widget4__text">
              {formatDateTime({ date: task.dueDate, formatTime: false }) || '-'}
            </span>
          </div>

          {taskParentManage && (
            <div className="kt-task_row--options">
              <div className="dropleft">
                <button
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  type="button"
                  className="app-btn-hover-secondary btn-icon btn-circle"
                >
                  <i className="la la-ellipsis-h" />
                </button>
                <div
                  className="dropdown-menu"
                  x-placement="left-start"
                  style={dropdownStyles}
                >
                  <a href={editTaskUrl} className="dropdown-item">
                    Edit {termSimpleTask.singular}
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderTaskSection = () => {
    const { taskList, termSimpleTask } = props;

    const sortedTaskList = sortedFilteredTaskList(taskList);

    if (!isEmpty(sortedTaskList)) {
      return renderTaskList(sortedTaskList);
    } else {
      return (
        <div className={'p-4'}>
          <h2
            className={'text-center'}
          >{`No ${termSimpleTask.plural} present`}</h2>
        </div>
      );
    }
  };

  const { taskParentManage, newTaskUrl, termSimpleTask } = props;

  return (
    <div className="kt-portlet kt-portlet--unelevate">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <select className={'form-control m-input'} onChange={handleSortBy}>
            <option value={''}>Sort by</option>
            <option value={Sort.Name}>Name (asc.)</option>
            <option value={Sort.DueDateAsc}>Due Date Asc.</option>
            <option value={Sort.DueDateDesc}>Due Date Desc.</option>
          </select>
        </div>
        <div className="kt-portlet__head-toolbar">
          <div className="d-md-none my-3">
            <ul
              className="nav nav-pills nav-pills--brand nav-pills-align-right nav-pills-btn-pill nav-pills-btn-sm"
              role="tablist"
            >
              {taskParentManage && (
                <li className="nav-item kt-tabs__item">
                  <a className="btn app-btn-primary btn-sm" href={newTaskUrl}>
                    New {termSimpleTask.singular}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="d-none d-md-block">
            <ul className="nav nav-pills nav-pills--brand" role="tablist">
              {map(tabsList, ({ name, className, key }) => {
                return (
                  <li className="nav-item kt-tabs__item" key={name}>
                    <a
                      className={`nav-link m-tabs__link ${className} ${
                        currentActiveTab === key ? 'active' : ''
                      }`}
                      onClick={() => handleStatusTabChange(key)}
                    >
                      {name}
                    </a>
                  </li>
                );
              })}
              {taskParentManage && (
                <li className="nav-item kt-tabs__item">
                  <a className="btn app-btn-primary btn-sm" href={newTaskUrl}>
                    New {termSimpleTask.singular}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="kt-portlet__body p-0">
        <div className="tab-content">
          <div className="tab-pane active" id="all_tabs">
            {renderTaskSection()}
          </div>
        </div>
      </div>
    </div>
  );
};

SimpleTaskList.propTypes = {
  taskList: PropTypes.array.isRequired,
  termSimpleTask: PropTypes.object.isRequired,
  newTaskUrl: PropTypes.string.isRequired,
  taskParentManage: PropTypes.bool.isRequired,
  taskParentId: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  taskPriorities: PropTypes.array,
};

SimpleTaskList.defaultProps = {};

export default withErrorHandler(SimpleTaskList);

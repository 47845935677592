import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../../../filestack/modal';
import Select from '../../../common/inputs/select';
import MultiSelect from '../../../common/inputs/multiSelect';
import CheckBox from '../../../common/inputs/checkBox';
import SubmitButton from '../../../common/presentational/submitButton';
import CloseIcon from './closeIcon';

import { getUserAccessTypeOptions, shareOptionValueRenderer } from '../../utils';

import '../styles/shareModal.scss';
import FormRow from '../../../common/presentational/formRow';

class ShareModalContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  handleCloseModalClick = () => {
    this.props.closeModalHandler();
  };

  handleSelectedUserAccessChange = ({ userId, selectedOption }) => {
    this.props.handleSelectedUserAccessChange({ userId, selectedOption });
  };

  handleSelectedUserRemoval = ({ userId }) => {
    this.props.handleSelectedUserRemoval({ userId });
  };

  handleShareFormSubmit = async event => {
    this.props.handleShareFormSubmit(event);
  };

  handleShareFormChange = value => {
    this.props.handleShareFormChange(value);
  };

  constructShareUserOptions = optionsData =>
    _.map(optionsData, ({ email, id }) => ({
      label: email,
      value: id,
    }));

  getNonSelectedUsersOption = () => {
    const selectedUsersIds = _.map(this.props.postableUsersSharingData, 'id');

    const nonSelectedUsers = _.filter(
      this.props.shareableUsers,
      shareableUser => !_.includes(selectedUsersIds, shareableUser.id)
    );

    return this.constructShareUserOptions(nonSelectedUsers);
  };

  handleNewRoleSelect = value => {
    this.props.handleNewRoleSelect(value);
  };

  handleNotifyUsersChange = event => {
    this.props.handleNotifyUsersChange(event);
  };

  handleSelectedUsers = event => {
    this.props.handleSelectedUsers(event);
  };

  isUsersSelectedForAssignment = () => !_.isEmpty(this.props.selectedUsers);

  isUsersPresentInSharedList = () => !_.isEmpty(this.props.postableUsersSharingData);

  render() {
    const {
      postableUsersSharingData,
      selectedUsers,
      selectedUsersRole,
      notifyUsersByEmail,
      title,
    } = this.props;

    const isUsersUnderAssignmentSelection = this.isUsersSelectedForAssignment();
    const isUsersPresentInSharedList = this.isUsersPresentInSharedList();
    const userListCardClassName = isUsersPresentInSharedList ? 'card' : null;

    return (
      <Modal
        title={title}
        onClose={this.handleCloseModalClick}
        renderContent={() => (
          <React.Fragment>
            <div
              className="kt-form kt-form--label-right"
              style={{
                padding: '20px 10px',
                border: '4px solid #efefef',
              }}
            >
              <FormRow
                inlineInput
                label={'Invite Users'}
                className={'row col-md-12'}
                inputColClassName={'col-md-8'}
                labelClassName={'col-md-4'}
              >
                <MultiSelect
                  name="shareable_users"
                  className="selected-users-select"
                  placeholder="Pick E-Mail Addresses"
                  onChange={this.handleShareFormChange}
                  options={this.getNonSelectedUsersOption()}
                  value={selectedUsers}
                  optionIdentifier="value"
                  closeMenuOnSelect={false}
                  noOptionsMessage={() => 'No users found'}
                  hideSelectedOptions
                />
              </FormRow>
              <br />
              <FormRow
                inlineInput
                label={'Invited Users'}
                className={'row col-md-12'}
                inputColClassName={'col-md-8'}
                labelClassName={'col-md-4'}
              >
                <Select
                  name="access_roles"
                  onChange={this.handleNewRoleSelect}
                  options={getUserAccessTypeOptions()}
                  value={selectedUsersRole.value}
                  optionIdentifier="value"
                  isClearable={false}
                  formatOptionLabel={shareOptionValueRenderer}
                  components
                  className="borderless-role-select"
                  isSearchable={false}
                />
              </FormRow>
              <FormRow
                inlineInput
                label={'Notify Invited Users ?'}
                className={'row col-md-12'}
                inputColClassName={'col-md-8'}
                labelClassName={'col-md-4'}
              >
                <CheckBox
                  style={{
                    height: 30,
                  }}
                  showLabel
                  label=""
                  name="notify_users"
                  value={notifyUsersByEmail}
                  handleOnChange={event => this.handleNotifyUsersChange(event)}
                />
              </FormRow>
              <div className="row col-md-12" style={{ alignItems: 'center' }}>
                {isUsersUnderAssignmentSelection && (
                  <div className="col-md-2">
                    <input
                      className="btn btn-success"
                      type="submit"
                      name="Submit"
                      value="Add"
                      onClick={this.handleSelectedUsers}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={`col-md-12 table-responsive user-share-list-container ${userListCardClassName}`}
            >
              <table className="table table-striped">
                {isUsersPresentInSharedList && <caption>Who Has Access</caption>}
                <tbody>
                  {_.map(postableUsersSharingData, (shareableUserData, index) => (
                    <tr key={`shared-user-${shareableUserData.id}`}>
                      <td className="selected-user-row-padding selected-user-email">
                        {shareableUserData.email}
                      </td>

                      <td className="selected-user-role-col">
                        <Select
                          onChange={value =>
                            this.handleSelectedUserAccessChange({
                              userId: shareableUserData.id,
                              selectedOption: value,
                            })
                          }
                          options={getUserAccessTypeOptions()}
                          value={shareableUserData.access_level}
                          optionIdentifier="value"
                          isClearable={false}
                          isSearchable={false}
                          formatOptionLabel={shareOptionValueRenderer}
                          className="borderless-role-select"
                        />
                      </td>

                      <td className="selected-user-row-padding selected-user-remove">
                        <span
                          onClick={event => {
                            event.preventDefault();
                            this.handleSelectedUserRemoval({
                              userId: shareableUserData.id,
                            });
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
        renderFooter={() => {
          if (!isUsersUnderAssignmentSelection) {
            return <SubmitButton onClick={this.handleShareFormSubmit} />;
          }
        }}
      />
    );
  }
}

ShareModalContainer.propTypes = {
  title: PropTypes.string,
  selectedUsers: PropTypes.array,
  postableUsersSharingData: PropTypes.arrayOf(PropTypes.object),
  selectedUsersRole: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  shareableUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
    })
  ),
  notifyUsersByEmail: PropTypes.bool,
  closeModalHandler: PropTypes.func.isRequired,
  handleSelectedUserAccessChange: PropTypes.func.isRequired,
  handleSelectedUserRemoval: PropTypes.func.isRequired,
  handleShareFormSubmit: PropTypes.func.isRequired,
  handleShareFormChange: PropTypes.func.isRequired,
  handleNewRoleSelect: PropTypes.func.isRequired,
  handleNotifyUsersChange: PropTypes.func.isRequired,
  handleSelectedUsers: PropTypes.func.isRequired,
};

ShareModalContainer.defaultProps = {
  postableUsersSharingData: [],
  selectedUsers: [],
  title: 'Share With Users',
  notifyUsersByEmail: false,
  shareableUsers: [],
};

export default ShareModalContainer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AssetManager from '../filestack/AssetManager';
import EvaluateForm from './forms/EvaluateForm';
import CheckBox from './inputs/checkBox';
import Spinner from './presentational/spinner';
import { alertSuccessNotifications } from '../folders/utils';
import { recursiveSnakeCasetoCamelCase } from './utils';
import Http from './Http';
import Toggle from '../common/inputs/Toggle';

class SubmissionsEvaluator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAssets: [],
      loading: false,
      assessmentSubmissionsConfig: props.assessmentSubmissionsConfig,
      isSelectable: props.canEvaluate,
    };
  }

  toggleSelectableMode = () => {
    if (this.state.isSelectable) {
      this.setState({
        selectedAssets: [],
      });
    }
    const { isSelectable, selectedAssets } = this.state;
    this.setState(prevState => ({
      isSelectable: !prevState.isSelectable,
      selectedAssets: isSelectable ? [] : selectedAssets,
    }));
  };

  handleAssetSelect = selectedAssets => {
    this.setState({
      selectedAssets: selectedAssets,
    });
  };

  isSelectedAll = () => {
    const { selectedAssets } = this.state;
    const { assets } = this.props;
    const encodedAssets = _.filter(assets, 'encoded');

    return (
      encodedAssets.length > 0 &&
      _.map(encodedAssets, 'id').length === selectedAssets.length
    );
  };

  handleOnSelectAll = () => {
    if (this.isSelectedAll()) {
      this.setState({ selectedAssets: [] });
    } else {
      const { assets } = this.props;
      const encodedAssets = _.filter(assets, 'encoded');

      const newSelectedAssets = _.map(encodedAssets, 'id');
      this.setState({ selectedAssets: newSelectedAssets });
    }
  };

  hanldeEvaluationMarkComplete = async () => {
    const {
      csrfToken,
      tenantTerms: { term_submission: termSubmission, term_assignment: termAssignment },
    } = this.props;
    const { assessmentSubmissionsConfig } = this.state;

    const isAssessmentConfigPresent = !_.isNil(assessmentSubmissionsConfig);

    const proceedNext = confirm(
      `${termSubmission.singular} will be marked as completed without Evaluations. Do you want to continue ?`
    );

    if (isAssessmentConfigPresent && proceedNext) {
      const { user_id, links } = assessmentSubmissionsConfig;

      const Request = await new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .post(links.finalize_submission, {
          user_id: user_id,
        })
        .useAlerts()
        .onSuccess(response => {
          if (response.data) {
            this.setState(prevState => ({
              assessmentSubmissionsConfig: {
                ...assessmentSubmissionsConfig,
                finalized: true,
              },
              loading: false,
            }));
            alertSuccessNotifications(
              `Successfuly marked ${termAssignment.singular.toLowerCase()} as complete.`
            );
          }
        })
        .exec();
    }
  };

  hanldeEvaluationRevertToUnderReview = async () => {
    const {
      csrfToken,
      tenantTerms: { term_submission: termSubmission, term_assignment: termAssignment },
    } = this.props;
    const { assessmentSubmissionsConfig } = this.state;

    const isAssessmentConfigPresent = !_.isNil(assessmentSubmissionsConfig);

    const proceedNext = confirm(
      `${termSubmission.singular} will be reverted to Under Review state. Do you want to continue?`
    );

    if (isAssessmentConfigPresent && proceedNext) {
      const { user_id, links } = assessmentSubmissionsConfig;

      const Request = await new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .post(links.revert_submission_to_under_review, {
          user_id: user_id,
        })
        .useAlerts()
        .doesRedirect(true)
        .onSuccess(response => {
          if (response.data) {
            alertSuccessNotifications(
              `Successfuly Reverted ${termAssignment.singular.toLowerCase()} to Under Review.`
            );
            window.location.href = response.data.meta.redirection_url;
          }
        })
        .exec();
    }
  };

  handleAssessmentSubmissionMarkIncomplete = async event => {
    event.preventDefault();

    const {
      csrfToken,
      tenantTerms: {
        term_submission: termSubmission,
        term_assignment: termAssignment,
        term_user: termUser,
      },
    } = this.props;

    const { assessmentSubmissionsConfig } = this.state;
    const isAssessmentConfigPresent = !_.isNil(assessmentSubmissionsConfig);

    const proceedNext = confirm(
      `${termUser.singular} needs to upload new submissions. Do you want to mark ${termSubmission.singular} as incomplete?`
    );

    if (isAssessmentConfigPresent && proceedNext) {
      const { links } = assessmentSubmissionsConfig;

      await new Http(this)
        .setToken(csrfToken)
        .setLoading()
        .post(links.mark_submission_incomplete)
        .useAlerts()
        .doesRedirect(true)
        .onSuccess(response => {
          alertSuccessNotifications(
            `Marked ${termAssignment.singular.toLowerCase()} as incomplete successfully`
          );

          window.location.href = response.data.meta.redirection_url;
        })
        .exec();
    }
  };

  render() {
    const { isSelectable } = this.state;
    const {
      csrfToken,
      assets,
      noAssetOptions,
      evaluationConfig,
      canEvaluate,
      showFinalizeBtn,
      tenantTerms: tenant_terms,
      selectableGalleryConfig,
      noHover,
    } = this.props;

    const tenantTerms = recursiveSnakeCasetoCamelCase(tenant_terms);

    const { termSubmission } = tenantTerms;

    const { selectedAssets, loading, assessmentSubmissionsConfig } = this.state;

    let isFinalized = false;
    let isRejected = false;
    let self_evaluation_mode = false;
    const isAssessmentConfigPresent = !_.isNil(assessmentSubmissionsConfig);

    if (isAssessmentConfigPresent) {
      isFinalized = assessmentSubmissionsConfig.finalized;
      isRejected = assessmentSubmissionsConfig.rejected;
      self_evaluation_mode = assessmentSubmissionsConfig.self_evaluation_mode;
    }

    const isFinalizedOrRejected = isFinalized || isRejected;

    const encodedAssets = _.filter(assets, 'encoded');
    const mediaURLS = {
      url: _.map(_.filter(assets, ({ id }) => _.includes(selectedAssets, id)), 'url'),
    };

    return (
      <div
        className={`kt-portlet kt-portlet--mobile ${
          noHover ? ' kt-portlet--unelevate' : ''
        }`}
      >
        <div className="kt-portlet__head kt-portlet__head--mobile">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              {termSubmission.plural}
              {canEvaluate && (
                <small>
                  &nbsp;&nbsp;(
                  <small className="text-darkred">
                    *Please select {termSubmission.plural.toLowerCase()} to start
                    evaluation.
                  </small>
                  )
                </small>
              )}
            </h3>
          </div>
          {loading && <Spinner />}

          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-group">
              {canEvaluate && (
                <div className="d-flex align-items-center justify-content-md-end justify-content-sm-center h-100">
                  {isSelectable && (
                    <CheckBox
                      disabled={_.isEmpty(encodedAssets)}
                      name={'selectAll'}
                      value={this.isSelectedAll()}
                      handleOnChange={this.handleOnSelectAll}
                      showLabel={true}
                      label="Select All"
                      style={{ fontSize: '16px', minWidth: '90px' }}
                    />
                  )}
                  <span className="ml-15 mr-10" style={{ minWidth: '190px' }}>
                    <span className="pr-10">Gallery Mode</span>
                    <Toggle
                      name={'galleryMode'}
                      onClick={this.toggleSelectableMode}
                      size="sm"
                      on={selectableGalleryConfig.onLabel}
                      off={selectableGalleryConfig.offLabel}
                      height={30}
                      width={100}
                      recalculateOnResize
                      offstyle="default"
                      active={isSelectable}
                    />
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="scenario-video">
            <AssetManager
              csrfToken={csrfToken}
              showUploader={false}
              selectableMode={isFinalizedOrRejected ? false : isSelectable}
              existingAssets={assets}
              selectedAssets={selectedAssets}
              noAssetOptions={noAssetOptions}
              onAssetSelect={this.handleAssetSelect}
              selectableModeToggleText={selectableGalleryConfig}
              showFileDeleteOption={false}
            />
          </div>
        </div>

        <div className="kt-portlet__foot">
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            {canEvaluate && isSelectable && (
              <div className="m-2">
                <EvaluateForm
                  media={mediaURLS}
                  statusUid={evaluationConfig['statusUid']}
                  apiKey={evaluationConfig['apiKey']}
                  resource={evaluationConfig['resource']}
                  returnUrl={evaluationConfig['returnUrl']}
                  evaluatorId={evaluationConfig['evaluatorId']}
                  apiPostBackParams={JSON.stringify(
                    evaluationConfig['apiPostBackParams']
                  )}
                  disabled={isFinalizedOrRejected || _.isEmpty(selectedAssets)}
                />
              </div>
            )}
            <div className="d-flex m-2 align-items-center">
              {isFinalizedOrRejected && (
                <span className="kt-badge kt-badge--lg kt-badge--inline badge-darkred">
                  {`Marked As ${isFinalized ? 'Finalized' : 'Rejected'}`}
                </span>
              )}

              {isFinalizedOrRejected &&
                isAssessmentConfigPresent &&
                (_.isNil(
                  assessmentSubmissionsConfig.links['unrejected_submission_content']
                ) ? (
                  <button
                    type="button"
                    className="btn-outline-darkred mr-5 ml-5"
                    onClick={this.hanldeEvaluationRevertToUnderReview}
                  >
                    Revert to Under Review
                  </button>
                ) : (
                  <a
                    href={
                      assessmentSubmissionsConfig.links['unrejected_submission_content']
                    }
                    className="app-btn-primary mr-5 ml-5"
                  >
                    View Unrejected
                  </a>
                ))}

              {canEvaluate &&
                showFinalizeBtn &&
                !self_evaluation_mode &&
                isAssessmentConfigPresent &&
                !isFinalized &&
                !isRejected && (
                  <button
                    type="button"
                    disabled={!_.isEmpty(selectedAssets)}
                    className="btn btn-darkred mr-2"
                    onClick={this.hanldeEvaluationMarkComplete}
                  >
                    Finalize
                  </button>
                )}

              {!isFinalized &&
                !isRejected &&
                !self_evaluation_mode &&
                isAssessmentConfigPresent && (
                  <button
                    type="button"
                    className="btn btn-darkred mr-2"
                    onClick={this.handleAssessmentSubmissionMarkIncomplete}
                  >
                    Mark Incomplete
                  </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SubmissionsEvaluator.propTypes = {
  assets: PropTypes.array.isRequired,
  canEvaluate: PropTypes.bool.isRequired,
  csrfToken: PropTypes.string.isRequired,
  evaluationConfig: PropTypes.object.isRequired,
  assessmentSubmissionsConfig: PropTypes.object,
  showFinalizeBtn: PropTypes.bool,
  noAssetOptions: PropTypes.bool,

  tenantTerms: PropTypes.shape({
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }),
    term_submission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }),
  }).isRequired,
  selectableGalleryConfig: PropTypes.shape({
    onLabel: PropTypes.string,
    offLabel: PropTypes.string,
  }),
  noHover: PropTypes.bool,
};

SubmissionsEvaluator.defaultProps = {
  assessmentSubmissionsConfig: null,
  showFinalizeBtn: true,
  noAssetOptions: false,
  selectableGalleryConfig: {
    onLabel: 'On',
    offLabel: 'Off',
  },
  noHover: false,
};

export default SubmissionsEvaluator;

import React from 'react';

import map from 'lodash/map';

import useLearnerTabContext from '../hooks/useLearnerTabContext';

const TabHeader = () => {
  const { activeTab, setActiveTab, tabs } = useLearnerTabContext();
  return (
    <div className="d-flex justify-content-center align-items-center flex-column mt-5 mt-lg-0 mb-2">
      <h3 className="text-capitalize my-4 mx-2 text-capitalize">
        <strong className="app-text-primary">{activeTab?.label}</strong>
      </h3>

      <ul className="learner-dashboard__tabs mb-3 mx-2 p-0">
        {map(tabs, tab => (
          <li
            key={tab.key}
            className={`text-capitalize cursor-pointer${
              tab.key === activeTab?.key ? ' app-text-primary ' : ''
            }`}
            onClick={() => setActiveTab(tab)}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabHeader;

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import FormRow from '../common/presentational/formRow';
import Toggle from '../common/inputs/Toggle';
import SubmitButton from '../common/presentational/submitButton';
import CancelButton from '../common/presentational/cancelButton';
import Http from '../common/Http';
import {
  removePageLeavePreventDialog,
  getSnakeCaseKeyedObject,
} from '../common/utils';
import Spinner from '../common/presentational/spinner';
import { withErrorHandler } from '../hoc/withErrorHandler';

const buildState = data => ({
  learning: data.learning,
  projects: data.projects,
  simpleProject: data.simpleProject,
  singleProject: data.singleProject,
  support: data.support,
  allowForms: data.allowForms,
  teams: data.teams,
  files: data.files,
  allowTicketTasks: data.allowTicketTasks,
  useFeedbackFormTypes: data.useFeedbackFormTypes,
});

const AppsSettingsForm = props => {
  const [loading, setLoading] = React.useState(false);
  const [appsSettings, setAppsSettings] = React.useState(buildState(props));

  React.useEffect(() => {
    setAppsSettings(buildState(props));
  }, [props]);

  const appsFieldElements = React.useMemo(() => {
    const { termProject, termSupport, termTicket } = props.tenantTerms;
    return Object.freeze([
      { label: 'Learning', key: 'learning' },
      {
        label: `Single ${termProject.singular}`,
        key: 'singleProject',
        message: `* cannot be enabled when ${termProject.plural} is enabled`,
      },
      {
        label: `${termProject.plural}`,
        key: 'projects',
        message: `* cannot be enabled when Single ${termProject.singular} is enabled`,
      },
      {
        label: `Simple ${termProject.singular}`,
        key: 'simpleProject',
        message: `* cannot be enabled when ${termProject.singular} is disabled`,
      },
      { label: `Teams`, key: 'teams' },
      // { label: `${termSupport.plural}`, key: 'support' },
      // { label: 'Forms', key: 'allowForms' },
      // { label: 'Feedback Form Types', key: 'useFeedbackFormTypes' },
      { label: 'Files', key: 'files' },
      { label: `${termTicket.singular} To-Do`, key: 'allowTicketTasks' },
    ]);
  }, [props.tenantTerms]);

  const onFormSubmit = async event => {
    event.preventDefault();

    const { csrfToken, links } = props;
    const snakeCasedAppsSettings = getSnakeCaseKeyedObject(appsSettings);

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(true)
      .patch(links.settingsPath, {
        tenant: { ...snakeCasedAppsSettings },
      })
      .onSuccess(res => {
        setLoading(false);
        removePageLeavePreventDialog();
        window.location.href = res.data.redirection_url;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handleToggleFieldValue = (fieldIdentifier, value) => {
    setAppsSettings(prevState => {
      return {
        ...prevState,
        [fieldIdentifier]: value,
      };
    });
  };

  const getFormAttributeValue = attribute => {
    return appsSettings[attribute];
  };

  const commonToggleProps = {
    on: <span>ON</span>,
    off: <span>OFF</span>,
    size: 'md',
    recalculateOnResize: true,
    offstyle: 'default',
  };

  return (
    <React.Fragment>
      <form
        className="kt-form kt-form--label-right kt-form--fit"
        onSubmit={onFormSubmit}
      >
        <div className="kt-portlet__body">
          {loading && <Spinner text="Submitting" />}

          <div className="kt-form__heading">
            <h3 className="kt-form__heading-title">Apps</h3>
          </div>

          {map(appsFieldElements, ({ label, key, message }) => {
            const toggleValue = getFormAttributeValue(key);
            let isDisabled = false;
            let showHelperText = false;

            if (
              (key === 'singleProject' && getFormAttributeValue('projects')) ||
              (key === 'simpleProject' && !getFormAttributeValue('projects')) ||
              (key === 'projects' && getFormAttributeValue('singleProject'))
            ) {
              isDisabled = true;
              showHelperText = true;
            } else if (
              key === 'simpleProject' &&
              getFormAttributeValue('projects')
            ) {
              showHelperText = false;
            }

            return (
              <FormRow
                key={`${key}_switch`}
                label={label}
                labelClassName={'text-left col-md-2'}
              >
                <Toggle
                  name={key}
                  onClick={value => handleToggleFieldValue(key, value)}
                  active={toggleValue}
                  disabled={isDisabled}
                  {...commonToggleProps}
                />
                {message && showHelperText && (
                  <div className="app-text-primary font-11">{message}</div>
                )}
              </FormRow>
            );
          })}
        </div>

        <div className="kt-portlet__foot">
          <div className="kt-form__actions text-right">
            <CancelButton className="mr-2" />
            <SubmitButton />
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

AppsSettingsForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    settingsPath: PropTypes.string.isRequired,
  }).isRequired,
  tenantTerms: PropTypes.oneOfType([PropTypes.object]).isRequired,
  learning: PropTypes.bool.isRequired,
  projects: PropTypes.bool.isRequired,
  simpleProject: PropTypes.bool.isRequired,
  singleProject: PropTypes.bool,
  teams: PropTypes.bool.isRequired,
  support: PropTypes.bool.isRequired,
  allowForms: PropTypes.bool.isRequired,
  files: PropTypes.bool.isRequired,
  allowTicketTasks: PropTypes.bool.isRequired,
  useFeedbackFormTypes: PropTypes.bool.isRequired,
};

export default withErrorHandler(AppsSettingsForm);

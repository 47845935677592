import React from 'react';
import RenderSmoothImage from 'render-smooth-image-react';
import PropTypes from 'prop-types';
import 'render-smooth-image-react/build/style.css';

const SmoothImage = ({ src, alt, objectFit, onLoad, ...otherProps }) => (
  <RenderSmoothImage
    src={src}
    alt={_.capitalize(alt)}
    objectFit={objectFit}
    onLoad={onLoad}
    {...otherProps}
  />
);

SmoothImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  objectFit: PropTypes.string,
  onLoad: PropTypes.func,
};

SmoothImage.defaultProps = {
  alt: 'notFound',
  objectFit: 'contain',
  onLoad: () => null,
};

export default SmoothImage;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import { formatDateTime } from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

import isEmpty from 'lodash/isEmpty';

const CourseSurveyListing = ({ fetchResponsesListing, csrfToken }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    new Http()
      .onBegin(() => setIsLoading(true))
      .setToken(csrfToken)
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .get(fetchResponsesListing)
      .onSuccess(({ data: { userResponses } }) => {
        setData(userResponses);
        setIsLoading(false);
      })
      .onError(() => setIsLoading(false))
      .exec();
  }, [csrfToken, fetchResponsesListing]);

  const renderEmptyView = () => (
    <div className="text-center">
      <h1>No Responses found</h1>
    </div>
  );

  const renderTableBody = () => (
    <tbody>
      {data.map(
        ({ id, submitterName, submittedAt, links: { view: viewLink } }) => (
          <tr key={id}>
            <td>{submitterName}</td>
            <td>{formatDateTime({ date: submittedAt })}</td>
            <td>
              <a href={viewLink}>
                View <i className="far fa-eye"></i>
              </a>
            </td>
          </tr>
        )
      )}
    </tbody>
  );

  return (
    <div className="kt-portlet">
      {isLoading && <Spinner />}
      <div className="kt-portlet__body">
        <div>
          {!isEmpty(data) ? (
            <table className="table table-striped">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Submitted on</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              {renderTableBody()}
            </table>
          ) : (
            renderEmptyView()
          )}
        </div>
      </div>
    </div>
  );
};

CourseSurveyListing.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  fetchResponsesListing: PropTypes.string.isRequired,
};

export default withErrorHandler(CourseSurveyListing);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SelectInput from '../common/inputs/select';
import Http from '../common/Http';
import TextInput from '../common/inputs/text';
import FormElementHelperText from '../common/presentational/FormElementHelperText';
import FormRow from '../common/presentational/formRow';
import { alertSuccessNotifications } from '../folders/utils';
import AssetManager from '../filestack/AssetManager';
import { withErrorHandler } from '../hoc/withErrorHandler';

const INITIAL_DATA = {
  categoryId: '',
  title: '',
  body: '',
  attachments: [],
  existingAttachments: [],
};

const TopicForm = ({
  onSuccess,
  categories,
  submitLink,
  onTopicTitleChange,
  closeModal,
  csrfToken,
  defaultSelectValue,
  topicAttachmentsConfig,
  disableSubmitButton,
  getDataLink,
}) => {
  const [formData, setFormData] = useState(INITIAL_DATA);
  const [loading, setLoading] = useState(false);
  const [isFetchingFormData, setIsFetchingFormData] = useState(false);

  useEffect(() => {
    const fetchFormData = () => {
      new Http()
        .setToken(csrfToken)
        .useAlerts()
        .get(getDataLink)
        .onBegin(() => setIsFetchingFormData(true))
        .useAPIDataFormatters({
          snakifyRequestData: true,
          camelizeResponseData: true,
        })
        .onSuccess(({ data: { topic } }) => {
          setFormData({
            categoryId: topic.categoryId,
            title: topic.title,
            body: topic.body,
            attachments: [],
            existingAttachments: topic.attachments,
          });
          setIsFetchingFormData(false);
        })
        .onError(() => {
          setIsFetchingFormData(false);
        })
        .exec();
    };

    if (getDataLink) {
      fetchFormData();
    }

    // the value csrfToken is always a static value and the getDataLink is a string based on the title of the topic. So whenever the user tries to update the title the effect will triggered after the topic form component is unmounted, because of the getDataLink.
    // to avoid the unwanted trigger of the unmounted component, removed the dependency array variables.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandle = e => {
    e.preventDefault();

    const Requestor = new Http()
      .setToken(csrfToken)
      .useAlerts()
      .onBegin(() => setLoading(true))
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData({
        categoryId: formData.categoryId,
        title: formData.title,
        body: formData.body,
        attachments: formData.attachments,
      })
      .onSuccess(data => {
        onSuccess(data);
        setFormData(preValues => ({
          title: '',
          body: '',
          categoryId: preValues.categoryId,
        }));
        setLoading(false);
        alertSuccessNotifications(
          `Topic successfully ${data ? 'Saved.' : 'posted.'}`
        );
        closeModal();
      })
      .onError(() => {
        setLoading(false);
      });

    if (getDataLink) {
      Requestor.put(submitLink);
    } else {
      Requestor.post(submitLink);
    }

    Requestor.exec();
  };

  const inputChange = (name, value) => {
    if (name === 'title' && onTopicTitleChange) {
      onTopicTitleChange(value);
    }
    setFormData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const onAttachmentChange = (attachments, existingAttachments) => {
    setFormData(prev => ({
      ...prev,
      attachments,
      existingAttachments,
    }));
  };

  if (isFetchingFormData) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: '300px' }}
      >
        <div
          className="spinner-grow"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmitHandle} className="d-flex flex-column">
      <FormRow label="Category">
        <div>
          <SelectInput
            name="categoryId"
            placeholder="Select a category"
            options={categories}
            onChange={v => inputChange('categoryId', v.id)}
            defaultValue={
              defaultSelectValue || categories?.[formData.categoryId] || ''
            }
            closeMenuOnSelect
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            isClearable={false}
            isDisabled={loading}
          />
          <FormElementHelperText text="* category is required" />
        </div>
      </FormRow>
      <FormRow label="Title">
        <TextInput
          name="title"
          placeholder="ex: How to ....?"
          value={formData.title}
          onInputChange={({ target: { value, name } }) =>
            inputChange(name, value)
          }
          numberAttributes={{
            required: true,
          }}
          disabled={loading}
        />
      </FormRow>
      <FormRow label="Body">
        <textarea
          name="body"
          value={formData.body}
          onChange={({ target: { value, name } }) => inputChange(name, value)}
          className="mb-4 w-100"
          required
          disabled={loading}
        />
      </FormRow>
      <FormRow label="Attachments">
        <AssetManager
          showLabel={false}
          showUploader={true}
          csrfToken={csrfToken}
          filestackOptions={topicAttachmentsConfig}
          existingAssets={formData.existingAttachments}
          assets={formData.attachments}
          showAssetsAsListItems
          onFileChange={onAttachmentChange}
          renderUploader={({ onPick }) => (
            <button
              className="btn-sm app-btn-primary"
              onClick={!loading ? onPick : null}
              disabled={loading}
            >
              <i className="la la-plus"></i> Select Files
            </button>
          )}
        />
      </FormRow>

      <div className="text-right">
        <button
          className="btn-outline-darkred mr-2"
          onClick={e => {
            e.preventDefault();
            closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className="btn app-btn-primary"
          disabled={loading || disableSubmitButton}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </form>
  );
};

TopicForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  defaultSelectValue: PropTypes.object,
  topicAttachmentsConfig: PropTypes.object,
  categories: PropTypes.array,
  submitLink: PropTypes.string.isRequired,
  onTopicTitleChange: PropTypes.func,
  csrfToken: PropTypes.string.isRequired,
  getDataLink: PropTypes.string,
  disableSubmitButton: PropTypes.bool,
};

TopicForm.defaultProps = {
  categories: [],
  onTopicTitleChange: null,
  defaultSelectValue: null,
  disableSubmitButton: false,
  getDataLink: null,
};

export default withErrorHandler(TopicForm);

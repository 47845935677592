import React from 'react';
import PropTypes from 'prop-types';
import { getAssetTypeFromMIME, renderAttachmentThumbnail } from '../common/utils';
import SmoothImage from '../common/presentational/SmoothImage';

const Asset = ({ id, mimetype, url, poster, openModal, className }) => (
  <div>
    {getAssetTypeFromMIME(mimetype) == 'image' && (
      <div
        className={`image test ${className}`}
        onClick={() => openModal(url, 'image', id)}
      >
        <SmoothImage src={url} />
      </div>
    )}
    {getAssetTypeFromMIME(mimetype) == 'video' && (
      <div
        className={`image ${className}`}
        onClick={() => openModal(url, 'video', id, poster)}
      >
        <SmoothImage src={renderAttachmentThumbnail(mimetype, poster)} />
      </div>
    )}
    {getAssetTypeFromMIME(mimetype) == 'file' && (
      <div
        className={`icon ${className}`}
        style={{
          backgroundImage: `url(${renderAttachmentThumbnail(mimetype)})`,
        }}
      />
    )}
  </div>
);

Asset.propTypes = {
  id: PropTypes.number,
  mimetype: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  openModal: PropTypes.func,
};

Asset.defaultProps = {
  openModal: () => {},
};

export default Asset;

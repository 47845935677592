export default {
  items: [
    {
      key: 'Header',
      name: 'Header Text',
      icon: 'fa fa-header',
      static: true,
      content: 'Placeholder Text...',
    },
    {
      key: 'Label',
      name: 'Label',
      static: true,
      icon: 'fa fa-font',
      content: 'Placeholder Text...',
    },
    {
      key: 'Paragraph',
      name: 'Paragraph',
      static: true,
      icon: 'fa fa-paragraph',
      content: 'Placeholder Text...',
    },
    {
      key: 'LineBreak',
      name: 'Line Break',
      static: true,
      icon: 'fa fa-arrows-h',
    },
    {
      key: 'Signature',
      canReadOnly: true,
      name: 'Signature',
      icon: 'fa fa-pencil-square-o',
      label: 'Signature',
      field_name: 'signature_',
    },
    {
      key: 'Dropdown',
      canHaveAnswer: true,
      name: 'Dropdown',
      icon: 'fa fa-caret-square-o-down',
      label: 'Placeholder Label',
      field_name: 'dropdown_',
      options: [],
    },
    {
      key: 'Tags',
      canHaveAnswer: true,
      name: 'Tags',
      icon: 'fa fa-tags',
      label: 'Placeholder Label',
      field_name: 'tags_',
      options: [],
    },
    {
      key: 'Checkboxes',
      canHaveAnswer: true,
      name: 'Checkboxes',
      icon: 'fa fa-check-square-o',
      label: 'Placeholder Label',
      field_name: 'checkboxes_',
      options: [],
    },
    {
      key: 'RadioButtons',
      canHaveAnswer: true,
      name: 'Multiple Choice',
      icon: 'fa fa-dot-circle-o',
      label: 'Placeholder Label',
      field_name: 'radio_buttons_',
      options: [],
    },
    {
      key: 'TextInput',
      canHaveAnswer: true,
      name: 'Text Input',
      label: 'Placeholder Label',
      icon: 'fa fa-font',
      field_name: 'text_input_',
    },
    {
      key: 'NumberInput',
      canHaveAnswer: true,
      name: 'Number Input',
      label: 'Placeholder Label',
      icon: 'fa fa-plus',
      field_name: 'number_input_',
    },
    {
      key: 'TextArea',
      canHaveAnswer: true,
      name: 'Multi-line Input',
      label: 'Placeholder Label',
      icon: 'fa fa-text-height',
      field_name: 'text_area_',
    },
    {
      key: 'Rating',
      canHaveAnswer: true,
      name: 'Rating',
      label: 'Placeholder Label',
      icon: 'fa fa-star',
      field_name: 'rating_',
    },
    {
      key: 'DatePicker',
      canDefaultToday: true,
      canReadOnly: true,
      name: 'Date',
      icon: 'fa fa-calendar',
      label: 'Placeholder Label',
      field_name: 'date_picker_',
    },
    {
      key: 'Range',
      name: 'Range',
      icon: 'fa fa-sliders',
      label: 'Placeholder Label',
      field_name: 'range_',
      step: 1,
      default_value: 5,
      min_value: 1,
      max_value: 10,
      min_label: 'Not at all likely',
      max_label: 'Extremely likely',
    },
    // {
    //   key: 'HyperLink',
    //   name: 'Web site',
    //   icon: 'fa fa-link',
    //   static: true,
    //   content: 'Placeholder Web site link ...',
    //   href: 'http://www.example.com'
    // },
  ],
};

import React, { memo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import SmoothImage from '../presentational/SmoothImage';
import './style.scss';
import { getRandomColor } from '../Avatar/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { GRADIENT_COLORS } from './gradients';
import ColorThief from '../colorThief/index';

const BackgroundGradient = ({ imgUrl, text, className, objectFit }) => {
  const [bgColor, setBgColor] = useState(null);
  const uniqImgUrl = useRef(`${imgUrl}?${Date.now()}`);

  const imageLoaded = event => {
    const colorThief = new ColorThief();
    try {
      const [r, g, b] = colorThief.getColor(event.target);
      setBgColor(`rgb(${r}, ${g}, ${b})`);
    } catch (err) {
      console.warn('error when setting background color', err, event);
    }
  };

  return (
    <div
      className={`image-generator-container ${className}`}
      style={{
        background: bgColor
          ? bgColor
          : text
          ? getRandomColor(text, GRADIENT_COLORS)
          : 'linear-gradient(to left, #2193b0, #6dd5ed)',
      }}
    >
      {imgUrl && (
        <SmoothImage
          src={uniqImgUrl.current}
          alt={text}
          objectFit={objectFit}
          onLoad={imageLoaded}
          imageProps={{
            crossOrigin: 'anonymous',
          }}
        />
      )}
    </div>
  );
};

BackgroundGradient.propTypes = {
  imgUrl: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  objectFit: PropTypes.string,
};

BackgroundGradient.defaultProps = {
  imgUrl: '',
  text: '',
  className: '',
  objectFit: 'cover',
};

export default withErrorHandler(memo(BackgroundGradient));

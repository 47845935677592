import React from 'react';
import PropTyes from 'prop-types';
import './styles.scss';

const Popover = ({ className, content, children, title, position, ...other }) => {
  const classes = `custom-popover ${className}`;
  const popoverClasses = `${position}Popover popoverWrapper`;
  return (
    <span className={classes}>
      <span className={'contentWrapper'} {...other}>
        {children}
      </span>

      <div className={popoverClasses} aria-label="popover-content" tabIndex={0}>
        {title && (
          <h3 className={'popover-header'} aria-label="popover-title">
            {title}
          </h3>
        )}
        <div className={'popover-body'}>{content}</div>
        <span className={'popoverArrow'} />
      </div>
    </span>
  );
};

Popover.propTypes = {
  className: PropTyes.string,
  children: PropTyes.oneOfType([PropTyes.node, PropTyes.element]),
  content: PropTyes.oneOfType([PropTyes.node, PropTyes.element]).isRequired,
  title: PropTyes.string,
  position: PropTyes.string,
};

Popover.defaultProps = {
  className: '',
  children: null,
  title: null,
  position: 'left',
};

export default Popover;

import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
  useReducer,
  createContext,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

import Modal from '../filestack/modal';
import SelectInput from '../common/inputs/select';
import Http from '../common/Http';
import TextInput from '../common/inputs/text';
import TopicSuggestion from './TopicSuggestion';
import Breadcrumbs from '../common/Breadcrumbs';

import { convertArrayOfObjectsToHash } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { getCategoryRandomColors } from './utils';
import TopicForm from './TopicForm';
import Notification from './Notification';
import ForumContextProvider from '../common/ContextProvider';
import './forum.scss';
import {
  learningForumDataReducers,
  learningForumLoadingReducers,
} from './learningForumReducers';
import { INITIAL_LEARNING_FORUM_STATE, INITIAL_LOADER_STATE } from './constant';
import ForumTable from './ForumTable';

const PAGINATION_COUNT = 50;

export const LearningForumContext = createContext();

const LearningForum = ({
  tenantTerms,
  csrfToken,
  categories: allCategories,
  links: {
    postTopic,
    viewTopics,
    viewCourse,
    searchTopics,
    toggleNotifications,
  },
  currentUserId,
  notificationsSubscribed,
  topicAttachmentsConfig,
  isCourseAdmin,
}) => {
  // Loaders
  const [loaderState, dispatchLoaderActions] = useReducer(
    learningForumLoadingReducers,
    INITIAL_LOADER_STATE
  );
  const { isPaginationLoading, isSuggestionsLoading } = loaderState;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categories] = useState(
    convertArrayOfObjectsToHash(allCategories, 'id')
  );

  const [learningForumState, dispatchForumActions] = useReducer(
    learningForumDataReducers,
    INITIAL_LEARNING_FORUM_STATE
  );
  const {
    allTopics,
    newTopicTitle,
    topicSuggestions,
    categoryFilter,
    topicFilter,
  } = learningForumState;

  const initialRender = useRef(true); // To resist the Effect to run on initial mount - only on dependency change
  const initialRenderNewTopic = useRef(true); // To resist the Effect to run on initial mount - only on dependency change
  const pagingRef = useRef(null);

  const fetchFilteredData = async (options = {}) => {
    let link;

    if (
      options.isSuggestion ||
      (options.link && options.topicFilter) ||
      options.topicFilter
    ) {
      link = searchTopics;
    } else {
      link = viewTopics;
    }

    let queryParams = {
      paging: {
        count: PAGINATION_COUNT,
      },
    };

    if (options.pagination && options.pagination.hasNextPage) {
      queryParams = {
        paging: {
          startIndex: options.pagination.startIndex,
          count: PAGINATION_COUNT,
        },
      };
    }

    if (options.categoryFilter && !options.isSuggestion) {
      queryParams = {
        ...queryParams,
        categoryId: options.categoryFilter,
      };
    }
    if (options.topicFilter && !options.isSuggestion) {
      queryParams = {
        ...queryParams,
        searchTerm: options.topicFilter,
      };
    }
    if (options.isSuggestion) {
      queryParams = {
        paging: {
          count: 10,
        },
        searchTerm: options.suggestionFor,
      };
    }

    const Requestor = new Http()
      .setToken(csrfToken)
      .onBegin(() => {
        dispatchLoaderActions({
          type: 'handleLoader',
          options: options,
          pagingRef: pagingRef,
        });
      })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .get(link)
      .onSuccess(({ data: { topics, paging } }) => {
        if (options.isSuggestion) {
          dispatchForumActions({
            type: 'setTopicSuggestionsData',
            payload: {
              topicSuggestions: topics,
            },
          });
          pagingRef.current = null;
        } else if (!isEmpty(options) && !options.pagination) {
          dispatchForumActions({
            type: 'setAllTopicData',
            payload: {
              allTopics: topics,
            },
          });
          pagingRef.current = paging;
        } else {
          dispatchForumActions({
            type: 'setAllTopicData',
            payload: {
              allTopics: [...allTopics, ...topics],
            },
          });
          pagingRef.current = paging;
        }
        dispatchLoaderActions({ type: 'resolved' });
      })
      .onError(() => {
        dispatchLoaderActions({ type: 'rejected' });
      })
      .cancelPrevRequest();

    await Requestor.setQueryParams(queryParams).exec();
  };

  // we don't need the fetch in the dependency array, which will eventually fetch in infinite loop.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchData = useCallback(
    debounce(val => fetchFilteredData(val), 400),
    []
  );

  useEffect(() => {
    if (initialRender.current && window.history.replaceState) {
      window.history.replaceState('', '', window.location.pathname);
    }
  }, [topicFilter, categoryFilter]);

  const fetchInitialData = async () => {
    let queryParams = {
      paging: {
        count: PAGINATION_COUNT,
      },
    };

    if (pagingRef.current && pagingRef.current.hasNextPage) {
      queryParams = {
        paging: {
          startIndex: pagingRef.current.startIndex,
          count: PAGINATION_COUNT,
        },
      };
      dispatchLoaderActions({ type: 'setIsPaginationLoading' });
    }

    new Http()
      .onBegin(() => {
        dispatchLoaderActions({ type: 'setLoading' });
      })
      .setToken(csrfToken)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .get(viewTopics)
      .useAlerts()
      .onSuccess(({ data: { paging, topics } }) => {
        pagingRef.current = paging;
        dispatchForumActions({
          type: 'setAllTopicData',
          payload: {
            allTopics: !!paging.startIndex ? [...allTopics, ...topics] : [...topics],
          },
        });
        dispatchLoaderActions({ type: 'resolved' });
      })
      .onError(() => {
        dispatchLoaderActions({ type: 'rejected' });
      })
      .setQueryParams(queryParams)
      .exec();
  };

  useEffect(() => {
    setTimeout(() => {
      if (categoryFilter || topicFilter) {
        debouncedFetchData({
          link: searchTopics,
          topicFilter,
          categoryFilter,
          initialFetch: true,
        });
      } else {
        fetchInitialData();
      }
    }, 300);

    // To trigger only once on the initial mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      debouncedFetchData({
        link: searchTopics,
        topicFilter,
        categoryFilter,
      });
    }

    // debounced function on dependency array will create a recursive triggering of the data fetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicFilter, categoryFilter]);

  useEffect(() => {
    if (initialRenderNewTopic.current) {
      initialRenderNewTopic.current = false;
    } else {
      debouncedFetchData({
        link: searchTopics,
        suggestionFor: newTopicTitle,
        isSuggestion: true,
      });
    }

    // to trigger only on the new topic title change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newTopicTitle]);

  const afterDelete = deletedTopicId => {
    dispatchForumActions({
      type: 'setAllTopicData',
      payload: {
        allTopics: allTopics.filter(topic => topic.id !== deletedTopicId),
      },
    });
  };

  const closeModal = () => setIsModalOpen(false);

  // const inputChange = (name, value) => {
  //   setNewTopic(prev => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };

  const renderModalBody = () => (
    <Fragment>
      <TopicForm
        csrfToken={csrfToken}
        onSuccess={({ data: { topic } }) => {
          if (
            topic.categoryId === categoryFilter ||
            !isNumber(categoryFilter)
          ) {
            dispatchForumActions({
              type: 'setAllTopicData',
              payload: {
                allTopics: [topic, ...allTopics],
              },
            });
          }
        }}
        categories={allCategories}
        submitLink={postTopic}
        onTopicTitleChange={value => {
          dispatchForumActions({
            type: 'setNewTopicTitle',
            payload: { newTopicTitle: value },
          });
        }}
        closeModal={closeModal}
        topicAttachmentsConfig={topicAttachmentsConfig}
        disableSubmitButton={isSuggestionsLoading}
      />
      {!isEmpty(topicSuggestions) && (
        <div className="">
          <h3 className="text-center">Similar Topics posted</h3>
          {topicSuggestions.map(topic => (
            <TopicSuggestion
              key={topic.id}
              topic={topic}
              category={categories[topic.categoryId]}
            />
          ))}
        </div>
      )}
    </Fragment>
  );

  const loadMoreRows = () => {
    if (isPaginationLoading || !pagingRef.current.hasNextPage) return;

    fetchFilteredData({
      pagination: pagingRef.current,
      categoryFilter,
    });
  };

  const renderCategory = () => {
    const categoryFilters = [{ id: '', name: 'All' }, ...allCategories];

    const CustomOption = prop => {
      const { innerProps, innerRef, data } = prop;
      return (
        <div className="custom-select-option" ref={innerRef} {...innerProps}>
          <div
            style={{
              backgroundColor: getCategoryRandomColors(data.name),
            }}
            className="color mr-2"
          ></div>
          <span className="text-black-50 font-weight-normal">{data.name}</span>
        </div>
      );
    };

    const renderOptionLabel = label => (
      <div>
        <i
          className="fa fa-circle mr-2 font-10"
          style={{ color: getCategoryRandomColors(label) }}
        />
        {label}
      </div>
    );

    return (
      <div className="category-filter">
        <SelectInput
          name="categoryFilter"
          placeholder="Select a category"
          options={categoryFilters}
          onChange={v => {
            pagingRef.current = null;
            dispatchForumActions({
              type: 'setCategoryFilter',
              payload: { categoryFilter: v.id },
            });
          }}
          value={categoryFilter || categoryFilter[0] || ''}
          closeMenuOnSelect
          optionIdentifier="id"
          isClearable={false}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          components={{ Option: CustomOption }}
          formatOptionLabel={({ name }) => renderOptionLabel(name)}
        />
      </div>
    );
  };

  const value = useMemo(() => {
    return {
      forumDataState: learningForumState,
      forumLoaderState: loaderState,
      dispatchDataActions: dispatchForumActions,
      dispatchLoaderActions: dispatchLoaderActions,
      tenantTerms: tenantTerms,
      currentUserId: currentUserId,
      categories: categories,
    };
  }, [categories, currentUserId, learningForumState, loaderState, tenantTerms]);

  return (
    <ForumContextProvider ComponentContext={LearningForumContext} value={value}>
      <Breadcrumbs
        data={[
          {
            label: tenantTerms?.termCourse.singular,
            link: viewCourse,
          },
          {
            label: 'Forum',
            link: null,
          },
        ]}
      />
      <div className="forum-container text-black-50">
        <div className="d-flex align-items-center justify-content-between mb-5 forum-header__tools">
          <div className="d-flex align-items-center my-2 w-100">
            <div className="col-md-4 pl-0">
              <TextInput
                placeholder="Search by topic"
                value={topicFilter}
                name="topicFilter"
                onInputChange={({ target: { value } }) => {
                  pagingRef.current = null;
                  dispatchForumActions({
                    type: 'setTopicFilter',
                    payload: { topicFilter: value },
                  });
                }}
              />
            </div>
            <div className="col-md-4 pr-0">{renderCategory()}</div>
          </div>
          <div className="d-flex align-items-center justify-content-end w-100">
            <button
              className="app-btn-hover-secondary"
              onClick={() => setIsModalOpen(true)}
            >
              <i className="fa fa-plus"></i>
              New Topic
            </button>
            <div className="ml-2">
              <Notification
                isSubscribed={notificationsSubscribed}
                link={toggleNotifications}
                csrfToken={csrfToken}
                showNotificationText={false}
              />
            </div>
          </div>
        </div>
        <ForumTable
          csrfToken={csrfToken}
          isCourseAdmin={isCourseAdmin}
          pagingRef={pagingRef}
          topicAttachmentsConfig={topicAttachmentsConfig}
          loadMoreRows={loadMoreRows}
          afterDelete={afterDelete}
          afterTogglePin={fetchInitialData}
        />
        {isModalOpen && (
          <Modal
            title="Post a New Topic"
            onClose={closeModal}
            renderContent={renderModalBody}
            large
            closeOnEscKey={false}
            closeOnBackdropClick={false}
          />
        )}
      </div>
    </ForumContextProvider>
  );
};

LearningForum.propTypes = {
  courseName: PropTypes.string,
  categories: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    postTopic: PropTypes.string.isRequired,
    viewTopics: PropTypes.string.isRequired,
    viewCourse: PropTypes.string.isRequired,
    searchTopics: PropTypes.string.isRequired,
    toggleNotifications: PropTypes.string.isRequired,
  }),
  currentUserId: PropTypes.number.isRequired,
  notificationsSubscribed: PropTypes.bool.isRequired,
  isCourseAdmin: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  topicAttachmentsConfig: PropTypes.object,
};

LearningForum.defaultPropTypes = {
  isCourseAdmin: false,
};

export default withErrorHandler(LearningForum);

import React, { createContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import DashboardContextProvider from '../../../common/ContextProvider';

import useFetchQueryParams from '../../../common/hooks/useFetchParams';
import TabHeader from './TabHeader';
import TabContent from './DashboardTabContent';

export const DashboardContext = createContext();

const RenderDashboardTabs = ({ tabs, children }) => {
  const [activeTab, setActiveTab, getQueryParamStatus] =
    useFetchQueryParams(tabs);

  useEffect(() => {
    const redirectPrev = e => {
      e.preventDefault();

      getQueryParamStatus();
    };
    window.addEventListener('popstate', redirectPrev, false);
    return () => {
      window.removeEventListener('popstate', redirectPrev, false);
    };

    // the getQueryParamStatus handler is an external function to persist the state of the tab.
    // By adding it to the dependency array, will trigger the function multiple times.Which won't be any issues visually but the efficiency of the code is affected. So to overcome that issue, added the eslint comment for that line.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      activeTab: activeTab,
      setActiveTab: setActiveTab,
      tabs: tabs,
    }),
    [activeTab, setActiveTab, tabs]
  );

  return (
    activeTab && (
      <DashboardContextProvider
        ComponentContext={DashboardContext}
        value={value}
      >
        <div className="learner-dashboard">{children}</div>
      </DashboardContextProvider>
    )
  );
};

RenderDashboardTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RenderDashboardTabs.Header = TabHeader;
RenderDashboardTabs.TabContent = TabContent;

export default RenderDashboardTabs;

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactFlowPlayerWrapper from './ReactFlowPlayerWrapper';
import { SelectableGallery } from './SelectableGallery';
import Modal from './modal';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import FormRow from '../common/presentational/formRow';
import TextInput from '../common/inputs/text';
import DynamicMultiSelect from '../common/inputs/dynamicMultiSelect';
import SubmitButton from '../common/presentational/submitButton';
import GalleryCard from './GalleryCard';
import SortableGallery from './SortableGallery';
import { alertErrorNotifications, alertSuccessNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModelOpen: false,
      isAddToLibModal: false,
      showAllAssets: false,
      libraryItemInfo: {
        selectedTagNames: [],
      },
      currentLibModalLinks: {},
      file: {
        fileId: null,
        fileSrc: '',
        fileType: '',
        fileName: '',
        filePoster: '',
      },
      loading: false,
    };
  }

  openModal = (src, type, id = null, poster = '') => {
    const { file } = this.state;
    this.setState({
      isModelOpen: true,
      file: {
        ...file,
        fileId: id,
        fileSrc: src,
        fileType: type,
        filePoster: poster,
      },
    });
  };

  closeModal = () => {
    this.setState({
      isModelOpen: false,
      isAddToLibModal: false,
      file: {
        fileId: null,
        fileSrc: '',
        fileType: '',
        fileName: '',
        filePoster: '',
      },
    });
  };

  libraryViewEnabled = () => {
    const { libraryViewConfig } = this.props;

    return !_.isNull(libraryViewConfig);
  };

  handleCustomMultiSelectChange = (values, type) => {
    const { libraryItemInfo } = this.state;
    let newLibraryItemInfo = {
      ...libraryItemInfo,
      [type]: values,
    };
    this.setState({
      libraryItemInfo: newLibraryItemInfo,
    });
  };

  getLibraryModalFormInfo = attribute => {
    const { libraryItemInfo } = this.state;
    return libraryItemInfo[attribute];
  };

  handleLibraryModalChange = (attribute, value) => {
    const { libraryItemInfo } = this.state;
    let newLibraryItemInfo = {
      ...libraryItemInfo,
      [attribute]: value,
    };
    this.setState({
      libraryItemInfo: newLibraryItemInfo,
    });
  };

  handleLibraryModalFormSubmit = async event => {
    event.preventDefault();

    const { csrfToken, libraryViewConfig } = this.props;
    const { libraryItemInfo, currentLibModalLinks } = this.state;
    const postableData = {
      tag_names: _.map(libraryItemInfo['selectedTagNames'], 'value'),
      title: libraryItemInfo['title'],
    };
    // Dirty Fix: links are receiving with snackeCase in some views and camelCase in some other.
    const linkToManageLibraryItem =
      currentLibModalLinks['manageLibraryItem'] ||
      currentLibModalLinks['manage_library_item'];

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .post(linkToManageLibraryItem)
      .setPostData(postableData)
      .onSuccess(_response => {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.closeModal();
          }
        );
        alertSuccessNotifications('Successfully updated library data');
      })
      .onError(err => {
        this.setState({
          loading: false,
        });
        alertErrorNotifications(err || 'Cannot Submit, Something is wrong!');
      })
      .exec();

    const libraryDataUpdateCb = _.get(
      libraryViewConfig,
      'libraryDataUpdateCallback',
      () => {}
    );
    libraryDataUpdateCb();
  };

  renderModalContent = () => {
    const {
      file: { fileType, fileSrc, filePoster },
    } = this.state;

    switch (fileType) {
      case 'image':
        return <img src={fileSrc} className="w-100" />;
      case 'video':
        return (
          <ReactFlowPlayerWrapper
            poster={filePoster}
            playerId="reactFlowPlayer"
            sources={[
              {
                type: 'video/mp4',
                src: fileSrc,
              },
            ]}
          />
        );
      default:
        return null;
    }
  };

  setTagNamesForOptions = () => {
    const { libraryItemInfo, assetTags } = this.state;
    const selectedTags = _.filter(assetTags, tag =>
      libraryItemInfo['tag_ids'].includes(tag.id)
    );
    const tagNames = _.map(selectedTags, 'name');
    this.setState({
      libraryItemInfo: {
        ...this.state.libraryItemInfo,
        selectedTagNames: _.map(tagNames, tagName => ({
          label: tagName,
          value: tagName,
        })),
      },
    });
  };

  fetchLibraryItemInfo = async link => {
    if (!!link) {
      const { csrfToken } = this.props;
      await new Http(this)
        .setLoading()
        .setToken(csrfToken)
        .get(link)
        .onSuccess(res => {
          this.setState(
            {
              libraryItemInfo: res.data['asset_library_info'] || {},
              assetTags: res.data['asset_tags'] || [],
              isModelOpen: true,
              loading: false,
            },
            () => this.setTagNamesForOptions()
          );
        })
        .onError(err => {
          this.setState({
            loading: false,
          });
          alertErrorNotifications(err || 'Not able to fetch Asset Library Item Info');
        })
        .exec();
    }
  };

  renderAddToLibModalContent = () => {
    const {
      file: { fileName },
      libraryItemInfo,
      assetTags,
    } = this.state;

    return (
      <div className="p-1 mb-30">
        <h4 className="pb-20 text-center">{fileName}</h4>
        <form
          onSubmit={this.handleLibraryModalFormSubmit}
          className="kt-form kt-form--label-right kt-form--fit"
        >
          <FormRow
            label={'Title'}
            labelClassName={'col-md-4 text-right'}
            inputColClassName={'col-md-8'}
          >
            <TextInput
              name={'titleInput'}
              value={this.getLibraryModalFormInfo('title') || ''}
              className="form-control search-input--text"
              placeholder="Add title here"
              onInputChange={e => this.handleLibraryModalChange('title', e.target.value)}
              required={true}
            />
          </FormRow>
          <FormRow
            label={'Tags'}
            labelClassName={'col-md-4 text-right'}
            inputColClassName={'col-md-8'}
          >
            <DynamicMultiSelect
              name={'assetTags'}
              options={assetTags.map(({ name: tagName }) => ({
                label: tagName,
                value: tagName,
              }))}
              value={libraryItemInfo.selectedTagNames || []}
              closeMenuOnSelect={false}
              promptTextCreator={newTagName => `Create Tag "${newTagName}"`}
              onChange={values =>
                this.handleCustomMultiSelectChange(values, 'selectedTagNames')
              }
            />
          </FormRow>
          <div className="kt-form__section">
            <div className="col-lg-12 text-center">
              <span
                type="button"
                className="app-btn-secondary mr-15"
                onClick={this.closeModal}
              >
                Cancel
              </span>
              <SubmitButton
                disabled={
                  _.toString(this.getLibraryModalFormInfo('title')).trim().length === 0
                }
              />
            </div>
          </div>
        </form>
      </div>
    );
  };

  handleAddToLibrary = (filename, links, id) => {
    const currentAsset = _.find(this.props.assets, asset => asset.id === id);
    // Dirty Fix: links are receiving with snackeCase in some views and camelCase in some other.
    const linkToGetLibraryInfo =
      links['getLibraryItemInfo'] || links['get_library_item_info'];

    const { file } = this.state;

    this.fetchLibraryItemInfo(linkToGetLibraryInfo);
    this.setState({
      isAddToLibModal: true,
      file: {
        ...file,
        fileName: filename,
      },
      currentLibModalLinks: currentAsset.links,
    });
  };

  selectAsset = id => {
    const { selectedAssets } = this.props;

    let newSelectedAssets = [...selectedAssets];

    if (_.includes(selectedAssets, parseInt(id))) {
      newSelectedAssets = _.filter(selectedAssets, assetId => assetId !== id);
    } else {
      newSelectedAssets.push(id);
    }

    this.props.onAssetSelect(newSelectedAssets);
  };

  handleShowAllAssets = () => {
    this.setState({
      showAllAssets: true,
    });
  };

  getAssetsDetails = () => {
    const { assets } = this.props;
    const maxAssets = 3;
    let fewMoreAssets = 0;

    let assetsToRender = assets;

    if (!_.isEmpty(assets) && assets.length > maxAssets) {
      assetsToRender = _.slice(assets, 0, maxAssets);
      fewMoreAssets = assets.length - maxAssets;
    }
    return { assetsToRender, fewMoreAssets };
  };

  render() {
    const {
      assets,
      noAssetOptions,
      onAssetDelete,
      showAssetShareOption,
      assetShareEnablerPredicate,
      onAssetShareClick,
      selectableMode,
      selectedAssets,
      galleryClassName,
      hideFileInfo,
      showAssetDeleteOption,
      libraryViewConfig,
      showMoreAssetsCount,
      sortableGallery,
      onSortComplete,
      showAssetDownloadOption = true,
    } = this.props;

    const {
      isModelOpen,
      isAddToLibModal,
      loading,
      showAllAssets,
      file: { fileId },
    } = this.state;

    let assetsToRender = assets;
    let moreAssetsCount = 0;
    if (showMoreAssetsCount && !showAllAssets) {
      const assetDetails = this.getAssetsDetails();
      assetsToRender = assetDetails.assetsToRender;
      moreAssetsCount = assetDetails.fewMoreAssets;
    }

    return (
      <React.Fragment>
        {loading && <Spinner />}
        {selectableMode ? (
          <SelectableGallery
            assets={assets}
            selectedAssets={selectedAssets}
            openModal={this.openModal}
            onAssetSelect={this.selectAsset}
          />
        ) : null}

        {!selectableMode ? (
          !!sortableGallery ? (
            <SortableGallery
              assets={assetsToRender}
              onAssetDelete={onAssetDelete}
              openModal={this.openModal}
              onSortComplete={onSortComplete}
            />
          ) : (
            <div className={galleryClassName ? galleryClassName : 'd-flex flex-wrap'}>
              {_.map(assetsToRender, (asset, index) => (
                <div
                  className="m-1"
                  key={index}
                  style={{
                    margin: '10px 0',
                    minWidth: showMoreAssetsCount ? '200px' : '230px',
                  }}
                >
                  <GalleryCard
                    asset={asset}
                    noAssetOptions={noAssetOptions}
                    onAssetDelete={onAssetDelete}
                    showAssetShareOption={showAssetShareOption}
                    assetShareEnablerPredicate={assetShareEnablerPredicate}
                    onAssetShareClick={onAssetShareClick}
                    hideFileInfo={hideFileInfo}
                    showAssetDeleteOption={showAssetDeleteOption}
                    showAssetDownloadOption={showAssetDownloadOption}
                    handleAddToLibrary={this.handleAddToLibrary}
                    openModal={this.openModal}
                    libraryViewConfig={libraryViewConfig}
                  />
                </div>
              ))}
              {showMoreAssetsCount && !showAllAssets && moreAssetsCount > 0 && (
                <div
                  className=""
                  style={{ margin: '10px 0', minWidth: 200 }}
                >
                  <div
                    className="file-card"
                    style={{ height: '150px' }}
                    onClick={this.handleShowAllAssets}
                  >
                    <div
                      className="kt-file d-flex justify-content-center align-items-center font-600"
                      style={{
                        height: '150px',
                        fontSize: '2rem',
                        backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        cursor: 'pointer',
                      }}
                    >
                      <span>+&nbsp;{moreAssetsCount}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}

        {isModelOpen ? (
          <Modal
            title={isAddToLibModal ? 'Add to Library' : 'Asset Preview'}
            onClose={this.closeModal}
            renderContent={
              isAddToLibModal ? this.renderAddToLibModalContent : this.renderModalContent
            }
            renderFooter={() => {
              if (isAddToLibModal) {
                return null;
              }
              return this.libraryViewEnabled()
                ? libraryViewConfig.modalFooterRenderer(fileId)
                : null;
            }}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

Gallery.propTypes = {
  galleryClassName: PropTypes.string,
  assets: PropTypes.array.isRequired,
  selectableMode: PropTypes.bool,
  noAssetOptions: PropTypes.bool,
  showMoreAssetsCount: PropTypes.bool,
  libraryViewConfig: PropTypes.shape({
    assetFooterRenderer: PropTypes.func.isRequired,
    modalFooterRenderer: PropTypes.func.isRequired,
    libraryDataUpdateCallback: PropTypes.func.isRequired,
  }),
};

Gallery.defaultProps = {
  selectableMode: false,
  noAssetOptions: false,
  // Note: Default Value is used as null for ease of check in component logic
  libraryViewConfig: null,
  showMoreAssetsCount: false,
};

export default withErrorHandler(Gallery);

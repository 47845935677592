import React from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import AdminTabs from './AdminTab/index';

import CourseTab from './CoursesTab';
import CurriculumTab from './CurriculumTab';

import {
  COURSES_TAB,
  CURRICULUM_TAB,
} from './constants';

const CourseAdmin = ({
  csrfToken,
  tenantTerms,
  links,
}) => {
  const TABS = [
    { key: CURRICULUM_TAB, label: tenantTerms.termCurriculum.plural },
    { key: COURSES_TAB, label: tenantTerms.termCourse.plural },
  ];

  return (
    <AdminTabs tabs={TABS}>
      <AdminTabs.Header />
      <AdminTabs.RenderTabs />
      <AdminTabs.TabsBody>
        <CourseTab
          id={COURSES_TAB}
          csrfToken={csrfToken}
          tenantTerms={tenantTerms}
          fetchUrl={links.fetchCourses}
        />
        <CurriculumTab
          id={CURRICULUM_TAB}
          fetchUrl={links.fetchCurriculums}
          csrfToken={csrfToken}
          tenantTerms={tenantTerms}
        />
      </AdminTabs.TabsBody>
    </AdminTabs>
  );
};

CourseAdmin.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termCurriculum: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
    termCourse: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
    termCertification: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }).isRequired,
  links: PropTypes.shape({}).isRequired,
};

export default withErrorHandler(CourseAdmin);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetManager from '../../filestack/AssetManager';
import Spinner from '../../common/presentational/spinner';
import Http from '../../common/Http';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class UnassignedSubmissionGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allAssets: [],
      loading: false,
      showGallery: true,
      selectedAssets: [],
    };
  }

  closeModal = () => {
    this.setState(
      {
        showGallery: true,
        selectedAssets: [],
      },
      () => {
        if (
          this.state.selectedAssets.length === 0 &&
          !_.isNil(this.props.onSelectButtonClick)
        ) {
          this.props.onSelectButtonClick('close');
        }
      }
    );
  };

  fetchAllAssets = async () => {
    const { csrfToken, links } = this.props;

    const url = links.fetchUnassignedAssessmentSubmissions;

    const Request = await new Http(this)
      .setToken(csrfToken)
      .onBegin(() => {
        this.setState({
          loading: true,
          showGallery: false,
        });
      })
      .useAlerts()
      .get(url)
      .onSuccess(response => {
        this.setState({
          allAssets: response.data.unassignedSubmissions || [],
          loading: false,
        });
      })
      .exec();
  };

  submitAssets = async () => {
    const { selectedAssets } = this.state;
    const { csrfToken, courseAssessmentId, links } = this.props;

    this.setState({
      loading: true,
    });

    const url = links.assessmentLinkSubmissions;

    const Request = await new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .post(url, {
        course_assessment: {
          submission_ids: selectedAssets,
        },
      })
      .onSuccess(response => {
        window.location.href = window.location;
      })
      .exec();
  };

  handleAssetSelect = selectedAssets => {
    this.setState({
      selectedAssets: selectedAssets,
    });
  };

  handleSelectedAssetRemove = (assetId, handle) => {
    this.setState(
      prevS => ({
        selectedAssets: _.filter(prevS.selectedAssets, asset => asset !== assetId),
      }),
      () => {
        if (
          this.state.selectedAssets.length === 0 &&
          !_.isNil(this.props.onSelectButtonClick)
        ) {
          this.props.onSelectButtonClick('close');
        }
      }
    );
  };

  render() {
    const {
      csrfToken,
      showUploader,
      allowAssetDelete,
      filestackConfig,
      existingAssets,
      tenantTerms: { termSubmission },
    } = this.props;
    const { selectedAssets, allAssets: assets, loading, showGallery } = this.state;

    return (
      <div>
        {!_.isEmpty(existingAssets) && (
          <AssetManager
            label={''}
            filestackOptions={filestackConfig}
            showUploader={false}
            showGallery={true}
            assets={[]}
            existingAssets={existingAssets}
            csrfToken={csrfToken}
            showFileDeleteOption={allowAssetDelete}
          />
        )}
        <br />
        {showGallery && (
          <React.Fragment>
            <AssetManager
              showFileDeleteOption={allowAssetDelete}
              csrfToken={csrfToken}
              showUploader={false}
              showFileShareOption={false}
              showGallery={true}
              onFileChange={() => {}}
              handleGalleryItemDelete={this.handleSelectedAssetRemove}
              existingAssets={_.filter(
                assets,
                asset => _.indexOf(selectedAssets, asset.id) > -1
              )}
              selectedAssets={[]}
            />
            <div
              className="kt-separator"
              style={{
                height: 1,
                border: '1px dashed #eee',
                marginBottom: 20,
              }}
            />
          </React.Fragment>
        )}
        <div className="form-group kt-form__group row">
          <label className="col-md-2 col-form-label" />
          <div className="col-md-10">
            <div className="form-group kt-form__group">
              <div className="col-md-4 offset-md-2">
                {loading && <Spinner />}
                {!loading && showUploader && (
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#assetsModal"
                    className="app-btn-primary btn-wide btn-block font-18 font-weight-bold my-3"
                    onClick={() => {
                      this.fetchAllAssets();
                    }}
                  >
                    {termSubmission.singular} Library
                  </button>
                )}
                {selectedAssets.length > 0 && showGallery && (
                  <React.Fragment>
                    {!_.isNil(this.props.onSelectButtonClick) && (
                      <button
                        className="btn btn-darkred btn-wide btn-block"
                        onClick={() => {
                          this.setState(
                            {
                              selectedAssets: [],
                              showGallery: false,
                            },
                            () => this.props.onSelectButtonClick('close')
                          );
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-success btn-wide btn-block"
                      onClick={this.submitAssets}
                    >
                      Submit
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {showUploader && (
          <div
            className="modal fade"
            id="assetsModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="assetsModalTitle"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="assetsModalTitle">
                    Select {termSubmission.plural}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={this.closeModal}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {loading && <p>Loading {termSubmission.plural}…</p>}
                  {loading === false && assets.length === 0 && (
                    <h5 className="text-center">
                      No {termSubmission.plural.toLowerCase()} found.
                    </h5>
                  )}
                  {assets.length > 0 && (
                    <div
                      data-scrollbar-shown="true"
                      data-scrollable="true"
                      data-height="300"
                      style={{
                        height: 400,
                        overflowY: 'auto',
                      }}
                    >
                      <AssetManager
                        csrfToken={csrfToken}
                        showUploader={false}
                        selectableMode={true}
                        onFileChange={() => {}}
                        existingAssets={assets}
                        selectedAssets={selectedAssets}
                        onAssetSelect={this.handleAssetSelect}
                      />
                    </div>
                  )}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="app-btn-secondary"
                    data-dismiss="modal"
                    onClick={this.closeModal}
                  >
                    Close
                  </button>
                  {assets.length > 0 && (
                    <button
                      disabled={selectedAssets.length === 0}
                      type="button"
                      data-dismiss="modal"
                      className="app-btn-primary"
                      onClick={() =>
                        this.setState(
                          {
                            showGallery: true,
                          },
                          () => {
                            if (this.state.selectedAssets.length > 0) {
                              this.props.onSelectButtonClick('open');
                            }
                          }
                        )
                      }
                    >
                      Select Assets
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

UnassignedSubmissionGallery.defaultProps = {
  filestackConfig: {},
  existingAssets: [],
};

UnassignedSubmissionGallery.propTypes = {
  onSelectButtonClick: PropTypes.func.isRequired,
  courseAssessmentId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  showUploader: PropTypes.bool.isRequired,
  allowAssetDelete: PropTypes.bool.isRequired,
  existingAssets: PropTypes.array,
  filestackConfig: PropTypes.object,

  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    fetchUnassignedAssessmentSubmissions: PropTypes.string.isRequired,
    assessmentLinkSubmissions: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(UnassignedSubmissionGallery);

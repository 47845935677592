import React from 'react';
import PropTypes from 'prop-types';
import InitialsAvatar from '.';

const UserIconsGroup = ({ users = [], totalUsersCount = 0, maxUsersToDisplay }) => {
  let moreUsersCount = totalUsersCount ? totalUsersCount : users.length;
  if (maxUsersToDisplay > 0) {
    moreUsersCount = moreUsersCount - maxUsersToDisplay;
  }

  return (
    <span className="d-flex">
      {maxUsersToDisplay > 0 &&
        !_.isEmpty(users) &&
        _.take(users, maxUsersToDisplay).map((user, idx) => (
          <span key={`${user.id}-${user.email}-${idx}`}>
            <InitialsAvatar url={user.url || user.avatar} name={user.name} size="small" />
          </span>
        ))}
      {moreUsersCount > 0 && (
        <span className="more_avatars avatar" title={`and ${moreUsersCount} more`}>
          <span className="initials">+{moreUsersCount}</span>
        </span>
      )}
    </span>
  );
};

UserIconsGroup.propTypes = {
  users: PropTypes.array.isRequired,
  maxUsersToDisplay: PropTypes.number,
};

UserIconsGroup.defaultProps = {
  maxUsersToDisplay: 4,
};

export default UserIconsGroup;

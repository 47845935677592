import React from 'react';
import PropTypes from 'prop-types';
import ButtonsInColumn from './ButtonsInColumn';

const RadioButton = ({ children }) => {
  return <div>{children}</div>;
};

RadioButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

RadioButton.InColumn = ButtonsInColumn;
RadioButton.ButtonWrapper = ButtonsInColumn.ButtonWrapper;

export default RadioButton;

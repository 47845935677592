import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import isNil from 'lodash/isNil';

import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import SelectInput from '../common/inputs/select';
import ImpressionsOverview from './ImpressionsOverview';
import ImpressionEmoji from '../common/ImpressionEmoji';
import SentimentSelect from '../common/inputs/SentimentSelect';
import { SkeletonRow, SkeletonWrap } from '../SkeletonLoading/index';
import {
  InfiniteLoader,
  WindowScroller,
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
} from 'react-virtualized';
import {
  convertArrayOfObjectsToHash,
  formatDateTime,
  PERIOD_FILTER_OPTIONS,
} from '../common/utils';
import { alertErrorNotifications } from '../folders/utils';
import 'react-virtualized/styles.css';
import ProjectSelect from '../common/inputs/ProjectSelect';
import { userResponsesIndexTableReducer } from './formsReducers';
import { constructUserResponses } from './utils';

const getInitialState = props => {
  return {
    filterOptions: {
      selectedForms: [],
      impression: null,
      selectedProjects: null,
      selectedProjectCategories: null,
      selectedResponsePeriod: null,
    },
    userResponses: constructUserResponses(props.userResponses),
    projectsHash: props.featureFlags.projectsEnabled
      ? convertArrayOfObjectsToHash(props.projects, 'id')
      : {},
  };
};

const UserResponsesIndexTable = props => {
  const initialState = getInitialState(props);
  const [indexTableState, dispatchTableActions] = useReducer(
    userResponsesIndexTableReducer,
    initialState
  );
  const [loading, setLoading] = useState(false);

  const {
    showImpressionsOverview,
    featureFlags: { projectsEnabled, isSingleProjectTenant },
    responsesLoading,
    tenantTerms: { termProject },
    pagingMeta: { hasNextPage },
  } = props;
  const { userResponses, filterOptions, projectsHash } = indexTableState;

  const userResponsesCount = size(userResponses);
  const responsesCountOffset = hasNextPage ? 30 : 0;

  useEffect(() => {
    dispatchTableActions({
      type: 'updateUserResponse',
      payload: props.userResponses,
    });
  }, [props.userResponses]);

  useEffect(() => {
    const {
      projects: projectsProps,
      featureFlags: { projectsEnabled },
    } = props;
    dispatchTableActions({
      type: 'updateProjectsHash',
      payload: {
        projectsHash: projectsEnabled
          ? convertArrayOfObjectsToHash(projectsProps, 'id')
          : {},
      },
    });

    // the effect only triggered when the projects from props is updated instead of checking every props update.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projects]);

  const handleOptionSelect = attributeName => value => {
    let optionValue = value;
    if (isEqual('impression', attributeName)) {
      optionValue = value.value;
    }
    if (isEqual('selectedResponsePeriod', attributeName)) {
      optionValue = value.key;
    }
    dispatchTableActions({
      type: 'handleFilterOptions',
      payload: { [attributeName]: optionValue },
    });
  };

  const handleDeleteRequest = url => async () => {
    const proceed = confirm('Are you sure, you want to delete?');
    if (proceed) {
      await new Http()
        .onBegin(() => {
          setLoading(true);
        })
        .useAlerts(true)
        .setToken(props.csrfToken)
        .doesRedirect(true)
        .delete(url)
        .onSuccess(() => {
          setLoading(false);
          window.location = window.location.href;
        })
        .onError(err => {
          setLoading(false);
          alertErrorNotifications(err || 'Cannot Delete at this moment!');
        })
        .exec();
    }
  };

  const renderFilterHeaderRow = () => {
    const {
      featureFlags: {
        projectsEnabled,
        projectCategoriesEnabled,
        isSingleProjectTenant,
      },
      forms,
      projects,
      projectCategories,
      getUserResponses,
      tenantTerms: { termProject },
      metaDataLoading,
      responsesLoading,
    } = props;
    const { filterOptions, loading } = indexTableState;
    const {
      selectedForms,
      impression,
      selectedProjects,
      selectedProjectCategories,
      selectedResponsePeriod,
    } = filterOptions;

    const disableFiltering = loading || metaDataLoading || responsesLoading;

    return (
      <React.Fragment>
        <div
          className="d-flex justify-content-end"
          style={{ paddingRight: '40px' }}
        >
          <button
            className="app-btn-outline-primary"
            style={{ width: 'max-content' }}
            type="button"
            data-toggle="collapse"
            data-target="#userResponseFilterCollapse"
            aria-expanded="false"
            aria-controls="userResponseFilterCollapse"
          >
            <i className="fas fa-filter"></i> Filter
          </button>
        </div>
        <div
          className="collapse multi-collapse"
          id="userResponseFilterCollapse"
        >
          <div className="kt-portlet m-4">
            <div className="row ml-15 mr-15 mt-15 pt-20 mb-4 justify-content-center">
              <div className="col-md-4 p-2">
                <MultiSelect
                  placeholder={
                    metaDataLoading ? 'Loading Forms...' : 'Select Forms'
                  }
                  options={forms}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  optionIdentifier="id"
                  value={selectedForms || []}
                  onChange={handleOptionSelect('selectedForms')}
                  isDisabled={disableFiltering}
                />
              </div>
              <div className="col-md-4 p-2">
                <SentimentSelect
                  placeholder={'Select an Impression'}
                  value={impression || ''}
                  onChange={handleOptionSelect('impression')}
                  isDisabled={disableFiltering}
                />
              </div>
              <div className="col-md-4 p-2">
                <SelectInput
                  placeholder={'Response Period'}
                  name={'selectedResponsePeriod'}
                  isSearchable={false}
                  getOptionValue={option => option.key}
                  optionIdentifier="key"
                  options={PERIOD_FILTER_OPTIONS}
                  value={selectedResponsePeriod || ''}
                  onChange={handleOptionSelect('selectedResponsePeriod')}
                  isDisabled={disableFiltering}
                />
              </div>
              <React.Fragment>
                {!isSingleProjectTenant && projectsEnabled && (
                  <div className="col-md-4 p-2">
                    <ProjectSelect
                      isMulti
                      placeholder={`${metaDataLoading ? 'Loading' : 'Select'} ${
                        termProject.plural
                      }`}
                      options={projects}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={selectedProjects || []}
                      onChange={handleOptionSelect('selectedProjects')}
                      isDisabled={disableFiltering}
                      noOptionsMessage={() => `No ${termProject.plural}`}
                      closeMenuOnSelect={false}
                    />
                  </div>
                )}
                {!isSingleProjectTenant && projectCategoriesEnabled && (
                  <div className="col-md-4 p-2">
                    <MultiSelect
                      placeholder={`${metaDataLoading ? 'Loading' : 'Select'} ${
                        termProject.singular
                      } Categories`}
                      options={projectCategories}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      optionIdentifier="id"
                      value={selectedProjectCategories || []}
                      onChange={handleOptionSelect('selectedProjectCategories')}
                      isDisabled={disableFiltering}
                    />
                  </div>
                )}
              </React.Fragment>
              <div className="col-md-12 d-flex justify-content-center px-1 py-2">
                <button
                  onClick={() =>
                    getUserResponses({ filterOptions, isFilterSubmit: true })
                  }
                  className="app-btn-primary"
                  disabled={disableFiltering}
                >
                  Filter Responses
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const getAssignedProjectName = projectId => {
    const assignedProject = projectsHash[projectId];

    return !isNil(assignedProject) ? assignedProject.name : '-';
  };

  const loadMoreRows = () => {
    const {
      getUserResponses,
      pagingMeta: { startIndex, rowsLoading },
    } = props;
    const { filterOptions } = indexTableState;
    // Do not initiate another request when one is in progress
    if (rowsLoading || !(startIndex > 0)) return;

    getUserResponses({ filterOptions, isInfiniteLoader: true });
  };
  // Every row is loaded except for our loading indicator row.
  const isRowLoaded = ({ index }) => {
    const {
      userResponses,
      pagingMeta: { hasNextPage },
    } = props;

    return !hasNextPage || index < size(userResponses);
  };

  // Render a list item or a loading indicator.
  const tableRowRenderer = ({ index, style, ...restProps }) => {
    const customRowStyle = {
      ...style,
      backgroundColor: index % 2 === 0 ? '#f7f8fa' : '#fff',
    };

    if (!isRowLoaded({ index })) {
      return (
        <div style={customRowStyle} key={index}>
          <div className="w-100">
            <SkeletonWrap>
              <SkeletonRow />
            </SkeletonWrap>
          </div>
        </div>
      );
    }

    return defaultTableRowRenderer({
      style: customRowStyle,
      ...restProps,
    });
  };

  const renderOptions = item => {
    const {
      featureFlags: { showDeleteOption },
      tenantTerms: { termTicket },
    } = props;
    return (
      <div className="dropleft">
        <button
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          className="app-btn-hover-secondary btn-circle btn-icon"
        >
          <i className="la la-ellipsis-h" />
        </button>
        <div
          className="dropdown-menu"
          x-placement="left-start"
          style={{
            position: 'absolute',
            willChange: 'transform',
            top: 0,
            left: 0,
            transform: 'translate3d(-2px, 0px, 0px)',
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={item.links['show']}
            className="dropdown-item"
          >
            <span className="kt-nav__link-text">View</span>
          </a>

          {isEmpty(item['ticket']) ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.links['newUserResponseTicket']}
              className="dropdown-item"
            >
              <span className="kt-nav__link-text">{`Create ${termTicket.singular}`}</span>
            </a>
          ) : (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={item.ticket.links['show']}
              className="dropdown-item"
            >
              <span className="kt-nav__link-text">{`View ${termTicket.singular}`}</span>
            </a>
          )}

          {showDeleteOption && (
            <button
              onClick={handleDeleteRequest(item.links['delete'])}
              className="dropdown-item text-darkred"
            >
              <span className="kt-nav__link-text">Delete</span>
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderLoadingScreen = () => (
    <React.Fragment>
      <SkeletonWrap>
        <SkeletonRow header />
        <br />
        <SkeletonRow rows={8} />
      </SkeletonWrap>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {renderFilterHeaderRow()}
      {showImpressionsOverview && (
        <div
          style={{
            visibility: `${
              isEmpty(filterOptions['impression']) ? 'visible' : 'hidden'
            }`,
          }}
        >
          <ImpressionsOverview userResponses={userResponses} />
        </div>
      )}
      {loading || responsesLoading ? (
        renderLoadingScreen()
      ) : (
        <div className="kt-section reviews-table virtualized-table-overflow__visible">
          <div
            className="kt-section__content"
            style={{ overflowX: 'auto', minHeight: '300px' }}
          >
            <InfiniteLoader
              isRowLoaded={isRowLoaded}
              loadMoreRows={loadMoreRows}
              rowCount={userResponsesCount + responsesCountOffset}
            >
              {({ onRowsRendered, registerChild }) => (
                /* Removed the ref, since we didn't use anywhere in the component and unwanted ref initialization without usage. */
                <WindowScroller>
                  {({ height, isScrolling, onChildScroll, scrollTop }) => (
                    <AutoSizer disableHeight>
                      {({ width }) => (
                        <Table
                          ref={registerChild}
                          onRowsRendered={onRowsRendered}
                          headerHeight={60}
                          width={width < 950 ? 950 : width}
                          height={height}
                          autoHeight
                          rowCount={userResponsesCount + responsesCountOffset}
                          rowGetter={({ index }) => userResponses[index] || {}} // Ref: https://github.com/bvaughn/react-virtualized/issues/1012
                          rowHeight={50}
                          rowRenderer={tableRowRenderer}
                          isScrolling={isScrolling}
                          onScroll={onChildScroll}
                          overscanRowCount={20}
                          scrollTop={scrollTop}
                          noRowsRenderer={() => (
                            <h3
                              className="text-center p-3"
                              style={{ backgroundColor: '#f7f8fa' }}
                            >
                              No Responses
                            </h3>
                          )}
                        >
                          <Column
                            dataKey="impression"
                            disableSort
                            width={50}
                            flexShrink={0}
                            headerClassName="text-center"
                            className="text-center"
                            cellRenderer={({ cellData }) =>
                              !isEmpty(cellData) ? (
                                <ImpressionEmoji impression={cellData} />
                              ) : null
                            }
                          />
                          <Column
                            dataKey="links"
                            label="Form Name"
                            disableSort
                            width={200}
                            flexShrink={0}
                            flexGrow={1}
                            className="text-truncate"
                            cellRenderer={({ rowData }) =>
                              !isEmpty(rowData) && (
                                <a href={rowData.links.show}>
                                  {rowData.formName}
                                </a>
                              )
                            }
                          />
                          <Column
                            dataKey="submittedBy"
                            label="Submitted By"
                            disableSort
                            width={200}
                            flexShrink={0}
                            flexGrow={1}
                            className="text-truncate"
                            cellRenderer={({ cellData }) => (
                              <span>{cellData}</span>
                            )}
                          />
                          {!isSingleProjectTenant && projectsEnabled && (
                            <Column
                              dataKey="assignedProjectId"
                              label={termProject.singular}
                              disableSort
                              width={200}
                              flexShrink={0}
                              flexGrow={1}
                              cellRenderer={({ cellData }) =>
                                getAssignedProjectName(cellData)
                              }
                            />
                          )}
                          <Column
                            dataKey="submittedAt"
                            label="Date Submitted"
                            disableSort
                            width={150}
                            flexShrink={0}
                            flexGrow={1}
                            headerClassName="text-center"
                            className="text-center"
                            cellRenderer={({ cellData }) => (
                              <span>
                                {formatDateTime({
                                  date: cellData,
                                  formatTime: false,
                                })}
                              </span>
                            )}
                          />
                          <Column
                            dataKey="id"
                            disableSort
                            width={120}
                            flexShrink={0}
                            cellRenderer={({ rowData }) =>
                              !isEmpty(rowData) && renderOptions(rowData)
                            }
                          />
                        </Table>
                      )}
                    </AutoSizer>
                  )}
                </WindowScroller>
              )}
            </InfiniteLoader>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

UserResponsesIndexTable.propTypes = {
  userResponses: PropTypes.array.isRequired,
  featureFlags: PropTypes.shape({
    projectsEnabled: PropTypes.bool.isRequired,
    projectCategoriesEnabled: PropTypes.bool.isRequired,
    showDeleteOption: PropTypes.bool.isRequired,
    isSingleProjectTenant: PropTypes.bool.isRequired,
  }).isRequired,
  forms: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  projectCategories: PropTypes.array.isRequired,
  showImpressionsOverview: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termTicket: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  csrfToken: PropTypes.string,
  getUserResponses: PropTypes.func,
  metaDataLoading: PropTypes.bool,
  responsesLoading: PropTypes.bool,
  pagingMeta: PropTypes.shape({
    startIndex: PropTypes.number,
    rowsLoading: PropTypes.bool,
    hasNextPage: PropTypes.bool,
  }),
};

UserResponsesIndexTable.defaultProps = {
  showImpressionsOverview: false,
};

export default UserResponsesIndexTable;

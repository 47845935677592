import React from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import MemberCertificationsGridItem from './MemberCertificationsGridItem';

const MemberCertificationsGrid = ({
  memberCertifications,
  links,
  tenantTerms,
  isTenantAdmin,
}) => {
  const { termCertification } = tenantTerms;
  return (
    <React.Fragment>
      <div className="kt-subheader m-2">
        <div className="d-flex justify-content-between flex-wrap w-100">
          <h3 className="kt-subheader__title ">
            My {termCertification.plural}
          </h3>
          {isTenantAdmin && !isNil(links.newCertification) && (
            <a
              href={links.newCertification}
              className="btn kt-subheader__btn-primary mr-2"
            >
              Add {termCertification.singular}
            </a>
          )}
        </div>
      </div>
      <div className="p-5">
        <div className="kt-content">
          <div className="row">
            {map(memberCertifications, certification => (
              <div
                className="col-xl-3 col-md-4 col-sm-6"
                key={certification.id}
              >
                <MemberCertificationsGridItem
                  certification={certification}
                  isTenantAdmin={isTenantAdmin}
                />
              </div>
            ))}

            {isEmpty(memberCertifications) && (
              <div className={'col-md-12'}>
                <div className="kt-portlet kt-portlet--fit">
                  <div className="kt-portlet__body" style={{ padding: '15px' }}>
                    <h4 className="text-center">
                      No {termCertification.plural}
                    </h4>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

MemberCertificationsGrid.propTypes = {
  memberCertifications: PropTypes.array.isRequired,
  links: PropTypes.shape({
    newCertification: PropTypes.string.isRequired,
  }).isRequired,
  isTenantAdmin: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termCertification: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

MemberCertificationsGrid.defaultProps = {
  isTenantAdmin: false,
};

export default withErrorHandler(MemberCertificationsGrid);

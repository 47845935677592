import map from 'lodash/map';
import keys from 'lodash/keys';
import values from 'lodash/values';
import max from 'lodash/max';

export const getSentimentalColor = sentiment => {
  switch (sentiment) {
    case 'Positive':
      return 'success';
    case 'Negative':
      return 'danger';
    case 'Neutral':
      return 'warning';
    default:
      return;
  }
};

export const setDefaultChartTypeByElement = element => {
  let defaultChartType;
  switch (element) {
    case 'Dropdown':
    case 'RadioButtons':
    case 'Rating':
    case 'Range':
    case 'Signature':
      defaultChartType = 'bar';
      break;
    case 'FormToggleBox':
      defaultChartType = 'column';
      break;
    case 'Checkboxes':
    case 'Tags':
    default:
      defaultChartType = 'pie';
      break;
  }
  return defaultChartType;
};

export const getChartData = props => {
  const {
    summaryItem: { meta, responses, element },
  } = props;
  let responseKeys = keys(responses);

  if (element === 'Rating') {
    responseKeys = responseKeys.sort();
  }

  let formattedResponses = [];

  if (element === 'Signature') {
    formattedResponses = [
      ['Signed', meta.answered],
      ['Not Signed', meta.skipped],
    ];
  } else {
    responseKeys.map(key => {
      return (formattedResponses = [
        ...formattedResponses,
        [key, responses[key]],
      ]);
    });
  }
  const maxCountValue = max(values(responses));
  return { formattedResponses, maxCountValue };
};

export const getSentimentChartData = props => {
  const {
    summaryItem: { meta },
  } = props;
  let sentiments = values(meta.sentiments);
  let sentimentCounter = sentiments.reduce((acc, currentSentiment) => {
    acc[currentSentiment] = (acc[currentSentiment] || 0) + 1;
    return acc;
  }, {});

  let formattedResponses = [];

  sentimentCounter = {
    Positive: sentimentCounter['Positive'] || 0,
    Negative: sentimentCounter['Negative'] || 0,
    Neutral: sentimentCounter['Neutral'] || 0,
  };

  map(sentimentCounter, (count, sentiment) => {
    return (formattedResponses = [...formattedResponses, [sentiment, count]]);
  });
  const maxCountValue = max(values(sentimentCounter));

  return { formattedResponses, maxCountValue };
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withErrorHandler } from '../hoc/withErrorHandler';
import FeedbackFormTable from './FeedbackFormTable';

const TABS = [
  { key: 'published', label: 'Published' },
  { key: 'unPublished', label: 'Unpublished' },
];

const FeedbackFormsIndex = ({ feedbackForms: allForms, ...otherProps }) => {
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const [feedbackForms, setFeedbackForms] = useState([]);
  const [initialLoader, setInitialLoader] = useState(true);

  useEffect(() => {
    let publishedForms = [],
      unPublishedForms = [];
    allForms.map(form =>
      form.published ? publishedForms.push(form) : unPublishedForms.push(form)
    );
    setFeedbackForms({
      [TABS[0].key]: publishedForms,
      [TABS[1].key]: unPublishedForms,
    });
    setInitialLoader(false);
  }, [allForms]);

  const renderTabs = () => {
    return (
      <ul className="nav nav-tabs justify-content-center m" role="tablist">
        {TABS.map(tab => (
          <li className="nav-item" key={tab.key}>
            <a
              className={`nav-link${
                activeTab.key === tab.key ? ' active' : ''
              }`}
              onClick={() => setActiveTab(tab)}
            >
              <strong>{tab.label}</strong>
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="new-theme-tabs-container">
      <h4 className="text-center app-text-primary mt-3">
        <span className="text-capitalize">{activeTab.label}</span> Forms
      </h4>
      <div className="new-theme-tabs new-theme-tabs__reset my-3">
        {renderTabs()}
      </div>
      {initialLoader ? (
        <h3 className="text-center mt-5 text-black-50">
          <div className="spinner-grow app-text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>{' '}
          Loading...
        </h3>
      ) : (
        <div className="tab-content">
          {activeTab.key === TABS[0].key && (
            <FeedbackFormTable
              feedbackForms={feedbackForms[TABS[0].key]}
              showDraftState
              {...otherProps}
            />
          )}
          {activeTab.key === TABS[1].key && (
            <FeedbackFormTable
              feedbackForms={feedbackForms[TABS[1].key]}
              showDraftState={false}
              {...otherProps}
            />
          )}
        </div>
      )}
    </div>
  );
};

FeedbackFormsIndex.propTypes = {
  feedbackForms: PropTypes.array,
};

export default withErrorHandler(FeedbackFormsIndex);

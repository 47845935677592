import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Asset from './Asset';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '15px',
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#ffffff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#f4f5f8',
  display: 'flex',
  padding: '2rem 1rem',
  marginBottom: '20px',
  marginTop: '20px',
  overflow: 'auto',
  minHeight: '200px',
});

class SortableGallery extends Component {
  constructor(props) {
    super(props);
  }

  onDragEnd = result => {
    let { assets } = this.props;

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedAssets = reorder(
      [...assets],
      result.source.index,
      result.destination.index
    );

    this.props.onSortComplete(sortedAssets);
  };

  openModal = (event, url, assetType, id, poster) => {
    event.preventDefault();
    this.props.openModal(url, assetType, id, poster);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    let { assets, onAssetDelete, openModal, showAssetDeleteOption } = this.props;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        {!_.isEmpty(assets) && (
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {provided.placeholder}
                <div className="kt-widget4" style={{ width: '100%', padding: '0px' }}>
                  {_.map(
                    assets,
                    (
                      { id, filename, size, handle, mimetype, url, poster, links },
                      index
                    ) => (
                      <Draggable key={handle} draggableId={handle.toString()} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <div className="kt-widget4__item">
                              <div className="kt-widget4__pic kt-widget4__pic--icon">
                                <Asset
                                  id={id}
                                  mimetype={mimetype}
                                  url={url}
                                  poster={poster}
                                  openModal={openModal}
                                />
                              </div>
                              <div className="kt-widget4__title">{filename}</div>

                              <div className="kt-widget4__info">
                                <span className="kt-widget4__text">
                                  {parseFloat(size / (1024 * 1024)).toFixed(3)} MB
                                </span>
                              </div>

                              <div className="kt-widget4__tools">
                                <div className="btn-group dropleft">
                                  <button
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    type="button"
                                    className="btn-sm app-btn-outline-primary dropdown-toggle"
                                  >
                                    Options
                                  </button>
                                  <div className="dropdown-menu" x-placement="left-start">
                                    <button
                                      className="dropdown-item"
                                      onClick={event =>
                                        this.openModal(
                                          event,
                                          url,
                                          mimetype.split('/')[0],
                                          id,
                                          poster
                                        )
                                      }
                                    >
                                      View
                                    </button>
                                    {id && (
                                      <a className="dropdown-item" href={links.download}>
                                        Download
                                      </a>
                                    )}
                                    <div className=" dropdown-divider" />
                                    <button
                                      className="dropdown-item text-danger"
                                      onClick={() => onAssetDelete(id, handle)}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                </div>
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    );
  }
}

export default SortableGallery;

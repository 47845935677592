import React from 'react';
import PropTypes from 'prop-types';

import reduce from 'lodash/reduce';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import intersectionBy from 'lodash/intersectionBy';
import some from 'lodash/some';
import toString from 'lodash/toString';
import trim from 'lodash/trim';
import find from 'lodash/find';

import Spinner from '../common/presentational/spinner';
import AssetManager from '../filestack/AssetManager';
import MultiSelect from '../common/inputs/multiSelect';
import TextInput from '../common/inputs/text';

import Http from '../common/Http';

import './styles.scss';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

const LibraryManager = props => {
  const [loading, setLoading] = React.useState(false);
  const [assetTags, setAssetTags] = React.useState([]);
  const [libraryAssets, setLibraryAssets] = React.useState([]);
  const [selectedTagOptions, setSelectedTagOptions] = React.useState([]);
  const [searchInputTitle, setSearchInputTitle] = React.useState('');
  const [assetTagsMapper, setAssetTagsMapper] = React.useState({});

  const buildAssetTagsMapper = () => {
    const newAssetTagsMapper = reduce(
      assetTags,
      (mapperObj, assetTag) => {
        mapperObj[assetTag.id] = assetTag.name;
        return mapperObj;
      },
      {}
    );

    setAssetTagsMapper(newAssetTagsMapper);
  };

  const handleAssetTagsHydration = async () => {
    const {
      links: { fetchAssetTags: fetchAssetTagsLink },
      csrfToken,
    } = props;

    await new Http(this)
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(fetchAssetTagsLink)
      .onSuccess(res => {
        const response = res.data;
        setAssetTags(response['asset_tags']);
      })
      .onError(error =>
        alertErrorNotifications(error || 'Error while fetching tags')
      )
      .exec();
  };

  React.useEffect(() => {
    if (!isEmpty(assetTags)) {
      buildAssetTagsMapper();
    }

    // the effect only run on the update of assetTags state.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTags]);

  const handleLibraryAssetsHydration = async () => {
    const {
      links: { fetchLibraryAssets: fetchLibraryAssetsLink },
      csrfToken,
    } = props;

    await new Http(this)
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(fetchLibraryAssetsLink)
      .onSuccess(res => {
        const response = res.data;
        setLibraryAssets(response.libraryAssets);
      })
      .onError(error =>
        alertErrorNotifications(
          error || 'Error while fetching library attachments'
        )
      )
      .exec();
  };

  const handleLibraryStateHydration = async () => {
    try {
      // Note: Firing multiple requests simultaneously will crash dev server
      // Hence adding intentional delay
      await Promise.all([
        handleAssetTagsHydration(),
        setTimeout(handleLibraryAssetsHydration, 100),
      ]);
    } catch (e) {
      console.log(e);
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const triggerLibraryStateHydration = async () =>
      await handleLibraryStateHydration();

    triggerLibraryStateHydration();

    // the useEffect is triggered only on the component did mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTagFilterSelect = tagOptions => {
    setSelectedTagOptions(tagOptions);
  };

  const handleSearchTitleChange = event => {
    const { target } = event;
    setSearchInputTitle(target.value);
  };

  const getAssetTagOptions = () => {
    return map(assetTags, ({ id, name, taggedCount }) => ({
      label: `${name} (${taggedCount})`,
      value: id,
    }));
  };

  const refreshSelectedTagOptions = () => {
    const tagOptionsWithRefreshedTagCount = getAssetTagOptions();

    const newSelectedTagOptions = intersectionBy(
      tagOptionsWithRefreshedTagCount,
      selectedTagOptions,
      'value'
    );

    setSelectedTagOptions(newSelectedTagOptions);
  };

  const libraryDataUpdateCallback = async () => {
    try {
      await handleLibraryStateHydration();
      refreshSelectedTagOptions();
    } catch (_e) {
      alertErrorNotifications('Error while refreshing data');
    }
  };

  const filterAssetsByTags = (filterableAssets = []) => {
    const selectedTagIds = map(selectedTagOptions, 'value');

    const containsAnySelectedTagIds = asset =>
      some(asset.tagIds, assetTagId => includes(selectedTagIds, assetTagId));

    const filteredAssets = filter(filterableAssets, containsAnySelectedTagIds);

    return filteredAssets;
  };

  const filterAssetsByTitle = (filterableAssets = []) => {
    const filteredAssets = filter(filterableAssets, filterableAsset =>
      includes(toLower(filterableAsset.filename), toLower(searchInputTitle))
    );

    return filteredAssets;
  };

  const renderFilterOptions = () => {
    return (
      <div className="form-group kt-form__group row offset-lg-1 mt-20 mb-30">
        <div className="col-lg-5 col-md-6 pb-10">
          <MultiSelect
            placeholder={'Filter by Tags'}
            name={'tagFilter'}
            options={getAssetTagOptions()}
            value={selectedTagOptions}
            optionIdentifier="value"
            onChange={handleTagFilterSelect}
            closeMenuOnSelect={false}
          />
        </div>

        <div className="col-lg-5 col-md-6 pb-10">
          <TextInput
            name={'titleInput'}
            value={searchInputTitle}
            className="form-control search-input--text"
            placeholder="Search by Title"
            onInputChange={handleSearchTitleChange}
          />
        </div>
      </div>
    );
  };

  const userSelectedAnyTags = () => {
    return !isEmpty(selectedTagOptions);
  };

  const userEnteredSearchTitle = () => {
    return toString(trim(searchInputTitle)).length > 0;
  };

  const getAssetTagNames = asset => {
    const assetTagIds = asset.tagIds || [];
    const assetTagNames = map(
      assetTagIds,
      assetTagId => assetTagsMapper[assetTagId]
    );
    return assetTagNames;
  };

  const getTagNamesFromAssetId = assetId => {
    const asset = find(libraryAssets, { id: assetId });
    if (isNull(asset)) {
      return [];
    } else {
      const assetTagNames = getAssetTagNames(asset);
      return assetTagNames;
    }
  };

  const assetFooterRenderer = assetId => {
    const assetTagNames = getTagNamesFromAssetId(assetId);
    return (
      <div className="asset-tags-block text-truncate">
        <p className="m-b-5 text-muted f-10 break-word filename-truncate">
          <i className="fa fa-tags" />
          <span className="tags-list">
            {!isEmpty(assetTagNames) ? assetTagNames.join(', ') : 'No tags yet'}
          </span>
        </p>
      </div>
    );
  };

  const assetModalFooterRenderer = assetId => {
    const assetTagNames = getTagNamesFromAssetId(assetId);

    return (
      <div className="asset-tags-block text-right">
        <p className="m-b-5 text-muted font-12 break-word">
          <i className="fa fa-tags" />
          <span className="tags-list">
            {!isEmpty(assetTagNames) ? assetTagNames.join(', ') : 'No tags yet'}
          </span>
        </p>
      </div>
    );
  };

  const { csrfToken } = props;

  let filteredAssets = libraryAssets;

  if (userSelectedAnyTags()) filteredAssets = filterAssetsByTags(libraryAssets);

  if (userEnteredSearchTitle())
    filteredAssets = filterAssetsByTitle(filteredAssets);

  return (
    <div className="kt-content">
      <div className="row">
        <div className="col-lg-12">
          {loading && <Spinner />}

          <div className="p-4">
            <h3 className="mb-30 text-center">Library</h3>

            <div>
              {renderFilterOptions()}

              <div className="row" id="assets-wrapper">
                <div className="col-12">
                  <div className="asset-gallery">
                    <div className="kt-section" id="asset-gallery">
                      <div className="kt-section">
                        <div className="kt-section__content">
                          <AssetManager
                            showGallery
                            showUploader={false}
                            existingAssets={filteredAssets}
                            csrfToken={csrfToken}
                            showFileDeleteOption={false}
                            libraryViewConfig={{
                              assetFooterRenderer: assetFooterRenderer,
                              modalFooterRenderer: assetModalFooterRenderer,
                              libraryDataUpdateCallback:
                                libraryDataUpdateCallback,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LibraryManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.object.isRequired,
  links: PropTypes.shape({
    fetchLibraryAssets: PropTypes.string.isRequired,
    fetchAssetTags: PropTypes.string.isRequired,
  }).isRequired,
};

export default withErrorHandler(LibraryManager);

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import ActivityItem from './ActivityItem';
import { Sorted } from './index';

const ActivityTable = ({ activities, currentSortBy, handleSortBy }) => {
  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          <th
            className="kt-font-info cursor-pointer"
            onClick={handleSortBy(
              currentSortBy === Sorted.typeAsc
                ? Sorted.typeDesc
                : Sorted.typeAsc
            )}
            title="Sort by Type"
          >
            Type
            <span className="pull-right">
              {[Sorted.typeAsc, Sorted.typeDesc].includes(currentSortBy) &&
                (currentSortBy === Sorted.typeAsc ? ' ▲' : ' ▼')}
            </span>
          </th>
          <th>Owner</th>
          <th>Description</th>
          <th>Activity</th>
          <th
            className="kt-font-info cursor-pointer"
            onClick={handleSortBy(
              currentSortBy === Sorted.dateAsc
                ? Sorted.dateDesc
                : Sorted.dateAsc
            )}
            title="Sort by Date"
          >
            Date
            <span className="pull-right">
              {[Sorted.dateDesc, Sorted.dateAsc].includes(currentSortBy) &&
                (currentSortBy === Sorted.dateAsc ? ' ▲' : ' ▼')}
            </span>
          </th>
        </tr>
      </thead>
    );
  };

  return (
    <div className="table-responsive">
      <table className="table table-striped" style={{ minWidth: 750 }}>
        {renderTableHeader()}

        <tbody>
          {map(activities, activity => {
            return <ActivityItem {...activity} key={activity.id} />;
          })}
          {isEmpty(activities) && (
            <tr>
              <td colSpan={5} className="p-4 text-center">
                No Activities to show.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

ActivityTable.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.string.isRequired,
      owner: PropTypes.string.isRequired,
      description: PropTypes.string,
      activity: PropTypes.string,
      date: PropTypes.string,
      links: PropTypes.object,
    })
  ),
  currentSortBy: PropTypes.number,
  handleSortBy: PropTypes.func,
};

export default ActivityTable;

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

const MembersTable = ({
  handleOnDelete,
  handleRoleChange,
  userRoles,
  members,
  allRoles,
}) => {
  const memberRows = map(members, (member, index) => (
    <tr key={index}>
      <td>{member.name}</td>
      <td>
        {!isEmpty(allRoles) && (
          <select
            className="form-control"
            value={userRoles[`${member.id}`] || 1}
            onChange={event => handleRoleChange(member.id, event.target.value)}
          >
            {
              //make default value dynamic
              map(allRoles, ({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))
            }
          </select>
        )}
      </td>
      <td>
        <div
          className="btn-sm btn btn-darkred btn-pill"
          onClick={() => handleOnDelete(member.id)}
        >
          <span>
            <i className="la la-trash-o" />
            <span>Delete</span>
          </span>
        </div>
      </td>
    </tr>
  ));

  return (
    <div className="table-responsive" style={{ minHeight: 'auto' }}>
      <table className="table table-striped">
        <tbody>{memberRows}</tbody>
      </table>
    </div>
  );
};

MembersTable.propTypes = {
  handleOnDelete: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  allRoles: PropTypes.array.isRequired,
  userRoles: PropTypes.object.isRequired,
};

MembersTable.defaultProps = {
  allRoles: [],
};

export default MembersTable;

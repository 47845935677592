import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../common/inputs/Toggle';
import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import isEmpty from 'lodash/isEmpty';

import Text from '../common/inputs/text';
import AssetManager from '../filestack/AssetManager';
import FormRow from '../common/presentational/formRow';
import Spinner from '../common/presentational/spinner';
import {
  getSnakeCaseKeyedObject,
  removePageLeavePreventDialog,
  renderOptions,
  addPageLeavePreventDialog,
} from '../common/utils';
import SubmitButton from '../common/presentational/submitButton';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import CancelButton from '../common/presentational/cancelButton';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';
import SelectInput from '../common/inputs/select';

class CourseScormForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      attachments: [],
      existingAttachments: isEmpty(props.existingAttachment) ? [] : [props.existingAttachment],
      [props.fieldsPrefix]: Object.assign({}, props.attributes),
    };
  }

  componentDidMount() {
    addPageLeavePreventDialog();
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
  }

  setModelAttributeState = (fieldName, value) => {
    const updatedModelAttributes = {
      ...this.state[this.props.fieldsPrefix],
      [fieldName]: value,
    };

    this.setState({
      [this.props.fieldsPrefix]: updatedModelAttributes,
    });
  };

  onAttachmentChange = (attachments, existingAttachments) => {
    this.setState({
      attachments: attachments,
      existingAttachments: existingAttachments,
    });
  };

  getModelAttributeValue = attribute =>
    this.state[this.props.fieldsPrefix][attribute] || '';

  handleModelAttributeChange = (attributeName = '') => {
    return event => {
      const { target } = event;

      // Select Input Changed
      if (_.isNil(target)) {
        const targetValue = _.isArray(event)
          ? _.map(event, option => option.value)
          : event.value;

        this.setModelAttributeState(attributeName, targetValue);
      } else {
        const targetName = attributeName || target.name;
        const targetValue = target.type !== 'checkbox' ? target.value : target.checked;
        this.setModelAttributeState(targetName, targetValue);
      }
    };
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { courseScormId, csrfToken, links } = this.props;
    const { attachments } = this.state;

    const updatedModelAttributes = getSnakeCaseKeyedObject(
      this.state[this.props.fieldsPrefix]
    );

    const attachmentAttributes = getAssetAttributes(attachments)[0];

    let Requester = new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .useAlerts()
      .doesRedirect(true)
      .setPostData({
        [this.props.fieldsPrefix]: {
          ...updatedModelAttributes,
          attachment: attachmentAttributes,
        },
      })
      .onSuccess(response => {
        removePageLeavePreventDialog();
        window.location.href = _.get(
          response.data,
          'redirection_url',
          links.courseAdminContent
        );
      });

    if (_.isNil(courseScormId)) {
      Requester = Requester.post(links.createScorm);
    } else {
      Requester = Requester.patch(links.updateScorm);
    }

    await Requester.exec();
  };

  render() {
    const { loading, attachments, existingAttachments } = this.state;

    const { csrfToken, attachmentOptions, courseForumEnabled } = this.props;

    if (loading) {
      return (
        <div className={'kt-portlet__body'}>
          <Spinner />
        </div>
      );
    }

    return (
      <div className={'kt-portlet__body'}>
        <form
          encType="multipart/form-data"
          className="kt-form kt-form--label-right kt-form--fit"
          onSubmit={this.handleSubmit}
        >
          <div className="kt-section kt-section--first">
            <FormRow label={'Name'}>
              <Text
                name={'name'}
                value={this.getModelAttributeValue('name')}
                onInputChange={this.handleModelAttributeChange('name')}
              />
            </FormRow>

            {courseForumEnabled && (
              <FormRow label={'Discussion Enabled?'}>
                <Toggle
                  name={'discussionEnabled'}
                  onClick={value => this.setModelAttributeState('discussionEnabled', value)}
                  on={<span>Yes</span>}
                  off={<span>No</span>}
                  size="sm"
                  width={100}
                  height={30}
                  recalculateOnResize
                  offstyle="default"
                  active={this.getModelAttributeValue('discussionEnabled')}
                />
              </FormRow>
            )}
          </div>

          <div className="kt-seperator kt-seperator--dashed" />

          <div className="kt-section">
            <AssetManager
              label={'Attachment'}
              csrfToken={csrfToken}
              filestackOptions={attachmentOptions}
              existingAssets={existingAttachments}
              assets={attachments}
              onFileChange={this.onAttachmentChange}
              showAssetDownloadOption={false}
            />
          </div>

          <div className="kt-seperator kt-seperator--dashed" />
          <div className="kt-section">
            <div className="col-lg-12 text-center">
              <CancelButton confirmCancel={false} />
              <SubmitButton buttonText={'Save'} />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

CourseScormForm.defaultProps = {
  fieldsPrefix: 'course_scorm',
  attributes: {},
};

CourseScormForm.propTypes = {
  fieldsPrefix: PropTypes.string,

  csrfToken: PropTypes.string.isRequired,
  courseId: PropTypes.number.isRequired,
  courseScormId: PropTypes.number,

  attachmentOptions: PropTypes.object.isRequired,

  attributes: PropTypes.object.isRequired,
  existingAttachment: PropTypes.object.isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termSubmission: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termAssignment: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,

  links: PropTypes.shape({
    courseAdminContent: PropTypes.string.isRequired,
    createScorm: PropTypes.string.isRequired,
    updateScorm: PropTypes.string.isRequired,
    directUploadUrl: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(CourseScormForm);

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import map from 'lodash/map';

import CourseReport from '../../reports/CourseReport';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import StoreFilter from './storeFilter';

const UsersProgress = ({ csrfToken, course }) => {
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [selectedStores, setSelectedStores] = useState([]);
  const storeFilters = useMemo(() => omitBy({ store_ids: map(selectedStores, 'id') }, isEmpty), [selectedStores]);

  const fetchUserProgress = useCallback((filters) => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`/c/${course.slug}/reports.json`, {
        ...filters,
        apply_store_filter: true,
      })
      .onSuccess(response => {
        const { data } = response;
        setData(data);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
      })
      .exec();
  }, [course]);

  const applyFilters = useCallback(() => {
    fetchUserProgress(storeFilters);
  }, [storeFilters]);

  useEffect(() => {
    fetchUserProgress({});
  }, [fetchUserProgress]);

  return (
    <>
      {
        isEmpty(data.allCourseUsers) && selectedStores?.length < 1
          ? null
          :
            (
              <div className="row justify-content-center">
                <div className="col-md-5">
                  <StoreFilter onChange={setSelectedStores} />
                </div>
                <div className="col-md-2">
                  <button className='app-btn-primary w-full' onClick={applyFilters}>
                    Filter
                  </button>
                </div>
              </div>
            )
      }

      {
        isLoading
          ? <Spinner />
          :
            (
              <CourseReport
                {...data}
                filters={{ ...storeFilters, apply_store_filter: true }}
                renderWithTitle={false}
                loading={isLoading}
              />
            )
      }
    </>
  );
};

export default UsersProgress;

import map from 'lodash/map';

export const teamsFormReducer = (state, action) => {
  switch (action.type) {
    case 'onBegin': {
      return { ...state, loading: true };
    }
    case 'onError':
    case 'onSubmitSuccess': {
      return { ...state, loading: false };
    }
    case 'onGetUserDataSuccess': {
      const { response, userHasRoles } = action.payload;
      return {
        ...state,
        loading: false,
        name: response.name,
        selectedUsers: map(response.users, user => user.id),
        ...(userHasRoles && {
          userRoles: response.users_roles,
        }),
        memberList: response.users,
      };
    }
    case 'handleAttributeChange':
    case 'handleOnDelete':
    case 'handleUserSelect': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'handleUserRoleChange': {
      return {
        ...state,
        userRoles: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

import React, { useEffect, useState } from "react";

import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import CurriculumReport from "../../reports/CurriculumReport";
import { alertErrorNotifications } from "../../folders/utils";

const CurriculumProgress = ({ csrfToken, curriculum }) => {
  const [isLoading, setLoading] = useState(true);
  const [allUsersProgress, setAllUsersProgress] = useState([]);
  const [courses, setCourses] = useState([]);
  const [tenantTerms, setTenantTerms] = useState({});
  const [meta, setMeta] = useState({});

  const fetchReport = () => {
    if (!curriculum?.slug) {
      return;
    }

    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`/curriculums/${curriculum.slug}/reports.json`, {
        page: 0,
        per_page: 20,
      })
      .onSuccess(response => {
        const { data: { usersProgress, courses, tenantTerms, meta } } = response;
        setAllUsersProgress(usersProgress.map((a) => ({
          coursesProgress: a.coursesProgress,
          user: a.user,
        })));
        setCourses(courses);
        setTenantTerms(tenantTerms);
        setMeta(meta);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting reports!');
      })
      .exec();
  }

  useEffect(() => {
    fetchReport();
  }, [curriculum]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <CurriculumReport
      csrfToken={csrfToken}
      curriculumName={curriculum.name}
      links={{ curriculumReports: `/curriculums/${curriculum.slug}/reports.json` }}
      usersProgress={allUsersProgress}
      courses={courses}
      tenantTerms={tenantTerms}
      meta={meta}
      applyStoreFilter
      noHeader
    />
  );
};

export default CurriculumProgress;

import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { withErrorHandler } from '../../hoc/withErrorHandler';
import BackgroundGradient from '../../common/BackgroundGradient';
import CompletedCourse from './completed-course.png';
import './styles.scss';

const GridItem = ({ item, descriptionKey, showPublishStatus }) => {
  const { name, thumbnailImageUrl, headerImageUrl, links, isCompleted = false, pinned } = item;
  const itemDescription = item[descriptionKey];

  const cardBody = () => {
    const body = (
      <Fragment>
        <div className="learning-card__image">
          <BackgroundGradient
            imgUrl={thumbnailImageUrl || headerImageUrl || ''}
            text={name}
            objectFit="contain"
          />
          {
            isCompleted
              ? <img src={CompletedCourse} className='completed-course' />
              : null
          }
          {pinned && <div className="pin-indicator">
            <i className="fas fa-thumbtack"></i>
          </div>}
        </div>
        <div className="learning-card__details">
          <div className="row no-gutters">
            <h5 className="learning-card__name" title={name}>
              {name.slice(0, 59)}
              {name.length > 60 && '...'}
            </h5>
          </div>
          {!_.isNil(itemDescription) && (
            <p className="learning-card__description m-0">
              {_.unescape(itemDescription.slice(0, 160))}
              {itemDescription.length > 161 && '...'}
            </p>
          )}
        </div>
      </Fragment>
    );

    return showPublishStatus ? (
      <a href={links.show} className="card-wrapping-link">
        {body}
      </a>
    ) : (
      body
    );
  };

  const getBadgeColor = item?.published
    ? 'bg-success text-white'
    : 'bg-warning text-white';

  const card = (
    <div className="learning-card d-flex justify-content-between flex-column">
      {cardBody()}
      {showPublishStatus && (
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className={`badge ${getBadgeColor}`}>
              {item?.published ? 'Published' : 'Unpublished'}
            </div>
          </div>
          <div>
            <a href={links?.edit} className="app-btn-outline-secondary btn-sm">
              <i className="fas fa-cog mr-1"></i> Manage
            </a>
          </div>
        </div>
      )}
    </div>
  );

  return showPublishStatus ? (
    card
  ) : (
    <a href={links.show} className="card-wrapping-link">
      {card}
    </a>
  );
};

GridItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  descriptionKey: PropTypes.string,
  showPublishStatus: PropTypes.bool,
};

GridItem.defaultProps = {
  descriptionKey: 'description',
  showPublishStatus: false,
};

export default withErrorHandler(memo(GridItem));

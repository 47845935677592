import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../filestack/modal';
import TopicForm from './TopicForm';

const TopicModal = ({
  title,
  onModalClose,
  link,
  handleOnSuccess,
  topicData,
  csrfToken,
  topicAttachmentsConfig,
}) => {
  const renderModalBody = () => (
    <TopicForm
      csrfToken={csrfToken}
      onSuccess={handleOnSuccess}
      link={link}
      closeModal={onModalClose}
      data={topicData ? topicData : null}
      topicAttachmentsConfig={topicAttachmentsConfig}
    />
  );
  return (
    <>
      <Modal
        title={title}
        onClose={onModalClose}
        renderContent={renderModalBody}
        large={true}
        hideClose={true}
        closeOnBackdropClick={false}
        closeOnEscKey={false}
      />
    </>
  );
};

TopicModal.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  onModalClose: PropTypes.func,
  handleOnSuccess: PropTypes.func,
  topicAttachmentsConfig: PropTypes.object,
  topicData: PropTypes.object,
};

export default TopicModal;

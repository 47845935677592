import React from 'react';

const style = {
  'color': '#716aca',
};

const CloseIcon = () => (
  <i className="fa fa-times custom" style={style} aria-hidden="true"></i>
)

export default CloseIcon;

import { isValidElement } from 'react';

import moment from 'moment';
import indexOf from 'lodash/indexOf';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import split from 'lodash/split';
import times from 'lodash/times';
import find from 'lodash/find';
import replace from 'lodash/replace';
import snakeCase from 'lodash/snakeCase';
import camelCase from 'lodash/camelCase';
import includes from 'lodash/includes';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import {
  DATE_TIME_FORMATS,
  SENTIMENT_COLOR_MAP,
  USER_ROLES,
} from './constants';
import camelCaseKeys from './dataFormatters/camelCase';

export const isValidChildren = child => {
  return isValidElement(child);
};

export const renderOptions = options =>
  map(options, option => ({ label: option.name, value: option.id }));

export const getErrorMessages = serverResponse => {
  return !isNil(serverResponse.response)
    ? !isNil(serverResponse.response.data['errors'])
      ? serverResponse.response.data['errors']
      : ['something went wrong.']
    : ['something went wrong.'];
};

export const constructOptionsFromRailsSelect = options =>
  map(options, option => ({ label: option[0], value: option[1] }));

export const pageLeavePreventDialog = e => {
  // custom messages are no longer supported, but still needed so the popup shows
  const dialogText = 'Changes you made may not be saved!';
  e.returnValue = dialogText;
  return dialogText;
  // returning undefined for IE browsers
};

export const addPageLeavePreventDialog = () => {
  window.addEventListener('beforeunload', pageLeavePreventDialog);
};

export const removePageLeavePreventDialog = () => {
  window.removeEventListener('beforeunload', pageLeavePreventDialog);
};

export const getRolesIndexForOption = role => indexOf(USER_ROLES, role) + 1;

export const getSnakeCaseKeyedObject = (object = {}) =>
  reduce(
    object,
    (snakeCaseKeyedObject, value, key) => {
      const snakeCasedKey = snakeCase(key);
      snakeCaseKeyedObject[snakeCasedKey] = value;

      return snakeCaseKeyedObject;
    },
    {}
  );

export const getCamelCaseKeyedObject = (object = {}) =>
  reduce(
    object,
    (camelCaseKeyedObject, value, key) => {
      const camelCasedKey = camelCase(key);
      camelCaseKeyedObject[camelCasedKey] = value;

      return camelCaseKeyedObject;
    },
    {}
  );

/**
 * This method no longer to be used in future - for axios response data conversion.
 * To convert response object cases.
 * Now, interceptors are added, so Http.useAPIDataFormatters({
    snakifyRequestData: boolean,
    camelizeResponseData: boolean,
  }) can be used.
 * Warning: To be used only on objects.
 * @param {object} object
 */
export const recursiveSnakeCasetoCamelCase = object => {
  const options = Object.freeze({
    exclude: [],
    stopPaths: [],
    deep: true,
    pascalCase: false,
  });

  return camelCaseKeys(object, options);
};

export const constructMultiSelectOptions = (array = []) =>
  map(array, element => ({ label: element, value: element }));

export const getFileStackConfig = data => {
  const { mimeType = ['**'], maxFiles: maxAllowedFiles = '10' } = data;

  let acceptMimeTypes = isArray(mimeType) ? mimeType : [mimeType];

  if (
    acceptMimeTypes.length >= 1 &&
    acceptMimeTypes.findIndex(m => m === '**') > -1
  ) {
    acceptMimeTypes = ['.pdf', 'image/*', 'video/*', 'audio/*', 'text/*'];
  }
  const max = parseInt(maxAllowedFiles, 10);

  return {
    accept: acceptMimeTypes || [
      '.pdf',
      'image/*',
      'video/*',
      'audio/*',
      'text/*',
    ],
    fromSources: ['local_file_system'],
    maxFiles: max >= 0 ? max : 10,
    rootId: 'new-drop-pane',
  };
};

export const humanFileSize = (bytes, si = true) => {
  let thresh = si ? 1000 : 1024;
  if (!bytes) {
    return 'Any';
  }
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

export const getAssetTypeFromMIME = mimetype => {
  const IMAGE_ASSET_TYPE = 'image';
  const VIDEO_ASSET_TYPE = 'video';
  const FILE_ASSET_TYPE = 'file';

  if (includes(mimetype, IMAGE_ASSET_TYPE)) {
    return IMAGE_ASSET_TYPE;
  }

  if (includes(mimetype, VIDEO_ASSET_TYPE)) {
    return VIDEO_ASSET_TYPE;
  }

  return FILE_ASSET_TYPE;
};

export const renderAttachmentThumbnail = (mimetype, poster) => {
  let thumbNail = isEmpty(poster)
    ? `https://mentor-assets.s3.amazonaws.com/assets/files/${mimetype
        .split('/')
        .splice(-1, 1)}.svg`
    : poster;

  return thumbNail;
};

export const PERIOD_FILTER_OPTIONS = [
  { key: 'today', label: 'Today' },
  { key: 'thisWeek', label: 'This Week' },
  { key: 'lastWeek', label: 'Last Week' },
  { key: 'thisMonth', label: 'This Month' },
  { key: 'lastMonth', label: 'Last Month' },
  { key: 'yearToDate', label: 'Year to Date' },
  { key: 'lastYear', label: 'Last Year' },
];

export const getStartAndEndDatesForAGivenPeriod = period => {
  let startDate = null;
  let endDate = null;

  switch (period) {
    case 'today':
      startDate = moment().startOf('day').local();
      endDate = moment().endOf('hour').local();
      break;

    case 'thisWeek':
      startDate = moment().startOf('week').local();
      endDate = moment().local();
      break;

    case 'lastWeek':
      startDate = moment().startOf('week').subtract(1, 'week').local();
      endDate = moment().startOf('week').subtract(1, 'day').local();
      break;

    case 'thisMonth':
      startDate = moment().startOf('month').local();
      endDate = moment().local();
      break;

    case 'lastMonth':
      startDate = moment().startOf('month').subtract(1, 'month').local();
      endDate = moment().startOf('month').subtract(1, 'day').local();
      break;

    case 'yearToDate':
      startDate = moment().startOf('year').local();
      endDate = moment().local();
      break;
    case 'lastYear':
      startDate = moment().startOf('year').subtract(1, 'year').local();
      endDate = moment()
        .startOf('year')
        .subtract(1, 'day')
        .endOf('day')
        .local();
      break;

    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};

export const getRangeOfDatesWithDefaultCount = ({
  startDate,
  endDate,
  groupBy = 'days',
}) => {
  const fromDate = moment(startDate);
  const toDate = moment(endDate);
  let diff = toDate.diff(fromDate, groupBy);
  let format = 'YYYY-MM-DD';

  if (isEqual('months', groupBy)) {
    format = 'MMMM';
  } else if (isEqual('hours', groupBy)) {
    format = 'hh:00 a';
    diff += 1;
  }
  const range = {};
  for (let toAdd = 0; toAdd <= diff; toAdd++) {
    const formattedDay = moment(startDate).add(toAdd, groupBy).format(format);
    range[formattedDay] = 0;
  }
  return range;
};

// Ref: https://stackoverflow.com/a/39835908/10208226
export const pluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

// Ref: https://stackoverflow.com/a/53006402/10208226
export const convertMilliSecondsToReadableString = (ms = 0) => {
  let inSeconds = ms / 1000;

  const days = Math.floor((inSeconds %= 31536000) / 86400);
  const hours = Math.floor((inSeconds %= 86400) / 3600);
  const minutes = Math.floor((inSeconds %= 3600) / 60);
  const seconds = inSeconds % 60;

  if (days || hours || minutes || seconds) {
    return (
      (days ? `${pluralize(days, 'day')} ` : '') +
      (hours ? `${pluralize(hours, 'hr')} ` : '') +
      (minutes ? `${pluralize(minutes, 'min')} ` : '') +
      `${pluralize(Number.parseFloat(seconds).toFixed(2), 'sec')} `
    );
  }

  return '0 secs';
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

/**
 * @param {Array} array @default [] - array to convert to hash
 * @param {String} key @default "id" - key to specify the key in converted hash (should be one of the keys from each object in array)
 * Given an array of objects - [{id: 1, name: 'test'}, {id: 2, name: 'success'}] and key as 'id'
 * @returns Hash - {1: {id: 1, name: 'test'}, 2: {id: 2, name: 'success'}}
 */
export const convertArrayOfObjectsToHash = (
  array = [],
  key = '',
  keySuffix = ''
) => {
  if (isEmpty(array)) return {};
  if (isEmpty(key)) {
    throw new Error(
      'Key should be a valid. Any valid key that is present in all the objects in given array'
    );
  }

  return reduce(
    array,
    (hash, current) => {
      const hashKey = isEmpty(keySuffix)
        ? current[key]
        : `${camelCase(keySuffix)}_${current[key]}`;
      hash[hashKey] = current;
      return hash;
    },
    {}
  );
};

/**
 * Call Interface Window
 * @param {String} strUrl - link to load window / resource
 * Ref: https://developer.mozilla.org/en-US/docs/Web/API/Window/open
 */
let callInterfaceWindowRef = null; // global variable
let PreviousUrl; // global variable which will store the url currently in the secondary window
const WINDOW_IDENTIFIER = 'callInterfaceWindow';
const WINDOW_OPTIONS = 'width=400,height=400,alwaysOnTop,location=no';

export function openCallInterfaceWindow(strUrl) {
  if (callInterfaceWindowRef == null || callInterfaceWindowRef.closed) {
    callInterfaceWindowRef = window.open(
      strUrl,
      WINDOW_IDENTIFIER,
      WINDOW_OPTIONS
    );
  } else if (PreviousUrl != strUrl) {
    callInterfaceWindowRef = window.open(
      strUrl,
      WINDOW_IDENTIFIER,
      WINDOW_OPTIONS
    );
    /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
    callInterfaceWindowRef.focus();
  } else {
    callInterfaceWindowRef.focus();
  }

  PreviousUrl = strUrl;
  /* explanation: we store the current url in order to compare url
     in the event of another call of this function. */
}

/**
 * Custom filterOptions method for enhanced filtering
 * @param {Array[String]} filterKeys - search value will be matched with values of given keys from an option object
 * Ex: Consider location object - {id: 1, name: "Cafe Coffee Day", city: "Bangalore", state: "Karnataka", zip_code: 560066}
 * and filterKeys are ["name", "city", "state"]
 * Search value matching any of given key paired values will be treated as a match
 * IMPORTANT: Only send snake cased filter keys - conversion is costly since filter is invoked for each input entered
 */
// Ref: https://github.com/JedWatson/react-select/blob/master/packages/react-select/src/filters.js
export function customFilterOptions(filterKeys) {
  const isValid = value => !isNil(value) && !isEmpty(value);
  const trimString = str => str.replace(/^\s+|\s+$/g, '');

  const filterOptions = ({ data: option }, inputValue) => {
    if (!isValid(inputValue)) return true;

    const filterValue = trimString(inputValue.toLowerCase());

    return Object.entries(option).some(([optionKey, optionValue]) => {
      if (
        // match against only given keys
        !includes(filterKeys, snakeCase(optionKey)) ||
        // must have a value
        isNil(optionValue) ||
        // Anything but string and number are ignored for check
        (!isString(optionValue) && !isNumber(optionValue))
      ) {
        return false;
      }

      let stringifiedOptionValue = String(optionValue);

      stringifiedOptionValue = trimString(stringifiedOptionValue.toLowerCase());
      return includes(stringifiedOptionValue, filterValue);
    });
  };

  return filterOptions;
}

// Generate hashKey of Given Length
export const generateRandomHashKey = (keyLength = 7) => {
  let hash = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  times(keyLength, () => {
    hash += characters.charAt(Math.floor(Math.random() * charactersLength));
  });
  return hash;
};

// Get formated date and time for given date time
export const formatDateTime = ({
  date,
  formatTime = true,
  timeOnly = false,
}) => {
  if (isNil(date)) return null;
  const momentDateObj = moment(date);

  const format = timeOnly
    ? DATE_TIME_FORMATS.time
    : formatTime
    ? DATE_TIME_FORMATS.dateTime
    : DATE_TIME_FORMATS.date;
  // format only valid dates
  return momentDateObj.isValid() ? momentDateObj.format(format) : date;
};

/**
 *
 * @param {Date} date
 * @returns Readable time diff if less than a day else date as string
 */
export const getReadableTimeDiffFromNowUntilAWeek = date => {
  const dateObj = moment(date).utc();
  const timePassed = moment().utc() - dateObj;
  const WEEK_IN_MILLISECODS = 604800000;
  return timePassed >= WEEK_IN_MILLISECODS
    ? dateObj.format(DATE_TIME_FORMATS.date)
    : dateObj.fromNow();
};

/**
 * Ref: https://codebrahma.com/how-to-efficiently-operate-on-large-datasets-using-transducers-in-javascript/
 * @param  {Functions} functions - functions to be composed
 */
export const compose =
  (...functions) =>
  value =>
    functions.reduceRight(
      (currentValue, currentFunc) => currentFunc(currentValue),
      value
    );

const makeFilterTransducer = predicateFunc => reducer => {
  return (accumulator, value) => {
    if (predicateFunc(value)) {
      return reducer(accumulator, value);
    }
    return accumulator;
  };
};

const makeMapTransducer = transformerFunc => reducer => {
  return (accumulator, value) => {
    return reducer(accumulator, transformerFunc(value));
  };
};

const valuesAccumulatorReducer = (accumulator, value) => {
  accumulator.push(value);
  return accumulator;
};

/**
 * @param  {Functions} functionsToTransduce - functions that goes in transducer pipeline
 * @returns {Function} - that takes @param {Object} - {payload, transduceMakerType}
 * and @return {Array} final list after appliying given functions
 *
 * Ex: Usage - transduce(filterByName, filterByAge)({ payload: users, transduceMakerType: 'filter' })
 *
 * Note: More transducers can be added as required - for now only map and filter are supported
 */

export const transduce =
  (...functionsToTransduce) =>
  ({ payload = [], transduceMakerType = 'filter' }) => {
    // Return payload if either functions or payload is empty
    if (isEmpty(functionsToTransduce) || isEmpty(payload)) return payload;
    // If maker type isn't available - show console error about unavailability and return payload
    if (!includes(['filter', 'map'], transduceMakerType)) {
      console.error(
        `${transduceMakerType} is not supported yet, Please add definition before usage`
      );
      return payload;
    }

    const transducers = {
      filter: functionsToTransduce.map(f => makeFilterTransducer(f)),
      map: functionsToTransduce.map(f => makeMapTransducer(f)),
    }[transduceMakerType];

    const resultTransducer = compose(...transducers);
    return payload.reduce(resultTransducer(valuesAccumulatorReducer), []);
  };

/**
 * @param {String} phone
 * @returns {String} - digits only phone number
 * Ex: Given +1 (123)-456-789, returns 1123456789
 */
export const getRawPhoneNumber = phone => phone.replace(/\D+/g, ''); // Remove everything but numbers

/**
 * @param {String} phoneValue - PhoneInput value
 * @param {Object} countryData - PhoneInput's country details
 * @returns {String} - An unfomatted version of Phone Number or empty string
 * Ex: Given +1 (123)-456-789, returns +1123456789
 */
export const getUnformattedPhoneNumber = (phoneValue, countryData) => {
  let updatedPhoneValue = '';

  if (!isEqual(phoneValue, '+')) {
    const rawPhone = getRawPhoneNumber(phoneValue);
    if (!isEqual(rawPhone, countryData.dialCode)) {
      updatedPhoneValue = `+${rawPhone}`;
    }
  }

  return updatedPhoneValue;
};

/**
 *
 * @param {string} sentiment - one of 'positive', 'negative', 'neutral'
 * @returns {string} - one of color class - 'success', 'warning', 'danger' or ''
 */
export const getSentimentColor = sentiment =>
  !isEmpty(sentiment) ? SENTIMENT_COLOR_MAP[sentiment.toLowerCase()] : '';

// Reference: https://www.sitepoint.com/get-url-parameters-with-javascript/
export const getUrlQueryParamsAsObject = (url = '') => {
  // get query string from url (optional) or window
  let queryString = url ? split(url, '?')[1] : window.location.search.slice(1);
  let paramsObject = {};

  if (queryString) {
    // #(hash history) is not part of query string, so get rid of it
    queryString = split(queryString, '#')[0];

    // split our query string into its component parts
    const queryParams = split(queryString, '&');

    map(queryParams, param => {
      // separate the keys and the values
      const paramPair = split(param, '=');

      // set parameter name and value (use '' if empty)
      let paramName = paramPair[0];
      let paramValue = !isUndefined(paramPair[1]) ? paramPair[1] : '';

      if (isString(paramValue)) {
        // Decode the params, eg: "hello%20world" will result "hello world"
        paramValue = decodeURIComponent(paramValue);
      }

      // if the param is an array type, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        const key = replace(paramName, /\[(\d+)?\]/, '');
        if (!paramsObject[key]) {
          paramsObject[key] = [];
        }

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const index = /\[(\d+)\]/.exec(paramName)[1];
          paramsObject[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          paramsObject[key].push(paramValue);
        }
      } else {
        if (
          !paramsObject[paramName] ||
          (paramsObject[paramName] && isString(paramsObject[paramName]))
        ) {
          // if it doesn't exist, create property
          // if it exists take later one
          paramsObject[paramName] = paramValue;
        } else {
          // otherwise add the property
          paramsObject[paramName].push(paramValue);
        }
      }
    });
  }

  return paramsObject;
};

/**
 * get the selected values for the select input || multi <select name="" id=""></select>
 * @param {Object[]} options
 * @param {Object/String/Number || Object[]/String[]/Number[]} selectedOption
 * @param {String} optionIdentifier - key to look up the value in options
 * @returns {Object || Object[]} - selected options
 */
export const getSelectedOptions = ({
  options = [],
  optionIdentifier,
  selectedOption,
  isMulti = false,
  isGrouped = false,
}) => {
  if (isNil(selectedOption)) return isMulti ? [] : '';

  const isValidOption = option =>
    isObject(option) && !isArray(option) && !isNil(option[optionIdentifier]);

  if (isMulti) {
    // if selectedOptions are already objects, selectedOption construction is not necesssary
    const [firstOption] = selectedOption;
    if (isValidOption(firstOption)) return selectedOption;

    return filter(options, option =>
      includes(selectedOption, option[optionIdentifier])
    );
  }

  // if selectedOption is already an object, selectedOption construction is not necesssary
  if (isValidOption(selectedOption)) return selectedOption;
  if (isGrouped) {
    return options.forEach(
      option =>
        find(option.options, { [optionIdentifier]: selectedOption }) || ''
    );
  }
  // return the matched option or an empty string
  return find(options, { [optionIdentifier]: selectedOption }) || '';
};

/**
 * @param {String|Object(moment)} date
 * @returns {Date}
 */
export const getDateInstance = date => new Date(date);

// Get document Height
const getDocHeight = () => {
  let D = document;
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  );
};

// Get scroll percentage
export const getScrollPercentage = () => {
  let winheight =
    window.innerHeight ||
    (document.documentElement || document.body).clientHeight;
  let docheight = getDocHeight();
  let scrollTop =
    window.pageYOffset ||
    (document.documentElement || document.body.parentNode || document.body)
      .scrollTop;
  let trackLength = docheight - winheight;
  let pctScrolled = Math.floor((scrollTop / trackLength) * 100); // gets percentage scrolled (ie: 80 or NaN if tracklength == 0)
  return pctScrolled;
};

// Get meta tag info
export const getMeta = (metaName = 'csrf-token', attributeName = 'name') =>
  document.querySelector(`meta[${attributeName}="${metaName}"]`)?.content;

// Get csrf-token from meta tag
export const csrfTokenFromMeta = getMeta();

export const getSentimentClass = sentimentLabel => {
  switch (sentimentLabel) {
    case 'Positive':
      return 'positive';
    case 'Negative':
      return 'negative';
    case 'Neutral':
      return 'neutral';
  }
};

// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
function nonClipboardCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    nonClipboardCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text);
}

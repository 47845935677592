import React from 'react';
import PropTypes from 'prop-types';

import snakeCase from 'lodash/snakeCase';
import isEqual from 'lodash/isEqual';
import toLower from 'lodash/toLower';
import startCase from 'lodash/startCase';

const headingStyles = {
  marginBottom: 0,
  padding: '2.2rem',
  backgroundColor: '#f7f7fa',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  fontSize: '1.5rem',
  color: '#464457',
};

const CategoryHeader = ({ catName }) => {
  const snakeCasedCatName = snakeCase(catName);
  return (
    <div className="card-header" id={`${snakeCasedCatName}Header`}>
      <h3
        className="kt-section__heading card-title"
        style={headingStyles}
        role="tab"
        data-toggle="collapse"
        data-target={`#${snakeCasedCatName}_list`}
        aria-expanded="true"
        aria-controls={`${snakeCasedCatName}_list`}
      >
        {!isEqual(toLower(catName), 'na') ? startCase(toLower(catName)) : null}
      </h3>
    </div>
  );
};

CategoryHeader.propTypes = {
  catName: PropTypes.string.isRequired,
};

export default CategoryHeader;

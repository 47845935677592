import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import toInteger from 'lodash/toInteger';

import Spinner from '../common/presentational/spinner';
import FormShowPage from './FormShowPage';
import Http from '../common/Http';
import { SURVEY_SUMMARY_QUERY_STRING } from '../surveys/constants';
import UserResponsesTable from './UserResponsesTable';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { feedbackFormTableReducer } from './formsReducers';

const INITIAL_FEEDBACK_FORM_TABLE_STATE = {
  formsLoading: false,
  responsesLoading: false,
  newFormMode: false,
  selectedFormId: null,
  feedbackForms: [],
  userResponses: [],
  formDataHash: {},
};

const FeedbackFormTable = props => {
  const [feedbackFormTableState, dispatchFeedbackFormActions] = useReducer(
    feedbackFormTableReducer,
    INITIAL_FEEDBACK_FORM_TABLE_STATE
  );

  const {
    newFormMode,
    formsLoading,
    responsesLoading,
    selectedFormId,
    feedbackForms,
    userResponses,
  } = feedbackFormTableState;

  const {
    isSurveyPage,
    links,
    showDeleteOption,
    csrfToken,
    showImpressionsOverview,
    tenantTerms,
    surveyProjects,
    projectsEnabled,
  } = props;

  const fetchFeedbackForms = async () => {
    const {
      csrfToken,
      links: { fetchFeedbackForms },
    } = props;

    await new Http()
      .onBegin(() =>
        dispatchFeedbackFormActions({ type: 'initiateFeedbackFormsFetch' })
      )
      .setToken(csrfToken)
      .get(fetchFeedbackForms)
      .onSuccess(({ data }) => {
        dispatchFeedbackFormActions({
          type: 'resolvedFeedbackFormsFetch',
          payload: data.feedback_forms,
        });
      })
      .onError(err => {
        dispatchFeedbackFormActions({ type: 'rejectedFeedbackFormsFetch' });
        alertErrorNotifications(
          err || 'Error Occurred while getting forms list.'
        );
      })
      .exec();
  };

  const fetchUserResponsesData = async () => {
    const {
      resourceType,
      resourceId,
      isSurveyPage,
      selectedForm,
      csrfToken,
      links: { fetchUserResponses },
    } = props;

    let queryParams = {};
    if (isSurveyPage && !isEmpty(selectedForm)) {
      queryParams = {
        [SURVEY_SUMMARY_QUERY_STRING]: selectedForm.id,
      };
    } else {
      queryParams = {
        resource_type: resourceType,
        resource_id: resourceId,
      };
    }
    await new Http()
      .onBegin(() =>
        dispatchFeedbackFormActions({ type: 'initiateUserResponseFetch' })
      )
      .setToken(csrfToken)
      .get(fetchUserResponses, {
        ...queryParams,
      })
      .onSuccess(res => {
        dispatchFeedbackFormActions({
          type: 'resolvedUserResponseFetch',
          payload: orderBy(
            res.data.user_responses,
            ['submitted_at', 'form_name'],
            ['desc', 'asc']
          ),
        });
      })
      .onError(err => {
        dispatchFeedbackFormActions({ type: 'rejectedUserResponseFetch' });
        alertErrorNotifications(
          err || 'Error Occurred while getting answers list.'
        );
      })
      .exec();
  };

  useEffect(() => {
    const initialFeedbackFormFetch = async () => {
      const { isSurveyPage, selectedForm } = props;

      if (!isSurveyPage) {
        // Note: Firing multiple requests simultaneously will crash dev server
        // Hence adding intentional delay
        setTimeout(fetchFeedbackForms, 300);
      }

      if (!isSurveyPage || !isEmpty(selectedForm)) {
        fetchUserResponsesData();
      }
    };
    initialFeedbackFormFetch();

    // this useEffect is equal to componentDidMount which triggered only once when the component is mounted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { selectedForm } = props;
    if (!isEmpty(selectedForm)) {
      fetchUserResponsesData();
    }

    // this useEffect only triggered when the selectedForm prop value has changed or updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedForm]);

  const handleNewForm = () => {
    dispatchFeedbackFormActions({ type: 'handleNewForm' });
  };

  const handleFormSelect = event => {
    const { target } = event;
    dispatchFeedbackFormActions({
      type: 'selectFormId',
      payload: target.value,
    });
  };

  const fetchFormData = async formId => {
    const { formDataHash } = feedbackFormTableState;
    const {
      csrfToken,
      links: { getFeedbackForms: feedbackFormLinks },
    } = props;

    if (isNil(formDataHash[formId]) || isEmpty(formDataHash[formId])) {
      await new Http()
        .onBegin(() =>
          dispatchFeedbackFormActions({ type: 'initiateFormsDataFetch' })
        )
        .setToken(csrfToken)
        .get(feedbackFormLinks[toInteger(formId)])
        .onSuccess(({ data }) => {
          dispatchFeedbackFormActions({
            type: 'resolvedFormsDataFetch',
            payload: { [formId]: data.feedback_form },
          });
        })
        .onError(() => {
          dispatchFeedbackFormActions({ type: 'rejectedFormsDataFetch' });
        })
        .exec();
    }
  };

  useEffect(() => {
    const { selectedFormId } = feedbackFormTableState;
    if (!isNull(selectedFormId)) {
      fetchFormData(selectedFormId);
    }

    // whenever the selectedFormId in the table state is updated the useEffect will triggered.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackFormTableState.selectedFormId]);

  const handleNewFormCancel = () => {
    const { selectedFormId } = feedbackFormTableState;
    let ready = true;
    if (!isNil(selectedFormId)) {
      ready = confirm(
        'Any changes made will be discarded. Do you want to Cancel?'
      );
    }

    if (ready) {
      dispatchFeedbackFormActions({ type: 'cancelNewForm' });
    }
  };

  const renderNewForm = () => {
    const { csrfToken, resourceId, resourceType, userList, projectList } =
      props;
    const { selectedFormId, feedbackForms, formDataHash } =
      feedbackFormTableState;

    return (
      <React.Fragment>
        <div className="offset-md-1 col-md-10">
          <div className="form-group kt-form__group">
            <label htmlFor="newFormDropDown">Select Form to respond:</label>
            <select
              className="form-control kt-input"
              id="newFormDropDown"
              onChange={handleFormSelect}
              value={selectedFormId || ''}
            >
              <option value="">Select a form</option>
              {map(feedbackForms, form => {
                return (
                  <option value={form.id} key={form.id}>
                    {form.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="offset-md-1 col-md-10">
          {!isNil(selectedFormId) &&
            !isEmpty(selectedFormId) &&
            !isNil(formDataHash[selectedFormId]) && (
              <React.Fragment>
                <div className="kt-separator kt-separator--space-md kt-separator--border-dashed" />
                <FormShowPage
                  csrfToken={csrfToken}
                  feedbackFormData={formDataHash[selectedFormId]?.data}
                  formId={selectedFormId}
                  resourceId={resourceId}
                  userList={userList}
                  projectList={projectList}
                  resourceType={resourceType}
                  isPreview={false}
                  links={{
                    record_user_response:
                      formDataHash[selectedFormId].links.record_user_response,
                    get_closest_projects:
                      formDataHash[selectedFormId].links.get_closest_projects,
                  }}
                />
              </React.Fragment>
            )}
          <br />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="kt-portlet mb-0">
      {(formsLoading || responsesLoading) && <Spinner />}
      {!isSurveyPage && (
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Forms</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-group">
              {isNil(selectedFormId) && !newFormMode && (
                <React.Fragment>
                  {!isEmpty(links.archivedUserResponses) && (
                    <a
                      href={links.archivedUserResponses}
                      className="btn-outline-darkred mr-15"
                    >
                      Deleted Responses
                    </a>
                  )}
                  <button
                    className="pull-right app-btn-primary"
                    onClick={handleNewForm}
                  >
                    New Form
                  </button>
                </React.Fragment>
              )}
              {(!isNil(selectedFormId) || newFormMode) && (
                <button
                  type="button"
                  className="pull-right btn btn-darkred"
                  onClick={handleNewFormCancel}
                >
                  Go Back
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={`kt-portlet__body ${!newFormMode ? 'plr-0' : ''}`}>
        {newFormMode ? (
          renderNewForm()
        ) : (
          <UserResponsesTable
            csrfToken={csrfToken}
            feedbackForms={feedbackForms}
            userResponses={userResponses}
            isSurveyPage={isSurveyPage}
            showDeleteOption={showDeleteOption}
            showRestoreOption={false}
            showImpressionsOverview={showImpressionsOverview}
            tenantTerms={tenantTerms}
            surveyProjects={surveyProjects}
            projectsEnabled={projectsEnabled}
          />
        )}
      </div>
    </div>
  );
};

FeedbackFormTable.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  resourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  resourceType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  userList: PropTypes.array.isRequired,
  projectList: PropTypes.array,
  tenantTerms: PropTypes.object,
  showDeleteOption: PropTypes.bool,
  projectsEnabled: PropTypes.bool,
  links: PropTypes.shape({
    fetchFeedbackForms: PropTypes.string.isRequired,
    fetchUserResponses: PropTypes.string.isRequired,
    getFeedbackForms: PropTypes.object.isRequired,
    archivedUserResponses: PropTypes.object,
  }).isRequired,
  isSurveyPage: PropTypes.bool,
  showImpressionsOverview: PropTypes.bool,
  surveyProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  selectedForm: PropTypes.shape({
    id: PropTypes.number,
  }),
};

FeedbackFormTable.defaultProps = {
  resourceId: '',
  resourceType: '',
  isSurveyPage: false,
  projectsEnabled: false,
  showDeleteOption: false,
  showImpressionsOverview: false,
  surveyProjects: [],
};

export default withErrorHandler(FeedbackFormTable);

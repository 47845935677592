import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import values from 'lodash/values';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';

import TextInput from '../../common/inputs/text';
import MultiSelect from '../../common/inputs/multiSelect';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import {
  generateRandomHashKey,
  getUnformattedPhoneNumber,
} from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { alertErrorNotifications } from '../../folders/utils';

const DEFAULT_USER_INFO = {
  email: '',
  firstName: '',
  lastName: '',
  mobile: '',
  teamIds: [],
  roleIds: [],
};

const AdminMembersForm = props => {
  const initialRowHash = React.useMemo(() => generateRandomHashKey(), []);
  const [loading, setLoading] = React.useState(false);
  const [newUserRowsCount, setNewUserRowsCount] = React.useState(1);
  const [users, setUsers] = React.useState({
    [`user_${initialRowHash}`]: DEFAULT_USER_INFO,
  });
  const teamsPresent = React.useMemo(
    () => !isEmpty(props.teams),
    [props.teams]
  );
  const rolesPresent = React.useMemo(
    () => !isEmpty(props.roles),
    [props.roles]
  );

  const handleUserInfoAttributeChange = (
    hashKey,
    attributeName,
    attributeValue
  ) => {
    setUsers(prevState => ({
      ...prevState,
      [hashKey]: {
        ...prevState[hashKey],
        [attributeName]: attributeValue,
      },
    }));
  };

  const handlePhoneInputChange = hashKey => (value, countryData) => {
    const updatedValue = getUnformattedPhoneNumber(value, countryData);
    setUsers(prevState => ({
      ...prevState,
      [hashKey]: {
        ...prevState[hashKey],
        mobile: updatedValue,
      },
    }));
  };

  const handleTeamsAndRolesSelect = (hashKey, values, attribute) => {
    setUsers(prevState => ({
      ...prevState,
      [hashKey]: {
        ...prevState[hashKey],
        [attribute]: map(values, 'id'),
      },
    }));
  };

  const inviteUsers = async () => {
    const {
      csrfToken,
      links,
      tenantTerms: { termUser },
    } = props;

    // Remove user data object if all rows are empty.
    let submittableUserData = users;
    map(users, (data, hash) => {
      let isRowDataEmpty = isEmpty(data); // Flag to verify and remove data if row is empty.

      if (!isEmpty(data)) {
        const totalData = map(values(data), value => !isEmpty(value));
        const validData = uniq(compact(totalData));
        isRowDataEmpty = isEmpty(validData);
      }

      if (isRowDataEmpty) {
        submittableUserData = omit(submittableUserData, hash);
      }
    });

    if (isEmpty(submittableUserData)) {
      alertErrorNotifications(
        `Please add atleast one ${termUser.singular} details to invite`
      );
      return;
    }

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .post(links.inviteUsers)
      .setPostData({
        user: submittableUserData,
      })
      .useAlerts()
      .onSuccess(({ data }) => {
        setLoading(false);
        window.location.href = data.redirectionUrl;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const canAddNewUser = hashKey => {
    return (
      !isEmpty(users) &&
      !isEmpty(users[hashKey]) &&
      !isEmpty(users[hashKey]['email']) &&
      !isEmpty(users[hashKey]['firstName']) &&
      !isEmpty(users[hashKey]['lastName']) &&
      !isEmpty(users[hashKey]['roleIds'])
    );
  };

  const addNewUserRow = hashKey => {
    // Restrict adding new user row when current last row is empty
    if (!canAddNewUser(hashKey)) {
      return;
    }

    const newRowHash = generateRandomHashKey();

    setNewUserRowsCount(prevState => prevState + 1);
    setUsers(prevState => ({
      ...prevState,
      [`user_${newRowHash}`]: DEFAULT_USER_INFO,
    }));
  };

  const deleteUserRow = hash => {
    setNewUserRowsCount(prevState => prevState - 1);
    setUsers(prevState => omit(prevState, hash));
  };

  const renderDynamicallyAddedUserRow = () => {
    const { teams, roles } = props;

    return map(keys(users), (hashKey, index) => (
      <tr key={hashKey}>
        <td>
          <TextInput
            type="email"
            placeholder="member@example.com"
            name="email"
            onInputChange={({ target }) =>
              handleUserInfoAttributeChange(hashKey, 'email', target.value)
            }
            value={users[hashKey]['email']}
            numberAttributes={{
              required: true,
            }}
          />
        </td>
        <td>
          <TextInput
            type="text"
            placeholder="First Name..."
            name="firstName"
            value={users[hashKey]['firstName']}
            onInputChange={({ target }) =>
              handleUserInfoAttributeChange(hashKey, 'firstName', target.value)
            }
            numberAttributes={{
              required: true,
            }}
          />
        </td>
        <td>
          <TextInput
            type="text"
            placeholder="Last Name..."
            name="lastName"
            value={users[hashKey]['lastName']}
            onInputChange={({ target }) =>
              handleUserInfoAttributeChange(hashKey, 'lastName', target.value)
            }
          />
        </td>
        <td>
          <PhoneInput
            country={'us'}
            value={users[hashKey]['mobile']}
            onChange={handlePhoneInputChange(hashKey)}
            countryCodeEditable={false}
            autoFormat={false}
            enableSearch
            inputStyle={{ width: '100%' }}
          />
        </td>
        {(teamsFeatureEnabled && teamsPresent)  && (
          <td>
            <MultiSelect
              placeholder="Assign to Team"
              options={teams}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={users[hashKey]['teamIds']}
              onChange={values =>
                handleTeamsAndRolesSelect(hashKey, values, 'teamIds')
              }
              optionIdentifier="id"
            />
          </td>
        )}
        {rolesPresent && (
          <td>
            <MultiSelect
              placeholder="Assign Roles"
              options={roles}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={users[hashKey]['roleIds']}
              onChange={values =>
                handleTeamsAndRolesSelect(hashKey, values, 'roleIds')
              }
              optionIdentifier="id"
            />
          </td>
        )}
        <td className="td-50 text-center">
          {isEqual(newUserRowsCount - 1, index) ? (
            <span
              className="kt-font-success"
              style={{
                opacity: canAddNewUser(hashKey) ? 1 : 0.6,
                cursor: canAddNewUser(hashKey) ? 'pointer' : 'not-allowed',
              }}
              onClick={() => addNewUserRow(hashKey)}
            >
              <i className="fa fa-user-plus font-20" />
            </span>
          ) : (
            <span
              className="text-darkred cursor-pointer"
              onClick={() => deleteUserRow(hashKey)}
            >
              <i className="fa fa-user-times font-20" />
            </span>
          )}
        </td>
      </tr>
    ));
  };

  const {
    tenantTerms: { termUser },
    companyName,
    teamsFeatureEnabled,
  } = props;

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{`Add ${termUser.plural}`}</h3>
        </div>
      </div>
      {loading && <Spinner text="Submitting" />}

      <div className="kt-portlet__body">
        <div className="row">
          <div className="col-md-12">
            <p>
              {`To add new ${termUser.plural} to ${companyName}, enter the ${termUser.singular}’s name and email address in the form below and click the submit button.
                Once you have submitted their information, the ${termUser.singular} will receive an email with instructions on how to complete the signup process.
                After the ${termUser.singular} has created an account, they can begin uploading submissions to your evaluators.`}
            </p>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table kt-table__row-equal-width">
                <thead className="block-title">
                  <tr className="text-left">
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Mobile Number</th>
                    {(teamsFeatureEnabled && teamsPresent) && <th>Team</th>}
                    {rolesPresent && <th>Roles</th>}
                    <th className="td-50"></th>
                  </tr>
                </thead>
                <tbody>{renderDynamicallyAddedUserRow()}</tbody>
              </table>
            </div>
            <div className="kt-portlet__foot">
              <div className="row">
                <div className="col-lg-6 offset-3 text-center">
                  <button
                    type="submit"
                    className="btn btn-success"
                    onClick={inviteUsers}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AdminMembersForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  teamsFeatureEnabled: PropTypes.bool.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  links: PropTypes.shape({
    inviteUsers: PropTypes.string.isRequired,
  }).isRequired,
  roles: PropTypes.array,
  tenantTerms: PropTypes.shape({
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withErrorHandler(AdminMembersForm);

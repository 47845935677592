import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import reduce from 'lodash/reduce';
import map from 'lodash/map';

import FormBuilder from 'cb-react-form-builder';
import './form_styles.scss';
import './public_form_styles.scss';
import Spinner from '../common/presentational/spinner';
import CustomAssetManager from './custom/CustomAssetManager';
import CustomDatePicker from './custom/CustomDatePicker';
import { alertErrorNotifications } from '../folders/utils';
import {
  removePageLeavePreventDialog,
  addPageLeavePreventDialog,
} from '../common/utils';
import CustomTextInput from './custom/CustomTextInput';
import Http from '../common/Http';
import FormToggleBox from './custom/FormToggleBox';
import CustomSelectDropDown from './custom/CustomSelectDropDown';
import CustomPhoneInput from './custom/CustomPhoneInput';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS } from '../common/constants';

const FormShowPage = props => {
  const [loading, setLoading] = useState(false);
  const [closestProject, setClosestProject] = useState({});

  const fetchClosestProject = async location => {
    const {
      links: { get_closest_projects: getClosestProjects },
    } = props;

    await new Http()
      .setToken(props.csrfToken)
      .onBegin(() => setLoading(true))
      .get(getClosestProjects)
      .setQueryParams({
        latitude: location.latitude, // Ex: -20.1629776,
        longitude: location.longitude, // Ex: 57.4617571
      })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { closestProjects } }) => {
        let firstClosestProject = {};
        if (!isEmpty(closestProjects)) {
          firstClosestProject = closestProjects[0];
        }
        setClosestProject(firstClosestProject);
        setLoading(false);
      })
      .onError(error => {
        setClosestProject({});
        setLoading(false);
        alertErrorNotifications(error);
      })
      .exec();
  };

  // const getPosition = options => {
  //   return new Promise(function (resolve, reject) {
  //     navigator.geolocation.getCurrentPosition(resolve, reject, options);
  //   });
  // };

  // const getCoordinates = position => {
  //   if (position && position.coords) {
  //     let { coords } = position;
  //     return {
  //       latitude: coords.latitude,
  //       longitude: coords.longitude,
  //     };
  //   } else {
  //     return {
  //       latitude: null,
  //       longitude: null,
  //     };
  //   }
  // };

  // commented for future development and improvements
  const getCurrentLocation = async () => {
    // let position = {};
    let coords = {
      latitude: null,
      longitude: null,
    };
    // if ('geolocation' in navigator) {
    //   try {
    //     position = (await getPosition()) || {};
    //     coords = getCoordinates(position);
    //   } catch (e) {
    //     coords = getCoordinates({});
    //   }
    // }
    return coords;
  };

  const getInitialClosestProject = async () => {
    const { feedbackFormData, isPreview } = props;
    const projectElement = find(feedbackFormData, {
      element: 'CustomProjectDropdown',
    });
    const isProjectElementIncluded = !isUndefined(projectElement);
    let location = {};
    if (isProjectElementIncluded) {
      location = await getCurrentLocation();
    }

    if (
      !isEmpty(location) &&
      !isNull(location.latitude) &&
      !isNull(location.longitude)
    ) {
      fetchClosestProject(location);
    }

    if (!isPreview) addPageLeavePreventDialog();
  };

  useEffect(() => {
    getInitialClosestProject();
    return () => {
      removePageLeavePreventDialog();
    };

    // to trigger only on the initial mount of component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const convertFormArrayToHash = formResponse => {
    return reduce(
      formResponse,
      (result, formField) => {
        if (formField.name.startsWith('camera') && !isEmpty(formField.value)) {
          result.images.push(formField);
        } else if (!formField.name.startsWith('camera')) {
          if (formField.name.startsWith('tags')) {
            result.fields = {
              ...result.fields,
              [formField.name]: formField.value
                .map(v => v.value || v.label)
                .join(','),
            };
          } else {
            result.fields = {
              ...result.fields,
              [formField.name]: formField.value,
            };
          }
        }
        return result;
      },
      {
        images: [],
        fields: {},
      }
    );
  };

  // As questions from the feedback form and answers from the formResponse are mapped via unique field labels.
  // the attributes used in the response show page are case sensitive.
  // Adding the useApiDataFormatters interceptor will lead us to the data loss.
  // NOTE: should not use the api formatters here.
  const handleFormResponse = async formResponse => {
    const {
      resourceType,
      resourceId,
      links: { record_user_response: recordUserResponseUrl },
    } = props;

    const formResponseHash = convertFormArrayToHash(formResponse);

    const answerHash = formResponseHash.fields;

    const attachableObject = !isNil(resourceId)
      ? {
          attachable_id: resourceId,
          attachable_type: resourceType,
        }
      : {};

    await new Http()
      .setToken(props.csrfToken)
      .onBegin(() => setLoading(true))
      .post(recordUserResponseUrl, {
        user_response: {
          data: JSON.stringify(answerHash),
          ...attachableObject,
        },
      })
      .doesRedirect(true)
      .useAlerts()
      .onSuccess(({ data }) => {
        setLoading(false);
        removePageLeavePreventDialog();
        window.location.href = data.meta.redirection_url;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const getFormData = () => {
    const {
      feedbackFormData,
      isPreview,
      userList,
      projectList,
      links,
      customerData,
    } = props;

    let answerData = {};
    let isCustomerData = !!customerData;

    const updateAnswerData = (fieldName, customerAttribute) => {
      if (!isNil(customerData[customerAttribute])) {
        answerData[fieldName] = customerData[customerAttribute];
      }
    };

    const convertedKeys = map(feedbackFormData, item => {
      let response = {
        ...item,
      };

      if (item.custom) {
        switch (item.element) {
          case 'CustomAssetManager':
            response['component'] = CustomAssetManager;
            response['read_only'] = isPreview;
            break;
          case 'NameTextInput':
            response['component'] = CustomTextInput;
            if (isCustomerData) {
              updateAnswerData(item.field_name, 'name');
            }
            break;
          case 'EmailTextInput':
            response['component'] = CustomTextInput;
            if (isCustomerData) {
              updateAnswerData(item.field_name, 'email');
            }
            break;
          case 'CustomPhoneInput':
            response['component'] = CustomPhoneInput;
            response['props']['mobileValidationLink'] =
              links['mobile_validator'];
            if (isCustomerData) {
              updateAnswerData(item.field_name, 'phone_number');
            }
            break;
          case 'FormToggleBox':
            response['component'] = FormToggleBox;
            break;
          case 'DateTime':
          case 'Time':
            response['component'] = CustomDatePicker;
            break;
          case 'CustomMemberDropdown':
            response['component'] = CustomSelectDropDown;
            response['props']['optionsList'] = userList;
            break;
          case 'CustomProjectDropdown':
            response['component'] = CustomSelectDropDown;
            response['props']['optionsList'] = projectList;
            response['props']['defaultValue'] = closestProject.id;
            response['props']['customFilterOptionKeys'] =
              DEFAULT_PROJECT_CUSTOM_FILTER_OPTION_KEYS;
            break;
        }
      }

      return response;
    });

    return { convertedKeys, answerData };
  };

  const { csrfToken, formId, isPreview, isPublic } = props;

  const { convertedKeys, answerData } = getFormData();

  return (
    <div className={`kt-portlet ${isPublic ? 'public_form' : ''}`}>
      {loading && <Spinner />}

      {!isPublic && (
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">
              Feedback Form
              {isPreview && (
                <span className="kt-badge kt-badge--metal kt-badge--inline mx-2">
                  Preview
                </span>
              )}
            </h3>
          </div>
        </div>
      )}

      <div className={`kt-portlet__body ${isPublic ? 'p-0' : ''}`}>
        <FormBuilder.ReactFormGenerator
          task_id={formId}
          answer_data={answerData}
          authenticity_token={csrfToken}
          data={convertedKeys}
          hide_actions={isPreview}
          onErrors={errors => {
            alertErrorNotifications(errors);
          }}
          onSubmit={response => (isPreview ? {} : handleFormResponse(response))}
        />
      </div>
    </div>
  );
};

FormShowPage.defaultProps = {
  resourceId: null,
  resourceType: null,
  isPreview: true,
  isPublic: false,
  userList: [],
  customerData: null,
};

FormShowPage.propTypes = {
  formId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  feedbackFormData: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  isPreview: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool,
  resourceId: PropTypes.number,
  userList: PropTypes.array,
  projectList: PropTypes.array,
  resourceType: PropTypes.string,
  links: PropTypes.shape({
    record_user_response: PropTypes.string.isRequired,
    get_closest_projects: PropTypes.string,
    mobile_validator: PropTypes.string,
  }),
  customerData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
  }),
};

export default withErrorHandler(FormShowPage);

import React from 'react';
import PropTypes from 'prop-types';
import { withErrorHandler } from '../../../hoc/withErrorHandler';

const KbLink = ({ link, name, noIndicators }) => (
  <div className="mt-3 d-flex align-items-baseline text-black-50">
    {!noIndicators && <i className="fas fa-chevron-right mr-2"></i>}
    <a className="category-link" href={link}>
      {name}
    </a>
  </div>
);

KbLink.propTypes = {
  noIndicators: PropTypes.bool,
  link: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

KbLink.defaultProps = {
  noIndicators: false,
};

export default withErrorHandler(KbLink);

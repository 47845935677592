import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import startCase from 'lodash/startCase';

import TextInput from '../common/inputs/text';
import './styles.scss';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';

const SearchView = props => {
  const [loading, setLoading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState({});
  const [searchText, setSearchText] = React.useState('');

  const resetSearchResults = () => {
    setLoading(true);
    setSearchText('');
    setSearchResults({});
  };

  const handleSearchTextChange = event => {
    const { target } = event;
    if (target) {
      setSearchText(target.value);
    }
  };

  const handleSearchSubmit = async event => {
    event.preventDefault();

    await new Http()
      .get('/search.json')
      .onBegin(() => {
        setLoading(true);
        setSearchResults([]);
      })
      .setQueryParams({
        q: searchText,
      })
      .onSuccess(response => {
        setSearchResults(response.data.results || []);
        setLoading(false);
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  const renderSearchCategorySection = (category, results) => {
    const { projects: projectsPath, courses: coursesPath } =
      props.customRoutePaths;

    return (
      <React.Fragment key={category}>
        <div className="kt-quick-search__category">{startCase(category)}</div>
        {map(results, item => {
          let itemUrl = '';
          switch (category) {
            case 'projects':
              itemUrl = `/${projectsPath}/${item.id}`;
              break;
            case 'tickets':
              itemUrl = `/tickets/${item.slug}`;
              break;
            case 'courses':
              itemUrl = `/${coursesPath}/${item.id}`;
              break;
            default:
              itemUrl = '/';
              break;
          }

          return (
            // Display result name based on the type
            // Instead of {item.name || item.title } - we can do a conditional rendering based on type.
            <a
              href={itemUrl}
              className="kt-quick-search__item"
              key={category + item.id}
            >
              <span className="kt-quick-search__item-text">
                {item.name || item.title}
              </span>
            </a>
          );
        })}
      </React.Fragment>
    );
  };

  const renderSearchResults = () => {
    if (!isEmpty(searchResults)) {
      let searchCount = reduce(
        searchResults,
        (count, itemList) => {
          return count + itemList.length;
        },
        0
      );

      if (searchCount > 0) {
        return map(searchResults, (results, categoryKey) => {
          if (results.length > 0) {
            return renderSearchCategorySection(categoryKey, results);
          }
          return null;
        });
      }
      return <div className="kt-quick-search__message">No records found</div>;
    }
    return null;
  };

  return (
    <React.Fragment>
      <div
        className="modal search-modal fade"
        id="searchModal"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered search-modal-dialog"
          role="document"
          style={{
            width: '100% !important',
            maxWidth: 'unset',
            margin: 0,
          }}
        >
          <div className="modal-content search-modal-content">
            <div className="modal-header">
              <form
                role="search"
                className={'col-md-12 search-modal-form'}
                onSubmit={handleSearchSubmit}
              >
                <div className="col-md-5 form-group kt-form__group">
                  <div className="input-group">
                    <TextInput
                      name={'searchText'}
                      value={searchText}
                      className="form-control search-input--text"
                      placeholder="Search for…"
                      onInputChange={handleSearchTextChange}
                    />
                    <div className="input-group-append">
                      <button
                        disabled={!(searchText.length > 0)}
                        className="btn app-btn-primary search-sumbit--button"
                        type="submit"
                      >
                        <i
                          className="fa fa-search"
                          style={{ color: 'inherit' }}
                        />
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={resetSearchResults}
                style={{ marginRight: '100px' }}
              ></button>
            </div>
            <div className="modal-body">
              {loading && (
                <div className={'loading-text--wrap'}>
                  Loading search results…
                  <div className="kt-spinner kt-spinner--success kt-spinner--lg loading-text--spinner" />
                </div>
              )}
              {!loading && (
                <div className="kt-quick-search kt-quick-search--inline kt-quick-search--has-result">
                  <div
                    className="col-md-5 kt-quick-search__wrapper kt-scroll"
                    data-scroll="true"
                    data-height="500"
                    style={{
                      margin: 'auto',
                      maxHeight: 'calc(100vh - 200px)',
                      overflowY: 'auto',
                    }}
                  >
                    <div className="kt-quick-search__result">
                      {renderSearchResults()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

SearchView.propTypes = {
  customRoutePaths: PropTypes.object.isRequired,
};

export default withErrorHandler(SearchView);

import React, { useCallback } from "react";
import TextInput from "../../common/inputs/text";
import EnterKeyListener from '../../common/EnterKeyListener';

const Filters = ({
  nameFilter,
  onNameFilterChange,
  onFilter,
  onClear,
}) => {
  const handleClear = useCallback((e) => {
    e.preventDefault();

    if (onClear) {
      onClear();
    }
  }, [onClear]);

  return (
    <div className="form-group  ml-15 mr-15">
      <EnterKeyListener onPress={onFilter}>
        <div className="row">
          <div className="col-lg-8 m-auto pt-10 pb-10">
            <TextInput
              name="nameFilter"
              placeholder="Search by name"
              value={nameFilter}
              onInputChange={onNameFilterChange}
            />
          </div>
          <div className="col-lg-4 m-auto pt-10 pb-10">
            <button
              className="app-btn-primary m-btn--wide"
              onClick={onFilter}
              style={{ width: '100%' }}
            >
              Filter
            </button>
          </div>
        </div>
      </EnterKeyListener>

      <a href="#" tabIndex={1} onClick={handleClear}>
        Clear search
      </a>
    </div>
  );
}

export default Filters;

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import FormBuilder from 'cb-react-form-builder';
import fscreen from 'fscreen';
import CustomDatePicker from '../custom/CustomDatePicker';
import Toggle from '../../common/inputs/Toggle';
import 'cb-react-form-builder/dist/app.css';
import '../form_styles.scss';
import FormRow from '../../common/presentational/formRow';
import Spinner from '../../common/presentational/spinner';
import defaultToolbarItems from '../defaultToolbarItems';
import CustomAssetManager, {
  customAssetManagerOptions,
} from '../custom/CustomAssetManager';

import 'react-bootstrap-toggle/dist/bootstrap2-toggle.css';
import Http from '../../common/Http';

import CustomTextInput from '../custom/CustomTextInput';
import FormToggleBox from '../custom/FormToggleBox';
import CustomSelectDropDown from '../custom/CustomSelectDropDown';
import SelectInput from '../../common/inputs/select';
import {
  constructOptionsFromRailsSelect,
  removePageLeavePreventDialog,
  addPageLeavePreventDialog,
} from '../../common/utils';
import CustomPhoneInput from '../custom/CustomPhoneInput';
import AssetManager from '../../filestack/AssetManager';
import Modal from '../../filestack/modal';
import TextInput from '../../common/inputs/text';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import TextArea from '../../common/inputs/textArea';
import { DATE_TIME_PICKER_FORMATS } from '../../common/constants';

import {
  STEP_FORM_DETAILS_ID,
  STEP_RESPONSE_MESSAGES_ID,
  STEP_FORM_GENERATOR_ID,
  SUBMIT_BUTTON_STYLE_OPTIONS,
  DEFAULT_SUBMIT_BUTTON_OPTIONS,
  SUBMIT_BUTTON_ELEMENT_IDENTIFIER,
  DEFAULT_RESPONSE_CONFIRMATION_MESSAGES,
} from './constants';

class NewFormGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        task_data: _.filter(props.formData, (data) => !_.isEmpty(data)) || [], // Clean Up any invalid form data
      },
      formName: props.formName || '',
      typeId: props.enableFormTypes ? props.typeId : '',
      // secure: props.secure || false,
      responseConfirmationMessages: !_.isNil(props.responseConfirmationMessages)
        ? props.responseConfirmationMessages
        : DEFAULT_RESPONSE_CONFIRMATION_MESSAGES,
      loading: false,
      logo: [],
      existingLogo: _.compact(_.castArray(props.existingLogo)),
      submitButtonOptions: {
        ...DEFAULT_SUBMIT_BUTTON_OPTIONS,
      },
      isModalOpen: false,
      ...(!_.isUndefined(props.discussionEnabled) && {
        discussionEnabled: props.discussionEnabled || false,
      }),
      isFullScreenEnabled: false,
      isDraftSavingInitialized: false,
      isDraftSaved: false,
    };
  }

  componentDidMount() {
    addPageLeavePreventDialog();
    fscreen.addEventListener('fullscreenchange', this.onFullScreenChange);
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
    fscreen.removeEventListener('fullscreenchange', this.onFullScreenChange);
  }

  onLoad = () => {
    return new Promise((resolve, _reject) => {
      return resolve(
        _.map(this.state.formData.task_data, (item) => {
          let response = {
            ...item,
          };

          if (item.custom) {
            switch (item.element) {
              case 'CustomAssetManager':
                response['component'] = CustomAssetManager;
                response['custom_options'] = customAssetManagerOptions;
                break;
              case 'NameTextInput':
              case 'EmailTextInput':
                response['component'] = CustomTextInput;
                break;
              case 'CustomPhoneInput':
                response['component'] = CustomPhoneInput;
                break;
              case 'FormToggleBox':
                response['component'] = FormToggleBox;
                break;
              case 'DateTime':
              case 'Time':
                response['component'] = CustomDatePicker;
                break;
              case 'CustomMemberDropdown':
                response['component'] = CustomSelectDropDown;
                break;
              case 'CustomProjectDropdown':
                response['component'] = CustomSelectDropDown;
                break;
              case SUBMIT_BUTTON_ELEMENT_IDENTIFIER:
                // Here we do not want to render button, but update button style options
                this.setSubmitButtonOptionsFromFormData(item);
                break;
            }
          }
          return response;
        })
      );
    });
  };

  onPost = (data) => {
    this.setState(
      {
        formData: data,
      },
      () => this.debouncedSaveDraft()
    );
  };

  handleSubmitButtonModal = (value, type = '') => {
    const { submitButtonOptions } = this.state;

    let updatedSubmitButtonOptions = {};

    if (_.isEmpty(submitButtonOptions.text)) {
      if (_.isEqual('done', type)) return;

      // Modal can be closed by clicking anywhere on page but modal
      // Hence, Setting to Submit as default when text field cleared
      // and modal is closed.
      if (_.isEqual('close', type)) {
        updatedSubmitButtonOptions = {
          ...submitButtonOptions,
          text: 'Submit',
        };
      }
    }

    this.setState(
      {
        isModalOpen: value,
        ...(!_.isEmpty(updatedSubmitButtonOptions) && {
          submitButtonOptions: { ...updatedSubmitButtonOptions },
        }),
      },
      () => this.debouncedSaveDraft()
    );
  };

  handleSubmitButtonStyleChange = (attribute, value) => {
    this.setState((prevState) => ({
      submitButtonOptions: {
        ...prevState.submitButtonOptions,
        [attribute]: value,
      },
    }));
  };

  handleFormAttributeChange = (field, value) => {
    this.setState(
      {
        [field]: value,
      },
      () => this.debouncedSaveDraft()
    );
  };

  handleResponseConfirmationMessageChange = (responseImpression, message) => {
    this.setState(
      (prevState) => ({
        responseConfirmationMessages: {
          ...prevState.responseConfirmationMessages,
          [responseImpression]: message,
        },
      }),
      () => this.debouncedSaveDraft()
    );
  };

  onFormLogoChange = (logo, existingLogo) => {
    this.setState(
      {
        logo: logo || [],
        existingLogo: existingLogo || [],
      },
      () => this.debouncedSaveDraft()
    );
  };

  // Update state with data plucked from form data
  setSubmitButtonOptionsFromFormData = (item) => {
    this.setState({
      submitButtonOptions: this.getSubmitButtonOptionsFromFormData(item),
    });
  };

  // Get submit button style options from form data if found
  // else return default options from state
  getSubmitButtonOptionsFromFormData = (item = {}) => {
    const { submitButtonOptions, formData } = this.state;

    let options = { ...submitButtonOptions };
    if (!_.isEmpty(formData.task_data)) {
      let customSubmitButtonData = { ...item };
      if (_.isEmpty(item)) {
        customSubmitButtonData = formData.task_data.find(
          (item) => item.element === SUBMIT_BUTTON_ELEMENT_IDENTIFIER
        );
      }
      if (
        !!customSubmitButtonData &&
        !!customSubmitButtonData.props &&
        !!customSubmitButtonData.props.submitButtonOptions
      ) {
        options = { ...customSubmitButtonData.props.submitButtonOptions };
      }
    }

    return options;
  };

  getSubmitButtonData = () => {
    const { submitButtonOptions } = this.state;
    const submitButtonData = {
      id: 'submit_button_options',
      element: SUBMIT_BUTTON_ELEMENT_IDENTIFIER,
      custom: true,
      props: {
        submitButtonOptions,
      },
      component: null,
    };

    return submitButtonData;
  };

  handleFormSave = async (saveDraft = false) => {
    const {
      formId,
      links: {
        saveProgress: saveProgressLink,
        updateFeedbackForm: updateFeedbackFormLink,
      },
      enableFormTypes,
    } = this.props;
    const {
      formData,
      formName,
      typeId,
      secure,
      logo,
      discussionEnabled,
      responseConfirmationMessages: {
        defaultConfirmationMessage,
        positiveConfirmationMessage,
        negativeConfirmationMessage,
      },
      isDraftSavingInitialized,
    } = this.state;

    // Prevent storing csrf token as part of question data in the feedback form JSON.
    const generatedFormData = _.map(formData.task_data, (questionData) => {
      // If submit button already exists in form data update options from state.
      if (_.isEqual(questionData?.element, SUBMIT_BUTTON_ELEMENT_IDENTIFIER)) {
        questionData.props.submitButtonOptions = { ...this.state.submitButtonOptions };
        return questionData;
      }
      return _.omit(questionData, ['props[csrfToken]']);
    });

    const submitButtonOptionsData = this.getSubmitButtonData();

    const submitButtonOptionsIndex = _.findIndex(generatedFormData, {
      element: SUBMIT_BUTTON_ELEMENT_IDENTIFIER,
    });

    // If no submit button data is found, add it to from data.
    if (submitButtonOptionsIndex === -1) {
      generatedFormData.push(submitButtonOptionsData);
    }

    let Requester = new Http(this);
    if (!saveDraft) {
      Requester.setLoading()
        .setToken(this.props.csrfToken)
        .useAlerts()
        .doesRedirect(true)
        .patch(updateFeedbackFormLink)
        .onSuccess((res) => {
          removePageLeavePreventDialog();
          window.location.href = _.get(
            res,
            'data.meta.redirection_url',
            '/feedback_forms'
          );
        });
    } else {
      Requester.onBegin(() => {
        if (!isDraftSavingInitialized) {
          this.setState({ isDraftSavingInitialized: true });
        } else {
          this.setState({ isDraftSaved: false });
        }
      })
        .patch(saveProgressLink)
        .onSuccess(() => this.setState({ isDraftSaved: true }))
        .onError(() => this.setState({ isDraftSaved: false }));
    }

    await Requester.setPostData({
      feedback_form: {
        name: formName,
        ...(enableFormTypes && !_.isNil(typeId) && { type_id: typeId }),
        // secure,
        logo,
        data: JSON.stringify(generatedFormData) || JSON.stringify([]),
        default_response_confirmation_message: defaultConfirmationMessage,
        positive_response_confirmation_message: positiveConfirmationMessage,
        negative_response_confirmation_message: negativeConfirmationMessage,
        ...(!_.isUndefined(this.props.discussionEnabled) && {
          discussion_enabled: discussionEnabled,
        }),
      },
    }).exec();
  };

  debouncedSaveDraft = _.debounce(() => this.handleFormSave(true), 1000);

  renderModalContent = () => {
    const {
      submitButtonOptions: {
        text: textState,
        color: colorState,
        alignment: alignmentState,
      },
    } = this.state;
    return (
      <React.Fragment>
        <FormRow label="Text *">
          <TextInput
            name={'submitButtonText'}
            value={textState || ''}
            numberAttributes={{
              required: true,
            }}
            onInputChange={({ target }) =>
              this.handleSubmitButtonStyleChange('text', target.value)
            }
          />
          {_.isEmpty(textState) && (
            <span className="form-text text-muted text-darkred">
              Button text cannot be empty!
            </span>
          )}
        </FormRow>
        <FormRow label="Alignment">
          <SelectInput
            placeholder="Select Button Alignment"
            name={'alignment'}
            options={SUBMIT_BUTTON_STYLE_OPTIONS['alignments']}
            value={alignmentState}
            onChange={({ value }) =>
              this.handleSubmitButtonStyleChange('alignment', value)
            }
            optionIdentifier="value"
            isClearable={false}
          />
        </FormRow>
        <FormRow label="Color">
          {_.map(SUBMIT_BUTTON_STYLE_OPTIONS['colors'], (color) => (
            <div
              className="m-1 inline-block p-1"
              key={color}
              style={{
                border: `2px solid ${
                  _.isEqual(colorState, color) ? '#5867dd' : 'transparent'
                }`,
              }}
            >
              <button
                className={`btn btn-${color}`}
                onClick={() => this.handleSubmitButtonStyleChange('color', color)}
              >
                {_.capitalize(color)}
              </button>
            </div>
          ))}
        </FormRow>
        <br />
        <div>
          <h5>Preview</h5>
          {this.renderCustomSubmitButtonPreview()}
        </div>
      </React.Fragment>
    );
  };

  renderCustomSubmitButtonPreview = () => {
    const {
      submitButtonOptions: { text, color, alignment },
    } = this.state;
    return (
      <div className={`d-flex justify-content-${alignment} kt-demo p-5 w-100`}>
        <button className={`btn btn-lg btn-big btn-${color}`}>{text}</button>
      </div>
    );
  };

  renderModalFooter = () => {
    const {
      submitButtonOptions: { text },
    } = this.state;

    const canSubmit = !_.isEmpty(text);

    return (
      <button
        className={`app-btn-primary my-3 ${!canSubmit ? 'cursor-not-allowed' : ''}`}
        disabled={!canSubmit}
        onClick={() => this.handleSubmitButtonModal(!canSubmit, 'done')}
      >
        Done
      </button>
    );
  };

  onFullScreenChange = () =>
    this.setState({ isFullScreenEnabled: !!fscreen.fullscreenElement });

  toggleFullScreen = () => {
    this.state.isFullScreenEnabled
      ? fscreen.exitFullscreen()
      : fscreen.requestFullscreen(document.querySelector('.full-screen-form-generator'));
  };

  render() {
    const {
      feedbackFormTypeOptions,
      tenantTerms,
      csrfToken,
      logoOptions,
      enableLogoSection,
      enableConfirmationMessagesSection,
      enableFormTypes,
      disabledFieldElements,
      discussionEnabled: discussionEnabledProp,
      courseForumEnabled,
      activeFormStep,
      lastFormStepId,
    } = this.props;
    const {
      loading,
      formData,
      formName,
      typeId,
      secure,
      discussionEnabled,
      logo,
      existingLogo,
      isModalOpen,
      responseConfirmationMessages,
      submitButtonOptions: { text },
      isFullScreenEnabled,
      isDraftSaved,
      isDraftSavingInitialized,
    } = this.state;
    const { term_project } = tenantTerms;

    const showLogoUploader = !(existingLogo.length || logo.length);
    const formType = !!typeId && _.find(feedbackFormTypeOptions, { id: typeId });
    const formTypeHasSentimentAnalysis = formType?.process_sentiment_analysis || false;
    const requiredConfirmationMessages = formTypeHasSentimentAnalysis
      ? _.omit(responseConfirmationMessages, 'defaultConfirmationMessage')
      : _.pick(responseConfirmationMessages, 'defaultConfirmationMessage');

    return (
      <div>
        {loading && <Spinner />}

        {isModalOpen && (
          <Modal
            title={'Edit Submit Button Styles'}
            onClose={() => this.handleSubmitButtonModal(false, 'close')}
            renderContent={this.renderModalContent}
            renderFooter={this.renderModalFooter}
            hideClose={_.isEmpty(text)}
          />
        )}
        <div className="text-success text-right mb-3 mt-2">
          {isDraftSavingInitialized ? (
            isDraftSaved ? (
              <p className="m-0">
                <i className="far fa-check-circle mr-2"></i>Draft Saved
              </p>
            ) : (
              <div className="d-flex align-items-center justify-content-end">
                <div className="spinner-border spinner-border-sm mr-2" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <p className="m-0"> Saving Draft...</p>
              </div>
            )
          ) : null}
        </div>
        <div className="d-flex justify-content-end">
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              {activeFormStep === lastFormStepId && (
                <button
                  disabled={
                    _.isEmpty(formName) ||
                    _.isEmpty(formData) ||
                    _.isEmpty(formData.task_data)
                  }
                  onClick={() => this.handleFormSave()}
                  className="btn btn-label-primary btn-bold btn-icon-h"
                >
                  <span>
                    <i className="la la-save" />
                    <span>Publish</span>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="kt-content">
          <div className="kt-portlet kt-portlet--bordered">
            <div className="kt-portlet__body edit-form">
              {activeFormStep === STEP_FORM_DETAILS_ID && (
                <div>
                  <div className="form-group kt-form__group">
                    <label>
                      Form Name<span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      name="formName"
                      type="text"
                      value={formName}
                      onChange={({ target }) =>
                        this.handleFormAttributeChange('formName', target['value'])
                      }
                      className="form-control"
                      placeholder="Enter Form Name"
                    />
                  </div>
                  {enableFormTypes && (
                    <FormRow label={'Form Type'} stackedLabel>
                      <SelectInput
                        name={'typeId'}
                        placeholder={'Select Form Type...'}
                        options={feedbackFormTypeOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        optionIdentifier="id"
                        value={typeId}
                        onChange={({ id }) =>
                          this.handleFormAttributeChange('typeId', id)
                        }
                        noOptionsMessage={() => 'No form types found'}
                      />
                    </FormRow>
                  )}

                  {/* <FormRow label={'Secure'}>
                  <Toggle
                    name="secure"
                    onClick={value => this.handleFormAttributeChange('secure', value)}
                    on={<span>Secure</span>}
                    off={<span>Not Secure</span>}
                    size="sm"
                    width={100}
                    height={30}
                    recalculateOnResize
                    offstyle="default"
                    active={secure}
                  />
                </FormRow> */}

                  {!_.isUndefined(discussionEnabledProp) && courseForumEnabled && (
                    <FormRow label={'Discussion Enabled?'}>
                      <Toggle
                        name={'discussionEnabled'}
                        onClick={(value) =>
                          this.handleFormAttributeChange('discussionEnabled', value)
                        }
                        on={<span>Yes</span>}
                        off={<span>No</span>}
                        size="sm"
                        width={100}
                        height={30}
                        recalculateOnResize
                        offstyle="default"
                        active={discussionEnabled}
                      />
                    </FormRow>
                  )}

                  {enableLogoSection && (
                    <FormRow label={`${showLogoUploader ? '' : 'Public Form Logo'}`}>
                      <AssetManager
                        label={'Logo'}
                        csrfToken={csrfToken}
                        filestackOptions={logoOptions}
                        existingAssets={existingLogo || []}
                        assets={logo || []}
                        onFileChange={this.onFormLogoChange}
                        showUploader={showLogoUploader}
                      />
                    </FormRow>
                  )}

                  <FormRow label={'Submit Button Style'}>
                    <button
                      className="btn btn-sm btn-label-info mb-10"
                      onClick={() => this.handleSubmitButtonModal(true)}
                    >
                      <i className="la la-edit" /> Edit Style
                    </button>
                  </FormRow>
                </div>
              )}

              {activeFormStep === STEP_RESPONSE_MESSAGES_ID &&
                enableConfirmationMessagesSection && (
                  <React.Fragment>
                    <h3>Response Confirmation Messages</h3>
                    {_.map(
                      requiredConfirmationMessages,
                      (message, responseImpression) => (
                        <FormRow
                          label={`${_.startCase(responseImpression)} *`}
                          key={responseImpression}
                        >
                          <TextArea
                            name={responseImpression}
                            placeholder={'Your Response Has Been Recorded Succesfully!'}
                            value={message}
                            required={true}
                            onInputChange={({ target }) =>
                              this.handleResponseConfirmationMessageChange(
                                responseImpression,
                                target.value
                              )
                            }
                          />
                        </FormRow>
                      )
                    )}
                  </React.Fragment>
                )}

              {activeFormStep === STEP_FORM_GENERATOR_ID && (
                <div
                  className={`full-screen-form-generator${
                    isFullScreenEnabled ? ' full-screen-mode' : ''
                  }`}
                >
                  <div className="mb-2">
                    <button className="app-btn-secondary" onClick={this.toggleFullScreen}>
                      {isFullScreenEnabled ? (
                        <Fragment>
                          <i className="fas fa-compress"></i> Exit Full Screen
                        </Fragment>
                      ) : (
                        <Fragment>
                          <i className="fas fa-expand"></i> Full Screen
                        </Fragment>
                      )}
                    </button>
                  </div>
                  <FormBuilder.ReactFormBuilder
                    onPost={this.onPost}
                    onLoad={this.onLoad}
                    variables={{}}
                    toolbarItems={defaultToolbarItems}
                    customToolbarItems={[
                      {
                        key: 'DateTime',
                        name: 'Date Time',
                        label: 'Label',
                        icon: 'la la-calendar-check-o',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                          className: 'form-control',
                          showTimeSelect: true,
                          timeFormat: 'HH:mm',
                          timeIntervals: 15,
                          dateFormat: `${DATE_TIME_PICKER_FORMATS.date} h:mm aa`,
                          timeCaption: 'time',
                          placeholderText: `${DATE_TIME_PICKER_FORMATS.date} HH:MM:SS`,
                          dropdownMode: 'scroll',
                        },
                        component: CustomDatePicker,
                        field_name: 'date_time_',
                        custom_options: [],
                      },
                      {
                        key: 'Time',
                        name: 'Time',
                        label: 'Label',
                        icon: 'la la-clock-o',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                          className: 'form-control',
                          showTimeSelect: true,
                          showTimeSelectOnly: true,
                          timeIntervals: 15,
                          timeCaption: 'Time',
                          dateFormat: 'h:mm aa',
                          placeholderText: 'HH:MM',
                          dropdownMode: 'scroll',
                        },
                        component: CustomDatePicker,
                        field_name: 'time_',
                        custom_options: [],
                      },
                      {
                        key: 'CustomAssetManager',
                        name: 'Attachments',
                        label: 'Attachments',
                        icon: 'fa fa-file',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                        },
                        component: CustomAssetManager,
                        field_name: 'attachments_',
                        custom_options: customAssetManagerOptions,
                      },
                      {
                        key: 'NameTextInput',
                        name: 'Name',
                        label: 'Name',
                        icon: 'fa fa-edit',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                        },
                        component: CustomTextInput,
                        field_name: 'name_',
                        custom_options: [],
                      },
                      {
                        key: 'EmailTextInput',
                        name: 'Email',
                        label: 'Email',
                        icon: 'fa fa-envelope',
                        type: 'custom',
                        props: {
                          inputType: 'email',
                          csrfToken: this.props.csrfToken,
                        },
                        component: CustomTextInput,
                        field_name: 'email_',
                        custom_options: [],
                      },
                      {
                        key: 'CustomPhoneInput',
                        name: 'Phone',
                        label: 'Phone',
                        icon: 'fa fa-phone',
                        type: 'custom',
                        props: {},
                        component: CustomPhoneInput,
                        field_name: 'phone_',
                        custom_options: [],
                      },
                      {
                        key: 'FormToggleBox',
                        name: 'Toggle',
                        label: 'Label',
                        icon: 'la la-toggle-on',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                        },
                        component: FormToggleBox,
                        field_name: 'toggle_',
                        custom_options: [],
                      },
                      {
                        key: 'CustomMemberDropdown',
                        name: 'Members',
                        label: 'Label',
                        icon: 'la la-users',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                        },
                        component: CustomSelectDropDown,
                        field_name: 'custom_member_',
                        custom_options: [],
                      },
                      {
                        key: 'CustomProjectDropdown',
                        name: `${term_project.plural}`,
                        label: 'Label',
                        icon: 'fa fa-network-wired',
                        type: 'custom',
                        props: {
                          csrfToken: this.props.csrfToken,
                        },
                        component: CustomSelectDropDown,
                        field_name: 'custom_project_',
                        custom_options: [],
                      },
                    ].filter(
                      (toolbarItems) => !disabledFieldElements.includes(toolbarItems.key)
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewFormGenerator.defaultProps = {
  formData: [],
  enableLogoSection: true,
  enableConfirmationMessagesSection: true,
  disabledFieldElements: [],
  isCourseFeedbackForm: false,
};

NewFormGenerator.propTypes = {
  formId: PropTypes.number,
  formData: PropTypes.array,
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.object.isRequired,
  links: PropTypes.shape({
    createFeedbackForm: PropTypes.string,
    updateFeedbackForm: PropTypes.string,
  }).isRequired,
  enableLogoSection: PropTypes.bool,
  enableConfirmationMessagesSection: PropTypes.bool,
  enableFormTypes: PropTypes.bool.isRequired,
  isCourseFeedbackForm: PropTypes.bool,
  disabledFieldElements: PropTypes.array,
  lastFormStepId: PropTypes.number.isRequired,
};

export default withErrorHandler(NewFormGenerator);

import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Http from '../../common/Http';
import { alertSuccessNotifications } from '../../folders/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import AttachmentPreview from '../../common/AttachmentPreview/Attachments';
import TopicModal from './TopicModal';

const Topic = ({
  topic,
  csrfToken,
  afterDelete,
  afterUpdate,
  isActive,
  setActiveTopicId,
  topicAttachmentsConfig,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id, question, answer, links, attachments } = topic;

  const handleDeleteTopic = () => {
    const confirmDelete = confirm(
      'Are you sure you want to delete? This action is irreversible!'
    );
    if (!confirmDelete) return;

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .delete(links.delete)
      .useAPIDataFormatters({
        camelizeResponseData: true,
      })
      .onSuccess(() => {
        afterDelete(id);
        setLoading(false);
        alertSuccessNotifications('Deleted Successfully');
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  return (
    <Fragment>
      <div className={`topic-item my-4 ${isActive ? 'active' : ''}`}>
        <div className="topic-header d-flex align-items-center justify-content-between p-3">
          <div
            className="topic-question cursor-pointer"
            onClick={() => setActiveTopicId(id)}
          >
            <h5 className="m-0">
              <i className="arrow-icon fa fa-arrow-right mr-3" />
              {question}
            </h5>
          </div>
          <div className="topic-options">
            <button
              className="app-btn-primary btn-sm btn-icon m-1"
              onClick={() => setIsEditModalOpen(true)}
              disabled={loading}
            >
              <i className="fa fa-edit" />
            </button>
            <button
              className="app-btn-primary btn-sm btn-icon m-1"
              onClick={handleDeleteTopic}
              disabled={loading}
            >
              <i className="fa fa-trash" />
            </button>
          </div>
        </div>
        <div className="topic-answer text-dark font-14">
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: `${answer}` }}
          />
          {!isEmpty(attachments) && (
            <div className="mt-3" style={{ maxWidth: '700px' }}>
              <AttachmentPreview attachments={attachments} />
            </div>
          )}
        </div>
      </div>
      {isEditModalOpen && (
        <TopicModal
          title="Edit Topic"
          onModalClose={() => setIsEditModalOpen(false)}
          link={links.update}
          topicData={{
            question,
            answer,
            attachments,
          }}
          handleOnSuccess={({ data }) => afterUpdate(data.topic)}
          csrfToken={csrfToken}
          topicAttachmentsConfig={topicAttachmentsConfig}
        />
      )}
    </Fragment>
  );
};

Topic.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    links: PropTypes.shape({
      update: PropTypes.string.isRequired,
      delete: PropTypes.string,
    }).isRequired,
    attachments: PropTypes.array,
  }).isRequired,
  afterDelete: PropTypes.func,
  afterUpdate: PropTypes.func,
  isActive: PropTypes.bool,
  setActiveTopicId: PropTypes.func,
  topicAttachmentsConfig: PropTypes.object,
};

export default withErrorHandler(memo(Topic));

import React, { useCallback, useMemo } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import debounce from "lodash/debounce";

import TextInput from '../common/inputs/text';
import MemberTableItem from './MemberTableItem';
import Http from '../common/Http';
import EnterKeyListener from '../common/EnterKeyListener';
import Spinner from '../common/presentational/spinner';
import SelectInput from '../common/inputs/select';
import { withErrorHandler } from '../hoc/withErrorHandler';
import PaginationWrapper from '../hoc/PaginationWrapper';

const MembersShowPage = props => {
  const {
    roles,
    customRoles,
    csrfToken,
    showStores = false,
    showCustomRoles = false,
    showBusinessId = false,
    storesPath,
  } = props;
  const [members, setMembers] = React.useState([]);
  const [paginationObject, setPaginationObject] = React.useState({});
  const [hasCompanyName, setHasCompanyName] = React.useState(false);
  const [companyOptions, setCompanyOptions] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [nameSearch, setNameSearch] = React.useState('');
  const [emailSearch, setEmailSearch] = React.useState('');
  const [selectedCompany, setSelectedCompany] = React.useState('');
  const [selectedRoles, setSelectedRoles] = React.useState([]);
  const [selectedStores, setSelectedStores] = React.useState([]);
  const [selectedCustomRoles, setSelectedCustomRoles] = React.useState([]);

  const allCompanyName = React.useMemo(
    () =>
      members.reduce((acc, m) => {
        if (m.companyName && !acc.includes(m.companyName)) {
          acc.push(m.companyName);
        }
        return acc;
      }, []),
    [members]
  );

  const renderTableHeader = useCallback(() => (
    <tr>
      <th className="pl-15 td-200">
        User Info
      </th>
      {hasCompanyName && <th>Company Name</th>}
      <th className="td-180">Last Active At</th>
      <th>Roles</th>
      {showBusinessId && <th className="td-110">Employee ID</th>}
      {showStores && <th>Stores</th>}
      {showCustomRoles && <th>Business Roles</th>}
      <th className="td-110">Actions</th>
    </tr>
  ), [hasCompanyName, showStores]);

  const fetchData = React.useCallback(
    async (pageNum = 1) => {
      const { csrfToken } = props;

      const newFilterOptions = {
        name: nameSearch,
        email: emailSearch,
        company: selectedCompany,
        roles: selectedRoles.map(r => r.id),
        stores: (selectedStores || []).map(r => r.value),
        custom_roles: selectedCustomRoles.map(r => r.id),
      };

      await new Http()
        .onBegin(() => setLoading(true))
        .get(`${window.location.pathname}`)
        .setQueryParams({
          page: pageNum,
          ...newFilterOptions,
        })
        .setToken(csrfToken)
        .onSuccess(res => {
          const { data } = res;
          setMembers(data.members);
          setPaginationObject(data.meta.pagination);
          setLoading(false);
        })
        .onError(() => setLoading(false))
        .exec();
    },

    // whole props don't need to affect the callBack, So removed the props from depArray.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      nameSearch,
      emailSearch,
      selectedCompany,
      selectedRoles,
      selectedStores,
      selectedCustomRoles,
    ]
  );

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (members.length > 0 && !isNil(members[0]['companyName'])) {
      setHasCompanyName(true);
      let companyOptionsArray = allCompanyName.map(i => ({
        label: i,
        value: i,
      }));
      setCompanyOptions(companyOptionsArray);
    }
  }, [allCompanyName, members]);

  const loadStores = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`${storesPath}?keyword=${inputValue}`)
      .onSuccess(response => {
        const { stores = [] } = response.data;
        callback(stores);
      })
      .onError(err => {
        console.log(err);
        callback([]);
      })
      .exec();
  }, [csrfToken, storesPath]);

  const debouncedLoadStores = useMemo(() => debounce(loadStores, 500), [loadStores]);

  const getFilteredMembers = () => {
    fetchData();
  };

  const handleNameChange = event => {
    const { target } = event;
    setNameSearch(target.value);
  };

  const handleEmailChange = event => {
    const { target } = event;
    setEmailSearch(target.value);
  };

  const handleActiveInactive = url => async () => {
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .post(url)
      .doesRedirect(true)
      .onSuccess(() => {
        setLoading(false);
        window.location = window.location.href;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handleResend = url => async () => {
    await new Http(this)
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAlerts()
      .put(url)
      .doesRedirect(true)
      .onSuccess(() => {
        setLoading(false);
        window.location = window.location.href;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handleCompanySelect = val => setSelectedCompany(val.value);

  const handleRolesSelect = val => setSelectedRoles(val);

  const handleCustomRolesSelect = val => setSelectedCustomRoles(val);

  const handleMemberDeletion = deletionURL => async () => {
    const proceed = confirm(
      'This will delete the user permanently and cannot be undone. Are you sure, you want to delete?'
    );
    if (proceed) {
      await new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .useAlerts()
        .doesRedirect(true)
        .delete(deletionURL)
        .onSuccess(response => {
          setLoading(false);
          window.location =
            response.data.redirection_url || window.location.href;
        })
        .onError(() => setLoading(false))
        .exec();
    }
  };

  const renderPagination = () => {
    if (
      !isEmpty(members) &&
      !isEmpty(paginationObject) &&
      paginationObject.totalPages > 1
    ) {
      return (
        <PaginationWrapper
          paginationObject={paginationObject}
          fetchData={fetchData}
        />
      );
    }
    return <br />;
  };

  const renderEmptyMessage = (memberArray, nameSearch) => {
    if (isEmpty(memberArray) && isEmpty(nameSearch)) {
      return (
        <tr>
          <td colSpan="8" className={'text-center p-4'}>
            <h4>No Members present.</h4>
          </td>
        </tr>
      );
    } else if (isEmpty(memberArray) && !isEmpty(nameSearch)) {
      return (
        <tr>
          <td colSpan="8" className={'text-center p-4'}>
            <h4>No Members found for the filter.</h4>
          </td>
        </tr>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <EnterKeyListener onPress={fetchData}>
        <div className="form-group row ml-15 mr-15">
          <div className="col-lg-4 m-auto pt-10 pb-10">
            <TextInput
              placeholder={'Search members by name'}
              name={'nameSearch'}
              value={nameSearch}
              onInputChange={handleNameChange}
            />
          </div>

          <div className="col-lg-4 m-auto pt-10 pb-10">
            <TextInput
              placeholder={'Search members by email'}
              name={'emailSearch'}
              value={emailSearch}
              onInputChange={handleEmailChange}
            />
          </div>

          {hasCompanyName && (
            <div className="col-lg-4 m-auto pt-10 pb-10">
              <SelectInput
                value={selectedCompany}
                options={companyOptions}
                optionIdentifier="value"
                placeholder={'Select a Company'}
                onChange={handleCompanySelect}
              />
            </div>
          )}

          <div className="col-lg-4 m-auto pt-10 pb-10">
            <SelectInput
              value={selectedRoles}
              options={roles}
              placeholder={'Select a Role'}
              getOptionLabel={option => option['name']}
              getOptionValue={option => option['id']}
              onChange={handleRolesSelect}
              isClearable
              isMulti
            />
          </div>


          {
            showCustomRoles && (
              <div className="col-lg-4 m-auto pt-10 pb-10">
                <SelectInput
                  value={selectedCustomRoles}
                  options={customRoles}
                  placeholder={'Select a Business Role'}
                  getOptionLabel={option => option['name']}
                  getOptionValue={option => option['id']}
                  onChange={handleCustomRolesSelect}
                  isClearable
                  isMulti
                />
              </div>
            )
          }

          {
            showStores && (
              <div className="col-lg-4 m-auto pt-10 pb-10">
                <AsyncSelect
                  name="stores-selector"
                  placeholder="Search / Select Stores"
                  noOptionsMessage={() => "No stores found"}
                  loadOptions={debouncedLoadStores}
                  getOptionLabel={option => `#${option.value}`}
                  getOptionValue={option => option.id}
                  onChange={setSelectedStores}
                  defaultOptions
                  cacheOptions
                  isClearable
                  isMulti
                />
              </div>
            )
          }

          <div className="col-lg-4 m-auto pt-10 pb-10">
            <button
              className="app-btn-primary m-btn--wide"
              onClick={getFilteredMembers}
              style={{ width: '100%' }}
            >
              Filter
            </button>
          </div>
        </div>
      </EnterKeyListener>

      {renderPagination()}
      <br />

      <div className="kt-section">
        <div className="kt-section__content">
          <div className="table-responsive">
            <table
              className="table table-striped kt-table__row-equal-width members"
              style={{ minWidth: hasCompanyName ? 900 : 800 }}
            >
              <thead>{renderTableHeader(hasCompanyName)}</thead>

              <tbody>
                {map(members, member => {
                  return (
                    <MemberTableItem
                      hasCompanyName={hasCompanyName}
                      key={member.id}
                      member={member}
                      onActiveInactive={handleActiveInactive}
                      onResend={handleResend}
                      onDelete={handleMemberDeletion}
                      roles={roles}
                      csrfToken={csrfToken}
                      showStores={showStores}
                      showCustomRoles={showCustomRoles}
                      showBusinessId={showBusinessId}
                    />
                  );
                })}
                {renderEmptyMessage(members, nameSearch)}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {renderPagination()}
    </React.Fragment>
  );
};

MembersShowPage.propTypes = {
  roles: PropTypes.array.isRequired,
  customRoles: PropTypes.array.isRequired,
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  showStores: PropTypes.bool,
  showCustomRoles: PropTypes.bool,
};

export default withErrorHandler(MembersShowPage);

import React from 'react';
import PropTypes from 'prop-types';

import reject from 'lodash/reject';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import countBy from 'lodash/countBy';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import map from 'lodash/map';
import values from 'lodash/values';
import toInteger from 'lodash/toInteger';

import { IMPRESSION_MAP, getImpressionEmojiLink } from './constants';
import { withErrorHandler } from '../hoc/withErrorHandler';
import './impression_styles.scss';

const ImpressionsOverview = ({ userResponses }) => {
  const responsesWithImpressions = reject(userResponses, response =>
    isEmpty(response.impression)
  );

  const uniqueImpressionsCount = size(IMPRESSION_MAP);
  const recordedImpressionsCount = size(responsesWithImpressions);
  const isRecordedImpressionsPresent = recordedImpressionsCount !== 0;

  const impressionsCounter = countBy(
    responsesWithImpressions,
    response => IMPRESSION_MAP[response.impression]
  );

  const impressionsOverview = isRecordedImpressionsPresent
    ? reduce(
        impressionsCounter,
        (overviewConfig, impressionCount, impression) => {
          overviewConfig[impression] = round(
            (impressionCount / recordedImpressionsCount) * 100
          );
          return overviewConfig;
        },
        {}
      )
    : {};

  return (
    isRecordedImpressionsPresent && (
      <div className="row ml-15 mr-15 pt-10 impressions-overview-block">
        {/*
        Using Values of Impression Map to preserve order
      */}
        {map(values(IMPRESSION_MAP), impression => {
          const impressionPercentage = toInteger(
            impressionsOverview[impression]
          );
          const blockSize = 12 / uniqueImpressionsCount;

          return (
            <div
              className={`col-md-${blockSize} col-sm-${blockSize} mb-4 impression-holder`}
              key={impression}
            >
              <img src={getImpressionEmojiLink(impression)} className="image" />
              <div className="percent text-info">{`${impressionPercentage} %`}</div>
            </div>
          );
        })}
      </div>
    )
  );
};

ImpressionsOverview.propTypes = {
  userResponses: PropTypes.array,
};

ImpressionsOverview.defaultProps = {
  userResponses: [],
};

export default withErrorHandler(ImpressionsOverview);

import isPlainObject from 'lodash/isPlainObject';
import snakeCaseKeys from '../../dataFormatters/snakeCase';

const DEFAULT_SNAKIFIER_OPTIONS = Object.freeze({
  exclude: [],
  deep: true,
});

export const requestDataSnakifier =
  (formattingOptions = {}) =>
  requestConfig => ({
    ...requestConfig,
    ...(isPlainObject(requestConfig.data) && {
      data: snakeCaseKeys(requestConfig.data, {
        ...DEFAULT_SNAKIFIER_OPTIONS,
        ...formattingOptions,
      }),
    }),
    ...(isPlainObject(requestConfig.params) && {
      params: snakeCaseKeys(requestConfig.params, {
        ...DEFAULT_SNAKIFIER_OPTIONS,
        ...formattingOptions,
      }),
    }),
  });

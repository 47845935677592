import React, { useCallback, useMemo } from "react";
import AsyncSelect from 'react-select/async';
import debounce from "lodash/debounce";

import Http from "../../common/Http";
import Select from '../../common/inputs/select';

const statuses = {
  'None': null,
  'Active': 'active',
  'Expired': 'expired',
};

const Filters = ({
  csrfToken,
  usersPath,
  certificationsPath,
  setSelectedUser,
  setSelectedCertification,
  selectedStatus,
  setSelectedStatus,
}) => {
  const loadUsers = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`${usersPath}?name=${inputValue}`)
      .onSuccess(response => {
        const { members = [] } = response.data;
        callback(members);
      })
      .onError(err => {
        console.log(err);
        callback([]);
      })
      .exec();
  }, [csrfToken, usersPath]);

  const loadCertifications = useCallback((inputValue, callback) => {
    new Http()
      .setToken(csrfToken)
      .get(`${certificationsPath}?name=${inputValue}`)
      .onSuccess(response => {
        const { certifications = [] } = response.data;
        callback(certifications);
      })
      .onError(err => {
        console.log(err);
        callback([]);
      })
      .exec();
  }, [csrfToken, certificationsPath]);

  const debouncedLoadUsers = useMemo(() => debounce(loadUsers, 500), [loadUsers]);
  const debouncedLoadCertifications = useMemo(() => debounce(loadCertifications, 500), [loadCertifications]);

  return (
    <div className="kt-section__content">
      <div className="form-group kt-form__group filters-select-row ml-15 mr-15">
        <div className="col-md-4">
          <AsyncSelect
            name="user-selector"
            placeholder="Search User"
            noOptionsMessage={() => "No users found"}
            loadOptions={debouncedLoadUsers}
            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
            getOptionValue={option => option.id}
            onChange={setSelectedUser}
            defaultOptions
            cacheOptions
            isClearable
          />
        </div>
        <div className="col-md-4">
          <AsyncSelect
            name="certification-selector"
            placeholder="Search Certification"
            noOptionsMessage={() => "No certifications found"}
            loadOptions={debouncedLoadCertifications}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            onChange={setSelectedCertification}
            defaultOptions
            cacheOptions
            isClearable
          />
        </div>
        <div className="col-md-4">
          <Select
            name="status"
            options={
              Object.keys(statuses).map((statusKey) => ({
                label: statusKey,
                value: statuses[statusKey],
              }))
            }
            value={selectedStatus}
            onChange={(val) => setSelectedStatus(val.value)}
            placeholder="Select Status"
            optionIdentifier="value"
            isClearable
          />
        </div>
      </div>
    </div>
  );
}

export default Filters;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormRow from '../common/presentational/formRow';
import AssetManager from '../filestack/AssetManager';
import { removePageLeavePreventDialog } from '../common/utils';
import { getAssetAttributes } from '../filestack/utils/getAssetAttributes';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';

const IMPORT_STATUS_COLORS = Object.freeze({
  completed: 'success',
  completed_with_errors: 'warning',
  failed: 'danger',
});

class BulkResourceCSVForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attachments: [],
      existingAttachments: [],
      loading: false,
      isCustomersImport: _.isEqual('Customers', props.resourceTerm),
      ...(_.isEqual('Customers', props.resourceTerm) && {
        importInProgress: false,
        importStatusInfo: null,
      }),
    };
  }

  onFileChange = (attachments, existingAttachments) => {
    this.setState({
      attachments: attachments,
      existingAttachments: existingAttachments,
    });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      csrfToken,
      data: {
        links: { createBulkResourceRecord },
      },
      paramsRootKey,
      onImportCompletion,
      isCustomerGroupForm,
    } = this.props;

    const { isCustomersImport } = this.state;

    const { attachments } = this.state;
    const assetAttributes = getAssetAttributes(attachments);

    if (isCustomersImport) {
      this.setState({
        importInProgress: true,
      });
    }

    let Request = await new Http(this)
      .setToken(csrfToken)
      .useAlerts()
      .post(createBulkResourceRecord, {
        [paramsRootKey]: {
          attachments: assetAttributes,
        },
        ...(isCustomerGroupForm && {
          include_customers: true,
        }),
      })
      .onSuccess(({ data }) => {
        removePageLeavePreventDialog();
        if (isCustomersImport) {
          this.setState(
            {
              attachments: [],
              importInProgress: false,
              importStatusInfo: {
                status: data.status,
                description: data.description,
                file_link: data.file_link,
                redirection_url: !!data.meta ? data.meta.redirection_url : '',
              },
            },
            () => {
              if (!_.isNil(onImportCompletion)) {
                onImportCompletion(data.imported_customer_ids, data.customers);
              }
            }
          );
        }

        if (!isCustomerGroupForm && !_.isNil(data.meta)) {
          window.location.href = data.meta.redirection_url;
        }
      });

    if (!isCustomersImport) {
      Request.setLoading().doesRedirect(true);
    }

    Request.exec();
  };

  renderReuploadCSVButton = ({ onPick }) => (
    <div className="m-4 d-flex justify-content-center">
      <div onClick={onPick} className="btn btn-warning">
        Re-upload {this.props.resourceTerm} CSV
      </div>
    </div>
  );

  renderCSVUploadCard = ({ onPick }) => {
    const { resourceTerm } = this.props;

    return (
      <div className="row d-flex justify-content-center m-4">
        <div
          className="card project-card col-md-5 col-sm-12 cursor-pointer mb-0"
          onClick={onPick}
        >
          <div className="project-card__body">
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <img
                src={'https://mentor-assets.s3.amazonaws.com/diamond.png'}
                alt="new"
                width="100"
                height="100"
                className="mb-10"
              />
              <h5 className="pt-10" style={{ color: '#4c4b4b' }}>
                Import {resourceTerm} from CSV
              </h5>
            </div>
          </div>
          <div
            className="project-card__foot mt-0 kt-font-bolder"
            style={{ borderTop: 0, color: '#6c757d' }}
          >
            Upload a CSV and quickly import {resourceTerm}.
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      csrfToken,
      data: {
        attachmentOptions,
        links: {
          downloadSampleCSV: downloadSampleCSVLink,
          downloadStateCountryCSV: downloadStateCountryCSVLink,
        },
      },
    } = this.props;

    const { isCustomersImport, importInProgress } = this.state;

    const { attachments, existingAttachments, loading, importStatusInfo } = this.state;

    const hasAttachments = !_.isEmpty(attachments);

    const shouldReuploadCSV =
      isCustomersImport &&
      !_.isNil(importStatusInfo) &&
      !_.isEmpty(importStatusInfo.file_link);

    return (
      <React.Fragment>
        {loading && <Spinner />}

        <div className="d-flex flex-column align-items-center">
          {!_.isNil(importStatusInfo) && !hasAttachments && (
            <h3 className={`kt-font-${IMPORT_STATUS_COLORS[importStatusInfo.status]}`}>
              <strong>{_.upperCase(importStatusInfo.status)}:</strong>
              <span className="pl-15">{importStatusInfo.description}</span>
            </h3>
          )}
          <AssetManager
            label={''}
            wrapperClassName={
              hasAttachments ? 'text-center d-flex justify-content-center' : ''
            }
            csrfToken={csrfToken}
            filestackOptions={attachmentOptions}
            showGallery={true}
            showUploader={!hasAttachments}
            noAssetOptions={!!importInProgress}
            existingAssets={existingAttachments}
            assets={attachments}
            onFileChange={this.onFileChange}
            renderUploader={
              shouldReuploadCSV ? this.renderReuploadCSVButton : this.renderCSVUploadCard
            }
            showLabel={false}
          />
          {!hasAttachments ? (
            shouldReuploadCSV ? (
              <a href={importStatusInfo.file_link} className="my-2 btn btn-outline-info">
                <i className="fa fa-download mr-2" />
                Download Error CSV File
              </a>
            ) : (
              <React.Fragment>
                <a href={downloadSampleCSVLink} className="my-2">
                  <i className="fa fa-download mr-2" />
                  Download Sample CSV
                </a>
                {!_.isEmpty(downloadStateCountryCSVLink) && (
                  <a href={downloadStateCountryCSVLink} className="my-2">
                    <i className="fa fa-download mr-2" />
                    Download State Country CSV
                  </a>
                )}
              </React.Fragment>
            )
          ) : (
            <button
              className={`btn app-btn-primary my-2 ${
                !!importInProgress ? 'kt-spinner kt-spinner--right kt-spinner--light' : ''
              }`}
              onClick={this.handleSubmit}
              disabled={!!importInProgress}
            >
              {!!importInProgress ? 'Importing' : 'Import Now'}
            </button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

BulkResourceCSVForm.propTypes = {
  data: PropTypes.shape({
    attachmentOptions: PropTypes.object.isRequired,
    existingAttachments: PropTypes.array,
    links: PropTypes.shape({
      createBulkResourceRecord: PropTypes.string.isRequired,
      downloadSampleCSV: PropTypes.string.isRequired,
      downloadStateCountryCSV: PropTypes.string,
    }).isRequired,
  }),
  csrfToken: PropTypes.string.isRequired,
  paramsRootKey: PropTypes.string.isRequired,
  resourceTerm: PropTypes.string.isRequired,
  isCustomerGroupForm: PropTypes.bool,
  onImportCompletion: PropTypes.func,
};

BulkResourceCSVForm.defaultProps = {
  isCustomerGroupForm: false,
  onImportCompletion: null,
};

export default withErrorHandler(BulkResourceCSVForm);

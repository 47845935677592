import React, { Component } from 'react';

class CallTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callDuration: 0,
      startTime: Date.now(),
    };
    this.timer = null;
  }

  componentDidMount() {
    this.timer = setInterval(
      () =>
        this.setState({
          callDuration: Date.now() - this.state.startTime,
        }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getReadableCallDuration = (milliseconds = 0) => {
    let inSeconds = milliseconds / 1000;

    const hours = Math.floor((inSeconds %= 86400) / 3600);
    const minutes = Math.floor((inSeconds %= 3600) / 60);
    const seconds = inSeconds % 60;

    const padAtStart = (str, len = 2, pad = '0') => String(str).padStart(len, pad);
    if (hours || minutes || seconds) {
      return (
        (hours ? `${padAtStart(hours)}: ` : '') +
        `${padAtStart(minutes)}:${padAtStart(Number.parseInt(seconds))}`
      );
    }

    return '00:00';
  };

  render() {
    const { callDuration } = this.state;
    return (
      <p className="font-14 font-weight-bold">
        {this.getReadableCallDuration(callDuration, 'callDurationFormat')}
      </p>
    );
  }
}

export default CallTimer;

import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Text from '../../../common/inputs/text';

export default function AnswerInputWithCheckbox({
  isChecked,
  onCheckboxChange,
  placeholder,
  name,
  value,
  onTextChange,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const onFocus = useCallback(() => setIsFocused(true), []);
  const onBlur = useCallback(() => setIsFocused(false), []);

  return (
    <div
      className={classNames('answer-input', {
        'answer-input--focused': isFocused,
      })}
    >
      <div className="answer-input__checkbox">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onCheckboxChange}
        />
      </div>
      <Text
        className="form-control mb-3 answer-input__text"
        placeholder={placeholder}
        name={name}
        value={value}
        onInputChange={onTextChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
}

AnswerInputWithCheckbox.propTypes = {
  isChecked: PropTypes.bool,
  onCheckboxChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onTextChange: PropTypes.func,
};

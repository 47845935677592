import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

const ActivityPagination = ({ totalPages, currentPage, onPageChange }) => {
  if (totalPages <= 1) {
    return <></>;
  }

  const pageArray = [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ];

  return (
    <ul className="kt-pagination__links">
      {currentPage >= 3 && (
        <li className="kt-pagination__link--first">
          <a onClick={onPageChange(1)}>
            <i className="fa fa-angle-double-left kt-font-brand"></i>
          </a>
        </li>
      )}

      {currentPage > 1 && (
        <li>
          <a onClick={onPageChange(currentPage - 1)}>
            <i className="fa fa-angle-left kt-font-brand"></i>
          </a>
        </li>
      )}

      {map(pageArray, pageNum => {
        if (pageNum === currentPage) {
          return (
            <li className="kt-pagination__link--active" key={pageNum}>
              <a disabled>{pageNum}</a>
            </li>
          );
        } else if (pageNum > 1 && pageNum <= totalPages) {
          return (
            <li key={pageNum}>
              <a onClick={onPageChange(pageNum)}>{pageNum}</a>
            </li>
          );
        }
        return null;
      })}

      {currentPage < totalPages && (
        <li className="kt-pagination__link--prev">
          <a onClick={onPageChange(currentPage + 1)}>
            <i className="fa fa-angle-right kt-font-brand"></i>
          </a>
        </li>
      )}
      {currentPage >= 1 && currentPage < totalPages - 1 && (
        <li className="kt-pagination__link--last">
          <a onClick={onPageChange(totalPages)}>
            <i className="fa fa-angle-double-right kt-font-brand"></i>
          </a>
        </li>
      )}
    </ul>
  );
};

ActivityPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};

export default ActivityPagination;

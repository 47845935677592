import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';

const DynamicSelectInput = ({
  name,
  placeholder,
  options,
  onChange,
  value,
  isMulti,
  hideSelectedOptions,
  ...props
}) => (
  <div>
    <CreatableSelect
      name={name}
      placeholder={placeholder}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      value={value}
      classNamePrefix="react-select"
      id='react-select'
      {...props}
    />
  </div>
);

DynamicSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  value: (props, propName, componentName) => {
    const isMultipleValuesExpected = props['isMulti'];

    if (isMultipleValuesExpected && !_.isArray(props[propName])) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to" +
          " `" +
          componentName +
          "`. Array Values Expected."
      );
    } else if (
      !isMultipleValuesExpected &&
      !(_.isString(props[propName]) || _.isNumber(props[propName]))
    ) {
      return new Error(
        "Invalid prop `" +
          propName +
          "` supplied to" +
          " `" +
          componentName +
          "`. String/Number Expected."
      );
    }
  }
};

DynamicSelectInput.defaultProps = {
  name: "",
  isMulti: false,
  hideSelectedOptions: true
};

export default DynamicSelectInput;

import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import includes from 'lodash/includes';
import map from 'lodash/map';
import isNull from 'lodash/isNull';

import FeedbackSummaryItem from './FeedbackSummaryItem';
import Http from '../../common/Http';
import { alertErrorNotifications } from '../../folders/utils';
import Spinner from '../../common/presentational/spinner';
import FeedbackSummaryGroupedResponsesTable from './FeedbackSummaryGroupedResponsesTable';
import {
  RECORD_GROUPABLE_ELEMENTS,
  SURVEY_SUMMARY_QUERY_STRING,
} from '../constants';
import Select from '../../common/inputs/select';
import {
  getStartAndEndDatesForAGivenPeriod,
  PERIOD_FILTER_OPTIONS,
} from '../../common/utils';
import SentimentSelect from '../../common/inputs/SentimentSelect';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { feedbackStateReducer } from './reducers';

const feedbackSummaryInitialState = {
  loading: false,
  surveySummary: {
    groupedResponseElements: {},
    uniqueResponseElements: {},
  },
  summaryMetaData: {
    projects: [],
  },
  summaryFilters: {
    project_id: null,
    submission_start_date: null,
    submission_end_date: null,
    dateSubmitted: null,
    sentiment: null,
  },
};

const FeedbackSummary = props => {
  const [feedbackSummaryState, dispatchFeedbackSummaryActions] = useReducer(
    feedbackStateReducer,
    feedbackSummaryInitialState
  );

  const fetchFeedbackSummary = async () => {
    const {
      links: { fetchSummary },
      selectedForm,
      csrfToken,
      projectId,
    } = props;
    const {
      summaryFilters: {
        project_id,
        submission_end_date,
        submission_start_date,
        sentiment,
      },
    } = feedbackSummaryState;

    const filterQueries = {
      project_id,
      submission_start_date,
      submission_end_date,
      sentiment,
    };

    let summary_query_params = {};
    if (!isEmpty(selectedForm)) {
      summary_query_params = {
        [SURVEY_SUMMARY_QUERY_STRING]: selectedForm.id,
        ...filterQueries,
        ...(projectId ? { project_id: projectId } : {}),
      };
    }

    await new Http()
      .onBegin(() => dispatchFeedbackSummaryActions({ type: 'onFetchBegin' }))
      .setToken(csrfToken)
      .get(fetchSummary)
      .setQueryParams({
        ...summary_query_params,
      })
      .onSuccess(res => {
        const { data } = res;
        const summaryData = data['survey_summary'] || {};
        let groupedResponseElements = [];
        let uniqueResponseElements = [];
        map(summaryData, item => {
          if (includes(RECORD_GROUPABLE_ELEMENTS, item.element)) {
            groupedResponseElements = [...groupedResponseElements, item];
          } else {
            uniqueResponseElements = [...uniqueResponseElements, item];
          }
        });
        // Grouped Responses (Name, Email and Project) should be added only once in the form, TODO: Discuss to restrict while creating.
        // Also Grouped Responses (DateTime, Date, Time) Can occur multiple times but only one response value will be shown in table .
        dispatchFeedbackSummaryActions({
          type: 'onFetchResponseSuccess',
          payload: {
            surveySummary: {
              groupedResponseElements: uniqBy(
                groupedResponseElements,
                'element'
              ),
              uniqueResponseElements,
            },
            summaryMetaData: data['meta'],
          },
        });
      })
      .onError(err => {
        console.log(err);
        dispatchFeedbackSummaryActions({ type: 'onFetchError' });
        alertErrorNotifications(err || 'Error fetching Summary!');
      })
      .exec();
  };

  useEffect(() => {
    if (!isEmpty(props.selectedForm)) {
      fetchFeedbackSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedForm]);

  const handleProjectFilterSelect = value => {
    dispatchFeedbackSummaryActions({
      type: 'handleSummaryFilters',
      payload: {
        project_id: value['id'],
      },
    });
  };

  const handleSentimentFilterSelect = sentimentValue => {
    dispatchFeedbackSummaryActions({
      type: 'handleSummaryFilters',
      payload: {
        sentiment: sentimentValue,
      },
    });
  };

  const handleDateSubmittedFilterSelect = value => {
    let { startDate, endDate } = getStartAndEndDatesForAGivenPeriod(
      value['key']
    );

    startDate = !isNull(startDate) ? startDate.format() : startDate;
    endDate = !isNull(endDate) ? endDate.format() : endDate;

    dispatchFeedbackSummaryActions({
      type: 'handleSummaryFilters',
      payload: {
        dateSubmitted: value['key'],
        submission_start_date: startDate,
        submission_end_date: endDate,
      },
    });
  };

  const renderGroupedResponseElementsSection = () => {
    const {
      surveySummary: { groupedResponseElements },
      summaryMetaData,
    } = feedbackSummaryState;
    const { tenantTerms, projectsEnabled } = props;

    return (
      <React.Fragment>
        {!isEmpty(groupedResponseElements) && (
          <FeedbackSummaryGroupedResponsesTable
            groupedResponseElements={groupedResponseElements}
            metaData={summaryMetaData}
            tenantTerms={tenantTerms}
            projectsEnabled={projectsEnabled}
          />
        )}
      </React.Fragment>
    );
  };

  const {
    tenantTerms: { termProject },
    projectsEnabled,
    isProjectShowPage,
  } = props;

  const {
    surveySummary: { groupedResponseElements, uniqueResponseElements },
    summaryMetaData,
    loading,
    summaryFilters,
  } = feedbackSummaryState;

  const formHasProjectResponses =
    projectsEnabled && !isEmpty(summaryMetaData['projects']);

  return (
    <React.Fragment>
      {loading && <Spinner />}
      <div
        className="d-flex justify-content-end mb-3"
        style={{ paddingRight: '40px' }}
      >
        <button
          className="app-btn-outline-primary"
          style={{ width: 'max-content' }}
          type="button"
          data-toggle="collapse"
          data-target="#summaryFilterCollapse"
          aria-expanded="false"
          aria-controls="summaryFilterCollapse"
        >
          <i className="fas fa-filter"></i> Filter
        </button>
      </div>
      <div className="collapse multi-collapse" id="summaryFilterCollapse">
        <div
          className={`row offset-md-${
            formHasProjectResponses ? '1' : '2'
          } mb-30 justify-content-center`}
        >
          {formHasProjectResponses && !isProjectShowPage && (
            <div className="col-md-3 pb-10">
              <Select
                placeholder={`Filter by ${termProject.singular}`}
                name={'projectFilter'}
                options={summaryMetaData.projects}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                optionIdentifier="id"
                value={summaryFilters['project_id'] || ''}
                onChange={value => handleProjectFilterSelect(value)}
              />
            </div>
          )}
          <div
            className={`col-md-${formHasProjectResponses ? '3' : '4'} pb-10`}
          >
            <SentimentSelect
              name={'selectedSentiment'}
              value={summaryFilters['sentiment'] || ''}
              onChange={({ value }) => handleSentimentFilterSelect(value)}
            />
          </div>
          <div
            className={`col-md-${formHasProjectResponses ? '3' : '4'} pb-10`}
          >
            <Select
              placeholder={'Date Submitted'}
              name={'dateSubmitted'}
              isSearchable={false}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.key}
              optionIdentifier="key"
              options={PERIOD_FILTER_OPTIONS}
              value={summaryFilters['dateSubmitted'] || ''}
              onChange={value => handleDateSubmittedFilterSelect(value)}
            />
          </div>
          <div className="col-md-2 text-lg-left text-md-left text-center">
            <button className="app-btn-primary" onClick={fetchFeedbackSummary}>
              Filter
            </button>
          </div>
        </div>
      </div>
      {!isEmpty(groupedResponseElements) || !isEmpty(uniqueResponseElements) ? (
        <React.Fragment>
          {map(Object.values(uniqueResponseElements), (item, index) => (
            <div key={`${item.element}-${index}`} className="kt-portlet">
              <FeedbackSummaryItem
                summaryItem={item}
                summaryMetaData={summaryMetaData}
                index={index}
              />
            </div>
          ))}
          {renderGroupedResponseElementsSection()}
        </React.Fragment>
      ) : (
        <div className="kt-portlet p-4 text-center">
          <i
            className="far fa-clipboard mb-3 text-muted"
            style={{ fontSize: '70px' }}
          ></i>
          <h4>No Summary</h4>
        </div>
      )}
    </React.Fragment>
  );
};

FeedbackSummary.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  projectsEnabled: PropTypes.bool.isRequired,
  links: PropTypes.shape({
    fetchSummary: PropTypes.string.isRequired,
    fetchIndividualResponses: PropTypes.string,
  }).isRequired,
  selectedForm: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  projectId: PropTypes.number,
  isProjectShowPage: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

FeedbackSummary.defaultProps = {
  projectId: null,
  isProjectShowPage: false,
};

export default withErrorHandler(FeedbackSummary);

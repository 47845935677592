import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import ReactChartkick, {
  BarChart,
  PieChart,
  ColumnChart,
} from 'react-chartkick';

import map from 'lodash/map';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import Chart from 'chart.js';
import Asset from '../../filestack/Asset';
import Modal from '../../filestack/modal';
import ReactFlowPlayerWrapper from '../../filestack/ReactFlowPlayerWrapper';
import { getAssetTypeFromMIME, formatDateTime } from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { summaryItemReducer } from './reducers';
import {
  getChartData,
  getSentimentChartData,
  setDefaultChartTypeByElement,
  getSentimentalColor,
} from './utils';
import { CHART_REPRESENTING_TYPES } from '../constants';

ReactChartkick.addAdapter(Chart);

const getInitialState = ({ summaryItem }) => {
  return {
    chartType: setDefaultChartTypeByElement(summaryItem.element),
    ...(summaryItem.element === 'CustomAssetManager' && {
      currentAssetResponseId: '',
      isModalOpen: false,
      fileSrc: '',
      fileType: '',
      filePoster: '',
    }),
  };
};

const FeedbackSummaryItem = props => {
  const summaryItemInitialState = getInitialState(props);
  const [summaryItemState, dispatchSummaryItemActions] = useReducer(
    summaryItemReducer,
    summaryItemInitialState
  );

  const changeChart = type => {
    dispatchSummaryItemActions({ type: 'handleChartType', payload: type });
  };

  const setCurrentAssetResponseId = responseId => {
    dispatchSummaryItemActions({
      type: 'handleCurrentAssetResponseId',
      payload: responseId,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const openModal = (src, type, id = null, poster = '') => {
    dispatchSummaryItemActions({
      type: 'handleOpenModal',
      payload: {
        fileSrc: src,
        fileType: type,
        filePoster: poster,
      },
    });
  };

  const closeModal = () => {
    dispatchSummaryItemActions({ type: 'handleCloseModal' });
  };

  const renderModalContent = () => {
    const { fileType, fileSrc, filePoster } = summaryItemState;

    switch (fileType) {
      case 'image':
        return (
          <img
            src={fileSrc}
            style={{
              width: '100%',
              maxHeight: '400px',
            }}
          />
        );
      case 'video':
        return (
          <ReactFlowPlayerWrapper
            poster={filePoster}
            playerId="reactFlowPlayer"
            sources={[
              {
                type: 'video/mp4',
                src: fileSrc,
              },
            ]}
          />
        );
      default:
        return null;
    }
  };

  const renderLabel = (response, responseId = null) => {
    const {
      summaryItem: { element },
    } = props;

    const { isModalOpen, fileSrc } = summaryItemState;

    let responseLabel;
    switch (element) {
      case 'CustomAssetManager': {
        const responseData = JSON.parse(response);

        const isAssetFileType =
          getAssetTypeFromMIME(responseData.mimetype) === 'file';
        let AssetParentElement = 'span';
        let assetParentProps = {
          onClick: () => setCurrentAssetResponseId(responseId),
        };
        if (isAssetFileType) {
          AssetParentElement = 'a';
          assetParentProps = {
            href: `${responseData.url}`,
            target: '_blank',
          };
        }

        responseLabel = (
          <div
            style={{
              borderRadius: '4px',
              overflow: 'hidden',
              border: `2px solid ${
                responseData.url === fileSrc && isModalOpen
                  ? '#6f42c1'
                  : 'transparent'
              }`,
              cursor: 'pointer',
            }}
          >
            <AssetParentElement {...assetParentProps}>
              <Asset
                className={'asset-inside-row'}
                mimetype={responseData.mimetype}
                url={responseData.url}
                openModal={openModal}
              />
            </AssetParentElement>
          </div>
        );
        break;
      }
      case 'DatePicker':
        responseLabel = formatDateTime({ date: response, formatTime: false });
        break;
      case 'Time':
      case 'DateTime':
        responseLabel = formatDateTime({ date: response });
        break;
      default:
        responseLabel = response;
    }
    return <React.Fragment>{responseLabel}</React.Fragment>;
  };

  const renderAssetList = (attachments, responseId) => (
    <div className="d-flex align-items-center flex-wrap">
      {map(attachments, (attachment, id) => (
        <span key={id} className="p-4">
          {renderLabel(attachment, responseId)}
        </span>
      ))}
    </div>
  );

  const renderTable = () => {
    const {
      summaryItem: { responses, element, meta },
      summaryMetaData: { user_response_links, sentimental_fields },
    } = props;

    return (
      <div className="table-responsive" style={{ maxHeight: '75vh' }}>
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr style={{ height: 42 }}>
              <th>
                <span className="pl-15">Responses</span>
              </th>
              {isEqual(element, 'CustomMemberDropdown') && (
                <th className="td-110">
                  <span>Count</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {map(responses, (response, responseKey) => (
              <tr key={responseKey}>
                {isEqual(element, 'CustomAssetManager') ? (
                  <td>{renderAssetList(response, responseKey)}</td>
                ) : (
                  <td className="text-truncate">
                    <span className="pl-15">
                      {isEqual(element, 'CustomMemberDropdown') ? (
                        renderLabel(responseKey)
                      ) : (
                        <a
                          href={user_response_links?.[responseKey]?.['show']}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {includes(sentimental_fields, element) && (
                            <span
                              className={`kt-badge kt-badge--${getSentimentalColor(
                                meta.sentiments[responseKey]
                              )} kt-badge--dot mr-3`}
                              style={{ width: '1rem', height: '1rem' }}
                            />
                          )}
                          {renderLabel(response)}
                        </a>
                      )}
                    </span>
                  </td>
                )}
                {isEqual(element, 'CustomMemberDropdown') && (
                  <td className="td-110">
                    <span>{response}</span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderChart = () => {
    const { chartType } = summaryItemState;
    const {
      summaryItem: { element },
      summaryMetaData: { sentimental_fields },
    } = props;

    const isSentimentalFieldType = includes(sentimental_fields, element);

    const chartData = isSentimentalFieldType
      ? getSentimentChartData(props)
      : getChartData(props);

    let stepSize = chartData.maxCountValue < 10 ? 1 : null;
    const getLibraryOptions = type => {
      const axes = type === 'bar' ? 'xAxes' : 'yAxes';
      return {
        scales: {
          [axes]: [
            {
              ticks: {
                stepSize,
              },
            },
          ],
        },
      };
    };

    switch (chartType) {
      case 'bar':
        return (
          <BarChart
            data={chartData.formattedResponses}
            library={getLibraryOptions('bar')}
          />
        );
      case 'column':
        return (
          <ColumnChart
            data={chartData.formattedResponses}
            library={getLibraryOptions('column')}
          />
        );
      case 'pie':
      default:
        return (
          <PieChart
            data={chartData.formattedResponses}
            donut
            colors={
              isSentimentalFieldType ? ['#34bfa3', '#f4516c', '#ffb822'] : null
            }
          />
        );
    }
  };

  const { isModalOpen, currentAssetResponseId } = summaryItemState;
  const {
    summaryItem,
    index,
    summaryMetaData: { sentimental_fields },
  } = props;

  const { question, element, meta, responses } = summaryItem;
  const isChartRenderableType = includes(CHART_REPRESENTING_TYPES, element);
  const hasAtleastOneAnswer = meta.answered > 0 ? true : false;
  const isSentimentalFieldType = includes(sentimental_fields, element);

  return (
    <div className="kt-portlet__body feedback-survey-summary">
      <div className="kt-section mb-20">
        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <div style={{ flex: 3 }}>
            <h3 className="mb-20">
              {`Q${index + 1}`}&#58;&nbsp;&nbsp;
              <span
                className="d-inline-block"
                dangerouslySetInnerHTML={{ __html: `${question.name}` }}
              />
            </h3>
            <div className="mb-20">
              <h5>{question.type}</h5>
              <span>
                Answered: <strong>{`${meta.answered}`}</strong>
                &nbsp;&nbsp;&nbsp;Skipped: <strong>{`${meta.skipped}`}</strong>
              </span>
            </div>
          </div>
          {(isChartRenderableType || isSentimentalFieldType) && (
            <div
              className="btn-group ml-15 plr-10 mt-10 mb-20"
              role="group"
              style={{ flex: 1 }}
            >
              <button
                type="button"
                title="Pie"
                className={`btn btn-sm ${
                  summaryItemState.chartType === 'pie'
                    ? 'app-btn-primary'
                    : 'btn-outline-brand'
                }`}
                onClick={() => changeChart('pie')}
                disabled={!hasAtleastOneAnswer}
              >
                <i className="la la-pie-chart" />
              </button>
              <button
                type="button"
                title="Bar"
                className={`btn btn-sm ${
                  summaryItemState.chartType === 'bar'
                    ? 'app-btn-primary'
                    : 'btn-outline-brand'
                }`}
                onClick={() => changeChart('bar')}
                disabled={!hasAtleastOneAnswer}
              >
                <i className="fa fa-align-left" />
              </button>
              <button
                type="button"
                title="Column"
                className={`btn btn-sm ${
                  summaryItemState.chartType === 'column'
                    ? 'app-btn-primary'
                    : 'btn-outline-brand'
                }`}
                onClick={() => changeChart('column')}
                disabled={!hasAtleastOneAnswer}
              >
                <i className="la la-bar-chart" />
              </button>
            </div>
          )}
        </div>
        {hasAtleastOneAnswer ? (
          isChartRenderableType ? (
            renderChart()
          ) : (
            <div>
              {isSentimentalFieldType && (
                <div className="mb-20">{renderChart()}</div>
              )}
              {renderTable()}
            </div>
          )
        ) : (
          <h4>No Answers yet.</h4>
        )}
      </div>
      {isModalOpen && (
        <Modal
          title={'Attachment Responses'}
          onClose={closeModal}
          renderContent={renderModalContent}
          renderFooter={() =>
            renderAssetList(
              responses[currentAssetResponseId],
              currentAssetResponseId
            )
          }
        />
      )}
    </div>
  );
};

FeedbackSummaryItem.propTypes = {
  summaryItem: PropTypes.shape({
    element: PropTypes.string.isRequired,
    meta: PropTypes.shape({
      answered: PropTypes.number.isRequired,
      skipped: PropTypes.number.isRequired,
      sentiments: PropTypes.object,
    }).isRequired,
    question: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
    responses: PropTypes.oneOfType([PropTypes.object]),
  }),
  summaryMetaData: PropTypes.object,
  index: PropTypes.number,
};

export default withErrorHandler(FeedbackSummaryItem);

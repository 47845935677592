import React from 'react';

export const ShowPageHeader = ({
  projectName,
  projectStatus,
  hideMapButton,
  projectManage,
  adminUser,
  links,
}) => {
  const showOptions = projectManage || adminUser;

  const projectStatusColorClass = {
    active: 'success',
    upcoming: 'warning',
    closed: 'danger',
  }[projectStatus]

  return (
    <div className="p-4 d-flex flex-wrap justify-content-between align-items-center">
      <div className="pr-10 d-flex flex-wrap align-items-center">
        <h3 className="mb-0">
          {projectName}
        </h3>
        <span
          className={`ml-2 kt-badge kt-badge--inline kt-badge--rounded kt-badge--${projectStatusColorClass} text-capitalize`}
        >{projectStatus}</span>
      </div>
      {(!hideMapButton || showOptions) && (
        <div style={{ backgroundColor: '#fff' }}>
          <div className="btn-group dropleft">
            {!hideMapButton && (
              <button
                type="button"
                id="demo_panel_toggler"
                className="app-btn-outline-primary demo-panel-toggler"
              >
                <i className="la la-map-marker" />
              </button>
            )}
            {showOptions && (
              <React.Fragment>
                <a
                  type="button"
                  className="app-btn-outline-primary px-4"
                  href={links.edit}
                >
                  Edit
                </a>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

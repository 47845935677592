import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import AssetManager from '../../filestack/AssetManager';
import { getFileStackConfig } from '../../common/utils';

// if an option is removed, make sure to remove any of its usage in component

/*
eg:
    if 'maxFiles' is removed make sure 'data.maxFiles' is not being used anywhere
    or provide a default value or else component may break;
*/
export const customAssetManagerOptions = [
  {
    type: 'select',
    isMulti: true,
    name: 'mimeType',
    label: 'Allowed File Types',
    defaultValue: [
      {
        label: 'All',
        value: '**',
      },
    ],
    options: [
      {
        label: 'All',
        value: '**',
      },
      {
        label: 'Images',
        value: 'image/*',
      },
      {
        label: 'Videos',
        value: 'video/*',
      },
      {
        label: 'Audios',
        value: 'audio/*',
      },
      {
        label: 'Pdf',
        value: '.pdf',
      },
    ],
  },
  {
    type: 'input',
    name: 'maxFiles',
    label: 'Max allowed files',
    defaultValue: '10',
  },
];

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.

class CustomAssetManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: [],
      existingAttachments: !isEmpty(props.answerData)
        ? props.answerData[props.data.field_name]
        : [],
    };
  }

  onFileChange = (assets, existingAttachments) => {
    this.setState({
      value: assets,
      existingAttachments: existingAttachments,
    });
  };

  render() {
    const { csrfToken, data } = this.props;
    const { props: componentProps } = data;
    const { value: assets, existingAttachments } = this.state;
    const { showUploader = true } = componentProps;

    return (
      <div>
        <AssetManager
          label={''}
          assets={assets}
          showGallery={true}
          showUploader={showUploader}
          csrfToken={csrfToken}
          readOnly={data.read_only}
          disabled={data.read_only}
          onFileChange={this.onFileChange}
          filestackOptions={getFileStackConfig(data)}
          existingAssets={existingAttachments}
          showFileDeleteOption={!data.read_only}
        />
      </div>
    );
  }
}

CustomAssetManager.defaultProps = {};

CustomAssetManager.propTypes = {
  answerData: PropTypes.object,
  csrfToken: PropTypes.string,
  data: PropTypes.shape({
    field_name: PropTypes.string,
    props: PropTypes.object,
    read_only: PropTypes.bool,
  }),
};

export default CustomAssetManager;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import partition from 'lodash/partition';
import orderBy from 'lodash/orderBy';

import { TransitionContainer, Transition } from '../../../common/animations';
import TextInput from '../../../common/inputs/text';
import GridItem from '../../CoursesGrid/GridItem';
import { withErrorHandler } from '../../../hoc/withErrorHandler';

const partitionByCompletedAndSortCourses = (items) => {
    const [pinned, unpinned] = partition(items, item => item.pinned === true);
    const [notCompleted, completed] = partition(unpinned, item => (item.isCompleted != true));

    const sortedPinned = orderBy(pinned, ['createdAt'], ['desc']);
    const sortedNotCompleted = orderBy(notCompleted, ['createdAt'], ['desc']);
    const sortedCompleted = orderBy(completed, ['createdAt'], ['desc']);

    return [...sortedPinned, ...sortedNotCompleted, ...sortedCompleted];
};

const AllCourses = ({
  courses,
  curriculums,
  tenantTerms: { termCurriculum, termCourse },
  isMyCoursesTab,
}) => {
  const coursesAndCurriculums = partitionByCompletedAndSortCourses([...courses, ...curriculums]);
  const [filterText, setFilterText] = useState('');
  const [content, setContent] = useState(coursesAndCurriculums);

  const onFilterChange = value => {
    const keyword = value.toLowerCase();

    setContent(
      coursesAndCurriculums.filter(c => {
        const title = c.name.toLowerCase();
        let description = c.objective?.toLowerCase?.();

        if (!c.objective) {
          const span = document.createElement('span');
          span.innerHTML = c.description;

          description = span.innerText.toLowerCase();
        }

        return (title.includes(keyword) || description.includes(keyword));
      })
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-center justify-content-lg-end">
        <div className="mr-md-3 mb-4" style={{ minWidth: 300 }}>
          <div className="input-text-with-icon">
            <div className="input-text-with-icon__icon-wrapper">
              <i className="flaticon2-search-1"></i>
            </div>
            <TextInput
              name="filterText"
              onInputChange={({ target: { value } }) => {
                setFilterText(value);
                onFilterChange(value);
              }}
              type="text"
              value={filterText}
              placeholder="Filter by name"
              className="form-control input-text-with-icon__input"
            />
          </div>
        </div>
      </div>
      {isEmpty(content) ? (
        <h2 className="text-center mt-4 text-muted">
          <i className="flaticon-presentation" style={{ fontSize: '100px' }} />
          <br />
          {isMyCoursesTab ? (
            <span>
              Your {termCourse.plural} and {termCurriculum.plural} will be
              visible here.
            </span>
          ) : (
            <span>
              No {termCourse.plural} and {termCurriculum.plural} are found.
            </span>
          )}
        </h2>
      ) : (
        <TransitionContainer className="display-grid">
          {content.map(c => (
            <Transition key={`${filterText}-${c.id}-${c.name}`}>
              <GridItem
                item={c}
                descriptionKey={
                  !isUndefined(c.objective) ? 'objective' : 'description'
                }
              />
            </Transition>
          ))}
        </TransitionContainer>
      )}
    </div>
  );
};

AllCourses.propTypes = {
  courses: PropTypes.array,
  curriculums: PropTypes.array,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCurriculum: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  isMyCoursesTab: PropTypes.bool,
};

AllCourses.defaultProps = {
  courses: [],
  curriculums: [],
  isMyCoursesTab: false,
};

export default withErrorHandler(AllCourses);

import { INITIAL_LOADER_STATE } from './constant';

export const learningForumLoadingReducers = (state, action) => {
  switch (action.type) {
    case 'handleLoader': {
      const { options, pagingRef } = action;
      const isSuggestionsLoading = options.isSuggestion;
      const isPaginationLoading = options.pagination;
      const isInitialLoading = options.initialFetch;

      if (!pagingRef.current && !isSuggestionsLoading) {
        if (isInitialLoading) {
          return {
            ...INITIAL_LOADER_STATE,
            loading: !state.loading,
          };
        }
        return {
          ...INITIAL_LOADER_STATE,
          filterLoader: !state.filterLoader,
        };
      }
      if (isPaginationLoading) {
        return {
          ...INITIAL_LOADER_STATE,
          isPaginationLoading: !state.isPaginationLoading,
        };
      }
      if (isSuggestionsLoading) {
        return {
          ...INITIAL_LOADER_STATE,
          isSuggestionsLoading: !state.isSuggestionsLoading,
        };
      }
      return { ...state };
    }
    case 'setLoading': {
      return { ...INITIAL_LOADER_STATE, loading: !state.loading };
    }
    case 'setFilterLoader': {
      return {
        ...INITIAL_LOADER_STATE,
        filterLoader: !state.filterLoader,
      };
    }
    case 'setIsPaginationLoading': {
      return {
        ...INITIAL_LOADER_STATE,
        isPaginationLoading: !state.isPaginationLoading,
      };
    }
    case 'setIsSuggestionsLoading': {
      return {
        ...INITIAL_LOADER_STATE,
        isSuggestionsLoading: !state.isSuggestionsLoading,
      };
    }
    case 'resolved':
    case 'rejected': {
      return {
        ...INITIAL_LOADER_STATE,
      };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

export const learningForumDataReducers = (state, action) => {
  switch (action.type) {
    case 'setAllTopicData':
    case 'setTopicSuggestionsData': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'setNewTopicTitle':
    case 'setCategoryFilter':
    case 'setTopicFilter': {
      return { ...state, ...action.payload };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const RadioButtonGroup = ({ onChange, buttonList, checkedId, className }) => (
  <div className={`radio-button-group ${className}`}>
    {buttonList.map(({ id, label, color }) => (
      <Fragment key={id}>
        <input
          type="radio"
          name={id}
          value={id}
          id={id}
          checked={checkedId === id}
          onChange={onChange}
        />
        <label
          htmlFor={id}
          className={
            color && checkedId === id ? `bg-${color} border-${color}` : ''
          }
        >
          {label}
        </label>
      </Fragment>
    ))}
  </div>
);

RadioButtonGroup.propTypes = {
  onChange: PropTypes.func.isRequired,
  buttonList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  checkedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RadioButtonGroup.defaultProps = {
  className: '',
};

export default RadioButtonGroup;

import React from 'react';
import PropTypes from 'prop-types';

import { ALERT_DIALOG_WRAPPER_STYLE } from '../constants';
import './alert.scss';

const ClearAlertDialog = ({ onClick }) => (
  <div
    data-notify="container"
    className="alert clear-all-alert-wrapper"
    role="alert"
    data-notify-position="bottom-center"
    style={{ ...ALERT_DIALOG_WRAPPER_STYLE, ...{ width: '30%' } }}
    onClick={onClick}
  >
    <span type="button">Clear All</span>
  </div>
);

ClearAlertDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ClearAlertDialog;

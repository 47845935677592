import React from 'react';
import PropTypes from 'prop-types';

import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import keys from 'lodash/keys';

import { ShowPageHeader } from './ShowPageHeader';
import './ProjectShowPage.scss';
import { withErrorHandler } from '../hoc/withErrorHandler';
import FeedbackSummary from '../surveys/feedback_summary';
import Select from '../common/inputs/select';

const TABS = Object.freeze({
//   tickets: 'tickets',
  responseSummary: 'response_summary',
});

const ProjectShowPage = props => {
  const { surveyForms } = props.formResponsesSummaryConfig;
  const getValidDefaultTab = () => {
    const {
      formResponsesSummaryConfig: { showTab: showSummaryTab },
    } = props;

    return showSummaryTab
      ? TABS.responseSummary
      : null;
  };
  const selectedTab = props.activeTab ? props.activeTab : getValidDefaultTab();
  const determineSelectedForm = surveyForms.length > 1 ? null : surveyForms[0];

  // STATE
  const [currentTab, setCurrentTab] = React.useState(selectedTab);
  const [selectedForm, setSelectedForm] = React.useState(determineSelectedForm);

  const redirectUrl = () => {
    let url = window.location.href;
    let a = url.replace(/(\/+|)tab\/(.*)(\/+|)/, '');
    return `${a}`;
  };

  const redirectPrevTab = e => {
    e.preventDefault();
    const path = window.location.pathname.split('/');
    if (window.location.pathname.includes('/tab')) {
      const tabName = path[path.length - 1];
      setCurrentTab(tabName);
      if (history.replaceState) {
        window.history.replaceState('', '', `${redirectUrl()}/tab/${tabName}`);
      }
    } else {
      window.history.replaceState('', '', `${redirectUrl()}`);
      window.location.href = redirectUrl();
    }
    return null;
  };

  const handleTabChange = tabName => () => {
    setCurrentTab(tabName);
    if (history.pushState) {
      window.history.pushState('', '', `${redirectUrl()}/tab/${tabName}`);
    }
  };

  const activeTabClass = tabName => {
    return tabName === currentTab ? 'active' : '';
  };

  const handleTabsOnMount = async () => {
    const { activeTab, allowForms } = props;

    let omittableTabs = [];
    if (!allowForms) {
      omittableTabs.push('responseSummary');
    }
    const viewConsiderableTabs = omit(TABS, omittableTabs);
    const validTabs = map(keys(viewConsiderableTabs), tkey => TABS[tkey]);

    const redirectToTab = getValidDefaultTab();

    if (!validTabs.includes(activeTab)) {
      if (!validTabs.includes(activeTab) && history.replaceState) {
        window.history.replaceState(
          '',
          '',
          `${redirectUrl()}/tab/${redirectToTab}`
        );
      }
      await handleTabChange(redirectToTab)();
    }
  };

  React.useEffect(() => {
    handleTabsOnMount();
    window.addEventListener('popstate', e => redirectPrevTab(e), false);
    return () => {
      // this will triggered on unmount of the component.
      window.removeEventListener('popstate', redirectPrevTab, false);
    };

    // useEffect has to be triggered only on the mount not on every tab change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTabs = () => {
    const {
      tenantTerms,
      allowForms,
      formResponsesSummaryConfig: { showTab: showSummaryTab },
    } = props;
    const { termTicket } = tenantTerms;

    const TabObject = Object.freeze({
      tickets: { label: termTicket.plural, key: TABS.tickets },
      responseSummary: {
        label: 'Response Summary',
        key: TABS.responseSummary,
      },
    });

    let navTabs = [];

    if (allowForms && showSummaryTab) navTabs.push(TabObject.responseSummary);

    return (
      <ul className="nav nav-tabs" role="tablist">
        {map(navTabs, tab => (
          <li className="nav-item " key={tab.key}>
            {/* Changed to button instead of having anchor tag, which makes more sense instead of having unwanted href attribute. */}
            <button
              type="button"
              className={`nav-link ${activeTabClass(tab.key)}`}
              onClick={handleTabChange(tab.key)}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  const {
    links,
    adminUser,
    csrfToken,
    projectId,
    projectName,
    projectStatus,
    tenantTerms,
    projectManage,
    hideMapButton,
    formResponsesSummaryConfig,
    isSingleProjectTenant,
  } = props;

  return (
    <React.Fragment>
      <ShowPageHeader
        projectName={projectName}
        hideMapButton={hideMapButton}
        projectManage={projectManage}
        adminUser={adminUser}
        tenantTerms={tenantTerms}
        links={links}
        projectStatus={projectStatus}
      />
      <div className="new-theme-tabs-container">
        <div className="d-flex justify-content-between flex-wrap-reverse">
          <div className="new-theme-tabs new-theme-tabs__responsive pt-2 align-self-baseline">
            <div className="d-flex">{renderTabs()}</div>
          </div>
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body p-0">
            <div className="row">
              <div className="col-md-12">
                {currentTab === TABS.responseSummary && (
                  <div className="tab-pane pt-5">
                    {!isEmpty(formResponsesSummaryConfig.surveyForms) ? (
                      <div className="mb-2 w-75 pb-5 mr-auto ml-auto">
                        {isEmpty(selectedForm) && (
                          <div className="text-center pb-3">
                            <i
                              className="far fa-clipboard mb-2 text-muted"
                              style={{ fontSize: '70px' }}
                            ></i>
                            <h4>
                              Please Select a Form to View it&apos;s Summary
                            </h4>
                          </div>
                        )}
                        <Select
                          placeholder={'Select Form'}
                          name={'formSelect'}
                          getOptionLabel={option => option.name}
                          getOptionValue={option => option.id}
                          options={formResponsesSummaryConfig.surveyForms}
                          value={selectedForm}
                          onChange={value => {
                            setSelectedForm(value);
                          }}
                          isClearable={false}
                        />
                      </div>
                    ) : (
                      <div className="text-center">
                        <h4>No Responses at this moment!</h4>
                      </div>
                    )}
                    {!isEmpty(selectedForm) && (
                      <React.Fragment>
                        <h2 className="pl-4">{selectedForm.name}</h2>
                        <FeedbackSummary
                          selectedForm={selectedForm}
                          csrfToken={csrfToken}
                          tenantTerms={tenantTerms}
                          projectId={projectId}
                          projectsEnabled
                          isProjectShowPage
                          {...formResponsesSummaryConfig}
                        />
                      </React.Fragment>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectShowPage.propTypes = {
  activeTab: PropTypes.string,
  hideMapButton: PropTypes.bool.isRequired,
  allowForms: PropTypes.bool.isRequired,
  projectName: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  projectManage: PropTypes.bool.isRequired,
  adminUser: PropTypes.bool.isRequired,
  commentProps: PropTypes.object.isRequired,
  feedbackFormProps: PropTypes.object.isRequired,
  tenantTerms: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  existingAssets: PropTypes.array.isRequired,
  existingAssetsFolders: PropTypes.array.isRequired,
  isSingleProjectTenant: PropTypes.bool.isRequired,
  formResponsesSummaryConfig: PropTypes.shape({
    surveyForms: PropTypes.array,
    showTab: PropTypes.bool,
  }),
  projectStatus: PropTypes.string,
};

export default withErrorHandler(ProjectShowPage);

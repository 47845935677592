import React from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import values from 'lodash/values';
import replace from 'lodash/replace';
import forEach from 'lodash/forEach';
import castArray from 'lodash/castArray';
import includes from 'lodash/includes';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';

import { REACT_FLASH_CONTAINER_WINDOW_REF_KEY } from '../../common/constants';
import {
  DEFAULT_ASSET_FILTER_TYPE,
  ASSET_TYPE_HASH_PARAM,
  ASSET_FILTER_OPTIONS,
} from '../common/constants';

export const userAccessTypes = ['viewable', 'editable'];
export const editableStatus = 'editable';
export const viewableStatus = 'viewable';

const userAccessTypeLabelMap = {
  viewable: 'Can View',
  editable: 'Can Edit & Share',
};

export const getUserAccessTypeOptions = () =>
  map(userAccessTypes, userAccessType => ({
    label: userAccessTypeLabelMap[userAccessType],
    value: userAccessType,
  }));

export const shareOptionValueRenderer = option =>
  option.value == viewableStatus ? (
    <i className="fa fa-eye" aria-hidden="true"></i>
  ) : (
    <i className="fa fa-pencil" aria-hidden="true"></i>
  );

export const getAssetTypeFromLocationHash = () => {
  const extractedAssetType = replace(
    window.location.hash,
    ASSET_TYPE_HASH_PARAM,
    ''
  );

  const hashAssetType =
    isEmpty(extractedAssetType) ||
    !includes(values(ASSET_FILTER_OPTIONS), extractedAssetType)
      ? DEFAULT_ASSET_FILTER_TYPE
      : extractedAssetType;

  return hashAssetType;
};

export const myFolderUrlProvider = folderId => `/folders/${folderId}`;
export const sharedFolderUrlProvider = folderId =>
  `/shared_folders/${folderId}`;

const alertNotifications = ({ messages, notificationType }) => {
  // AlertMessagesContainer Should be mounted before displaying any alerts.
  if (isNull(window[REACT_FLASH_CONTAINER_WINDOW_REF_KEY])) return;

  const messagesCollection = castArray(messages);

  forEach(messagesCollection, message =>
    window[REACT_FLASH_CONTAINER_WINDOW_REF_KEY].addMessage({
      id: Math.random().toString(36).substring(7),
      payload: message,
      type: notificationType,
    })
  );
};

export const alertSuccessNotifications = messages => {
  alertNotifications({
    messages,
    notificationType: 'success',
  });
};

export const alertErrorNotifications = messages => {
  alertNotifications({
    messages,
    notificationType: 'error',
  });
};

export const alertWarningNotifications = messages => {
  alertNotifications({
    messages,
    notificationType: 'alert',
  });
};

export const alertInfoNotifications = messages => {
  alertNotifications({
    messages,
    notificationType: 'notice',
  });
};

export const clearAlertNotifications = () =>
  !isNull(window[REACT_FLASH_CONTAINER_WINDOW_REF_KEY]) &&
  window[REACT_FLASH_CONTAINER_WINDOW_REF_KEY].clearMessages();

export const getTenantDisplayTerm = ({ tenantDisplayTerms, resourceTerm }) =>
  get(tenantDisplayTerms, camelCase(resourceTerm), startCase(resourceTerm));

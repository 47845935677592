import React from 'react';
import Asset from './Asset';
import { formatDateTime, humanFileSize } from '../common/utils';

export const SelectableGallery = ({
  assets,
  selectedAssets,
  openModal,
  onAssetSelect,
}) => (
  <div className="row">
    {_.map(
      assets,
      ({ id, filename, mimetype, handle, size, url, createdAt, encoded }, index) => (
        <div
          className="col-xl-3 col-lg-4 col-md-4 col-sm-12"
          key={index}
          style={{ margin: '10px 0', minWidth: 230 }}
        >
          <div className="file-card">
            <div className="kt-file">
              <a>
                {encoded ? (
                  <div
                    className={
                      _.includes(selectedAssets, parseInt(id))
                        ? 'file-options-left'
                        : 'hover-left'
                    }
                  >
                    <div className="btn-group mr-2" role="group" style={{ zIndex: 1 }}>
                      <div
                        type="button"
                        className={`btn ${
                          _.includes(selectedAssets, parseInt(id))
                            ? 'btn-primary'
                            : 'btn-secondary'
                        }`}
                        onClick={() => onAssetSelect(id)}
                      >
                        <i className="la la-check" />
                      </div>
                    </div>
                  </div>
                ) : null}

                <Asset id={id} mimetype={mimetype} url={url} openModal={openModal} />

                <div className="file-name">
                  <p className="m-b-5 text-muted font-13 break-word filename-truncate">
                    {!encoded && <strong className="text-darkred">Not Encoded</strong>}{' '}
                    {filename}
                  </p>
                  <small>
                    Size: {humanFileSize(size)}
                    <span className="kt-date text-muted f-10">
                      {formatDateTime({ date: createdAt, formatTime: false })}
                    </span>
                  </small>
                </div>
              </a>
            </div>
          </div>
        </div>
      )
    )}
  </div>
);

import axios from 'axios';
import toPlainObject from 'lodash/toPlainObject';
import endsWith from 'lodash/endsWith';
import 'url-polyfill';

import { getErrorMessages } from './utils';
import {
  alertErrorNotifications,
  alertSuccessNotifications,
} from '../folders/utils';

import errorHandler from './apiProcessors/middlewares/errorHandler';
import { responseDataCamelizer } from './apiProcessors/middlewares/response';
import { requestDataSnakifier } from './apiProcessors/middlewares/request';

const getTypeProcessedURL = (
  url,
  { jsonFormat = true, jsonFormatURLExtension = '.json' } = {}
) => {
  if (!jsonFormat) return url;

  const urlObj = new URL(url, window.location);

  const urlPath = urlObj.pathname;
  const searchQueryString = urlObj.search;

  const processedPath = endsWith(urlPath, jsonFormatURLExtension)
    ? urlPath
    : urlPath + jsonFormatURLExtension;

  return `${processedPath}${searchQueryString}`;
};

let _cancelPrevRequest;

export default function Http(mContext = null) {
  let _method = 'GET';
  let _url = '/';
  let _csrfToken = undefined;
  let _postData = '';
  let _queryParams = '';
  let _doesRedirect = false;

  let _context = mContext;

  let _useAlertForNotifications = false;

  let _onBegin = null;

  let _successMessage = null;
  let _successCallbackProvided = false;
  let _successCallbackFunction = null;

  let _errorCallbackProvided = false;
  let _errorCallbackFunction = null;

  let jsonFormatURLExtension = '.json';

  let _axiosInstance = axios.create();
  const CancelToken = axios.CancelToken;

  let _shouldCancelPrevRequest = false;

  this.response = null;
  this.errors = [];

  this.cancelPrevRequest = function () {
    _shouldCancelPrevRequest = true;
    return this;
  };

  this.setToken = function (token = undefined) {
    _csrfToken = token;
    return this;
  };

  this.doesRedirect = function (redirect = false) {
    _doesRedirect = redirect;
    return this;
  };

  this.get = function (url, data = {}, { jsonFormat = true } = {}) {
    _method = 'GET';
    // 1. When links are sent as props to component, .json can be appended to URL using rails path helper. But, it requires a lot of changes.
    // 2. Axios interceptor can be used to append .json extension for GET requests. But new instance of axios has to be created and imported everywhere.
    // Comparing to above two solutions, tweaking logic here is a simple and customizable solution.
    _url = getTypeProcessedURL(url, { jsonFormat, jsonFormatURLExtension });
    _queryParams = data;

    return this;
  };

  this.delete = function (url, data = undefined) {
    _method = 'DELETE';
    _url = url;
    _queryParams = data;

    return this;
  };

  this.put = function (url, data = undefined) {
    _method = 'PUT';
    _url = url;
    if (data != null) {
      console.log(data);
      _postData = data;
    }

    return this;
  };

  this.post = function (url, data = undefined) {
    _method = 'POST';
    _url = url;
    if (data != null) {
      _postData = data;
    }

    return this;
  };

  this.setPostData = function (data = {}) {
    _postData = data;
    return this;
  };

  this.setQueryParams = function (data = {}) {
    _queryParams = data;
    return this;
  };

  this.patch = function (url, data = undefined) {
    _method = 'PATCH';
    _url = url;
    if (data != null) {
      _postData = data;
    }

    return this;
  };

  this.useAlerts = function (useAlerts = true) {
    _useAlertForNotifications = useAlerts;
    return this;
  };

  this.useAPIDataFormatters = function ({
    snakifyRequestData = false,
    camelizeResponseData = false,
  }) {
    if (camelizeResponseData) {
      _axiosInstance.interceptors.response.use(
        responseDataCamelizer(toPlainObject(camelizeResponseData)),
        errorHandler
      );
    }

    if (snakifyRequestData) {
      _axiosInstance.interceptors.request.use(
        requestDataSnakifier(toPlainObject(snakifyRequestData)),
        errorHandler
      );
    }
    return this;
  };

  this.setLoading = function (isLoading = true) {
    if (_context != null) {
      _context.setState({
        loading: isLoading,
        isLoading: isLoading,
        errors: [],
      });
    }

    return this;
  };

  this.setSuccessMessage = function (message) {
    _successMessage = message;
    return this;
  };

  this.setContext = function (context) {
    _context = context;
    return this;
  };

  this.onBegin = function (callback) {
    _onBegin = callback;
    return this;
  };

  this.onSuccess = function (callback) {
    _successCallbackProvided = true;
    _successCallbackFunction = callback;
    return this;
  };

  this.callSuccessCallback = function () {
    if (
      _successCallbackProvided &&
      'function' === typeof _successCallbackFunction
    ) {
      _successCallbackFunction(this.response);
    }
  };

  this.onError = function (callback) {
    _errorCallbackProvided = true;
    _errorCallbackFunction = callback;
    return this;
  };

  this.callErrorCallback = function () {
    if (
      _errorCallbackProvided &&
      'function' === typeof _errorCallbackFunction
    ) {
      _errorCallbackFunction(this.errors);
    }
  };

  this.exec = async function () {
    if (_onBegin != null && 'function' === typeof _onBegin) {
      _onBegin();
    }

    let mToken = '';

    if (_shouldCancelPrevRequest && _cancelPrevRequest) {
      _cancelPrevRequest('canceled');
    }

    if (_context != null && _csrfToken == null) {
      mToken = _context.props.csrfToken || _context.props.token || '';
    }

    try {
      const serverResponse = await _axiosInstance({
        method: _method,
        url: _url,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': _csrfToken || mToken,
        },
        timeout: 60000 * 2,
        data:
          _method === 'POST' || _method === 'PATCH' || _method === 'PUT'
            ? _postData
            : {},
        params: _method === 'GET' || _method === 'DELETE' ? _queryParams : {},
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          _cancelPrevRequest = c;
        }),
      });

      this.response = serverResponse;

      if (_useAlertForNotifications && _successMessage != null) {
        alertSuccessNotifications(_successMessage);
      }

      if (_context != null && _doesRedirect === false) {
        _context.setState({
          errors: [],
          isLoading: false,
          loading: false,
        });
      }

      this.callSuccessCallback();

      return serverResponse;
    } catch (e) {
      if (axios.isCancel(e)) {
        console.log(e.message);
      } else {
        console.log(e);

        const errorObj = getErrorMessages(e);

        this.errors = errorObj;

        if (_context != null && 'function' === typeof _context.setState) {
          _context.setState({
            errors: errorObj,
            isLoading: false,
            loading: false,
          });
        }

        if (_useAlertForNotifications) {
          alertErrorNotifications(errorObj);
        }

        this.callErrorCallback();

        return {
          error: errorObj,
          data: null,
        };
      }
    }
  };

  this.toString = function () {
    return `
    {
      method: ${_method},
      url: ${_url},
      queryParams: ${JSON.stringify(_queryParams)},
      postData: ${JSON.stringify(_postData)}
      csrfToken: ${_csrfToken},
      response: ${JSON.stringify(this.response)},
      errors: ${this.errors}
    }`;
  };
}

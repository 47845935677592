import isEmpty from 'lodash/isEmpty';
import { getRandomColor } from '../common/Avatar/utils';

const MAX_BODY_LINES = 5;

const getNumberOfLines = str => str.split(/\r\n|\r|\n/).length;

const getTrimmedBody = str =>
  `${str
    .split(/\r\n|\r|\n/)
    .slice(0, MAX_BODY_LINES)
    .join('\n')}...`;

// Checking the number of lines and str length
export const renderTopicBody = topic =>
  getNumberOfLines(topic) > MAX_BODY_LINES
    ? getTrimmedBody(topic)
    : topic.length >= 200
    ? `${topic.slice(0, 200)}...`
    : topic;

const defaultColors = [
  '#d73d32',
  '#7e3794',
  '#4285f4',
  '#67ae3f',
  '#d61a7f',
  '#ff4080',
  '#667292',
  '#e0876a',
  '#622569',
  '#588c7e',
  '#7e4a35',
];

export const getCategoryRandomColors = name =>
  getRandomColor(name, defaultColors);

export const generateFilterParams = params => {
  if (!isEmpty(params)) {
    const query = Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');

    return `?${query}`;
  }

  return '';
};

export const getFilterParams = ({ key, parseToInt = false }) => {
  const search = location.search.substring(1);
  if (!search) return '';

  const objParam = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );

  if (parseToInt && objParam[key]) {
    return parseInt(objParam[key]);
  }

  return objParam[key] || '';
};

export const getTimeDifferenceInDays = date => {
  const today = new Date();
  const activityDate = new Date(date);
  const differenceInTime = today.getTime() - activityDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return differenceInDays;
};

export const formatDate = date => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [month, day, year].join('/');
};

export const timeFromNow = days => {
  const roundedValue = Math.round(days);
  if (roundedValue >= 30) return 'a month ago';
  if (roundedValue === 1) return '1 day ago';
  if (roundedValue < 1) return 'a while ago';
  return `${roundedValue} days ago`;
};

export const renderActivityTime = date => {
  const diffInDays = getTimeDifferenceInDays(date);
  const formattedDate = formatDate(date);
  return diffInDays < 30 ? timeFromNow(diffInDays) : formattedDate;
};

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AssetManager from '../filestack/AssetManager';
import InitialsAvatar from '../common/Avatar';
import Popover from '../common/presentational/Popover';
import AttachmentPreviewSlider from '../common/AttachmentPreviewSlider';

import './comment_sentiment_styler.scss';
import { DATE_TIME_FORMATS, DATE_TIME_FORMAT_BY_MONTH } from '../common/constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

const regexForMention = /@\[[-a-zA-Z0-9\s]+\]\(\d+\)/g;
const regexForName = /[-a-zA-Z0-9\s]+/;

class Comment extends Component {
  constructor(props) {
    super(props);
  }

  handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      this.props.handleDelete(id);
    }
  };

  toggleEditBox = (comment) => {
    const { isEditing } = this.props;

    if (isEditing && window.confirm('Are you sure you want to discard the changes?')) {
      this.props.toggleEditBox(comment);
    } else {
      this.props.toggleEditBox(comment);
    }
  };

  renderBody = (text) => {
    const mentions = text.match(regexForMention);
    _.forEach(mentions, (mention) => {
      const mentionedName = mention.match(regexForName)[0];
      text = text.replace(
        mention,
        `<a href='#' className='kt-link kt-font-boldest'>${mentionedName}</a>`
      );
    });

    return text.replace(/\n/g, '<br />');
  };

  renderEmotions = (emotions) => {
    const sortedEmotions = _.toPairs(emotions).sort(
      (current, next) => next[1] - current[1]
    );
    return (
      <span className="kt-widget3__body d-inline-flex flex-wrap comment-emotions">
        {_.map(sortedEmotions, ([emotion, score]) => (
          <span
            className="font-11 font-weight-bold mx-1 d-inline-flex align-items-center"
            key={emotion}
          >
            <span className="emotion-name px-1 text-info">{_.capitalize(emotion)}</span>
            <span className="mx-1 d-inline-block emotion-bar">
              <span
                className="d-block"
                style={{
                  width: `${score * 100}%`,
                }}
              ></span>
            </span>
            <span className="text-black-50">{Number.parseFloat(score).toFixed(2)}</span>
          </span>
        ))}
      </span>
    );
  };

  getFormattedLogTime = (time) => moment(time).format(DATE_TIME_FORMATS.dateTime);

  renderSmsDeliveredStatus = () => {
    const { smsDeliveredTime } = this.props;

    return (
      <Popover
        title="Delivered At"
        content={
          smsDeliveredTime
            ? this.getFormattedLogTime(smsDeliveredTime)
            : 'SMS Sent! - Awaiting Delivery!'
        }
        position="bottom"
      >
        <span className="mt-2 font-16">
          {smsDeliveredTime ? (
            <i className="fa fa-sms" />
          ) : (
            <span>
              <i className="fa fa-sms" />
              <i className="fa fa-rocket ml-2 font-12" />
            </span>
          )}
        </span>
      </Popover>
    );
  };

  getTimeSpanGroupedLogs = (logs) => {
    const TIME_SPAN = 5;

    const uniqLogs = _.uniq(_.map(logs, this.getFormattedLogTime));
    uniqLogs.sort();

    const roundedTimeSpannedLogs = uniqLogs.reduce((acc, log) => {
      const minute = `${Math.floor(moment(log).format('mm') / TIME_SPAN) * TIME_SPAN}`;
      acc[log] = {
        log,
        rounded: `${moment(log)
          .startOf('hour')
          .format(`${DATE_TIME_FORMATS.date}, hh`)}:${minute.padStart(2, '0')}`,
      };
      return acc;
    }, {});

    return _.uniqBy(_.values(roundedTimeSpannedLogs), 'rounded');
  };

  renderMailOpenCount = () => {
    const { openedTimeLog } = this.props;
    const isTimeLogExists = !_.isNil(openedTimeLog);
    const isOpenedAtleastOnce = isTimeLogExists && openedTimeLog.length > 0;

    const timeSpannedLogs = isOpenedAtleastOnce
      ? this.getTimeSpanGroupedLogs(openedTimeLog)
      : [];

    const getTimeLogList = () => (
      <div style={{ whiteSpace: 'pre' }}>
        {_.map(timeSpannedLogs, ({ log }) => log).join('\n')}
      </div>
    );

    return (
      <Popover
        title="Time Log"
        content={isOpenedAtleastOnce ? getTimeLogList() : 'Email Sent - Not Opened Yet!'}
        position="bottom"
      >
        <span className="mt-2 font-14">
          <i className={`fa fa-envelope${isOpenedAtleastOnce ? '-open-text' : ''}`} />
          {isOpenedAtleastOnce && (
            <span className="pl-2">
              <b>{timeSpannedLogs.length}</b>
            </span>
          )}
        </span>
      </Popover>
    );
  };

  renderSentimentSection = () => {
    const { sentiment } = this.props;

    const getSentimentClass = (sentimentLabel) => {
      switch (sentimentLabel) {
        case 'Positive':
          return 'positive';
        case 'Negative':
          return 'negative';
        case 'Neutral':
          return 'neutral';
      }
    };

    const sentimentClass = getSentimentClass(sentiment.label);

    return (
      <div className={`comment-body-sentiment ${sentimentClass} font-18 mt-3`}>
        <div>
          <span className="sentimental-dot"></span>&nbsp;
          {`${sentiment.label} (${sentiment.score}) Net Sentiment Score`}
        </div>
        {!_.isEmpty(sentiment?.emotions) && this.renderEmotions(sentiment.emotions)}
      </div>
    );
  };

  formatDate = (timeLog) => {
    const date = moment(timeLog).format(DATE_TIME_FORMAT_BY_MONTH.date);
    const time = moment(timeLog).format(DATE_TIME_FORMAT_BY_MONTH.time);

    return (
      <div className="pl-3 text-right">
        <div className="text-muted">{date}</div>
        <div className="text-dark">{time}</div>
      </div>
    );
  };

  render() {
    const {
      currentUserId,
      comment,
      csrfToken,
      onCommentAssetsChange,
      editable,
      sentiment,
      callLoggedCommentIds,
    } = this.props;

    const {
      id,
      body,
      createdAt,
      userId,
      userName,
      userAvatar,
      internal,
      notificationType,
    } = comment;

    return (
      <div className={`comment${internal ? ' internal' : ''}`}>
        <div className="d-flex w-100 align-items-end">
          <div className="comment_avatar">
            <div className="comment_avatar-img">
              <InitialsAvatar
                url={userAvatar}
                className="kt-widget3__img"
                name={userName}
              />
            </div>
          </div>

          <div className="comment_content">
            <div className="d-md-flex align-items-end">
              <div className="comment_content-body">
                <p
                  className="break-word"
                  dangerouslySetInnerHTML={{ __html: this.renderBody(body) }}
                />
                <div className="d-flex justify-content-between">
                  {internal ? (
                    <span>( Internal Note )</span>
                  ) : _.isEqual(notificationType, 'sms') ? (
                    this.renderSmsDeliveredStatus()
                  ) : _.isEqual(notificationType, 'email') ? (
                    this.renderMailOpenCount()
                  ) : (
                    <div />
                  )}

                  {editable && currentUserId === userId && (
                    <span className="comment-options">
                      <span onClick={() => this.toggleEditBox(comment)}>
                        <a className="text-black-50">
                          <i className="fas fa-edit"></i>
                        </a>
                      </span>
                      <span className="ml-15" onClick={() => this.handleDelete(id)}>
                        <a className="text-black-50">
                          <i className="fas fa-trash-alt"></i>
                        </a>
                      </span>
                    </span>
                  )}
                </div>
              </div>
              {this.formatDate(createdAt)}
            </div>

            {sentiment && this.renderSentimentSection()}

            {!_.isEmpty(comment['attachments']) && (
              <AttachmentPreviewSlider attachments={comment['attachments']}/>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Comment.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func.isRequired,
  toggleEditBox: PropTypes.func.isRequired,
  onCommentAssetsChange: PropTypes.func.isRequired,
  comment: PropTypes.object.isRequired,
  currentUserId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  editable: PropTypes.bool,
};

Comment.defaultProps = {
  editable: true,
};

export default withErrorHandler(Comment);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import isEqual from 'lodash/isEqual';

import InitialsAvatar from '../common/Avatar';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { getUnformattedPhoneNumber } from '../common/utils';

import CustomNewTextInput from '../common/inputs/NewTextInput';
import './editProfileStyles.scss';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import AssetUploader from '../filestack/AssetUploader';
import { alertErrorNotifications } from '../folders/utils';

const EditProfile = ({ attributes, csrfToken, links, avatarOptions }) => {
  const [profileInfo, setProfileInfo] = useState(attributes);
  const [loading, setLoading] = useState(false);

  const handleProfileInfoChange = (attributeValue, attributeName) => {
    let updatedValue = attributeValue;
    if (isEqual(attributeName, 'mobile')) {
      updatedValue = getUnformattedPhoneNumber(
        attributeValue.phone,
        attributeValue.data
      );
    }

    setProfileInfo(prevInfo => ({
      ...prevInfo,
      [attributeName]: updatedValue,
    }));
  };

  const updateProfile = async e => {
    e.preventDefault();
    await new Http()
      .onBegin(() => setLoading(true))
      .useAlerts()
      .doesRedirect(true)
      .setToken(csrfToken)
      .put(links.update)
      .setPostData({ member: profileInfo })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { redirectionUrl } }) => {
        window.location.href = links.redirectionUrl || redirectionUrl;
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  const uploadNewAvatar = async ({ filesUploaded }) => {
    if (filesUploaded?.length > 0) {
      await new Http()
        .onBegin(() => setLoading(true))
        .useAlerts()
        .doesRedirect(true)
        .setToken(csrfToken)
        .post(links.setProfilePic)
        .setPostData({ asset: filesUploaded[0] })
        .useAPIDataFormatters({
          snakifyRequestData: true,
          camelizeResponseData: true,
        })
        .onSuccess(({ data: { avatar } }) => {
          handleProfileInfoChange(avatar, 'avatar');
          setLoading(false);
        })
        .onError(() => {
          setLoading(false);
        })
        .exec();
    }
  };

  const renderAvatarUploader = ({ onPick }) => (
    <button
      type="button"
      className="avatar-uploader btn btn-dark btn-icon btn-circle btn-icon-only"
      onClick={onPick}
      disabled={loading}
    >
      <i className="fa fa-camera" />
    </button>
  );

  return (
    <div className="profile-form-container my-5">
      {loading && <Spinner />}
      <div className="user-avatar-container">
        <InitialsAvatar
          url={profileInfo.avatar}
          name={`${profileInfo.firstName} ${profileInfo.lastName}`}
          className="user-avatar"
        />
        <AssetUploader
          readOnly={loading}
          inputColClassName={''}
          labelClassName={''}
          showLabel={false}
          label={''}
          options={avatarOptions}
          onSuccess={uploadNewAvatar}
          onError={() => alertErrorNotifications('Error Uploading Avatar')}
          renderUploader={renderAvatarUploader}
        />
      </div>
      <form onSubmit={updateProfile}>
        <CustomNewTextInput
          label={'First Name'}
          name={'firstName'}
          value={profileInfo['firstName'] || ''}
          handleChange={({ target }) =>
            handleProfileInfoChange(target.value, 'firstName')
          }
          required
          helperText="*First name must be required"
        />

        <CustomNewTextInput
          label={'Last Name'}
          name={'lastName'}
          value={profileInfo['lastName'] || ''}
          handleChange={({ target }) =>
            handleProfileInfoChange(target.value, 'lastName')
          }
          required
          helperText="*Last name must be required"
        />

        <CustomNewTextInput
          label={'Company'}
          name={'company'}
          value={profileInfo['company'] || ''}
          handleChange={({ target }) =>
            handleProfileInfoChange(target.value, 'company')
          }
        />

        <CustomNewTextInput
          label={'Title'}
          name={'title'}
          value={profileInfo['title'] || ''}
          handleChange={({ target }) =>
            handleProfileInfoChange(target.value, 'title')
          }
          className="mb-0"
        />

        <div className="text-black-50 font-weight-normal ml-2 font-12">
          Phone Number
        </div>
        <PhoneInput
          country={'us'}
          placeholder="Please Enter Mobile Number..."
          value={profileInfo['mobile'] || ''}
          countryCodeEditable={false}
          autoFormat={false}
          onChange={(phone, data) =>
            handleProfileInfoChange({ phone, data }, 'mobile')
          }
          inputProps={{
            name: 'mobile',
          }}
        />

        <div className="text-center mt-5">
          <button
            type="submit"
            className="btn btn-darkred px-5"
            disabled={loading}
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

EditProfile.propTypes = {
  attributes: PropTypes.object,
  csrfToken: PropTypes.string,
  links: PropTypes.shape({
    redirectionUrl: PropTypes.string,
    setProfilePic: PropTypes.string,
    update: PropTypes.string,
  }),
  avatarOptions: PropTypes.object,
};

export default withErrorHandler(EditProfile);

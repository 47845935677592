import React from 'react';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import QuizGallery from './QuizGallery';
import OptionSelector from './QuizOptionSelector';

const Options = ({
  option,
  idx,
  questionToDisplay,
  currentQuestionAnswers,
  markAnswerForQuestion,
}) => {
  return (
    <div className={'row'} key={option.id}>
      <div className="offset-md-1 col-md-1">
        {!questionToDisplay.isMultipleChoice && (
          <OptionSelector
            name={'option'}
            type="radio"
            value={option.id}
            id={option.id}
            label={idx + 1}
            checked={_.includes(currentQuestionAnswers, option.id)}
            onChange={markAnswerForQuestion(questionToDisplay.id, option.id, false)}
          />
        )}
        {questionToDisplay.isMultipleChoice && (
          <OptionSelector
            id={option.id}
            label={idx + 1}
            type="checkbox"
            checked={_.includes(currentQuestionAnswers, option.id)}
            onChange={markAnswerForQuestion(questionToDisplay.id, option.id, true)}
          />
        )}
      </div>
      <div className="col-md-10">
        <h4>{option.title}</h4>
        <br />
        <QuizGallery hideFileInfo assets={option.existingAttachments || []} />
        <br />
      </div>
    </div>
  );
};

export default withErrorHandler(Options);

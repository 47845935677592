import React from 'react';
import PropTypes from 'prop-types';

import AssetFilterLink from './AssetFilterLink';

import { ASSET_FILTER_OPTIONS } from '../../../../constants';
import { getAssetTypeFromLocationHash } from '../../../../../utils';

const AssetsFilterOptions = ({ onFilterClick }) => {
  const assetTypeFromLocationHash = getAssetTypeFromLocationHash();

  return _.map(ASSET_FILTER_OPTIONS, (assetType, assetTypeLabel) => (
    <AssetFilterLink
      key={`${assetType}`}
      onLinkClick={() => onFilterClick(assetType)}
      isActive={assetTypeFromLocationHash === assetType}
      label={assetTypeLabel}
    />
  ));
};

AssetsFilterOptions.propTypes = {
  onFilterClick: PropTypes.func.isRequired,
};

export default AssetsFilterOptions;

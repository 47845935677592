import React from 'react';
import PropTypes from 'prop-types';

import RadioInput from './RadioInput';
import ButtonContent from './ButtonContent';

const RadioButtonWrapper = ({
  inputName,
  inputId,
  inputValue,
  checked,
  onInputChange,
  option,
  className,
  inputClassName,
  labelClassName,
  isDisabled,
  ...otherProps
}) => {
  const customClassName = [
    'm-2 flex-center justify-content-start',
    className,
  ].join(' ');
  return (
    <label className={customClassName} {...otherProps}>
      <RadioInput
        className={inputClassName}
        name={inputName}
        id={inputId}
        value={inputValue}
        checked={checked}
        onChange={onInputChange}
        isDisabled={isDisabled}
      />
      <ButtonContent className={labelClassName} option={option} />
    </label>
  );
};

RadioButtonWrapper.propTypes = {
  className: PropTypes.string,
  inputName: PropTypes.string,
  inputId: PropTypes.string,
  inputValue: PropTypes.string,
  checked: PropTypes.bool,
  onInputChange: PropTypes.func,
  option: PropTypes.string,
  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default RadioButtonWrapper;

import React from 'react';
import PropTypes from 'prop-types';

// Blocks the UI / Portlet / Element enclosing with a spinner.
const PortletBlocker = ({ children, loading }) => (
  <div id="kt_blockui_1_content" style={{ position: 'relative' }}>
    {loading && (
      <React.Fragment>
        <div className="blockUI d-none"></div>
        <div
          className="blockUI blockOverlay m-0 p-0 w-100 h-100 border-none"
          style={{
            zIndex: 10,
            top: 0,
            left: 0,
            backgroundColor: 'rgb(0, 0, 0)',
            opacity: 0.05,
            cursor: 'wait',
            position: 'absolute',
          }}
        ></div>
        <div
          className="blockUI blockMsg blockElement p-0 m-0 w-auto text-center border-0"
          style={{
            zIndex: 1011,
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: 'rgb(0, 0, 0)',
            cursor: 'wait',
          }}
        >
          <div className="kt-spinner  kt-spinner--brand "></div>
        </div>
      </React.Fragment>
    )}
    {children}
  </div>
);

PortletBlocker.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
};

PortletBlocker.defaultProps = {
  loading: false,
};

export default PortletBlocker;

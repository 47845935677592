import React from 'react';
import ErrorFallback from '../common/presentational/ErrorFallback';
import Http from '../common/Http';

export function withErrorHandler(WrappedComponent) {
  class ErrorHandler extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        hasError: false,
      };
    }

    componentDidCatch(error, info) {
      this.setState(
        {
          hasError: true,
        },
        () => this.reportError(error, info)
      );
    }

    reportError = async (error, info) => {
      await new Http(this)
        .setToken(this.props.csrfToken)
        .post('/report_error')
        .setPostData({
          error: error.toString(),
          info: {
            ...info,
            stack: error.stack,
            location: window.location.href,
          },
        })
        .exec();
    };

    render() {
      const { hasError } = this.state;

      if (hasError) return <ErrorFallback />;

      return <WrappedComponent {...this.props} />;
    }
  }

  return ErrorHandler;
}

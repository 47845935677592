import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AssetManager from '../../filestack/AssetManager';
import FolderContainer from './FolderContainer';
import AssetsContainer from './FolderContainer/AssetsContainer';
import FolderSidebar from './FolderContainer/FolderSidebar';
import FolderHeader from './FolderHeader';

import {
  ASSET_TYPE_HASH_PARAM,
  ASSET_MIMETYPE_FILTER_PREDICATES,
  DEFAULT_ASSET_FILTER_TYPE,
} from '../common/constants';
import { getAssetTypeFromLocationHash } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class ResourceFolderAssets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayAssetType: DEFAULT_ASSET_FILTER_TYPE,
    };
  }

  componentDidMount = async () => {
    this.setDisplayAssetTypeFromLocationHash();

    window.onpopstate = _event => {
      this.setDisplayAssetTypeFromLocationHash();
    };
  };

  setDisplayAssetTypeFromLocationHash = () => {
    const displayAssetType = getAssetTypeFromLocationHash();

    this.setState({
      displayAssetType,
    });
  };

  handleAssetFilterClick = ({ assetType }) => {
    window.location.hash = `${ASSET_TYPE_HASH_PARAM}${assetType}`;
  };

  getFilteredAssets = ({ assetType }) => {
    const { assets: existingAssets } = this.props;

    const assetTypeFilterPredicate = ASSET_MIMETYPE_FILTER_PREDICATES[assetType];

    const filteredAssets = _.filter(existingAssets, asset =>
      assetTypeFilterPredicate(asset.mimetype)
    );

    return filteredAssets;
  };

  render() {
    const { parentResourceName, assetFolderName } = this.props;
    const { displayAssetType } = this.state;

    const filteredAssets = this.getFilteredAssets({ assetType: displayAssetType });

    return (
      <div>
        <FolderHeader title={`${parentResourceName} - ${assetFolderName}`} />

        {/* Content */}
        <FolderContainer
          folderSidebar={
            <FolderSidebar
              showAssetFilterOption={true}
              fileProps={{
                handlers: {
                  onAssetFilterClick: this.handleAssetFilterClick,
                },
              }}
              folderProps={{
                data: {
                  folders: [],
                  foldersUrlProvider: () => {},
                },
              }}
            />
          }
          assetsHolder={
            <AssetsContainer>
              {!_.isEmpty(filteredAssets) ? (
                <AssetManager
                  showUploader={false}
                  assets={[]}
                  existingAssets={filteredAssets}
                  shareEnablerPredicate={_assetId => false}
                />
              ) : (
                <span>No Files Found</span>
              )}
            </AssetsContainer>
          }
        />
      </div>
    );
  }
}

ResourceFolderAssets.propTypes = {
  assets: PropTypes.array.isRequired,
  parentResourceName: PropTypes.string.isRequired,
  assetFolderName: PropTypes.string.isRequired,
};

ResourceFolderAssets.defaultProps = {
  assets: [],
  parentResourceName: '',
  assetFolderName: '',
};

export default withErrorHandler(ResourceFolderAssets);

import React, { Fragment, useState, memo } from 'react';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import PropTypes from 'prop-types';
import { formatDateTime } from '../../../../common/utils';
import Modal from '../../../../filestack/modal';
import { withErrorHandler } from '../../../../hoc/withErrorHandler';
import BackgroundGradient from '../../../../common/BackgroundGradient';

const Certificate = ({
  certificate: {
    name,
    attachments,
    status,
    issuedDate,
    expirationDate,
    parentCertification,
  },
  term,
}) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const closeViewModal = () => setIsInfoModalOpen(false);

  const renderModalContent = () => (
    <div>
      <h3 className="text-capitalize">{name}</h3>
      {!isEmpty(attachments) && (
        <div className="row no-gutters">
          {attachments.map(attachment => (
            <div className="col-md-6 image-preview p-1" key={attachment.handle}>
              <BackgroundGradient
                imgUrl={attachment.url}
                text={attachment.handle}
                objectFit="contain"
              />
              <a
                href={attachment?.links?.download}
                className="download-link app-text-primary bg-white rounded font-16"
              >
                <i className="fas fa-cloud-download-alt"></i>
              </a>
            </div>
          ))}
        </div>
      )}
      <div className="row text-black-50 mt-4">
        <div className="col-md-6">
          <strong>Status: </strong>
          <p>{status}</p>
          <strong>Earned For: </strong>
          <p>{parentCertification?.learnable?.type}</p>
          <strong>{parentCertification?.learnable?.type} Name: </strong>
          <p>{parentCertification?.learnable?.name}</p>
        </div>
        <div className="col-md-6">
          {expirationDate && (
            <Fragment>
              <strong>Expiration Date: </strong>
              <p>
                <i className="far fa-calendar-alt mr-2"></i>
                {formatDateTime({ date: expirationDate, formatTime: false })}
              </p>
            </Fragment>
          )}
          {issuedDate && (
            <Fragment>
              <strong>Issued Date: </strong>
              <p>
                <i className="far fa-calendar-alt mr-2"></i>
                {formatDateTime({ date: issuedDate, formatTime: false })}
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );

  const renderInfoModal = () => (
    <Modal
      onClose={closeViewModal}
      title={`${term.singular} Details`}
      large
      renderContent={renderModalContent}
      renderFooter={() => (
        <div className="text-right w-100">
          <button className="btn btn-darkred" onClick={closeViewModal}>
            Close
          </button>
        </div>
      )}
    />
  );

  return (
    <Fragment>
      <div className="learning-card" onClick={() => setIsInfoModalOpen(true)}>
        <div className="learning-card__image">
          <BackgroundGradient
            imgUrl={parentCertification?.headerImageUrl || ''}
            text={name}
            objectFit="contain"
          />
        </div>
        <div className="learning-card__details">
          <h5 className="learning-card__name" title={name}>
            {name}
            <span
              className={`mx-2 certificate-status__dot ${status.toLowerCase()}`}
            ></span>
            <span className="font-10">{status}</span>
          </h5>
          <div className="font-11 mt-3">
            {!isNil(issuedDate) && (
              <span>
                Issued:{' '}
                <span className="mx-2 kt-font-bold">
                  {formatDateTime({ date: issuedDate, formatTime: false })}
                </span>
              </span>
            )}
            {!isNil(expirationDate) && (
              <span>
                Expiry:{' '}
                <span className="mx-2 kt-font-bold">
                  {formatDateTime({ date: expirationDate, formatTime: false })}
                </span>
              </span>
            )}
          </div>
        </div>
      </div>
      {isInfoModalOpen && renderInfoModal()}
    </Fragment>
  );
};

Certificate.propTypes = {
  certificate: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    attachments: PropTypes.array,
    issuedDate: PropTypes.string,
    status: PropTypes.string,
    expirationDate: PropTypes.string,
    parentCertification: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      headerImageUrl: PropTypes.string,
      learnable: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
  }),
  term: PropTypes.shape({
    singular: PropTypes.string.isRequired,
    plural: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(memo(Certificate));

import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import CourseTable from '../Table';
import { withErrorHandler } from '../../../hoc/withErrorHandler';
import Spinner from '../../../common/presentational/spinner';
import Http from '../../../common/Http';

const COURSE_SUB_TABS = ['published', 'unpublished', 'archived'];

const CourseTab = ({ fetchUrl, tenantTerms, csrfToken }) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [activeTab, setActiveTab] = useState(COURSE_SUB_TABS[0]);
  const links = useRef({});
  const publishedConfig = useRef({});
  const keywordSearchTimeoutRef = useRef(null);

  const [isSortable, courseLinks] = useMemo(() => (
    activeTab === 'published'
      ? [
          publishedConfig.current.sortable,
          {
            ...links.current,
            changeOrder: publishedConfig.current.links?.changeOrder
          },
        ]
      : [false, links.current]
  ), [activeTab, links.current, publishedConfig.current]);

  const fetchCourses = useCallback((keyword = '') => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${fetchUrl}?status=${activeTab}&keyword=${keyword}`)
      .onSuccess((res) => {
        links.current = res.data.links;
        publishedConfig.current = res.data.publishedConfig;
        setCourses(res.data.courses);
        setLoading(false);
      })
      .onError(() => setLoading(false))
      .exec();
  }, [activeTab, csrfToken]);

  useEffect(() => {
    fetchCourses();
  }, [activeTab]);

  useEffect(() => {
    clearTimeout(keywordSearchTimeoutRef.current);
    keywordSearchTimeoutRef.current = setTimeout(() => fetchCourses(keyword), 500);
  }, [keyword]);

  const renderTabs = () => (
    <ul className="nav nav-tabs justify-content-center" role="tablist">
      {COURSE_SUB_TABS.map(tab => (
        <li className="nav-item" key={tab}>
          <a
            className={`nav-link${activeTab === tab ? ' active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            <strong>{tab}</strong>
          </a>
        </li>
      ))}
    </ul>
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="d-flex justify-content-center flex-wrap-reverse mb-2">
        <div className="new-theme-tabs align-self-baseline courses-admin-tabs">
          <div className="d-flex">{renderTabs()}</div>
        </div>
      </div>
      <div className="tab-content">
        <CourseTable
          courses={courses}
          tenantTerms={tenantTerms}
          sortable={isSortable}
          csrfToken={csrfToken}
          links={courseLinks}
          keyword={keyword}
          onKeywordChange={setKeyword}
        />
      </div>
    </div>
  );
};

CourseTab.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({}).isRequired,
};

export default withErrorHandler(CourseTab);

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import { withErrorHandler } from '../hoc/withErrorHandler';
import './course_report.scss';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import isEmpty from 'lodash/isEmpty';
import TextInput from '../common/inputs/text';

const CurriculumReport = ({
  csrfToken,
  curriculumName,
  courses,
  usersProgress,
  tenantTerms: { termUser } = {},
  links,
  meta,
  applyStoreFilter = false,
  noHeader = false,
}) => {
  const [keyword, setKeyword] = useState('');
  const [totalPages, setTotalPages] = useState(meta?.totalPages || 0);
  const [currentPage, setCurrentPage] = useState(meta?.currentPage || 0);
  const [totalRecordsCount, setTotalRecordsCount] = useState(meta?.totalRecordsCount || 0);
  const [isLoading, setLoading] = useState(false);
  const [allUsersProgress, setAllUsersProgress] = useState(usersProgress);

  const onKeywordChange = useCallback((e) => setKeyword(e.target.value.trim()), []);
  const onClearFilter = useCallback(() => {
    setKeyword('');
    handleFilter('');
  }, []);

  const handleFilter = (keywordToSearch) => {
    const storeFilter = applyStoreFilter || (new URLSearchParams(window.location.search).get('apply_store_filter'));

    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(links.curriculumReports, {
        page: 0,
        per_page: 20,
        ...(keywordToSearch ? { name: keywordToSearch } : {}),
        ...(storeFilter ? { apply_store_filter: storeFilter } : {}),
      })
      .onSuccess(response => {
        const { data: { usersProgress, meta } } = response;
        setAllUsersProgress(usersProgress.map((a) => ({
          coursesProgress: a.coursesProgress,
          user: a.user,
        })));
        setTotalPages(meta?.totalPages || 0);
        setCurrentPage(meta?.currentPage || 0);
        setTotalRecordsCount(meta?.totalRecordsCount || 0);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting reports!');
      })
      .exec();
  }

  const onClickLoadMore = useCallback(() => {
    const storeFilter = applyStoreFilter || (new URLSearchParams(window.location.search).get('apply_store_filter'));

    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(links.curriculumReports, {
        page: currentPage + 1,
        per_page: 20,
        ...(keyword ? { name: keyword } : {}),
        ...(storeFilter ? { apply_store_filter: storeFilter } : {}),
      })
      .onSuccess(response => {
        const { data: { usersProgress, meta } } = response;
        setAllUsersProgress((prevProgress) => (
          [
            ...prevProgress,
            ...usersProgress.map((a) => ({
              coursesProgress: a.coursesProgress,
              user: a.user,
            }))
          ]
        ));
        setTotalPages(meta.totalPages);
        setCurrentPage(meta.currentPage);
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting reports!');
      })
      .exec();
  }, [csrfToken, currentPage, links]);

  const renderBody = () => (
    <>
      {
        !isEmpty(allUsersProgress) || !keyword
          ?
            (
              <div className="row" style={{ justifyContent: 'center' }}>
                <div className="col-lg-3 pt-10 pb-10">
                  <TextInput
                    placeholder={'Filter Report by User Name'}
                    name={'nameSearch'}
                    value={keyword}
                    onInputChange={onKeywordChange}
                  />
                </div>

                <div className="col-lg-2 pt-10 pb-10">
                  <button
                    className="app-btn-primary m-btn--wide"
                    onClick={() => handleFilter(keyword)}
                    style={{ width: '100%' }}
                  >
                    Filter By User
                  </button>
                </div>

                <div className="col-lg-2 pt-10 pb-10">
                  <button
                    className="app-btn-outline-primary m-btn--wide"
                    onClick={onClearFilter}
                    style={{ width: '100%' }}
                  >
                    Clear Filter
                  </button>
                </div>
              </div>
            )
          : null
      }

      <div className="table-responsive">
        {usersProgress.length > 0 ? (
          <>
            <table
              className="table table-hover table-checkable table-striped dataTable no-footer dtr-inline table__fixed_cell_width"
              role="grid"
            >
              <thead>
                <tr>
                  <th colSpan="1" rowSpan="2" style={{ width: 65 }}>
                    <span>{termUser.plural}</span>
                  </th>
                </tr>

                <tr style={{ height: 220, whiteSpace: 'nowrap' }}>
                  {map(courses, course => (
                    <th className="text-center" key={course.id}>
                      <span
                        className="kt-badge kt-badge--inline kt-badge--pill overflow-hiddenx rotated-name kt-badge--info"
                        title={course.name || '-'}
                      >
                        <span className="text-truncate">
                          {course.name || '-'}
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {map(allUsersProgress, userProgress => (
                  <tr style={{ height: 53 }} key={userProgress?.user?.id}>
                    <td className="column__user-name" width="200px">
                      <span>{userProgress?.user?.name}</span>
                    </td>
                    {courses.map(course => {
                      const [statusIconClass, statusTextClass] = userProgress
                        .coursesProgress[course.id]
                        ? ['la-check', 'text-success']
                        : ['la-close', 'text-danger'];

                      return (
                        <td
                          className="text-center"
                          key={course.id + '-' + userProgress?.user?.id}
                        >
                          <span className={statusTextClass}>
                            <i className={`la la-bold ${statusIconClass}`} />
                          </span>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <center>
              {
                currentPage + 1 < totalPages
                  ?
                    (
                      <button
                        className="btn btn-primary"
                        onClick={onClickLoadMore}
                      >
                        Load More
                      </button>
                    )
                  : null
              }
            </center>
            {
              totalPages > 1
                ?
                  (
                    <div>
                      Showing {allUsersProgress.length} of {totalRecordsCount} records
                    </div>
                  )
                : null
            }
            {
              isLoading && <Spinner />
            }
          </>
        ) : (
          <h3
            className="text-center"
            style={{ marginTop: '100px' }}
          >
            No learners found to show progress!
          </h3>
        )}
      </div>
    </>
  );

  if (noHeader) {
    return renderBody();
  }

  return (
    <div className="kt-portlet kt-portlet--tabs">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <span className="kt-font-info">{curriculumName}</span> - Reports
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <span className="pull-right" />
        </div>
      </div>
      <div className="kt-portlet__body">
        {renderBody()}
      </div>
    </div>
  );
};

CurriculumReport.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  curriculumName: PropTypes.string.isRequired,
  courses: PropTypes.array.isRequired,
  usersProgress: PropTypes.array.isRequired,
  tenantTerms: PropTypes.object.isRequired,
};

export default withErrorHandler(CurriculumReport);

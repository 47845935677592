import isNull from 'lodash/isNull';

export const getGroupedByStatus = ({ dataArray = [] }) => {
  let group = dataArray.reduce((r, a) => {
    r[a.published] = r[a.published] || [];
    r[a.published].push(a);
    return r;
  }, {});
  let formattedGrouped = [
    { label: 'Published', options: group.true },
    { label: 'Unpublished', options: group.false },
  ];
  return formattedGrouped;
};

export const getForumCloningTitle = forumCloneStatus => {
  switch (forumCloneStatus) {
    case null: {
      return 'Only available for Cloned Courses';
    }
    case true: {
      return 'Forum Cloning is already completed';
    }
    case false:
    default:
      return 'Initiate Forum Cloning';
  }
};

export const checkIsForumClonable = forumCloneStatus => {
  return !isNull(forumCloneStatus) && !forumCloneStatus;
};

import React from 'react';
import useAdminTabContext from '../hooks/useAdminTabContext';

const AdminRenderTabs = () => {
  const { tabs, activeTab, setActiveTab } = useAdminTabContext();

  const renderTabs = () => (
    <ul className="nav nav-tabs justify-content-center" role="tablist">
      {tabs.map(tab => (
        <li className="nav-item" key={tab.key}>
          <a
            className={`nav-link${activeTab?.key === tab.key ? ' active' : ''}`}
            onClick={() => setActiveTab(tab)}
          >
            <strong>{tab.label}</strong>
          </a>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="d-flex justify-content-center flex-wrap-reverse">
      <div className="new-theme-tabs pt-2 align-self-baseline courses-admin-tabs">
        <div className="d-flex">{renderTabs()}</div>
      </div>
    </div>
  );
};

export default AdminRenderTabs;

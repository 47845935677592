import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Table,
  WindowScroller,
} from 'react-virtualized';
import 'react-virtualized/styles.css';

import useKeyboardKey from '../../common/hooks/useKeyboardKeys';
import { getCategoryRandomColors } from '../utils';

import { ForumCloningContext } from './index';

const TopicsTable = ({ filteredData }) => {
  const windowScrollerRef = useRef(true);
  const autoSizerTableRef = useRef(true);

  const { forumDataState, forumTopicsTableState, dispatchTopicTableOperation } =
    useContext(ForumCloningContext);
  const { categories } = forumDataState;
  const { selectedTopicIds, activeRow } = forumTopicsTableState;

  useEffect(() => {
    if (activeRow < 0) {
      dispatchTopicTableOperation({ type: 'setInitialActiveRow' });
    }
    if (activeRow + 1 > filteredData.length) {
      dispatchTopicTableOperation({
        type: 'setMaxActiveRow',
        length: filteredData.length,
      });
    }
  }, [activeRow, filteredData.length, dispatchTopicTableOperation]);

  useKeyboardKey(38, () => dispatchTopicTableOperation({ type: 'setPrevRow' }));
  useKeyboardKey(40, () => dispatchTopicTableOperation({ type: 'setNextRow' }));
  useKeyboardKey(13, () => {
    dispatchTopicTableOperation({
      type: 'handleEnterKey',
      filtered: filteredData,
    });
  });

  const tableRowRenderer = ({ index, style, rowData, ...restProps }) => {
    const isSelected = selectedTopicIds.includes(rowData.id);
    const customBackground = index === activeRow ? '#f7f8fa' : '#fff';
    return defaultTableRowRenderer({
      style: {
        ...style,
        backgroundColor: !isSelected ? customBackground : '#b0cee8b8',
      },
      ...restProps,
    });
  };

  const onChangeHeaderCheckBox = data => {
    dispatchTopicTableOperation({
      type: 'toggleHeaderCheckBox',
      payload: {
        selectedTopicIds: data.checked ? filteredData.map(row => row.id) : [],
      },
    });
  };

  const isAllTopicsSelected =
    selectedTopicIds.length === filteredData.length &&
    !isEmpty(selectedTopicIds);

  return (
    <WindowScroller ref={windowScrollerRef}>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <AutoSizer disableHeight>
          {({ width }) => (
            <Table
              ref={autoSizerTableRef}
              className="topics-table"
              headerHeight={52}
              width={width < 450 ? 450 : width}
              height={height}
              autoHeight
              rowCount={filteredData.length}
              rowGetter={({ index }) => filteredData[index]}
              rowHeight={48}
              rowRenderer={tableRowRenderer}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              overscanRowCount={15}
              scrollTop={scrollTop}
              noRowsRenderer={() => (
                <h3
                  className="text-center p-3"
                  style={{ backgroundColor: '#f7f8fa' }}
                >
                  No Topics To Show
                </h3>
              )}
            >
              <Column
                disableSort
                dataKey="checkbox"
                width={30}
                flexShrink={0}
                flexGrow={1}
                headerRenderer={() => (
                  <input
                    type="checkbox"
                    checked={isAllTopicsSelected}
                    onChange={({ target }) => onChangeHeaderCheckBox(target)}
                  />
                )}
                cellRenderer={({ rowIndex }) => (
                  <input
                    type="checkbox"
                    key={rowIndex}
                    id={rowIndex}
                    checked={selectedTopicIds.includes(
                      filteredData[rowIndex].id
                    )}
                    onChange={({ target }) => {
                      dispatchTopicTableOperation({
                        type: 'toggleOnRowCheckBox',
                        filtered: filteredData,
                        rowId: target.id,
                      });
                    }}
                  />
                )}
              />
              <Column
                dataKey="title"
                label="Topic"
                disableSort
                width={250}
                flexShrink={0}
                flexGrow={1}
                cellRenderer={({ cellData }) => <span>{cellData}</span>}
              />
              <Column
                dataKey="categoryId"
                label="Category"
                disableSort
                width={250}
                flexShrink={0}
                flexGrow={1}
                cellRenderer={({ cellData }) => {
                  const categoryName = find(
                    categories,
                    category => category.id === cellData
                  );
                  const categoryColor = getCategoryRandomColors(
                    categoryName.name
                  );
                  return (
                    <div className="category-tag px-1 text-truncate">
                      <span
                        className="category-color-icon"
                        style={{
                          backgroundColor: categoryColor,
                        }}
                      ></span>
                      {categoryName.name}
                    </div>
                  );
                }}
              />
              <Column
                dataKey="body"
                label="Description"
                disableSort
                width={300}
                flexShrink={0}
                flexGrow={1}
                cellRenderer={({ cellData }) => <span>{cellData}</span>}
              />
            </Table>
          )}
        </AutoSizer>
      )}
    </WindowScroller>
  );
};

TopicsTable.propTypes = {
  filteredData: PropTypes.array.isRequired,
  // categories: PropTypes.array.isRequired,
};

export default TopicsTable;

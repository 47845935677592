import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import split from 'lodash/split';
import startsWith from 'lodash/startsWith';

// Reverted the custom components to the class components instead of functional.
// Since the ref issue related to the package form builder is causing a data loss.
// cb-react-form-builder package is accepting class components and its expecting refs.
class CustomTextInput extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: {
        firstName: '',
        lastName: '',
      },
    };

    const {
      answerData,
      data: { field_name },
    } = props;

    if (!isNil(answerData) && !isNil(answerData[field_name])) {
      this.state.value = answerData[field_name];

      if (this.isNameInput()) {
        const nameParts = this.getNameParts(answerData[field_name]);

        this.state.name.firstName = nameParts.firstName;
        this.state.name.lastName = nameParts.lastName;
      }
    } else {
      this.state.value = '';
    }
  }

  handleValueChange = e => {
    const { target } = e;
    this.setState({
      value: target.value,
    });
  };

  handleNameChange = nameIdentifier => e => {
    const { target } = e;
    this.setState(
      {
        name: {
          ...this.state.name,
          [nameIdentifier]: target.value,
        },
      },
      () => {
        const {
          name: { firstName, lastName },
        } = this.state;

        this.setState({
          value: `${firstName} ${lastName}`,
        });
      }
    );
  };

  isNameInput = () => {
    const {
      data: { field_name },
    } = this.props;

    return startsWith(field_name, 'name_');
  };

  getNameParts = (fullName = '') => {
    const nameSplits = split(fullName, ' ');

    if (nameSplits.length === 1) {
      return {
        firstName: nameSplits[0] || '',
        lastName: '',
      };
    }
    return {
      firstName: split(fullName, ' ', nameSplits.length - 1).join(' '),
      lastName: nameSplits[nameSplits.length - 1],
    };
  };

  render() {
    const {
      value,
      name: { firstName, lastName },
    } = this.state;

    const { data, inputType = 'text' } = this.props;
    const { field_name, read_only } = data;

    if (!!field_name && this.isNameInput()) {
      return (
        <div style={{ display: 'flex' }}>
          <input
            onChange={this.handleNameChange('firstName')}
            type={inputType}
            readOnly={read_only}
            className="form-control"
            name={field_name}
            value={firstName}
            style={{ marginRight: '20px' }}
            placeholder="First Name"
          />
          <input
            onChange={this.handleNameChange('lastName')}
            type={inputType}
            readOnly={read_only}
            className="form-control"
            name={field_name}
            value={lastName}
            placeholder="Last Name"
          />
        </div>
      );
    }

    return (
      <input
        onChange={this.handleValueChange}
        type={inputType}
        readOnly={read_only}
        className="form-control"
        name={field_name}
        value={value}
      />
    );
  }
}

CustomTextInput.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string.isRequired,
    field_name: PropTypes.string.isRequired,
    read_only: PropTypes.bool,
  }),
  answerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  inputType: PropTypes.string,
};

export default CustomTextInput;

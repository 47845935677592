import React from 'react';
import PropTypes from 'prop-types';
import EventsTable from './EventsTable';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';

const SessionViewPage = ({
  visit: { userName, startedAt, landingPage, deviceType, browser, os },
  visit,
  ...restProps
}) => (
  <div className="kt-portlet kt-portlet--mobile">
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title text-center">User Session</h3>
      </div>
    </div>
    <div className="kt-portlet__body">
      <div className="kt-widget12 text-center">
        <div className="kt-widget12__content">
          <div className="kt-widget12__item">
            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">User</span>
              <span className="kt-widget12__value">{userName}</span>
            </div>

            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">Started At</span>
              <span className="kt-widget12__value">
                {formatDateTime({ date: startedAt })}
              </span>
            </div>
          </div>
          <div className="kt-widget12__item">
            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">Landing Page</span>
              <span className="kt-widget12__value">{landingPage}</span>
            </div>

            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">Device Type</span>
              <span className="kt-widget12__value">{deviceType}</span>
            </div>
          </div>
          <div className="kt-widget12__item">
            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">Browser</span>
              <span className="kt-widget12__value">{browser}</span>
            </div>
            <div className="kt-widget12__info">
              <span className="kt-widget12__desc">OS</span>
              <span className="kt-widget12__value">{os}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title text-center">Events</h3>
      </div>
    </div>
    <div className="kt-portlet__body">
      <EventsTable visit={visit} {...restProps} />
    </div>
  </div>
);

SessionViewPage.defaultProps = {
  resourceList: [],
};

SessionViewPage.propTypes = {
  visit: PropTypes.shape({
    userName: PropTypes.string,
    startedAt: PropTypes.string,
    landingPage: PropTypes.string,
    deviceType: PropTypes.string,
    browser: PropTypes.string,
    os: PropTypes.string,
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.object.isRequired,
  resourceList: PropTypes.array,
};

export default withErrorHandler(SessionViewPage);

import React from 'react';
import PropTypes from 'prop-types';
import ReactFilestack, { client } from 'filestack-react';
import FormRow from '../common/presentational/formRow';

const renderDefaultUploader = ({ onPick, readOnly }) => (
  <div>
    <button
      type="button"
      className="btn app-btn-primary"
      disabled={`${readOnly ? 'disabled' : ''}`}
      onClick={onPick}
    >
      <span>
        <i className="la la-plus" />
        <span>Select a File</span>
      </span>
    </button>
  </div>
);

class AssetUploader extends React.PureComponent {
  getPosition = options => {
    return new Promise(function(resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  };

  getCoordinates = position => {
    if (position && position.coords) {
      let { coords } = position;
      return {
        latitude: coords.latitude,
        longitude: coords.longitude,
      };
    } else {
      return {
        latitude: null,
        longitude: null,
      };
    }
  };

  handleOnSuccess = async ({ filesUploaded }) => {
    let position = {};
    let coords = {
      latitude: null,
      longitude: null,
    };
    // if ('geolocation' in navigator) {
    //   try {
    //     position = (await this.getPosition()) || {};
    //     coords = this.getCoordinates(position);
    //   } catch (e) {
    //     coords = this.getCoordinates({});
    //   }
    // }
    const locationInjectedFiles = _.map(filesUploaded, file => ({ ...file, ...coords }));
    this.props.onSuccess({ filesUploaded: locationInjectedFiles });
  };

  render() {
    const {
      showLabel,
      label,
      options,
      onSuccess,
      onError,
      renderUploader,
      rowClassName,
      labelClassName,
      inputColClassName,
      readOnly,
    } = this.props;

    if (showLabel) {
      return (
        <FormRow
          className={rowClassName}
          labelClassName={labelClassName}
          inputColClassName={inputColClassName}
          label={label}
        >
          <ReactFilestack
            apikey={process.env['FILESTACK_ID']}
            actionOptions={options}
            onSuccess={this.handleOnSuccess}
            onError={onError}
            customRender={props => renderUploader({ ...props, readOnly: readOnly })}
          />
        </FormRow>
      );
    } else {
      return (
        <ReactFilestack
          apikey={process.env['FILESTACK_ID']}
          actionOptions={options}
          onSuccess={this.handleOnSuccess}
          onError={onError}
          customRender={props => renderUploader({ ...props, readOnly: readOnly })}
        />
      );
    }
  }
}

AssetUploader.propTypes = {
  readOnly: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

AssetUploader.defaultProps = {
  showLabel: true,
  label: 'Attachments',
  readOnly: false,
  options: {
    fromSources: ['local_file_system'],
    maxFiles: 10,
    rootId: 'new-drop-pane',
  },
  renderUploader: renderDefaultUploader,
};

export default AssetUploader;

import React from 'react';
import './pageNotFound.scss';
import NotFoundImage from './NotFoundImg.jsx';

const PageNotFound = ({ links: { homePage } }) => (
  <div className="page-not-found-container">
    <div className="svg-img-container">
      <NotFoundImage />
    </div>
    <div className="page-not-found-content">
      <h2>Page Not Found</h2>
      <h4>
        Please contact admin if you cannot access the application even after logging-in
      </h4>
      <h4 className="text-danger">You may have insufficient permissions</h4>

      <a href={homePage} className="btn btn-outline-success mt-3">
        Home page &#x2192;
      </a>
    </div>
  </div>
);

export default PageNotFound;

export const forumCloneReducer = (state, action) => {
  switch (action.type) {
    case 'setForumData':
    case 'setTopicFilter':
    case 'setCategoryFilter': {
      return { ...state, ...action.payload };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

export const forumTableReducer = (state, action) => {
  switch (action.type) {
    case 'setNextRow': {
      return { ...state, activeRow: state.activeRow + 1 };
    }
    case 'setPrevRow': {
      return { ...state, activeRow: state.activeRow - 1 };
    }
    case 'setInitialActiveRow': {
      return { ...state, activeRow: 0 };
    }
    case 'setMaxActiveRow': {
      const { length } = action;
      const rowId = length === 0 ? 0 : length - 1;
      return { ...state, activeRow: rowId };
    }
    case 'toggleHeaderCheckBox': {
      return { ...state, ...action.payload };
    }
    case 'handleEnterKey': {
      const { activeRow, selectedTopicIds } = state;
      const filteredRow = action.filtered[activeRow].id;
      let isSelectedId = selectedTopicIds.includes(filteredRow);
      return {
        ...state,
        selectedTopicIds: isSelectedId
          ? selectedTopicIds.filter(row => row !== filteredRow)
          : [...selectedTopicIds, filteredRow],
      };
    }
    case 'toggleOnRowCheckBox': {
      const { selectedTopicIds } = state;
      const { rowId } = action;
      const filteredRow = action.filtered[rowId].id;
      let isSelectedId = selectedTopicIds.includes(filteredRow);
      return {
        ...state,
        selectedTopicIds: isSelectedId
          ? selectedTopicIds.filter(row => row !== filteredRow)
          : [...selectedTopicIds, filteredRow],
      };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { getFullAddress } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ProjectGridItem = ({ project, tenantTerms }) => {
  const { typeColor, status, code } = project;
  const { termProject } = tenantTerms;

  return (
    <div id="no-border-grid-item" className="card project-card">
      <div
        className="project-grid-card-color"
        style={{ borderColor: typeColor || '#716aca' }}
      />
      <div className="project-card__head">
        <div className="title-container">
          <h3 className="text-truncate">
            <a href={project.links.show} className="project_name">
              {project.name}
            </a>
          </h3>
          <div className="title-status__container">
            <span className={`project-status__dot ${status}`} />
            <span className={`project-status ${status} text-capitalize`}>
              {status}
            </span>
          </div>
        </div>
        <p>{getFullAddress(project)}</p>
      </div>
      <div className="project-card__body-group">
        <div className="project-card__body pt-0">
          <div className="mt-2">
            <b>Code: </b>
            <span>{code || '-'}</span>
          </div>
        </div>
        <div
          style={{ borderColor: typeColor }}
          className="project-card__foot-container"
        >
          <a href={project.links.show} className="view-project-link">
            View {termProject.singular}
          </a>
        </div>
      </div>
    </div>
  );
};

ProjectGridItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    typeColor: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    links: PropTypes.shape({
      show: PropTypes.string,
    }).isRequired,
  }),
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default withErrorHandler(ProjectGridItem);

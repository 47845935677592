import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import values from 'lodash/values';

import Modal from '../filestack/modal';
import InitialsAvatar from '../common/Avatar';
import TopicForm from './TopicForm';

import { getCategoryRandomColors, renderActivityTime } from './utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import Http from '../common/Http';
import { alertSuccessNotifications } from '../folders/utils';
import Spinner from '../common/presentational/spinner';

const Topic = ({
  topic: currentTopic,
  categories,
  csrfToken,
  currentUserId,
  breadcrumbQueryParams,
  topicAttachmentsConfig,
  isCourseAdmin,
  afterDelete,
  afterTogglePin,
}) => {
  const [topic, setTopic] = useState(currentTopic);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    id,
    poster,
    recentActivityAt,
    repliesCount,
    links,
    categoryId,
    editedAt,
    title,
    pinnedAt,
  } = topic;

  const checkCurrentUserOwner = id => currentUserId === id;

  const handleDelete = () => {
    if (loading) return;

    const confirmDelete = confirm(
      'Are you sure you want to delete? This action is irreversible!'
    );
    if (!confirmDelete) return;

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .delete(links.delete)
      .useAPIDataFormatters({
        camelizeResponseData: true,
      })
      .onSuccess(() => {
        afterDelete(id);
        setLoading(false);
        alertSuccessNotifications('Deleted Successfully');
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  const handleTogglePin = () => {
    if (loading) return;

    new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .useAlerts()
      .post(links.togglePin)
      .useAPIDataFormatters({
        camelizeResponseData: true,
      })
      .onSuccess(() => {
        afterTogglePin();
        setLoading(false);
        alertSuccessNotifications(`${pinnedAt ? 'Unpinned' : 'Pinned'} Topic Successfully`);
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  const renderModalBody = () => (
    <TopicForm
      csrfToken={csrfToken}
      onSuccess={({ data: { topic } }) => setTopic(topic)}
      submitLink={links.update}
      getDataLink={links.show}
      categories={values(categories)}
      defaultSelectValue={categories[categoryId] || ''}
      closeModal={() => setIsEditModalOpen(false)}
      topicAttachmentsConfig={topicAttachmentsConfig}
    />
  );

  const categoryName = categories[categoryId].name;
  const categoryColor = getCategoryRandomColors(categoryName);

  return (
    <Fragment>
      {loading && <Spinner />}
      <tr style={{ backgroundColor: pinnedAt ? '#dddddd1a' : '' }}>
        <td className="td-50">
          <InitialsAvatar
            url={poster.avatar}
            className="profile-info-image"
            name={poster.name}
            size="small"
          />
        </td>
        <td>
          <a
            href={`${links.show}${breadcrumbQueryParams}`}
            className="topic-body text-dark font-15"
          >
            {title}
          </a>
        </td>
        <td className="td-250">
          <div className="category-tag px-1 text-truncate">
            <span
              className="category-color-icon"
              style={{
                backgroundColor: categoryColor,
              }}
            ></span>
            {categoryName}
          </div>
        </td>
        <td className="td-80 text-center font-600 text-greyish">
          {repliesCount}
        </td>
        <td className="td-80 text-center font-600 text-greyish">
          {!!(recentActivityAt || editedAt) &&
            renderActivityTime(recentActivityAt || editedAt)}
        </td>
        <td className="td-80 text-md-center text-greyish">
          {
            isCourseAdmin
              ?
                (
                  <i
                    className={`fas fa-thumbtack mx-2 cursor-pointer pin-icon ${pinnedAt ? 'text-primary' : ''}`}
                    onClick={handleTogglePin}
                  />
                )
              : null
          }
          {(isCourseAdmin || checkCurrentUserOwner(poster.id)) && (
            <Fragment>
              <i
                className="fas fa-pencil-alt mx-2 cursor-pointer edit-icon"
                onClick={() => setIsEditModalOpen(true)}
              />
              <i
                className="fas fa-trash mx-2 cursor-pointer delete-icon"
                onClick={handleDelete}
              />
            </Fragment>
          )}
        </td>
      </tr>
      {isEditModalOpen && (
        /* Added under tr, td tag as wrapper, where the modal is a child of the table and we cannot use the div directly inside tbody of the table. */
        <tr>
          <td>
            <Modal
              title="Edit Topic"
              onClose={() => setIsEditModalOpen(false)}
              renderContent={renderModalBody}
              closeOnEscKey={false}
              closeOnBackdropClick={false}
              large
            />
          </td>
        </tr>
      )}
    </Fragment>
  );
};

Topic.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  breadcrumbQueryParams: PropTypes.string.isRequired,
  topic: PropTypes.shape({
    body: PropTypes.string.isRequired,
    categoryId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    links: PropTypes.shape({
      show: PropTypes.string.isRequired,
      update: PropTypes.string.isRequired,
      delete: PropTypes.string.isRequired,
    }),
    postedAt: PropTypes.string.isRequired,
    editedAt: PropTypes.string,
    poster: PropTypes.shape({
      id: PropTypes.number,
      avatar: PropTypes.string,
      name: PropTypes.string,
    }),
    recentActivityAt: PropTypes.string,
    title: PropTypes.string,
    repliesCount: PropTypes.number.isRequired,
    hasAttachment: PropTypes.bool.isRequired,
  }),
  currentUserId: PropTypes.number.isRequired,
  categories: PropTypes.shape({}),
  isCourseAdmin: PropTypes.bool.isRequired,
  topicAttachmentsConfig: PropTypes.object,
  afterDelete: PropTypes.func,
};

export default withErrorHandler(React.memo(Topic));

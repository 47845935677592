import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../../common/inputs/Toggle';
import ColorTheme from './ColorTheme';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import { alertErrorNotifications } from '../../folders/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import Modal from '../../filestack/modal';
import FontCustomization from './FontCustomization';

const ThemePersonalization = ({
  theme: themes,
  defaultTheme,
  csrfToken,
  links,
  fontConfig,
}) => {
  const [theme, setTheme] = useState(themes);
  const [font, setFont] = useState(
    fontConfig.currentFontFamily || fontConfig.permittedFontFamilies[0]
  );
  const [loading, setLoading] = useState(false);
  const [enableSaveButton, setEnableSaveButton] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = async () => {
    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .put(links?.settingsPath)
      .useAlerts()
      .doesRedirect(true)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .setPostData({ tenant: { theme, fontFamily: font } })
      .onSuccess(({ data }) => {
        window.location.href = data.redirectionUrl;
      })
      .onError(err => {
        setLoading(false);
      })
      .exec();
  };

  const onChange = (key, value) => {
    setTheme(theme => ({
      ...theme,
      [key]: value,
    }));
  };

  const resetToDefaultTheme = () => {
    setTheme(defaultTheme);
    closeModal();
  };

  const closeModal = () => setIsModalOpen(false);

  const setSaveButtonStatus = val => setEnableSaveButton(val);

  return (
    <div className="theme-personalization">
      {loading && <Spinner />}

      <div className="text-right mb-2">
        <button
          className="btn-sm app-btn-outline-secondary"
          onClick={() => setIsModalOpen(true)}
        >
          Reset to Default Theme
        </button>
      </div>
      <h3 className="text-black-50">Button Style</h3>
      <div className="row">
        {Object.keys(theme).map(themeColor => (
          <ColorTheme
            onChange={onChange}
            key={themeColor}
            themeKey={themeColor}
            theme={theme[themeColor]}
            setSaveButtonStatus={setSaveButtonStatus}
          />
        ))}
      </div>
      {fontConfig?.fontCustomizable && (
        <FontCustomization
          fontConfig={fontConfig}
          currentFont={font}
          onChange={setFont}
        />
      )}
      <div className="text-right">
        <button
          className="btn app-btn-primary px-5"
          onClick={handleSubmit}
          disabled={!enableSaveButton}
        >
          Save
        </button>
      </div>

      {isModalOpen && (
        <Modal
          title="Theme resetting"
          onClose={closeModal}
          renderContent={() => (
            <Fragment>
              <p>Are you sure you want to Reset the theme?</p>
              <p>This process can not be undone!</p>
            </Fragment>
          )}
          renderFooter={() => (
            <div className="text-right w-100">
              <button
                className="app-btn-outline-secondary mr-2"
                onClick={closeModal}
              >
                Close
              </button>
              <button className="btn btn-darkred" onClick={resetToDefaultTheme}>
                Reset
              </button>
            </div>
          )}
        />
      )}
    </div>
  );
};

ThemePersonalization.propTypes = {
  theme: PropTypes.shape({}),
};

export default withErrorHandler(memo(ThemePersonalization));

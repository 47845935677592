import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AssetFilterLink = ({
  onLinkClick: linkClickHandler,
  isActive,
  label,
}) => (
  <a
    onClick={(event) => {
      event.preventDefault();
      linkClickHandler();
    }}
    className={
      classNames('file-control', {
        'active': isActive
      })
    }
  >
    {
      label
    }
  </a>
);

AssetFilterLink.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default AssetFilterLink;

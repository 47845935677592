import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import './styles.scss';

const CustomTextInput = ({
  handleChange,
  label,
  helperText,
  className,
  inputClassName,
  ...otherProps
}) => (
  <div className={`custom-textinput-group ${className}`}>
    <input
      type="text"
      className={`custom-form-input ${inputClassName}`}
      onChange={handleChange}
      {...otherProps}
    />
    {helperText && isEmpty(otherProps.value) && (
      <div className="helper-text">{helperText}</div>
    )}
    {label && (
      <label
        className={`${
          otherProps.value.length ? 'shrink' : ''
        } custom-input-label`}
      >
        {label}
      </label>
    )}
  </div>
);

CustomTextInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
};

CustomTextInput.defaultProps = {
  helperText: '',
  className: '',
  inputClassName: '',
};

export default CustomTextInput;

import React from "react";
import isEmpty from "lodash/isEmpty";

const Results = ({ certifications }) => (
  <div className="kt-section__content">
    <div className="table-responsive">
      <table className="table table-striped kt-table__row-equal-width">
        <thead>
          <tr>
            <th>
              <span className="pl-15">
                Name
              </span>
            </th>
            <th>Certification</th>
            <th>Issued Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            certifications.length > 0
              ?
                certifications.map((mCertification) => (
                  <tr key={mCertification.id}>
                    <td>
                      <a className="pl-15" href={mCertification.links.edit}>
                        {mCertification.member.fullName}
                      </a>
                    </td>
                    <td>
                      <a href={mCertification.links.edit}>
                        {mCertification.certification.name}
                      </a>
                    </td>
                    <td>{mCertification.issuedDate}</td>
                    <td>{mCertification.expirationDate}</td>
                    <td>
                      <h4>
                        <span
                          style={{ marginLeft: "10px" }}
                          className={`kt-badge kt-badge--inline kt-badge--rounded kt-badge--${mCertification.status?.toLowerCase?.() === 'active' ? 'success' : 'danger'}`}
                        >
                          {mCertification.status}
                        </span>
                      </h4>
                    </td>
                    <td>
                      <div className="btn-group dropleft">
                        <button
                          type="button"
                          className="btn-sm app-btn-outline-primary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Options
                        </button>

                        <div className="dropdown-menu">
                          <a className="dropdown-item" href={mCertification.links.edit}>
                            Edit
                          </a>

                          {
                            isEmpty(mCertification.attachments)
                              ? null
                              :
                                (
                                  <a className="dropdown-item" href={mCertification.attachments[0]}>
                                    View Attachment
                                  </a>
                                )
                          }
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              :
                (
                  <tr>
                    <td colSpan={6} align="center">
                      No Certifications found
                    </td>
                  </tr>
                )
          }
        </tbody>
      </table>
    </div>
  </div>
);

export default Results;

import React from 'react';
import PropTypes from 'prop-types';

const ButtonContent = ({ option, className, ...otherProps }) => {
  const customClassName = ['text-capitalize font-weight-bold', className].join(
    ' '
  );
  return (
    <span className={customClassName} {...otherProps}>
      {option}
    </span>
  );
};

ButtonContent.propTypes = {
  option: PropTypes.string,
  className: PropTypes.string,
};

export default ButtonContent;

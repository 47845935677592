import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import FormRow from '../common/presentational/formRow';
import Text from '../common/inputs/text';
import CheckBox from '../common/inputs/checkBox';
import SubmitButton from '../common/presentational/submitButton';
import FormElementHelperText from '../common/presentational/FormElementHelperText';
import { withErrorHandler } from '../hoc/withErrorHandler';
import './styles.scss';
import { getUnformattedPhoneNumber } from '../common/utils';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyName: null,
      firstName: null,
      lastName: null,
      email: null,
      emailConfirmation: null,
      phoneNumber: null,
      password: null,
      passwordConfirmation: null,
      acceptedTerms: false,
      loading: false,
      signUpSuccessMessage: null,
      resendConfirmationLink: null,
      resentMail: false,
    };
  }

  handleUserDetails = (attributeName, attributeValue) => {
    this.setState({
      [attributeName]: attributeValue,
    });
  };

  handleAceeptTerms = () => {
    this.setState(prevState => ({
      acceptedTerms: !prevState.acceptedTerms,
    }));
  };

  handlePhoneInputChange = (value, countryData) => {
    const updatedValue = getUnformattedPhoneNumber(value, countryData);

    this.setState({
      phoneNumber: updatedValue,
    });
  };

  isConfirmValueMatching = attributeName => {
    const [value, confirmValue] = [
      this.state[attributeName],
      this.state[`${attributeName}Confirmation`],
    ];
    return (
      !_.isEmpty(value) && !_.isEmpty(confirmValue) && _.isEqual(value, confirmValue)
    );
  };

  canSubmit = () => {
    const { companyName, firstName, lastName, acceptedTerms, password } = this.state;

    return (
      acceptedTerms &&
      !_.isEmpty(companyName) &&
      !_.isEmpty(firstName) &&
      !_.isEmpty(lastName) &&
      this.isConfirmValueMatching('email') &&
      this.isConfirmValueMatching('password') &&
      _.size(password) >= 8
    );
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { csrfToken, links } = this.props;
    const {
      companyName,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      phoneNumber,
      acceptedTerms,
    } = this.state;

    const requestData = {
      user: {
        email,
        password,
        passwordConfirmation,
      },
      member: {
        firstName: _.trim(firstName),
        lastName: _.trim(lastName),
        mobile: phoneNumber,
      },
      tenant: {
        companyName: _.trim(companyName),
        termsAndConditions: acceptedTerms ? '1' : '0',
      },
    };

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .post(links.signup)
      .setPostData(requestData)
      .useAlerts()
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data }) => {
        this.setState({
          loading: false,
          signUpSuccessMessage: data.message,
          resendConfirmationLink: data.links.resendUserConfirmation,
        });
      })
      .exec();
  };

  resendConfirmationMail = async () => {
    const { csrfToken } = this.props;
    const { resendConfirmationLink } = this.state;

    await new Http(this)
      .setLoading()
      .setToken(csrfToken)
      .post(resendConfirmationLink)
      .useAlerts()
      .onSuccess(_res => {
        this.setState({
          loading: false,
          resentMail: true,
        });
      })
      .onError(_err => {
        this.setState({
          loading: false,
          resentMail: false,
        });
      })
      .exec();
  };

  renderError = (attributeValue, errorMessage = 'is required') => {
    if (_.isNull(attributeValue) || !_.isEmpty(attributeValue)) return null;

    return <FormElementHelperText text={errorMessage} type="danger" />;
  };

  renderUnMatchedConfirmValueError = (value, attributeName) => {
    if (_.isNull(value)) return null;
    if (_.isEmpty(value)) return this.renderError(value);

    return (
      !this.isConfirmValueMatching(attributeName) &&
      this.renderError('', `${attributeName}s not matching`) // intentional empty string sent to void the non-empty check for unmatched values
    );
  };

  render() {
    const {
      companyName,
      firstName,
      lastName,
      email,
      emailConfirmation,
      phoneNumber,
      password,
      passwordConfirmation,
      acceptedTerms,
      loading,
      signUpSuccessMessage,
      resentMail,
    } = this.state;

    if (!_.isNil(signUpSuccessMessage)) {
      return (
        <div className="row">
          <div className="col-lg-5 col-md-8 col-sm-12 my-5 mx-auto">
            <h2 className="text-center mb-30">Confirmation Needed</h2>
            <div className="kt-portlet signup-form-wrapper my-4">
              <div className="kt-portlet__body align-items-center">
                <p className="mb-30 font-16 text-center kt-font-bold">
                  {signUpSuccessMessage}
                </p>
                {resentMail ? (
                  <p className="kt-font-success">
                    Confirmation Mail Resent
                    <i className="fa fa-check mx-2" />
                  </p>
                ) : loading ? (
                  <p className="kt-font-info">Sending...</p>
                ) : (
                  <p
                    className="kt-link cursor-pointer"
                    onClick={this.resendConfirmationMail}
                  >
                    Resend Confirmation Mail
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        {loading && <Spinner />}
        <div className="col-lg-5 col-md-8 col-sm-12 my-5 mx-auto">
          <h2 className="text-center mb-30">Sign Up for Mentor</h2>
          <div className="kt-portlet signup-form-wrapper my-4">
            <div className="kt-portlet__body">
              <form onSubmit={this.handleSubmit}>
                <FormRow label="Company Name" stackedLabel>
                  <Text
                    name="companyName"
                    numberAttributes={{ required: true }}
                    value={companyName || ''}
                    onInputChange={({ target }) =>
                      this.handleUserDetails('companyName', target.value)
                    }
                  />
                  {this.renderError(companyName)}
                </FormRow>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <FormRow label="First Name" stackedLabel>
                      <Text
                        name="firstName"
                        numberAttributes={{ required: true }}
                        value={firstName || ''}
                        onInputChange={({ target }) =>
                          this.handleUserDetails('firstName', target.value)
                        }
                      />
                      {this.renderError(firstName)}
                    </FormRow>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <FormRow label="Last Name" stackedLabel>
                      <Text
                        name="lastName"
                        numberAttributes={{ required: true }}
                        value={lastName || ''}
                        onInputChange={({ target }) =>
                          this.handleUserDetails('lastName', target.value)
                        }
                      />
                      {this.renderError(lastName)}
                    </FormRow>
                  </div>
                </div>
                <FormRow label="Phone Number" stackedLabel>
                  <PhoneInput
                    country={'us'}
                    value={phoneNumber || ''}
                    onChange={this.handlePhoneInputChange}
                    countryCodeEditable={false}
                    autoFormat={false}
                    enableSearch
                    inputStyle={{ width: '100%' }}
                  />
                </FormRow>
                <FormRow label="Email" stackedLabel>
                  <Text
                    name="email"
                    inputType="email"
                    numberAttributes={{ required: true }}
                    value={email || ''}
                    onInputChange={({ target }) =>
                      this.handleUserDetails('email', target.value)
                    }
                  />
                  {this.renderError(email)}
                </FormRow>
                <FormRow label="Confirm Email" stackedLabel>
                  <Text
                    name="emailConfirmation"
                    inputType="email"
                    numberAttributes={{ required: true }}
                    value={emailConfirmation || ''}
                    onInputChange={({ target }) =>
                      this.handleUserDetails('emailConfirmation', target.value)
                    }
                  />
                  {this.renderUnMatchedConfirmValueError(emailConfirmation, 'email')}
                </FormRow>
                <FormRow label="Password (min 8 characters)" stackedLabel>
                  <Text
                    name="password"
                    inputType="password"
                    numberAttributes={{ required: true }}
                    value={password || ''}
                    onInputChange={({ target }) =>
                      this.handleUserDetails('password', target.value)
                    }
                  />
                  {this.renderError(password)}
                </FormRow>
                <FormRow label="Confirm Password" stackedLabel>
                  <Text
                    name="passwordConfirmation"
                    inputType="password"
                    numberAttributes={{ required: true }}
                    value={passwordConfirmation || ''}
                    onInputChange={({ target }) =>
                      this.handleUserDetails('passwordConfirmation', target.value)
                    }
                  />
                  {this.renderUnMatchedConfirmValueError(
                    passwordConfirmation,
                    'password'
                  )}
                </FormRow>
                <div className="d-flex flex-column align-items-center mb-20">
                  <CheckBox
                    name={'acceptedTerms'}
                    value={acceptedTerms}
                    handleOnChange={this.handleAceeptTerms}
                    style={{ padding: '0 20px' }}
                    showLabel
                    labelClassName="kt-checkbox--brand kt-checkbox--bold"
                    label={() => (
                      <p>
                        I have read and agree to the&nbsp;
                        <a
                          target="_blank"
                          href="https://www.mentorhq.com/terms-of-service/"
                          className="kt-link"
                        >
                          Terms of Service
                        </a>
                        &nbsp;and&nbsp;
                        <a
                          target="_blank"
                          href="https://www.mentorhq.com/privacy-policy/"
                          className="kt-link"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    )}
                  />
                  <SubmitButton buttonText="Get Started" disabled={!this.canSubmit()} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignUpForm.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    signup: PropTypes.string.isRequired,
  }).isRequired,
};

export default withErrorHandler(SignUpForm);

import React from 'react';
import Asset from '../../filestack/Asset';
import PropTypes from 'prop-types';

const AttachmentCard = ({ url, filename, links, size, mimetype, poster }) => (
  <div className="custom_attachment m-2">
    <Asset mimetype={mimetype} url={url} poster={poster} />
    <div className="p-2 attachment-info d-flex justify-content-between">
      <div className="text-truncate">
        <span className="font-weight-normal">{filename}</span>
        <div className="text-muted">{size}</div>
      </div>
      <div>
        {links?.download && (
          <a
            href={links.download}
            className="custom-secondary-btn"
            onClick={(e) => e.stopPropagation()}
          >
            <i className="fas fa-cloud-download-alt"></i>
          </a>
        )}
      </div>
    </div>
  </div>
);

AttachmentCard.propTypes = {
  filename: PropTypes.string.isRequired,
  links: PropTypes.shape({
    download: PropTypes.string,
  }),
  mimetype: PropTypes.string.isRequired,
  poster: PropTypes.string,
  size: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};

export default AttachmentCard;

import React, { useState, memo, Fragment } from 'react';
import PropTypes from 'prop-types';

import Http from '../common/Http';
import { withErrorHandler } from '../hoc/withErrorHandler';

const Notification = ({
  link,
  isSubscribed: subscription,
  csrfToken,
  showNotificationText,
  showOnlyIcon,
}) => {
  const [subscribed, setSubscribed] = useState(subscription);
  const [loading, setLoading] = useState(false);

  const handleSubscribe = () => {
    const Requestor = new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .post(link)
      .onSuccess(({ data }) => {
        setSubscribed((subscribed) => !subscribed);
        setLoading(false);
      })
      .onError(() => {
        setLoading(false);
      })
      .exec();
  };

  const renderLoader = () => (
    <Fragment>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>{' '}
      {!subscribed ? 'Subscribing...' : 'Unsubscribing...'}
    </Fragment>
  );

  const renderButton = () => (
    <button className="app-btn-hover-secondary" onClick={handleSubscribe} disabled={loading}>
      {loading ? (
        renderLoader()
      ) : subscribed ? (
        <Fragment>
          <i className="far fa-bell-slash"></i> Unsubscribe
        </Fragment>
      ) : (
        <Fragment>
          <i className="far fa-bell"></i> Subscribe
        </Fragment>
      )}
    </button>
  );

  const renderIcon = () =>
    loading ? (
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <div onClick={handleSubscribe}>
        {subscribed ? (
          <i
            className="far fa-bell-slash font-16 mt-2 cursor-pointer"
            title="Unsubscribe Notification"
          ></i>
        ) : (
          <i
            className="far fa-bell font-16 mt-2 cursor-pointer"
            title="Subscribe Notification"
          ></i>
        )}
      </div>
    );

  return (
    <div>
      {showNotificationText && (
        <p className="mb-1">
          <strong>Notification</strong>
        </p>
      )}
      {showOnlyIcon ? renderIcon() : renderButton()}
    </div>
  );
};

Notification.propTypes = {
  showNotificationText: PropTypes.bool,
  showOnlyIcon: PropTypes.bool,
};
Notification.defaultProps = {
  showNotificationText: true,
  showOnlyIcon: false,
};

export default withErrorHandler(memo(Notification));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../common/presentational/spinner';
import QuizGallery from './QuizGallery';
import Http from '../../common/Http';
import { alertErrorNotifications } from '../../folders/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import {
  addPageLeavePreventDialog,
  removePageLeavePreventDialog,
} from '../../common/utils';
import Options from './Options';

class UserQuizView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentQuestionIndex: 1,
      isCurrentQuestionAnswered: false,
      nextQuestionIndex: 2,

      markedQuestions: _.keys(props.questionsChosenAnswerHash) || [],
      unMarkedQuestions: [],

      questionsArray:
        props.questionsArray.map((q, ind) => ({
          ...q,
          currentQuestionIndex: ind + 1,
          nextQuestionIndex: ind + 1 === props.totalQuestions ? null : ind + 2,
        })) || [],

      loading: false,

      questionsChosenAnswerHash: props.questionsChosenAnswerHash || {},
    };
  }

  componentDidMount() {
    if (
      _.isNil(this.state.questionsArray) ||
      _.isEmpty(this.state.questionsArray)
    ) {
      this.fetchNextQuestion(1);
    }
    addPageLeavePreventDialog();
    document.addEventListener('keydown', this.handleKeyPress.bind(this));
  }

  componentWillUnmount() {
    removePageLeavePreventDialog();
    document.addEventListener('keydown', this.handleKeyPress.bind(this));
  }

  handleKeyPress(e) {
    const keyCode = e.key;
    const isNumberPressed = parseInt(keyCode) >= 0 && parseInt(keyCode) <= 9;

    if (
      isNumberPressed ||
      keyCode === 'ArrowLeft' ||
      keyCode === 'ArrowRight'
    ) {
      const questionToDisplay = this.getQuestionObjectByIndex(
        this.state.currentQuestionIndex
      );
      if (isNumberPressed) {
        questionToDisplay.answerOptions.map((option, idx) => {
          if (parseInt(keyCode) === idx + 1) {
            if (!questionToDisplay.isMultipleChoice) {
              this.markAnswerForQuestion(
                questionToDisplay.id,
                option.id,
                false
              )();
            } else {
              this.markAnswerForQuestion(
                questionToDisplay.id,
                option.id,
                true
              )();
            }
          }
        });
      } else if (keyCode === 'ArrowLeft') {
        if (
          questionToDisplay.currentQuestionIndex !== 1 &&
          !_.isNil(questionToDisplay.currentQuestionIndex)
        ) {
          this.handleSaveAndGetNextPreviousQuestion(
            questionToDisplay.currentQuestionIndex,
            true
          )(e);
        }
      } else if (keyCode === 'ArrowRight') {
        if (!_.isNil(questionToDisplay.nextQuestionIndex)) {
          this.handleSaveAndGetNextPreviousQuestion(
            questionToDisplay.currentQuestionIndex,
            false
          )(e);
        }
      }
    }
  }

  handleGetRandomQuestion = questionNumber => event => {
    const { questionsArray } = this.state;

    const questionIndex = _.findIndex(questionsArray, {
      currentQuestionIndex: questionNumber,
    });

    const isQuestionAlreadyPresent = questionIndex > -1;

    if (isQuestionAlreadyPresent === false) {
      this.fetchNextQuestion(questionNumber);
    } else {
      this.setState({
        currentQuestionIndex:
          questionsArray[questionIndex].currentQuestionIndex,
        nextQuestionIndex: questionsArray[questionIndex].nextQuestionIndex,
      });
    }
  };

  handleSaveAndGetNextPreviousQuestion =
    (currentQuestionNumber, moveToPreviousQuestion) => event => {
      this.setState({ isCurrentQuestionAnswered: false });
      this.handleQuestionAnswerSave(
        currentQuestionNumber,
        moveToPreviousQuestion
      )(event);
    };

  handleSaveAndSubmitQuiz = e => {
    const { isCurrentQuestionAnswered, currentQuestionIndex: currentIndex } =
      this.state;

    if (isCurrentQuestionAnswered) {
      const { currentQuestionIndex } =
        this.getQuestionObjectByIndex(currentIndex);
      this.handleQuestionAnswerSave(currentQuestionIndex, false, true)(e);
    } else {
      this.handleSubmitQuiz();
    }
  };

  handleQuestionAnswerSave =
    (questionIndexNumber, moveToPreviousQuestion, isSubmitted = false) =>
    async event => {
      const { csrfToken, courseId, courseQuizId } = this.props;
      const { questionsChosenAnswerHash } = this.state;

      const questionObject = this.getQuestionObjectByIndex(questionIndexNumber);
      const { id: questionId, links } = questionObject;

      const currentAnswers = questionsChosenAnswerHash[questionObject.id] || [];

      const questionAnswerSaveUrl = links.saveAnswer;

      if (isSubmitted) {
        // Manually setting the loading state to prevent multiple loaders one after another
        this.setState({ loading: true });
      }

      const Requester = await new Http(this)
        .setToken(csrfToken)
        .post(questionAnswerSaveUrl, {
          selected_options: _.uniq(currentAnswers),
        })
        .onSuccess(response => {
          this.setState(
            prevState => {
              const { markedQuestions } = prevState;
              const questionIdString = `${questionObject.id}`;
              let newMarkedQuestions = [];

              if (
                _.includes(markedQuestions, questionIdString) &&
                _.isEmpty(currentAnswers)
              ) {
                newMarkedQuestions = _.filter(
                  markedQuestions,
                  que =>
                    que !== parseInt(questionIdString) &&
                    que !== questionIdString
                );
              } else if (
                !_.includes(markedQuestions, questionIdString) &&
                !_.isEmpty(currentAnswers)
              ) {
                newMarkedQuestions = markedQuestions.concat(questionIdString);
              } else {
                newMarkedQuestions = markedQuestions;
              }

              return {
                markedQuestions: newMarkedQuestions,
              };
            },
            () => {
              const { questionsArray } = this.state;

              let theGoToQuestionNumber =
                (moveToPreviousQuestion
                  ? questionIndexNumber - 1
                  : questionObject.nextQuestionIndex) || questionIndexNumber;

              let findIndexCondition = moveToPreviousQuestion
                ? {
                    nextQuestionIndex: questionIndexNumber,
                  }
                : { currentQuestionIndex: theGoToQuestionNumber };

              const questionIndex = _.findIndex(
                questionsArray,
                findIndexCondition
              );

              const isQuestionAlreadyPresent = questionIndex > -1;

              if (isQuestionAlreadyPresent === false) {
                this.fetchNextQuestion(theGoToQuestionNumber);
              } else {
                this.setState({
                  currentQuestionIndex:
                    questionsArray[questionIndex].currentQuestionIndex,
                  loading: isSubmitted ? true : false,
                  nextQuestionIndex:
                    questionsArray[questionIndex].nextQuestionIndex,
                });
              }
            }
          );

          if (isSubmitted) {
            this.handleSubmitQuiz();
          }
        })
        .onError(error => {
          this.setState(
            {
              currentQuestionIndex: questionObject.currentQuestionIndex,
              loading: false,
              nextQuestionIndex: questionObject.nextQuestionIndex,
            },
            () =>
              alertErrorNotifications(
                error || 'Failed to save response for question'
              )
          );
        });

      if (!isSubmitted) {
        Requester.setLoading();
      }

      Requester.exec();
    };

  fetchNextQuestion = async questionNumber => {
    const { csrfToken, courseId, courseQuizId, links } = this.props;
    const nextQuestionUrl = links.startQuiz;

    await new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .get(nextQuestionUrl, {
        page: questionNumber,
      })
      .onSuccess(response => {
        const { quizQuestion: question } = response.data;

        if (!_.isNil(question)) {
          this.setState(prevState => {
            const isQuestionAlreadyPresent =
              _.findIndex(prevState.questionsArray, { id: question.id }) > -1;
            let newQuestionArray = prevState.questionsArray;

            if (isQuestionAlreadyPresent === false) {
              newQuestionArray = newQuestionArray.concat(question);
            }

            return {
              loading: false,
              questionsArray: newQuestionArray,
              nextQuestionIndex: parseInt(question.nextQuestionIndex),
              currentQuestionIndex: parseInt(question.currentQuestionIndex),
            };
          });
        }
      })
      .exec();
  };

  markAnswerForQuestion = (questionId, answerId, isMutiple = true) => {
    const { questionsChosenAnswerHash } = this.state;
    const currentQuestionAnswers = questionsChosenAnswerHash[questionId] || [];

    return event => {
      let newAnswers = [];
      if (isMutiple) {
        if (
          _.isEmpty(currentQuestionAnswers) ||
          !_.includes(currentQuestionAnswers, answerId)
        ) {
          newAnswers = currentQuestionAnswers.concat(answerId);
        } else if (_.includes(currentQuestionAnswers, answerId)) {
          newAnswers = _.filter(
            currentQuestionAnswers,
            ans => ans !== answerId
          );
        }
      } else {
        newAnswers = [answerId];
      }

      this.setState({
        isCurrentQuestionAnswered: true,
        questionsChosenAnswerHash: {
          ...questionsChosenAnswerHash,
          ...{
            [questionId]: _.uniq(newAnswers),
          },
        },
      });
    };
  };

  handleSubmitQuiz = async () => {
    const { csrfToken, courseQuizId, courseId, links } = this.props;

    await new Http(this)
      .setToken(csrfToken)
      .setLoading()
      .get(links.submitQuiz)
      .onSuccess(response => {
        removePageLeavePreventDialog();
        window.location = links.previewQuiz;
      })
      .exec();
  };

  getQuestionObjectByIndex = currentQuestionIndex => {
    const { questionsArray } = this.state;
    return _.find(questionsArray, { currentQuestionIndex });
  };

  getQuestionObjectByNextIndex = nextQuestionIndex => {
    const { questionsArray } = this.state;
    return _.find(questionsArray, { nextQuestionIndex });
  };

  render() {
    const {
      currentQuestionIndex,
      questionsArray,
      loading,
      questionsChosenAnswerHash,
      markedQuestions,
    } = this.state;

    const { totalQuestions, csrfToken } = this.props;

    const questionToDisplay =
      this.getQuestionObjectByIndex(currentQuestionIndex);

    if (loading) {
      return (
        <div className="kt-content">
          <div className="row">
            <Spinner />
          </div>
        </div>
      );
    }

    if (
      _.isNil(questionToDisplay) &&
      (_.isNil(questionsArray) || _.isEmpty(questionsArray))
    ) {
      return (
        <div className="kt-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="kt-portlet">
                <div className="kt-portlet__body">
                  There are no questions in quiz at the moment.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const currentQuestionAnswers =
      questionsChosenAnswerHash[questionToDisplay.id];

    return (
      <div className="kt-content">
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Quiz Question</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <h3
                  style={{
                    fontFamily: 'sans-serif',
                  }}
                >
                  {questionToDisplay.title}
                </h3>
                {questionToDisplay.isMultipleChoice ? (
                  <span className="text-darkred">
                    * Select all options that apply.
                  </span>
                ) : null}
                <br />
                <QuizGallery
                  hideFileInfo
                  assets={questionToDisplay.existingAttachments || []}
                />
                <div className="kt-separator kt-separator--dashed" />
                {_.map(questionToDisplay.answerOptions, (option, idx) => (
                  <Options
                    option={option}
                    idx={idx}
                    key={idx}
                    questionToDisplay={questionToDisplay}
                    currentQuestionAnswers={currentQuestionAnswers}
                    markAnswerForQuestion={this.markAnswerForQuestion.bind(
                      this
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="col-xl-12 text-center">
            {questionToDisplay.currentQuestionIndex !== 1 &&
              !_.isNil(questionToDisplay.currentQuestionIndex) && (
                <button
                  type="button"
                  onClick={this.handleSaveAndGetNextPreviousQuestion(
                    questionToDisplay.currentQuestionIndex,
                    true
                  )}
                  className="app-btn-secondary quiz-btn btn-lg mr-2"
                >
                  <span className="badge border mr-2 p-1">&larr;</span>
                  Previous
                </button>
              )}
            {!_.isNil(questionToDisplay.nextQuestionIndex) && (
              <button
                type="button"
                onClick={this.handleSaveAndGetNextPreviousQuestion(
                  questionToDisplay.currentQuestionIndex,
                  false
                )}
                className="app-btn-primary quiz-btn btn-lg"
              >
                Next <span className="badge border ml-2 p-1">&rarr;</span>
              </button>
            )}
            {questionToDisplay.currentQuestionIndex ===
              questionsArray.length && (
              <button
                type="button"
                disabled={_.isEmpty(questionsChosenAnswerHash)}
                onClick={this.handleSaveAndSubmitQuiz}
                className="btn btn-success quiz-btn btn-lg"
              >
                Submit Quiz
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

UserQuizView.defaultProps = {
  questionsChosenAnswerHash: {},
  questionsArray: [],
};

UserQuizView.propTypes = {
  questionsArray: PropTypes.array,
  questionsChosenAnswerHash: PropTypes.object,
  totalQuestions: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,

  links: PropTypes.shape({
    previewQuiz: PropTypes.string.isRequired,
    startQuiz: PropTypes.string.isRequired,
    submitQuiz: PropTypes.string.isRequired,
  }),
};

export default withErrorHandler(UserQuizView);

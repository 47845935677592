import React from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import omit from 'lodash/omit';
import map from 'lodash/map';

import FormRow from '../common/presentational/formRow';
import Text from '../common/inputs/text';
import Http from '../common/Http';
import SubmitButton from '../common/presentational/submitButton';
import Spinner from '../common/presentational/spinner';
import { convertArrayOfObjectsToHash } from '../common/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';

const RESET_CURRENT_EDITING_KEYWORD = Object.freeze({
  id: null,
  keyword: '',
  links: {},
});

const FormResponseKeywords = props => {
  const [loading, setLoading] = React.useState(false);
  const [keyword, setKeyword] = React.useState('');
  const [currentEditing, setCurrentEditing] = React.useState({
    ...RESET_CURRENT_EDITING_KEYWORD,
  });
  let hashData = React.useMemo(
    () => convertArrayOfObjectsToHash(props.formResponseKeywords, 'id'),
    [props.formResponseKeywords]
  );
  const [keywordsHash, setKeywordsHash] = React.useState(hashData);

  const getTrimmedKeyword = keyWord =>
    !isEmpty(keyWord) ? keyWord.trim() : keyWord;

  const handleSubmit =
    (isEditRequest = false) =>
    async e => {
      // editRequest submit is not form event hence preventDefault isn't necessary
      if (!isEditRequest) {
        e.preventDefault();
      }

      const { links, csrfToken } = props;
      const trimmedKeyword = getTrimmedKeyword(
        isEditRequest ? currentEditing.keyword : keyword
      );

      const Request = new Http()
        .onBegin(() => setLoading(true))
        .setToken(csrfToken)
        .useAlerts()
        .setSuccessMessage(
          `Keyword ${isEditRequest ? 'Updated' : 'Created'} Successfully!`
        )
        .setPostData({ keyword: trimmedKeyword })
        .onSuccess(res => {
          const {
            data: { keyword: keyWord },
          } = res;
          setKeyword('');
          setCurrentEditing({ ...RESET_CURRENT_EDITING_KEYWORD });
          setKeywordsHash(prevState => ({
            ...prevState,
            [keyWord.id]: keyWord,
          }));
          setLoading(false);
        })
        .onError(() => setLoading(false));

      if (isEditRequest) {
        Request.patch(currentEditing.links.update);
      } else {
        Request.post(links.create);
      }

      await Request.exec();
    };

  const handleNewKeywordChange = value => setKeyword(value);

  const handleEditKeywordChange = value => {
    setCurrentEditing(prevState => ({
      ...prevState,
      keyword: value,
    }));
  };

  const triggerKeywordEdit = keywordId => {
    let currentId = keywordsHash[keywordId];
    setCurrentEditing(currentId);
  };

  const handleKeywordDelete = async keyWord => {
    const proceed = confirm('Are you sure, you want to delete keyword?');
    if (!proceed) return;

    const { links } = keyWord;

    await new Http()
      .onBegin(() => setLoading(true))
      .setToken(props.csrfToken)
      .delete(links.delete)
      .useAlerts()
      .setSuccessMessage('Keyword Deleted Successfully!')
      .onSuccess(res => {
        const {
          data: { keyword_id },
        } = res;
        setKeywordsHash(prevState => omit(prevState, keyword_id));
        setLoading(false);
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const isEditingMode = React.useMemo(
    () => !isNull(currentEditing.id),
    [currentEditing]
  );

  const handleEditDone = () => {
    // If existing and edited keyword are same (Do not send update request)
    setCurrentEditing({ ...RESET_CURRENT_EDITING_KEYWORD });
  };

  const renderActions = responseKeyword => {
    if (isEqual(currentEditing.id, responseKeyword.id)) {
      return (
        <button
          onClick={
            isEqual(currentEditing.keyword, responseKeyword.keyword)
              ? handleEditDone
              : handleSubmit(true)
          }
          className="btn btn-success btn-icon btn-icon-only btn-pill mx-1"
          disabled={isEmpty(currentEditing.keyword)}
        >
          <i className="la la-check" />
        </button>
      );
    }

    return (
      <React.Fragment>
        <button
          onClick={() => triggerKeywordEdit(responseKeyword.id)}
          className="btn btn-hover-brand btn-icon btn-icon-only btn-pill mx-1"
          disabled={isEditingMode}
        >
          <i className="la la-edit" />
        </button>
        <button
          onClick={() => handleKeywordDelete(responseKeyword)}
          className="btn btn-hover-danger btn-icon btn-icon-only btn-pill mx-1"
          disabled={isEditingMode}
        >
          <i className="la la-trash" />
        </button>
      </React.Fragment>
    );
  };

  const renderFormResponseKeywordsTable = () => {
    return (
      <div className="table-responsive">
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr>
              <th className="td-300">Keyword / Phrase</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(keywordsHash) ? (
              map(keywordsHash, responseKeyword => (
                <tr key={responseKeyword.id}>
                  {isEditingMode &&
                  isEqual(currentEditing.id, responseKeyword.id) ? (
                    <td className="td-300">
                      <Text
                        name={'editFormResponseKeyword'}
                        value={currentEditing.keyword || ''}
                        onInputChange={e =>
                          handleEditKeywordChange(e.target.value)
                        }
                      />
                    </td>
                  ) : (
                    <td className="td-300">{responseKeyword.keyword}</td>
                  )}
                  <td>{renderActions(responseKeyword)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No keywords found. Please create one above.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="kt-content">
      {loading && <Spinner />}
      <form className="kt-form" onSubmit={handleSubmit()}>
        <FormRow label="New Keyword / Phrase">
          <Text
            name={'newFormResponseKeyword'}
            value={keyword || ''}
            onInputChange={e => handleNewKeywordChange(e.target.value)}
            placeholder="Ex: Disappointed..."
          />
        </FormRow>
        <div className="text-center">
          <SubmitButton buttonText="Create" disabled={isEmpty(keyword)} />
        </div>
      </form>
      <br />
      {renderFormResponseKeywordsTable()}
    </div>
  );
};

FormResponseKeywords.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  formResponseKeywords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      keyword: PropTypes.string.isRequired,
      links: PropTypes.shape({
        update: PropTypes.string.isRequired,
        delete: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  links: PropTypes.shape({
    create: PropTypes.string.isRequired,
  }).isRequired,
};

export default withErrorHandler(FormResponseKeywords);

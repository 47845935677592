import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FolderContainer from './FolderContainer';
import AssetsContainer from './FolderContainer/AssetsContainer';
import FolderSidebar from './FolderContainer/FolderSidebar';
import FolderHeader from './FolderHeader';

import { getTenantDisplayTerm } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class ResourceAssetFolders extends Component {
  constructor(props) {
    super(props);
  }

  urlProvider = folderType => {
    const { parentType, parentId } = this.props;

    if (!_.isNil(parentType) && !_.isNil(parentId)) {
      return `/folders/protected/resource_list/${parentType}/${parentId}/${folderType}`;
    } else {
      return `/folders/protected/resource_list/${folderType}`;
    }
  };

  assetFolderUrlProvider = folderId => {
    const { parentType, parentId } = this.props;

    return `/folders/protected/${parentType}/${parentId}/assets/folders/${folderId}`;
  };

  getDisplayProcessedFoldersObject = () => {
    const { folders, tenantDisplayTerms } = this.props;

    const folderObjects = _.map(folders, folderName => ({
      id: folderName,
      name: getTenantDisplayTerm({ tenantDisplayTerms, resourceTerm: folderName }),
    }));

    return folderObjects;
  };

  getFolderHeaderTitle = () => {
    const { parentName, parentType, tenantDisplayTerms } = this.props;
    const displayParentType = getTenantDisplayTerm({
      tenantDisplayTerms,
      resourceTerm: parentType,
    });

    return `${displayParentType} - ${parentName}`;
  };

  render() {
    const { assetFolders } = this.props;
    const folderObjects = this.getDisplayProcessedFoldersObject();
    const folderHeaderTitle = this.getFolderHeaderTitle();

    return (
      <div>
        <FolderHeader title={folderHeaderTitle} />

        {/* Content */}
        <FolderContainer
          folderSidebar={
            <React.Fragment>
              <FolderSidebar
                folderProps={{
                  data: {
                    folders: folderObjects,
                    foldersUrlProvider: folderType => this.urlProvider(folderType),
                  },
                }}
              />

              <FolderSidebar
                folderHeaderTitle={'Asset Folders'}
                folderProps={{
                  data: {
                    folders: assetFolders,
                    foldersUrlProvider: folderId => this.assetFolderUrlProvider(folderId),
                  },
                }}
              />
            </React.Fragment>
          }
          assetsHolder={
            <AssetsContainer>
              <span>No Files Found</span>
            </AssetsContainer>
          }
        />
      </div>
    );
  }
}

ResourceAssetFolders.propTypes = {
  assetFolders: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
  parentType: PropTypes.string.isRequired,
  parentId: PropTypes.string.isRequired,
  parentName: PropTypes.string.isRequired,
  tenantDisplayTerms: PropTypes.object.isRequired,
};

ResourceAssetFolders.defaultProps = {
  assetFolders: [],
  folders: [],
  tenantDisplayTerms: {},
};

export default withErrorHandler(ResourceAssetFolders);

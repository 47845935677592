import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { convertArrayOfObjectsToHash } from '../../common/utils';
import KbLink from './common/KbLink';
import NoItemMessage from './common/NoTopics';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const SearchResults = ({
  topics,
  categories: allCategories,
  clearSearch,
  searchText,
}) => {
  const [categories, _setCategories] = useState(
    convertArrayOfObjectsToHash(allCategories, 'id')
  );

  if (_.isEmpty(topics)) return <NoItemMessage />;

  const data = topics.reduce((acc, topic) => {
    const existingCategory = acc.find((a) => a.categoryId === topic.categoryId);
    let newData = acc;
    if (existingCategory) {
      newData = acc.map((a) => {
        if (a.categoryId !== topic.categoryId) return a;
        return {
          ...a,
          topics: [...a.topics, topic],
        };
      });
    } else {
      newData.push({
        categoryId: categories[topic.categoryId]['id'],
        name: categories[topic.categoryId]['name'],
        links: categories[topic.categoryId]['links'],
        topics: [topic],
      });
    }
    return newData;
  }, []);

  const searchResultMessage = `${topics.length} results in ${data.length} ${
    data.length === 1 ? 'category' : 'categories'
  }`;

  return (
    <div className="knowledge_base-container">
      <div className="knowledge_base-content">
        <div className="d-flex flex-column flex-md-row mb-5">
          <div>
            <h2>Search Results for "{searchText}"</h2>
            <p>{searchResultMessage}</p>
          </div>
          <div>
            <button
              className="btn btn-sm btn-outline-secondary ml-md-2"
              onClick={clearSearch}
            >
              Clear Search
            </button>
          </div>
        </div>
        <div className="pl-5">
          {data.map((category) => (
            <div className="mb-5" key={category.categoryId}>
              <div className="d-flex align-items-center">
                <i className="fas fa-chevron-right mx-2 font-12 text-black-50"></i>
                <a className="category-link" href={category.links.viewCategory}>
                  <strong>{category.name}</strong>
                </a>
              </div>
              <div className="pl-5">
                {category.topics.map((topic) => (
                  <KbLink
                    link={topic.links.viewTopic}
                    name={topic.question}
                    key={topic.id}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

SearchResults.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewCategory: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      answer: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      lastUpdatedAt: PropTypes.string.isRequired,
      links: PropTypes.shape({
        viewTopic: PropTypes.string.isRequired,
      }).isRequired,
      postedBy: PropTypes.shape({
        avatar: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default withErrorHandler(SearchResults);

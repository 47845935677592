import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CloneLink from '../../courses/admin/cloning/CloneLink';
import {
  checkIsForumClonable,
  getForumCloningTitle,
} from '../../courses/admin/cloning/utils';
import useAttributesMutationObserver from '../../common/hooks/useAttributesMutationObserver';

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : '#ffffff',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#ffffff',
});

const DropdownBtn = ({ onAttrChange }) => {
  const dropdownRef = useRef(null);
  const onMutate = useCallback(
    mutation => {
      if (mutation.attributeName === 'aria-expanded') {
        onAttrChange(mutation.target.getAttribute('aria-expanded') === 'true');
      }
    },
    [onAttrChange]
  );

  useAttributesMutationObserver(dropdownRef, onMutate);

  return (
    <button
      type="button"
      className="btn-sm app-btn-outline-primary dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      ref={dropdownRef}
    >
      Options
    </button>
  );
};

DropdownBtn.propTypes = {
  onAttrChange: PropTypes.func.isRequired,
};

export const SortableCourseTableBody = ({
  courses,
  onDragEnd,
  csrfToken,
  toggleLoader,
  tenantTerms,
  curriculumCloneConfig,
  handlePublishedStatus,
  handleArchiveRequest,
  handleUnArchiveRequest,
}) => {
  const [isDragDisabled, setDragDisabled] = useState(false);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <tbody
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {provided.placeholder}
            {map(
              courses,
              (
                { id, name, links, cloneConfig, archived, published },
                index
              ) => {
                const isForumClonable = checkIsForumClonable(
                  cloneConfig.forumCloningCompleted
                );
                const forumCloningTitle = getForumCloningTitle(
                  cloneConfig.forumCloningCompleted
                );
                return (
                  <Draggable
                    isDragDisabled={isDragDisabled}
                    key={id}
                    draggableId={id.toString()}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <td className="text-center td-50 text-muted">
                          <i className="fas fa-grip-vertical"></i>
                        </td>
                        <td>{name}</td>
                        <td className="text-right td-110">
                          <div className="btn-group dropleft">
                            <DropdownBtn onAttrChange={setDragDisabled} />
                            <div className="dropdown-menu">
                              <a href={links.edit} className="dropdown-item">
                                Edit
                              </a>
                              <CloneLink
                                link={links.clone}
                                subjectName={name}
                                csrfToken={csrfToken}
                                toggleLoader={toggleLoader}
                                tenantTerms={tenantTerms}
                                inManageCourses={true}
                                curriculumCloneConfig={curriculumCloneConfig}
                              />
                              <a
                                href={
                                  isForumClonable
                                    ? links.initiateForumPostsCloning
                                    : null
                                }
                                className={`dropdown-item ${
                                  !isForumClonable && 'cursor-disabled'
                                }`}
                                title={forumCloningTitle}
                              >
                                Initiate Forum Cloning
                              </a>
                              {!archived &&
                                (published ? (
                                  <button
                                    className="dropdown-item"
                                    onClick={handlePublishedStatus(
                                      links.unpublish
                                    )}
                                  >
                                    Mark as Unpublished
                                  </button>
                                ) : (
                                  <button
                                    className="dropdown-item"
                                    onClick={handlePublishedStatus(
                                      links.publish
                                    )}
                                  >
                                    Mark as Published
                                  </button>
                                ))}
                              {!published && !archived && (
                                <button
                                  className="dropdown-item text-darkred"
                                  onClick={handleArchiveRequest(links.archive)}
                                >
                                  Archive
                                </button>
                              )}
                              {archived && (
                                <button
                                  className="dropdown-item text-danger"
                                  onClick={handleUnArchiveRequest(
                                    links.unarchive
                                  )}
                                >
                                  UnArchive
                                </button>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                );
              }
            )}
          </tbody>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SortableCourseTableBody.propTypes = {
  courses: PropTypes.array,
  onDragEnd: PropTypes.func,
  csrfToken: PropTypes.string,
  toggleLoader: PropTypes.func,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  curriculumCloneConfig: PropTypes.shape({
    currentCurriculum: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    remainingCloneTargetCurriculums: PropTypes.array,
  }),
  handlePublishedStatus: PropTypes.func,
  handleArchiveRequest: PropTypes.func,
  handleUnArchiveRequest: PropTypes.func,
};

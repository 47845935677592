import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import map from 'lodash/map';
import UserIconsGroup from '../common/Avatar/UserIconsGroup';
import Spinner from '../common/presentational/spinner';
import Http from '../common/Http';
import { alertErrorNotifications } from '../folders/utils';
import PaginationWrapper from '../hoc/PaginationWrapper';

const CourseTableWithProgress = React.memo(({ csrfToken, fetchUrl }) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  useEffect(() => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .get(`${fetchUrl}?page=${pagination.currentPage}`)
      .onSuccess(response => {
        const { data } = response;
        setCourses(data.courses);
        setPagination(prevState => ({
          ...prevState,
          totalPages: data.meta.pagination.totalPages,
        }));
        setLoading(false);
      })
      .onError(err => {
        console.log(err);
        setLoading(false);
        alertErrorNotifications(err || 'Error getting courses!');
      })
      .exec();
  }, [fetchUrl, pagination.currentPage]);

  const renderPagination = () => {
    if (!isEmpty(pagination) && pagination.totalPages > 1) {
      return (
        <PaginationWrapper
          paginationObject={pagination}
          fetchData={(pageNumber) => {
            setPagination(prevState => ({
              ...prevState,
              currentPage: pageNumber,
            }));
          }}
        />
      );
    }

    return <br />;
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div style={{ overflow: 'auto' }}>
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr>
              <th><strong>Course Name</strong></th>
              <th className="td-180"><strong>Members</strong></th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {courses.length > 0 ? (
              map(courses, ({ id, name, members, members_count, links }) => {
                return (
                  <tr key={id}>
                    <td>{name}</td>
                    <td className="td-180">
                      <UserIconsGroup
                        users={members}
                        totalUsersCount={members_count}
                      />
                    </td>
                    <td className="text-right">
                      <a
                        role="button"
                        className="btn-sm app-btn-outline-primary"
                        href={`${links.report}?apply_store_filter=true`}
                      >
                        View Learner Progress
                      </a>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={3}>
                  <div className="text-center">No courses found to show progress</div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {renderPagination()}
    </>
  );
});

CourseTableWithProgress.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  fetchUrl: PropTypes.string.isRequired,
};

export default CourseTableWithProgress;

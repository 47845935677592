import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AssetManager from '../../filestack/AssetManager';
import FolderContainer from './FolderContainer';
import AssetsContainer from './FolderContainer/AssetsContainer';
import FolderSidebar from './FolderContainer/FolderSidebar';
import FolderHeader from './FolderHeader';

import { getTenantDisplayTerm } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

class ProtectedFolderList extends Component {
  constructor(props) {
    super(props);
  }

  getFolderHeaderTitle = () => {
    const { resourceType, tenantDisplayTerms, parentName } = this.props;
    const displayResourceType = getTenantDisplayTerm({
      tenantDisplayTerms,
      resourceTerm: resourceType,
    });

    return `${displayResourceType} - ${parentName}`;
  };

  render() {
    const { folders, resourceType, assets } = this.props;
    const folderHeaderTitle = this.getFolderHeaderTitle();

    return (
      <div>
        <FolderHeader title={folderHeaderTitle} />

        {/* Content */}
        <FolderContainer
          folderSidebar={
            <FolderSidebar
              showAssetFilterOption={false}
              fileProps={{
                handlers: {},
              }}
              folderProps={{
                data: {
                  folders: folders,
                  foldersUrlProvider: folderId =>
                    `/folders/protected/${resourceType}/${folderId}`,
                },
              }}
            />
          }
          assetsHolder={
            <AssetsContainer>
              {!_.isEmpty(assets) ? (
                <AssetManager
                  showUploader={false}
                  assets={[]}
                  existingAssets={assets}
                  shareEnablerPredicate={_assetId => false}
                />
              ) : (
                <span>No Files Found</span>
              )}
            </AssetsContainer>
          }
        />
      </div>
    );
  }
}

ProtectedFolderList.propTypes = {
  folders: PropTypes.array.isRequired,
  resourceType: PropTypes.string.isRequired,
  assets: PropTypes.array,
  parentName: PropTypes.string.isRequired,
  tenantDisplayTerms: PropTypes.object.isRequired,
};

ProtectedFolderList.defaultProps = {
  folders: [],
  assets: [],
  resourceType: '',
  parentName: 'App Folders',
  tenantDisplayTerms: {},
};

export default withErrorHandler(ProtectedFolderList);

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getSelectedOptions } from '../utils';

const SelectInput = ({
  name,
  placeholder,
  options,
  onChange,
  value: selectedOption,
  isMulti,
  hideSelectedOptions,
  closeMenuOnSelect,
  optionIdentifier,
  isClearable,
  isGrouped,
  ...otherProps
}) => (
  <div>
    <Select
      name={name}
      placeholder={placeholder}
      isMulti={isMulti}
      isClearable={isClearable}
      onChange={value => {
        if (value === null) {
          // Dirty Fix: when option is unselected for single input - null will be set as option
          // Which fails in components that destructure to get value - hence dummy object with desired value key is sent
          onChange(
            isMulti ? [] : { label: '', [optionIdentifier || 'value']: '' }
          );
        } else {
          onChange(value);
        }
      }}
      options={options}
      hideSelectedOptions={hideSelectedOptions}
      closeMenuOnSelect={closeMenuOnSelect}
      classNamePrefix="react-select"
      id="react-select"
      // If optionIdentifier isn't, it is assumed that selectedOption is alredy in valid format
      value={
        _.isNil(optionIdentifier)
          ? selectedOption
          : getSelectedOptions({
              options,
              selectedOption,
              optionIdentifier,
              isMulti,
              isGrouped,
            })
      }
      {...otherProps}
    />
  </div>
);

SelectInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  hideSelectedOptions: PropTypes.bool,
  optionIdentifier: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  isGrouped: PropTypes.bool,
  value: (props, propName, componentName) => {
    const isMultipleValuesExpected = props['isMulti'];

    if (isMultipleValuesExpected && !_.isArray(props[propName])) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. Array Values Expected.'
      );
    } else if (
      props[propName] &&
      !isMultipleValuesExpected &&
      !(_.isString(props[propName]) || _.isNumber(props[propName]))
    ) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. String/Number Expected.'
      );
    }
  },
};

SelectInput.defaultProps = {
  name: '',
  isMulti: false,
  closeMenuOnSelect: true,
  hideSelectedOptions: true,
  isClearable: true,
  optionIdentifier: null,
  isGrouped: false,
};

export default SelectInput;

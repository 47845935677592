import React from 'react';
import PropTypes from 'prop-types';

import FolderList from './FolderList';
import AssetsFilterOptions from '../../../common/components/FolderContainer/FolderSidebar/AssetsFilterOptions';

import '../../../common/styles/folderSidebar.scss';
import '../../styles/folderSidebar.scss';

const FolderSidebar = ({
  fileProps,
  folderProps,
  showAssetFilterOption,
  folderHeaderTitle,
}) => {
  const { handlers: fileHandlers } = fileProps;
  const {
    data: { folders, foldersUrlProvider },
  } = folderProps;

  return (
    <div className="kt-widget24">
      <div className="ibox float-e-margins">
        <div className="ibox-content">
          <div className="file-manager">
            <div className="pl-15 mb-30">
              {showAssetFilterOption && (
                <AssetsFilterOptions
                  onFilterClick={assetType => {
                    fileHandlers.onAssetFilterClick({ assetType });
                  }}
                />
              )}
            </div>

            <h6 className="mb-10 pl-15">{folderHeaderTitle}</h6>

            <ul className="folder-list mb-30">
              {<FolderList folders={folders} folderUrlProvider={foldersUrlProvider} />}
            </ul>

            <div className="clearfix" />
          </div>
        </div>
      </div>
    </div>
  );
};

FolderSidebar.propTypes = {
  showAssetFilterOption: PropTypes.bool,
  fileProps: PropTypes.shape({
    handlers: PropTypes.shape({
      onAssetFilterClick: PropTypes.func,
    }),
  }).isRequired,

  folderProps: PropTypes.shape({
    data: PropTypes.shape({
      folders: PropTypes.array.isRequired,
      foldersUrlProvider: PropTypes.func.isRequired,
    }),
  }).isRequired,

  folderHeaderTitle: PropTypes.string,
};

FolderSidebar.defaultProps = {
  folderHeaderTitle: 'Folders',
  showAssetFilterOption: false,
  fileProps: {},
  folderProps: {
    handlers: {},
    predicates: {},
    data: {
      folders: [],
      foldersUrlProvider: () => {},
    },
  },
};

export default FolderSidebar;

import React from 'react';
import './skeletonStyles.scss';


export const SkeletonWrap = ({ children }) => {
  return (
    <div className="skeleton">
      {children}
    </div>
  )
};

export const SkeletonRow = ({ cols = 4, rows = 1, header = false, className = "" }) => {
  return (
    <React.Fragment>
      {
        Array(rows).fill().map((_, ix) => {
          return (
            <div className={`row px-3 ${className}`} key={ix}>
              {
                Array(cols).fill().map((_, idx) => {
                  return (
                    <div className={`col-md-${Math.floor(12 / cols)}`} key={idx}>
                      <span className={header ? 'header' : ''} />
                    </div>
                  );
                })
              }
            </div>
          );
        })
      }
    </React.Fragment>
  );
};

export default {
  SkeletonRow,
  SkeletonWrap,
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import ProjectTableItem from './ProjectTableItem';
import DropdownButton from '../../common/presentational/dropDownButton';
import { projectSortListOptions, sortProjects } from '../utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import './styles.scss';

const ProjectTable = ({
  projectList: allProjectsList,
  viewAll,
  tenantTerms,
  csrfToken,
}) => {
  const [projectFilterText, setProjectFilterText] = useState(null);
  const [currentSortBy, setCurrentSortBy] = useState(null);

  const { termProject } = tenantTerms;
  let projectList = sortProjects(currentSortBy, allProjectsList);

  if (!isNil(projectFilterText) && !isEmpty(projectFilterText)) {
    projectList = filter(projectList, project =>
      project.name.toLowerCase().includes(projectFilterText.toLowerCase())
    );
  } else {
    if (!viewAll) {
      projectList = projectList.slice(
        Math.max(projectList.length - 9, 0),
        projectList.length
      );
    }
  }

  const onInputChange = ({ target }) => {
    if (!isNil(target.name)) {
      setProjectFilterText(target.value);
    }
  };

  const handleSortBy = event => {
    if (event && event.target) {
      setCurrentSortBy(event.target.value);
    }
  };

  const renderProjectRow = project => {
    return (
      <ProjectTableItem
        key={project.id}
        project={project}
        csrfToken={csrfToken}
      />
    );
  };

  return (
    <React.Fragment>
      <div className="pt-0">
        <div className="m-2">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-3 col-md-4 col-sm-8 d-flex justify-content-center ml-15 mr-15 mt-10 mb-10">
              <input
                style={{
                  height: 37,
                }}
                onChange={onInputChange}
                type="text"
                title="Search by name"
                className="form-control kt-input"
                placeholder="Search by Name"
              />
            </div>
            <div className="col-lg-3 col-md-4 col-sm-8 ml-15 mr-15 mt-10 mb-10">
              <DropdownButton
                // dropDown title is label of currentSortBy item from projectSortListOptions array
                dropdownTitle={
                  currentSortBy !== null
                    ? find(
                        projectSortListOptions,
                        ({ value }) => value === currentSortBy
                      )['label']
                    : 'Sort by'
                }
                onClick={handleSortBy}
                dropdownValues={projectSortListOptions}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="kt-content plr-0">
        <div className="table-responsive projects-table">
          <table className="table table-striped table-hover kt-table__row-equal-width mtb-0 plr-0 border border-secondary">
            <thead>
              <tr style={{ height: 42 }}>
                <th className="td-130">
                  <span className="pl-15">{termProject.singular} Code</span>
                </th>
                <th className="td-220">{termProject.singular} Name</th>
                <th className="td-110">Status</th>
                <th className="td-80">
                  <span>&nbsp;</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {map(projectList, (project, index) =>
                renderProjectRow(project, index)
              )}
              {isEmpty(projectList) && (
                <tr>
                  <td className="text-center" colSpan={4}>
                    <h4>No {termProject.plural}.</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectTable.propTypes = {
  csrfToken: PropTypes.string,
  viewAll: PropTypes.bool,
  projectList: PropTypes.array.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

ProjectTable.defaultProps = {
  viewAll: false,
};

export default withErrorHandler(ProjectTable);

import React from 'react';
import PropTypes from 'prop-types';

import FolderActions from './FolderActions';

const FolderHeader = ({
  title,
  children,
  showFolderUpdateActions,
  onFolderShareClick,
  onFolderRenameClick,
}) => (
  <div className="kt-subheader ">
    <div className="kt-subheader__main">
      <h3 className="kt-subheader__title">{title}</h3>
      <span
        className={children ? 'kt-subheader__separator kt-subheader__separator--v' : ''}
      />
      {children}
    </div>

    {showFolderUpdateActions && (
      <div className="kt-subheader__toolbar">
        <div className="kt-subheader__wrapper">
          <FolderActions
            dropdownLinkType="iconOnly"
            onRenameClick={() => {
              onFolderRenameClick();
            }}
            onShareClick={() => {
              onFolderShareClick();
            }}
          />
        </div>
      </div>
    )}
  </div>
);

FolderHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  showFolderUpdateActions: PropTypes.bool.isRequired,
  onFolderRenameClick: PropTypes.func,
  onFolderShareClick: PropTypes.func,
};

FolderHeader.defaultProps = {
  title: 'Assets',
  children: null,
  showFolderUpdateActions: false,
};

export default FolderHeader;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetManager from '../filestack/AssetManager';
import ReactDraftWysiwygEditor from '../wysiwyg_editor/index';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SIMPLE_WYSIWYG_EDITOR_OPTIONS } from '../common/constants';
import { withErrorHandler } from '../hoc/withErrorHandler';

const WysiwygCommentForm = ({
  renderUploader,
  csrfToken,
  onFileChange,
  assets,
  existingAssets,
  formElementId,
  wysiwygBody,
  onEditorStateChange,
  handleSubmit,
  handleCancel,
  loading,
}) => {
  return (
    <div className="kt-chat">
      <div className="kt-chat__input">
        <div className="kt-chat__editor" id={`element_${formElementId}`}>
          <ReactDraftWysiwygEditor
            editorState={wysiwygBody}
            onEditorStateChange={onEditorStateChange}
            toolbarConfig={{
              options: SIMPLE_WYSIWYG_EDITOR_OPTIONS,
            }}
          />
        </div>

        {!_.isEmpty(_.flatten([...assets, existingAssets])) && (
          <div className="my-2">
            <AssetManager
              showUploader={false}
              assets={assets}
              existingAssets={existingAssets}
              onFileChange={onFileChange}
              csrfToken={csrfToken}
              showGalleryInListView={true}
            />
          </div>
        )}
        <div className="kt-separator kt-separator--dashed kt-separator--sm" />
        <div className="kt-chat__toolbar">
          <div className="kt_chat__tools">
            <AssetManager
              filestackOptions={{
                fromSources: ['local_file_system'],
                maxFiles: 10,
                rootId: 'new-drop-pane',
              }}
              csrfToken={csrfToken}
              showLabel={false}
              showGallery={false}
              renderUploader={renderUploader}
              onFileChange={onFileChange}
              assets={assets}
              existingAssets={existingAssets}
            />
          </div>
          <div className="kt_chat__actions">
            <button
              type="button"
              className="app-btn-secondary btn-elevate btn-pill btn-md"
              onClick={handleCancel}
            >
              Cancel
            </button>
            &nbsp;
            <button
              type="button"
              className="app-btn-primary btn-md btn-elevate btn-pill kt-chat__reply"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? 'Submitting..': 'Submit'}
            </button>
          </div>
        </div>

        <br />
      </div>
    </div>
  );
};

WysiwygCommentForm.propTypes = {
  onEditorStateChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  onFileChange: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  formElementId: PropTypes.number,
};

WysiwygCommentForm.defaultProps = {
  formElementId: null,
};

export default withErrorHandler(WysiwygCommentForm);

import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import isEmpty from 'lodash/isEmpty';

import AlertMessagesContainer from '../common/notifications/AlertMessagesContainer';
import axios from 'axios';

const ReactDraftWysiwygEditor = props => {
  const { editorState, editorStyle, onEditorStateChange, toolbarConfig } =
    props;

  const [errors, setErrors] = React.useState([]);

  const handleDirectUpload = file => {
    const { token, directUploadUrl } = props;
    return new Promise(resolve => {
      var formData = new FormData();
      formData.append('image', file);

      axios({
        method: 'post',
        url: directUploadUrl,
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': token,
        },
        data: formData,
      })
        .then(function (response) {
          resolve({ data: { link: response.data.url } });
        })
        .catch(function (e) {
          if (e.response.status == 400) {
            setErrors(e.response.data.errors);
            resolve({ data: { link: '' } });
          }
        });
    });
  };

  return (
    <React.Fragment>
      {!isEmpty(errors) && (
        <AlertMessagesContainer notificationType={'error'} messages={errors} />
      )}
      <Editor
        editorState={editorState}
        editorStyle={editorStyle}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: handleDirectUpload,
            previewImage: true,
            popupClassName: 'image-upload-popup-on-editor',
          },
          ...toolbarConfig,
        }}
      />
    </React.Fragment>
  );
};

ReactDraftWysiwygEditor.propTypes = {
  editorState: PropTypes.object.isRequired,
  onEditorStateChange: PropTypes.func.isRequired,
  toolbarConfig: PropTypes.object,
  editorStyle: PropTypes.object,
  token: PropTypes.string,
  directUploadUrl: PropTypes.string,
};

ReactDraftWysiwygEditor.defaultProps = {
  editorStyle: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#F1F1F1',
    padding: 5,
    borderRadius: 2,
    height: 200,
  },
  toolbarConfig: {},
};

export default ReactDraftWysiwygEditor;

import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import flow from 'lodash/flow';
import includes from 'lodash/includes';
import isEqual from 'lodash/isEqual';

import {
  WindowScroller,
  AutoSizer,
  Table,
  Column,
  defaultTableRowRenderer,
} from 'react-virtualized';
import 'react-virtualized/styles.css';
import './virtualizedTable.scss';

import DatePicker from 'react-datepicker';
import TextInput from '../common/inputs/text';
import SelectInput from '../common/inputs/select';
import ImpressionEmoji from '../common/ImpressionEmoji';
import SentimentSelect from '../common/inputs/SentimentSelect';

import {
  CUSTOMER_SEARCH_ATTRIBUTE_OPTIONS,
  DATE_TIME_PICKER_FORMATS,
} from '../common/constants';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { formatDateTime } from '../common/utils';
import { formattedDateString } from './utils';

const INITIAL_FORM_RESPONSE_CUSTOMERS_STATE = {
  selectedImpression: '',
  customerFilter: {
    searchKey: 'name',
    searchValue: '',
  },
  selectedResponseDate: null,
};

const customersFormResponseReducer = (state, action) => {
  switch (action.type) {
    case 'selectedImpression': {
      return {
        ...state,
        selectedImpression: action.payload,
      };
    }
    case 'selectedResponseDate': {
      return {
        ...state,
        selectedResponseDate: action.payload,
      };
    }
    case 'handleCustomerFilter': {
      return {
        ...state,
        customerFilter: {
          ...state.customerFilter,
          ...action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
};

// removed the usage of the ref in the package components since we don't have usage currently.
// we can use ref when there is a need in future development.

const FormResponseCustomers = props => {
  const [formResponseState, dispatchFormResponseAction] = React.useReducer(
    customersFormResponseReducer,
    INITIAL_FORM_RESPONSE_CUSTOMERS_STATE
  );

  const handleCustomerFilter = (attributeValue, attributeName) => {
    dispatchFormResponseAction({
      type: 'handleCustomerFilter',
      payload: {
        [attributeName]: attributeValue,
      },
    });
  };

  const handleResponseFilter = (attributeValue, attributeName) => {
    dispatchFormResponseAction({
      type: attributeName,
      payload: attributeValue,
    });
  };

  const responseDateFilter = customers => {
    const { selectedResponseDate } = formResponseState;

    return isNil(selectedResponseDate)
      ? customers
      : filter(customers, ({ latestResponseDate }) =>
          isEqual(
            formattedDateString(selectedResponseDate),
            formattedDateString(latestResponseDate)
          )
        );
  };

  const impressionFilter = customers => {
    const { selectedImpression } = formResponseState;
    return isEmpty(selectedImpression)
      ? customers
      : filter(customers, ({ latestImpression }) =>
          isEqual(selectedImpression, latestImpression)
        );
  };

  const customerAttributeFilter = customers => {
    const {
      customerFilter: { searchKey, searchValue },
    } = formResponseState;

    const lowerCasedSearchValue = searchValue.toLowerCase();

    return isEmpty(searchValue)
      ? customers
      : filter(customers, customer => {
          return (
            !!customer[searchKey] &&
            includes(customer[searchKey].toLowerCase(), lowerCasedSearchValue)
          );
        });
  };

  const renderFilters = () => {
    const { selectedImpression, customerFilter, selectedResponseDate } =
      formResponseState;
    return (
      <div className="row m-4">
        <div className="col-md-2 mb-4">
          <SelectInput
            options={CUSTOMER_SEARCH_ATTRIBUTE_OPTIONS}
            value={customerFilter.searchKey || ''}
            isClearable={false}
            onChange={({ value }) => handleCustomerFilter(value, 'searchKey')}
            optionIdentifier="value"
          />
        </div>
        <div className="col-md-4 col-sm-12 mb-4">
          <TextInput
            name={'searchValue'}
            value={customerFilter.searchValue}
            placeholder={`Customer ${customerFilter.searchKey}...`}
            onInputChange={({ target }) =>
              handleCustomerFilter(target.value, 'searchValue')
            }
          />
        </div>
        <div className="col-md-3 mb-4">
          <SentimentSelect
            value={selectedImpression || ''}
            onChange={({ value }) =>
              handleResponseFilter(value, 'selectedImpression')
            }
          />
        </div>
        <div className="col-md-3 mb-4 datepicker-tweaks">
          <DatePicker
            dropdownMode={'select'}
            selected={selectedResponseDate}
            onChange={value =>
              handleResponseFilter(value, 'selectedResponseDate')
            }
            className={'form-control Select-control'}
            placeholderText="Latest Response on"
            dateFormat={DATE_TIME_PICKER_FORMATS.date}
            isClearable={true}
          />
        </div>
      </div>
    );
  };

  const tableRowRenderer = ({ index, style, ...restProps }) =>
    defaultTableRowRenderer({
      style: {
        ...style,
        backgroundColor: index % 2 === 0 ? '#f7f8fa' : '#fff',
      },
      ...restProps,
    });

  const { formResponseCustomers } = props;

  const filteredFormResponseCustomers = flow(
    responseDateFilter,
    impressionFilter,
    customerAttributeFilter
  )(formResponseCustomers);

  return (
    <div className="kt-portlet kt-portlet--mobile">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Form Response - Customers</h3>
        </div>
      </div>
      <div className="kt-portlet__body plr-0">
        <div className="kt-section m-3">{renderFilters()}</div>
        <div className="kt-section">
          <div className="kt-section__content" style={{ overflowX: 'auto' }}>
            <WindowScroller>
              {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <AutoSizer disableHeight>
                  {({ width }) => (
                    <Table
                      headerHeight={60}
                      width={width < 800 ? 800 : width}
                      height={height}
                      autoHeight
                      rowCount={filteredFormResponseCustomers.length}
                      rowGetter={({ index }) =>
                        filteredFormResponseCustomers[index]
                      }
                      rowHeight={58}
                      rowRenderer={tableRowRenderer}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      overscanRowCount={10}
                      scrollTop={scrollTop}
                      noRowsRenderer={() => (
                        <h3
                          className="text-center p-3"
                          style={{ backgroundColor: '#f7f8fa' }}
                        >
                          No Customers
                        </h3>
                      )}
                    >
                      <Column
                        dataKey="latestImpression"
                        disableSort
                        width={60}
                        flexShrink={0}
                        headerClassName="text-center"
                        className="text-center"
                        cellRenderer={({ cellData }) =>
                          !isEmpty(cellData) ? (
                            <ImpressionEmoji impression={cellData} />
                          ) : null
                        }
                      />
                      <Column
                        dataKey="id"
                        disableSort
                        label="Customer"
                        width={200}
                        flexShrink={0}
                        flexGrow={1}
                        cellRenderer={({
                          rowData: { name, email, phoneNumber, links },
                        }) => (
                          <a href={links.show}>
                            {name || email || phoneNumber}
                          </a>
                        )}
                      />
                      <Column
                        dataKey="responsesCount"
                        disableSort
                        label="Responses"
                        width={180}
                        flexShrink={0}
                        flexGrow={1}
                        headerClassName="text-center"
                        className="text-center"
                        cellRenderer={({ cellData }) => (
                          <span className="text-center">{cellData}</span>
                        )}
                      />
                      <Column
                        dataKey="latestResponseDate"
                        disableSort
                        label="Latest Response Date"
                        width={220}
                        flexShrink={0}
                        flexGrow={1}
                        cellRenderer={({ cellData }) => (
                          <span>
                            {!!cellData && formatDateTime({ date: cellData })}
                          </span>
                        )}
                      />
                      <Column
                        dataKey="links"
                        disableSort
                        width={100}
                        flexShrink={0}
                        className="text-center"
                        cellRenderer={({ cellData }) => (
                          <a
                            className="btn-sm app-btn-outline-primary btn-icon"
                            href={cellData.show}
                          >
                            <i className="fa fa-eye" />
                          </a>
                        )}
                      />
                    </Table>
                  )}
                </AutoSizer>
              )}
            </WindowScroller>
          </div>
        </div>
      </div>
    </div>
  );
};

FormResponseCustomers.propTypes = {
  formResponseCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      responsesCount: PropTypes.number,
      latestResponseDate: PropTypes.string,
      latestImpression: PropTypes.string,
      links: PropTypes.shape({
        show: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default withErrorHandler(FormResponseCustomers);

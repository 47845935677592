import React from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';

import StarRatings from 'react-star-ratings';

const ReviewRating = ({ rating }) => (
  <div className="d-flex align-items-center">
    {typeof rating === 'string' ? (
      <i
        className={`far font-16 ${
          isEqual(rating.toLowerCase(), 'positive')
            ? 'fa-thumbs-up kt-font-success'
            : 'fa-thumbs-down text-darkred'
        }`}
      />
    ) : (
      <React.Fragment>
        <StarRatings
          rating={rating}
          name="rating"
          starDimension="20px"
          starSpacing="4px"
          starRatedColor="gold"
        />
        <span className="kt-font-bold ml-2">{rating}</span>
      </React.Fragment>
    )}
  </div>
);

ReviewRating.propTypes = {
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
export default ReviewRating;

import React from 'react';

const Header = ({ children, queryFormLink }) => (
  <div className="header_section">
    <div className="knowledge_base-container">
      {!_.isNil(queryFormLink) && (
        <div className="d-flex align-items-center justify-content-center m-3">
          <a href={queryFormLink} className="px-2 app-btn-primary">Submit A Query</a>
        </div>
      )}
      <div className="header_section-content">{children}</div>
    </div>
  </div>
);

export default Header;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import includes from 'lodash/includes';
import isNumber from 'lodash/isNumber';
import filter from 'lodash/filter';

import moment from 'moment';

import DatePicker from 'react-datepicker';
import MultiSelect from '../common/inputs/multiSelect';
import Http from '../common/Http';
import Spinner from '../common/presentational/spinner';
import SelectInput from '../common/inputs/select';
import ImpressionsOverview from './ImpressionsOverview';
import TextInput from '../common/inputs/text';
import ImpressionEmoji from '../common/ImpressionEmoji';
import SentimentSelect from '../common/inputs/SentimentSelect';

import {
  renderOptions,
  convertArrayOfObjectsToHash,
  formatDateTime,
} from '../common/utils';
import { alertErrorNotifications } from '../folders/utils';
import { withErrorHandler } from '../hoc/withErrorHandler';
import { DATE_TIME_PICKER_FORMATS } from '../common/constants';
import { useReducer } from 'react';
import { constructUserResponses } from './utils';
import { userResponsesTableReducer } from './formsReducers';

const getInitialState = props => {
  return {
    loading: false,
    filterOptions: {
      forms: [],
      submittedBy: '',
      submitDate: null,
      impression: null,
      selectedProject: null,
    },
    userResponses: constructUserResponses(props.userResponses),
    surveyProjectsHash:
      props.isSurveyPage && props.projectsEnabled
        ? convertArrayOfObjectsToHash(props.surveyProjects, 'id')
        : {},
  };
};

const UserResponsesTable = props => {
  const initialState = getInitialState(props);
  const [tableState, dispatchTableActions] = useReducer(
    userResponsesTableReducer,
    initialState
  );

  useEffect(() => {
    dispatchTableActions({
      type: 'updateUserResponse',
      payload: props.userResponses,
    });
  }, [props.userResponses]);

  const {
    isSurveyPage,
    showRestoreOption,
    showImpressionsOverview,
    projectsEnabled,
    tenantTerms: { termTicket, termProject },
  } = props;
  const { loading, userResponses, filterOptions, surveyProjectsHash } =
    tableState;

  const handleOptionSelect = attributeName => value => {
    let optionValue = value;
    if (includes(['impression', 'selectedProject'], attributeName)) {
      optionValue = value.value;
    }
    if (isEqual(attributeName, 'submittedBy')) {
      optionValue = value.target.value;
    }
    dispatchTableActions({
      type: 'handleFilterOptions',
      payload: { [attributeName]: optionValue },
    });
  };

  const handleDeleteRequest = url => async () => {
    const proceed = confirm('Are you sure, you want to delete?');
    if (proceed) {
      await new Http()
        .onBegin(() => {
          dispatchTableActions({ type: 'initiateDelete' });
        })
        .setToken(props.csrfToken)
        .doesRedirect(true)
        .useAlerts(true)
        .delete(url)
        .onSuccess(() => {
          dispatchTableActions({ type: 'resolved' });
          window.location = window.location.href;
        })
        .onError(err => {
          dispatchTableActions({ type: 'rejected' });
          alertErrorNotifications(err || 'Cannot Delete at this moment!');
        })
        .exec();
    }
  };

  const handleRestoreRequest = async url => {
    const proceed = confirm('Are you sure, you want to restore?');
    if (proceed) {
      await new Http()
        .onBegin(() => {
          dispatchTableActions({ type: 'initiateRestore' });
        })
        .setToken(props.csrfToken)
        .doesRedirect(true)
        .useAlerts(true)
        .put(url, {})
        .onSuccess(() => {
          dispatchTableActions({ type: 'resolved' });
          window.location = window.location.href;
        })
        .onError(err => {
          dispatchTableActions({ type: 'rejected' });
          alertErrorNotifications(
            err || 'Cannot Restore Response at this moment!'
          );
        })
        .exec();
    }
  };

  const renderFilterHeaderRow = () => {
    const {
      isSurveyPage,
      projectsEnabled,
      feedbackForms,
      surveyProjects,
      tenantTerms: { termProject },
    } = props;
    const { filterOptions } = tableState;
    const { forms, submitDate, submittedBy, impression, selectedProject } =
      filterOptions;

    const formNameOptions = map(feedbackForms, option => ({
      id: option.id,
      name: option.name,
    }));

    return (
      <div className={'row ml-15 mr-15 mt-15 pt-20'}>
        {!isSurveyPage ? (
          <div className="col-md-4 mb-4">
            <MultiSelect
              placeholder={'Select a Form'}
              options={formNameOptions}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={forms}
              onChange={handleOptionSelect('forms')}
            />
          </div>
        ) : (
          <React.Fragment>
            <div className={`col-md-${projectsEnabled ? '3' : '4'} mb-4`}>
              <SentimentSelect
                placeholder={'Select an Impression'}
                value={impression || ''}
                onChange={handleOptionSelect('impression')}
              />
            </div>
            {projectsEnabled && (
              <div className="col-md-3 mb-4">
                <SelectInput
                  placeholder={`Select a ${termProject.singular}`}
                  options={renderOptions(surveyProjects)}
                  value={selectedProject || ''}
                  optionIdentifier="value"
                  onChange={handleOptionSelect('selectedProject')}
                />
              </div>
            )}
          </React.Fragment>
        )}
        <div
          className={`col-md-${
            isSurveyPage && projectsEnabled ? '3' : '4'
          } mb-4`}
        >
          <TextInput
            name={'submittedBy'}
            value={submittedBy}
            placeholder={'Submitted By...'}
            onInputChange={handleOptionSelect('submittedBy')}
          />
        </div>
        <div
          className={`col-md-${
            isSurveyPage && projectsEnabled ? '3' : '4'
          } mb-4 datepicker-tweaks`}
        >
          <DatePicker
            allowSameDay
            isClearable={true}
            dropdownMode={'select'}
            selected={submitDate}
            showTimeSelect={false}
            onChange={handleOptionSelect('submitDate')}
            className={'form-control Select-control'}
            placeholderText="Submitted on"
            dateFormat={DATE_TIME_PICKER_FORMATS.date}
          />
        </div>
      </div>
    );
  };

  const filterResults = () => {
    const { filterOptions, userResponses } = tableState;
    const { submittedBy, forms, submitDate, impression, selectedProject } =
      filterOptions;

    const selectedFormIds = map(forms, 'id');

    if (
      isEmpty(submittedBy) &&
      isEmpty(selectedFormIds) &&
      isNil(submitDate) &&
      isEmpty(impression) &&
      !isNumber(selectedProject)
    ) {
      return userResponses;
    }

    return filter(userResponses, userResponse => {
      let isSubmittedByMatched = isEmpty(submittedBy)
        ? true
        : includes(
            userResponse.submitted_by.toLowerCase(),
            submittedBy.toLowerCase()
          );
      let isFormIdMatched = isEmpty(selectedFormIds)
        ? true
        : includes(selectedFormIds, userResponse.form_id);
      let isSubmittedAtMatched = isNil(submitDate)
        ? true
        : moment(submitDate).isSame(moment(userResponse.submitted_at), 'day');
      let isImpressionMatched = isEmpty(impression)
        ? true
        : isEqual(impression, userResponse.impression);
      let isSelectedProject = !isNumber(selectedProject)
        ? true
        : isEqual(selectedProject, userResponse.assigned_project_id);

      if (
        isSubmittedByMatched &&
        isFormIdMatched &&
        isSubmittedAtMatched &&
        isImpressionMatched &&
        isSelectedProject
      ) {
        return userResponse;
      }
    });
  };

  const getAssignedProjectName = projectId => {
    const assignedProject = surveyProjectsHash[projectId];

    return !isNil(assignedProject) ? assignedProject.name : '-';
  };

  const renderItemRow = (item, _idx, termTicket) => (
    <tr key={item.id}>
      {props.isSurveyPage ? (
        <td className="td-30 text-center">
          {!isEmpty(item.impression) ? (
            <ImpressionEmoji impression={item.impression} />
          ) : null}
        </td>
      ) : (
        <td className="text--truncate">
          <a href={item.links.show}>{item.form_name}</a>
        </td>
      )}
      <td className="text--truncate">{item.submitted_by}</td>
      {props.isSurveyPage && (
        <React.Fragment>
          {props.projectsEnabled && (
            <td>{getAssignedProjectName(item.assigned_project_id)}</td>
          )}
          <td>{item.email_from_field || '-'}</td>
          <td>{item.phone_number_from_field || '-'}</td>
        </React.Fragment>
      )}
      <td className="td-110">
        {formatDateTime({ date: item.submitted_at, formatTime: false })}
      </td>
      <td className="td-110">
        {props.showRestoreOption ? (
          <button
            onClick={() => handleRestoreRequest(item.links.restore)}
            className="btn-sm btn-outline-darkred"
          >
            <span className="kt-nav__link-text">Restore</span>
          </button>
        ) : (
          <div className="kt-task_row--options pr-10">
            <div className="btn-group dropleft">
              <button
                type="button"
                className="btn-sm app-btn-outline-primary dropdown-toggle hide--on-mobile"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Options
              </button>
              <button
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                type="button"
                className="app-btn-outline-secondary btn-circle btn-icon show--on-mobile"
              >
                <i className="la la-ellipsis-h" />
              </button>
              <div
                className="dropdown-menu"
                x-placement="left-start"
                style={{
                  position: 'absolute',
                  willChange: 'transform',
                  top: 0,
                  left: 0,
                  transform: 'translate3d(-2px, 0px, 0px)',
                }}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={item.links['show']}
                  className="dropdown-item"
                >
                  <span className="kt-nav__link-text">View</span>
                </a>

                {props.isSurveyPage &&
                  (isEmpty(item['ticket']) ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.links['new_user_response_ticket']}
                      className="dropdown-item"
                    >
                      <span className="kt-nav__link-text">{`Create ${termTicket.singular}`}</span>
                    </a>
                  ) : (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.ticket.links['show']}
                      className="dropdown-item"
                    >
                      <span className="kt-nav__link-text">{`View ${termTicket.singular}`}</span>
                    </a>
                  ))}

                {props.showDeleteOption && (
                  <button
                    onClick={handleDeleteRequest(item.links['delete'])}
                    className="dropdown-item text-darkred"
                  >
                    <span className="kt-nav__link-text">Delete</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </td>
    </tr>
  );

  let filteredUserResponses = filterResults();

  const colSpanOnEmptyRows = isSurveyPage ? (projectsEnabled ? 7 : 6) : 4;

  return (
    <div
      style={{
        overflow: 'scroll',
      }}
    >
      {loading && <Spinner />}

      {renderFilterHeaderRow()}
      {showImpressionsOverview && (
        <div
          style={{
            visibility: `${
              isEmpty(filterOptions['impression']) ? 'visible' : 'hidden'
            }`,
          }}
        >
          <ImpressionsOverview userResponses={filteredUserResponses} />
        </div>
      )}

      <div className="table-responsive">
        <table
          className="table table-striped kt-table__row-equal-width"
          style={{ minWidth: '650px' }}
        >
          <thead>
            <tr>
              {isSurveyPage ? (
                <th className="td-30 text-center">&nbsp;</th>
              ) : (
                <th>Form Name</th>
              )}
              <th>Submitted By</th>
              {isSurveyPage && (
                <React.Fragment>
                  {projectsEnabled && <th>{termProject.singular}</th>}
                  <th>Email</th>
                  <th>Phone</th>
                </React.Fragment>
              )}
              <th className="td-110">Date Submitted</th>
              <th className="td-110">&nbsp;</th>
            </tr>
          </thead>

          <tbody>
            {map(filteredUserResponses, (item, idx) => {
              return renderItemRow(item, idx, termTicket);
            })}

            {isEmpty(filteredUserResponses) && !isEmpty(userResponses) && (
              <tr>
                <td colSpan={colSpanOnEmptyRows}>
                  <div style={{ margin: '20px auto' }}>
                    <h2 className="text-center">
                      No result matching the filters.
                    </h2>
                  </div>
                </td>
              </tr>
            )}
            {isEmpty(userResponses) && (
              <tr>
                <td colSpan={colSpanOnEmptyRows}>
                  <div className="m-4 text-center">
                    <h3 className="p-4">No response is provided yet.</h3>
                    {!isSurveyPage && !showRestoreOption && (
                      <p className="app-text-primary">
                        Click on <b>New Form</b> button to create new.
                      </p>
                    )}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UserResponsesTable.propTypes = {
  userResponses: PropTypes.array.isRequired,
  feedbackForms: PropTypes.array.isRequired,
  showRestoreOption: PropTypes.bool,
  isSurveyPage: PropTypes.bool,
  projectsEnabled: PropTypes.bool,
  showImpressionsOverview: PropTypes.bool,
  surveyProjects: PropTypes.array,
  tenantTerms: PropTypes.shape({
    termTicket: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
    termProject: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }),
  csrfToken: PropTypes.string,
  showDeleteOption: PropTypes.bool,
};

UserResponsesTable.defaultProps = {
  showRestoreOption: false,
  isSurveyPage: false,
  projectsEnabled: false,
  showImpressionsOverview: false,
};

export default withErrorHandler(UserResponsesTable);

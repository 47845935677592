import React, { useState, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import './progressStyles.scss';
import CourseTableWithProgress from './CoursesTableWithProgress';
import CurriculumTableWithProgress from './CurriculumTableWithProgress';

const AllProgress = ({ csrfToken, links }) => {
  const [tabsToRender, setTabsToRender] = useState([]);
  const isCourseTabSelected = useMemo(() => {
    const courseTab = tabsToRender.find((t) => (t.slug === 'courses'));
    return (courseTab ? courseTab.isVisible : true);
  }, [tabsToRender]);

  const selectCourses = useCallback(() => {
    setTabsToRender((prevTabs) => prevTabs.map((tab) => ({
      ...tab,
      isVisible: tab.slug === 'courses',
    })))
  }, []);

  const selectCurriculums = useCallback(() => {
    if (tabsToRender.length < 2) {
      return setTabsToRender((prevTabs) => (
        [
          ...prevTabs.map((t) => ({ ...t, isVisible: false })),
          {
            slug: 'curriculums',
            Comp: CurriculumTableWithProgress,
            props: { csrfToken, fetchUrl: links.curriculumsFetcher },
            isVisible: true,
          }
        ]
      ));
    }

    setTabsToRender((prevTabs) => prevTabs.map((tab) => ({
      ...tab,
      isVisible: tab.slug === 'curriculums',
    })));
  }, [tabsToRender]);

  useEffect(() => {
    setTabsToRender([
      {
        slug: 'courses',
        Comp: CourseTableWithProgress,
        props: { csrfToken, fetchUrl: links.coursesFetcher },
        isVisible: true,
      }
    ]);
  }, [csrfToken, links.coursesFetcher]);

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Learner Progress</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <ul className="learner-dashboard__tabs mb-3 mx-2 p-0 flex-row">
          <li
            className={`text-capitalize cursor-pointer ${
              isCourseTabSelected ? 'app-text-primary' : ''
            }`}
            onClick={selectCourses}
          >
            Courses
          </li>
          <li
            className={`text-capitalize cursor-pointer ${
              isCourseTabSelected ? '' : 'app-text-primary'
            }`}
            onClick={selectCurriculums}
          >
            Curriculums
          </li>
        </ul>

        <div>
          {
            tabsToRender.map(({ slug, Comp, props, isVisible }) => (
              <div key={slug} style={{ display: (isVisible ? 'block' : 'none') }}>
                <Comp {...props} />
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

AllProgress.props = {
  csrfToken: PropTypes.string.isRequired,
  links: PropTypes.shape({
    coursesFetcher: PropTypes.string.isRequired,
    curriculumsFetcher: PropTypes.string.isRequired,
  }).isRequired,
};

export default AllProgress;

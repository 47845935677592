import React from 'react';
import PropTypes from 'prop-types';

import MembersTable from '../../teams/members_table';
import MultiSelect from '../inputs/multiSelect';
import FormRow from '../presentational/formRow';

import { renderOptions } from '../../common/utils';

import isEmpty from 'lodash/isEmpty';

const MembersForm = ({
  userList,
  memberList,
  selectedUsersRoles,
  selectedUsersIds,
  selectedTeamsIds,
  entireTeams,
  allRoles,
  tenantTerms,
  hideTeamSection,
  onMultiSelectChange,
  onUserSelect,
  onUserDelete,
  onUserRoleChange,
}) => {
  const { termUser } = tenantTerms;
  return (
    <div>
      <div className="p-4">
        <h3>Assign {termUser.plural}</h3>
      </div>
      <div className="">
        <FormRow label={termUser.plural}>
          <MultiSelect
            name={'members_users'}
            options={renderOptions(userList)}
            onChange={onUserSelect}
            value={selectedUsersIds}
            optionIdentifier="value"
          />
        </FormRow>

        {!isEmpty(memberList) && (
          <div className="form-group  kt-form__group row">
            <label className="col-2 col-form-label">Team Members</label>
            <div className="col-md-10">
              <MembersTable
                members={memberList}
                userRoles={selectedUsersRoles}
                handleOnDelete={onUserDelete}
                handleRoleChange={onUserRoleChange}
                allRoles={allRoles}
              />
            </div>
          </div>
        )}
      </div>
      {!hideTeamSection && (
        <React.Fragment>
          <div className="p-4">
            <h3>Assign Teams</h3>
          </div>
          <div className="">
            <FormRow label={'Team Sections'}>
              <MultiSelect
                name={'members_teams'}
                options={renderOptions(entireTeams)}
                onChange={values =>
                  onMultiSelectChange(values, 'selectedTeamsIds')
                }
                optionIdentifier="value"
                value={selectedTeamsIds}
              />
            </FormRow>
            <br />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

MembersForm.defaultProps = {
  selectedUsersIds: [],
  selectedUsersRoles: {},
  selectedTeamsIds: [],
  hideTeamSection: false,
  tenantTerms: {
    termUser: {
      singular: 'User',
      plural: 'Users',
    },
  },
};

MembersForm.propTypes = {
  tenantTerms: PropTypes.shape({
    termUser: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  hideTeamSection: PropTypes.bool,
  userList: PropTypes.array.isRequired,
  memberList: PropTypes.array.isRequired,
  selectedUsersIds: PropTypes.array,
  selectedUsersRoles: PropTypes.object,
  selectedTeamsIds: PropTypes.array,
  entireTeams: PropTypes.array.isRequired,
  allRoles: PropTypes.array.isRequired,
  onUserSelect: PropTypes.func.isRequired,
  onUserRoleChange: PropTypes.func.isRequired,
  onUserDelete: PropTypes.func.isRequired,
  onMultiSelectChange: PropTypes.func.isRequired,
};

export default MembersForm;

import React, { Fragment, useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Modal from '../../../common/Modal/Modal';
import Http from '../../../common/Http';
import { withErrorHandler } from '../../../hoc/withErrorHandler';
import StatusModal from './StatusModal';

import useEscapeKey from '../../../common/hooks/useEscapeKey';

import RadioButton from '../../../common/inputs/RadioButton';
import CurriculumSelect from './CustomCurriculumSelect';
import { getGroupedByStatus } from './utils';
import FormElementHelperText from '../../../common/presentational/FormElementHelperText';

const CLONE_CURRICULUM_COURSE_STATE = {
  isInsideSameCurriculum: true,
  isOutsideCurriculum: false,
  isInsideAnotherCurriculum: false,
  curriculumId: 0,
};

const curriculumCloneReducer = (state, action) => {
  switch (action.type) {
    case 'cloneInsideSameCurriculum': {
      return {
        ...CLONE_CURRICULUM_COURSE_STATE,
        ...action.payload,
      };
    }
    case 'cloneInsideAnotherCurriculum':
    case 'cloneOutsideCurriculum': {
      return {
        ...CLONE_CURRICULUM_COURSE_STATE,
        isInsideSameCurriculum: false,
        ...action.payload,
      };
    }
    case 'setCurriculumId': {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      throw new Error('Unhandled Action Type.');
  }
};

const CloneLink = ({
  link,
  subjectName,
  csrfToken,
  toggleLoader,
  inManageCourses,
  tenantTerms,
  curriculumCloneConfig,
}) => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isViewStatusModalOpen, setIsViewStatusModalOpen] = useState(false);
  const [isOngoing, setIsOngoing] = useState(false);
  const [data, setData] = useState(null);

  useEscapeKey(() => setIsConfirmationModalOpen(false));

  const grouped = inManageCourses
    ? getGroupedByStatus({
        dataArray: curriculumCloneConfig.remainingCloneTargetCurriculums,
      })
    : [];

  const [curriculumState, dispatch] = useReducer(
    curriculumCloneReducer,
    CLONE_CURRICULUM_COURSE_STATE
  );

  const getCustomOptions = () => {
    const {
      isInsideSameCurriculum,
      isInsideAnotherCurriculum,
      isOutsideCurriculum,
      curriculumId,
    } = curriculumState;
    if (isInsideSameCurriculum) {
      return curriculumCloneConfig.currentCurriculum.id;
    }
    if (isInsideAnotherCurriculum) {
      return curriculumId;
    }
    if (isOutsideCurriculum) {
      return false;
    }
  };

  const customOptions = inManageCourses
    ? {
        withinCurriculum: getCustomOptions(),
      }
    : {};

  const Requester = new Http()
    .onBegin(() => {
      toggleLoader();
      setIsConfirmationModalOpen(false);
    })
    .setToken(csrfToken)
    .useAlerts()
    .doesRedirect(false)
    .useAPIDataFormatters({
      snakifyRequestData: true,
      camelizeResponseData: true,
    })
    .onError(() => {
      toggleLoader();
    });

  const initiateResourceCloning = () => {
    Requester.setPostData({
      customOptions: customOptions,
    }).onSuccess(({ data }) => {
      setIsOngoing(false);
      setData(data);
      toggleLoader();
      setIsViewStatusModalOpen(true);
    });

    Requester.post(link);
    Requester.exec();
  };

  const manageCloningInitiation = () => {
    if (inManageCourses) {
      setIsConfirmationModalOpen(true);
    } else {
      initiateResourceCloning();
    }
  };

  const getCloneStatus = () => {
    Requester.onSuccess(({ data }) => {
      if (data.cloneLog === 'NOT_FOUND') {
        toggleLoader();
        manageCloningInitiation();
      } else {
        setIsOngoing(true);
        setData(data);
        toggleLoader();
        setIsViewStatusModalOpen(true);
      }
    });

    Requester.get(link);
    Requester.exec();
  };

  const initiateCloning = () => {
    initiateResourceCloning();
    setIsViewStatusModalOpen(modalStatus => !modalStatus);
  };

  const renderCloningRadioOptions = () => (
    <div>
      <RadioButton>
        <RadioButton.InColumn>
          <RadioButton.ButtonWrapper
            inputName="insideCurriculum"
            inputId="insideCurriculum"
            inputValue="insideCurriculum"
            checked={curriculumState.isInsideSameCurriculum}
            onInputChange={() =>
              dispatch({
                type: 'cloneInsideSameCurriculum',
                payload: {
                  isInsideSameCurriculum:
                    !curriculumState.isInsideSameCurriculum,
                },
              })
            }
            option={`Clone ${tenantTerms.termCourse.singular} Within Current 
              ${tenantTerms.termCurriculum.singular}`}
          />
          <RadioButton.ButtonWrapper
            inputName="outsideCurriculum"
            inputId="outsideCurriculum"
            inputValue="outsideCurriculum"
            checked={curriculumState.isOutsideCurriculum}
            onInputChange={() =>
              dispatch({
                type: 'cloneOutsideCurriculum',
                payload: {
                  isOutsideCurriculum: !curriculumState.isOutsideCurriculum,
                },
              })
            }
            option={`Clone ${tenantTerms.termCourse.singular} outside 
              ${tenantTerms.termCurriculum.plural} (As An Independent ${tenantTerms.termCourse.singular})`}
          />
          <RadioButton.ButtonWrapper
            inputName="insideOtherCurriculum"
            inputId="insideOtherCurriculum"
            inputValue="insideOtherCurriculum"
            checked={curriculumState.isInsideAnotherCurriculum}
            onInputChange={() =>
              dispatch({
                type: 'cloneInsideAnotherCurriculum',
                payload: {
                  isInsideAnotherCurriculum:
                    !curriculumState.isInsideAnotherCurriculum,
                },
              })
            }
            option={`Clone ${tenantTerms.termCourse.singular} Within Another 
              ${tenantTerms.termCurriculum.singular}`}
          />
        </RadioButton.InColumn>
      </RadioButton>
      {curriculumState.isInsideAnotherCurriculum && (
        <div className="my-2" style={{ width: 300 }}>
          <CurriculumSelect
            placeholder={'Select Curriculum'}
            name={'selectedCurriculum'}
            options={grouped}
            value={curriculumState.curriculumId}
            onChange={value =>
              dispatch({
                type: 'setCurriculumId',
                payload: {
                  curriculumId: value.id,
                },
              })
            }
          />
        </div>
      )}
      {(curriculumState.isInsideAnotherCurriculum ||
        curriculumState.isInsideSameCurriculum) && (
        <FormElementHelperText
          text={`Learners enrolled in ${tenantTerms.termCurriculum.singular} will be considered as Learners for this ${tenantTerms.termCourse.singular} - To enroll new batch, kindly clone outside ${tenantTerms.termCurriculum.singular}`}
        />
      )}
    </div>
  );

  const newCourseCloneConfirmationModal = (
    <Modal
      modalState={isConfirmationModalOpen}
      header={'Cloning Confirmation'}
      content={
        <div>
          <h5>
            Do you want to Clone{' '}
            <strong>
              <em>{subjectName}</em>
            </strong>
            ?
          </h5>
          {inManageCourses && renderCloningRadioOptions()}
          <div className="text-right mt-3">
            <button
              className="btn-outline-darkred mr-2"
              onClick={() => setIsConfirmationModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="app-btn-primary"
              onClick={() => initiateResourceCloning()}
            >
              Clone
            </button>
          </div>
        </div>
      }
      disableBackdropClick
      hideClose={true}
    />
  );

  const renderCloneButton = (
    <button className="dropdown-item" onClick={getCloneStatus}>
      Clone {subjectName}
    </button>
  );

  return (
    <Fragment>
      {renderCloneButton}
      {inManageCourses && newCourseCloneConfirmationModal}
      {isViewStatusModalOpen && !isEmpty(data) && (
        <StatusModal
          onCloseCallback={() => setIsViewStatusModalOpen(false)}
          data={data}
          fetchData={initiateCloning}
          isOngoing={isOngoing}
        />
      )}
    </Fragment>
  );
};

CloneLink.propTypes = {
  link: PropTypes.string.isRequired,
  subjectName: PropTypes.string.isRequired,
  csrfToken: PropTypes.string.isRequired,
  toggleLoader: PropTypes.func,
  inManageCourses: PropTypes.bool,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termCurriculum: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  curriculumCloneConfig: PropTypes.shape({
    currentCurriculum: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    remainingCloneTargetCurriculums: PropTypes.array,
  }),
};

CloneLink.defaultProps = {
  inManageCourses: false,
};

export default withErrorHandler(CloneLink);

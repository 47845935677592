import React from 'react';
import PropTypes from 'prop-types';
import { getFullAddress } from '../utils';
import ProjectActionButtons from '../ProjectActionButtons';

const ProjectTableItem = ({ project, csrfToken }) => {
  const { name, links, code, status } = project;

  return (
    <tr style={{ height: 30 }}>
      <td className="td-130">
        <span className="pl-15">{code}</span>
      </td>
      <td className="td-220">
        <a href={links.show} className="text-truncate kt-font-dark">
          <b>{name}</b>
        </a>
        <p>{getFullAddress(project)}</p>
      </td>
      <td className="td-110 text-capitalize">
        <span className={`project-status__dot ${status}`} />
        <span>{status}</span>
      </td>
      <td className="text-truncate td-80 d-table-cell">
        <ProjectActionButtons links={links} csrfToken={csrfToken} />
      </td>
    </tr>
  );
};

ProjectTableItem.propTypes = {
  csrfToken: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    typeColor: PropTypes.string.isRequired,
    links: PropTypes.shape({
      show: PropTypes.string,
    }).isRequired,
    code: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }),
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
};

export default ProjectTableItem;

import quantize from 'quantize';

function createPixelArray(imgData, pixelCount, quality) {
  const pixels = imgData;
  const pixelArray = [];

  for (let i = 0, offset, r, g, b, a; i < pixelCount; i = i + quality) {
    offset = i * 4;
    r = pixels[offset + 0];
    g = pixels[offset + 1];
    b = pixels[offset + 2];
    a = pixels[offset + 3];

    // If pixel is mostly opaque and not white
    if (typeof a === 'undefined' || a >= 125) {
      if (!(r > 250 && g > 250 && b > 250)) {
        pixelArray.push([r, g, b]);
      }
    }
  }
  return pixelArray;
}

function validateOptions(options) {
  let { colorCount, quality } = options;

  if (typeof colorCount === 'undefined' || !Number.isInteger(colorCount)) {
    colorCount = 10;
  } else if (colorCount === 1) {
    throw new Error(
      'colorCount should be between 2 and 20. To get one color, call getColor() instead of getPalette()'
    );
  } else {
    colorCount = Math.max(colorCount, 2);
    colorCount = Math.min(colorCount, 20);
  }

  if (
    typeof quality === 'undefined' ||
    !Number.isInteger(quality) ||
    quality < 1
  ) {
    quality = 10;
  }

  return {
    colorCount,
    quality,
  };
}

var ColorThief = function () {};

ColorThief.prototype.getColorFromUrl = function (imageUrl, callback, quality) {
  const sourceImage = new Image();

  sourceImage.addEventListener('load', () => {
    const palette = this.getPalette(sourceImage, 5, quality);
    const dominantColor = palette[0];
    callback(dominantColor, imageUrl);
  });
  sourceImage.crossOrigin = 'Anonymous';
  sourceImage.src = imageUrl;
};

const CanvasImage = function (image) {
  this.canvas = document.createElement('canvas');
  this.context = this.canvas.getContext('2d');
  this.width = this.canvas.width = image.naturalWidth;
  this.height = this.canvas.height = image.naturalHeight;
  this.context.drawImage(image, 0, 0, this.width, this.height);
};

CanvasImage.prototype.getImageData = function () {
  return this.context.getImageData(0, 0, this.width, this.height);
};

ColorThief.prototype.getColor = function (sourceImage, quality = 10) {
  const palette = this.getPalette(sourceImage, 5, quality);
  const dominantColor = palette[0];
  return dominantColor;
};

ColorThief.prototype.getPalette = function (sourceImage, colorCount, quality) {
  const options = validateOptions({
    colorCount,
    quality,
  });

  // Create custom CanvasImage object
  const image = new CanvasImage(sourceImage);
  const imageData = image.getImageData();
  const pixelCount = image.width * image.height;
  const pixelArray = createPixelArray(
    imageData.data,
    pixelCount,
    options.quality
  );
  // Send array to quantize function which clusters values
  // using median cut algorithm
  const cmap = quantize(pixelArray, options.colorCount);
  const palette = cmap ? cmap.palette() : null;

  return palette;
};

export default ColorThief;

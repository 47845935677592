import { useContext } from 'react';
import { LearningForumContext } from '../index';

const useLearningForumContext = () => {
  const learningForumContext = useContext(LearningForumContext);
  if (learningForumContext === undefined) {
    throw new Error(
      'useLearningForumContext must be used within a LearningForumProvider'
    );
  }
  return learningForumContext;
};

export default useLearningForumContext;

// https://github.com/bendrucker/snakecase-keys

import mapObj from './mapObj';
import snakeCase from 'to-snake-case';

function matches(patterns, value) {
  return patterns.some(function (pattern) {
    return typeof pattern === 'string'
      ? pattern === value
      : pattern.test(value);
  });
}

export default function (obj, options) {
  options = Object.assign({ deep: true, exclude: [] }, options);

  return mapObj(
    obj,
    function (key, val) {
      return [matches(options.exclude, key) ? key : snakeCase(key), val];
    },
    options
  );
}

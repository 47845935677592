import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ForumCloningContext } from '.';
import SelectInput from '../../common/inputs/select';
import FormElementHelperText from '../../common/presentational/FormElementHelperText';
import { getCategoryRandomColors } from '../utils';

const CategorySelect = ({ termCourse }) => {
  const { forumDataState, dispatchForumDataOperation } =
    useContext(ForumCloningContext);

  const { categories, categoryFilter } = forumDataState;

  const categoryFilters = [{ id: '', name: 'All' }, ...categories];

  const CustomOption = prop => {
    const { innerProps, innerRef, data } = prop;
    return (
      <div className="custom-select-option" ref={innerRef} {...innerProps}>
        <div
          style={{
            backgroundColor: getCategoryRandomColors(data.name),
          }}
          className="color mr-2"
        ></div>
        <span className="text-black-50 font-weight-normal">{data.name}</span>
      </div>
    );
  };

  const renderOptionLabel = label => (
    <div>
      <i
        className="fa fa-circle mr-2 font-10"
        style={{ color: getCategoryRandomColors(label) }}
      />
      {label}
    </div>
  );

  return (
    <div className="category-filter">
      <SelectInput
        name="categoryFilter"
        placeholder="Select a category"
        options={categoryFilters}
        onChange={v => {
          dispatchForumDataOperation({
            type: 'setCategoryFilter',
            payload: { categoryFilter: v.id },
          });
        }}
        value={categoryFilter || categoryFilter[0] || ''}
        closeMenuOnSelect
        optionIdentifier="id"
        isClearable={false}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        components={{ Option: CustomOption }}
        formatOptionLabel={({ name }) => renderOptionLabel(name)}
      />
      <FormElementHelperText
        text={`Only the categories present in Cloned ${termCourse.singular} are listed for convenience`}
      />
    </div>
  );
};

CategorySelect.propTypes = {
  termCourse: PropTypes.shape({
    singular: PropTypes.string,
    plural: PropTypes.string,
  }),
};

export default CategorySelect;

import React, { Component } from 'react';
import ReactFlowPlayer from 'react-flow-player';

const ReactFlowPlayerWrapper = ({ poster, playerId, sources }) => (
  <ReactFlowPlayer
    poster={poster}
    playerInitScript="https://releases.flowplayer.org/7.2.7/flowplayer.min.js"
    playerId={playerId}
    sources={sources}
  />
);

export default ReactFlowPlayerWrapper;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Toggle from '../common/inputs/Toggle';
import DynamicMultiSelect from '../common/inputs/dynamicMultiSelect';
import Select from '../common/inputs/select';
import { constructMultiSelectOptions } from '../common/utils';

import map from 'lodash/map';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

const BulkCSVProjectsTable = ({
  tenantTerms: { termTicket },
  getProjectAttributeValue,
  handleProjectAttributeChange,
  projectsData,
  projectsInOrder,
  categories,
  timeZones,
}) => {
  const [expandedProjectRows, setExpandedProjectRows] = useState([]);
  const handleProjectRowExpand = projectId => {
    let newExpandedProjectRows = [];

    if (includes(expandedProjectRows, projectId)) {
      newExpandedProjectRows = filter(
        expandedProjectRows,
        id => projectId !== id
      );
    } else {
      newExpandedProjectRows = [...expandedProjectRows, projectId];
    }

    setExpandedProjectRows(newExpandedProjectRows);
  };

  const isProjectRowExpanded = id => includes(expandedProjectRows, id);

  const renderAdditionInfoTable = project => (
    <table className="table table-striped mb-0">
      <tbody>
        <tr>
          <td style={{ width: '150px' }}>
            <strong>Address:</strong>
          </td>
          <td>{project.address || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>Address-2:</strong>
          </td>
          <td>{project.address2 || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>City:</strong>
          </td>
          <td>{project.city || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>State:</strong>
          </td>
          <td>{project.state || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>Zip Code:</strong>
          </td>
          <td>{project.zipCode || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>Latitude:</strong>
          </td>
          <td>{project.latitude || '-'}</td>
        </tr>
        <tr>
          <td>
            <strong>Longitude:</strong>
          </td>
          <td>{project.longitude || '-'}</td>
        </tr>
      </tbody>
    </table>
  );

  const renderProjectsTableHead = () => (
    <thead>
      <tr>
        <th className="td-30"></th>
        <th>Name</th>
        <th className="text-center td-130">Show {termTicket.plural}?</th>
        <th className="td-220">Categories</th>
        <th className="td-220">TimeZone</th>
      </tr>
    </thead>
  );

  const renderProjectAttributeToggle = (projectId, attributeName = '') => {
    if (isEmpty(attributeName)) return null;

    return (
      <Toggle
        name={attributeName}
        onClick={value =>
          handleProjectAttributeChange(projectId, attributeName, value)
        }
        active={getProjectAttributeValue(projectId, attributeName) || false}
        on={<span>YES</span>}
        off={<span>NO</span>}
        width={60}
        height={30}
        recalculateOnResize
      />
    );
  };

  const renderProjectsTableBody = () => (
    <tbody>
      {map(projectsInOrder, projectId => {
        const project = projectsData[projectId];
        return (
          <React.Fragment key={project.id}>
            <tr>
              <td
                className="text-center cursor-pointer td-30"
                style={{ width: '40px' }}
                onClick={() => handleProjectRowExpand(project.id)}
              >
                <i
                  className={`fa fa-caret-${
                    isProjectRowExpanded(project.id) ? 'down' : 'right'
                  }`}
                />
              </td>
              <td>{project.name}</td>
              <td className="text-center td-130">
                {renderProjectAttributeToggle(project.id, 'showTickets')}
              </td>
              <td className="td-220">
                <DynamicMultiSelect
                  name={'categories'}
                  inputProps={{
                    maxLength: 30,
                  }}
                  options={categories}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  placeholder="Select / Create Categories"
                  onChange={value =>
                    handleProjectAttributeChange(
                      project.id,
                      'categories',
                      value
                    )
                  }
                  value={
                    getProjectAttributeValue(project.id, 'categories') || []
                  }
                />
              </td>
              <td className="td-220">
                <Select
                  name="timeZone"
                  placeholder="Select TimeZone"
                  options={constructMultiSelectOptions(timeZones)}
                  value={getProjectAttributeValue(project.id, 'timeZone')}
                  onChange={({ value }) =>
                    handleProjectAttributeChange(project.id, 'timeZone', value)
                  }
                  isClearable={false}
                  optionIdentifier="value"
                />
              </td>
            </tr>
            {isProjectRowExpanded(project.id) && (
              <tr>
                <td className="td-30"></td>
                <td colSpan={6} className="p-0">
                  {renderAdditionInfoTable(project)}
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
    </tbody>
  );

  return (
    <div className="table-responsive">
      <table className="table table-bordered kt-table__row-equal-width">
        {renderProjectsTableHead()}
        {renderProjectsTableBody()}
      </table>
    </div>
  );
};

BulkCSVProjectsTable.propTypes = {
  projectsData: PropTypes.object.isRequired,
  projectsInOrder: PropTypes.arrayOf(PropTypes.number).isRequired,
  getProjectAttributeValue: PropTypes.func.isRequired,
  handleProjectAttributeChange: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  timeZones: PropTypes.arrayOf(PropTypes.string).isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
    termTicket: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BulkCSVProjectsTable;

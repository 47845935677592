import React from 'react';
import PropTypes from 'prop-types';

import AppFolderList from './AppFolderList';
import { APP_FOLDERS_URL_CONFIG } from '../../../../constants';
import { getTenantDisplayTerm } from '../../../../../utils';

const displayTermForFolder = ({ displayTerms, appFolderName }) => (
  getTenantDisplayTerm({ tenantDisplayTerms: displayTerms, resourceTerm: appFolderName })
);

const isFolderEligibleForDisplay = ({ displayConfig, appFolderName }) => {
  const folderName = _.capitalize(appFolderName);
  const configKey = `show${folderName}`;

  return (
    _.get(displayConfig, configKey, false)
  );
}

const AppFolders = ({ displayTerms, displayConfig }) => {
  return (
    <React.Fragment>
      <h6 className="mb-10 pl-15">App Folders</h6>
      <ul className="folder-list mb-30">
        {
          _.map(APP_FOLDERS_URL_CONFIG, (appFolderUrl, appFolderName) => (
            isFolderEligibleForDisplay({ displayConfig, appFolderName }) && (
              <AppFolderList
                key={`${appFolderName} - ${appFolderUrl}`}
                folderName={displayTermForFolder({ displayTerms, appFolderName })}
                folderUrl={appFolderUrl}
              />
            )
          ))
        }
      </ul>
    </React.Fragment>
  );
};

AppFolders.propTypes = {
  displayTerms: PropTypes.object.isRequired,
  displayConfig: PropTypes.object.isRequired,
}

AppFolders.defaultProps = {
  displayTerms: {
    'projects': 'Projects',
    'courses': 'Courses',
  },
  displayConfig: {
    showCourses: false,
    showProjects: false,
  },
}

export default AppFolders;

import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import isNull from 'lodash/isNull';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import Modal from '../../filestack/modal';
import { getAssetTypeFromMIME, formatDateTime } from '../../common/utils';
import Asset from '../../filestack/Asset';
import ReactFlowPlayerWrapper from '../../filestack/ReactFlowPlayerWrapper';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const RESET_ATTACHMENT_META = Object.freeze({
  fileSrc: '',
  fileType: '',
  filePoster: '',
});

const getDaysDiff = (startDate, endDate) =>
  moment(endDate).startOf('day').diff(moment(startDate).startOf('day'), 'days');

const MemberCertificationsGridItem = props => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [attachmentMeta, setAttachmentMeta] = React.useState(
    RESET_ATTACHMENT_META
  );

  const openModal = () => {
    const {
      certification: { attachments },
    } = props;

    const [firstAttachment] = attachments;
    setIsModalOpen(true);
    setAttachmentMeta({
      fileSrc: firstAttachment.url,
      fileType: getAssetTypeFromMIME(firstAttachment.mimetype),
      filePoster: firstAttachment.poster,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAttachmentMeta(RESET_ATTACHMENT_META);
  };

  const expirationMetaData = React.useMemo(() => {
    const getExpirationMetaData = () => {
      const { issuedDate, expirationDate } = props.certification;

      const todayDate = moment();
      const startDate = issuedDate || todayDate;
      const hasExpiry = !isNull(expirationDate);

      let expiryMetaData = {
        inDays: 0,
        inPercentage: 0,
        helperText: 'expired',
      };

      if (!hasExpiry) {
        expiryMetaData = {
          inPercentage: 100,
          inDays: 'NA',
          helperText: 'no expiration',
        };
      } else if (moment(expirationDate).isSameOrAfter(todayDate, 'day')) {
        let [totalActiveDays, daysLeftToExpireFromToday] = [
          getDaysDiff(startDate, expirationDate),
          getDaysDiff(todayDate, expirationDate),
        ];
        let helperText = 'days to expire';

        if (moment(expirationDate).isSame(todayDate, 'day')) {
          helperText = 'expires today';
        }

        expiryMetaData = {
          inPercentage: Math.round(
            (daysLeftToExpireFromToday / totalActiveDays) * 100
          ),
          inDays: daysLeftToExpireFromToday,
          helperText,
        };
      }

      return expiryMetaData;
    };
    return getExpirationMetaData();
  }, [props.certification]);

  // there is no need for id in this instance but the common handler needs the optional arguments
  // eslint-disable-next-line no-unused-vars
  const handleAttachmentMeta = (src, type, id = null, poster = '') => {
    setAttachmentMeta({
      fileSrc: src,
      fileType: type,
      filePoster: poster,
    });
  };

  const renderModalContent = () => {
    const { fileSrc, filePoster, fileType } = attachmentMeta;

    switch (fileType) {
      case 'image':
        return (
          <img
            src={fileSrc}
            style={{
              width: '100%',
              maxHeight: '400px',
            }}
          />
        );
      case 'video':
        return (
          <ReactFlowPlayerWrapper
            poster={filePoster}
            playerId="reactFlowPlayer"
            sources={[
              {
                type: 'video/mp4',
                src: fileSrc,
              },
            ]}
          />
        );
      default:
        return <h4>Unable to preview</h4>;
    }
  };

  const renderAttachmentPreview = attachment => {
    const isFileType = isEqual(
      getAssetTypeFromMIME(attachment.mimetype),
      'file'
    );
    let AttachmentParentElement = 'span';
    let attachmentParentProps = {};
    if (isFileType) {
      AttachmentParentElement = 'a';
      attachmentParentProps = {
        href: `${attachment.url}`,
        target: '_blank',
      };
    }

    return (
      <div
        style={{
          borderRadius: '4px',
          overflow: 'hidden',
          cursor: 'pointer',
        }}
      >
        <AttachmentParentElement {...attachmentParentProps}>
          <Asset
            className={'asset-preview'}
            mimetype={attachment.mimetype}
            url={attachment.url}
            openModal={handleAttachmentMeta}
          />
        </AttachmentParentElement>
      </div>
    );
  };

  const renderAttachmentsList = () => {
    const {
      certification: { attachments },
    } = props;

    const { fileSrc } = attachmentMeta;

    return (
      <div className="d-flex align-items-center flex-wrap">
        {map(attachments, attachment => (
          <span
            key={attachment.handle}
            className="m-3"
            style={{
              border: `2px solid ${
                isEqual(attachment.url, fileSrc) ? '#6f42c1' : 'transparent'
              }`,
              borderRadius: '4px',
              overflow: 'hidden',
            }}
          >
            {renderAttachmentPreview(attachment)}
          </span>
        ))}
      </div>
    );
  };

  const renderExpirationInfoPie = () => {
    const expiryMetaData = expirationMetaData;
    const progressRotationAngle = (expiryMetaData.inPercentage / 100) * 360;
    const isAboveFiftyPercent = expiryMetaData.inPercentage > 50;

    return (
      <div
        className={`pie-wrapper ${
          isAboveFiftyPercent ? 'progress-full' : 'progress-half'
        }`}
      >
        <span className="label">
          <em>{expiryMetaData.inDays}</em>
          <small
            className={
              isEqual(expiryMetaData.helperText.toLocaleLowerCase(), 'expired')
                ? 'text-darkred text-uppercase'
                : 'text-capitalize'
            }
          >
            {expiryMetaData.helperText}
          </small>
        </span>
        <div className="pie">
          <div
            className="left-side half-circle"
            style={{
              transform: `rotate(${
                isAboveFiftyPercent ? 180 : progressRotationAngle
              }deg)`,
            }}
          />
          <div
            className="right-side half-circle"
            style={{ transform: `rotate(${progressRotationAngle}deg)` }}
          />
        </div>
      </div>
    );
  };

  const renderCertificationDates = () => {
    const {
      certification: { issuedDate, expirationDate },
    } = props;

    return (
      <div className="kt-stack kt-stack--ver kt-stack--general kt-stack--demo">
        <div className="kt-stack__item kt-stack__item--center kt-stack__item--middle kt-stack__item--course-card">
          <h6 className="text-dark">
            {!isNull(issuedDate)
              ? formatDateTime({ date: issuedDate, formatTime: false })
              : '--'}
          </h6>
          Issued on
        </div>

        <div className="kt-stack__item kt-stack__item--center kt-stack__item--middle kt-stack__item--course-card">
          <h6 className="text-dark">
            {!isNull(expirationDate)
              ? formatDateTime({ date: expirationDate, formatTime: false })
              : 'No Expiry'}
          </h6>
          Expires on
        </div>
      </div>
    );
  };

  const {
    certification: { name, links, attachments },
    isTenantAdmin,
  } = props;

  return (
    <React.Fragment>
      <div className="card course-card">
        <div className="card-box project-box">
          <div className="row card-header--fixed-height">
            <h4 className="col-md-12 plr-10">
              <a href={links.show} className="project_name" title={name}>
                {name.slice(0, 24)}
                {name.length > 25 && '...'}
                <i className="fa fa-external-link-alt mx-2 font-12" />
              </a>
            </h4>
            <div>
              {!isEmpty(attachments) && (
                <span
                  onClick={openModal}
                  className="m-2 cursor-pointer"
                  title="View Attachments"
                >
                  View Attachments
                </span>
              )}
              {!isEmpty(attachments) && isTenantAdmin && <span>|</span>}
              {isTenantAdmin && (
                <a href={links.edit} title="Edit" className="m-2">
                  Edit
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="project_lower">
          {renderExpirationInfoPie()}
          {renderCertificationDates()}
        </div>
      </div>
      {isModalOpen && (
        <Modal
          title="Attachments"
          onClose={closeModal}
          renderContent={renderModalContent}
          renderFooter={renderAttachmentsList}
        />
      )}
    </React.Fragment>
  );
};

MemberCertificationsGridItem.propTypes = {
  certification: PropTypes.shape({
    attachments: PropTypes.array,
    name: PropTypes.string,
    issuedDate: PropTypes.any,
    expirationDate: PropTypes.any,
    links: PropTypes.shape({
      edit: PropTypes.string,
      show: PropTypes.string,
    }),
  }),
  isTenantAdmin: PropTypes.bool,
};

export default withErrorHandler(MemberCertificationsGridItem);

import { useEffect, useState, useCallback } from 'react';
import find from 'lodash/find';
import snakeCase from 'lodash/snakeCase';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

const TAB_QUERY_KEY = 'tab';

const useFetchQueryParams = availableTabs => {
  const [tab, setTab] = useState(null);

  const getQueryParamStatus = useCallback(() => {
    const queryParams = window.location.search;
    const [queryKey, queryValue] = !isEmpty(queryParams)
      ? queryParams.split('=')
      : [];

    if (isEqual(`?${TAB_QUERY_KEY}`, queryKey) && !isEmpty(queryValue)) {
      const lowerCasedQuery = queryValue.toLowerCase();

      // Check whether the tab is valid
      const queryTab = find(
        availableTabs,
        tab => snakeCase(tab.key) === lowerCasedQuery
      );

      // Switch to tab, If tab present is valid
      if (queryTab) {
        setTab(queryTab);
        return;
      }
    }

    // if query param is invalid, remove it from url
    if (window.history.replaceState) {
      setTab(availableTabs[0]);
      window.history.replaceState('', '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    getQueryParamStatus();
  }, [getQueryParamStatus]);

  useEffect(() => {
    if (tab) {
      let queryString = '';
      queryString = `?${TAB_QUERY_KEY}=${snakeCase(tab.key)}`;

      if (window.history.pushState) {
        window.history.pushState(
          '',
          '',
          `${window.location.pathname}${queryString}`
        );
      }
    }
  }, [tab]);

  return [tab, setTab, getQueryParamStatus];
};

export default useFetchQueryParams;

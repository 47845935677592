import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { SortableCourseTableBody } from './SortableCourseTableBody';
import { NormalCourseTableBody } from './NormalCourseTableBody';
import Http from '../../common/Http';
import Spinner from '../../common/presentational/spinner';
import { reorder } from '../../common/utils';
import {
  alertSuccessNotifications,
  alertErrorNotifications,
  clearAlertNotifications,
} from '../../folders/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const CourseTable = ({
  courses,
  csrfToken,
  links,
  afterSortSuccess,
  tenantTerms,
  sortable,
  curriculumCloneConfig,
}) => {
  const allCourses = useMemo(() => [...courses], [courses]);
  const [loading, setLoading] = useState(false);

  const toggleLoader = () => setLoading(prevState => !prevState);

  const handlePatchRequest = url => {
    new Http()
      .onBegin(() => setLoading(true))
      .setToken(csrfToken)
      .doesRedirect(true)
      .patch(url)
      .useAlerts()
      .onSuccess(response => {
        setLoading(false);
        window.location = response.data.redirection_url || window.location.href;
      })
      .onError(() => setLoading(false))
      .exec();
  };

  const handleArchiveRequest = url => () => {
    const proceed = confirm('Are you sure, you want to archive this?');
    if (proceed) {
      handlePatchRequest(url);
    }
  };

  const handleUnArchiveRequest = url => () => {
    const proceed = confirm('Are you sure, you want to Unarchive this?');
    if (proceed) {
      handlePatchRequest(url);
    }
  };

  const handlePublishedStatus = url => async event => {
    event.preventDefault();
    handlePatchRequest(url);
  };

  const onDragEnd = async result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedCourses = reorder(
      [...allCourses],
      result.source.index,
      result.destination.index
    );

    clearAlertNotifications();

    const courseIds = map(sortedCourses, 'id');

    await new Http()
      .setToken(csrfToken)
      .onBegin(() => setLoading(true))
      .post(links.changeOrder)
      .setPostData({
        course_ids: courseIds,
      })
      .onSuccess(() => {
        afterSortSuccess(sortedCourses);
        alertSuccessNotifications('Order Updated Successfully!');
        setLoading(false);
      })
      .onError(() => {
        alertErrorNotifications('Unable to reorder at this moment!');
        setLoading(false);
      })
      .exec();
  };

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body px-0">
        <div className="table-responsive">
          {loading && <Spinner />}
          <table className="table kt-table__row-equal-width">
            <thead>
              <tr>
                <th className="text-center td-50"></th>
                <th>Name</th>
                <th className="td-110 text-center">&nbsp;</th>
              </tr>
            </thead>

            {!isEmpty(allCourses) &&
              (sortable ? (
                <SortableCourseTableBody
                  courses={allCourses}
                  onDragEnd={onDragEnd}
                  csrfToken={csrfToken}
                  toggleLoader={toggleLoader}
                  tenantTerms={tenantTerms}
                  handlePublishedStatus={handlePublishedStatus}
                  handleArchiveRequest={handleArchiveRequest}
                  handleUnArchiveRequest={handleUnArchiveRequest}
                  curriculumCloneConfig={curriculumCloneConfig}
                />
              ) : (
                <NormalCourseTableBody
                  courses={allCourses}
                  csrfToken={csrfToken}
                  toggleLoader={toggleLoader}
                  tenantTerms={tenantTerms}
                  handlePublishedStatus={handlePublishedStatus}
                  handleArchiveRequest={handleArchiveRequest}
                  handleUnArchiveRequest={handleUnArchiveRequest}
                  curriculumCloneConfig={curriculumCloneConfig}
                />
              ))}

            {isEmpty(allCourses) && (
              <tbody>
                <tr>
                  <td colSpan="6" className="p-4 text-center">
                    {`There are no ${tenantTerms.termCourse.plural} at this time.`}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

CourseTable.defaultProps = {
  courses: [],
  sortable: false,
  links: {},
};

CourseTable.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  tenantTerms: PropTypes.shape({
    termCourse: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }),
  courses: PropTypes.array,
  links: PropTypes.shape({
    changeOrder: PropTypes.string,
    newCourse: PropTypes.string,
  }),
  afterSortSuccess: PropTypes.func,
  sortable: PropTypes.bool,
  curriculumCloneConfig: PropTypes.shape({
    currentCurriculum: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    remainingCloneTargetCurriculums: PropTypes.array,
  }),
};

export default withErrorHandler(CourseTable);

import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';

import InitialsAvatar from '../../common/Avatar';
import ReviewRating from './ReviewRating';
import { withErrorHandler } from '../../hoc/withErrorHandler';
import { getReadableTimeDiffFromNowUntilAWeek } from '../../common/utils';
import { SENTIMENT_COLOR_MAP } from '../../common/constants';
import { getReviewSourceLabel } from './utils';
import './reviewStyles.scss';

const ReviewItem = ({
  review: { permalink, body, reviewedAt, sentiment, rating, type, reply },
  reviewer,
  project,
  replyConfig: { canManage, links },
  csrfToken,
  filestackAttachmentConfig,
}) => {
  let ProfileNameElement = 'span';
  let profileNameElementProps = {};
  if (!isEmpty(reviewer.profileUrl)) {
    ProfileNameElement = 'a';
    profileNameElementProps = {
      href: reviewer.profileUrl,
      target: '_blank',
      rel: 'noreferrer noopener',
    };
  }

  const renderEmotions = () => {
    const sortedEmotions = map(sentiment.emotions, (score, emotion) => [
      emotion,
      score,
    ]).sort((current, next) => next[1] - current[1]);

    return (
      <span className="comment-emotions">
        {map(sortedEmotions, ([emotion, score]) => (
          <span className="emotion-wrapper" key={emotion}>
            <span className="emotion-name">{capitalize(emotion)}</span>
            <span className="emotion-bar">
              <span
                style={{
                  width: `${score * 100}%`,
                }}
              ></span>
            </span>
            {Number.parseFloat(score).toFixed(2)}
          </span>
        ))}
      </span>
    );
  };

  const reviewSource = getReviewSourceLabel(type);

  const renderPermaLink = () => {
    if (isEmpty(reviewSource)) return null;

    return (
      <a
        href={permalink}
        target="_blank"
        rel="noreferrer noopener"
        className="kt-link d-inline-block"
      >
        <span>
          <i className="fa fa-external-link-alt mr-2" />
          {`View on ${capitalize(reviewSource)}`}
        </span>
      </a>
    );
  };

  return (
    <div>
      <div className="kt-portlet__body">
        <div className="user-post__header align-items-start pt-3">
          <InitialsAvatar name={reviewer.name} url={reviewer.imageUrl} />
          <div className="user-post__info-main d-flex justify-content-between align-items-start w-100">
            <div className="user-post__info">
              <div className="user-data d-flex">
                <ProfileNameElement
                  className="user-name"
                  {...profileNameElementProps}
                >
                  {reviewer.name || 'Anonymous'}
                </ProfileNameElement>
                {!isNil(sentiment) && !isEmpty(sentiment.label) && (
                  <span
                    className={`d-inline-block mx-2 font-12 kt-font-${
                      SENTIMENT_COLOR_MAP[sentiment.label.toLowerCase()]
                    }`}
                  >
                    <i className="fa fa-circle mr-2 font-10" />
                    {sentiment.label}
                  </span>
                )}
              </div>
              <span className="date">
                <span>{getReadableTimeDiffFromNowUntilAWeek(reviewedAt)}</span>
              </span>
              {!isNil(rating) && <ReviewRating rating={rating} />}
            </div>
            <span className="btn btn-sm btn-bold btn-label-primary my-2">
              {project.name}
            </span>
          </div>
        </div>
        {!isNil(sentiment) && !isNil(sentiment.emotions) && (
          <div className="mb-2">{renderEmotions()}</div>
        )}
        <p>{body}</p>
      </div>
    </div>
  );
};

ReviewItem.propTypes = {
  review: PropTypes.shape({
    id: PropTypes.number.isRequired,
    body: PropTypes.string.isRequired,
    reviewedAt: PropTypes.string.isRequired,
    rating: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sentiment: PropTypes.shape({
      emotions: PropTypes.object,
      label: PropTypes.string,
    }),
    reply: PropTypes.shape({
      name: PropTypes.string,
      repliedAt: PropTypes.string,
    }),
    type: PropTypes.string,
    permalink: PropTypes.string,
  }).isRequired,
  reviewer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    profileUrl: PropTypes.string,
  }).isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  replyConfig: PropTypes.shape({
    canManage: PropTypes.bool.isRequired,
    links: PropTypes.shape({
      upsertReply: PropTypes.string,
    }),
  }).isRequired,
  csrfToken: PropTypes.string.isRequired,
  comments: PropTypes.array,
  links: PropTypes.object,
  filestackAttachmentConfig: PropTypes.object,
};

export default withErrorHandler(ReviewItem);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import Http from '../../common/Http';
import { getReadableTimeDiffFromNowUntilAWeek } from '../../common/utils';
import InitialsAvatar from '../../common/Avatar';
import PreviewAttachments from '../../common/AttachmentPreview/Attachments';
import ReplyForm from './ReplyForm';

import useRepliesContext from '../hooks/useRepliesContext';

const canHaveReply = lvl => lvl < 3;

const Reply = ({
  replies: allReplies,
  respondents: allRespondents,
  level,
  csrfToken,
  currentUserId,
  replyAttachmentsConfig,
  handleTogglePin,
  isCourseAdmin,
}) => {
  const [replies, setReplies] = React.useState(allReplies);
  const [respondents, setRespondents] = React.useState(allRespondents);
  const [editReplyId, setEditReplyId] = React.useState('');
  const [, setEditReplyValue] = React.useState('');
  const [isEditFormLoading, setIsEditFormLoading] = React.useState(false);

  const { reloadReplies } = useRepliesContext();

  useEffect(() => {
    setReplies(allReplies);
  }, [allReplies]);

  useEffect(() => {
    setRespondents(allRespondents);
  }, [allRespondents]);

  const onSubmitReply = async (formData, links, parentId) => {
    await new Http()
      .setToken(csrfToken)
      .useAlerts()
      .doesRedirect(false)
      .post(links.postReply)
      .setPostData({
        body: formData.body,
        attachments: formData.attachments,
      })
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { reply } }) => {
        setReplies(prevReplies => {
          return reduce(
            prevReplies,
            (acc, rep) => {
              let otherReply = rep;

              if (rep.id === parentId) {
                otherReply = {
                  ...rep,
                  replies: [...rep.replies, reply],
                };
              }

              return [...acc, otherReply];
            },
            []
          );
        });
        setRespondents(prevRespondents => {
          return {
            ...prevRespondents,
            [reply.respondent.id]: reply.respondent,
          };
        });
        reloadReplies();
        return true;
      })
      .onError(() => {
        return false;
      })
      .exec();
  };

  const editFormSubmitHandle = async (links, formData) => {
    await new Http()
      .setToken(csrfToken)
      .onBegin(() => {
        setIsEditFormLoading(true);
      })
      .useAlerts()
      .doesRedirect(false)
      .put(links.update)
      .setPostData(formData)
      .useAPIDataFormatters({
        snakifyRequestData: true,
        camelizeResponseData: true,
      })
      .onSuccess(({ data: { reply } }) => {
        setReplies(prevReplies => {
          return prevReplies.map(preReply =>
            preReply.id === reply.id ? reply : preReply
          );
        });
        setEditReplyId('');
        setEditReplyValue('');
        setIsEditFormLoading(false);
      })
      .onError(() => {
        setIsEditFormLoading(false);
      })
      .exec();
  };

  const deleteReplyHandle = (links, replyId) => async () => {
    const proceed = confirm('Are you sure, you want to delete?');
    if (proceed) {
      await new Http()
        .setToken(csrfToken)
        .useAlerts()
        .doesRedirect(false)
        .delete(links.update)
        .onSuccess(() => {
          setReplies(prevReplies => prevReplies.filter(r => r.id !== replyId));
          reloadReplies();
          return true;
        })
        .onError(() => {
          alert('Unable to delete reply. Please try again later');
          return false;
        })
        .exec();
    }
  };

  const renderReply = reply => {
    const respondent = respondents[reply.respondentId] || {};
    const isCurrentReplyEditable = reply.respondentId === currentUserId;
    const { name = '', avatar = '' } = respondent;
    const readablePostedAt = getReadableTimeDiffFromNowUntilAWeek(
      reply.createdAt
    );

    return (
      <div key={reply.id} className="reply-section mt-2">
        <div className="d-flex profile-info">
          <InitialsAvatar
            url={avatar}
            className="profile-info-image"
            name={name}
            size="medium"
          />
          <div className="flex-1 text-truncate ml-2 w-100">
            <div className="font-14 topic-info-section">
              <div className="info text-truncate w-100">
                <div className="text-dark-50">
                  <span className="font-weight-bold">{name || '-'}</span>{' '}
                  replied {readablePostedAt}
                </div>

                <div className="description font-14 pb-2">
                  {editReplyId === reply.id ? (
                    <ReplyForm
                      onSubmit={formData =>
                        editFormSubmitHandle(reply.links, formData)
                      }
                      onChange={({ target: { value } }) =>
                        setEditReplyValue(value)
                      }
                      values={{
                        body: reply.body,
                        attachments: reply.attachments,
                      }}
                      loading={isEditFormLoading}
                      onCancel={() => {
                        setEditReplyId('');
                        setEditReplyValue(reply.body);
                      }}
                      csrfToken={csrfToken}
                      replyAttachmentsConfig={replyAttachmentsConfig}
                      isFocused
                    />
                  ) : (
                    <div>
                      <span className="description mt-1">{reply.body}</span>
                      {!isEmpty(reply.attachments) && (
                        <div className="mt-2">
                          <PreviewAttachments attachments={reply.attachments} />
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="d-flex">
                  {
                    isCourseAdmin
                      ?
                        (
                          <span
                            onClick={() => handleTogglePin(reply)}
                            className={`text-dark-50 mr-2 cursor-pointer ${reply.pinnedAt ? 'text-primary' : 'text-dark-50'}`}
                          >
                            <i className={`fas fa-thumbtack mx-2 cursor-pointer pin-icon ${reply.pinnedAt ? 'text-primary' : ''}`} />
                            {reply.pinnedAt ? 'unpin' : 'pin'}
                          </span>
                        )
                      : null
                  }

                  {isCurrentReplyEditable && editReplyId !== reply.id && (
                    <span
                      onClick={() => {
                        setEditReplyId(reply.id);
                        setEditReplyValue(reply.body);
                      }}
                      className="text-dark-50 mx-2 cursor-pointer"
                    >
                      <i className="fas fa-pencil-alt mr-1 edit-icon"></i>
                      edit
                    </span>
                  )}
                  {canHaveReply(level) && (
                    <>
                      {reply.deletable && (
                        <span
                          className="cursor-pointer mx-2 text-darkred"
                          onClick={deleteReplyHandle(reply.links, reply.id)}
                        >
                          <i className="fas fa-trash-alt mr-1"></i>
                          delete
                        </span>
                      )}
                      <ReplyBox
                        onSubmit={onSubmitReply}
                        links={reply.links}
                        id={reply.id}
                        csrfToken={csrfToken}
                        replyAttachmentsConfig={replyAttachmentsConfig}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isEmpty(reply.replies) && (
          <Reply
            replies={reply.replies}
            respondents={respondents}
            level={level + 1}
            csrfToken={csrfToken}
            currentUserId={currentUserId}
            replyAttachmentsConfig={replyAttachmentsConfig}
          />
        )}
      </div>
    );
  };

  return <div className="pl-5 pt-2 reply">{replies.map(renderReply)}</div>;
};

const ReplyBox = ({
  onSubmit,
  id,
  links,
  csrfToken,
  replyAttachmentsConfig,
}) => {
  const [isCommentBoxOpen, setIsCommentBoxOpen] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const submitHandle = formData => {
    setIsFormLoading(true);
    const res = onSubmit(formData, links, id);
    if (res) {
      setIsCommentBoxOpen(false);
    }
    setIsFormLoading(false);
  };

  const toggleCommentBoxView = () => {
    setIsCommentBoxOpen(view => !view);
  };

  return (
    <div className="forum-reply w-100">
      <span
        className="cursor-pointer mx-2 -dark-50"
        onClick={toggleCommentBoxView}
      >
        <i className="fas fa-reply font-11 mr-1"></i>
        reply
      </span>
      {isCommentBoxOpen && (
        <ReplyForm
          onSubmit={submitHandle}
          replyAttachmentsConfig={replyAttachmentsConfig}
          csrfToken={csrfToken}
          loading={isFormLoading}
          isFocused
          onCancel={toggleCommentBoxView}
        />
      )}
    </div>
  );
};

Reply.propTypes = {
  replies: PropTypes.array,
  respondents: PropTypes.object,
  level: PropTypes.number,
  csrfToken: PropTypes.string,
  currentUserId: PropTypes.number,
  replyAttachmentsConfig: PropTypes.object,
};

ReplyBox.propTypes = {
  onSubmit: PropTypes.func,
  links: PropTypes.shape({
    update: PropTypes.string,
    postReply: PropTypes.string,
  }),
  id: PropTypes.number,
  csrfToken: PropTypes.string,
  replyAttachmentsConfig: PropTypes.object,
  isCourseAdmin: PropTypes.bool,
};

export default React.memo(Reply);

import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import MultiSelect from '../common/inputs/multiSelect';
import { withErrorHandler } from '../hoc/withErrorHandler';
import FormRow from '../common/presentational/formRow';
import FormElementHelperText from '../common/presentational/FormElementHelperText';
import { convertArrayOfObjectsToHash } from '../common/utils';

const ParticipantSelectWithList = ({
  onChange,
  participantList,
  selectedUsers: usersSelected,
  participantLabel,
  participantListLabel,
  name,
  optionLabel,
  optionValue,
  showHeaderText,
  headerText,
  optionIdentifier,
  mapUserIdWithList,
  showList,
  helperText = "",
  requiredField = false,
}) => {
  const [selectedUsers, setSelectedUsers] = useState(usersSelected);
  let users = participantList;

  useEffect(() => {
    setSelectedUsers(usersSelected);
  }, [usersSelected]);

  if (mapUserIdWithList) {
    users = convertArrayOfObjectsToHash(participantList, 'id');
  }

  const onSelectChange = (participants) => {
    const participantIds = participants.map(({ id }) => id);
    setSelectedUsers(participantIds);
    onChange(participantIds);
  };

  const onRemoveValue = (userId) => {
    let newUsers;
    if (mapUserIdWithList) {
      newUsers = selectedUsers.filter((user) => user !== userId);
    } else {
      newUsers = selectedUsers.filter((user) => user.id !== userId);
    }
    setSelectedUsers(newUsers);
    onChange(newUsers);
  };

  return (
    <div>
      {showHeaderText && <h2 className="mb-3">{headerText}</h2>}
      <FormRow label={participantLabel} className={requiredField ? 'required-setting' : ''}>
        <MultiSelect
          name={name}
          getOptionLabel={(option) => option[optionLabel]}
          getOptionValue={(option) => option[optionValue]}
          options={participantList}
          value={selectedUsers}
          onChange={onSelectChange}
          optionIdentifier={optionIdentifier}
        />
        {helperText && <FormElementHelperText text={helperText} />}
      </FormRow>

      {showList && !!selectedUsers.length && (
        <FormRow label={participantListLabel}  className={`rounded ${requiredField ? 'required-setting' : ''}`}>
          {selectedUsers.map((user, idx) => {
            const bg = idx % 2 ? ' bg-transparent' : ' bg-secondary';
            const participant = mapUserIdWithList ? users[user] : user;

            return (
              <div
                className={`p-3 d-flex justify-content-between rounded align-items-center${bg}`}
                key={participant.id}
              >
                <div className="text-truncate">{participant.name}</div>
                <div
                  className="btn btn-sm btn-darkred"
                  onClick={() => onRemoveValue(participant.id)}
                >
                  <i className="fas fa-trash-alt"></i> Remove
                </div>
              </div>
            );
          })}
        </FormRow>
      )}
    </div>
  );
};

ParticipantSelectWithList.propTypes = {
  onChange: PropTypes.func.isRequired,
  participantList: PropTypes.array.isRequired,
  selectedUsers: PropTypes.array,
  participantLabel: PropTypes.string,
  participantListLabel: PropTypes.string,
  name: PropTypes.string,
  optionLabel: PropTypes.string,
  optionValue: PropTypes.string,
  optionIdentifier: PropTypes.string,
  showHeaderText: PropTypes.bool,
  mapUserIdWithList: PropTypes.bool,
  requiredField: PropTypes.bool,
};

ParticipantSelectWithList.defaultProps = {
  name: 'Participants',
  selectedUsers: [],
  optionLabel: 'name',
  optionValue: 'id',
  optionIdentifier: 'id',
  participantLabel: 'Participants',
  headerText: 'Participants',
  participantListLabel: 'Enrolled Participants',
  showHeaderText: true,
  mapUserIdWithList: false,
  showList: true,
  requiredField: false,
};

export default withErrorHandler(memo(ParticipantSelectWithList));

import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import keys from 'lodash/keys';
import uniq from 'lodash/uniq';
import includes from 'lodash/includes';
import isUndefined from 'lodash/isUndefined';
import flatten from 'lodash/flatten';

import { formatDateTime } from '../../common/utils';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const FeedbackSummaryGroupedResponsesTable = ({
  groupedResponseElements,
  metaData,
  projectsEnabled,
  tenantTerms: { termProject },
}) => {
  const RECORD_GROUPABLE_ELEMENTS = Object.freeze({
    name: 'NameTextInput',
    email: 'EmailTextInput',
    phone: 'CustomPhoneInput',
    date: 'DatePicker',
    time: 'Time',
    dateTime: 'DateTime',
    ...(projectsEnabled && { project: 'CustomProjectDropdown' }),
  });

  const getAvailableFormElements = () => {
    let availableFormElements = [];
    map(groupedResponseElements, element => {
      availableFormElements = [...availableFormElements, element['element']];
    });
    return availableFormElements;
  };

  const generateResponsesTableData = () => {
    let responseElements = {};
    let responseIds = [];

    map(groupedResponseElements, element => {
      responseElements = {
        ...responseElements,
        [element['element']]: element['responses'],
      };
      responseIds = [...responseIds, keys(element['responses'])];
    });

    const getResponseValue = (id, attribute) => {
      const element = RECORD_GROUPABLE_ELEMENTS[attribute];
      let responseValue = '-';

      if (!isUndefined(responseElements[element])) {
        responseValue = !isUndefined(responseElements[element][id])
          ? responseElements[element][id]
          : '-';
      }
      return responseValue;
    };

    const uniqResponseIds = uniq(flatten(responseIds));

    let groupedResponseTableData = {};
    map(uniqResponseIds, id => {
      const nameResponse = getResponseValue(id, 'name');
      const emailResponse = getResponseValue(id, 'email');
      const projectResponse = getResponseValue(id, 'project');
      const phoneResponse = getResponseValue(id, 'phone');
      const dateResponse = getResponseValue(id, 'date');
      const timeResponse = getResponseValue(id, 'time');
      const dateTimeResponse = getResponseValue(id, 'dateTime');
      groupedResponseTableData = {
        ...groupedResponseTableData,
        [id]: {
          name: nameResponse,
          email: emailResponse,
          project: projectResponse,
          phone: phoneResponse,
          date: formatDateTime({ date: dateResponse, formatTime: false }),
          time: formatDateTime({ date: timeResponse, timeOnly: true }),
          dateTime: formatDateTime({ date: dateTimeResponse }),
          links: metaData?.['user_response_links']?.[id],
        },
      };
    });

    return groupedResponseTableData;
  };

  const renderTable = () => {
    const groupedResponseTableData = generateResponsesTableData();
    const formElements = getAvailableFormElements();
    const groupedElementsTableHeaders = Object.freeze({
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      date: 'Date',
      time: 'Time',
      dateTime: 'Date and Time',
      ...(projectsEnabled && { project: `${termProject.singular}` }),
    });

    return (
      <div className="table-responsive">
        <table className="table table-striped kt-table__row-equal-width">
          <thead>
            <tr style={{ height: 42 }}>
              {map(groupedElementsTableHeaders, (elementValue, elementKey) => {
                return includes(
                  formElements,
                  RECORD_GROUPABLE_ELEMENTS[elementKey]
                ) ? (
                  <th key={`${elementKey}-${elementValue}`}>
                    <span className="pl-15">{elementValue}</span>
                  </th>
                ) : null;
              })}
              <th className="td-80">
                <span>&nbsp;</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {map(groupedResponseTableData, (responseValue, responseKey) => (
              <tr key={responseKey}>
                {map(RECORD_GROUPABLE_ELEMENTS, (elementValue, elementKey) => {
                  return includes(formElements, elementValue) ? (
                    <td key={elementKey}>
                      <span className="pl-15">{responseValue[elementKey]}</span>
                    </td>
                  ) : null;
                })}
                <td className="td-80">
                  <a
                    className="btn btn-outline-brand btn-sm"
                    href={responseValue.links.show}
                  >
                    <i className="la la-eye" />
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body">
        <div className="kt-section mb-20">{renderTable()}</div>
      </div>
    </div>
  );
};

FeedbackSummaryGroupedResponsesTable.propTypes = {
  groupedResponseElements: PropTypes.arrayOf(
    PropTypes.shape({
      element: PropTypes.string.isRequired,
      meta: PropTypes.shape({
        answered: PropTypes.number.isRequired,
        skipped: PropTypes.number.isRequired,
      }).isRequired,
      question: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
      }).isRequired,
      responses: PropTypes.oneOfType([PropTypes.object]),
    })
  ),
  metaData: PropTypes.shape({
    user_response_links: PropTypes.oneOfType([PropTypes.object]),
  }),
  projectsEnabled: PropTypes.bool.isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string,
      plural: PropTypes.string,
    }),
  }).isRequired,
};

export default withErrorHandler(FeedbackSummaryGroupedResponsesTable);

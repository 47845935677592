import React from 'react';
import PropTypes from 'prop-types';

import FolderActions from '../../FolderActions';

const FolderListRow = ({
  folder,
  urlProvider,
  updateEnablerPredicate,
  onRenameClick: renameHandler,
  onShareClick: shareHandler,
}) => {
  const { id: folderId, name: folderName } = folder;

  return (
    <li className="folder-list-li">
      <div className="row">
        <div className="col-9">
          <a href={urlProvider(folderId)} className="text-truncate" title={folderName}>
            <i className="fa fa-folder" />
            {folderName}
          </a>
        </div>
        {updateEnablerPredicate(folderId) && (
          <div className="col-3">
            <FolderActions
              dropdownLinkType="flat"
              onRenameClick={() => {
                renameHandler(folderId);
              }}
              onShareClick={() => {
                shareHandler(folderId);
              }}
            />
          </div>
        )}
      </div>
    </li>
  );
};

FolderListRow.propTypes = {
  folder: PropTypes.object.isRequired,
  urlProvider: PropTypes.func.isRequired,
  updateEnablerPredicate: PropTypes.func.isRequired,
  onRenameClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
};

export default FolderListRow;

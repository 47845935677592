import React from 'react';
import PropTypes from 'prop-types';

import useAdminTabContext from '../hooks/useAdminTabContext';
import { isValidChildren } from '../../../common/utils';

const TabContent = ({ children }) => {
  const { activeTab } = useAdminTabContext();
  const renderChildren =
    activeTab &&
    children.filter(tab => {
      if (isValidChildren(tab)) {
        return tab.props.id === activeTab.key;
      }
      return null;
    });
  return <div className="tab-content">{renderChildren}</div>;
};

TabContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default TabContent;

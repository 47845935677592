import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({
  inputType,
  className,
  placeholder,
  value,
  name,
  disabled,
  onInputChange,
  onFocus,
  onBlur,
  numberAttributes,
  style,
}) => (
  <input
    type={inputType}
    className={className}
    placeholder={placeholder}
    value={value}
    name={name}
    onChange={onInputChange}
    disabled={disabled}
    onFocus={onFocus}
    onBlur={onBlur}
    style={style}
    {...numberAttributes}
  />
);

TextInput.propTypes = {
  inputType: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  numberAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

TextInput.defaultProps = {
  inputType: 'text',
  className: 'form-control',
  value: '',
  placeholder: '',
  numberAttributes: {},
  disabled: false,
  style: {},
};

export default TextInput;

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import keys from 'lodash/keys';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import find from 'lodash/find';
import toLower from 'lodash/toLower';

import ProjectGridItem from './ProjectGridItem';
import DropdownButton from '../../common/presentational/dropDownButton';
import { projectSortListOptions, sortProjects } from '../utils';
import { TransitionContainer, Transition } from '../../common/animations';
import { withErrorHandler } from '../../hoc/withErrorHandler';

const ProjectGrid = ({
  projectList: allProjectsList,
  viewAll,
  groupProjectsByCategory,
  tenantTerms,
  renderHeaderTools,
  links: { projectsUrl },
}) => {
  const [projectFilterText, setProjectFilterText] = useState(null);
  const [currentSortBy, setCurrentSortBy] = useState(null);
  const sortedList = React.useMemo(
    () => (groupProjectsByCategory ? allProjectsList : null),
    [allProjectsList, groupProjectsByCategory]
  );
  const categoriesList = React.useMemo(
    () => (groupProjectsByCategory ? keys(allProjectsList) : null),
    [allProjectsList, groupProjectsByCategory]
  );

  const { termProject } = tenantTerms;

  const onInputChange = ({ target }) => {
    if (!isNil(target.name)) {
      setProjectFilterText(target.value);
    }
  };

  const handleSortBy = event => {
    if (event && event.target) {
      setCurrentSortBy(event.target.value);
    }
  };

  const sortedProjectList = React.useCallback(
    categoryName => {
      let currentCatProjectList = sortedList[categoryName];

      return sortProjects(currentSortBy, currentCatProjectList);
    },
    [currentSortBy, sortedList]
  );

  const filterProjectListBySearchText = (projectList, filterText) =>
    filter(projectList, project =>
      project.name.toLowerCase().includes(filterText.toLowerCase())
    );

  const renderEmptyView = () => {
    return (
      <Transition classNames="slide-up">
        <div className={'col-md-12'}>
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <h4 className="text-center">No {termProject.plural}.</h4>
            </div>
          </div>
        </div>
      </Transition>
    );
  };

  const renderProjectsGrid = projectList => {
    return map(projectList, project => (
      <Transition key={`${projectFilterText}-${project.id}`} timeout={500}>
        <div className="col-md-6 col-lg-6 col-xl-4 plr-10">
          <ProjectGridItem project={project} tenantTerms={tenantTerms} />
        </div>
      </Transition>
    ));
  };

  const renderCategoryGroupedProjects = () => {
    const catHeaderList = categoriesList;
    if (!isEmpty(catHeaderList)) {
      catHeaderList.sort().reverse();
    }

    const hasOnlyNaProjects =
      isEqual(catHeaderList.length, 1) &&
      isEqual(catHeaderList[0].toLowerCase(), 'na');

    if (!isNil(catHeaderList) && !isNil(sortedList)) {
      return map(catHeaderList, catName => {
        let sortedProjectsList = sortedProjectList(catName);

        if (!isNil(projectFilterText) && !isEmpty(projectFilterText)) {
          sortedProjectsList = filterProjectListBySearchText(
            sortedProjectsList,
            projectFilterText
          );
        }

        const showCategorySectionHeader =
          !isEmpty(sortedProjectsList) ||
          !isEqual(catName.toLowerCase(), 'na') ||
          hasOnlyNaProjects;

        return (
          <div key={catName} className="project-group col-md-12">
            {showCategorySectionHeader && (
              <h3 className="font-18 text-capitalize mb-20 mt-10">
                {!isEqual(toLower(catName), 'na') ? catName : null}
              </h3>
            )}
            <TransitionContainer className="row">
              {!isEmpty(sortedProjectsList)
                ? renderProjectsGrid(sortedProjectsList)
                : !isEqual(catName.toLowerCase(), 'na') || hasOnlyNaProjects
                ? renderEmptyView()
                : null}
            </TransitionContainer>
            <div className="kt-separator kt-separator--dashed kt-separator--sm" />
          </div>
        );
      });
    } else {
      return renderEmptyView();
    }
  };

  let projectList = allProjectsList;
  if (!groupProjectsByCategory) {
    projectList = sortProjects(currentSortBy, allProjectsList);

    if (!isNil(projectFilterText) && !isEmpty(projectFilterText)) {
      projectList = filterProjectListBySearchText(
        projectList,
        projectFilterText
      );
    } else {
      if (!viewAll) {
        projectList = projectList.slice(
          Math.max(projectList.length - 9, 0),
          projectList.length
        );
      }
    }
  }

  return (
    <div className="p-5">
      <React.Fragment>
        <div className="kt-content course-header--responsive w-100 pt-20">
          <div className="m-2">
            <h3>My {termProject.plural}</h3>
          </div>
          <div style={{ minWidth: 300 }} className="m-2">
            <div className="form-group kt-form__group">
              <input
                style={{
                  height: 40,
                }}
                onChange={onInputChange}
                type="text"
                title="Search by name"
                className="form-control"
                placeholder="Search by Name"
              />
              {viewAll && (
                <DropdownButton
                  className="pt-10 pb-10"
                  // dropDown title is label of currentSortBy item from projectSortListOptions array
                  dropdownTitle={
                    currentSortBy !== null
                      ? find(
                          projectSortListOptions,
                          ({ value }) => value === currentSortBy
                        )['label']
                      : 'Sort by'
                  }
                  onClick={handleSortBy}
                  dropdownValues={projectSortListOptions}
                />
              )}
            </div>
          </div>
          {!isNil(renderHeaderTools) && (
            <div className="d-flex justify-content-end">
              {renderHeaderTools()}
            </div>
          )}
        </div>
      </React.Fragment>
      <div className="kt-content">
        <div className="row">
          {groupProjectsByCategory ? (
            <div className="category-grouped-projects w-100">
              {renderCategoryGroupedProjects()}
            </div>
          ) : (
            renderProjectsGrid(projectList)
          )}

          {isEmpty(projectList) && renderEmptyView()}

          <div className="col-md-12">
            <br />
            {!viewAll && allProjectsList.length > projectList.length && (
              <h3 className="text-center">
                <a
                  className="kt-link"
                  href={projectsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  View All &rarr;
                </a>
              </h3>
            )}
            <div className="kt-separator kt-separator--dashed kt-separator--sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectGrid.propTypes = {
  viewAll: PropTypes.bool,
  groupProjectsByCategory: PropTypes.bool,
  projectList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    .isRequired,
  tenantTerms: PropTypes.shape({
    termProject: PropTypes.shape({
      singular: PropTypes.string.isRequired,
      plural: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  links: PropTypes.shape({
    projectsUrl: PropTypes.string.isRequired,
  }).isRequired,
  renderHeaderTools: PropTypes.func,
};

ProjectGrid.defaultProps = {
  viewAll: false,
  groupProjectsByCategory: false,
};

export default withErrorHandler(ProjectGrid);

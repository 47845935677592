import React from 'react';
import PropTypes from 'prop-types';

const AppFolderList = ({ folderName, folderUrl }) => (
  <li>
    <a
      href={folderUrl}
    >
      <i className="fa fa-folder"></i>
      {
        folderName
      }
    </a>
  </li>
);

AppFolderList.propTypes = {
  folderName: PropTypes.string.isRequired,
  folderUrl: PropTypes.string.isRequired,
};

export default AppFolderList;

// helper functions for project
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import values from 'lodash/values';
import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import { constructMultiSelectOptions } from '../common/utils';

export const projectSortListOptions = [
  {
    label: 'Name Asc.',
    key: 1,
    value: 'Name',
  },
  {
    label: 'Date Created Asc.',
    key: 2,
    value: 'DateCreatedAsc',
  },
  {
    label: 'Date Created Desc.',
    key: 3,
    value: 'DateCreatedDesc',
  },
];

export const sortProjects = (currentSortBy, projectList) => {
  let sortedProjectList = projectList;

  if (isNil(currentSortBy)) {
    return sortedProjectList;
  } else {
    // CreatedAt is receiving with two keys, {createdAt or created_at}
    // To get the correct key, first object of list is taken and extracted the key.
    let createdAtKey = 'createdAt';
    const projectObject = !isEmpty(sortedProjectList)
      ? sortedProjectList[0]
      : {};
    if (!('createdAt' in projectObject)) {
      createdAtKey = 'created_at';
    }
    switch (currentSortBy) {
      case 'Name':
        return sortBy(sortedProjectList, [
          project => {
            if (!isNil(project.name)) {
              return project.name.toLowerCase();
            } else return project.name;
          },
          'id',
        ]);
      case 'DateCreatedAsc':
        return orderBy(sortedProjectList, [createdAtKey, 'name']);
      case 'DateCreatedDesc':
        return orderBy(
          sortedProjectList,
          [createdAtKey, 'name'],
          ['desc', 'asc']
        );
      default:
        return sortedProjectList;
    }
  }
};

export const getDefaultBusinessHours = [
  {
    day: 'monday',
    closed: false,
  },
  {
    day: 'tuesday',
    closed: false,
  },
  {
    day: 'wednesday',
    closed: false,
  },
  {
    day: 'thursday',
    closed: false,
  },
  {
    day: 'friday',
    closed: false,
  },
  {
    day: 'saturday',
    closed: false,
  },
  {
    day: 'sunday',
    closed: false,
  },
];

export const constructBusinessHoursData = businessHours => {
  let newBusinessHourData = !isEmpty(businessHours)
    ? businessHours
    : getDefaultBusinessHours;
  newBusinessHourData = newBusinessHourData.reduce((acc, data) => {
    acc[data.day] = data;
    return acc;
  }, {});
  return newBusinessHourData;
};

export const getFullAddress = project => {
  const addressFieldss = pick(project, [
    'address',
    'address2',
    'city',
    'state',
    'zipCode',
    'country',
  ]);
  return compact(values(addressFieldss)).join(', ');
};

export const getProjectFormInitialState = props => {
  return {
    selectedCategoryNames: constructMultiSelectOptions(
      props.selectedCategoryNames
    ),
    selectedOwners: filter(props.assignableOwners, owner =>
      includes(props.existingOwnerIds, owner.id)
    ),
    csrfToken: props.token,
    assets: [],
    existingAssets: props.existingAssets,
    assetsWithFolders: props.existingAssetsFolders.reduce((acc, item) => {
      acc[item.handle] = item.folderId;
      return acc;
    }, {}),
    errors: [],
    loading: false,
    businessHourState: constructBusinessHoursData(props.businessHours),
    [props.modelFieldsPrefix]: props.attributes,
  };
};
